import React, { Fragment } from "react"
import { observer } from "mobx-react"
import DocumentKindSelect from "components/Common/DocumentKindSelect"
import { CheckboxInput, DateInput, RadioButtonGroup, RadioInput, TextInput } from "components/UI"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import RelationAgencyContract from "./RelationAgencyContract"

const InfoTab = ({ values }) => (
  <Fragment>
    <InsurerSelect name="insurer" required />
    <ChannelSelect name="channel" required />
    <DocumentKindSelect name="document_kind.id" ids={[4, 6, 26, 33]} required />
    <RelationAgencyContract
      name="parent"
      required={values.document_kind?.id != 4}
      disabled={values.document_kind?.id == 4 || !values.insurer?.id || !values.channel?.id}
      insurer_id={values.insurer?.id}
      channel_id={values.channel?.id}
    />
    <TextInput name="number" label="Номер документа" required />
    <DateInput name="conclusion_on" label="Дата заключения" required />
    <DateInput name="start_on" label="Срок действия с" required />
    <div className="form-group">
      <CheckboxInput name="is_prolongation" label="Автопролонгация" />
      <br />
    </div>
    <DateInput name="finish_on" label="Срок действия по" disabled={values.is_prolongation} />
    <DateInput name="termination_on" label="Дата расторжения" />
    <RadioButtonGroup label="Целевой сегмент" name="target.value">
      <RadioInput key="target_0" label="ЮЛ" id="ul" />
      <RadioInput key="target_1" label="ФЛ" id="fl" />
      <RadioInput key="target_2" label="ЮЛ + ФЛ" id="both" />
    </RadioButtonGroup>
    <TextInput name="comment" label="Комментарий" />
  </Fragment>
)

export default observer(InfoTab)
