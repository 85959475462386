import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { Card, CardTitle, CurrencyInput, TextInput, RadioButtonGroup, RadioInput } from "components/UI"
import ClientSelect from "components/Common/ClientSelect"
import CourtSelect from "components/Common/CourtSelect"

const BankruptcyTab = ({ store, values }) => {
  return (
    <Fragment>
      <br />
      <TextInput name="contract_bankruptcy.subject" label="Предмет договора (описание)" required />
      <RadioButtonGroup label="Сторона" name={"contract_bankruptcy.side.value"}>
        {[
          { label: "Должник", value: "debtor" },
          { label: "Кредитор", value: "сreditor" }
        ].map(({ label, value }) => (
          <RadioInput key={`contract_bankruptcy_side_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {values.contract_bankruptcy.side?.value === "debtor" && (
        <ClientSelect name="contract_bankruptcy.debtor.id" label="Должник" required />
      )}
      {values.contract_bankruptcy.side?.value === "сreditor" && (
        <TextInput name="contract_bankruptcy.debtor_comment" label="Должник" required />
      )}
      {values.contract_bankruptcy.side?.value === "сreditor" && (
        <ClientSelect name="contract_bankruptcy.сreditor.id" label="Кредитор" required />
      )}
      {values.contract_bankruptcy.side?.value === "debtor" && (
        <TextInput name="contract_bankruptcy.сreditor_comment" label="Кредитор" required />
      )}
      <Fragment>
        <hr />
        <h6>Требования</h6>
        <div className="row">
          <div className="col-3">
            <CurrencyInput name="contract_bankruptcy.requirement_duty" label="Основной долг" />
          </div>
          <div className="col-3">
            <CurrencyInput name="contract_bankruptcy.requirement_penalty" label="Неустойка" />
          </div>
          <div className="col-3">
            <CurrencyInput name="contract_bankruptcy.requirement_fee" label="Пошлина" />
          </div>
          <div className="col-3">
            <CurrencyInput name="contract_bankruptcy.requirement_expense" label="Судебные расходы" />
          </div>
        </div>
      </Fragment>
      <CurrencyInput name="contract_bankruptcy.bargain" label="Макс./Мин. сумма для торга" />
      <TextInput name="contract_bankruptcy.case_number" label="№ дела" />
      <CourtSelect name="contract_bankruptcy.court.id" />
      <TextInput name="contract_bankruptcy.arbitration_manager" label="Арбитражный управляющий" />
      <TextInput name="contract_bankruptcy.comment" label="Доп.информация" />
      <Card>
        <CardTitle>Результат</CardTitle>
        <RadioButtonGroup name={"contract_bankruptcy.result.value"}>
          {[
            { label: "В работе", value: "in_work" },
            { label: "Выиграно", value: "won" },
            { label: "Проиграно", value: "lost" },
            { label: "Нетиповой", value: "atypical" }
          ].map(({ label, value }) => (
            <RadioInput key={`contract_bankruptcy_result_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
        {values.contract_bankruptcy.result?.value === "atypical" && (
          <TextInput name="contract_bankruptcy.result_comment" />
        )}
        <Fragment>
          <hr />
          <h6>Взыскано</h6>
          <div className="row">
            <div className="col-3">
              <CurrencyInput name="contract_bankruptcy.recovered_duty" label="Сумма взыскания" />
            </div>
            <div className="col-3">
              <CurrencyInput name="contract_bankruptcy.recovered_penalty" label="Сумма неустойки" />
            </div>
            <div className="col-3">
              <CurrencyInput name="contract_bankruptcy.recovered_expense" label="Сумма судебных расходов" />
            </div>
          </div>
        </Fragment>
      </Card>
    </Fragment>
  )
}

export default observer(BankruptcyTab)
