import { types } from "mobx-state-tree"

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Signer = types.model("Signer", {
  id: types.maybeNull(types.integer),
  user: types.maybeNull(User),
  channel: types.maybeNull(Channel),
  comment: types.maybeNull(types.string)
})

export { Signer }
