import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import Documents from "./../Documents"

const AwardTab = ({ store }) => {
  const { data } = store
  return (
    <div className="row">
      <div className="col-12">
        <table className="table">
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>Сумма премирования</td>
              <td style={{ width: "50%" }}>{data.order_award.salary}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Дата премирования (не позднее)</td>
              <td style={{ width: "50%" }}>{formatDate(data.order_award.date_on)}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Основание премирования</td>
              <td style={{ width: "50%" }}>{data.order_award.reason}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Комментарии</td>
              <td style={{ width: "50%" }}>{data.order_award.comment}</td>
            </tr>
          </tbody>
        </table>
        <Documents store={store} />
      </div>
    </div>
  )
}

export default observer(AwardTab)
