import React, { useContext, useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import IndustryStore from "stores/IndustryStore/List"

const industryStore = IndustryStore.create()

const IndustrySelect = ({ name = "industry", required = false, label = "Отрасль", ...props }) => {
  const { values, setFieldValue } = useFormikContext()

  const industry = values[name]

  useEffect(() => {
    industryStore.fetch()
  }, [])

  const getOptions = () => {
    const { industryOptions } = industryStore
    const { id: value, name: label } = getIn(values, name) || { id: null, name: null }
    if (value) {
      industryOptions.concat([{ label, value }])
    }
    return industryOptions
  }

  const industryOptions = getOptions()

  const loadIndustryOptions = (value) =>
    industryStore
      .fetch({ filter: { by_name: value }, limit: 0 })
      .then(({ industryOptions }) => industryOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label={label}
        options={industryOptions}
        required={required}
        isSearchable
        loadOptions={loadIndustryOptions}
        {...props}
      />
    </div>
  )
}

export default observer(IndustrySelect)
