import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import state from "types/state"
import { InsuranceProduct } from "./InsuranceProduct"
import Meta from "./OneMeta"

const dataDefaultValue = { name: "", insurer: { id: 0 }, risks: [] }

const metaDefaultValue = {
  filters_options: {}
}

const InsuranceProductStore = types
  .model("InsuranceProductStore", {
    data: types.optional(InsuranceProduct, dataDefaultValue),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(state)
  })
  .views(self => ({
    get contacts() {
      return self.data.contacts
    },

    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get name() {
      return self.data.name
    },

    get bso() {
      return self.data.bso
    },
    
    get comments() {
      return self.data.comments
    },

    get risks() {
      return self.data.risks
    },

    get filtersOptions() {
      const { risks } = self.meta.filters_options
      const riskOptions = risks.map(({ id: value, name: label }) => {
        return {
          label,
          value
        }
      })

      return [riskOptions]
    }
  }))
  .actions(self => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, insurer_id, ...options } = params
      return instance
        .get(`/insurers/${insurer_id}/insurance_products/${id}`, { options })
        .then(response => self.resetStore(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    fetchMeta(params = {}) {
      self.setState("pending")
      const { insurer_id } = params
      return instance
        .get(`/insurers/${insurer_id}/insurance_products/meta`)
        .then(response => self.applyMeta(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    create(params = {}) {
      const { insurer_id } = params
      self.setState("pending")
      return instance
        .post(`/insurers/${insurer_id}/insurance_products`, { data: params })
        .then(response => self.applyData(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    update(params = {}) {
      const { id, insurer_id } = params
      self.setState("pending")
      return instance
        .put(`/insurers/${insurer_id}/insurance_products/${id}`, { data: params })
        .then(response => this.applyData(response))
        .then(response => self.setState("done"))
        .catch(error => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default InsuranceProductStore
