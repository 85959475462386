import Breadcrumbs from "pages/Users/components/Breadcrumbs"
import React, { Fragment, useEffect, useContext } from "react"
import UserStore from "stores/UserStore/One"
import { BreadcrumbItem, Button, Card } from "components/UI"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import { Link } from "react-router-dom"
import differenceInDays from "date-fns/differenceInDays"
import AuthStoreContext from "contexts/AuthStoreContext"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const userStore = UserStore.create()

const status = (status) => {
  switch (status.value) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Работает" />
    case 1:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не работает" />
    default:
      return <i className="fa fa-circle" style={{ color: "yellow" }} title="Отпуск" />
  }
}

const Show = (props) => {
  useEffect(() => {
    const { id } = props.match.params
    userStore.fetch({ id })
  }, [])

  const order_hire = userStore.data.orders.find((i) => i.order_subject.id == 4)
  const order_fire_at_will = userStore.data.orders.find((i) => i.order_subject.id == 6)
  const order_fire_by_agreement = userStore.data.orders.find((i) => i.order_subject.id == 7)
  const order_change_positions = userStore.data.orders.filter((i) => i.order_subject.id == 5 || i.order_subject.id == 8)
  const order_vacations = userStore.data.orders
    .filter((i) => i.order_subject.id == 3)
    .sort((a, b) => new Date(b.order_vacation.vacation_start_on) - new Date(a.order_vacation.vacation_start_on))

  const order_salaries = userStore.data.orders.filter((i) => [4, 5, 8].includes(i.order_subject.id))

  const { data: currentUser } = useContext(AuthStoreContext)

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{userStore.name}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/users/${userStore.id}/edit`}>Редактировать</Button>
      </Card>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Инфо о сотруднике">
          <br />
          <div className="row">
            <div className="col-12">
              <table className="table">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Статус сотрудника</td>
                    <td style={{ width: "50%" }}>{status(userStore.data.status)}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Табельный номер</td>
                    <td style={{ width: "50%" }}>{userStore.data.number}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Фамилия</td>
                    <td style={{ width: "50%" }}>{userStore.l_name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Имя</td>
                    <td style={{ width: "50%" }}>{userStore.f_name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Отчество</td>
                    <td style={{ width: "50%" }}>{userStore.p_name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Подразделение</td>
                    <td style={{ width: "50%" }}>{userStore.subdivision}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Должность</td>
                    <td style={{ width: "50%" }}>{userStore.position}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Юридическое лицо</td>
                    <td style={{ width: "50%" }}>{userStore.channel.name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Дата рождения</td>
                    <td style={{ width: "50%" }}>{userStore.birthday_on}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Emails</td>
                    <td style={{ width: "50%" }}>
                      {userStore.emails.map(({ id, value }) => (
                        <a key={`email-${id}`} href={`emailto:${value}`} rel="noopener noreferrer">
                          {value}
                        </a>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Телефоны</td>
                    <td style={{ width: "50%" }}>
                      {userStore.phones.map(({ id, value }) => (
                        <a key={`phone-${id}`} href={`tel:${value}`} rel="noopener noreferrer">
                          {value}
                        </a>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Комментарии</td>
                    <td style={{ width: "50%" }}>{userStore.data.comment}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Tab>
        <Tab eventKey="contacts" title="Личное дело">
          <br />
          <Tabs defaultActiveKey="movements">
            <Tab eventKey="movements" title="Движение персонала">
              <br />
              <div className="row">
                <div className="col-12">
                  {order_hire && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2">Прием на работу</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>Дата приема на работу</td>
                          <td style={{ width: "50%" }}>{formatDate(order_hire.order_hire.hire_on)}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Номер трудового договора</td>
                          <td style={{ width: "50%" }}>{order_hire.order_hire.contract_number}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {order_fire_at_will && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2">Увольнение</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>Дата увольнения</td>
                          <td style={{ width: "50%" }}>{formatDate(order_fire_at_will.order_fire_at_will.date_on)}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Причина увольнения</td>
                          <td style={{ width: "50%" }}>{order_fire_at_will.order_fire_at_will.reason}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Номер приказа об увольнении</td>
                          <td style={{ width: "50%" }}>
                            <Link to={`/orders/${order_fire_at_will.id}`}>{order_fire_at_will.number}</Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {order_fire_by_agreement && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2">Увольнение</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>Дата увольнения</td>
                          <td style={{ width: "50%" }}>
                            {formatDate(order_fire_by_agreement.order_fire_by_agreement.date_on)}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Причина увольнения</td>
                          <td style={{ width: "50%" }}>{order_fire_by_agreement.order_fire_by_agreement.reason}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Номер приказа об увольнении</td>
                          <td style={{ width: "50%" }}>
                            <Link to={`/orders/${order_fire_by_agreement.id}`}>{order_fire_by_agreement.number}</Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  <table className="table">
                    <thead>
                      <tr>
                        <th colSpan="6">История изменений должностей и окладов</th>
                      </tr>
                      <tr>
                        <th>#</th>
                        <th>Должность</th>
                        <th>Подразделение</th>
                        <th>Действует с</th>
                        <th>Оклад, руб.</th>
                        <th>Приказ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order_change_positions.map((i, index) => (
                        <tr>
                          <td>{i.id}</td>
                          <td>{i.order_change_position.position ? i.order_change_position.position.name : userStore.data.position.name}</td>
                          <td>{i.order_change_position.subdivision ? i.order_change_position.subdivision.name : userStore.data.subdivision.name}</td>
                          <td>{i.order_change_position.date_on ? formatDate(i.order_change_position.date_on) : formatDate(i.order_salary_change.date_on)}</td>
                          <td>{i.order_change_position.salary ? i.order_change_position.salary : i.order_salary_change.salary}</td>
                          <td>
                            <Link to={`/orders/${i.id}`}>{i.number}</Link>
                          </td>
                        </tr>
                      ))}
                      {order_hire && (
                        <tr>
                          <td>{1}</td>
                          <td>{order_hire.order_hire.position?.name}</td>
                          <td>{order_hire.order_hire.subdivision?.name}</td>
                          <td>{formatDate(order_hire.order_hire.hire_on)}</td>
                          <td>{order_hire.order_hire.salary}</td>
                          <td>
                            <Link to={`/orders/${order_hire.id}`}>{order_hire.number}</Link>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="personal_info" title="Персональные данные">
              <br />
              <div className="row">
                <div className="col-12">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td style={{ width: "50%" }}>Адрес регистрации</td>
                        <td style={{ width: "50%" }}>{userStore.data.address}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>ИНН</td>
                        <td style={{ width: "50%" }}>{userStore.data.inn}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>СНИЛС</td>
                        <td style={{ width: "50%" }}>{userStore.data.snils}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>Паспорт (серия, номер)</td>
                        <td style={{ width: "50%" }}>{userStore.data.passport_number}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>Выдан</td>
                        <td style={{ width: "50%" }}>{userStore.data.passport_issued_by}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>Дата выдачи паспорта</td>
                        <td style={{ width: "50%" }}>{formatDate(userStore.data.passport_issued_on)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Tab>
        {[5, 23].includes(currentUser.id) && (
          <Tab eventKey="finances" title="Финансы">
            <br />
            <div className="row">
              <div className="col-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Оклад, руб.</th>
                      <th>02, руб.</th>
                      <th>Действует с</th>
                      <th>Actual NET</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order_salaries.map((i) => (
                      <tr>
                        <td>{i.id}</td>
                        <td>
                          {i.order_change_position?.salary || i.order_hire?.salary || i.order_salary_change?.salary}
                        </td>
                        <td>{i.order_change_position?.o2 || i.order_hire?.o2 || i.order_salary_change?.o2}</td>
                        <td>{formatDate(i.date_on)}</td>
                        <td>
                          {formatMoney(
                            parseMoney(
                              i.order_change_position?.salary || i.order_hire?.salary || i.order_salary_change?.salary
                            ) *
                              0.87 +
                              parseMoney(i.order_change_position?.o2 || i.order_hire?.o2 || i.order_salary_change?.o2)
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Tab>
        )}
        <Tab eventKey="vacations" title="Отпуска">
          <br />
          <div className="row">
            <div className="col-12">
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Вид отпуска</th>
                    <th>Период с... по...</th>
                    <th>Кол-во дней</th>
                    <th>Приказ</th>
                  </tr>
                </thead>
                <tbody>
                  {order_vacations.map((i) => (
                    <tr>
                      <td>{i.id}</td>
                      <td>{i.order_vacation.kind.label}</td>
                      <td>
                        {formatDate(i.order_vacation.vacation_start_on)} -{" "}
                        {formatDate(i.order_vacation.vacation_finish_on)}
                      </td>
                      <td>
                        {differenceInDays(i.order_vacation.vacation_finish_on, i.order_vacation.vacation_start_on) + 1}
                      </td>
                      <td>
                        <Link to={`/orders/${i.id}`}>{i.number}</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan="3">Итого дней отпуска за период, дн.</th>
                    <th>
                      {order_vacations
                        .map(
                          (i) =>
                            differenceInDays(i.order_vacation.vacation_finish_on, i.order_vacation.vacation_start_on) +
                            1
                        )
                        .reduce((a, b) => a + b, 0)}
                    </th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </Tab>
        <Tab eventKey="account" title="Документы">
          <br />
          <table className="table table-hover">
            <thead className="thead-light">
              <tr>
                <th scope="col">Файл</th>
                <th scope="col">Тип</th>
              </tr>
            </thead>
            <tbody>
              {userStore.data.documents.map((i, index) => (
                <tr key={`user-document-${index}`}>
                  <td>
                    {i.attachments
                      .filter((i) => i.file.url)
                      .map((attachment) => (
                        <a
                          key={`attachment-${attachment.id}`}
                          href={attachment.file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {decodeURI(attachment.file.url.replace(/^.*[\\|/]/, ""))}
                          <br />
                        </a>
                      ))}
                  </td>
                  <td>{String(i.document_kind.id) === "18" ? i.document_kind_other : i.document_kind.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </Fragment>
  )
}

export default observer(Show)
