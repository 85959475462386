import React from "react"
import { RadioInput, Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"

const Table = ({ store, selected, setSelected }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col" className="actions one">
          #
        </th>
        <th scope="col">Название</th>
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`document_kinds-${index}`}>
          <td>
            <input
              type="radio"
              name="row.id"
              value={row.id}
              checked={selected.id === row.id}
              onChange={() =>
                setSelected({
                  id: row.id,
                  organization: row.organization,
                  address: row.address,
                  comment: row.comment,
                  contact: row.contact,
                  phone: row.phone
                })
              }
            />
          </td>
          <td>{row.name}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
