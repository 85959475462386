import React, { Fragment, useContext } from "react"
import { CurrencyInput, PercentageInput } from "components/UI"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import { observer } from "mobx-react"
// import { caclulateCommissionByPaidSp } from "./../SpTab/caclulateCommissionByPaidSp"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import { useFormikContext, getIn } from "formik"
import AuthStoreContext from "contexts/AuthStoreContext"

const Kv = ({ prefix = null, index, form, ...p }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")

  const { values, setFieldValue } = useFormikContext()

  const calculateCommissionCost = ({ sp_accrual_cost, rate }) => {
    sp_accrual_cost = parseMoney(sp_accrual_cost)
    rate = parseMoney(rate)

    return formatMoney(sp_accrual_cost * (rate * 0.01))
  }

  const { data: currentUser } = useContext(AuthStoreContext)
  // const disabled = ![3, 5, 10, 23, 49, 57].includes(currentUser.id)

  const caclulateCommissionByPaidSp = (values, rate) => {
    return (
      values.contract_installments
        .filter(
          (contract_installment) =>
            contract_installment.sp_payment_on &&
            contract_installment.sp_payment_cost &&
            contract_installment.sp_payment_cost !== "0.00" &&
            contract_installment.sp_sk_payment_on
        )
        .reduce((a, i) => a + parseMoney(i.sp_payment_cost), 0) *
      rate *
      0.01
    )
  }

  const hasAvr = form.values.contract_installments
    .filter((i) => i.avrs.length > 0)
    .map((i) => i.contract.kvs.map((i) => i.id))
    .flat()
    .includes(form.values.kvs[index].id)

  // const hasAvr = form.values.contract_installments.filter((i) => i.avrs.length > 0)

  return (
    <Fragment>
      <h5 className="card-title">{`КВ доп. ${index}`}</h5>
      <PercentageInput
        name={path("rate")}
        label="Ставка, %"
        required={form.values.status.value.toString() === "1"}
        disabled={
          (form.values.status.value.toString() === "1" &&
            form.initialValues.status.value.toString() === "1" &&
            getIn(values, path("id"))) ||
          form.values.has_additional ||
          String(values.is_without_kv) === "true" ||
          hasAvr
        }
        onChange={(e) => {
          setFieldValue(path("rate"), e.target.value)
          setFieldValue(path("by_contract"), calculateCommissionCost({ ...values, rate: e.target.value }))
          setFieldValue(path("by_paid_sp"), caclulateCommissionByPaidSp(values, e.target.value))
        }}
      />
      <CurrencyInput
        name={path("by_contract")}
        label="КВ по начисленной СП"
        value={calculateCommissionCost({ ...values, rate: values.kvs[index].rate })}
        disabled
      />
      <CurrencyInput
        name={path("by_paid_sp")}
        label="КВ по оплаченной СП"
        value={caclulateCommissionByPaidSp(values, values.kvs[index].rate)}
        disabled
      />
      <ChannelSelect
        name={path("channel")}
        disabled={
          (form.values.status.value.toString() === "1" && form.initialValues.status.value.toString() === "1") ||
          form.values.has_additional ||
          hasAvr
        }
      />
    </Fragment>
  )
}

export default observer(Kv)
