import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"

const Cost = types.model("Cost", {
  id: types.maybeNull(types.integer),
  month: types.maybeNull(types.number),
  cost: types.maybeNull(types.string)
})

const Item = types.model("Item", {
  id: types.maybeNull(types.integer),
  short_name: types.maybeNull(types.string),
  costs: types.maybeNull(types.array(Cost))
})

const SalePlan = types.model("SalePlan", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  name: types.maybeNull(types.string),
  year: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  items: types.maybeNull(types.array(Item))
})

export { SalePlan }
