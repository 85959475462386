import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { FieldArray, getIn } from "formik"
import { PercentageInput, RadioButtonGroup, RadioInput } from "components/UI"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import ContractInstallments from "./ContractInstallments"
// import { caclulateCommissionByPaidSp } from "./caclulateCommissionByPaidSp"

const SpTab = ({ store, values, setFieldValue, ...props }) => {
  const calculateCommissionCost = ({ sp_accrual_cost, kvs }) => {
    sp_accrual_cost = parseMoney(sp_accrual_cost)
    const rate = parseMoney(kvs[0].rate)

    return (sp_accrual_cost * (rate * 0.01)).toFixed(2)
  }

  const caclulateCommissionByPaidSp = ({ contract_installments, kvs }) => {
    return (
      contract_installments
        .filter(
          (contract_installment) =>
            contract_installment.sp_payment_on &&
            contract_installment.sp_payment_cost &&
            contract_installment.sp_payment_cost !== "0.00" &&
            contract_installment.sp_sk_payment_on
        )
        .reduce((a, i) => a + parseMoney(i.sp_payment_cost), 0) *
      kvs.reduce((a, i) => a + parseMoney(i.rate), 0) *
      0.01
    )
  }

  const caclulateCommissionByPaidSpForInfo = ({ contract_installments, kvs }) => {
    return (
      contract_installments
        .filter(
          (contract_installment) =>
            contract_installment.sp_payment_on &&
            contract_installment.sp_payment_cost &&
            contract_installment.sp_payment_cost !== "0.00" &&
            contract_installment.sp_sk_payment_on
        )
        .reduce((a, i) => a + parseMoney(i.sp_payment_cost), 0) *
      (kvs[0] ? kvs[0].rate : 0) *
      0.01
    )
  }

  const hasAvr = values.contract_installments.some((i) => i.avrs.length > 0)

  return (
    <Fragment>
      <br />
      <RadioButtonGroup label="Договор без КВ (%)" name="is_without_kv" disabled={hasAvr}>
        {[
          { label: "Нет", value: false },
          { label: "Да", value: true }
        ].map(({ label, value }) => (
          <RadioInput
            key={`sp_sk_retention_${value}`}
            label={label}
            id={value}
            onChange={(e) => {
              setFieldValue("is_without_kv", e.target.value)
              if (String(e.target.value) === "true") {
                setFieldValue("kvs.0.channel", values.channel)
                setFieldValue("kvs.0.rate", "0.000")
              }
            }}
          />
        ))}
      </RadioButtonGroup>
      <hr />
      <h6>
        Всего КВ по договору начисленное:{" "}
        {formatMoney(calculateCommissionCost({ ...values, rate: values.kvs[0].rate }))} | КВ по оплаченной СП:{" "}
        {formatMoney(caclulateCommissionByPaidSpForInfo({ ...values, rate: values.kvs[0].rate }))}
      </h6>
      <PercentageInput
        name={"kvs.0.rate"}
        label="Ставка, %"
        required={values.status.value.toString() === "1"}
        disabled={
          (values.status.value.toString() === "1" &&
            props.initialValues.status.value.toString() === "1" &&
            getIn(values, "kvs.0.id")) ||
          values.has_additional ||
          String(values.is_without_kv) === "true" ||
          hasAvr
        }
        onChange={(e) => {
          setFieldValue("kvs.0.rate", e.target.value)
          setFieldValue("kvs.0.by_contract", calculateCommissionCost({ ...values, rate: e.target.value }))
          setFieldValue("kvs.0.by_paid_sp", caclulateCommissionByPaidSp({ ...values, rate: e.target.value }))
        }}
      />
      <FieldArray name="contract_installments" render={(props) => <ContractInstallments {...props} store={store} />} />
    </Fragment>
  )
}

export default observer(SpTab)
