import React, { useEffect } from "react"
import compose from "utils/compose"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { withRouter } from "react-router"
import { AsyncSelectInput, ResetButton, Submit } from "components/UI"
import InsurerStore from "stores/InsurerStore/List"
import filterSubmitHandler from "utils/filterSubmitHandler"

const insurerStore = InsurerStore.create()

const Filter = ({ values, handleSubmit }) => {
  useEffect(() => {
    insurerStore.fetch()
  }, [])

  const insurerOptions = (() => {
    if (values.by_insurer) {
      const { id, name } = values.by_insurer
      const options = insurerStore.insurerOptions
      if (id) {
        options.concat([{ label: name, value: id }])
      }
      return options
    }
    return insurerStore.insurerOptions
  })()

  const loadInsurerOptions = (value) =>
    insurerStore.fetch({ filter: { by_name: value }, limit: 0 }).then(({ insurerOptions }) => insurerOptions)

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <AsyncSelectInput
            name="by_insurer.id"
            label="Страховая компания"
            options={insurerOptions}
            isSearchable
            loadOptions={loadInsurerOptions}
          />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
