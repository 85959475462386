import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import newCustomEnum from "types/newCustomEnum"
import toOptions from "utils/newToOptions"
import { BudgetNor } from "./BudgetNor"

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate)
})

const metaDefaultValue = {
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const ContractInstallmentStore = types
  .model("ContractInstallment", {
    data: types.array(BudgetNor, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { statuses, submissions } = self.meta.filters_options
      return {
        statuses: toOptions(statuses),
        submissions: toOptions(submissions)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      const { budget_id, ...options } = params

      return instance
        .get(`/budgets/${budget_id}/budget_nors`, { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/budget_nors/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchAll(params = {}) {
      self.setState("pending")

      const { budget_id, ...options } = params
      return instance
        .get(`/budgets/${budget_id}/budget_nors/all`)
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(params) {
      const { id, budget_id } = params
      self.setState("pending")
      return instance.delete(`/budgets/${budget_id}/budget_nors/${id}`)
      // .then(response => dispatch(destroyUserSuccess(response)))
      // .catch(error => dispatch(destroyUserFailure(error)))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ContractInstallmentStore
