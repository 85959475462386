import React from "react"
import { observer } from "mobx-react"
import { Breadcrumb, BreadcrumbItem } from "components/UI"

const Breadcrumbs = ({ provider: { id, name }, children }) => (
  <Breadcrumb>
    <BreadcrumbItem active={!children} url="/providers">
      Поставщики
    </BreadcrumbItem>
    <BreadcrumbItem url={`/providers/${id}`}>{name}</BreadcrumbItem>
    <BreadcrumbItem url={`/providers/${id}/contacts`}>Контактные лица</BreadcrumbItem>
    {children}
  </Breadcrumb>
)

export default observer(Breadcrumbs)
