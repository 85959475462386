import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import Breadcrumbs from "pages/Reports/components/Breadcrumbs"
import { BreadcrumbItem, Card } from "components/UI"
import Filter from "./components/Filter"
import Table from "./components/Table"
import Total from "./components/Total"
import ReportStore from "stores/ReportStore/Kvs"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

const reportStore = ReportStore.create()

const Sales = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    reportStore.fetch({ filter: { year: new Date().getFullYear() } })
  }, [])
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>План факт Компании за год</BreadcrumbItem>
      </Breadcrumbs>
      <Tabs defaultActiveKey="by_monts">
        <Tab eventKey="by_monts" title="По месяцам">
          <br />
          <Card>
            <Filter store={reportStore} />
          </Card>
          <Table store={reportStore} />
        </Tab>
        <Tab eventKey="total" title="Накопительным итогом">
          <br />
          <Card>
            <Filter store={reportStore} />
          </Card>
          <Total store={reportStore} />
        </Tab>
      </Tabs>
    </Fragment>
  )
}

export default observer(Sales)
