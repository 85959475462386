import React, { Fragment, useState } from "react"
import compose from "utils/compose"
import { Button, TextInput, ResetButton, SelectInput, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import SubdivisionSelect from "components/Common/SubdivisionSelect"

const Filter = ({ values, store, handleSubmit }) => {
  const { statuses } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <SelectInput name="by_status" label="Статус" options={statuses} />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_year"
            label="Год"
            options={[
              { label: "2020", value: 2020 },
              { label: "2021", value: 2021 },
              { label: "2022", value: 2022 },
              { label: "2023", value: 2023 },
              { label: "2024", value: 2024 },
              { label: "2025", value: 2025 },
              { label: "2026", value: 2026 },
              { label: "2027", value: 2027 }
            ]}
          />
        </div>
        <div className="col-3">
          <SubdivisionSelect name="by_subdivision.id" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
