import React, { Fragment, useEffect, useContext } from "react"
import { observer } from "mobx-react"
import AgencyContractStore from "stores/AgencyContractStore/One"
import Breadcrumbs from "pages/AgencyContracts/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import formatDate from "utils/formatDate"
import formatDateTime from "utils/formatDateTime"
import { Link } from "react-router-dom"
import AuthStoreContext from "contexts/AuthStoreContext"

const agencyContractStore = AgencyContractStore.create()

const Show = (props) => {
  useEffect(() => {
    const { id } = props.match.params
    agencyContractStore.fetch({ id })
  }, [])
  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{agencyContractStore.data.id}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button
          url={`/agency_contracts/${agencyContractStore.data.id}/edit`}
          disabled={![7, 8, 9].includes(currentUser.subdivision.id)}
        >
          Редактировать
        </Button>
        &nbsp;
      </Card>
      <div className="row">
        <div className="col-12">
          <table className="table">
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Страховая компания</td>
                <td style={{ width: "50%" }}>{agencyContractStore.data.insurer.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Канал продаж</td>
                <td style={{ width: "50%" }}>{agencyContractStore.data.channel.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Вид документа</td>
                <td style={{ width: "50%" }}>{agencyContractStore.data.document_kind?.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>№ документа</td>
                <td style={{ width: "50%" }}>{agencyContractStore.data.number}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Связанный договор</td>
                <td style={{ width: "50%" }}>
                  {agencyContractStore.data.parent?.id ? (
                    <Link target="_blank" to={`/agency_contracts/${agencyContractStore.data.parent.id}`}>
                      {agencyContractStore.data.parent.number}
                    </Link>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата заключения</td>
                <td style={{ width: "50%" }}>{formatDate(agencyContractStore.data.conclusion_on)}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Срок действия</td>
                <td style={{ width: "50%" }}>
                  {formatDate(agencyContractStore.data.start_on)} -{" "}
                  {!agencyContractStore.data.is_prolongation && formatDate(agencyContractStore.data.finish_on)}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Автопролонгация</td>
                <td style={{ width: "50%" }}>{agencyContractStore.data.is_prolongation ? "Да" : "Нет"}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Целевой сегмент</td>
                <td style={{ width: "50%" }}>{agencyContractStore.data.target.label}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата расторжения</td>
                <td style={{ width: "50%" }}>
                  {agencyContractStore.data.termination_on ? formatDate(agencyContractStore.data.termination_on) : "-"}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Комментарии</td>
                <td style={{ width: "50%" }}>{agencyContractStore.data.comment}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Документы</td>
                <td style={{ width: "50%" }}>
                  {agencyContractStore.data.documents.map((document) => (
                    <a
                      key={`document-${document.id}`}
                      href={document.file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {decodeURI(document.file.url.replace(/^.*[\\|/]/, ""))}
                      <br />
                    </a>
                  ))}
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Редактор</td>
                <td style={{ width: "50%" }}>{agencyContractStore.data.creator?.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Подразделение</td>
                <td style={{ width: "50%" }}>{agencyContractStore.data.creator?.subdivision?.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата редактирования</td>
                <td style={{ width: "50%" }}>{formatDateTime(agencyContractStore.data.updated_at)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

export default observer(Show)
