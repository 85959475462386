import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import BudgetMotivationCoefficientsStore from "stores/BudgetMotivationCoefficientStore/One"
import Form from "pages/Budgets/containers/BudgetMotivationCoefficients/components/Form"
import { BreadcrumbItem } from "components/UI"

const budgetStore = BudgetStore.create()
const budgetMotivationCoefficientsStore = BudgetMotivationCoefficientsStore.create()

const Add = (props) => {
  const { budget_id } = props.match.params

  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetMotivationCoefficientsStore.fetchMeta({ budget_id: budget_id })
  }, [])

  budgetMotivationCoefficientsStore.reset()

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>{budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}</BreadcrumbItem>
        <BreadcrumbItem active>Коэффициенты</BreadcrumbItem>
        <BreadcrumbItem url={`/budgets/${budget_id}/budget_motivation_coefficients`}>Коэф. мотивации</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>

      <Form {...{ store: budgetMotivationCoefficientsStore, budgetStore }} />
    </Fragment>
  )
}

export default observer(Add)
