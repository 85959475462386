import React from "react"
import Document from "./Document"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  kind: { value: 0 },
  submission: { value: 3 },
  submission_other: null,
  submission_holder: null,
  submission_on: new Date(),
  submission_status: { value: 2 },
  comment: null,
  attachments: []
}

const Documents = () => (
  <NestedFields name="documents" label="Сдача документов в СК" component={Document} defaultValues={defaultValues} />
)

export default Documents
