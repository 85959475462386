import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BorderoStore from "stores/BorderoStore/List"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { Button, Card, Paginate, BreadcrumbItem } from "components/UI"
import ContractStore from "stores/ContractStore/One"
import Tabsss from "pages/Contracts/components/Tabs"
import Table from "./components/Table"

const contractStore = ContractStore.create()
const borderoStore = BorderoStore.create()

const List = (props) => {
  const { contract_id } = props.match.params
  useEffect(() => {
    // const { state } = props.history.location
    // if (state && state.referer && /\/avrs\/\d+\/edit/i.test(state.referer)) {
    //   historyPushFromStore(props.history, window.location.pathname)
    // }
    contractStore.fetch({ id: contract_id })
    borderoStore.fetch({ contract_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem active>Бордеро</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${contract_id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${contract_id}/edit?additional=true`}>Доп. соглашение</Button>
        )}
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <Card>
        <Button url={`/contracts/${contract_id}/borderos/new`}>Создать</Button>
      </Card>
      <Table store={borderoStore} />
      <Paginate store={borderoStore} params={{ contract_id: contract_id }} />
    </Fragment>
  )
}

export default observer(List)
