import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import formatDate from "utils/formatDate"
import { Link } from "react-router-dom"

import Tabsss from "pages/Budgets/components/Tabs"

const budgetStore = BudgetStore.create()

const status = (status) => {
  switch (status) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const Show = (props) => {
  const { id } = props.match.params

  useEffect(() => {
    budgetStore.fetch({ id })
  }, [id])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budgetStore.data.id}/edit`}>Радактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <div className="row">
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th colSpan={2}>ОПЕРАЦИОННЫЙ БЮДЖЕТ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Статус</td>
                <td style={{ width: "50%" }}>{status(budgetStore.data.status.value)}</td>
              </tr>
              {budgetStore.data.kind.value.toString() === "0" && (
                <tr>
                  <td style={{ width: "50%" }}>Наименование подразделения</td>
                  <td style={{ width: "50%" }}>
                    {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"}
                  </td>
                </tr>
              )}
              {budgetStore.data.kind.value.toString() === "1" && (
                <Fragment>
                  <tr>
                    <td style={{ width: "50%" }}>Бюджет</td>
                    <td style={{ width: "50%" }}>Компания</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Подразделения входят</td>
                    <td style={{ width: "50%" }}>{budgetStore.data.subdivisions.map((i) => i.name).join(", ")}</td>
                  </tr>
                </Fragment>
              )}
              <tr>
                <td style={{ width: "50%" }}>Период</td>
                <td style={{ width: "50%" }}>{budgetStore.data.year}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Подготовил</td>
                <td style={{ width: "50%" }}>{budgetStore.data.creator.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата создания</td>
                <td style={{ width: "50%" }}>{formatDate(budgetStore.data.created_at)}</td>
              </tr>
              {budgetStore.data.kind.value.toString() === "1" && (
                <Fragment>
                  <tr>
                    <td style={{ width: "50%" }}>Родительский бюджет</td>
                    <td style={{ width: "50%" }}>
                      {budgetStore.data.parent && budgetStore.data.parent.id ? (
                        <Link to={`/budgets/${budgetStore.data.parent.id}`}>{budgetStore.data.parent.year}</Link>
                      ) : (
                        "Нет"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Дочерний бюджет</td>
                    <td style={{ width: "50%" }}>
                      {budgetStore.data.child && budgetStore.data.child.id ? (
                        <Link to={`/budgets/${budgetStore.data.child.id}`}>{budgetStore.data.child.year}</Link>
                      ) : (
                        "Нет"
                      )}
                    </td>
                  </tr>
                </Fragment>
              )}

              <tr>
                <td style={{ width: "50%" }}>Комментарий</td>
                <td style={{ width: "50%" }}>{budgetStore.data.comment}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

export default observer(Show)
