import React, { Fragment, useEffect } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useProject from "pages/Projects/hooks/useProject"
import Form from "pages/Projects/components/Form"

const Add = () => {
  const { project, newProject, createProject } = useProject()

  useEffect(() => {
    newProject()
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={project} handleSubmit={async (values) => createProject(values)} />
    </Fragment>
  )
}

export default Add
