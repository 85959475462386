import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import BillStore from "stores/BillStore/List"

const billStore = BillStore.create()

const BillSelect = ({ name = "bill", required = false, label = "Счет", form = null, ids = [], isMulti = false, ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    billStore.fetch({ filter: { by_form: form, by_ids: ids, order_by_name: "asc" } })
  }, [form])

  const getOptions = () => {
    const { billOptions } = billStore
    const { id: value, name: label } = getIn(values, name.replace(/\.id$/, "")) || { id: null, name: null }
    if (value && !billOptions.find(i => i.value === value)) {
      return billOptions.concat([{ label, value }])
    }
    return billOptions
  }

  const billOptions = getOptions()

  const loadBillOptions = (value) =>
    billStore
      .fetch({ filter: { by_name: value, by_form: form, by_ids: ids, order_by_name: "asc" }, limit: 0 })
      .then(({ billOptions }) => billOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label={label}
        required={required}
        options={billOptions}
        isSearchable
        isMulti={isMulti}
        loadOptions={loadBillOptions}
        {...props}
      />
    </div>
  )
}

export default observer(BillSelect)
