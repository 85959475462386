import React from "react"
import compose from "utils/compose"
import { TextInput, ResetButton, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import ClientSelect from "components/Common/ClientSelect"

const Filter = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <InsurerSelect name="by_insurer" />
      </div>
      <div className="col-3">
        <ClientSelect name="by_client.id" />
      </div>
      <div className="col-3">
        <TextInput name="by_number" label="Номер договора" />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
  </form>
)

export default compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  }),
  observer
)(Filter)
