import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetFotsStore from "stores/BudgetFotStore/List"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { Button, Card, BreadcrumbItem } from "components/UI"
import BudgetStore from "stores/BudgetStore/One"
import Tabsss from "pages/Budgets/components/Tabs"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import instance from "connection/instance"

const budgetStore = BudgetStore.create()
const budgetFotStore = BudgetFotsStore.create()

const Turnover = (props) => {
  const { id: budget_id } = props.match.params

  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetFotStore.fetchAll({ budget_id })
  }, [])

  const [fact, setFact] = useState([])
  const [turnoverFact, setTurnoverFact] = useState([])
  useEffect(() => {
    instance.get(`/budgets/${budget_id}/turnover`).then(({ data }) => {
      setFact(data)
    })
    instance.get(`/budgets/${budget_id}/turnover_fact`).then(({ data }) => {
      setTurnoverFact(data)
    })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>Налог с оборота</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: "4300px" }}>
          <thead>
            <tr>
              <th colSpan="19">План Налог с оборота</th>
            </tr>
            <tr>
              <th rowSpan={2}>#</th>
              <th rowSpan={2}>Статья</th>
              <th rowSpan={2}>Итого {budgetStore.data.year}</th>
              <th colSpan="4">1КВ{budgetStore.data.year}</th>
              <th colSpan="4">2КВ{budgetStore.data.year}</th>
              <th colSpan="4">3КВ{budgetStore.data.year}</th>
              <th colSpan="4">4КВ{budgetStore.data.year}</th>
            </tr>
            <tr>
              <th>1КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>2КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>3КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>4КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(2, 4)}</th>
            </tr>
          </thead>
          <tbody>
            {fact.map((values) => (
              <tr>
                <td>{values.id}</td>
                <td>{values.name}</td>
                <td>{formatMoney(values.costs.reduce((a, b) => a + parseMoney(b), 0))}</td>
                {values.costs.map((cost, costIndex) => (
                  <Fragment key={`item=${costIndex}`}>
                    {costIndex % 3 === 0 && (
                      <td style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </td>
                    )}
                    <td>{formatMoney(cost)}</td>
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th colSpan="19">Факт Налог с оборота</th>
            </tr>
            <tr>
              <th rowSpan={2}>#</th>
              <th rowSpan={2}>Статья</th>
              <th rowSpan={2}>Итого {budgetStore.data.year}</th>
              <th colSpan="4">1КВ{budgetStore.data.year}</th>
              <th colSpan="4">2КВ{budgetStore.data.year}</th>
              <th colSpan="4">3КВ{budgetStore.data.year}</th>
              <th colSpan="4">4КВ{budgetStore.data.year}</th>
            </tr>
            <tr>
              <th>1КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>2КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>3КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>4КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(2, 4)}</th>
            </tr>
          </thead>
          <tbody>
            {turnoverFact.map((values) => (
              <tr>
                <td>{values.id}</td>
                <td>{values.name}</td>
                <td>{formatMoney(values.costs.reduce((a, b) => a + parseMoney(b), 0))}</td>
                {values.costs.map((cost, costIndex) => (
                  <Fragment key={`item=${costIndex}`}>
                    {costIndex % 3 === 0 && (
                      <td style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </td>
                    )}
                    <td>{formatMoney(cost)}</td>
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default observer(Turnover)
