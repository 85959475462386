import store from "store"

const historyPushToStore = (history, key, search) => {
  store.set(key, search)
  history.replace({
    search
  })
}

const historyPushFromStore = (history, key) => {
  const search = store.get(key)
  if (search) {
    history.replace({
      search
    })
  }
}

export { historyPushToStore, historyPushFromStore }
