import React, { Fragment, useEffect } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import useProjects from "pages/Projects/hooks/useProjects"
import Table from "./components/Table"

const List = (props) => {
  const { projects, fetchProjects, destroyProjectWithFetch } = useProjects({ data: [] })

  useEffect(() => {
    fetchProjects()
  }, [])
  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/projects/new">Создать</Button>
      </Card>
      <Table collection={projects} destroyProjectWithFetch={destroyProjectWithFetch} />
      {/* <Paginate collection={projects} fetch={fetchProjects} /> */}
    </Fragment>
  )
}

export default List
