import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import CourtStore from "stores/CourtStore/List"
import Breadcrumbs from "pages/Courts/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"

const courtStore = CourtStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    courtStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/courts/new">Создать</Button>
      </Card>
      <Table store={courtStore} />
      <Paginate store={courtStore} />
    </Fragment>
  )
}

export default observer(List)
