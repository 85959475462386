import { types } from "mobx-state-tree"
import customEnum from "types/customEnum"
import Property from "./Property"

const ContractProperty = types.model("ContractProperty", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(customEnum),
  beneficiary: types.maybeNull(types.string),
  territory: types.maybeNull(types.string),
  inflector: types.maybeNull(customEnum),
  total_ss_cost: types.maybeNull(types.string),
  terms: types.maybeNull(types.string),
  franchise: types.maybeNull(types.string),
  properties: types.maybeNull(types.array(Property))
})

export default ContractProperty
