import React from "react"
import { Link } from "react-router-dom"
import { Destroy, Edit } from "components/UI"
import formatDate from "utils/formatDate"

const paymentStatus = (contract_installments) => {
  const count = contract_installments.filter((i) => i.payment_on).length
  switch (count) {
    case 0:
      return (
        <i className="fa fa-circle" style={{ color: "red" }} title="Ни один из платежей СП не оплачен Страхователем" />
      )
    case contract_installments.length:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Все платежи СП оплачены Страхователем" />
    default:
      return (
        <i
          className="fa fa-circle"
          style={{ color: "orange" }}
          title="Хотя бы один из платежей СП оплачен Страхователем"
        />
      )
  }
}

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Страховая компания</th>
        <th scope="col">№ договора</th>
        <th scope="col">Вид страхования</th>
        <th scope="col">Срок действия договора</th>
        <th scope="col">Исполнитель (СИ-НЕРДЖИ)</th>
        <th scope="col">Статус оплаты СП</th>
      </tr>
    </thead>
    <tbody>
      {store.data.map((i, index) => (
        <tr key={`clients-${index}`}>
          <td>{i.insurer.name}</td>
          <td>
            <Link to={`/contracts/${i.id}`}>{i.number}</Link>
          </td>
          <td>{i.insurance_kind.name}</td>
          <td>
            {formatDate(i.start_on)} - {formatDate(i.finish_on)}
          </td>
          <td>{i.user.name}</td>
          <td>{paymentStatus(i.contract_installments)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
