import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "components/UI"
import formatDate from "utils/formatDate"
import formatTime from "utils/formatTime"
import formatDateTime from "utils/formatDateTime"
import isBefore from "date-fns/isBefore"
import { Link } from "react-router-dom"
import { Fragment } from "react"

const rowClass = (i) => {
  if (i.status?.value == "done") return "table-success"
  if (i.status?.value == "in_work" && isBefore(i.date_on, new Date())) return "table-danger"
  if (i.status?.value == "in_work") return "table-warning"
}

const ShowModal = ({ isOpen, closeModal, entity }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>
        <span
          style={{
            color: "blue",
            fontWeight: "bold",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          <span>{[formatDate(entity.date_on), formatTime(entity.time_at)].filter(Boolean).join(" в ")}</span>
          <span>{entity.parent?.id ? "Подзадача" : "Задача"}</span>
          <span>{entity.performer?.name}</span>
        </span>
      </ModalHeader>
      <ModalBody>
        <table className="table table-hover">
          <tbody>
            <tr className={rowClass(entity)}>
              <td style={{ width: "50%" }}>Статус</td>
              <td>{entity.status?.label}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Договор</td>
              <td>
                {entity.contract && (
                  <Link target="_blank" to={`/contracts/${entity.contract.id}/specification`}>
                    {entity.contract.name}
                  </Link>
                )}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Вид страхования</td>
              <td>{entity.insurance_kind?.name}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Дата/время</td>
              <td>{[formatDate(entity.date_on), formatTime(entity.time_at)].filter(Boolean).join(" в ")}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Тип задачи</td>
              <td>{entity.kind?.label}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Тема</td>
              <td>{entity.subject}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Задачу создал</td>
              <td>{entity.creator?.name}</td>
            </tr>
            <tr>
              <td colSpan={2}>&nbsp;</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Исполнитель</td>
              <td>{entity.performer?.name}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Локация</td>
              <td>{entity.location || "-"}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Участники</td>
              <td>{entity.participant || "-"}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Суд</td>
              <td>{entity.court?.name || "-"}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Комментарии</td>
              <td>{entity.comment || "-"}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Файлы</td>
              <td>
                {entity.attachments && entity.attachments.length
                  ? entity.attachments
                      .filter((i) => i.file.url)
                      .map((attachment) => (
                        <a
                          key={`attachment-${attachment.id}`}
                          href={attachment.file.url}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {decodeURI(attachment.file.url.replace(/^.*[\\|/]/, ""))}
                          <br />
                        </a>
                      ))
                  : "-"}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>&nbsp;</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Результат</td>
              <td>{entity.result}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Дата / время закрытия</td>
              <td>{formatDateTime(entity.done_at)}</td>
            </tr>
          </tbody>
        </table>
        {entity.parent?.id && (
          <Fragment>
            <hr />
            <br />
            <br />
            <br />
            <table className="table table-hover">
              <tbody>
                <table className="table table-hover">
                  <thead style={{ background: "#eaecf4" }}>
                    <tr>
                      <th
                        colSpan={2}
                        style={{
                          color: "blue",
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "20px"
                        }}
                      >
                        Задача
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ background: "#eaecf4" }}>
                    <tr>
                      <td style={{ width: "50%" }}>Дата/время</td>
                      <td>
                        {[formatDate(entity.parent?.date_on), formatTime(entity.parent?.time_at)]
                          .filter(Boolean)
                          .join(" в ")}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Тип задачи</td>
                      <td>{entity.parent?.kind?.label}</td>
                    </tr>
                    <tr className="table-primary">
                      <td style={{ width: "50%" }}>Тема</td>
                      <td>{entity.parent?.subject}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Задачу создал</td>
                      <td>{entity.parent?.creator?.name}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>&nbsp;</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Исполнитель</td>
                      <td>{entity.parent?.performer?.name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Локация</td>
                      <td>{entity.parent?.location || "-"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Участники</td>
                      <td>{entity.parent?.participant || "-"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Суд</td>
                      <td>{entity.parent?.court?.name || "-"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Комментарии</td>
                      <td>{entity.parent?.comment || "-"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Файлы</td>
                      <td>
                        {entity.parent?.attachments && entity.parent?.attachments.length
                          ? entity.parent?.attachments
                              .filter((i) => i.file.url)
                              .map((attachment) => (
                                <a
                                  key={`attachment-${attachment.id}`}
                                  href={attachment.file.url}
                                  download
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {decodeURI(attachment.file.url.replace(/^.*[\\|/]/, ""))}
                                  <br />
                                </a>
                              ))
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>&nbsp;</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Результат</td>
                      <td>{entity.result}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Дата / время закрытия</td>
                      <td>{formatDateTime(entity.done_at)}</td>
                    </tr>
                  </tbody>
                </table>
              </tbody>
            </table>
          </Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ShowModal
