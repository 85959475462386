import React from "react"
import { Tab, Tabs } from "components/UI"
import { observer } from "mobx-react"

const TabsBlock = ({ store: { id, data } }) => {
  const mapping = {
    [`/clients/${id}`]: "Карточка страхователя",
    [`/clients/${id}/documents`]: "Документы",
    [`/clients/${id}/contracts`]: "Договоры"
  }

  if (data.kind === "entity") {
    mapping[`/clients/${id}/contacts`] = "Контактные лица"
  }
  return (
    <Tabs>
      {Object.entries(mapping).map((i) => {
        const active = window.location.pathname === i[0]
        return (
          <Tab key={i[0]} active={active} to={i[0]}>
            {i[1]}
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default observer(TabsBlock)
