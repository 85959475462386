import React, { Fragment, useEffect, useState } from "react"
import formatMoney from "utils/formatMoney"
import formatDate from "utils/formatDate"
import parseMoney from "utils/parseMoney"
import { Tooltip } from "recharts"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts"
import instance from "connection/instance"
import TabsBlock from "pages/Placements/components/TabsBlock"
import { Breadcrumb, BreadcrumbItem } from "components/UI"

const Structure = () => {
  const [table, setTable] = useState({ operations_sum: [], operations_by_channel: [] })

  const fetchData = async () => {
    try {
      const { data } = await instance.get("/reports/placements")

      data.operations_sum = data.operations_sum.map((i) => {
        return { ...i, total_sum: parseFloat(i.total_sum) }
      })

      Object.keys(data.operations_by_channel).map((name) => {
        data.operations_by_channel[name] = data.operations_by_channel[name].map((i) => {
          return { ...i, sum: parseFloat(i.sum) }
        })
      })

      setTable(data)
    } catch {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="recharts-default-tooltip custom-tooltip">
          {/* <p className="recharts-tooltip-label custom-tooltip-final-label-style">{label}</p> */}
          <ul className="recharts-tooltip-item-list custom-tooltip-list-style">
            <li className="recharts-tooltip-item custom-tooltip-final-item-style text-success">
              <span className="recharts-tooltip-item-name">Сумма</span>
              <span className="recharts-tooltip-item-separator">: </span>
              <span className="recharts-tooltip-item-value">{formatMoney(payload[0].payload.sum)}</span>
            </li>
            <li className="recharts-tooltip-item custom-tooltip-final-item-style text-warning">
              <span className="recharts-tooltip-item-name">Юр.лицо</span>
              <span className="recharts-tooltip-item-separator">: </span>
              <span className="recharts-tooltip-item-value">{label}</span>
            </li>
            <li className="recharts-tooltip-item custom-tooltip-final-item-style text-danger">
              <span className="recharts-tooltip-item-name">Контрагент</span>
              <span className="recharts-tooltip-item-separator">: </span>
              <span className="recharts-tooltip-item-value">{payload[0].payload.counterpart_name}</span>
            </li>
            <li className="recharts-tooltip-item custom-tooltip-final-item-style text-primary">
              <span className="recharts-tooltip-item-name">Дата возврата</span>
              <span className="recharts-tooltip-item-separator">: </span>
              <span className="recharts-tooltip-item-value">{formatDate(payload[0].payload.refund_on)}</span>
            </li>
          </ul>
        </div>
      )
    }

    return null
  }

  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem active>Размещения</BreadcrumbItem>
        <BreadcrumbItem active>Структура</BreadcrumbItem>
      </Breadcrumb>
      <TabsBlock />
      <br />
      <div className="row">
        <div className="col">
          <h6 style={{ fontWeight: "bold", textAlign: "center" }}>
            Размещения итого
            <br />
            <br />
            <span style={{ color: "green" }}>
              {formatMoney(table.operations_sum.reduce((a, b) => a + parseMoney(b.total_sum), 0))}&#8381;
            </span>
          </h6>
          <ResponsiveContainer width="100%" height={50 + table?.operations_sum.length*50}>
            <BarChart
              data={table?.operations_sum || []}
              layout="vertical"
              barSize={30}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis type="category" dataKey="name" width={200} />
              <Tooltip formatter={(value, name, props) => [formatMoney(value), 'Сумма итого']} />
              <Bar dataKey="total_sum" stackId="a" fill="#4e73df" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      {Object.keys(table?.operations_by_channel).map((name) => (
        <div className="row">
          <div className="col">
            <br />
            <br />
            <h6 style={{ fontWeight: "bold", textAlign: "center" }}>
              Размещения | {name}
              <br />
              <br />
              <span style={{ color: "green" }}>
                Итого: {formatMoney(table.operations_sum.find((i) => i.name === name).total_sum)}&#8381;
              </span>
            </h6>
            <ResponsiveContainer width="100%" height={50 + table?.operations_by_channel[name].length*50}>
              <BarChart
                data={table?.operations_by_channel[name] || []}
                layout="vertical"
                barSize={30}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis type="category" dataKey="name" width={200} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="sum" stackId="a" fill="#4e73df" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ))}
    </Fragment>
  )
}

export default Structure
