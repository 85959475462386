import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Clients from "./containers/Clients"
import CheckPermissions from "components/CheckPermissions"

const Holdings = () => (
  // <CheckPermissions condition={(privileges) => privileges.includes("holdings")}>
    <Switch>
      <Route path="/holdings/:id/edit" component={Edit} />
      <Route path="/holdings/:id/clients" component={Clients} />
      <Route path="/holdings/new" component={Add} />
      <Route path="/holdings/:id" component={Show} />
      <Route path="/holdings" component={List} />
    </Switch>
  // </CheckPermissions>
)

export default Holdings
