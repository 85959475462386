import React, { useContext, useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"
import AuthStoreContext from "contexts/AuthStoreContext"
import { useFormikContext } from "formik"
import UserStore from "stores/UserStore/List"

const userStore = UserStore.create()

const UserSelect = ({ name = "user", label = "Исполнитель", ...props }) => {
  useEffect(() => {
    userStore.search({ limit: 0 })
  }, [])
  // const { userStore } = useContext(ContractFormContext)
  // const {
  //   values: { user },
  //   setFieldValue
  // } = useFormikContext()

  const { values, setFieldValue } = useFormikContext()
  const user = values[name]

  const { data: currentUser } = useContext(AuthStoreContext)

  const userOptions = user.id ? [{ label: user.name, value: user.id }] : userStore.userOptions

  if (user.id === null) setFieldValue(name, { id: currentUser.id, name: currentUser.name })

  const loadUserOptions = (inputValue) =>
    userStore.search({ filter: { by_name: inputValue }, limit: 0 }).then(({ userOptions }) => userOptions)

  const onChange = ({ value, label, data }) => {
    setFieldValue(`${name}`, { id: value, name: label })
    setFieldValue("subdivision", { id: data.subdivision.id, name: data.subdivision.name })
  }

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label={label}
        required
        options={userOptions}
        isSearchable
        loadOptions={loadUserOptions}
        onChange={onChange}
        {...props}
      />
    </div>
  )
}

export default observer(UserSelect)
