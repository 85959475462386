const prepare = (params = {}) => {
  const {
    kind,
    kind_of_interaction,
    finance_model,
    status,
    head: { id: head_id },
    creator: { id: creator_id },
    users,
    ...other
  } = params
  return {
    ...other,
    kind: parseInt(kind.value),
    kind_of_interaction: parseInt(kind_of_interaction.value),
    finance_model: parseInt(finance_model.value),
    status: parseInt(status.value),
    head_id,
    creator_id
  }
}

export { prepare }
