import React from "react"
import compose from "utils/compose"
import { DateInput, ResetButton, SelectInput, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import BsoKindSelect from "./BsoKindSelect"

const Filter = ({ store, handleSubmit, insurer, values }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <BsoKindSelect name="by_bso_kind" insurer={insurer} />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
  </form>
)

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({}),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  }),
  observer
)(Filter)
