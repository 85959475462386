import React from "react"
import formatDate from "utils/formatDate"

const Table = ({ store, isCheckedRow, onCheckedRow, isCheckedAllRows, onCheckedAllRows }) => {
  const { page } = store.pagination
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllRows(page)} onChange={(e) => onCheckedAllRows(e, page)} />
          </th>
          <th>ID</th>
          <th>P&L счет</th>
          <th>Юр. лицо расчетов</th>
          <th>Контрагент</th>
          <th style={{ width: "150px" }}>Сумма</th>
          <th>Дата операции</th>
          <th>Назначение платежа</th>
        </tr>
      </thead>
      <tbody>
        {store.data.map((i, index) => (
          <tr key={`operation-${index}`}>
            <td>
              <input type="checkbox" checked={isCheckedRow(i, page)} onChange={() => onCheckedRow(i, page)} />
            </td>
            <td>{i.id}</td>
            <td>{i.bill.short_name}</td>
            <td>{i.channel.name}</td>
            <td>{i.counterpart.name}</td>
            <td>{i.cost}</td>
            <td>{formatDate(i.date_on)}</td>
            <td>{i.comment + (i.kind.value.toString() === "2" ? ` / ${i.moving_kind.label}` : "")}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
