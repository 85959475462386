import React, { Fragment } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter, Prompt } from "react-router"
import { Submit } from "components/UI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import BackofficeTab from "./BackofficeTab"
import CargoTab from "./CargoTab"
import FinanceTab from "./FinanceTab"
import InfoTab from "./InfoTab"
import PropertiesTab from "./PropertiesTab"
import AutoTab from "./AutoTab"
import DmsTab from "./DmsTab"
import AccidentTab from "./AccidentTab"
import GoTransportTab from "./GoTransportTab"
import GoEstateTab from "./GoEstateTab"
import OsgopTab from "./OsgopTab"
import MortgageTab from "./MortgageTab"
import ProceedingTab from "./ProceedingTab"
import BankruptcyTab from "./BankruptcyTab"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ store, handleSubmit, ...props }) => {
  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Prompt
          when={props.initialValues !== props.values && !props.isSubmitting}
          message="У вас есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?"
        />
        <Tabs defaultActiveKey="info">
          <Tab eventKey="info" title="Информация о договоре">
            <InfoTab {...{ store, ...props }} />
          </Tab>
          {props.values.insurance_kind.id === 7 && (
            <Tab eventKey="dms" title="ДМС">
              <DmsTab {...{ store, ...props }} />
            </Tab>
          )}
          {props.values.insurance_kind.id === 10 && (
            <Tab eventKey="cargo" title="Грузы">
              <CargoTab {...{ store, ...props }} />
            </Tab>
          )}
          {props.values.insurance_kind.id === 11 && (
            <Tab eventKey="properties" title="Имущество">
              <PropertiesTab {...{ store, ...props }} />
            </Tab>
          )}
          {[9, 13, 14, 15].includes(props.values.insurance_kind.id) && (
            <Tab eventKey="autos" title="Авто">
              <AutoTab {...{ store, ...props }} />
            </Tab>
          )}
          {props.values.insurance_kind.id === 35 && (
            <Tab eventKey="accidents" title="Несчастный случай">
              <AccidentTab {...{ store, ...props }} />
            </Tab>
          )}
          {[18, 37].includes(props.values.insurance_kind.id) && (
            <Tab eventKey="go_transports" title="ГО">
              <GoTransportTab {...{ store, ...props }} />
            </Tab>
          )}
          {[16, 17].includes(props.values.insurance_kind.id) && (
            <Tab eventKey="go_estates" title="ГО">
              <GoEstateTab {...{ store, ...props }} />
            </Tab>
          )}
          {props.values.insurance_kind.id === 36 && (
            <Tab eventKey="osgop" title="ОСГОП">
              <OsgopTab {...{ store, ...props }} />
            </Tab>
          )}
          {props.values.insurance_kind.id === 54 && (
            <Tab eventKey="mortgage" title="Ипотека">
              <MortgageTab {...{ store, ...props }} />
            </Tab>
          )}
          {props.values.insurance_kind.id === 43 && (
            <Tab eventKey="proceeding" title="Судопроизводство">
              <ProceedingTab {...{ store, ...props }} />
            </Tab>
          )}
          {props.values.insurance_kind.id === 44 && (
            <Tab eventKey="proceeding" title="Банкротство">
              <BankruptcyTab {...{ store, ...props }} />
            </Tab>
          )}
          <Tab eventKey="finance" title="Финансовый блок">
            <FinanceTab {...{ store, ...props }} />
          </Tab>
          {/* <Tab eventKey="backoffice" title="Бэкофис">
            <BackofficeTab {...{ store, ...props }} />
          </Tab> */}
        </Tabs>
        <Submit>Сохранить</Submit>
      </form>
    </Fragment>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      
      if (values.status.value.toString() === "0" && values.was_released) {
        if (confirm("Сохранить договор в статусе «Выпущен»?")) {
          values = { ...values, status: { value: 1 } }
        }
      }
      console.log(values.status.value)
      formSubmitHandler(values, formikBag, "/contracts")
    }
  }),
  observer
)(Form)
