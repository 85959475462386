import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const Avr = types.model("Avr", {
  id: types.maybeNull(types.integer),
  payment_status: types.maybeNull(newCustomEnum)
})

const ContractReturnment = types.model("ContractReturnment", {
  id: types.maybeNull(types.integer),
  avrs: types.array(Avr, []),
  payment_cost: types.maybeNull(types.string),
  payment_on: types.maybeNull(customDate),
  sp_payment_cost: types.maybeNull(types.string),
  sp_payment_on: types.maybeNull(customDate),
  sp_payment_kind: types.maybeNull(newCustomEnum),
  sp_sk_payment_on: types.maybeNull(customDate),
  sp_sk_payment_cost: types.maybeNull(types.string),
  sp_sk_payment_form: types.maybeNull(newCustomEnum),
  // sp_sinergy_payment_on: types.maybeNull(customDate),
  // sp_sinergy_payment_cost: types.maybeNull(types.string),
  // sp_sinergy_payment_form: types.maybeNull(newCustomEnum),
  // contract_payment_status: types.maybeNull(newCustomEnum),
  // kv_by_paid_sp: types.maybeNull(types.string),
  subagent_cost: types.maybeNull(types.string),
  subagent_cost_planned_on: types.maybeNull(customDate),
  subagent_cost_fact_on: types.maybeNull(customDate),
  is_payments_equal: types.maybeNull(types.boolean),
  returnment: types.maybeNull(types.boolean),
})

export default ContractReturnment
