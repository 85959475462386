import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import ClientStore from "stores/ClientStore/List"
import InsurerStore from "stores/InsurerStore/List"
import SubagentStore from "stores/SubagentStore/List"
import InsuranceProductStore from "stores/InsuranceProductStore/List"
import InsuranceKindStore from "stores/InsuranceKindStore/List"
import UserStore from "stores/UserStore/List"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import Form from "pages/Contracts/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"

const Add = () => {
  const contractStore = ContractStore.create()
  const clientStore = ClientStore.create()
  const insuranceKindStore = InsuranceKindStore.create()
  const insuranceProductStore = InsuranceProductStore.create()
  const insurerStore = InsurerStore.create()
  const subagentStore = SubagentStore.create()
  const userStore = UserStore.create()

  useEffect(() => {
    contractStore.fetchMeta()
    clientStore.search()
    // insuranceKindStore.fetch({ limit: 0 })
    // insurerStore.fetch()
    // subagentStore.fetch()
    // userStore.fetch()
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <ContractFormContext.Provider
        value={{
          clientStore,
          contractStore,
          insuranceKindStore,
          insuranceProductStore,
          insurerStore,
          subagentStore,
          userStore
        }}
      >
        <Form store={contractStore} />
      </ContractFormContext.Provider>
    </Fragment>
  )
}

export default observer(Add)
