import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Contacts from "./containers/Contacts"
import Documents from "./containers/Documents"
import CheckPermissions from "components/CheckPermissions"

const Providers = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("providers")}>
    <Switch>
      <Route path="/providers/:id/edit" component={Edit} />
      <Route path="/providers/:id/contacts" component={Contacts} />
      <Route path="/providers/:id/documents" component={Documents} />
      <Route path="/providers/new" component={Add} />
      <Route path="/providers/:id" component={Show} />
      <Route path="/providers" component={List} />
    </Switch>
  </CheckPermissions>
)

export default Providers
