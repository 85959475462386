import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { Submit, TextInput, RadioButtonGroup, RadioInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"
import UserSelect from "components/Common/UserSelect"

const Form = ({ store, handleSubmit }) => {
  const { kinds, kind_of_interactions, finance_models, statuses } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup label="Вид подразделения" name="kind.value">
        {kinds.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Тип подразделения" name="kind_of_interaction.value">
        {kind_of_interactions.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Финансовая модель" name="finance_model.value">
        {finance_models.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Статус" name="status.value">
        {statuses.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <TextInput name="name" label="Наименование полное" required />
      <TextInput name="short_name" label="Наименование краткое" required />
      <TextInput name="direction" label="Ключевое направление деятельности" />
      <UserSelect name="head" label="Руководитель подразделения" />
      <TextInput name="comment" label="Комментарий" />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/subdivisions")
  }),
  observer
)(Form)
