import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import state from "types/state"
import Channel from "./Channel"
import Meta from "./ListMeta"
import toOptions from "utils/toOptions"

const metaDefaultValue = {
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const ChannelStore = types
  .model("Channel", {
    data: types.array(Channel, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(state)
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get channelOptions() {
      return self.data.map(({ id, name }) => {
        return {
          label: name,
          value: id
        }
      })
    },

    get options() {
      return toOptions(self.data)
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      return instance
        .get("/channels", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchAround(params = {}) {
      self.setState("pending")
      const { id } = params
      return instance
        .get(`/channels/${id}/around`)
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/channels/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ChannelStore
