import React from "react"
import { Route, Switch } from "react-router-dom"
import List from "./containers/List"

const Contracts = () => (
  <Switch>
    <Route path="/subagents/:subagent_id/contracts" component={List} />
  </Switch>
)

export default Contracts
