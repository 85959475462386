import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { Link } from "react-router-dom"

const InfoTab = ({ store: { data } }) => (
  <div className="row">
    <div className="col-12">
      <table className="table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Тема приказа</td>
            <td style={{ width: "50%" }}>{data.order_subject.name}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>ФИО сотрудника</td>
            <td style={{ width: "50%" }}><Link to={`/users/${data.user.id}`}>{data.user.name}</Link></td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Табельный номер</td>
            <td style={{ width: "50%" }}>{data.user.number}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Подразделение</td>
            <td style={{ width: "50%" }}>{data.user.subdivision.name}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Должность</td>
            <td style={{ width: "50%" }}>{data.user.position.name}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Юридическое лицо</td>
            <td style={{ width: "50%" }}>{data.channel.name}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Дата составления приказа</td>
            <td style={{ width: "50%" }}>{formatDate(data.date_on)}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Номер приказа</td>
            <td style={{ width: "50%" }}>{data.number}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Подписант приказа</td>
            <td style={{ width: "50%" }}>{data.signer.user?.name}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Составитель</td>
            <td style={{ width: "50%" }}>{data.creator.name}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Дата создания</td>
            <td style={{ width: "50%" }}>{formatDate(data.created_at)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

export default observer(InfoTab)
