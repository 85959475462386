import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import AvrStore from "stores/AvrStore/One"
import Breadcrumbs from "pages/Avrs/components/Breadcrumbs"
import formatDate from "utils/formatDate"
import { BreadcrumbItem, Button, Card } from "components/UI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import { Link } from "react-router-dom"
import Badge from "react-bootstrap/Badge"
import addDays from "date-fns/addDays"
import classNames from "classnames"

const status = (status) => {
  switch (status) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    case 1:
      return <i className="fa fa-circle" style={{ color: "yellow" }} title="Согласован" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const paymentStatus = (avr) => {
  if (avr.payment_kind.value === 0) {
    if (avr.avr_installments[0]?.payment_on === null) {
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не оплачен" />
    } else {
      return <i className="fa fa-circle" style={{ color: "green" }} title="Оплачен" />
    }
  } else {
    const a = avr.avr_installments.filter((i) => i.payment_on !== null && parseInt(i.payment_cost, 10) !== 0).length

    const kvSumByAvr = avr.kv_costs.map((i) => i.cost).reduce((a, b) => a + b, 0)

    const paidKv =
      avr.payment_kind.value.toString() === "0"
        ? avr.avr_installments[0]?.payment_on
          ? kvSumByAvr
          : 0
        : avr.kv_costs
            // .filter((i) => i.payment_on)
            .map((i) => parseMoney(i.contract_installment.payment_cost))
            .reduce((a, b) => a + b, 0)

    if (a === 0) {
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не оплачен" />
    }
    if (a === avr.avr_installments.length && kvSumByAvr - paidKv <= 0) {
      return <i className="fa fa-circle" style={{ color: "green" }} title="Оплачен" />
    }
    return <i className="fa fa-circle" style={{ color: "yellow" }} title="Оплачен частично" />
  }
}

class Show extends Component {
  avrStore = AvrStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.avrStore.fetch({ id })
  }

  render() {
    const { avrStore: store } = this

    const kvSumByAvr = store.data.kv_costs.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0)

    const sumByReturnments = store.data.contract_returnments
      .map(
        (i) =>
          parseMoney(i.payment_cost) *
          parseMoney(i.contract.kvs.find((i) => i.channel.id === store.data.channel.id)?.rate) *
          0.01
      )
      .reduce((a, b) => a + b, 0)

    const paidKv =
      store.data.payment_kind.value.toString() === "0"
        ? store.data.avr_installments[0]?.payment_on
          ? kvSumByAvr
          : 0
        : store.data.avr_installments
            // .filter((i) => i.payment_on)
            .map((i) => parseMoney(i.payment_cost))
            .reduce((a, b) => a + b, 0)

    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{store.id}</BreadcrumbItem>
        </Breadcrumbs>
        <Tabs defaultActiveKey="info">
          <Tab eventKey="info" title="Общие сведения АВР">
            <br />
            <Card>
              <Button url={`/avrs/${store.data.id}/edit`}>Редактировать</Button>&nbsp;
              <Button url={`#`}>Печать</Button>&nbsp;
              <Button url={`#`}>Запрос в СК???</Button>&nbsp;
              <p>
                <br />
                Статус АВР&nbsp;{status(store.data.status.value)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Статус оплаты&nbsp;
                {paymentStatus(store.data)}
              </p>
            </Card>
            <div className="row">
              <div className="col-12">
                <table className="table">
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>Страховая компания</td>
                      <td style={{ width: "50%" }}>{store.insurer.name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Куратор от Страховой компании</td>
                      <td style={{ width: "50%" }}>
                        {store.insurer_contact.id && `${store.insurer_contact.l_name} ${store.insurer_contact.f_name}`}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Канал продаж</td>
                      <td style={{ width: "50%" }}>{store.channel.name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Период отчета с</td>
                      <td style={{ width: "50%" }}>{store.start_on}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Период отчета по</td>
                      <td style={{ width: "50%" }}>{store.finish_on}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>№ АВР</td>
                      <td style={{ width: "50%" }}>{store.number}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Дата АВР</td>
                      <td style={{ width: "50%" }}>{store.date_on}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Сумма КВ по АВР</td>
                      <td style={{ width: "50%" }}>{store.data.kv_sum}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>КВ по удержанию</td>
                      <td style={{ width: "50%" }}>{store.kv_by_hold.label}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Кто формирует АВР</td>
                      <td style={{ width: "50%" }}>{store.former.label}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Комментарий</td>
                      <td style={{ width: "50%" }}>{store.data.comment}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Создал</td>
                      <td style={{ width: "50%" }}>{store.data.user.name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Дата создания</td>
                      <td style={{ width: "50%" }}>{formatDate(store.data.created_at)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Tab>
          <Tab eventKey="registry" title="Реестр договоров">
            <br />
            <Card>
              <Button url={`/avrs/${store.data.id}/edit`}>Редактировать</Button>&nbsp;
              <Button url={`#`}>Печать</Button>&nbsp;
              <Button url={`#`}>Запрос в СК???</Button>&nbsp;
              <p>
                <br />
                Статус АВР&nbsp;{status(store.data.status.value)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Статус оплаты&nbsp;
                {paymentStatus(store.data)}
              </p>
            </Card>
            <p>
              <Badge variant="success">Кто формирует АВР:&nbsp;{store.data.former.label}</Badge>&nbsp;&nbsp;&nbsp;&nbsp;
              <Badge variant="warning">
                КВ по удержанию:&nbsp;
                {store.data.kv_by_hold.label}
              </Badge>
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>№ п/п</th>
                  <th>Номер договора</th>
                  <th>Страхователь</th>
                  <th>Дата оплаты премии страхователем</th>
                  <th>Сумма оплаченной премии страхователем</th>
                  <th>Ставка вознаграждения, %</th>
                  <th>Сумма вознаграждения, руб.</th>
                </tr>
              </thead>
              <tbody>
                {store.data.kv_costs.concat(store.data.kv_cost_returnments).map((kv_cost, index) => (
                  <Fragment>
                    {!kv_cost.hasOwnProperty("contract_returnment") && (
                      <tr key={`kv_cost${kv_cost.id}`}>
                        <td>{++index}</td>
                        <td>
                          <Link to={`/contracts/${kv_cost.contract_installment.contract.id}`}>
                            {kv_cost.contract_installment.contract.number}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/clients/${kv_cost.contract_installment.contract.client.id}`}>
                            {kv_cost.contract_installment.contract.client.name}
                          </Link>
                        </td>
                        <td>{formatDate(kv_cost.contract_installment.payment_on)}</td>
                        <td>{kv_cost.contract_installment.payment_cost}</td>
                        <td>{Object.keys(kv_cost.kv).length ? kv_cost.kv.rate : "-"}</td>
                        <td>{kv_cost.cost}</td>
                      </tr>
                    )}
                    {kv_cost.hasOwnProperty("contract_returnment") && (
                      <tr key={`kv_cost${kv_cost.id}`} className="table-danger">
                        <td>{++index}</td>
                        <td>
                          <Link to={`/contracts/${kv_cost.contract_returnment.contract.id}`}>
                            {kv_cost.contract_returnment.contract.number}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/clients/${kv_cost.contract_returnment.contract.client.id}`}>
                            {kv_cost.contract_returnment.contract.client.name}
                          </Link>
                        </td>
                        <td>{formatDate(kv_cost.contract_returnment.payment_on)}</td>
                        <td>{kv_cost.contract_returnment.payment_cost}</td>
                        <td>{kv_cost.kv.rate}</td>
                        <td>{kv_cost.cost}</td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan="4">Итого</th>
                  <th>
                    {formatMoney(
                      store.data.kv_costs
                        .map((i) => parseMoney(i.contract_installment.payment_cost))
                        .reduce((a, b) => a + b, 0) -
                        store.data.kv_cost_returnments
                          .map((i) => parseMoney(i.contract_returnment.payment_cost))
                          .reduce((a, b) => a + b, 0)
                    )}
                  </th>
                  <th>&nbsp;</th>
                  <th>
                    {formatMoney(
                      store.data.kv_costs.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0) -
                        store.data.kv_cost_returnments.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0)
                    )}
                  </th>
                </tr>
              </tfoot>
            </table>
          </Tab>
          <Tab eventKey="backoffice" title="Бэк-офис">
            <br />
            <Card>
              <Button url={`/avrs/${store.data.id}/edit`}>Редактировать</Button>&nbsp;
              <Button url={`#`}>Печать</Button>&nbsp;
              <Button url={`#`}>Запрос в СК???</Button>&nbsp;
              <p>
                <br />
                Статус АВР&nbsp;{status(store.data.status.value)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Статус оплаты&nbsp;
                {paymentStatus(store.data)}
              </p>
            </Card>
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Бумажный АВР или ЭЦП</td>
                  <td style={{ width: "50%" }}>{store.kind.label}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Дата формирования АВР / дата передачи АВР в СИ-НЕРДЖИ</td>
                  <td style={{ width: "50%" }}>{store.former_on}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Дата подписания АВР</td>
                  <td style={{ width: "50%" }}>{formatDate(store.data.signed_on)}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Дата передачи в СК подписанного АВР</td>
                  <td style={{ width: "50%" }}>{store.transfer_on}</td>
                </tr>
                {/* <tr>
                  <td style={{ width: "50%" }}>Контрольная дата оплаты АВР СК</td>
                  <td style={{ width: "50%" }}>{store.payment_on}</td>
                </tr> */}
                <tr>
                  <td style={{ width: "50%" }}>Дата получения оригинала подписанного АВР из СК</td>
                  <td style={{ width: "50%" }}>{store.original_receipt_on}</td>
                </tr>
                {/* <tr>
                  <td style={{ width: "50%" }}>Фактическая дата оплаты АВР</td>
                  <td style={{ width: "50%" }}>{store.actual_payment_on}</td>
                </tr> */}
              </tbody>
            </table>
            <br />
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="2">Документы</th>
                </tr>
              </thead>
              <tbody>
                {store.data.documents.map((i, index) => (
                  <tr key={`avr-document-${index}`}>
                    <td style={{ width: "50%" }}>
                      {i.attachments
                        .filter((i) => i.file.url)
                        .map((attachment) => (
                          <a
                            key={`attachment-${attachment.id}`}
                            href={attachment.file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {decodeURI(attachment.file.url.replace(/^.*[\\|/]/, ""))}
                            <br />
                          </a>
                        ))}
                    </td>
                    <td style={{ width: "50%" }}>
                      {String(i.document_kind.id) === "18" ? i.document_kind_other : i.document_kind.name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="2">Связанные АВР</th>
                </tr>
              </thead>
              <tbody>
                {store.data.avr_relations.map((avr_relation) => (
                  <tr>
                    <td style={{ width: "50%" }}>
                      {avr_relation.relation_avr?.id ? (
                        <Link target="_blank" to={`/avrs/${avr_relation.relation_avr.id}`}>
                          {avr_relation.relation_avr.number}
                        </Link>
                      ) : (
                        "не выбран"
                      )}
                    </td>
                    <td style={{ width: "50%" }}>{avr_relation.comment}</td>
                  </tr>
                ))}
                {store.data.relation_avrs.map((avr_relation) => (
                  <tr>
                    <td style={{ width: "50%" }}>
                      {avr_relation.relation_avr?.id ? (
                        <Link target="_blank" to={`/avrs/${avr_relation.relation_avr.id}`}>
                          {avr_relation.relation_avr.number}
                        </Link>
                      ) : (
                        "не выбран"
                      )}
                    </td>
                    <td style={{ width: "50%" }}>{avr_relation.comment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Tab>
          <Tab eventKey="finances" title="Финансовый блок">
            <br />
            <Card>
              <Button url={`/avrs/${store.data.id}/edit`}>Редактировать</Button>&nbsp;
              <Button url={`#`}>Печать</Button>&nbsp;
              <Button url={`#`}>Запрос в СК???</Button>&nbsp;
              <p>
                <br />
                Статус АВР&nbsp;{status(store.data.status.value)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Статус оплаты&nbsp;
                {paymentStatus(store.data)}
              </p>
            </Card>
            <h4 className="text-center">Все расчеты указаны в рублях</h4>
            <br />

            <table className="table table-hover">
              <thead>
                <tr>
                  <th colSpan="2">Начисления КВ по АВР</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Дата подписания АВР</td>
                  <td>{formatDate(store.data.signed_on)}</td>
                </tr>
                <tr>
                  <td>Сумма КВ по АВР</td>
                  <td>{store.data.kv_sum}</td>
                </tr>
                <tr>
                  <td>Контрольная дата оплаты по АВР</td>
                  <td>{store.data.signed_on && formatDate(addDays(store.data.signed_on, 15))}</td>
                </tr>
                <tr>
                  <td>Оплаченное КВ (итого)</td>
                  <td>{formatMoney(Math.max(0, paidKv - sumByReturnments))}</td>
                </tr>
                <tr>
                  <td>Задолженность по АВР</td>
                  <td>{formatMoney(Math.max(0, kvSumByAvr - paidKv - sumByReturnments))}</td>
                </tr>
                <tr>
                  <td>Фактическая дата оплаты АВР</td>
                  <td>
                    {kvSumByAvr - paidKv <= 0 &&
                      (store.data.payment_kind.value.toString() === "0"
                        ? formatDate(store.data.avr_installments[0]?.payment_on)
                        : formatDate(store.data.avr_installments[store.data.avr_installments.length - 1]?.payment_on))}
                  </td>
                </tr>
                <tr>
                  <td>Операции ДДС</td>
                  <td>
                    {store.data.operations.map((operation, index) => [
                      index > 0 && ", ",
                      <Link target="_blank" to={`/operations/${operation.id}`}>
                        {operation.id}
                      </Link>
                    ])}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <table className="table table-hover">
              <thead>
                <tr>
                  <th>Контрольная дата оплаты</th>
                  <th>Сумма очередного платежа</th>
                  <th>Дата факт. оплаты АВР</th>
                  <th>Сумма опл. КВ</th>
                </tr>
              </thead>
              <tbody>
                {store.data.avr_installments.map((i) => (
                  <tr>
                    <td>{formatDate(i.control_payment_on)}</td>
                    <td>{formatMoney(i.payment_cost)}</td>
                    <td>???</td>
                    <td>???</td>
                  </tr>
                ))}
              </tbody>
            </table> */}
          </Tab>
        </Tabs>
      </Fragment>
    )
  }
}

export default observer(Show)
