import React from "react"
import Document from "./Document"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  file: null
}

const Documents = ({ name }) => (
  <NestedFields name={name} label="Документы" component={Document} defaultValues={defaultValues} />
)

export default Documents
