import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { FieldArray, withFormik } from "formik"
import { withRouter } from "react-router"
import { TextArea, TextInput, SelectInput, Submit } from "components/UI"
import Risks from "./Risks"

const bsoOptions = [
  { label: "Бланк полиса", value: "policy" },
  { label: "Бланк квитанции А7", value: "receipt" }
]

const Form = ({ store, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="name" label="Название страхового продукта" />
    <SelectInput name="bso" label="БСО" options={bsoOptions} />
    <FieldArray name="risks" render={props => <Risks {...props} store={store} />} />
    <TextArea name="comments" label="Комментарий" />
    <Submit>Сохранить</Submit>
  </form>
)

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  })
)(Form)
