import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Bill = types.model("Bill", {
  id: types.maybeNull(types.integer),
  short_name: types.maybeNull(types.string)
})

const BudgetFotItem = types.model("BudgetFotItem", {
  id: types.maybeNull(types.integer),
  month: types.maybeNull(types.number),
  fot: types.maybeNull(types.string),
  o_two: types.maybeNull(types.string),
})

const BudgetFot = types.model("BudgetFot", {
  id: types.maybeNull(types.integer),
  budget_id: types.maybeNull(types.integer),
  status: types.maybeNull(newCustomEnum),
  user: types.maybeNull(User),
  bill: types.maybeNull(Bill),
  comment: types.maybeNull(types.string),
  budget_fot_items: types.maybeNull(types.array(BudgetFotItem))
})

export { BudgetFot }
