import React, { useState } from "react"
import { observer } from "mobx-react"
import { getIn, useFormikContext } from "formik"
import { Link } from "react-router-dom"
import { Button } from "components/UI"
import Modal from "./Modal"

const RelationContract = ({ name }) => {
  const { values, setFieldValue } = useFormikContext()

  const relation_contract = getIn(values, name)

  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const onDestroy = () => {
    setFieldValue(name, { id: null, number: null })
  }
  
  return (
    <div className="form-group">
      <label>
        Связанный договор:&nbsp;
        {relation_contract?.id ? (
          <Link target="_blank" to={`/contracts/${relation_contract.id}`}>
            {relation_contract.number}
          </Link>
        ) : (
          "не выбран"
        )}
      </label>
      <fieldset>
        <Button onClick={openModal}>Выбрать</Button>
        &nbsp;
        <Button onClick={onDestroy} variant="danger">
          Удалить
        </Button>
      </fieldset>
      <Modal isOpen={isOpen} closeModal={closeModal} setFieldValue={setFieldValue} name={name} />
    </div>
  )
}

export default observer(RelationContract)
