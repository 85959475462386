import React, { useEffect } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { SelectInput, Submit, TextInput } from "components/UI"
import CarBrandStore from "stores/CarBrandStore/List"

const carBrandStore = CarBrandStore.create()

const Form = ({ handleSubmit }) => {
  useEffect(() => {
    carBrandStore.fetch({ limit: 0 })
  }, [])
  const carBrandOptions = carBrandStore.carBrandOptions
  return (
    <form onSubmit={handleSubmit}>
      <SelectInput name="car_brand.id" label="Бренд авто" options={carBrandOptions} isSearchable required />
      <TextInput name="value" label="Название" required />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  }),
  observer
)(Form)
