import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import AgencyContractStore from "stores/AgencyContractStore/List"
import Breadcrumbs from "pages/Insurers/components/Breadcrumbs"
import Tabs from "pages/Insurers/components/Tabs"
import { BreadcrumbItem, Card, Paginate } from "components/UI"
import Table from "./components/Table"
import Filter from "./components/Filter"

const insurerStore = InsurerStore.create()
const agencyContractStore = AgencyContractStore.create()

const List = (props) => {
  const { insurer_id } = props.match.params

  useEffect(() => {
    insurerStore.fetch({ id: insurer_id })
    agencyContractStore.fetch({ ...getParams(), insurer_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/insurers/${insurer_id}`}>{insurerStore.name}</BreadcrumbItem>
        <BreadcrumbItem active>Агентские договоры</BreadcrumbItem>
      </Breadcrumbs>
      <Tabs {...{ id: insurer_id }} />
      <br />
      {/* <Card>
        <Button url={`/insurers/${insurer_id}/agency_contracts/new`}>Создать</Button>
      </Card> */}
      <Card>
        <Filter store={agencyContractStore} />
      </Card>
      <Table store={agencyContractStore} insurer_id={insurer_id} />
      <Paginate store={agencyContractStore} additionalParams={{ insurer_id: insurer_id }} />
    </Fragment>
  )
}

export default observer(List)
