import React, { Fragment, useContext } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import { observer } from "mobx-react"
import SpTab from "./SpTab"
import KvTab from "./KvTab"
import FixKvTab from "./FixKvTab"
import ReturnmentTab from "./ReturnmentTab"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

const FinanceTab = (props) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <Fragment>
      <br />
      <Tabs defaultActiveKey="sp">
        <Tab eventKey="sp" title="Страховая премия / КВ">
          <SpTab {...props} />
        </Tab>
        <Tab eventKey="kv" title="Доп. КВ">
          <KvTab {...props} />
        </Tab>
        <Tab eventKey="fix_kv" title="Фикс. КВ" disabled={![5, 23, 10, 48].includes(currentUser.id) && currentUser.subdivision.id !== 7}>
          <FixKvTab {...props} />
        </Tab>
        <Tab eventKey="returnment" title="Возвраты СП">
          <ReturnmentTab {...props} />
        </Tab>
      </Tabs>
    </Fragment>
  )
}

export default observer(FinanceTab)
