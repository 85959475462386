import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"
import customDate from "types/customDate"

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Organization = types.model("Organization", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  organizationable_id: types.maybeNull(types.integer),
  organizationable_type: types.maybeNull(types.string)
})

const RecipientCargo = types.model("Recipient", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  kind_other: types.maybeNull(types.string)
})

const Recipient = types.model("Recipient", {
  id: types.maybeNull(types.integer),
  to_organization: types.maybeNull(Organization)
})

const Courier = types.model("Courier", {
  id: types.maybeNull(types.integer),
  status: types.maybeNull(newCustomEnum),
  user: types.maybeNull(User),
  from_date_on: types.maybeNull(customDate),

  from_organization: types.maybeNull(Organization),

  // to_organization: types.maybeNull(Organization),
  // to_contact: types.maybeNull(types.string),
  // to_phone: types.maybeNull(types.string),
  // to_address: types.maybeNull(types.string),
  // to_comment: types.maybeNull(types.string),

  recipients: types.maybeNull(types.array(Recipient, []))
})

export default Courier
