import convertErrorsObject from "utils/convertErrorsObject"

const success = response => response

const error = error => {
  if (422 === error.response.status) {
    error.response.data.errors = convertErrorsObject(error.response.data.errors)
  }
  return Promise.reject(error)
}

export default [success, error]
