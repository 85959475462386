import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import customEnum from "types/customEnum"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"
import { Avr } from "./Avr"
import toOptions from "utils/newToOptions"
import format from "date-fns/format"
import parseMoney from "utils/parseMoney"

const Filter = types.model("Filter", {
  by_channel: types.maybeNull(customEnum),
  by_insurer: types.maybeNull(customEnum),
  by_date_on_start: types.maybeNull(customDate),
  by_date_on_finish: types.maybeNull(customDate),
  by_number: types.maybeNull(types.union(types.integer, types.string)),
  by_original_status: types.maybeNull(types.integer),
  by_payment_status: types.maybeNull(types.integer),
  by_status: types.maybeNull(types.integer),
  by_kv_sum: types.maybeNull(types.string)
})

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, []),
  payment_statuses: types.array(newCustomEnum, []),
  original_statuses: types.array(newCustomEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number),
  statistics: types.frozen()
})

const metaDefaultValue = {
  filter: {
    by_channel: { id: null, name: null },
    by_insurer: { id: null, name: null },
    by_date_on_start: null,
    by_date_on_finish: null
  },
  filters_options: {
    statuses: [],
    payment_statuses: [],
    original_statuses: []
  },
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  },
  statistics: []
}

const AvrStore = types
  .model("Avr", {
    data: types.array(Avr, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { statuses, payment_statuses, original_statuses } = self.meta.filters_options
      return {
        statusesOptions: toOptions(statuses),
        paymentStatusesOptions: toOptions(payment_statuses),
        originalStatusesOptions: toOptions(original_statuses)
      }
    },

    get avrOptions() {
      return self.data.map(({ id, kind, organization_name, f_name, l_name, p_name }) => {
        return {
          label: kind === "individual" ? [l_name, f_name, p_name].join(" ") : organization_name,
          value: id
        }
      })
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_date_on_start, by_date_on_finish, by_kv_sum } = params.filter
        if (by_date_on_start) {
          params.filter = { ...params.filter, by_date_on_start: format(by_date_on_start, "yyyy-MM-dd") }
        }
        if (by_date_on_finish) {
          params.filter = { ...params.filter, by_date_on_finish: format(by_date_on_finish, "yyyy-MM-dd") }
        }
        if (by_kv_sum) {
          params.filter = { ...params.filter, by_kv_sum: parseMoney(by_kv_sum) }
        }
      }

      return instance
        .get("/avrs", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/avrs/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default AvrStore
