import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetMotivationCoefficientsStore from "stores/BudgetMotivationCoefficientStore/List"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { Button, Card, Paginate, BreadcrumbItem } from "components/UI"
import BudgetStore from "stores/BudgetStore/One"
import Tabsss from "pages/Budgets/components/Tabs"
import Table from "./components/Table"
import TabsBlock from "pages/Budgets/containers/BudgetMotivationCoefficients/components/Tabs"

const budgetStore = BudgetStore.create()
const budgetMotivationCoefficientsStore = BudgetMotivationCoefficientsStore.create()

const List = (props) => {
  const { budget_id } = props.match.params
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetMotivationCoefficientsStore.fetch({ budget_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>{budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}</BreadcrumbItem>
        <BreadcrumbItem active>Коэффициенты</BreadcrumbItem>
        <BreadcrumbItem active>Коэф. мотивации</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      <Card>
        <Button url={`/budgets/${budget_id}/budget_motivation_coefficients/new`}>Создать</Button>
      </Card>
      <Table store={budgetMotivationCoefficientsStore} />
      <Paginate store={budgetMotivationCoefficientsStore} />
    </Fragment>
  )
}

export default observer(List)
