import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import CounterpartStore from "stores/CounterpartStore/List"

const counterpartStore = CounterpartStore.create()

const CounterpartSelect = ({ name = "counterpart", required = false, ...props }) => {
  const { values } = useFormikContext()
  const counterpart = getIn(values, name, {}) || {}

  useEffect(() => {
    counterpartStore.fetch({ order_by_name: "asc" })
  }, [])

  const counterpartOptions = counterpart.id ? [{ label: counterpart.name, value: counterpart.id }] : counterpartStore.counterpartOptions

  const loadCounterpartOptions = (inputValue) =>
    counterpartStore
      .fetch({ order_by_name: "asc", filter: { by_name: inputValue }, limit: 0 })
      .then(({ counterpartOptions }) => counterpartOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label="Контрагент"
        required={required}
        options={counterpartOptions}
        isSearchable
        loadOptions={loadCounterpartOptions}
        {...props}
      />
    </div>
  )
}

export default observer(CounterpartSelect)
