import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import state from "types/state"
import formatDate from "utils/formatDate"
import toOptions from "utils/toOptions"
import newToOptions from "utils/newToOptions"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"
import { prepare } from "./utils"
import { Contract } from "./Contract"
import SpSkRetention from "./SpSkRetention"
import CargoKind from "./CargoKind"
import CargoPrefabricated from "./CargoPrefabricated"
import CargoСombined from "./CargoСombined"

const FilterOptions = types.model("FilterOptions", {
  sp_paid_inflectors: types.array(customEnum, []),
  sp_sk_retentions: types.array(SpSkRetention, []),
  cargo_kinds: types.array(CargoKind, []),
  cargo_procedure_for_submitting_declarations: types.array(CargoKind, []),
  cargo_procedure_for_certificate_issues: types.array(CargoKind, []),
  cargo_procedure_for_sp_payments: types.array(CargoKind, []),
  cargo_prefabricateds: types.array(CargoPrefabricated, []),
  cargo_combineds: types.array(CargoСombined, []),
  contract_property_kinds: types.array(customEnum, []),
  contract_property_inflectors: types.array(customEnum, []),
  use_bsos: types.array(newCustomEnum, []),
  customer_inviter_is_subagents: types.array(newCustomEnum, []),
  contract_auto_risks: types.array(newCustomEnum, []),
  contract_auto_number_kinds: types.array(newCustomEnum, []),
  contract_accident_risks: types.array(newCustomEnum, []),
  contract_osgop_kinds: types.array(newCustomEnum, []),
  contract_go_estate_objects: types.array(newCustomEnum, []),
  contract_statuses: types.array(newCustomEnum, []),
  contract_kinds: types.array(newCustomEnum, []),
  cargo_currencies: types.array(newCustomEnum, []),
  transport_kinds: types.array(customEnum, []),
  scan_kinds: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {}
}

const ContractStore = types
  .model("ContractStore", {
    data: types.optional(Contract, {
      status: {
        value: 0
      },
      kind: {
        value: 0
      },
      additional_agreement_kind: {
        value: 0
      },
      is_need_to_prolongation: true,
      avrs: [],
      contract_returnment_avrs: [],
      cargos: [],
      channel: { id: null, name: "" },
      fix_kv_channel: { id: null, name: "" },
      client: { id: null, name: "", ownership_kind: {} },
      insurance_kind: { id: null, name: "" },
      contract_installments: [
        {
          sp_payment_on: null,
          sp_payment_cost: null,
          sp_payment_kind: { value: 0 },
          sp_sk_payment_on: null,
          sp_sk_payment_cost: null,
          sp_sk_payment_form: { value: 0 },
          sp_sinergy_payment_on: null,
          sp_sinergy_payment_cost: null,
          sp_sinergy_payment_form: { value: 0 }
        }
      ],
      bsos: [],
      contract_returnments: [],
      bsos: [],
      insurance_product: { id: null, name: "" },
      insurer: { id: null, name: "", contacts: [] },
      insurer_contact: { id: null, f_name: "" },
      sp_paid_inflector: { id: 1, name: "" },
      sp_paid: { id: 0, name: "" },
      sp_paid_client_form: { id: 0, name: "" },
      sp_paid_form: { id: 0, name: "" },
      sp_sk_retention: { id: 0, name: "" },
      cargo_kind: { id: 0, name: "" },
      cargo_procedure_for_submitting_declaration: { id: 0, name: "" },
      cargo_procedure_for_certificate_issue: { id: 0, name: "" },
      cargo_procedure_for_sp_payment: { id: 0, name: "" },
      cargo_prefabricated: { id: 0, name: "" },
      cargo_combined: { id: 0, name: "" },
      subagent: { id: null, name: "" },
      transport_kinds: [],
      contract_properties: [],
      user: { id: null, name: "", phones: [], emails: [] },
      subdivision: { id: null, name: "" },
      use_bso: { value: 0 },
      customer_inviter_is_subagent: { value: 0 },
      parent: { id: null, number: null },
      child: { id: null, number: null },
      kvs: [
        {
          id: null,
          rate: "0",
          by_contract: "0",
          by_paid_sp: "0",
          channel: { id: null }
        }
      ],
      // documents: [],
      // scans: [],
      contract_accidents: [],
      contract_auto: {
        risk: {
          value: 0
        },
        number_kind: {
          value: 0
        },
        car_brand: {
          value: 0
        },
        car_model: {
          value: 0
        }
      },
      contract_osgop: {
        kind: {
          value: 0
        }
      },
      contract_go_estate: {
        object: {
          value: 0
        }
      },
      contract_dms: {
        kind: {
          value: 0
        }
      },
      contract_mortgage: {
        risks: []
      },
      contract_proceeding: {
        status: {
          value: 'claim'
        },
        side: {
          value: 'plaintiff'
        },
        result: {
          value: 'in_work'
        }
      },
      contract_bankruptcy: {
        status: {
          value: 'preparation'
        },
        side: {
          value: 'debtor'
        },
        result: {
          value: 'in_work'
        }
      },
      versions: [],
      tracking: [],
      is_without_kv: false
      // fix_kv_costs: []
    }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(state)
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const {
        sp_paid_inflectors,
        sp_sk_retentions,
        cargo_kinds,
        cargo_procedure_for_submitting_declarations,
        cargo_procedure_for_certificate_issues,
        cargo_procedure_for_sp_payments,
        cargo_prefabricateds,
        cargo_combineds,
        contract_property_kinds,
        contract_property_inflectors,
        use_bsos,
        customer_inviter_is_subagents,
        contract_auto_risks,
        contract_auto_number_kinds,
        contract_accident_risks,
        contract_osgop_kinds,
        contract_go_estate_objects,
        contract_statuses,
        contract_kinds,
        cargo_currencies,
        transport_kinds,
        scan_kinds
      } = self.meta.filters_options
      return {
        spPaidInflectorOptions: toOptions(sp_paid_inflectors),
        spSkRetentionOptions: toOptions(sp_sk_retentions),
        cargoKindOptions: toOptions(cargo_kinds),
        cargoProcedureForSubmittingDeclarationOptions: toOptions(cargo_procedure_for_submitting_declarations),
        cargoProcedureForCertificateIssueOptions: toOptions(cargo_procedure_for_certificate_issues),
        cargoProcedureForSpPaymentOptions: toOptions(cargo_procedure_for_sp_payments),
        cargoPrefabricatedOptions: toOptions(cargo_prefabricateds),
        cargoCombinedOptions: toOptions(cargo_combineds),
        contractPropertyKindOptions: toOptions(contract_property_kinds),
        contractPropertyInflectorOptions: toOptions(contract_property_inflectors),
        useBsosOptions: newToOptions(use_bsos),
        customerInviterIsSubagentsOptions: newToOptions(customer_inviter_is_subagents),
        contractAutoRisksOptions: newToOptions(contract_auto_risks),
        contractAutoNumberKindsOptions: newToOptions(contract_auto_number_kinds),
        contractAccidentRisksOptions: newToOptions(contract_accident_risks),
        contractOsgopKindsOptions: newToOptions(contract_osgop_kinds),
        contractGoEstateObjectsOptions: newToOptions(contract_go_estate_objects),
        contractStatusesOptions: newToOptions(contract_statuses),
        contractKindsOptions: newToOptions(contract_kinds),
        cargoCurrenciesOptions: newToOptions(cargo_currencies),
        transportKindsOptions: toOptions(transport_kinds),
        scanKinds: newToOptions(scan_kinds)
      }
    },

    get id() {
      return self.data.id
    },

    get status() {
      return self.data.status
    },

    get insurance_kind() {
      return self.data.insurance_kind
    },

    get insurer() {
      return self.data.insurer
    },

    get insurer_contact() {
      return self.data.insurer_contact
    },

    get insurance_product() {
      return self.data.insurance_product
    },

    get channel() {
      return self.data.channel
    },

    get client() {
      return self.data.client
    },

    get contractor() {
      return self.data.contractor
    },

    get customer_inviter() {
      return self.data.customer_inviter
    },

    get customer_inviter_is_subagent() {
      return self.data.customer_inviter_is_subagent
    },

    get kind() {
      return self.data.kind
    },

    get conclusion_on() {
      const { conclusion_on } = self.data
      return conclusion_on && formatDate(conclusion_on)
    },

    get number() {
      return self.data.number
    },

    get polis() {
      return self.data.polis
    },

    get receipt() {
      return self.data.receipt
    },

    get start_on() {
      const { start_on } = self.data
      return start_on && formatDate(start_on)
    },

    get finish_on() {
      const { finish_on } = self.data
      return finish_on && formatDate(finish_on)
    },

    get duration() {
      return self.data.duration
    },

    get user() {
      return self.data.user
    },

    get comment() {
      return self.data.comment
    },

    get contract_installments() {
      return self.data.contract_installments
    },

    get contract_returnments() {
      return self.data.contract_returnments
    },

    get sp_accrual_cost() {
      return self.data.sp_accrual_cost
    },

    get subagent() {
      return self.data.subagent
    },

    get avrs() {
      return self.data.avrs
    },

    get contract_properties() {
      return self.data.contract_properties
    },

    get sp_paid_inflector() {
      return self.data.sp_paid_inflector
    },

    get sp_accrual_control_on() {
      return self.data.sp_accrual_control_on
    },

    get bsos() {
      return self.data.bsos
    },

    get kvs() {
      return self.data.kvs
    },

    get parent() {
      return self.data.parent
    },

    get child() {
      return self.data.child
    },

    get documents() {
      return self.data.documents
    },

    get scans() {
      return self.data.scans
    },

    get contract_auto() {
      return self.data.contract_auto
    },

    get contract_accidents() {
      return self.data.contract_accidents
    },

    get contract_go_transport() {
      return self.data.contract_go_transport
    },

    get contract_go_estate() {
      return self.data.contract_go_estate
    },

    get contract_osgop() {
      return self.data.contract_osgop
    },

    get contract_mortgage() {
      return self.data.contract_mortgage
    },

    get contract_proceeding() {
      return self.data.contract_proceeding
    },

    get contract_bankruptcy() {
      return self.data.contract_bankruptcy
    },

    get versions() {
      return self.data.versions
    },

    get subdivision() {
      return self.data.subdivision
    },

    get is_need_to_prolongation() {
      return self.data.is_need_to_prolongation
    },

    get cancel_prolongation_cause() {
      return self.data.cancel_prolongation_cause
    },

    get couriers() {
      return self.data.couriers
    }

    // get documents() {
    //   return self.data.documents
    // },

    // get insurance_premium() {
    //   return self.data.insurance_premium
    // }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, documents, channel, insurer, ...attributes } = params
      return instance
        .get(`/contracts/${id}`, { attributes })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")

      return instance
        .get(`/contracts/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post("/contracts", { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/contracts/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const {
        status,
        data: { data }
      } = response
      if (status === 200) applySnapshot(self, { ...self, data })
      return self
    },

    applyMeta(response) {
      const {
        status,
        data: { meta }
      } = response
      if (status === 200) applySnapshot(self, { ...self, meta })
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    },

    setPicture(index, file) {
      self.pictureFiles[index] = file
    }
  }))

export default ContractStore
