import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { DocumentKind } from "./DocumentKind"
import newCustomEnum from "types/newCustomEnum"
import newToOptions from "utils/newToOptions"
import { prepare } from "./utils"

const FilterOptions = types.model("FilterOptions", {
  bindings: types.array(newCustomEnum, []),
  client_bindings: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    bindings: [],
    client_bindings: []
  }
}

const DocumentKindStore = types
  .model("DocumentKindStore", {
    data: types.optional(DocumentKind, { name: "", binding: { value: 0 }, client_binding: { value: 0 } }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get name() {
      return self.data.name
    },

    get filtersOptions() {
      const { bindings, client_bindings } = self.meta.filters_options
      return { bindings: newToOptions(bindings), client_bindings: newToOptions(client_bindings) }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/document_kinds/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/document_kinds/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/document_kinds`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/document_kinds/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    applyData(response) {
      const {
        status,
        data: { data }
      } = response
      if (status === 200) applySnapshot(self, { ...self, data })
      return self
    },

    applyMeta(response) {
      const {
        status,
        data: { meta }
      } = response
      if (status === 200) applySnapshot(self, { ...self, meta })
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default DocumentKindStore
