import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import ClientStore from "stores/ClientStore/List"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"
import Filter from "./components/Filter"

const clientStore = ClientStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    clientStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/clients/new">Создать</Button>
      </Card>
      <Card>
        <Filter store={clientStore} />
      </Card>
      <Table store={clientStore} />
      <Paginate store={clientStore} />
    </Fragment>
  )
}

export default observer(List)
