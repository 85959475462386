import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { DebitKpi } from "./DebitKpi"

const DebitKpiStore = types
  .model("DebitKpiStore", {
    data: types.optional(DebitKpi, { name: "" }),
    meta: types.optional(types.map(types.number), {}),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views(self => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },
    
    get name() {
      return self.data.name
    },

    get comment() {
      return self.data.comment
    }
  }))
  .actions(self => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/debit_kpis/${id}`, { options })
        .then(response => self.resetStore(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      return instance
        .post(`/debit_kpis`, { data: params })
        .then(response => self.resetStore(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const { id } = params
      return instance
        .put(`/debit_kpis/${id}`, { data: params })
        .then(response => this.resetStore(response))
        .then(response => this.setState("done", response))
        .catch(error => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default DebitKpiStore
