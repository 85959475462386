import React, { Fragment } from "react"
import { FileInput } from "components/UI"
import instance from "connection/instance"
import { useFormikContext } from "formik"

const Document = ({ prefix = null }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const { setFieldValue } = useFormikContext()

  const onFileInputChange = (e) => {
    const formData = new FormData()
    formData.append("data[file]", e.target.files[0])
    formData.append("data[documentable_type]", "Shipment")
    instance
      .post("/documents", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(({ data }) => {
        setFieldValue(path("id"), data.data.id)
      })
  }

  return (
    <Fragment>
      <FileInput name={path("file.url")} label="Файл" onChange={onFileInputChange} />
    </Fragment>
  )
}
export default Document
