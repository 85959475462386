import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import ParcelStore from "stores/ParcelStore/One"
import Form from "pages/Contracts/containers/Parcels/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"

const contractStore = ContractStore.create()
const parcelStore = ParcelStore.create()

const Edit = (props) => {
  const { id, contract_id } = props.match.params
  useEffect(() => {
    contractStore.fetch({ id: contract_id })
    parcelStore.fetch({ id, contract_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contract_id}/parcels`}>Отгрузки</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        <BreadcrumbItem active>{parcelStore.number}</BreadcrumbItem>
      </Breadcrumbs>
      <ContractFormContext.Provider value={{ contractStore }}>
        <Form {...{ store: parcelStore, contractStore }} />
      </ContractFormContext.Provider>
    </Fragment>
  )
}

export default observer(Edit)
