const prepare = (params = {}) => {
  const {
    name,
    mol: { value: mol },
    status: { value: status },
    position: { id: position_id },
    subdivision: { id: subdivision_id },
    channel: { id: channel_id },
    orders,
    ...other
  } = params
  return { ...other, mol, status: parseInt(status), position_id, subdivision_id, channel_id }
}

export { prepare }
