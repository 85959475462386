import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { AsyncSelectInput, Submit, TextArea, TextInput } from "components/UI"
import BsoBatches from "./BsoBatches"
import BsoKindFormContext from "contexts/BsoKindFormContext"
import InsuranceKindSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsuranceKindSelect"

const Form = ({ handleSubmit, values, store, insurerStore }) => {
  const insurerOptions = (() => {
    const { id, name } = values.insurer
    return id ? [{ label: name, value: id }] : insurerStore.insurerOptions
  })()

  const loadInsurerOptions = (input) =>
    insurerStore.fetch({ filter: { by_name: input }, limit: 0 }).then(({ insurerOptions }) => insurerOptions)

  return (
    <BsoKindFormContext.Provider value={{ store }}>
      <form onSubmit={handleSubmit}>
        <AsyncSelectInput
          name="insurer.id"
          label="Cтраховая компания"
          options={insurerOptions}
          isSearchable
          loadOptions={loadInsurerOptions}
        />
        <InsuranceKindSelect />
        <TextInput name="name" label="Вид БСО" />
        <BsoBatches store={store} />
        <TextInput name="numbers_count" label="Количество цифр в номере БСО" />
        <TextArea name="comment" label="Комментарий" />
        <Submit>Сохранить</Submit>
      </form>
    </BsoKindFormContext.Provider>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data.toJSON() }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  }),
  observer
)(Form)
