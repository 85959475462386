import { types } from "mobx-state-tree"

const DebitKpi = types.model("DebitKpi", {
  id: types.maybeNull(types.integer),
  average_level: types.maybeNull(types.string),
  turnover_rate: types.maybeNull(types.integer),
  delay_period: types.maybeNull(types.integer),
  exposure_period: types.maybeNull(types.integer)
})

export { DebitKpi }
