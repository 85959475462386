import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import DestinationStore from "stores/DestinationStore/List"
import { Button, Card, Paginate, Modal, ModalHeader, ModalBody, ModalFooter } from "components/UI"
import Table from "./Table"
import Filter from "./Filter"

const destinationStore = DestinationStore.create()

const DestinationModal = ({ isOpen, closeModal, onSet }) => {
  useEffect(() => {
    destinationStore.fetch()
  }, [isOpen])

  const [selected, setSelected] = useState({ id: null })

  const onSave = () => {
    onSet(selected)

    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Типовой адресат</ModalHeader>
      <ModalBody>
        <Card>
          <Filter store={destinationStore} />
        </Card>
        <Table store={destinationStore} selected={selected} setSelected={setSelected} />
        <Paginate store={destinationStore} params={{}} saveToStore={false} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onSave}>Сохранить</Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(DestinationModal)
