import React from "react"
import Recipient from "./Recipient"
import NestedFields from "components/UI/NestedFields"
import { useFormikContext } from "formik"

const Recipients = ({ isDisabled }) => {
  const { values } = useFormikContext()

  const defaultValues = {
    to_date_on: values.urgency.value === 0 ? null : new Date(),
    to_organization: null,
    to_contact: null,
    to_phone: null,
    to_address: null,
    to_comment: null,
    to_time_kind: { value: 1 },
    recipient_cargos: [
      {
        kind: { value: 0 },
        kind_other: null
      }
    ]
  }

  return (
    <NestedFields
      name="recipients"
      label="Получатели"
      component={Recipient}
      defaultValues={defaultValues}
      disabledAdd={isDisabled}
      disabledDestroy={isDisabled}
    />
  )
}

export default Recipients
