import React from "react"
import { Destroy, Edit } from "components/UI"
import { withRouter } from "react-router-dom"

const Table = ({ collection, destroyProjectWithFetch, history }) => {
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Основной</th>
          <th scope="col">Суб-проект</th>
          <th scope="col">Комментарий</th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {collection.data.map((i, index) => (
          <tr key={`projects-${index}`}>
            <td>{i.id}</td>
            <td>{i.kind?.value === "main" ? i.main : i.parent.main}</td>
            <td>{i.kind?.value === "sub" && i.sub}</td>
            <td>{i.comment}</td>
            <td>
              <Edit url={`/projects/${i.id}/edit`} />
              <Destroy action={() => destroyProjectWithFetch(i.id)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default withRouter(Table)
