import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { DateInput, TextInput, CurrencyInput } from "components/UI"
import formatDate from "utils/formatDate"

const SalaryChangeTab = ({ store, handleSubmit, values, setFieldValue, ...props }) => {
  return (
    <Fragment>
      <br />
      <DateInput name="order_salary_change.date_on" label="Дата изменения оклада" required />
      <CurrencyInput name="order_salary_change.salary" label="Оклад" required />
      <TextInput name="order_salary_change.number" label="Номер ДС к трудовому договора" />
      <DateInput
        name="date_on"
        disabled
        label="Дата ДС к трудовому договору"
        value={formatDate(values.date_on)}
      />
      <TextInput name="order_salary_change.comment" label="Комментарий" />
    </Fragment>
  )
}

export default observer(SalaryChangeTab)
