import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import ActivityKindStore from "stores/ActivityKindStore/List"

const activityKindStore = ActivityKindStore.create()

const ActivityKindSelect = ({ name = "holding", required = false, label = "Холдинг / Группа компаний", ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    activityKindStore.fetch()
  }, [])

  const getOptions = () => {
    const { activityKindOptions } = activityKindStore
    const { id: value, name: label } = getIn(values, name) || { id: null, name: null }
    if (value) {
      activityKindOptions.concat([{ label, value }])
    }
    return activityKindOptions
  }

  const activityKindOptions = getOptions()

  const loadActivityKindOptions = (value) =>
    activityKindStore
      .fetch({ filter: { by_name: value }, limit: 0 })
      .then(({ activityKindOptions }) => activityKindOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={activityKindOptions}
        required={required}
        isSearchable
        loadOptions={loadActivityKindOptions}
        {...props}
      />
    </div>
  )
}

export default observer(ActivityKindSelect)
