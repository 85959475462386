import React from "react"
import { observer } from "mobx-react"
import { useFormikContext } from "formik"
import { DateInput } from "components/UI"
import caclulateDurationInDays from "utils/caclulateDurationInDays"

const StartOnInput = ({ disabled = false, required = false }) => {
  const {
    values: { insurance_kind_id, finish_on },
    setFieldValue
  } = useFormikContext()

  const onChange = (start_on) => {
    setFieldValue("start_on", start_on)
    setFieldValue("duration", caclulateDurationInDays(start_on, finish_on))
    setFieldValue("sp_accrual_control_on", start_on)
  }

  return (
    <DateInput
      name="start_on"
      label="Дата начала"
      required={required}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

export default observer(StartOnInput)
