import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import Form from "pages/Budgets/components/Form"
import { BreadcrumbItem } from "components/UI"

const budgetStore = BudgetStore.create()

const Add = () => {
  useEffect(() => {
    budgetStore.reset()
    budgetStore.fetchMeta()
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={budgetStore} />
    </Fragment>
  )
}

export default observer(Add)
