import React, { Fragment } from "react"
import {
  Button,
  Card,
  CardTitle,
  CurrencyInput,
  DateInput,
  RadioButtonGroup,
  RadioInput,
  CheckboxInput
} from "components/UI"
import parseMoney from "utils/parseMoney"

const ContractReturnment = ({ index, contract_returnment, store, form, h: { replace, remove } }) => {
  const { sp_payment_kinds, sp_sinergy_payment_forms } = store.filtersOptions

  if (contract_returnment._destroy) return null

  const onDestroyClick = () => {
    contract_returnment.id ? replace(index, { ...contract_returnment, _destroy: true }) : remove(index)
    form.setFieldValue(
      "sp_accrual_cost",
      form.values.contract_returnments.reduce((a, b) => a + (b._destroy ? 0 : parseMoney(b.sp_payment_cost)), 0) -
        parseMoney(contract_returnment.sp_payment_cost)
    )
  }

  const hasAvr = form.values.contract_installments.some((i) => i.avrs.length > 0)

  return (
    <div key={`contract_returnments.${index}`}>
      <Fragment>
        <h5>Платеж № {index + 1}</h5>
        <DateInput
          name={`contract_returnments.${index}.sp_payment_on`}
          label="Дата очередного платежа СП (возврата)"
          disabled={
            (form.values.contract_returnments[index] &&
              form.values.contract_returnments[index].avrs.some((i) => i.payment_status.value === 1)) ||
            hasAvr
          }
        />
        <CurrencyInput
          name={`contract_returnments.${index}.sp_payment_cost`}
          label="Сумма очередного платежа СП (возврата)"
          disabled={
            (form.values.contract_returnments[index] &&
              form.values.contract_returnments[index].avrs.some((i) => i.payment_status.value === 1)) ||
            hasAvr
          }
        />
        <div className={"form-group"}>
          <CheckboxInput
            name={`contract_returnments.${index}.is_payments_equal`}
            label="Поставьте флажок, если опл. СП равна нач. СП"
            onChange={(e) => {
              form.setFieldValue(`contract_returnments.${index}.is_payments_equal`, e.target.checked)
              if (e.target.checked) {
                form.setFieldValue(
                  `contract_returnments.${index}.sp_sk_payment_cost`,
                  form.values.contract_returnments[index].sp_payment_cost
                )
              }
            }}
            disabled={
              form.values.contract_returnments[index] &&
              form.values.contract_returnments[index].avrs.some((i) => i.payment_status.value === 1)
            }
          />
          <br />
        </div>
      </Fragment>
      {form.values.subagent.id && (
        <Fragment>
          <CurrencyInput
            name={`contract_returnments.${index}.subagent_cost`}
            label="Сумма возврата КВ субагентом"
            disabled={
              form.values.contract_returnments[index] &&
              form.values.contract_returnments[index].avrs.some((i) => i.payment_status.value === 1)
            }
          />
          <DateInput
            name={`contract_returnments.${index}.subagent_cost_planned_on`}
            label="Планируемая дата возврата КВ субагентом"
            disabled={
              form.values.contract_returnments[index] &&
              form.values.contract_returnments[index].avrs.some((i) => i.payment_status.value === 1)
            }
          />
          <DateInput
            name={`contract_returnments.${index}.subagent_cost_fact_on`}
            label="Фактическая дата возврата КВ субагентом"
            disabled={
              form.values.contract_returnments[index] &&
              form.values.contract_returnments[index].avrs.some((i) => i.payment_status.value === 1)
            }
          />
        </Fragment>
      )}
      <Card>
        <CardTitle>СП возвращённое</CardTitle>
        <RadioButtonGroup
          label="Способ возврата СП"
          name={`contract_returnments.${index}.sp_payment_kind.value`}
          disabled={
            (form.values.contract_returnments[index] &&
              form.values.contract_returnments[index].avrs.some((i) => i.payment_status.value === 1)) ||
            hasAvr
          }
        >
          {sp_payment_kinds.map(({ label, value }) => (
            <RadioInput key={`contract_returnments.${index}.sp_payment_kind_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
        <Fragment>
          <DateInput
            name={`contract_returnments.${index}.sp_sk_payment_on`}
            label="Дата возврата СП"
            disabled={
              (form.values.contract_returnments[index] &&
                form.values.contract_returnments[index].avrs.some((i) => i.payment_status.value === 1)) ||
              hasAvr
            }
          />
          <CurrencyInput
            name={`contract_returnments.${index}.sp_sk_payment_cost`}
            label="Сумма возврата СП"
            disabled={
              (form.values.contract_returnments[index] &&
                form.values.contract_returnments[index].avrs.some((i) => i.payment_status.value === 1)) ||
              hasAvr
            }
          />
          <RadioButtonGroup
            label="Формат возврата СП"
            name={`contract_returnments.${index}.sp_sk_payment_form.value`}
            disabled={
              form.values.contract_returnments[index] &&
              form.values.contract_returnments[index].avrs.some((i) => i.payment_status.value === 1)
            }
          >
            {sp_sinergy_payment_forms.map(({ label, value }) => (
              <RadioInput key={`contract_returnments.${index}.sp_sk_payment_form_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
        </Fragment>
      </Card>
      <Button
        variant="danger"
        onClick={onDestroyClick}
        disabled={
          form.values.contract_returnments[index] &&
          (form.values.contract_returnments[index].avrs.length > 0 ||
            (form.values.status.value.toString() === "1" && form.values.contract_returnments[index].id))
        }
      >
        Удалить платеж
      </Button>
      <hr />
    </div>
  )
}
export default ContractReturnment
