import React, { Fragment } from "react"
import { observer } from "mobx-react"
import Kvs from "./Kvs"

const KvTab = (props) => {
  return (
    <Fragment>
      <br />
      <Kvs />
    </Fragment>
  )
}

export default observer(KvTab)
