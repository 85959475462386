import React, { Component, Fragment } from "react"
import afterDestroyFetch from "utils/afterDestroyFetch"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import ContactStore from "stores/ContactStore/List"
import Breadcrumbs from "pages/Insurers/components/Breadcrumbs"
import Tabs from "pages/Insurers/components/Tabs"
import { Button, Card, Paginate } from "components/UI"
import { BreadcrumbItem } from "components/UI"
import Table from "./components/Table"

class List extends Component {
  insurerStore = InsurerStore.create()
  contactStore = ContactStore.create()

  componentDidMount() {
    const { insurer_id } = this.props.match.params
    this.insurerStore.fetch({ id: insurer_id })
    this.contactStore.fetch({ ...getParams(), insurer_id })
  }

  onDestroyClick = (id) => {
    const { id: insurer_id } = this.insurerStore
    afterDestroyFetch(this.contactStore, { id, insurer_id }, { insurer_id })
  }

  onPageChange = (params) => {
    const { id: insurer_id } = this.insurerStore
    this.contactStore.fetch({ ...params, insurer_id })
  }

  render() {
    const {
      props: {
        match: {
          params: { insurer_id }
        }
      },
      onDestroyClick,
      insurerStore,
      contactStore
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem url={`/insurers/${insurerStore.id}`}>{insurerStore.name}</BreadcrumbItem>
          <BreadcrumbItem active>Контактные лица</BreadcrumbItem>
        </Breadcrumbs>
        <Tabs {...{ id: insurer_id }} />
        <br />
        <Card>
          <Button url={`/insurers/${insurer_id}/contacts/new`}>Создать</Button>
        </Card>
        <Table {...{ data: contactStore.data.toJSON(), insurer_id, onDestroyClick }} />
        <Paginate store={contactStore} additionalParams={{ insurer_id: insurer_id }} />
      </Fragment>
    )
  }
}

export default observer(List)
