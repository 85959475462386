const bsoHuman = value => {
  switch (value) {
    case "policy":
      return "Бланк полиса"
    case "receipt":
      return "Бланк квитанции А7"
    default:
      return ""
  }
}

export { bsoHuman }
