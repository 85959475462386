import { types } from "mobx-state-tree"

const Email = types.model("Email", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const Phone = types.model("Phone", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Contact = types.model("Contact", {
  id: types.maybeNull(types.integer),
  l_name: types.maybeNull(types.string),
  f_name: types.maybeNull(types.string),
  p_name: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  insurance_kinds: types.maybeNull(types.array(InsuranceKind)),
  emails: types.maybeNull(types.array(Email)),
  phones: types.maybeNull(types.array(Phone))
})

export default Contact
