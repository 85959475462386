import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import toOptions from "utils/newToOptions"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"
import { Avr } from "./Avr"
import { prepare } from "./utils"
import formatDate from "utils/formatDate"

const FilterOptions = types.model("FilterOptions", {
  kinds: types.array(newCustomEnum, []),
  kv_by_holds: types.array(newCustomEnum, []),
  formers: types.array(newCustomEnum, []),
  statuses: types.array(newCustomEnum, []),
  payment_kinds: types.array(newCustomEnum, []),
  avr_installment_payment_kinds: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    kinds: [],
    kv_by_holds: [],
    formers: [],
    statuses: [],
    payment_kinds: []
  }
}

const AvrStore = types
  .model("AvrStore", {
    data: types.optional(Avr, {
      insurer: {
        id: null,
        name: null
      },
      insurer_contact: { id: null, f_name: null },
      channel: {
        id: null,
        name: null
      },
      user: {
        id: null,
        full_name: null
      },
      former: { value: 0 },
      kind: { value: 1 },
      kv_by_hold: { value: 0 },
      status: { value: 0 },
      payment_kind: { value: 0 },
      payment_status: { value: 0 },
      contract_installments: [],
      avr_installments: [
        {
          control_payment_on: null,
          payment_kind: { value: 0 },
          payment_on: null,
          payment_cost: null
        }
      ],
      documents: [],
      avr_relations: [],
      operations: []
    }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get status() {
      return self.data.status
    },

    get number() {
      return self.data.number
    },

    get insurer() {
      return self.data.insurer
    },

    get insurer_contact() {
      return self.data.insurer_contact
    },

    get channel() {
      return self.data.channel
    },

    get created_at() {
      const { created_at } = self.data
      return created_at && formatDate(created_at)
    },

    get start_on() {
      const { start_on } = self.data
      return start_on && formatDate(start_on)
    },

    get finish_on() {
      const { finish_on } = self.data
      return finish_on && formatDate(finish_on)
    },

    get date_on() {
      const { date_on } = self.data
      return date_on && formatDate(date_on)
    },

    get kv_by_hold() {
      return self.data.kv_by_hold
    },

    get former() {
      return self.data.former
    },

    get kind() {
      return self.data.kind
    },

    get former_on() {
      const { former_on } = self.data
      return former_on && formatDate(former_on)
    },

    get transfer_on() {
      const { transfer_on } = self.data
      return transfer_on && formatDate(transfer_on)
    },

    get payment_on() {
      const { payment_on } = self.data
      return payment_on && formatDate(payment_on)
    },

    get original_receipt_on() {
      const { original_receipt_on } = self.data
      return original_receipt_on && formatDate(original_receipt_on)
    },

    get actual_payment_on() {
      const { actual_payment_on } = self.data
      return actual_payment_on && formatDate(actual_payment_on)
    },

    get contract_installments() {
      return self.data.contract_installments
    },

    get contract_returnments() {
      return self.data.contract_returnments
    },

    get user() {
      return self.data.user
    },

    get filtersOptions() {
      const { kinds, kv_by_holds, formers, statuses, payment_kinds, avr_installment_payment_kinds } =
        self.meta.filters_options
      return {
        kinds: toOptions(kinds),
        kv_by_holds: toOptions(kv_by_holds),
        formers: toOptions(formers),
        statuses: toOptions(statuses),
        payment_kinds: toOptions(payment_kinds),
        avr_installment_payment_kinds: toOptions(avr_installment_payment_kinds)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/avrs/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/avrs/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/avrs`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/avrs/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default AvrStore
