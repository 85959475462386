import React, { Fragment } from "react"
import { PercentageInput } from "components/UI"
import { observer } from "mobx-react"

const BudgetMotivationCoefficientLimit = ({ prefix = null, index, form }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")

  return (
    <Fragment>
      <h5 className="card-title">{`Лимит № ${index + 1}`}</h5>
      <PercentageInput name={path("bottom_limit")} label="Нижняя граница исполнения плана" />
      <PercentageInput name={path("upper_limit")} label="Верхняя граница исполнения плана" />
      <PercentageInput name={path("coefficient")} label="Коэффициент поправочный" />
    </Fragment>
  )
}

export default observer(BudgetMotivationCoefficientLimit)
