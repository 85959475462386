import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import ParcelBorderoStore from "stores/ParcelBorderoStore/List"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { Button, Card, Paginate, BreadcrumbItem } from "components/UI"
import ContractStore from "stores/ContractStore/One"
import Tabsss from "pages/Contracts/components/Tabs"
import Filter from "./components/Filter"
import Table from "./components/Table"
import useSelectAll from "hooks/useSelectAll"
import { historyPushFromStore } from "utils/historyPushWithStore"

const contractStore = ContractStore.create()
const parcel_borderoStore = ParcelBorderoStore.create()

const List = (props) => {
  const { contract_id } = props.match.params
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    contractStore.fetch({ id: contract_id })
    parcel_borderoStore.fetch({ contract_id, ...getParams() })
  }, [])

  console.log(parcel_borderoStore.isFetched)

  const [
    selectedRows,
    totalSelectedRow,
    isCheckedRow,
    onCheckedRow,
    isCheckedAllRows,
    onCheckedAllRows,
    resetSelected
  ] = useSelectAll(parcel_borderoStore.data.toJSON())

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem active>Бордеро</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${contract_id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${contract_id}/edit?additional=true`}>Доп. соглашение</Button>
        )}
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <Card>
        <Button url={`/contracts/${contract_id}/parcel_borderos/new`}>Создать</Button>
      </Card>
      <Card>
        <Filter store={parcel_borderoStore} resetSelected={resetSelected} contract_id={contract_id} />
      </Card>
      <Table
        store={parcel_borderoStore}
        selectedRows={selectedRows}
        totalSelectedRow={totalSelectedRow}
        isCheckedRow={isCheckedRow}
        onCheckedRow={onCheckedRow}
        isCheckedAllRows={isCheckedAllRows}
        onCheckedAllRows={onCheckedAllRows}
        resetSelected={resetSelected}
        contract_id={contract_id}
      />
      <Paginate store={parcel_borderoStore} additionalParams={{ contract_id: contract_id }} />
    </Fragment>
  )
}

export default observer(List)
