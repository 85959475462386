import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import {
  SertificateNumberInput,
  DateInput,
  RadioButtonGroup,
  RadioInput,
  TextInput,
  CurrencyInput,
  Submit
} from "components/UI"
import Documents from "./Documents"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ store, handleSubmit, values }) => {
  const { parcelStatusesOptions } = store.filtersOptions
  const disabled = store.data.parcel_bordero?.status?.value.toString() === "1"
  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup name="status.value" label="Статус" disabled={disabled}>
        {parcelStatusesOptions.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <DateInput name="departure_on" label="Дата отправки" disabled={disabled} />
      <TextInput name="number" label="№ накладной / № заказа" disabled={disabled} />
      <TextInput name="sender" label="Отправитель" disabled={disabled} />
      <TextInput name="sender_address" label="Адрес отправителя" disabled={disabled} />
      <TextInput name="recipient" label="Получатель" disabled={disabled} />
      <TextInput name="recipient_address" label="Адрес Получателя" disabled={disabled} />
      <CurrencyInput name="cost" label="Страховая сумма" disabled={disabled} />
      <TextInput name="comment" label="Комментарий" />
      <Documents store={store} disabled={disabled} />
      {/* <TextInput name="client_parcel_id" label="ID отгрузки Клиента" /> */}
      <SertificateNumberInput
        name="sertificate_number"
        label="№ страхового сертификата"
        required={values.status.value.toString() === "1"}
        // disabled={disabled}
      />
      <TextInput name="sp" label="Страховая премия по отгрузке" disabled />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: contract_id } = formikBag.props.contractStore.data
      formSubmitHandler({ ...values, contract_id: contract_id }, formikBag, `/contracts/${contract_id}/parcels`)
    }
  }),
  observer
)(Form)
