import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import ClientStore from "stores/ClientStore/One"
import ContactStore from "stores/ContactStore/One"
import Breadcrumbs from "pages/Clients/containers/Contacts/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import { fioHuman } from "stores/ContactStore/utils"

class Show extends Component {
  clientStore = ClientStore.create()
  contactStore = ContactStore.create()

  componentDidMount() {
    const { id, client_id } = this.props.match.params
    this.clientStore.fetch({ id: client_id })
    this.contactStore.fetch({ id, client_id })
  }

  render() {
    const {
      clientStore: { data: client },
      contactStore: { f_name, p_name, l_name, position, emails, phones, insurance_kinds }
    } = this
    return (
      <Fragment>
        <Breadcrumbs {...{ client }}>
          <BreadcrumbItem active>{fioHuman({ l_name, f_name, p_name })}</BreadcrumbItem>
        </Breadcrumbs>
        <br />
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>ФИО</td>
                  <td style={{ width: "50%" }}>{fioHuman({ l_name, f_name, p_name })}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Должность</td>
                  <td style={{ width: "50%" }}>{position}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Emails</td>
                  <td style={{ width: "50%" }}>{emails.map(({ value }) => value).join(", ")}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Телефоны</td>
                  <td style={{ width: "50%" }}>{phones.map(({ value }) => value).join(", ")}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Виды страхования</td>
                  <td style={{ width: "50%" }}>{insurance_kinds.map(({ name }) => name).join(", ")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
