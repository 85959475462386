const prepare = (params = {}) => {
  const {
    contract_id,
    contract,
    client_contact: { id: client_contact_id },
    insurer_contact: { id: insurer_contact_id },
    responsible: { id: responsible_id },
    executor: { id: executor_id },
    status: { value: status },
    result: { value: result },
    form: { value: form },
    ...other
  } = params
  return { ...other, contract_id: contract_id || contract.id, responsible_id, executor_id, status, result, form, client_contact_id, insurer_contact_id }
}

export { prepare }
