import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import format from "date-fns/format"
import state from "types/state"
import AgencyContract from "./AgencyContract"
import Meta from "./OneMeta"
import documentsToFormData from "./utils"

const dataDefaultValue = {
  channel: { name: "" },
  insurer: { name: "" },
  document_kind: { name: "" },
  parent: { id: null, number: "" },
  documents: [],
  target: {value: 'ul'},
  is_prolongation: false,
}

const metaDefaultValue = {
  filters_options: {}
}

const AgencyContractStore = types
  .model("AgencyContractStore", {
    data: types.optional(AgencyContract, dataDefaultValue),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(state)
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { channels } = self.meta.filters_options
      const channelOptions = channels.map(({ id: value, name: label }) => {
        return {
          label,
          value
        }
      })

      return [channelOptions]
    },

    get id() {
      return self.data.id
    },

    get channel() {
      return self.data.channel.name
    },

    get comment() {
      return self.data.comment
    },

    get conclusion_on() {
      const { conclusion_on } = self.data
      return conclusion_on && format(conclusion_on, "dd.MM.yyyy")
    },

    get documents() {
      return self.data.documents
    },

    get insurer() {
      return self.data.insurer.name
    },

    get number() {
      return self.data.number
    },

    get prolongation_on() {
      const { prolongation_on } = self.data
      return prolongation_on && format(prolongation_on, "dd.MM.yyyy")
    }
  }))
  .volatile(() => ({
    documentFiles: []
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, insurer_id, documents, ...attributes } = params
      const url = insurer_id ? `/insurers/${insurer_id}/agency_contracts/${id}` : `/agency_contracts/${id}`
      return instance
        .get(url, { attributes })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta(params = {}) {
      self.setState("pending")
      const { insurer_id } = params
      return instance
        .get(`/insurers/${insurer_id}/agency_contracts/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const { insurer_id } = params
      const data = documentsToFormData(self, params)
      const headers = {
        "Content-Type": "multipart/form-data"
      }
      return instance
        .post(`/agency_contracts`, data, { headers })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const { id, insurer_id } = params
      const data = documentsToFormData(self, params)
      const headers = {
        "Content-Type": "multipart/form-data"
      }
      return instance
        .put(`/agency_contracts/${id}`, data, { headers })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    },

    setDocument(index, file) {
      self.documentFiles[index] = file
    }
  }))

export default AgencyContractStore
