import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import formatDateTime from "utils/formatDateTime"
import ContractStore from "stores/ContractStore/One"
import Tabsss from "pages/Contracts/components/Tabs"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"

const contractStore = ContractStore.create()

const Tracking = (props) => {
  const { id: contract_id } = props.match.params

  useEffect(() => {
    contractStore.fetch({ id: contract_id })
  }, [contract_id])

  const { id } = contractStore

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{id}</BreadcrumbItem>
        <BreadcrumbItem active>Трекинг</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?additional=true`}>
            {contractStore.insurance_kind.id === 14 ? "Аддендум" : "Доп. соглашение"}
          </Button>
        )}
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?prolongation=true`}>Продление</Button>
        )}
        &nbsp;
        <Button url={`/contracts/${id}/edit?clone=true`}>Копировать</Button>
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <table className="table">
        <thead>
          <tr>
            <th>Тип активности</th>
            <th>Пользователь</th>
            <th>Дата/время</th>
          </tr>
        </thead>
        <tbody>
          {contractStore.data.tracking.map((track) => (
            <tr>
              <td>
                {track.event === "create" && track.item_type === "Contract"
                  ? "Создание договора"
                  : "Изменение договора"}
              </td>
              <td>{track.user.name}</td>
              <td>{formatDateTime(track.created_at)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  )
}

export default observer(Tracking)
