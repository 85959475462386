import React from "react"
import { Button, PhoneInput } from "components/UI"

const Phone = ({ name, index, phone, h: { replace, remove } }) => {
  if (phone._destroy) return null
  const onDestroyClick = () => (phone.id ? replace(index, { ...phone, _destroy: true }) : remove(index))
  return (
    <div key={`phones.${index}`}>
      <PhoneInput name={`${name}.${index}.value`} label="Телефон" />
      <Button variant="danger" onClick={onDestroyClick}>
        Удалить телефон
      </Button>
      <hr />
    </div>
  )
}
export default Phone
