import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import SourceStore from "stores/SourceStore/One"
import Breadcrumbs from "pages/Sources/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"

class Show extends Component {
  sourceStore = SourceStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.sourceStore.fetch({ id })
  }

  render() {
    const { sourceStore: store } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{store.name}</BreadcrumbItem>
        </Breadcrumbs>
      </Fragment>
    )
  }
}

export default observer(Show)
