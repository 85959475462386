import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { SelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import InsurerStore from "stores/InsurerStore/One"

const insurerStore = InsurerStore.create()

const InsurerContactSelect = ({ name = "insurer", required = false, ...props }) => {
  const { values } = useFormikContext()
  const insurer = getIn(values, name, {}) || {}

  useEffect(() => {
    if (values.insurer.id) {
      insurerStore.fetch({ id: values.insurer.id })
    }
  }, [values.insurer.id])

  return (
    <div className="form-group">
      <SelectInput
        name="insurer_contact.id"
        label="Куратор от Страховой компании"
        options={insurerStore.contacts.map((i) => {
          return {
            label: `${i.l_name} ${i.f_name}`,
            value: i.id
          }
        })}
        isDisabled={!values.insurer.id}
        required={values.status.value.toString() === "1"}
      />
    </div>
  )
}

export default observer(InsurerContactSelect)
