import React, { Fragment } from "react"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import {
  InnInput,
  RadioButtonGroup,
  RadioInput,
  TextInput,
  SnilsInput,
  SelectInput,
  DateInput,
  PassportNumberInput,
  CurrencyInput
} from "components/UI"
import { observer } from "mobx-react"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import Documents from "./Documents"
// import HoldingSelect from "components/Common/HoldingSelect"
// import UserSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/UserSelect"
// import SubdivisionSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/SubdivisionSelect"
// import IndustrySelect from "./IndustrySelect"
// import StrengthSelect from "./StrengthSelect"

const InfoTab = ({ store, handleSubmit, values, setFieldValue, ...p }) => {
  const { molsOptions, statusesOptions, positionsOptions, subdivisionsOptions } = store.filtersOptions
  return (
    <Tabs defaultActiveKey="info">
      <Tab eventKey="info" title="Общие сведения">
        <br />
        <TextInput name="number" label="Табельный номер" />
        <TextInput name="l_name" label="Фамилия" />
        <TextInput name="f_name" label="Имя" />
        <TextInput name="p_name" label="Отчество" />
        <DateInput name="birthday_on" label="Дата рождения" />
        <RadioButtonGroup label="МОЛ" name="mol.value">
          {molsOptions.map(({ label, value }) => (
            <RadioInput key={`kind_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
        <SelectInput name="subdivision.id" label="Подразделение" options={subdivisionsOptions} isDisabled />
        <SelectInput name="position.id" label="Должность" options={positionsOptions} isDisabled />
        <ChannelSelect label="Юридическое лицо" isDisabled />
        <TextInput name="comment" label="Комментарии" />
      </Tab>
      <Tab eventKey="personal" title="Личное дело">
        <br />
        {/* <RadioButtonGroup label="Статус сотрудника" name="status.value">
          {statusesOptions.map(({ label, value }) => (
            <RadioInput key={`status_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup> */}
        <h4>Персональные данные</h4>
        <InnInput name="inn" label="ИНН" kind="individual" />
        <SnilsInput name="snils" label="СНИЛС" />
        <PassportNumberInput name="passport_number" label="Паспорт (серия, номер)" />
        <DateInput name="passport_issued_on" label="Дата выдачи паспорта" />
        <TextInput name="passport_issued_by" label="Кем выдан" />
        {/* <DateInput name="hiring_on" label="Дата приема на работу" />
        <DateInput name="fired_on" label="Дата увольнения" />
        <TextInput name="fired_reason" label="Причина увольнения" /> */}
      </Tab>
      <Tab eventKey="documents" title="Документы">
        <br />
        <Documents store={store} />
      </Tab>
    </Tabs>
  )
}

export default observer(InfoTab)
