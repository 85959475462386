import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import formatDate from "utils/formatDate"
import Tabsss from "pages/Contracts/components/Tabs"
import SubTabsss from "pages/Contracts/components/SubTabs"
import Badge from "react-bootstrap/Badge"

const contractStore = ContractStore.create()

const Show = (props) => {
  const { id: contract_id } = props.match.params

  useEffect(() => {
    contractStore.fetch({ id: contract_id })
  }, [contract_id])

  const { id, insurer_contact } = contractStore

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{id}</BreadcrumbItem>
        <BreadcrumbItem active>
          {contractStore.insurance_kind.id === 14 ? "Аддендумы" : "Доп. соглашения"}
        </BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?additional=true`}>
            {contractStore.insurance_kind.id === 14 ? "Аддендум" : "Доп. соглашение"}
          </Button>
        )}
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?prolongation=true`}>Продление</Button>
        )}
        &nbsp;
        <Button url={`/contracts/${id}/edit?clone=true`}>Копировать</Button>
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <SubTabsss store={contractStore} />
      <br />
      <table className="table">
        <thead>
          <tr>
            <th>№ {contractStore.insurance_kind.id === 14 ? "аддендума" : "доп. соглашения"}</th>
            <th>Дата {contractStore.insurance_kind.id === 14 ? "аддендума" : "доп. соглашения"}</th>
            <th>Срок действия {contractStore.insurance_kind.id === 14 ? "аддендума" : "доп. соглашения"}</th>
            <th>Куратор договора от СК</th>
            <th>Существенные условия доп. соглашения</th>
          </tr>
        </thead>
        <tbody>
          {contractStore.versions.reverse().map((i) => (
            <tr>
              <td>
                {i.additional_agreement_number}
                <br />
                {i.object.additional_agreement_kind && (
                  <Badge variant={i.object.additional_agreement_kind.toString() === "0" ? "success" : "warning"}>
                    {i.object.additional_agreement_kind.toString() === "0" ? "Бумажный" : "Электронный"}
                  </Badge>
                )}
              </td>
              <td>{formatDate(i.additional_agreement_on)}</td>
              <td>
                {formatDate(i.additional_start_on)} - {formatDate(i.object.finish_on)}
              </td>
              <td>
                {insurer_contact.id && `${insurer_contact.l_name} ${insurer_contact.f_name}`}
                <br />
                {insurer_contact.id && insurer_contact.phones.map((i) => i.value).join(", ")}
                <br />
                {insurer_contact.id && insurer_contact.emails.map((i) => i.value).join(", ")}
              </td>
              <td dangerouslySetInnerHTML={{ __html: i.additional_agreement_comment }} />
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  )
}

export default observer(Show)
