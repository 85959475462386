import instance from "connection/instance"
import { types, applySnapshot } from "mobx-state-tree"
import { Tag } from "./Tag"
import { Meta, metaDefaultValue } from "./ListMeta"
import state from "types/state"
import merge from "deepmerge"

const TagStore = types
  .model("Tag", {
    data: types.array(Tag),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(state)
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get tagOptions() {
      return self.data.map(({ id, name }) => {
        return {
          label: name,
          value: id
        }
      })
    },
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      return instance
        .get("/tags", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    search(params = {}) {
      self.setState("pending")
      return instance
        .get("/tags/search", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const {
        status,
        data: { data, meta }
      } = response
      if (status === 200) applySnapshot(self, { data, meta: merge(metaDefaultValue, meta) })
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default TagStore
