import React from "react"
import { Tab, Tabs } from "components/UI"
import { observer } from "mobx-react"

const TabsBlock = ({ store }) => {
  const { id } = store.data
  const mapping = {
    [`/budgets/${id}/company_summary/tec_result_plan`]: "Тех. рез. (План)",
    [`/budgets/${id}/company_summary/fin_result_plan`]: "Фин. рез. (План)",
    [`/budgets/${id}/company_summary/tec_result_fact`]: "Тех. рез. (Факт)",
    [`/budgets/${id}/company_summary/fin_result_fact`]: "Фин. рез. (Факт)",
  }
  return (
    <Tabs>
      {Object.entries(mapping).map((i) => {
        const active = window.location.pathname === i[0]
        return (
          <Tab key={i[0]} active={active} to={i[0]}>
            {i[1]}
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default observer(TabsBlock)
