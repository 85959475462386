import { toast } from "react-toastify"

const formSubmitHandler = async (values, formikBag, path) => {
  const {
    props: { history, handleSubmit },
    setErrors,
    setSubmitting
  } = formikBag
  try {
    const response = await handleSubmit(values)
    if (response && path) {
      if (typeof path === "function") {
        history.push(path(response))
      } else {
        history.push(path)
      }
    }
    return response?.data
  } catch (error) {
    setErrors(error.response.data.errors)
    if (error.response.status !== 401 && error.response.status !== 404) {
      toast.error("Ошибка сохранения. Проверьте форму на наличие ошибок.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
  } finally {
    setSubmitting(false)
  }
}

export default formSubmitHandler