import React, { Fragment } from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import caclulateDurationInDays from "utils/caclulateDurationInDays"
import addYears from "date-fns/addYears"
import addMonths from "date-fns/addMonths"
import addDays from "date-fns/addDays"
import subDays from "date-fns/subDays"
import { useFormikContext } from "formik"

const StandardPeriods = ({ disabled = false }) => {
  const {
    values: { insurance_kind, start_on },
    setFieldValue
  } = useFormikContext()

  const setFinishOn = (finish_on) => {
    setFieldValue("finish_on", finish_on)
    setFieldValue("duration", caclulateDurationInDays(start_on, finish_on))
  }

  const plusOneYear = () => setFinishOn(subDays(addYears(start_on, 1), 1))
  const plusTwoYear = () => setFinishOn(subDays(addYears(start_on, 2), 1))
  const plusThreeYear = () => setFinishOn(subDays(addYears(start_on, 3), 1))
  const plusOneMonth = () => setFinishOn(subDays(addMonths(start_on, 1), 1))
  const plusTwoWeeks = () => setFinishOn(subDays(addDays(start_on, 20), 1))

  const insurance_kind_id = insurance_kind.id && insurance_kind.id.toString()

  return (
    <ButtonGroup>
      {insurance_kind_id === "7" && (
        <Fragment>
          <Button variant="success" onClick={plusOneYear} disabled={!start_on || disabled}>
            + 1 год
          </Button>
          <Button variant="success" onClick={plusTwoYear} disabled={!start_on || disabled}>
            + 2 года
          </Button>
          <Button variant="success" onClick={plusThreeYear} disabled={!start_on || disabled}>
            + 3 года
          </Button>
        </Fragment>
      )}
      {(!insurance_kind_id || insurance_kind_id !== "7") && (
        <Fragment>
          <Button variant="success" onClick={plusOneYear} disabled={!start_on || disabled}>
            + 1 год
          </Button>
          <Button variant="success" onClick={plusOneMonth} disabled={!start_on || disabled}>
            + 1 месяц
          </Button>
          <Button variant="success" onClick={plusTwoWeeks} disabled={!start_on || disabled}>
            + 20 дней
          </Button>
        </Fragment>
      )}
    </ButtonGroup>
  )
}

export default StandardPeriods
