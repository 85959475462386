import React from "react"
import { Destroy, Edit, Sort } from "components/UI"
import { Link } from "react-router-dom"
import afterDestroyFetch from "utils/afterDestroyFetch"
import sortChangeHandler from "utils/sortChangeHandler"
import { observer } from "mobx-react"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">
          <Sort name="order_by_name" onSortChange={() => sortChangeHandler(store)}>
            Страхователь
          </Sort>
        </th>
        <th scope="col">Создал</th>
        <th scope="col">Подразделение</th>
        <th scope="col">Численность</th>
        <th scope="col">Отрасль</th>
        <th scope="col">Комментарий</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map(
        (
          { id, organization_name, name, kind, ownership_kind, other_ownership_kind, user, comment, strength, industry, subdivision },
          index
        ) => (
          <tr key={`clients-${index}`}>
            <td>
              <Link to={`/clients/${id}`}>
                {kind === "individual" ? name : `${name} ${ownership_kind.value == 9 ? other_ownership_kind : ownership_kind.label}`}
              </Link>
            </td>
            <td>{user.name}</td>
            <td>{subdivision && subdivision.short_name}</td>
            <td>{strength.name}</td>
            <td>{industry.name}</td>
            <td dangerouslySetInnerHTML={{ __html: comment }} />
            <td>
              <Edit url={`/clients/${id}/edit`} />
              <Destroy action={() => afterDestroyFetch(store, id)} />
            </td>
          </tr>
        )
      )}
    </tbody>
  </table>
)

export default observer(Table)
