import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import ParcelBorderoStore from "stores/ParcelBorderoStore/One"
import Form from "pages/Contracts/containers/ParcelBorderos/components/Form"
import { BreadcrumbItem } from "components/UI"

const contractStore = ContractStore.create()
const parcel_borderoStore = ParcelBorderoStore.create()

const Edit = (props) => {
  const { id, contract_id } = props.match.params
  useEffect(() => {
    contractStore.fetch({ id: contract_id })
    parcel_borderoStore.fetch({ id, contract_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contract_id}/parcel_borderos`}>Бордеро</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        <BreadcrumbItem active>{parcel_borderoStore.number}</BreadcrumbItem>
      </Breadcrumbs>
      <Form {...{ store: parcel_borderoStore, contractStore, contract_id }} />
    </Fragment>
  )
}

export default observer(Edit)
