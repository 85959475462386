import React from "react"
import compose from "utils/compose"
import { TextInput, ResetButton, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"

const Filter = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <TextInput name="by_name" label="Название Адресата" />
      </div>
      <div className="col-3">
        <TextInput name="by_from_organization" label="Организация" />
      </div>
      <div className="col-3">
        <TextInput name="by_from_contact" label="Контактное лицо" />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
  </form>
)

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
