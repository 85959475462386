import React, { useContext } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import { Destroy, Edit, Sort } from "components/UI"
import formatDate from "utils/formatDate"
import { observer } from "mobx-react"
import afterDestroyFetch from "utils/afterDestroyFetch"
import parseMoney from "utils/parseMoney"
import addDays from "date-fns/addDays"
import { withRouter } from "react-router-dom"
import sortChangeHandler from "utils/sortChangeHandler"
import formatMoney from "utils/formatMoney"

// const renderStatus = ({ id }) => {
//   let color = null
//   switch (id) {
//     case 0:
//       color = "red"
//       break
//     case 1:
//       color = "yellow"
//       break
//     case 2:
//       color = "green"
//       break
//   }
//   return <i className="fas fa-square" style={{ color }}></i>
// }

const isPaid = (avr) => {
  const kvSumByAvr = avr.contract_installments
    .map(
      (i) =>
        parseMoney(i.payment_cost) *
        parseMoney(i.contract.kvs.find((i) => i.channel.id === avr.channel.id)?.rate) *
        0.01
    )
    .reduce((a, b) => a + b, 0)

  const paidKv =
    avr.payment_kind.value.toString() === "0"
      ? avr.avr_installments[0]?.payment_on
        ? kvSumByAvr
        : 0
      : avr.avr_installments
          // .filter((i) => i.payment_on)
          .map((i) => parseMoney(i.payment_cost))
          .reduce((a, b) => a + b, 0)

  return kvSumByAvr - paidKv <= 0
}

const status = (status) => {
  switch (status) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    case 1:
      return <i className="fa fa-circle" style={{ color: "yellow" }} title="Согласован" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const paymentStatus = (avr) => {
  if (avr.payment_kind.value === 0) {
    if (avr.avr_installments[0]?.payment_on === null) {
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не оплачен" />
    } else {
      return <i className="fa fa-circle" style={{ color: "green" }} title="Оплачен" />
    }
  } else {
    const a = avr.avr_installments.filter((i) => i.payment_on !== null && parseInt(i.payment_cost, 10) !== 0).length

    const kvSumByAvr = avr.contract_installments
      .map(
        (i) =>
          parseMoney(i.payment_cost) *
          parseMoney(i.contract.kvs.find((i) => i.channel.id === avr.channel.id)?.rate) *
          0.01
      )
      .reduce((a, b) => a + b, 0)

    const paidKv =
      avr.payment_kind.value.toString() === "0"
        ? avr.avr_installments[0]?.payment_on
          ? kvSumByAvr
          : 0
        : avr.avr_installments
            // .filter((i) => i.payment_on)
            .map((i) => parseMoney(i.payment_cost))
            .reduce((a, b) => a + b, 0)

    if (a === 0) {
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не оплачен" />
    }
    if (a === avr.avr_installments.length && kvSumByAvr - paidKv <= 0) {
      return <i className="fa fa-circle" style={{ color: "green" }} title="Оплачен" />
    }
    return <i className="fa fa-circle" style={{ color: "yellow" }} title="Оплачен частично" />
  }
}

const Table = ({
  store,
  history,
  selectedRows,
  totalSelectedRow,
  isCheckedRow,
  onCheckedRow,
  isCheckedAllRows,
  onCheckedAllRows,
  resetSelected
}) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  const { page } = store.meta.pagination
  const balance = Object.values(selectedRows)
    .flat()
    .map((i) => parseMoney(i.kv_sum))
    .reduce((a, b) => a + b, 0)
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllRows(page)} onChange={(e) => onCheckedAllRows(e, page)} />
          </th>
          <th scope="col">
            <Sort name="order_by_id" onSortChange={() => sortChangeHandler(store)}>
              №
            </Sort>
          </th>
          <th scope="col">Статус АВР</th>
          <th scope="col">Статус оплаты</th>
          <th scope="col">Статус оригинала</th>
          <th scope="col">Страховая компания</th>
          <th scope="col">Канал продаж</th>
          <th scope="col">№ АВР</th>
          <th scope="col">
            <Sort name="order_by_date_on" onSortChange={() => sortChangeHandler(store)}>
              Дата АВР
            </Sort>
          </th>
          <th scope="col" style={{width: '150px'}}>Итого сумма КВ в АВР</th>
          <th scope="col">Удержание КВ</th>
          <th scope="col">Кто формирует АВР</th>
          <th scope="col">
            <Sort name="order_by_signed_on" onSortChange={() => sortChangeHandler(store)}>
              Конт. дата оплаты
            </Sort>
          </th>
          <th scope="col">Факт. дата оплаты</th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {store.data.map((avr, index) => (
          <tr key={`avrs-${index}`}>
            <td>
              <input type="checkbox" checked={isCheckedRow(avr, page)} onChange={() => onCheckedRow(avr, page)} />
            </td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{avr.id}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{status(avr.status.value)}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{paymentStatus(avr)}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{avr.original_status.label}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{avr.insurer.name}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{avr.channel.name}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{avr.number}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{formatDate(avr.date_on)}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{avr.kv_sum}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{avr.kv_by_hold.label}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>{avr.former.label}</td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>
              {avr.signed_on && formatDate(addDays(avr.signed_on, 15))}
            </td>
            <td onClick={() => history.push(`/avrs/${avr.id}`)}>
              {isPaid(avr) &&
                (avr.payment_kind.value.toString() === "0"
                  ? formatDate(avr.avr_installments[0]?.payment_on)
                  : formatDate(avr.avr_installments[avr.avr_installments.length - 1]?.payment_on))}
            </td>
            <td>
              <Edit url={`/avrs/${avr.id}/edit`} />
              <Destroy
                action={() => afterDestroyFetch(store, avr.id)}
                disabled={avr.payment_status.value.toString() === "1" && ![5, 23].includes(currentUser.id)}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot className="thead-light">
        <tr>
          <th colSpan="14">
            Выбрано: {totalSelectedRow} | Сумма: {formatMoney(balance)}
          </th>
          <th>
            <button
              type="button"
              className="btn btn-danger"
              title="Сбросить выделение"
              onClick={resetSelected}
              disabled={totalSelectedRow === 0}
            >
              <i className="fas fa-sync-alt"></i>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  )
}

export default withRouter(observer(Table))
