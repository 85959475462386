import React, { Fragment } from "react"
import { TextInput } from "components/UI"
import RelationSelect from "./RelationSelect"

const Relation = ({ prefix = null, form }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  return (
    <Fragment>
      <RelationSelect name={path("relation_avr")} />
      <TextInput name={path("comment")} label="Комментарий" />
    </Fragment>
  )
}
export default Relation
