import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import customDate from "types/customDate"
import ParcelBordero from "./ParcelBordero"

const Filter = types.model("Filter", {
  by_status: types.maybeNull(types.string),
  by_number: types.maybeNull(types.string),
  by_date_on_from: types.maybeNull(customDate),
  by_date_on_to: types.maybeNull(customDate)
})

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

// const FilterOptions = types.model("FilterOptions", {
//   channels: types.array(Channel, []),
//   insurers: types.array(Insurer, [])
// })

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  // filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filter: {},
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const ParcelBorderoStore = types
  .model("ParcelBordero", {
    data: types.array(ParcelBordero, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      const { contract_id, ...options } = params

      return instance
        .get(`/contracts/${contract_id}/parcel_borderos`, { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(params) {
      const { id, contract_id } = params
      self.setState("pending")
      return instance.delete(`/contracts/${contract_id}/parcel_borderos/${id}`)
      // .then(response => dispatch(destroyUserSuccess(response)))
      // .catch(error => dispatch(destroyUserFailure(error)))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ParcelBorderoStore
