import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import TagStore from "stores/TagStore/List"

const tagStore = TagStore.create()

const TagSelect = ({
  name = "tag",
  required = false,
  label = "Теги",
  isDisabled = false,
  ...props
}) => {
  const { values } = useFormikContext()

  useEffect(() => {
    tagStore.fetch()
  }, [])

  const getOptions = () => {
    const { tagOptions } = tagStore
    const { id: value, name: label } = getIn(values, name) || { id: null, name: null }
    if (value) {
      return tagOptions.concat([{ label, value }])
    }
    return tagOptions
  }

  const tagOptions = getOptions()

  const loadTagOptions = (value) =>
    tagStore
      .fetch({ filter: { by_name: value }, limit: 0 })
      .then(({ tagOptions }) => tagOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={tagOptions}
        required={required}
        isSearchable
        isDisabled={isDisabled}
        loadOptions={loadTagOptions}
        {...props}
      />
    </div>
  )
}

export default observer(TagSelect)
