import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { TextArea, TextInput, DateInput, RadioButtonGroup, RadioInput } from "components/UI"

const DmsTab = ({ store, ...props }) => {
  return (
    <Fragment>
      <br />
      <RadioButtonGroup name="contract_dms.kind.value" label="Вид договора ДМС">
        {[
          { label: "ДМС стандарт", value: "0" },
          { label: "ДМС ЗоЗ", value: "1" }
        ].map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <TextInput
        name="contract_dms.number_of_insured"
        label="Количество застрахованных на дату заключения договора, чел."
      />
      <TextInput name="contract_dms.current_number_of_insured" label="Текущее количество застрахованных, чел." />
      <DateInput name="contract_dms.date_on" label="На дату" />
      <TextArea name="contract_dms.comment" label="Спецпредложения / Бонусы по ДМС" />
    </Fragment>
  )
}

export default observer(DmsTab)
