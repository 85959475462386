import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import ChannelStore from "stores/ChannelStore/List"

const channelStore = ChannelStore.create()

const ChannelSelect = ({ name = "channel", required = false, label = "Канал продаж", ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    channelStore.fetch()
  }, [])

  const getOptions = () => {
    const { channelOptions } = channelStore
    const { id: value, name: label } = getIn(values, name.replace(".id", "")) || { id: null, name: null }
    if (value) {
      return channelOptions.concat([{ label, value }])
    }
    return channelOptions
  }

  const channelOptions = getOptions()

  const loadChannelOptions = (value) =>
    channelStore.fetch({ filter: { by_name: value, order_by_ordered: "asc" }, limit: 0 }).then(({ channelOptions }) => channelOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={channelOptions}
        required={required}
        isSearchable
        loadOptions={loadChannelOptions}
        {...props}
      />
    </div>
  )
}

export default observer(ChannelSelect)
