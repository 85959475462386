import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import customDate from "types/customDate"
import Parcel from "./Parcel"
import format from "date-fns/format"

const Filter = types.model("Filter", {
  by_sender: types.maybeNull(types.string),
  by_sender_address: types.maybeNull(types.string),
  by_recipient: types.maybeNull(types.string),
  by_recipient_address: types.maybeNull(types.string),
  by_departure_on_from: types.maybeNull(customDate),
  by_departure_on_to: types.maybeNull(customDate),
  by_number: types.maybeNull(types.union(types.integer, types.string)),
})

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const FilterOptions = types.model("FilterOptions", {
  channels: types.array(Channel, []),
  insurers: types.array(Insurer, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filter: {},
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const ParcelStore = types
  .model("Parcel", {
    data: types.array(Parcel, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      const { contract_id, ...options } = params

      if (params.filter) {
        const { by_departure_on_from, by_departure_on_to } = params.filter
        if (by_departure_on_from) {
          params.filter = { ...params.filter, by_departure_on_from: format(by_departure_on_from, "yyyy-MM-dd") }
        }
        if (by_departure_on_to) {
          params.filter = { ...params.filter, by_departure_on_to: format(by_departure_on_to, "yyyy-MM-dd") }
        }
      }

      return instance
        .get(`/contracts/${contract_id}/parcels`, { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchAll(params = {}) {
      self.setState("pending")

      const { contract_id, ...options } = params

      if (params.filter) {
        const { by_departure_on_from, by_departure_on_to } = params.filter
        if (by_departure_on_from) {
          params.filter = { ...params.filter, by_departure_on_from: format(by_departure_on_from, "yyyy-MM-dd") }
        }
        if (by_departure_on_to) {
          params.filter = { ...params.filter, by_departure_on_to: format(by_departure_on_to, "yyyy-MM-dd") }
        }
      }

      return instance
        .get(`/contracts/${contract_id}/parcels`, { params })
        // .then((response) => self.resetStore(response))
        // .then((response) => self.setState("done"))
        // .catch((error) => self.errorHandler(error))
    },

    export(params = {}) {
      self.setState("pending")

      return instance
        .get(`/contracts/${params.contract_id}/parcels/export`, {
          params,
          responseType: "arraybuffer",
          headers: {
            Accept: "application/zip"
          }
        })
    },
    
    exportAll(params = {}) {
      self.setState("pending")

      return instance
        .get(`/contracts/${params.contract_id}/parcels/export_all`, {
          params,
          responseType: "arraybuffer",
          headers: {
            Accept: "application/zip"
          }
        })
    },

    destroy(params) {
      const { id, contract_id } = params
      self.setState("pending")
      return instance.delete(`/contracts/${contract_id}/parcels/${id}`)
      // .then(response => dispatch(destroyUserSuccess(response)))
      // .catch(error => dispatch(destroyUserFailure(error)))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ParcelStore
