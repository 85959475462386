import React, { Fragment } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { FieldArray, withFormik } from "formik"
import { withRouter } from "react-router"
import { AddressInput, Submit, TextArea, TextInput, RadioButtonGroup, RadioInput } from "components/UI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import Contacts from "./Contacts"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ handleSubmit }) => {
  const kindOptions = [
    { label: "Арбитражный", value: 0 },
    { label: "Общей юрисдикции", value: 1 }
  ]
  const instanceOptions = [
    { label: "Первая", value: 0 },
    { label: "Аппеляционная", value: 1 },
    { label: "Кассационная", value: 2 },
    { label: "Верховный суд", value: 3 }
  ]

  return (
    <Fragment>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Общие сведения">
          <br />
          <form onSubmit={handleSubmit}>
            <RadioButtonGroup label="Тип" name="kind.value">
              {kindOptions.map(({ label, value }) => (
                <RadioInput key={`kind_${value}`} label={label} id={value} />
              ))}
            </RadioButtonGroup>
            <RadioButtonGroup label="Инстанция" name="instance.value">
              {instanceOptions.map(({ label, value }) => (
                <RadioInput key={`instance_${value}`} label={label} id={value} />
              ))}
            </RadioButtonGroup>
            <TextInput name="name" label="Название" required />
            <AddressInput name="address" label="Адрес" required />
            <TextInput name="comment" label="Комментарий" />
            <Submit>Сохранить</Submit>
          </form>
        </Tab>
        <Tab eventKey="contacts" title="Контакты">
          <br />
          <FieldArray name="contacts" component={Contacts} />
        </Tab>
      </Tabs>
    </Fragment>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/courts")
  })
)(Form)
