import replaceSearchParams from "utils/replaceSearchParams"
import qs from "qs"

const afterDestroyFetch = (store, params, fetchParams = {}) => {
  const { fetch, destroy, data } = store
  let { page } = store.meta.pagination
  destroy(params).then(() => {
    if (data.length === 1 && page > 1) {
      page--
      replaceSearchParams({ page })
    }
    fetch({ ...qs.parse(window.location.search, { ignoreQueryPrefix: true }), ...fetchParams, page })
  })
}

export default afterDestroyFetch
