import { useState } from "react"
import instance from "connection/instance"
import afterDestroyFetch from "utils/newAfterDestroyFetch"

const useProjects = (collection) => {
  const [projects, setProjects] = useState(collection)

  const fetchProjects = async (params) => {
    const response = await instance.get("/pages/projects", { params })
    setProjects(response.data.projects)
    return response.data
  }

  const destroyProject = async (id) => await instance.delete(`/pages/projects/${id}`)
  const destroyProjectWithFetch = async (params) =>
    afterDestroyFetch(projects, fetchProjects, destroyProject, params)

  return { projects, fetchProjects, destroyProject, destroyProjectWithFetch }
}

export default useProjects
