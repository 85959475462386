import React, { Fragment, useEffect } from "react"
import { Button, Card, CardTitle } from "components/UI"
import ContractReturnment from "./ContractReturnment"
import ContractReturnmentStore from "stores/ContractReturnmentStore/List"

const contractInstallmentStore = ContractReturnmentStore.create()

const ContractReturnments = ({ push, form, store, ...h }) => {
  useEffect(() => {
    contractInstallmentStore.fetchMeta()
  }, [])

  const onAddClick = () =>
    push({
      avrs: [],
      sp_payment_on: null,
      sp_payment_cost: 0,
      sp_payment_kind: { value: 0 },
      sp_sk_payment_on: null,
      sp_sk_payment_cost: null,
      sp_sk_payment_form: { value: 0 },
      // sp_sinergy_payment_on: null,
      // sp_sinergy_payment_cost: null,
      // sp_sinergy_payment_form: { value: 0 }
    })

  const contract_returnments = form.values.contract_returnments.map((contract_returnment, index) => (
    <ContractReturnment
      {...{ key: `${index}`, index, contract_returnment, store: contractInstallmentStore, form, h }}
    />
  ))

  return (
    <Fragment>
      <Card>
        <CardTitle>Возвраты СП</CardTitle>

        <Fragment>
          <Button variant="success" onClick={onAddClick}>
            Добавить платеж
          </Button>
          <br />
          <br />
        </Fragment>

        {contract_returnments.reverse()}
      </Card>
    </Fragment>
  )
}

export default ContractReturnments
