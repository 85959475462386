import React from "react"
import { Destroy, Edit, Sort } from "components/UI"
import { Link } from "react-router-dom"
import formatDateTime from "utils/formatDateTime"
import { observer } from "mobx-react"
import sortChangeHandler from "utils/sortChangeHandler"
import afterDestroyFetch from "utils/afterDestroyFetch"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">
          <Sort name="order_by_id" onSortChange={() => sortChangeHandler(store)}>
            #
          </Sort>
        </th>
        <th scope="col">Дата и время создания</th>
        <th scope="col">Консультант</th>
        <th scope="col">Страхователь</th>
        <th scope="col">Тип</th>
        <th scope="col">Статус</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map(({ id, client, status, kind, created_at, creator }, index) => (
        <tr key={`appeals-${index}`}>
          <td>
            <Link to={`/appeals/${id}`}>{id}</Link>
          </td>
          <td>{formatDateTime(created_at)}</td>
          <td>{creator.name}</td>
          <td>{client.name}</td>
          <td>{kind.name}</td>
          <td>{status.label}</td>
          <td>
            <Edit url={`/appeals/${id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
