import parseMoney from "utils/parseMoney"

const prepare = (params = {}) => {
  const {
    kind: { value: kind },
    status: { value: status },
    user: { id: user_id },
    user_sale_plan_items,
    ...other
  } = params
  return {
    ...other,
    kind,
    status,
    user_id,
    user_sale_plan_items: user_sale_plan_items.map((i) => {
      return { ...i, cost: parseMoney(i.cost) }
    })
  }
}

export { prepare }
