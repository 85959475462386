import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Notification } from "./Notification"

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  pagination: types.maybeNull(Paginate)
})

const metaDefaultValue = {
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const NotificationStore = types
  .model("Notification", {
    data: types.array(Notification, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views(self => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get reports_prolongations() {
      return parseInt((self.data.find(i => i.name === "reports_prolongations")?.value || 0), 10)
    },
    
    get reports_contract_installments() {
      return parseInt((self.data.find(i => i.name === "reports_contract_installments")?.value || 0), 10)
    },
    
    get couriers() {
      return parseInt((self.data.find(i => i.name === "couriers")?.value || 0), 10)
    },
    
    get payments_plan() {
      return parseInt((self.data.find(i => i.name === "payments_plan")?.value || 0), 10)
    },
  }))
  .actions(self => ({
    fetch(params = {}) {
      self.setState("pending")

      return instance
        .get("/notifications", { params })
        .then(response => self.resetStore(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default NotificationStore
