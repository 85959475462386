import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { CurrencyInput } from "components/UI"

const GoTab = ({ store }) => {
  return (
    <Fragment>
      <br />
      <CurrencyInput name="contract_go_transport.limit_per_case" label="Лимит ответственности по одному случаю" />
      <CurrencyInput name="contract_go_transport.total_limit" label="Общий лимит ответственности (по договору)" />
    </Fragment>
  )
}

export default observer(GoTab)
