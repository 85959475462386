import React, { useContext, useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import StrengthStore from "stores/StrengthStore/List"

const strengthStore = StrengthStore.create()

const StrengthSelect = ({ name = "strength", required = false, label = "Численность", ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    strengthStore.fetch()
  }, [])

  const getOptions = () => {
    const { strengthOptions } = strengthStore
    const { id: value, name: label } = getIn(values, name) || { id: null, name: null }
    if (value) {
      strengthOptions.concat([{ label, value }])
    }
    return strengthOptions
  }

  const strengthOptions = getOptions()

  const loadStrengthOptions = (value) =>
    strengthStore
      .fetch({ filter: { by_name: value }, limit: 0 })
      .then(({ strengthOptions }) => strengthOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label={label}
        options={strengthOptions}
        required={required}
        isSearchable
        loadOptions={loadStrengthOptions}
        {...props}
      />
    </div>
  )
}

export default observer(StrengthSelect)
