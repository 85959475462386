const prepare = (params = {}) => {
  const {
    user: { id: user_id },
    channel: { id: channel_id },
    ...other
  } = params
  return {
    ...other,
    user_id,
    channel_id
  }
}

export { prepare }
