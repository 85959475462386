import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import HoldingStore from "stores/HoldingStore/List"
import Breadcrumbs from "pages/Holdings/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"

const holdingStore = HoldingStore.create()

const List = () => {
  useEffect(() => {
    holdingStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/holdings/new">Создать</Button>
      </Card>
      <Table store={holdingStore} />
      <Paginate store={holdingStore} />
    </Fragment>
  )
}

export default observer(List)
