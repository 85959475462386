import React, { useEffect, useContext, Fragment } from "react"
import { observer } from "mobx-react"
import OperationStore from "stores/OperationStore/One"
import Breadcrumbs from "pages/Operations/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import { Link } from "react-router-dom"
import Badge from "react-bootstrap/Badge"
import formatDate from "utils/formatDate"
import formatDateTime from "utils/formatDateTime"
import AuthStoreContext from "contexts/AuthStoreContext"

const operationStore = OperationStore.create()

const prepaymentIcon = (value) => {
  switch (value) {
    case 0:
      return <i className="fas fa-xmark" style={{ color: "red" }}></i>
    case 1:
      return <i className="fas fa-check" style={{ color: "green" }}></i>
    default:
      return <i className="fas fa-stop" style={{ color: "red" }}></i>
  }
}

const months = (month) => {
  switch (month) {
    case 1:
      return "Январь"
    case 2:
      return "Февраль"
    case 3:
      return "Март"
    case 4:
      return "Апрель"
    case 5:
      return "Май"
    case 6:
      return "Июнь"
    case 7:
      return "Июль"
    case 8:
      return "Август"
    case 9:
      return "Сентябрь"
    case 10:
      return "Октябрь"
    case 11:
      return "Ноябрь"
    case 12:
      return "Декабрь"
  }
}

const Show = (props) => {
  const { id } = props.match.params
  const { data: currentUser } = useContext(AuthStoreContext)

  useEffect(() => {
    operationStore.fetch({ id })
  }, [id])

  const data = operationStore.data

  let oprationKindVariant = "success"

  switch (data.kind.value.toString()) {
    case "0":
      oprationKindVariant = "success"
      break
    case "1":
      oprationKindVariant = "danger"
      break
    case "2":
      oprationKindVariant = "warning"
      break
  }

  const onAccept = async () => {
    operationStore.accept({ id: data.id })
  }

  const onReject = async () => {
    operationStore.reject({ id: data.id })
  }

  const durationVariant = ""

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{data.id}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/operations/${data.id}/edit`}>Редактировать</Button>
        &nbsp;
        <Button url={`/operations/${data.id}/edit?clone=true`}>Копировать</Button>
        {data.kind.value.toString() === "2" && data.status.value.toString() === "2" && (
          <Fragment>
            &nbsp;
            <Button variant="warning" onClick={onAccept} disabled={currentUser.id !== data.cashier_to.id}>
              Принять
            </Button>
            &nbsp;
            <Button variant="danger" onClick={onReject} disabled={currentUser.id !== data.cashier_to.id}>
              Отказать
            </Button>
          </Fragment>
        )}
      </Card>
      <div className="row">
        <div className="col-12">
          <table className="table">
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Статус операции</td>
                {data.status.value.toString() === "4" && (
                  <td style={{ width: "50%" }}>
                    <span style={{ color: "red" }}>{data.status.label}</span>
                  </td>
                )}
                {data.status.value.toString() !== "4" && <td style={{ width: "50%" }}>{data.status.label}</td>}
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Вид операции</td>
                <td style={{ width: "50%" }}>
                  <Badge variant={oprationKindVariant}>{data.operation_kind.label}</Badge>
                </td>
              </tr>
              {data.kind.value.toString() !== "2" && (
                <Fragment>
                  <tr>
                    <td style={{ width: "50%" }}>Основной счет</td>
                    <td style={{ width: "50%" }}>
                      {data.operation_kind.value.toString() === "0"
                        ? data.bill.first_level_bill_name || "-"
                        : data.balance_bill.first_level_bill_name || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Суб-счет 1-го уровня</td>
                    <td style={{ width: "50%" }}>
                      {data.operation_kind.value.toString() === "0"
                        ? data.bill.second_level_bill_name || "-"
                        : data.balance_bill.second_level_bill_name || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Суб-счет 2-го уровня</td>
                    <td style={{ width: "50%" }}>
                      {data.operation_kind.value.toString() === "0"
                        ? data.bill.third_level_bill_name || "-"
                        : data.balance_bill.third_level_bill_name || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Индекс {data.operation_kind.value.toString() === "0" ? "балансового" : "забалансового"} счета
                    </td>
                    <td style={{ width: "50%" }}>{data.balance_bill?.index}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Подразделение (аллокация)</td>
                    <td style={{ width: "50%" }}>{data.subdivision.name || "-"}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Привязка к АВР</td>
                    <td style={{ width: "50%" }}>
                      {data.avrs.map((avr, index) => [
                        index > 0 && ", ",
                        <Link target="_blank" to={`/avrs/${avr.id}`}>
                          {avr.number}
                        </Link>
                      ])}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Привязка к договорам</td>
                    <td style={{ width: "50%" }}>
                      {data.contracts.map((contract, index) => [
                        index > 0 && ", ",
                        <Link target="_blank" to={`/contracts/${contract.id}`}>
                          {contract.number}
                        </Link>
                      ])}
                      <Badge variant={durationVariant}>{data.duration}</Badge>
                      {data.is_prolongation && <Fragment><span style={{color: 'red'}}>&nbsp;&nbsp;*</span><span>АВТО</span></Fragment>}
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Юр.лицо</td>
                    <td style={{ width: "50%" }}>{data.channel.name ? data.channel.name : "-"}</td>
                  </tr>
                </Fragment>
              )}
              <tr>
                <td style={{ width: "50%" }}>Форма расчета</td>
                <td style={{ width: "50%" }}>{data.form.label}</td>
              </tr>
              {data.kind.value.toString() === "2" && data.operation_kind.value.toString() === "1" && (
                <tr>
                  <td style={{ width: "50%" }}>Связанный договор</td>
                  <td style={{ width: "50%" }}>
                    {data.relation_contract?.id ? (
                      <Link target="_blank" to={`/contracts/${data.relation_contract.id}`}>
                        {data.relation_contract.number}
                      </Link>
                    ) : (
                      "не выбран"
                    )}
                  </td>
                </tr>
              )}
              {data.kind.value.toString() !== "2" && (
                <Fragment>
                  <tr>
                    <td style={{ width: "50%" }}>Требуется фискализация</td>
                    <td style={{ width: "50%" }}>{data.is_need_fiscal ? "Да" : "Нет"}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Статус фискализации</td>
                    <td style={{ width: "50%" }}>{data.fiscal ? "Да" : "Нет"}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Аванс.отчет</td>
                    <td style={{ width: "50%" }}>{prepaymentIcon(data.prepayment.value)}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Вид кассы</td>
                    <td style={{ width: "50%" }}>{data.cash_kind.label}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Кассир</td>
                    <td style={{ width: "50%" }}>{data.user.name}</td>
                  </tr>
                </Fragment>
              )}
              {data.kind.value.toString() === "2" && (
                <Fragment>
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Передающая сторона</td>
                    <td>
                      {
                        { 0: "Дирекция", 1: "Бэк-офис", 2: "Бэк-офис", 3: "Дирекция" }[
                          data.moving_kind.value.toString()
                        ]
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Кассир передающей стороны</td>
                    <td>{data.cashier_from.name}</td>
                  </tr>
                  <tr>
                    <td>Индекс передающего счета</td>
                    <td>{data.from_bill.index}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Принимающая сторона</td>
                    <td>{{ 0: "Бэк-офис", 1: "Дирекция", 2: "Банк", 3: "Банк" }[data.moving_kind.value.toString()]}</td>
                  </tr>
                  {["2", "3"].includes(data.moving_kind.value.toString()) && (
                    <Fragment>
                      <tr>
                        <td style={{ width: "50%" }}>Юр.лицо</td>
                        <td style={{ width: "50%" }}>{data.channel.name ? data.channel.name : "-"}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>Банк</td>
                        <td style={{ width: "50%" }}>{data.bank}</td>
                      </tr>
                      <tr>
                        <td>Индекс принимающего счета</td>
                        <td>{data.to_bill.index}</td>
                      </tr>
                    </Fragment>
                  )}
                  {["0", "1"].includes(data.moving_kind.value.toString()) && (
                    <Fragment>
                      <tr>
                        <td style={{ width: "50%" }}>Кассир принимающей стороны</td>
                        <td style={{ width: "50%" }}>{data.cashier_to.name}</td>
                      </tr>
                      <tr>
                        <td>Индекс принимающего счета</td>
                        <td>{data.to_bill.index}</td>
                      </tr>
                    </Fragment>
                  )}
                  {/* <tr>
                      <td style={{ width: "50%" }}>Тип перемещения</td>
                      <td style={{ width: "50%" }}>{data.moving_kind.label}</td>
                    </tr> */}
                  {/* {["2", "3"].includes(data.moving_kind.value.toString()) && (
                      <Fragment>
                        <tr>
                          <td style={{ width: "50%" }}>Юр.лицо</td>
                          <td style={{ width: "50%" }}>{data.channel.name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Банк</td>
                          <td style={{ width: "50%" }}>{data.bank}</td>
                        </tr>
                      </Fragment>
                    )} */}
                  {/* {["0", "1"].includes(data.moving_kind.value.toString()) && (
                      <Fragment>
                        <tr>
                          <td colSpan="2">&nbsp;</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Кассир передающей стороны</td>
                          <td style={{ width: "50%" }}>{data.cashier_from.name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Кассир принимающей стороны</td>
                          <td style={{ width: "50%" }}>{data.cashier_to.name}</td>
                        </tr>
                      </Fragment>
                    )} */}
                  {/* <tr>
                      <td colSpan="2">&nbsp;</td>
                    </tr> */}
                  {/* <tr>
                      <td style={{ width: "50%" }}>Дата операции</td>
                      <td style={{ width: "50%" }}>{formatDate(data.date_on)}</td>
                    </tr>
                    {["2", "3"].includes(data.moving_kind.value.toString()) && (
                      <Fragment>
                        <tr>
                          <td style={{ width: "50%" }}>Назначение операции</td>
                          <td style={{ width: "50%" }}>{data.comment}</td>
                        </tr>
                      </Fragment>
                    )}
                    <tr>
                      <td style={{ width: "50%" }}>Сумма, руб.</td>
                      <td style={{ width: "50%" }}>{data.cost}</td>
                    </tr>
                    {["2", "3"].includes(data.moving_kind.value.toString()) && (
                      <Fragment>
                        <tr>
                          <td style={{ width: "50%" }}>Кассир передающей стороны</td>
                          <td style={{ width: "50%" }}>{data.cashier_from.name}</td>
                        </tr>
                      </Fragment>
                    )} */}
                  {/* {["0", "1"].includes(data.moving_kind.value.toString()) && (
                      <tr>
                        <td style={{ width: "50%" }}>Акцепт получения</td>
                        <td style={{ width: "50%" }}>{data.acceptance_status?.label}</td>
                      </tr>
                    )} */}
                </Fragment>
              )}
              <tr>
                <td style={{ width: "50%" }}>Дата создания/изменения</td>
                <td style={{ width: "50%" }}>{formatDateTime(data.updated_at)}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Проект</td>
                <td style={{ width: "50%" }}>{[data.project.main, data.project.sub].filter(Boolean).join(" / ")}</td>
              </tr>
            </tbody>
          </table>
          <br />
          {data.kind.value.toString() === "3" && (
            <div className="col-12">
              <table className="table">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Статус операции</td>
                    <td style={{ width: "50%" }}>{data.status.label}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Вид операции</td>
                    <td style={{ width: "50%" }}>
                      <Badge variant={oprationKindVariant}>{data.operation_kind.label}</Badge>
                    </td>
                  </tr>

                  <Fragment>
                    <tr>
                      <td style={{ width: "50%" }}>Основной счет</td>
                      <td style={{ width: "50%" }}>{data.bill.first_level_bill_name || "-"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Суб-счет 1-го уровня</td>
                      <td style={{ width: "50%" }}>{data.bill.second_level_bill_name || "-"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Суб-счет 2-го уровня</td>
                      <td style={{ width: "50%" }}>{data.bill.third_level_bill_name || "-"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Индекс</td>
                      <td style={{ width: "50%" }}>{data.bill?.index}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Дата операции</td>
                      <td style={{ width: "50%" }}>{formatDate(data.date_on)}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Дата возврата</td>
                      <td style={{ width: "50%" }}>{formatDate(data.refund_on)}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Срок, дней</td>
                      <td style={{ width: "50%" }}>
                        <Badge variant={durationVariant}>{data.duration}</Badge>
                        {data.is_prolongation && (
                          <Fragment>
                            <span style={{ color: "red" }}>&nbsp;&nbsp;*</span>
                            <span>АВТО</span>
                          </Fragment>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Юр.лицо</td>
                      <td style={{ width: "50%" }}>{data.channel.name ? data.channel.name : "-"}</td>
                    </tr>
                  </Fragment>
                  <tr>
                    <td style={{ width: "50%" }}>Форма расчета</td>
                    <td style={{ width: "50%" }}>{data.form.label}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Кассир</td>
                    <td style={{ width: "50%" }}>{data.user.name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Дата создания/изменения</td>
                    <td style={{ width: "50%" }}>{formatDateTime(data.updated_at)}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Контрагент</th>
                    <th>Валюта</th>
                    <th>Сумма</th>
                    <th>Ставка, %</th>
                    <th>Доход</th>
                    <th>Назначение операции / Комментарии</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.counterpart.name ? data.counterpart.name : "-"}</td>
                    <td>{data.currency.label}</td>
                    <td>{formatMoney(data.cost)}</td>
                    <td>{data.rate}</td>
                    <td>{formatMoney(((parseMoney(data.cost) * (data.rate / 100)) / 365) * data.duration)}</td>
                    <td>{data.comment}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th>Контрагент</th>
                <th>Дата</th>
                <th>Сумма, руб.</th>
                <th>Назначение операции / Комментарии</th>
                {data.bill &&
                  data.bill.index &&
                  (data.bill.index.startsWith("80") ||
                    data.bill.index.startsWith("701") ||
                    data.bill.index.startsWith("904") ||
                    data.bill.index.startsWith("905") ||
                    data.bill.index.startsWith("906")) && <th>Период отчета</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {data.counterpart.name ? `${data.counterpart.name} ${data.is_selfemployed ? "Самозанятый" : ''}` : "-"}
                </td>
                <td>{formatDate(data.date_on)}</td>
                <td>{data.cost}</td>
                <td>{data.comment}</td>
                {data.bill &&
                  data.bill.index &&
                  (data.bill.index.startsWith("80") ||
                    data.bill.index.startsWith("701") ||
                    data.bill.index.startsWith("904") ||
                    data.bill.index.startsWith("905") ||
                    data.bill.index.startsWith("906")) && (
                    <td>
                      {data.month ? months(data.month) : ""} {data.quarter ? `${data.quarter} кв.` : ""} {data.year}
                    </td>
                  )}
              </tr>
            </tbody>
          </table>
          <br />
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th>Название документа</th>
                <th>Файл</th>
                <th>Комментарий</th>
              </tr>
            </thead>
            <tbody>
              {data.operation_scans.map((i, index) => (
                <tr key={`operation-scan-${index}`}>
                  <td>{String(i.document_kind.id) === "18" ? i.document_kind_other : i.document_kind.name}</td>
                  <td>
                    {i.attachments
                      .filter((i) => i.file.url)
                      .map((attachment) => (
                        <a
                          key={`attachment-${attachment.id}`}
                          href={attachment.file.url}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {decodeURI(attachment.file.url.replace(/^.*[\\|/]/, ""))}
                          <br />
                        </a>
                      ))}
                  </td>
                  <td>{i.comment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

export default observer(Show)
