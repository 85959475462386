import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import SignerStore from "stores/SignerStore/List"

const signerStore = SignerStore.create()

const SignerSelect = ({ name = "signer", required = false, label = "Подписант", ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    signerStore.fetch()
  }, [])

  const getOptions = () => {
    const { signerOptions } = signerStore
    const { id: value, name: label } = getIn(values, name) || { id: null, name: null }
    if (value) {
      return signerOptions.concat([{ label, value }])
    }
    return signerOptions
  }

  const signerOptions = getOptions()

  // console.log(signerOptions)

  const loadSignerOptions = (value) =>
    signerStore.fetch({ filter: { by_name: value } }).then(({ signerOptions }) => signerOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={signerOptions}
        required={required}
        isSearchable
        loadOptions={loadSignerOptions}
        {...props}
      />
    </div>
  )
}

export default observer(SignerSelect)
