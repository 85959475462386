import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import format from "date-fns/format"

const Filter = types.model("Filter", {
  year: types.maybeNull(types.number)
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filter: {
    year: new Date().getFullYear()
  }
}

const ReportStore = types
  .model("ReportStore", {
    data: types.optional(types.frozen(), { statistics: [], table: [] }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      return instance
        .get("/reports/kvs", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ReportStore
