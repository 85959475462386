import React from "react"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn } from "formik"
import { observer } from "mobx-react"

const CheckboxInput = ({ name, label, type = "checkbox", id: _value, ...props }) => {
  const id = idGenerator()
  const { errors, values, handleBlur, handleChange } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false)
  const className = classnames("form-check-input", { "is-invalid": error })
  // console.log(value.map(i => i.id ? String(i.id).toString() : i))
  // console.log(props.formik.setFieldValue)
  return (
    <div className="form-check-inline form-check">
      <input
        // checked={String(_value).toString() === String(value).toString()}
        value={_value}
        onBlur={handleBlur}
        // onChange={handleChange}

        checked={
          Array.isArray(value) ? value.map((i) => String(i.id).toString()).includes(String(_value).toString()) : value
        }
        onChange={(e) => {
          if (e.target.checked) {
            if (Array.isArray(value)) {
              props.formik.setFieldValue(
                name,
                value.concat({
                  id: _value,
                  name: label
                })
              )
            } else {
              props.formik.setFieldValue(name, e.target.checked)
            }
          } else {
            if (Array.isArray(value)) {
              props.formik.setFieldValue(
                name,
                value.filter((i) => i.id !== _value)
              )
            } else {
              props.formik.setFieldValue(name, e.target.checked)
            }
          }
        }}
        {...{ className, id, name, type, ...props }}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default compose(connect, observer)(CheckboxInput)
