import React from "react"
import { observer } from "mobx-react"
import AsyncSelect from "react-select/async"
import instance from "connection/instance"
import { connect, getIn, useFormikContext } from "formik"
import compose from "utils/compose"

const SubdivisionsSelect = ({
  name = "subdivisions",
  required = false,
  label = "Подразделение",
  kind = "0",
  by_year,
  ...props
}) => {
  const { errors, values, handleBlur, setFieldValue } = props.formik
  const error = getIn(errors, name, false)
  // console.log((getIn(values, name, false) || []))
  const value = (getIn(values, name, false) || []).map((i) => {
    return {
      label: i.name,
      value: i.id
    }
  })
  const loadOptions = async (value) => {
    const { data } = await instance.get("/budgets/subdivisions", {
      params: { filter: { by_year: by_year } }
    })
    return data.data.map((i) => {
      return {
        label: i.name,
        value: i.id
      }
    })
  }

  const handleChange = (value) => {
    setFieldValue(
      name,
      value
        ? value.map((i) => {
            return {
              id: i.value,
              name: i.label
            }
          })
        : []
    )
  }

  return (
    <div className="form-group">
      <AsyncSelect
        isMulti
        defaultOptions
        value={value}
        placeholder="Выберите элементы"
        loadOptions={loadOptions}
        name={name}
        onChange={handleChange}
      />
    </div>
  )
}

export default compose(observer, connect)(SubdivisionsSelect)
