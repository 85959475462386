import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Budget } from "./Budget"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"
import toOptions from "utils/newToOptions"

const Filter = types.model("Filter", {
  by_status: types.maybeNull(types.integer),
  by_year: types.maybeNull(types.integer),
  by_subdivision: types.maybeNull(customEnum),
})

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filter: {
    by_status: null,
    by_year: null,
    by_subdivision: { id: null, name: null },
  },
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 0
  }
}

const BudgetStore = types
  .model("Budget", {
    data: types.array(Budget, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get budgetOptions() {
      return self.data.map(({ id, name }) => {
        return {
          label: name,
          value: id
        }
      }) 
    },

    get filtersOptions() {
      const { statuses } = self.meta.filters_options
      return { statuses: toOptions(statuses) }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { index } = params.filter
        if (index) {
          params.filter = { ...params.filter, index: index.toString() }
        }
      }
      
      return instance
        .get("/budgets", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    export(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { index } = params.filter
        if (index) {
          params.filter = { ...params.filter, index: index.toString() }
        }
      }

      return instance
        .get("/budgets/export_xlsx", {
          params,
          responseType: "arraybuffer",
          headers: {
            Accept: "application/vnd.ms-excel"
          }
        })
    },
    
    search(params = {}) {
      self.setState("pending")
      return instance
        .get("/budgets/search", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/budgets/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      applySnapshot(self, data)
      // if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default BudgetStore
