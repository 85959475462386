import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"
import Channel from "./Channel"
import Document from "./Document"
import Insurer from "./Insurer"
import customDateTime from "types/customDateTime"

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.string
})

const Parent = types.model("Parent", {
  id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string)
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Creator = types.model("Creator", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  subdivision: types.maybeNull(Subdivision)
})

const AgencyContract = types.model("AgencyContract", {
  id: types.maybeNull(types.integer),
  document_kind: types.maybeNull(DocumentKind),
  number: types.maybeNull(types.string),
  // channel_id: types.maybeNull(types.number),
  channel: types.maybeNull(Channel),
  // insurer_id: types.maybeNull(types.number),
  insurer: types.maybeNull(Insurer),
  conclusion_on: types.maybeNull(customDate),
  termination_on: types.maybeNull(customDate),
  // prolongation_on: types.maybeNull(customDate),
  start_on: types.maybeNull(customDate),
  finish_on: types.maybeNull(customDate),
  target: types.maybeNull(newCustomEnum),
  comment: types.maybeNull(types.string),
  documents: types.maybeNull(types.array(Document)),
  parent: types.maybeNull(Parent),
  creator: types.maybeNull(Creator),
  updated_at: types.maybeNull(customDateTime),
  is_prolongation: types.maybeNull(types.boolean),
})

export default AgencyContract
