import React from "react"
import BudgetMotivationCoefficientLimit from "./BudgetMotivationCoefficientLimit"
import NestedFields from "components/UI/NestedFields"
import { observer } from "mobx-react"

const defaultValues = {
  bottom_limit: 0,
  upper_limit: 0,
  coefficient: 0
}

const BudgetMotivationCoefficientLimits = () => {
  return (
    <NestedFields
      name="budget_motivation_coefficient_limits"
      component={BudgetMotivationCoefficientLimit}
      defaultValues={defaultValues}
    />
  )
}

export default observer(BudgetMotivationCoefficientLimits)
