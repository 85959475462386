import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import CourtStore from "stores/CourtStore/List"

const courtStore = CourtStore.create()

const CourtSelect = ({ name = "court", required = false, label = "Суд", ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    courtStore.fetch()
  }, [])

  const getOptions = () => {
    const { courtOptions } = courtStore
    const { id: value, name: label } = getIn(values, name.replace(".id", "")) || { id: null, name: null }
    if (value) {
      return courtOptions.concat([{ label, value }])
    }
    return courtOptions
  }

  const courtOptions = getOptions()

  const loadCourtOptions = (value) =>
    courtStore
      .fetch({ filter: { by_name: value, order_by_ordered: "asc" }, limit: 0 })
      .then(({ courtOptions }) => courtOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={courtOptions}
        required={required}
        isSearchable
        loadOptions={loadCourtOptions}
        {...props}
      />
    </div>
  )
}

export default observer(CourtSelect)
