import Breadcrumbs from "pages/Insurers/components/Breadcrumbs"
import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import Form from "pages/Insurers/components/Form"
import InsuranceKindStore from "stores/InsuranceKindStore/List"
import ContactFormContext from "contexts/ContactFormContext"
import { BreadcrumbItem } from "components/UI"

class Add extends Component {
  insurerStore = InsurerStore.create()
  insuranceKindStore = InsuranceKindStore.create()

  componentDidMount() {
    this.insuranceKindStore.fetch({ order_by_ordered: "asc" })
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .create(values)
      .then(() => history.push("/insurers"))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { insurerStore, handleSubmit, insuranceKindStore } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>Создать</BreadcrumbItem>
        </Breadcrumbs>
        <ContactFormContext.Provider value={{ insuranceKindStore }}>
          <Form {...{ store: insurerStore, handleSubmit }} />
        </ContactFormContext.Provider>
      </Fragment>
    )
  }
}

export default observer(Add)
