const prepare = (params = {}) => {
  const {
    status: { value: status },
    currency: { value: currency },
    creator,
    created_at,
    contract_installment,
    ...other
  } = params

  return { ...other, status: parseInt(status), currency: parseInt(currency) }
}

export { prepare }
