import React from "react"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn } from "formik"
import { observer } from "mobx-react"

const RadioInput = ({ name, label, type = "radio", id: _value, ...props }) => {
  const id = idGenerator()
  const { errors, values, handleBlur, handleChange } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false)
  const className = classnames("form-check-input", { "is-invalid": error })

  return (
    <div className="form-check-inline form-check">
      <input
        checked={String(_value).toString() === String(value).toString()}
        value={_value}
        onBlur={handleBlur}
        onChange={handleChange}
        {...{ className, id, name, type, ...props }}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default compose(connect, observer)(RadioInput)
