import React from "react"
import { Button, Card, CardTitle } from "components/UI"
import { connect, getIn, ErrorMessage, FieldArray } from "formik"
import { observer } from "mobx-react"
import compose from "utils/compose"
import { Fragment } from "react"

const NestedField = (
  { name, index, field, component, disabledDestroy, showDestroy, showAdd, form, h: { replace, remove } },
  ...props
) => {
  if (field._destroy) return null
  const onDestroyClick = () => (field.id ? replace(index, { ...field, _destroy: true }) : remove(index))
  const disabled =
    typeof disabledDestroy === "function"
      ? disabledDestroy({ prefix: `${name}.${index}`, index, form, ...props })
      : disabledDestroy
  return (
    <div>
      {React.createElement(component, { prefix: `${name}.${index}`, index, form, ...props })}
      {showDestroy(index) && (
        <Button variant="danger" onClick={onDestroyClick} disabled={disabled}>
          Удалить
        </Button>
      )}
      {showAdd && <hr />}
    </div>
  )
}

const NestedFields = ({
  name,
  push,
  form,
  label,
  component,
  defaultValues,
  disabledAdd,
  disabledDestroy,
  showAdd,
  showDestroy,
  required,
  reverse,
  minIndex,
  collection,
  ...h
}) => {
  const onAddClick = () => push(defaultValues)
  const error = getIn(form.errors, name, false)

  const _collection = collection ? collection : getIn(form.values, name, false)
  return (
    <Card required={required}>
      {label && <CardTitle>{label}</CardTitle>}
      {reverse && (
        <Fragment>
          {showAdd && (
            <Button variant="success" onClick={onAddClick} disabled={disabledAdd}>
              Добавить
            </Button>
          )}
          <br />
          <br />
          {_collection
            .map(
              (field, index) =>
                index >= minIndex &&
                React.createElement(NestedField, {
                  key: `${name}-${index}`,
                  name,
                  index,
                  field,
                  component,
                  disabledDestroy,
                  showDestroy,
                  showAdd,
                  form,
                  h
                })
            )
            .reverse()}
        </Fragment>
      )}
      {!reverse && (
        <Fragment>
          {_collection.map(
            (field, index) =>
              index >= minIndex &&
              React.createElement(NestedField, {
                key: `${name}-${index}`,
                name,
                index,
                field,
                component,
                disabledDestroy,
                showDestroy,
                showAdd,
                form,
                h
              })
          )}
          {showAdd && (
            <Button variant="success" onClick={onAddClick} disabled={disabledAdd}>
              Добавить
            </Button>
          )}
        </Fragment>
      )}

      {/* {error && (typeof error === "string" || Array.isArray(error)) && <div className="invalid-feedback">{error}</div>} */}
      {/* Вылетает ошибка если error массив */}
      {error && (typeof error === "string") && <div className="invalid-feedback">{error}</div>}
    </Card>
  )
}

const Aa = ({
  name,
  label,
  component,
  defaultValues,
  disabledAdd = false,
  disabledDestroy = false,
  showAdd = true,
  showDestroy = () => true,
  required = false,
  collection = null,
  reverse = false,
  minIndex = 0
}) => (
  <FieldArray
    name={name}
    render={(props) => (
      <NestedFields
        {...props}
        label={label}
        component={component}
        defaultValues={defaultValues}
        disabledAdd={disabledAdd}
        disabledDestroy={disabledDestroy}
        showAdd={showAdd}
        showDestroy={showDestroy}
        required={required}
        collection={collection}
        reverse={reverse}
        minIndex={minIndex}
      />
    )}
  />
)

export default compose(observer, connect)(Aa)
