import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import DocumentKindStore from "stores/DocumentKindStore/List"

const documentKindStore = DocumentKindStore.create()

const removeDuplicates = (myArr, prop) =>
  myArr.filter((obj, pos, arr) => arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos)

const DocumentKindSelect = ({ name = "holding", required = false, label = "Тип документа", ids = [], ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    documentKindStore.fetch({ filter: { by_ids: ids }, limit: 0 })
  }, [JSON.stringify(ids)])

  const getOptions = () => {
    const { documentKindOptions } = documentKindStore
    const { id: value, name: label } = getIn(values, name.replace(".id", "")) || { id: null, name: null }
    if (value) {
      return removeDuplicates(documentKindOptions.concat([{ label, value }]), "value")
    }
    return documentKindOptions
  }

  const documentKindOptions = getOptions()

  const loadDocumentKindOptions = (value) =>
    documentKindStore
      .fetch({ filter: { by_ids: ids, by_name: value }, limit: 0 })
      .then(({ documentKindOptions }) => documentKindOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={documentKindOptions}
        required={required}
        isSearchable
        loadOptions={loadDocumentKindOptions}
        {...props}
      />
    </div>
  )
}

export default observer(DocumentKindSelect)
