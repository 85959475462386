import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import ProviderStore from "stores/ProviderStore/One"
import Breadcrumbs from "pages/Providers/components/Breadcrumbs"
import Form from "pages/Providers/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContactFormContext from "contexts/ContactFormContext"
import InsuranceKindStore from "stores/InsuranceKindStore/List"

class Add extends Component {
  providerStore = ProviderStore.create()
  insuranceKindStore = InsuranceKindStore.create()

  componentDidMount() {
    this.providerStore.fetchMeta()
    this.insuranceKindStore.fetch({ order_by_ordered: "asc" })
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .create(values)
      .then(() => history.push("/providers"))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { providerStore: store, handleSubmit, insuranceKindStore } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>Создать</BreadcrumbItem>
        </Breadcrumbs>
        <ContactFormContext.Provider value={{ store, insuranceKindStore }}>
          <Form {...{ store, handleSubmit }} />
        </ContactFormContext.Provider>
      </Fragment>
    )
  }
}

export default observer(Add)
