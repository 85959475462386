import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import ContractScansStore from "stores/ContractScanStore/One"
import Form from "pages/Contracts/containers/ContractScans/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"

const contractStore = ContractStore.create()
const contractScanStore = ContractScansStore.create()

const Edit = (props) => {
  const { id, contract_id } = props.match.params
  useEffect(() => {
    contractStore.fetch({ id: contract_id })
    contractScanStore.fetch({ id, contract_id })
  }, [id, contract_id])
  console.log(contractScanStore.isFetched)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contractStore.id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contractStore.id}/contract_scans`}>Сканы документов</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        <BreadcrumbItem active>{contractScanStore.number}</BreadcrumbItem>
      </Breadcrumbs>
      <ContractFormContext.Provider value={{ contractStore }}>
        <Form {...{ store: contractScanStore, contractStore }} />
      </ContractFormContext.Provider>
    </Fragment>
  )
}

export default observer(Edit)
