import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import HoldingStore from "stores/HoldingStore/One"
import Breadcrumbs from "pages/Holdings/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import formatDateTime from "utils/formatDateTime"
import { Link } from "react-router-dom"
import Tabs from "pages/Holdings/components/Tabs"

class Show extends Component {
  holdingStore = HoldingStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.holdingStore.fetch({ id })
  }

  render() {
    const {
      holdingStore: {
        data: { id, name, clients_count, curator, subdivision, created_at, creator }
      }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumbs>
        <Tabs {...{ id }} />
        <br />
        <Card>
          <Button url={`/holdings/${id}/edit`}>Редактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Наименование Холдинга / Группы компаний</td>
                  <td style={{ width: "50%" }}>{name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Кол-во компаний в холдинге</td>
                  <td style={{ width: "50%" }}>{clients_count}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Куратор</td>
                  <td style={{ width: "50%" }}><Link to={`/users/${curator.id}`}>{curator.name}</Link></td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Подразделение</td>
                  <td style={{ width: "50%" }}>{subdivision.name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Запись создана</td>
                  <td style={{ width: "50%" }}>{formatDateTime(created_at)}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Запись создал</td>
                  <td style={{ width: "50%" }}>{creator.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
