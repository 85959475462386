import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { FieldArray, withFormik } from "formik"
import { withRouter } from "react-router"
import { TextInput, Submit } from "components/UI"
import Emails from "./Emails"
import Phones from "./Phones"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="l_name" label="Фамилия" />
    <TextInput name="f_name" label="Имя" />
    <TextInput name="p_name" label="Отчество" />
    <TextInput name="position" label="Должность" />
    <FieldArray name="emails" component={Emails} />
    <FieldArray name="phones" component={Phones} />
    <Submit>Сохранить</Submit>
  </form>
)

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: court_id } = formikBag.props.courtStore.data
      formSubmitHandler({ ...values, court_id: court_id }, formikBag, `/courts/${court_id}/contacts`)
    }
  })
)(Form)
