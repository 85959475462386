import AsyncSelect from "react-select/async"
import React from "react"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn } from "formik"
import { observer } from "mobx-react"

const AsyncSelectInput = ({
  name,
  label,
  options,
  selectedOption,
  required = false,
  afterChange = (label, value) => {},
  ...props
}) => {
  const id = idGenerator()
  const { errors, values, handleBlur, setFieldValue } = props.formik
  const error = getIn(errors, name.replace(".id", "_id"), false)
  // const error = getIn(errors, name.replace(".", "_"), false)
  const value = getIn(values, name, false)
  const className = classnames("react-select", { "is-invalid": error })
  const _value = props.isMulti ? value : (value && options.find((option) => option.value.toString() === String(value).toString()))

  const errorStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#e74a3b"
    })
  }

  const onChange = (option) => {
    if (props.isMulti) {
      if (option) {
        setFieldValue(name, option)
        afterChange(name, option)
        // if (/\.id/.test(name)) {
        //   console.log(1)
        //   setFieldValue(name.replace(".id", ".name"), option.label)
        // }
      } else {
        setFieldValue(name, [])
        afterChange(name, [])
      }
    } else {
      if (option) {
        setFieldValue(name, option.value)
        afterChange(name, option.value)
        if (/\.id/.test(name)) {
          setFieldValue(name.replace(".id", ".name"), option.label)
          // Патч для BillSelect
          if (option.data && option.data.index) {
            setFieldValue(name.replace(".id", ".index"), option.data.index)
          }
        }
      } else {
        setFieldValue(name, null)
        afterChange(name, null)
      }
    }
  }

  if (props.isMulti) {
    return (
      <div className={classnames("form-group", { required: required })}>
        <label htmlFor={id}>{label}</label>
        <AsyncSelect
          {...{ className, id, name, ...props }}
          escapeClearsValue={false}
          noOptionsMessage={() => "Нет элементов для выбора"}
          onBlur={handleBlur}
          onChange={onChange}
          defaultOptions
          placeholder="Выберите элементы"
          value={_value || []}
          styles={error && errorStyles}
          isClearable={true}
          isMulti
          {...props}
        />
        {error && <div className="invalid-feedback">{error[0]}</div>}
      </div>
    )
  }

  return (
    <div className={classnames("form-group", { required: required })}>
      <label htmlFor={id}>{label}</label>
      <AsyncSelect
        {...{ className, id, name, ...props }}
        escapeClearsValue={false}
        noOptionsMessage={() => "Нет элементов для выбора"}
        onBlur={handleBlur}
        onChange={onChange}
        defaultOptions={options}
        placeholder="Выберите элементы"
        value={_value || null}
        styles={error && errorStyles}
        isClearable={true}
        {...props}
      />
      {error && <div className="invalid-feedback">{error[0]}</div>}
    </div>
  )
}

export default compose(observer, connect)(AsyncSelectInput)
