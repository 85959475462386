import React, { Fragment } from "react"
import { TextInput } from "components/UI"
import Attachments from "./Attachments"
import DocumentKindSelect from "components/Common/DocumentKindSelect"
import { getIn } from "formik"

const Document = ({ prefix = null, form }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const ids = form.values.kind == "entity" ? [4, 6, 8, 21, 15, 18] : [1, 4, 6, 21, 15, 18]
  return (
    <Fragment>
      <DocumentKindSelect name={path("document_kind.id")} ids={ids} />
      {String(getIn(form.values, path("document_kind.id"))) === "18" && <TextInput name={path("document_kind_other")} label="Укажите тип документа" />}
      <TextInput name={path("comment")} label="Комментарий" />
      <Attachments name={path("attachments")} />
    </Fragment>
  )
}
export default Document
