import React, { Fragment } from "react"
import { TextInput, RadioButtonGroup, RadioInput, Button, Submit, CurrencyInput, SelectInput } from "components/UI"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import SubdivisionsSelect from "./SubdivisionsSelect"
import formSubmitHandler from "utils/formSubmitHandler"
import BudgetMotivationCoefficientLimits from "./BudgetMotivationCoefficientLimits"

const Form = ({ store, values, budgetStore, handleSubmit, ...props }) => {
  const { statuses, bases } = store.filtersOptions

  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup label="Статус" name="status.value">
        {statuses.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Базиз расчета" name="basis.value">
        {bases.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <SubdivisionsSelect by_year={budgetStore.data.year} />
      <TextInput name="comment" label="Комментарий" />
      <BudgetMotivationCoefficientLimits />
      <br />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: budget_id } = formikBag.props.budgetStore.data
      formSubmitHandler(
        { ...values, budget_id: budget_id },
        formikBag,
        `/budgets/${budget_id}/budget_motivation_coefficients`
      )
    }
  }),
  observer
)(Form)
