import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import RouteStore from "stores/RouteStore/One"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "components/UI"
import Form from "pages/Routes/components/Form"

const RouteModal = ({ isOpen, closeModal, data }) => {
  const routeStore = RouteStore.create({
    data: data
  })

  const handleSubmit = (values, { props: { store }, setErrors }) =>
    store
      .create(values)
      .then(() => closeModal())
      .catch(({ response }) => setErrors(response.data.errors))

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Типовой маршрут</ModalHeader>
      <ModalBody>
        <Form {...{ store: routeStore, handleSubmit }} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(RouteModal)
