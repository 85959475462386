import React, { Fragment } from "react"
import compose from "utils/compose"
import { FieldArray, withFormik } from "formik"
import formSubmitHandler from "utils/formSubmitHandler"
import { observer } from "mobx-react"
import { withRouter } from "react-router"
import { Submit, AddressInput } from "components/UI"
import Contacts from "./Contacts"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import InfoTab from "./InfoTab"
import Emails from "components/Common/Emails"
import Phones from "components/Common/Phones"

const Form = ({ store, handleSubmit, ...props }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Fragment>
        <br />
        <Tabs defaultActiveKey="info">
          <Tab eventKey="info" title="Информация о страхователе">
            <br />
            <InfoTab {...{ store, ...props }} />
          </Tab>
          {props.values.kind === "individual" && (
            <Tab eventKey="contacts" title="Контактные данные">
              <br />
              <AddressInput name="address" label="Адрес регистрации" />
              <Emails name="emails" required />
              <Phones name="phones" required />
            </Tab>
          )}
          {props.values.kind === "entity" && (
            <Tab eventKey="contacts" title="Контактные лица">
              <br />
              <FieldArray name="contacts" component={Contacts} />
            </Tab>
          )}
        </Tabs>
      </Fragment>
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  }),
  observer
)(Form)
