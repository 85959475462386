import React, { Fragment, Component } from "react"
import { observer } from "mobx-react"
import AvrStore from "stores/AvrStore/One"
import InsurerStore from "stores/InsurerStore/List"
import ChannelStore from "stores/ChannelStore/List"
import Breadcrumbs from "pages/Avrs/components/Breadcrumbs"
import KvCostStore from "stores/KvCostStore/List"
import Form from "pages/Avrs/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"
import qs from "qs"

class Add extends Component {
  avrStore = AvrStore.create()
  insurerStore = InsurerStore.create()
  channelStore = ChannelStore.create()
  kvCostStore = KvCostStore.create()

  componentDidMount() {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const kv_cost_ids = params.kv_cost_ids || []
    const channel_id = params.channel_id
    const insurer_id = params.insurer_id

    this.avrStore.fetchMeta()

    console.log(kv_cost_ids)

    if (kv_cost_ids.length > 0) {
      Promise.all([
        this.kvCostStore.fetch({
          filter: {
            by_id: kv_cost_ids
          },
          limit: 0
        }),
        this.insurerStore.fetch({ filter: { by_id: insurer_id } }),
        this.channelStore.fetch({ filter: { by_id: channel_id } })
      ]).then((values) => {
        console.log(123)
        this.avrStore.applyData({
          status: 200,
          data: {
            data: {
              ...this.avrStore.data,
              channel: { name: values[2].data[0].name, id: values[2].data[0].id },
              insurer: { name: values[1].data[0].name, id: values[1].data[0].id },
              kv_costs: JSON.parse(JSON.stringify(values[0].data))
            }
          }
        })
      })
    } else {
      this.insurerStore.fetch({ order_by_ordered: "asc" })
      this.channelStore.fetch()
    }

    
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .update(values)
      .then(() => history.push("/avrs", { referer: window.location.pathname }))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { avrStore: store, handleSubmit, insurerStore, channelStore } = this
    console.log(this.avrStore.isFetched)
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>Создать</BreadcrumbItem>
        </Breadcrumbs>
        <ContractFormContext.Provider
          value={{
            avrStore: store
          }}
        >
          <Form {...{ store, handleSubmit, insurerStore, channelStore }} />
        </ContractFormContext.Provider>
      </Fragment>
    )
  }
}

export default observer(Add)
