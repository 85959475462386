import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"

const ContractAuto = types.model("ContractAuto", {
  id: types.maybeNull(types.integer),
  risk: types.maybeNull(newCustomEnum),
  // brand: types.maybeNull(types.string),
  // model: types.maybeNull(types.string),
  car_brand: types.maybeNull(newCustomEnum),
  car_model: types.maybeNull(newCustomEnum),
  number_kind: types.maybeNull(newCustomEnum),
  number: types.maybeNull(types.string)
})

export default ContractAuto
