import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import SignerStore from "stores/SignerStore/List"
import Breadcrumbs from "pages/Signers/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"

const signerStore = SignerStore.create()

const List = () => {
  useEffect(() => {
    signerStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/signers/new">Создать</Button>
      </Card>
      <Table store={signerStore} />
      <Paginate store={signerStore} />
    </Fragment>
  )
}

export default observer(List)
