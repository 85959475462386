import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import CourtStore from "stores/CourtStore/One"
import Breadcrumbs from "pages/Courts/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import TabsBlock from "pages/Courts/components/Tabs"

class Show extends Component {
  courtStore = CourtStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.courtStore.fetch({ id })
  }

  render() {
    const {
      courtStore: { data }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{data.name}</BreadcrumbItem>
        </Breadcrumbs>
        <TabsBlock store={this.courtStore} />
        <br />
        <Card>
          <Button url={`/courts/${data.id}/edit`}>Редактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Название</td>
                  <td style={{ width: "50%" }}>{data.name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Тип/инстанция</td>
                  <td style={{ width: "50%" }}>
                    {data.kind.label}/{data.instance.label}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Адрес</td>
                  <td style={{ width: "50%" }}>{data.address}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Комментарий</td>
                  <td style={{ width: "50%" }}>{data.comment}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
