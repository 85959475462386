import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Breadcrumb, BreadcrumbItem, Card, Paginate } from "components/UI"
import Filter from "./components/Filter"
import Table from "./components/Table"
import ContractInstallmentStore from "stores/ContractInstallmentStore/Payments"
import { historyPushFromStore } from "utils/historyPushWithStore"
import getParams from "utils/getParams"

import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

import instance from "connection/instance"
import formatMoney from "utils/formatMoney"

import { PieChart, Pie, Label, Cell, Tooltip } from "recharts"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from "recharts"
import groupBy from "lodash.groupby"
import parseMoney from "utils/parseMoney"

const сontractInstallmentStore = ContractInstallmentStore.create()

const ContractInstallments = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    сontractInstallmentStore.fetch(getParams())
  }, [])
  console.log(сontractInstallmentStore.isFetched)

  const [table, setTable] = useState([])

  const fetchData = async () => {
    try {
      const { data } = await instance.get("/reports/debits")

      setTable(data.data)
    } catch {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100
    }
  ]
  const COLORS = ["#4e73df", "#ffc107"]

  const debits_by_group_by_insurer_name = groupBy(table["debits_by_channel_and_insurer"], (i) => i.insurer_name)
  const debits_by_group_by_channel_name = groupBy(table["debits_by_channel_and_insurer"], (i) => i.channel_name)

  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem active>Дебиторская задолженность</BreadcrumbItem>
      </Breadcrumb>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Состав">
          <br />
          {table && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">ДЕБИТОРКА ВСЕГО</th>
                  <th scope="col">ДЕБИТОРКА НЕ ВЫСТАВЛЕНО</th>
                  <th scope="col">ДЕБИТОРКА НЕ ОПЛАЧЕНО</th>
                  <th scope="col">СКОРОСТЬ ОБОРОТА (дн.)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ color: "#4e73df", fontWeight: "bold" }}>{formatMoney(table["total"])}</td>
                  <td style={{ color: "#4e73df", fontWeight: "bold" }}>{formatMoney(table["not_expose"])}</td>
                  <td style={{ color: "#4e73df", fontWeight: "bold" }}>{formatMoney(table["not_paid"])}</td>
                  <td style={{ color: "#4e73df", fontWeight: "bold" }}>{table["avr_average"]}</td>
                </tr>
              </tbody>
            </table>
          )}
          {table && (
            <div className="row">
              <div
                className="col"
                style={{
                  // backgroundColor: "#f8f8ff",
                  marginTop: "1.5rem",
                  marginRight: "0.75rem",
                  marginLeft: "0.75rem",
                  padding: "0.75rem",
                  borderRadius: "5px",
                  height: "400px"
                }}
              >
                <h6 style={{ fontWeight: "bold", textAlign: "center" }}>
                  ДЕБИТОРКА ВСЕГО
                  <br />
                  Отклонение от KPI, %
                </h6>
                <div style={{ margin: "0 auto", width: "240px" }}>
                  <PieChart width={240} height={240}>
                    <Pie
                      data={[
                        { name: "Не выставленно", value: Math.trunc(table["not_expose"]) },
                        {
                          name: "Не оплачено",
                          value: Math.trunc(table["not_paid"])
                        }
                      ]}
                      cx={115}
                      cy={115}
                      innerRadius={80}
                      outerRadius={120}
                      fill="#8884d8"
                      paddingAngle={0}
                      isAnimationActive={false}
                    >
                      {data.map((entry, index) => (
                        <Cell fill={COLORS[index % COLORS.length]} />
                      ))}
                      <Label
                        value={table["excess"] > 0 ? `+${table["excess"]}%` : `${table["excess"]}%`}
                        position="center"
                        style={{
                          fill: table["excess"] > 0 ? "rgba(255, 0, 0, 1)" : "rgba(0, 128, 0)",
                          fontSize: "24px"
                        }}
                      />
                    </Pie>
                    <Tooltip formatter={(value, name, props) => formatMoney(value)} />
                  </PieChart>
                </div>
              </div>

              <div className="col"></div>
              <div className="col"></div>
            </div>
          )}
          {table && (
            <div className="row">
              <div className="col">
                <h6 style={{ fontWeight: "bold", textAlign: "center" }}>ДЕБИТОРКА В РАЗРЕЗЕ СТРАХОВЫХ КОМПАНИЙ</h6>
                <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
                  <BarChart
                    data={table["debits_by_insurer"] || []}
                    layout="vertical"
                    barSize={30}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis type="category" dataKey="name" width={200} />
                    <Tooltip formatter={(value, name, props) => formatMoney(value)} />
                    <Legend />
                    <Bar dataKey="Не выставленно" stackId="a" fill="#4e73df" />
                    <Bar dataKey="Не оплачено" stackId="a" fill="#ffc107" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          <br />
          <br />
          <br />
          {table && (
            <div className="row">
              <div className="col">
                <h6 style={{ fontWeight: "bold", textAlign: "center" }}>ДЕБИТОРКА В РАЗРЕЗЕ КАНАЛОВ ПРОДАЖ</h6>
                <ResponsiveContainer aspect={4.0 / 1.5} width="100%">
                  <BarChart
                    data={table["debits_by_channel"] || []}
                    layout="vertical"
                    barSize={30}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis type="category" dataKey="name" width={200} />
                    <Tooltip formatter={(value, name, props) => formatMoney(value)} />
                    <Legend />
                    <Bar dataKey="Не выставленно" stackId="a" fill="#4e73df" />
                    <Bar dataKey="Не оплачено" stackId="a" fill="#ffc107" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
        </Tab>
        <Tab eventKey="dinamic" title="Динамика"></Tab>
        <Tab eventKey="numbers" title="Цифры">
          <br />
          {table && (
            <div style={{ overflowX: "scroll" }}>
              <table className="table table-bordered table-striped table-hover" style={{ width: `${Object.keys(debits_by_group_by_channel_name).length * 450 + 700}px` }}>
                <thead>
                  <tr>
                    <th rowSpan={2}>СТРАХОВЫЕ КОМПАНИИ</th>
                    <th colSpan={3}>ИТОГО ПО ВСЕМ КАНАЛАМ</th>
                    {Object.keys(debits_by_group_by_channel_name).map((channel_name) => (
                      <th colSpan={3}>{channel_name}</th>
                    ))}
                  </tr>
                  <tr>
                    <th>ИТОГО</th>
                    <th>НЕ ВЫСТАВЛЕНО</th>
                    <th>НЕ ОПЛАЧЕНО</th>
                    {Object.keys(debits_by_group_by_channel_name).map((channel_name) => (
                      <Fragment>
                        <th>ИТОГО</th>
                        <th>НЕ ВЫСТАВЛЕНО</th>
                        <th>НЕ ОПЛАЧЕНО</th>
                      </Fragment>
                    ))}
                  </tr>
                  <tr>
                    <th>ИТОГО</th>
                    <th>
                      {formatMoney(
                        Object.values(debits_by_group_by_channel_name)
                          .flat()
                          .reduce((a, b) => a + parseMoney(b.not_expose) + parseMoney(b.not_paid), 0)
                      )}
                    </th>
                    <th>
                      {formatMoney(
                        Object.values(debits_by_group_by_channel_name)
                          .flat()
                          .reduce((a, b) => a + parseMoney(b.not_expose), 0)
                      )}
                    </th>
                    <th>
                      {formatMoney(
                        Object.values(debits_by_group_by_channel_name)
                          .flat()
                          .reduce((a, b) => a + parseMoney(b.not_paid), 0)
                      )}
                    </th>
                    {Object.keys(debits_by_group_by_channel_name).map((channel_name) => (
                      <Fragment>
                        <th>
                          {formatMoney(
                            debits_by_group_by_channel_name[channel_name].reduce(
                              (a, b) => a + parseMoney(b.not_expose) + parseMoney(b.not_paid),
                              0
                            )
                          )}
                        </th>
                        <th>
                          {formatMoney(
                            debits_by_group_by_channel_name[channel_name].reduce(
                              (a, b) => a + parseMoney(b.not_expose),
                              0
                            )
                          )}
                        </th>
                        <th>
                          {formatMoney(
                            debits_by_group_by_channel_name[channel_name].reduce(
                              (a, b) => a + parseMoney(b.not_paid),
                              0
                            )
                          )}
                        </th>
                      </Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(debits_by_group_by_insurer_name).map((insurer_name) => (
                    <tr>
                      <td>{insurer_name}</td>
                      <td style={{ width: "150px" }}>
                        {formatMoney(
                          debits_by_group_by_insurer_name[insurer_name].reduce(
                            (a, b) => a + parseMoney(b.not_expose) + parseMoney(b.not_paid),
                            0
                          )
                        )}
                      </td>
                      <td style={{ width: "150px" }}>
                        {formatMoney(
                          debits_by_group_by_insurer_name[insurer_name].reduce((a, b) => a + parseMoney(b.not_expose), 0)
                        )}
                      </td>
                      <td style={{ width: "150px" }}>
                        {formatMoney(
                          debits_by_group_by_insurer_name[insurer_name].reduce(
                            (a, b) => a + parseMoney(b.not_paid),
                            0
                          )
                        )}
                      </td>
                      {Object.keys(debits_by_group_by_channel_name).map((channel_name) => (
                        <DataCell
                          cell={table["debits_by_channel_and_insurer"].find(
                            (i) => i.channel_name == channel_name && i.insurer_name == insurer_name
                          )}
                        />
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Tab>
      </Tabs>
      {/* <Card>
        <Filter store={сontractInstallmentStore} />
      </Card> */}
      {/* <Table store={сontractInstallmentStore} />
      <Paginate store={сontractInstallmentStore} /> */}
    </Fragment>
  )
}

const DataCell = ({ cell }) => (
  <Fragment>
    <td style={{ width: "150px" }}>{formatMoney(parseMoney(cell?.not_paid) + parseMoney(cell?.not_expose))}</td>
    <td style={{ width: "150px" }}>{formatMoney(cell?.not_expose)}</td>
    <td style={{ width: "150px" }}>{formatMoney(cell?.not_paid)}</td>
  </Fragment>
)

export default observer(ContractInstallments)
