import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import instance from "connection/instance"

const SubBillSelect = ({ name = "subdivision", required = false, label = "Подразделение", kind = "0", ...props }) => {
  const [options, setOptions] = useState([])
  useEffect(() => {
    loadOptions().then((data) => setOptions(data))
  }, [])
  const loadOptions = async (value) => {
    const { data } = await instance.get("/bills", {
      params: { filter: { by_name: value, by_kind: kind, order_by_short_name: "asc" }, limit: 0 }
    })
    return data.data.map((i) => {
      return {
        label: i.short_name,
        value: i.id
      }
    })
  }

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={options}
        required={required}
        isSearchable
        loadOptions={loadOptions}
        {...props}
      />
    </div>
  )
}

export default observer(SubBillSelect)
