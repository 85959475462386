const prepare = (params = {}) => {
  const {
    status: { value: status },
    parcel_bordero,
    creator,
    created_at,
    ...other
  } = params

  return {
    ...other,
    status: parseInt(status),
    // parcel_bordero_id: parseInt(parcel_bordero_id),
  }
}

export { prepare }
