import React from "react"
import classnames from "classnames"
import capitalize from "utils/capitalize"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn, ErrorMessage } from "formik"
import { observer } from "mobx-react"
import { BankSuggestions } from "react-dadata"
import "react-dadata/dist/react-dadata.css"

const BankInput = ({ name, label, type = "text", capitalizeValue = true, ...props }) => {
  const id = idGenerator()
  const { errors, values, handleBlur } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || props.value || ""
  const className = classnames("form-control", { "is-invalid": error })
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <BankSuggestions
        token="5c97ae359bff07e1a5ed292c09d8eb511caee739"
        placeholder={capitalizeValue ? capitalize(value.toString()) : value}
        onBlur={handleBlur}
        value={{ value }}
        onChange={({ value }) => props.formik.setFieldValue(name, value)}
        {...{ className, id, name, type, ...props }}
      />
      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default compose(observer, connect)(BankInput)
