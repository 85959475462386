import instance from "connection/instance"
import { types, applySnapshot } from "mobx-state-tree"
import { User } from "./User"
import toOptions from "utils/toOptions"
import newToOptions from "utils/newToOptions"
import { Meta, metaDefaultValue } from "./ListMeta"
import state from "types/state"
import merge from "deepmerge"

const UserStore = types
  .model("User", {
    data: types.array(User),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(state)
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get userOptions() {
      return self.data.map(({ id, name, number, subdivision, position, hiring_on }) => {
        return {
          label: name,
          value: id,
          data: {
            number,
            subdivision,
            position,
            hiring_on
          }
        }
      })
    },

    get filtersOptions() {
      const { mols, statuses, positions, subdivisions } = self.meta.filters_options
      return {
        molsOptions: newToOptions(mols),
        statusesOptions: newToOptions(statuses),
        positionsOptions: toOptions(positions),
        subdivisionsOptions: toOptions(subdivisions)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      return instance
        .get("/users", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    search(params = {}) {
      self.setState("pending")
      return instance
        .get("/users/search", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/users/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const {
        status,
        data: { data, meta }
      } = response
      if (status === 200) applySnapshot(self, { data, meta: merge(metaDefaultValue, meta) })
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default UserStore
