import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import instance from "connection/instance"

const SpecializationSelect = ({ name = "specialization", required = false, label = "Специализация", kind = "0", ...props }) => {
  const [options, setOptions] = useState([])
  useEffect(() => {
    loadOptions().then((data) => setOptions(data))
  }, [])
  const loadOptions = async (value) => {
    const { data } = await instance.get("/pages/specializations", {
      params: { filter: { by_name: value }, limit: 0 }
    })
    return data.specializations.map((i) => {
      return {
        label: i.name,
        value: i.id
      }
    })
  }

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={options}
        required={required}
        isSearchable
        loadOptions={loadOptions}
        {...props}
      />
    </div>
  )
}

export default observer(SpecializationSelect)
