import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Signer } from "./Signer"

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  pagination: types.maybeNull(Paginate)
})

const metaDefaultValue = {
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const SignerStore = types
  .model("Signer", {
    data: types.array(Signer, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views(self => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get signerOptions() {
      return self.data.map(({ id, name, ...i }) => {
        return {
          label: i.user.name,
          value: id
        }
      })
    }
  }))
  .actions(self => ({
    fetch(params = {}) {
      self.setState("pending")

      return instance
        .get("/signers", { params })
        .then(response => self.resetStore(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/signers/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default SignerStore
