import { useState } from "react"
import instance from "connection/instance"

const defaultData = { kind: { value: 0 } }

const prepare = (params = {}) => {
  const {
    parent: { id: parent_id },
    ...other
  } = params
  return {
    ...other,
    parent_id
  }
}

const useProject = (data = defaultData) => {
  const [project, setProject] = useState(data)

  const showProject = async (id) => {
    const response = await instance.get(`/pages/projects/${id}`)
    setProject(response.data.project)
    return data
  }

  const newProject = async (params) => {
    const response = await instance.get("/pages/projects/new", { params })
    setProject(response.data.project)
    return data
  }

  const editProject = async (id) => {
    const { data } = await instance.get(`/pages/projects/${id}/edit`)
    setProject(data.project)
    return data
  }

  const createProject = async (data) => {
    const response = await instance.post("/pages/projects", { data: prepare(data) })
    setProject(response.data.project)
    return data
  }

  const updateProject = async (data) => {
    const response = await instance.put(`/pages/projects/${data.id}`, { data: prepare(data) })
    setProject(response.data.project)
    return data
  }

  return { project, setProject, showProject, newProject, editProject, createProject, updateProject }
}

export default useProject
