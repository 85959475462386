import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import InsuranceKindStore from "stores/InsuranceKindStore/List"

const insuranceKindStore = InsuranceKindStore.create()

const InsuranceKindsSelect = ({ name = "insurance_kinds", required = false, label = "Виды страхования", ...props }) => {
  const { values } = useFormikContext()
  const [options, setOptions] = useState([])
  useEffect(() => {
    loadOptions().then((data) => setOptions(data))
  }, [])

  const loadOptions = async (value) => {
    return insuranceKindStore.fetch({ filter: { by_name: value }, limit: 0 }).then(({ data }) =>
      data.map((i) => {
        return {
          label: i.name,
          value: i.id
        }
      })
    )
  }

  const value = (getIn(values, name, false) || []).map((i) => {
    return {
      label: i.name || i.label,
      value: i.id || i.value
    }
  })

  const _value =
    value && options.filter((option) => value.map((i) => String(i.value).toString()).includes(option.value.toString()))

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={options}
        required={required}
        isSearchable
        loadOptions={loadOptions}
        isMulti
        value={_value}
        {...props}
      />
    </div>
  )
}

export default observer(InsuranceKindsSelect)
