import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import BudgetFotsStore from "stores/BudgetFotStore/One"
import Form from "pages/Budgets/containers/BudgetFots/components/Form"
import { BreadcrumbItem } from "components/UI"

const budgetStore = BudgetStore.create()
const budgetFotStore = BudgetFotsStore.create()

const Add = (props) => {
  const { budget_id } = props.match.params

  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetFotStore.fetchMeta({ budget_id: budget_id })
  }, [])

  budgetFotStore.reset()

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>{budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}</BreadcrumbItem>
        <BreadcrumbItem url={`/budgets/${budget_id}/budget_fots`}>ФОТ</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>

      <Form {...{ store: budgetFotStore, budgetStore }} />
    </Fragment>
  )
}

export default observer(Add)
