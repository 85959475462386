const prepare = (params = {}) => {
  const {
    binding: { value: binding },
    client_binding: { value: client_binding },
    ...other
  } = params
  return {
    ...other,
    binding: parseInt(binding),
    client_binding: parseInt(client_binding)
  }
}

export { prepare }
