import React from "react"
import { Link } from "react-router-dom"
import { Destroy, Edit } from "components/UI"
import { fioHuman } from "stores/ContactStore/utils"

const Table = ({ data, client_id, onDestroyClick }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ФИО</th>
        <th scope="col">Должность</th>
        <th scope="col">Виды страхования</th>
        <th scope="col">Emails</th>
        <th scope="col">Телефоны</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {data.map(({ id, name, position, emails, phones, insurance_kinds, ...other }, index) => (
        <tr key={`clients-${index}`}>
          <td>
            <Link to={`/clients/${client_id}/contacts/${id}`}>{fioHuman(other)}</Link>
          </td>
          <td>{position}</td>
          <td>{insurance_kinds.map(({ name }) => name).join(", ")}</td>
          <td>{emails.map(({ value }) => value).join(", ")}</td>
          <td>{phones.map(({ value }) => value).join(", ")}</td>
          <td>
            <Edit url={`/clients/${client_id}/contacts/${id}/edit`} />
            <Destroy action={() => onDestroyClick(id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
