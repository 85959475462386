import React, { Fragment } from "react"
import { TextInput, MultipleFileInput } from "components/UI"
import DocumentKindSelect from "components/Common/DocumentKindSelect"
import { getIn } from "formik"
import instance from "connection/instance"
import { FieldArray } from "formik"

const OperationScan = ({ prefix = null, index, form }) => {
  const { setFieldValue, setSubmitting } = form
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")

  const kinds = [
    { label: "Скан-копия", value: 0 },
    { label: "Wording", value: 1 }
  ]

  const onFileInputChange = (e, push) => {
    setSubmitting(true)
    const formData = new FormData()
    formData.append("data[file]", e.target.files[0])
    formData.append("data[documentable_type]", "ClientDocument")
    instance
      .post("/documents", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(({ data }) => {
        push(data.data)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onRemove = (id) =>
    setFieldValue(
      path("attachments"),
      form.values.operation_scans[index].attachments.filter((i) => i.id.toString() !== id.toString())
    )

  return (
    <Fragment>
      <DocumentKindSelect name={path("document_kind.id")} ids={[8, 18]} />
      {String(getIn(form.values.operation_scans[index], "document_kind.id")) === "18" && (
        <TextInput name={path("document_kind_other")} label="Укажите тип документа" />
      )}
      <TextInput name={path("comment")} label="Комментарий" />
      <FieldArray
        name={path("attachments")}
        render={({ push }) => (
          <MultipleFileInput
            name={path("attachments")}
            onChange={(e) => onFileInputChange(e, push)}
            onRemove={onRemove}
          />
        )}
      />
    </Fragment>
  )
}
export default OperationScan
