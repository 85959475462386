import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"

const contractDocumentStatus = (contractDocument) => {
  if (contractDocument.submission_on && contractDocument.submission.value.toString() === String(1)) {
    return <i className="fa fa-circle" style={{ color: "yellow" }} title="Сдано в бэк-офис" />
  }
  if (contractDocument.submission_on && contractDocument.submission.value.toString() === String(0)) {
    return <i className="fa fa-circle" style={{ color: "green" }} title="Сдано в СК" />
  }
  return <i className="fa fa-circle" style={{ color: "red" }} title="Не сдан" />
}

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Тип документа</th>
        <th scope="col">Вид документа</th>
        <th scope="col">Дата сдачи</th>
        <th scope="col">Кому/куда сдан</th>
        <th scope="col">Ответственный</th>
        <th scope="col">Статус</th>
        <th scope="col">Комментарии</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((i) => (
        <tr>
          <td>{String(i.document_kind.id) === "18" ? i.document_kind_other : i.document_kind.name}</td>
          <td>{i.kind.label}</td>
          <td>{formatDate(i.submission_on)}</td>
          <td>
            {i.submission_holder}/{String(i.submission.value) == "2" ? i.submission_other : i.submission.label}
          </td>
          <td>{i.responsible.name}</td>
          <td>{contractDocumentStatus(i)}</td>
          <td>{i.comment}</td>
          <td>
            <Edit url={`/contracts/${i.contract_id}/contract_documents/${i.id}/edit`} />
            <Destroy
              action={() =>
                afterDestroyFetch(store, { id: i.id, contract_id: i.contract_id }, { contract_id: i.contract_id })
              }
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
