import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { FieldArray, getIn, withFormik } from "formik"
import { withRouter } from "react-router"
import { DateInput, RadioButtonGroup, RadioInput, TextInput, CurrencyInput, Submit, CheckboxGroup, CheckboxInput } from "components/UI"
import Documents from "./Documents"
import formSubmitHandler from "utils/formSubmitHandler"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import Cargos from "./Cargos"

const Form = ({ contractStore, handleSubmit, values }) => {
  const {
    cargoCombinedOptions,
    cargoPrefabricatedOptions,
    cargoCurrenciesOptions,
    transportKindsOptions
  } = contractStore.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <TextInput name="number" label="№ ген.договора / порядковый № декларации" required />
      {/* <RadioButtonGroup label="Валюта договора" name="currency.value">
        {cargoCurrenciesOptions.map(({ label, value }) => (
          <RadioInput key={`cargo_currency_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup> */}
      <RadioButtonGroup label="Тип" name="cargo_prefabricated.id">
        {cargoPrefabricatedOptions.map(({ label, value }) => (
          <RadioInput
            key={`cargo_prefabricated_${value}`}
            label={label}
            id={value}
            // onChange={e => {
            //   props.setFieldValue(`cargo_prefabricated.id`, e.target.value)
            //   props.setFieldValue(`cargos`, props.values.cargos.slice(0, 1))
            // }}
          />
        ))}
      </RadioButtonGroup>
      <FieldArray name="cargos" component={Cargos} />
      {/* <CurrencyInput
        name="ss_cost"
        label="Общая СС по отгрузке (итого)"
        disabled
        value={formatMoney(
          getIn(values, 'cargos')
            .map((i) => parseMoney(i.cost))
            .reduce((a, b) => a + b, 0)
        )}
      /> */}
      <RadioButtonGroup label="Валюта СП по отгрузке" name="currency.value">
        {cargoCurrenciesOptions.map(({ label, value }) => (
          <RadioInput key={`cargo_currency_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <CurrencyInput name="sp_cost" label="СП по отгрузке (итого)" />
      <TextInput name="cargo_route" label="Маршрут" />
      <DateInput name="cargo_shipment_on" label="Дата отгрузки" required />

      {/* <RadioButtonGroup label="Перевозка комбинированная" name="cargo_combined.id">
        {cargoCombinedOptions.map(({ label, value }) => (
          <RadioInput key={`cargo_combined_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup> */}
      {/* <FieldArray name="transport_kinds" component={TransportKinds} /> */}
      <CheckboxGroup label="Виды транспорта" name="transport_kinds">
        {transportKindsOptions.map(({ label, value }) => (
          <CheckboxInput key={`transport_kinds_${value}`} label={label} id={value} />
        ))}
      </CheckboxGroup>
      <TextInput name="comment" label="Комментарий" />
      <Documents name="documents" />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: contract_id } = formikBag.props.contractStore.data
      formSubmitHandler({ ...values, contract_id: contract_id }, formikBag, `/contracts/${contract_id}/shipments`)
    }
  }),
  observer
)(Form)
