import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import CarModelStore from "stores/CarModelStore/List"
import Breadcrumbs from "pages/CarModels/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"

const carBrandStore = CarModelStore.create()

const List = () => {
  useEffect(() => {
    carBrandStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/car_models/new">Создать</Button>
      </Card>
      <Table store={carBrandStore} />
      <Paginate store={carBrandStore} />
    </Fragment>
  )
}

export default observer(List)
