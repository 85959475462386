import React from "react"
import compose from "utils/compose"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { observer } from "mobx-react"
import { ResetButton, SelectInput, Submit, TextInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"

const Filter = ({ store, handleSubmit }) => {
  const { kinds } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <TextInput name="by_name" label="Субагент" />
        </div>
        <div className="col-3">
          <SelectInput name="by_kind" label="Тип" options={kinds} />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
