import React from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

const Tab = ({ active = false, to = "#", children }) => (
  <li className="nav-item">
    <Link className={classnames("nav-link", { active: active })} to={to}>
      {children}
    </Link>
  </li>
)

export default Tab
