import React from "react"
import { Button, FileInput } from "components/UI"

const Document = ({ index, document, store, h: { replace, remove } }) => {
  if (document._destroy) return null
  const onFileInputChange = e => store.setDocument(index, e.target.files[0])
  const onDestroyClick = () => (document.id ? replace(index, { ...document, _destroy: true }) : remove(index))
  return (
    <div key={`documents.${index}`}>
      <FileInput name={`documents.${index}.file.url`} onChange={onFileInputChange} />
      <Button variant="danger" onClick={onDestroyClick}>
        Удалить документ
      </Button>
      <hr />
    </div>
  )
}
export default Document
