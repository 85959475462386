import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { withRouter } from "react-router-dom"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import isBefore from "date-fns/isBefore"
import isSameDay from "date-fns/isSameDay"

const Table = ({
  history,
  store,
  selectedRows,
  totalSelectedRow,
  isCheckedRow,
  onCheckedRow,
  isCheckedAllRows,
  onCheckedAllRows,
  resetSelected
}) => {
  const { page } = store.meta.pagination

  const balance = Object.values(selectedRows)
    .flat()
    .map((i) => parseMoney(i.cost))
    .reduce((a, b) => a + b, 0)

  const status = (i) => {
    if ((isBefore(i.date_on, new Date()) || isSameDay(i.date_on, new Date())) && isBefore(new Date(), i.refund_on)) {
      return <i className="fa fa-circle" style={{ color: "green" }} title="Действует" />
    }
    return <i className="fa fa-circle" style={{ color: "red" }} title="Не действует" />
  }

  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllRows(page)} onChange={(e) => onCheckedAllRows(e, page)} />
          </th>
          <th>ID</th>
          <th style={{ textAlign: "center" }}>Статус</th>
          <th>Основной счет</th>
          <th>Юр.лицо расчетов</th>
          <th>Контрагент</th>
          <th style={{ width: "150px" }}>Сумма</th>
          <th>Валюта</th>
          <th>Срок</th>
          <th>Дата размещения</th>
          <th>Дата возврата</th>
          <th>Ставка, %</th>
          <th>Доход</th>
        </tr>
      </thead>
      <tbody>
        {store.data.map((i, index) => (
          <tr
            key={`operations-${index}`}
            className={["table-success", "table-danger", "table-warning", "table-info"][parseInt(i.kind.value)]}
          >
            <td>
              <input type="checkbox" checked={isCheckedRow(i, page)} onChange={() => onCheckedRow(i, page)} />
            </td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.id}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)} style={{ textAlign: "center" }}>
              {status(i)}
            </td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.bill.third_level_bill_name}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.channel.name}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.counterpart.name}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.cost}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.currency?.label}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.duration}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{formatDate(i.date_on)}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{formatDate(i.refund_on)}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.rate}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>
              {formatMoney(((parseMoney(i.cost) * (i.rate / 100)) / 365) * i.duration)}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot className="thead-light">
        <tr>
          <th colSpan="12">
            Выбрано: {totalSelectedRow} | Сумма: {formatMoney(balance)}
          </th>
          <th>
            <button
              type="button"
              className="btn btn-danger"
              title="Сбросить выделение"
              onClick={resetSelected}
              disabled={totalSelectedRow === 0}
            >
              <i className="fas fa-sync-alt"></i>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  )
}

export default withRouter(observer(Table))
