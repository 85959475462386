import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import { withRouter } from "react-router-dom"

const row_color = (kv_cost) => {
  if (Object.keys(kv_cost.avr).length !== 0) {
    if (kv_cost.avr.status.value == 2) {
      return "table-success"
    } else {
      return "table-warning"
    }
  } else {
    return "table-danger"
  }
}

const contract_status = (status) => {
  switch (status) {
    case "not_active":
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не действует" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Действует" />
  }
}

const contract_sending_status = (status) => {
  switch (status) {
    case "not_send":
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не сдан" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Сдан" />
  }
}

const status = (status) => {
  switch (status) {
    case "overdue":
      return <i className="fa fa-circle" style={{ color: "red" }} title="Просрочен" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="В плане" />
  }
}

const Table = ({
  history,
  store,
  selectedRows,
  totalSelectedRow,
  isCheckedRow,
  onCheckedRow,
  isCheckedAllRows,
  onCheckedAllRows,
  resetSelected
}) => {
  const { page } = store.meta.pagination
  const balance = Object.values(selectedRows)
    .flat()
    .map((i) => parseMoney(i.cost))
    .reduce((a, b) => a + b, 0)
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllRows(page)} onChange={(e) => onCheckedAllRows(e, page)} />
          </th>
          <th>Страховая компания</th>
          <th>Канал</th>
          <th>Страхователь</th>
          <th>Вид страхования</th>
          <th>Номер договора</th>
          <th style={{width: '150px'}}>СП оплаченное</th>
          <th>Дата оплаты СП</th>
          <th>КВ, %</th>
          <th style={{width: '150px'}}>КВ по опл. СП</th>
          <th>Статус договора</th>
          <th>Cдача договора</th>
          <th>Выставление АВР</th>
          <th>Срок оплаты АВР</th>
        </tr>
      </thead>
      <tbody>
        {store.data.map((i, index) => (
          <tr key={`debits-${index}`} className={row_color(i)}>
            <td>
              <input type="checkbox" checked={isCheckedRow(i, page)} onChange={() => onCheckedRow(i, page)} />
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {i.contract.insurer.name}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {i.channel.name}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {i.contract.client.name}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {i.contract.insurance_kind.name}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {i.contract.number}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {i.payment_cost}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {formatDate(i.payment_on)}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {i.kind == "fix_kv" ? "-" : i.rate}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {i.cost}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {contract_status(i.contract_status)}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {contract_sending_status(i.contract_sending_status)}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {status(i.sending_deadline_avr)}
            </td>
            <td
              onClick={() =>
                i.avr.id
                  ? window.open(`/avrs/${i.avr.id}`, "_blank").focus()
                  : window.open(`/contracts/${i.contract.id}`, "_blank").focus()
              }
            >
              {status(i.payment_deadline_avr)}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot className="thead-light">
        <tr>
          <th colSpan="13">
            Выбрано: {totalSelectedRow} | Сумма: {formatMoney(balance)}
          </th>
          <th>
            <button
              type="button"
              className="btn btn-danger"
              title="Сбросить выделение"
              onClick={resetSelected}
              disabled={totalSelectedRow === 0}
            >
              <i className="fas fa-sync-alt"></i>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  )
}

export default withRouter(observer(Table))
