import React, { Fragment } from "react"
import { TextInput } from "components/UI"
import { observer } from "mobx-react"
import InsurerSelect from "./../../../Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import BsoKindSelect from "components/Common/BsoKindSelect"
import { getIn } from "formik"

const DocumentFlowBsoItem = ({ prefix = null, index, form }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const insurer = getIn(form.values, path("insurer"))

  return (
    <Fragment>
      <h5>Запрос БСО {++index}</h5>
      <InsurerSelect name={path("insurer")} />
      <BsoKindSelect name={path("bso_kind")} insurer={insurer} label="Вид БСО" />
      <TextInput name={path("quantity")} label="Количество БСО" />
    </Fragment>
  )
}

export default observer(DocumentFlowBsoItem)
