import React, { useEffect } from "react"
import { observer } from "mobx-react"
import ParcelStore from "stores/ParcelStore/List"
import Modal from "react-modal"
import { Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"
import Filter from "./components/Filter"
import useSelectAll from "hooks/useSelectAll"

const parcelStore = ParcelStore.create()

const chunckArray = (array, chunkCount) => {
  var chunks = []
  while (array.length) {
    chunks.push(array.splice(0, chunkCount))
  }
  return chunks
}

const ParcelsModal = ({
  isOpen,
  setIsOpen,
  parcels,
  by_payment_on_start,
  by_payment_on_finish,
  toggleChange,
  contract_id,
  ...props
}) => {
  const defaultFilter = {
    by_status: "released",
    // by_channel: { id: channel_id },
    // by_insurer: { id: insurer_id },
    by_parcel_bordero_id: "null",
    by_departure_on_from: by_payment_on_start,
    by_departure_on_to: by_payment_on_finish
    // order_by_departure_on: 'desc'
  }

  useEffect(() => {
    parcelStore.fetch({
      contract_id: contract_id,
      filter: defaultFilter
    })
  }, [isOpen])

  const closeModal = () => setIsOpen(false)

  const onSave = () => {
    props.setParcels(parcels.concat(JSON.parse(JSON.stringify(Object.values(selectedRows).flat()))))
    closeModal()
  }

  const onFilterSubmit = (values, { props: { store } }) =>
    store.fetch({ contract_id, filter: { ...defaultFilter, ...values } })

  const [
    selectedRows,
    totalSelectedRow,
    isCheckedRow,
    onCheckedRow,
    isCheckedAllRows,
    onCheckedAllRows,
    resetSelected,
    setSelected
  ] = useSelectAll(parcelStore.data.toJSON(), false)

  const checkedAll = async (values) => {
    const { data } = await parcelStore.fetchAll({
      contract_id: contract_id,
      filter: { ...defaultFilter, ...values },
      limit: 0
    })

    const selected = chunckArray(data.data, 10).reduce((result, item, index, array) => {
      result[index + 1] = item
      return result
    }, {})

    setSelected(selected)
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className="modal" ariaHideApp={false}>
      <div className="modal-dialog modal-xxl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Добавить</h5>
            <button type="button" className="close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Card>
              <Filter
                store={parcelStore}
                handleSubmit={onFilterSubmit}
                defaultFilter={defaultFilter}
                contract_id={contract_id}
                checkedAll={checkedAll}
              />
            </Card>
            <Table
              parcels={parcels}
              store={parcelStore}
              selectedRows={selectedRows}
              totalSelectedRow={totalSelectedRow}
              isCheckedRow={isCheckedRow}
              onCheckedRow={onCheckedRow}
              isCheckedAllRows={isCheckedAllRows}
              onCheckedAllRows={onCheckedAllRows}
              resetSelected={resetSelected}
            />
            <Paginate
              store={parcelStore}
              params={{ ...defaultFilter, ...parcelStore.meta.filter }}
              additionalParams={{ contract_id: contract_id }}
            />
          </div>
          <div className="modal-footer">
            <Button onClick={onSave}>Сохранить</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(ParcelsModal)
