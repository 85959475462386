import React, { useEffect } from "react"
import { observer } from "mobx-react"
import SubagentStore from "stores/SubagentStore/One"
import Form from "pages/Subagents/components/Form"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"

const subagentStore = SubagentStore.create()

const SubagentModal = ({ isOpen, closeModal, onSubagentAdded }) => {
  useEffect(() => {
    subagentStore.fetchMeta()
  }, [])

  const handleSubmit = (values, { props: { store }, setErrors }) =>
    store
      .create(values)
      .then(() => {
        onSubagentAdded({ label: store.name, value: store.data.id })
        closeModal()
      })
      .catch(({ response }) => setErrors(response.data.errors))

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Создать субагента</ModalHeader>
      <ModalBody>
        <Form store={subagentStore} handleSubmit={handleSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(SubagentModal)
