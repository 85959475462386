import React, { Fragment, useEffect, useContext, useState } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import ClientStore from "stores/ClientStore/List"
import InsurerStore from "stores/InsurerStore/List"
import SubagentStore from "stores/SubagentStore/List"
import InsuranceProductStore from "stores/InsuranceProductStore/List"
import InsuranceKindStore from "stores/InsuranceKindStore/List"
import UserStore from "stores/UserStore/List"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import Form from "pages/Contracts/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"
import qs from "qs"
import formatDate from "utils/formatDate"
import AuthStoreContext from "contexts/AuthStoreContext"
import addDays from "date-fns/addDays"

const contractStore = ContractStore.create()
const clientStore = ClientStore.create()
const insuranceKindStore = InsuranceKindStore.create()
const insuranceProductStore = InsuranceProductStore.create()
const insurerStore = InsurerStore.create()
const subagentStore = SubagentStore.create()
const userStore = UserStore.create()

const Edit = (props) => {
  const { id } = props.match.params
  const { data: currentUser } = useContext(AuthStoreContext)

  const [store, setStore] = useState(contractStore)

  useEffect(() => {
    contractStore.fetch({ id }).then(({ data }) => {
      data.insurer.id && insuranceProductStore.fetch({ insurer_id: data.insurer.id })
    })

    clientStore.search()
    // insuranceKindStore.fetch()
    // insurerStore.fetch()
    // subagentStore.fetch()
    // userStore.fetch()
  }, [id])

  // не удалять! Грязный хак
  console.log(contractStore.isFetched)
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const additional = params.additional == "true"
  const prolongation = params.prolongation == "true"
  const clone = params.clone == "true"
  if (additional) {
    contractStore.applyData({
      status: 200,
      data: { data: { ...contractStore.data, status: { value: 0 }, has_additional: additional } }
    })
  }

  let aa = "Редактировать"

  useEffect(() => {
    if (prolongation) {
      const data = contractStore.toJSON()
      if (contractStore.isFetched) {
        const newData = {
          is_prolongation: true,
          status: {
            value: 0
          },
          user: {
            id: currentUser.id,
            name: currentUser.name
          },
          subdivision: {
            id: currentUser.subdivision.id,
            name: currentUser.subdivision.name
          },
          conclusion_on: new Date(),
          number: "",
          parent: {
            id: contractStore.data.id,
            number: contractStore.data.number
          },
          bsos: [],
          start_on: addDays(contractStore.data.finish_on, 1),
          finish_on: addDays(
            contractStore.data.finish_on,
            contractStore.data.duration === 366 ? 365 : contractStore.data.duration
          ),
          duration: contractStore.data.duration === 366 ? 365 : contractStore.data.duration,
          comment: "",

          sp_accrual_on: new Date(),
          sp_accrual_cost: null,
          sp_accrual_control_on: addDays(contractStore.data.finish_on, 1),
          sp_paid_inflector: {
            id: 0
          },
          contract_installments: [
            {
              sp_payment_on: null,
              sp_payment_cost: null,
              sp_payment_kind: { value: 0 },
              sp_sk_payment_on: null,
              sp_sk_payment_cost: null,
              sp_sk_payment_form: { value: 0 },
              sp_sinergy_payment_on: null,
              sp_sinergy_payment_cost: null,
              sp_sinergy_payment_form: { value: 0 },
              is_payments_equal: false
            }
          ],
          kvs: [
            {
              channel: {
                id: data.data.channel.id
              },
              rate: "0",
              by_contract: null,
              by_paid_sp: null
            }
          ],
          documents: [],
          contract_returnments: []
        }

        if (data.data.insurance_kind.id === 7) {
          newData["contract_dms"] = {
            number_of_insured: null,
            current_number_of_insured: null,
            date_on: null,
            comment: ""
          }
        }

        if (data.data.insurance_kind.id === 14 && data.data.insurer.id == 13) {
          newData["policy_number"] = ""
        }

        // if (data.data.insurance_kind.id === 10) {
        //   newData["cargo_beneficiary"] = null
        //   newData["cargos"] = []
        //   newData["cargo_kind"] = { id: 0, name: "" }
        //   newData["cargo_procedure_for_submitting_declaration"] = { id: 0, name: "" }
        //   newData["cargo_procedure_for_certificate_issue"] = { id: 0, name: "" }
        //   newData["cargo_procedure_for_sp_payment"] = { id: 0, name: "" }
        //   newData["cargo_prefabricated"] = { id: 0, name: "" }
        //   newData["cargo_combined"] = { id: 0, name: "" }
        //   newData["cargo_deposit_sp_cost"] = null
        //   newData["cargo_total_cost"] = null
        //   newData["cargo_condition"] = null
        //   newData["cargo_tarif"] = null
        //   newData["cargo_route"] = null
        //   newData["cargo_comment"] = null
        //   newData["cargo_responsibility_from"] = null
        //   newData["cargo_responsibility_to"] = null
        // }

        setStore(
          ContractStore.create({
            ...data,
            data: {
              ...data.data,
              ...newData
            }
          })
        )
      }

      //
    }
  }, [contractStore.isFetched])

  if (additional) {
    aa = "Редактировать Договор в версии ДС"
    contractStore.applyData({
      status: 200,
      data: {
        data: {
          ...contractStore.data,
          additional_agreement_on: null,
          additional_agreement_number: null,
          additional_agreement_comment: null,
          additional_start_on: null
        }
      }
    })
  }
  if (!additional && contractStore.data.versions.length > 0) {
    aa = `Редактировать Договор в версии ДС №${contractStore.data.additional_agreement_number} от ${formatDate(
      contractStore.data.additional_agreement_on
    )}`
  }
  if (prolongation) {
    aa = "Продление"
  }
  if (clone) {
    aa = "Копирование"
  }

  useEffect(() => {
    if (clone) {
      const data = contractStore.toJSON()

      const newData = {
        id: null,
        is_clone: true,
        status: {
          value: 0
        },
        number: null,
        parent: { id: null, number: null },
        bsos: [],
        documents: [],
        scan: [],
        avrs: [],
        contract_installments: data.data.contract_installments.map(i => {
          return {
            ...i,
            avrs: []
          }
        }),
        contract_returnments: [],
        additional_agreement_on: null,
        additional_start_on: null,
        additional_agreement_number: null,
        additional_agreement_comment: null
      }

      setStore(
        ContractStore.create({
          ...data,
          data: {
            ...data.data,
            ...newData
          }
        })
      )
    }
  }, [contractStore.isFetched])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/contracts/${store.id}`}>{store.id}</BreadcrumbItem>
        <BreadcrumbItem active>{aa}</BreadcrumbItem>
        {/* <BreadcrumbItem active>{additional ? "Редактировать Договор в версии ДС" : "Редактировать"}</BreadcrumbItem> */}
      </Breadcrumbs>
      <ContractFormContext.Provider
        value={{
          clientStore,
          contractStore: store,
          insuranceKindStore,
          insuranceProductStore,
          insurerStore,
          subagentStore,
          userStore
        }}
      >
        <Form store={store} />
      </ContractFormContext.Provider>
    </Fragment>
  )
}

export default observer(Edit)
