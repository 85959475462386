import React from "react"
import { useFormikContext } from "formik"
import { observer } from "mobx-react"

const Submit = ({ children, variant = "primary", url, ...props }) => {
  const { isSubmitting } = useFormikContext()
  return (
    <button type="submit" className={`btn btn-${variant}`} disabled={isSubmitting} {...props}>
      {children}
    </button>
  )
}

export default observer(Submit)
