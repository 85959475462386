import React, { Fragment } from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { ResetButton, SelectInput, Submit, TextInput, DateInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import CounterpartSelect from "components/Common/CounterpartSelect"
import BillSelect from "components/Common/BillSelect"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"

const Filter = ({ store, handleSubmit }) => {
  const { forms } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <BillSelect name="by_bill" ids={[25, 26, 27, 28, 29, 119, 120, 121]} />
        </div>
        <div className="col-3">
          <ChannelSelect label="Юр.лицо расчетов" name="by_channel" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_from" label="Дата размещения с" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_to" label="Дата размещения по" />
        </div>
        <div className="col-3">
          <CounterpartSelect name="by_counterpart" />
        </div>
        <div className="col-3">
          <SelectInput name="by_form" label="Форма расчета" options={forms} isClearable />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_currency"
            label="Валюта"
            options={[
              { label: "RUR", value: "0" },
              { label: "USD", value: "1" },
              { label: "EUR", value: "2" }
            ]}
            isClearable
          />
        </div>
        <div className="col-3">
          <TextInput name="by_comment" label="Назначение" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      {store.meta.statistics.map((i) => (
        <Fragment key={`statustic-${i}`}>
          &nbsp;&nbsp;&nbsp;&nbsp;{i[0]}: <b>{i[1]}</b>
        </Fragment>
      ))}
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => {
      formikBag.props.resetSelected()
      filterSubmitHandler(values, formikBag, {}, 'fetch_placements')
    }
  })
)(Filter)
