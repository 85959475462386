import React, { useState } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { AddressInput, PhoneInput, Submit, TextInput, Button } from "components/UI"
import OrganizationSelect from "components/Common/OrganizationSelect"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import DestinationModal from "./DestinationModal"

const Form = ({ handleSubmit, setFieldValue }) => {
  const [isOpenFrom, setIsOpenFrom] = useState(false)
  const [isOpenTo, setIsOpenTo] = useState(false)

  const closeFromModal = () => setIsOpenFrom(false)
  const closeToModal = () => setIsOpenTo(false)

  const setFrom = (selected) => {
    setFieldValue("from_destination_id", selected.id)
    setFieldValue("from_organization", selected.organization)
    setFieldValue("from_address", selected.address)
    setFieldValue("from_comment", selected.comment)
    setFieldValue("from_contact", selected.contact)
    setFieldValue("from_phone", selected.phone)
  }
  
  const setTo = (selected) => {
    setFieldValue("to_destination_id", selected.id)
    setFieldValue("to_organization", selected.organization)
    setFieldValue("to_address", selected.address)
    setFieldValue("to_comment", selected.comment)
    setFieldValue("to_contact", selected.contact)
    setFieldValue("to_phone", selected.phone)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Tabs defaultActiveKey="from">
        <Tab eventKey="from" title="Отправитель">
          <br />
          <Button onClick={() => setIsOpenFrom(true)}>Типовой адресат</Button>
          <br />
          <br />
          <TextInput name="name" label="Название типовой курьерской отправки" required />
          <OrganizationSelect name="from_organization" />
          <TextInput name="from_contact" label="Имя адресата (контактное лицо)" />
          <PhoneInput name="from_phone" label="Телефон" />
          <AddressInput name="from_address" label="Адрес" />
          <TextInput name="from_comment" label="Комментарий" />
        </Tab>
        <Tab eventKey="to" title="Получатель">
          <br />
          <Button onClick={() => setIsOpenTo(true)}>Типовой адресат</Button>
          <br />
          <br />
          <TextInput name="name" label="Название типовой курьерской отправки" required disabled />
          <OrganizationSelect name="to_organization" />
          <TextInput name="to_contact" label="Имя адресата (контактное лицо)" />
          <PhoneInput name="to_phone" label="Телефон" />
          <AddressInput name="to_address" label="Адрес" />
          <TextInput name="to_comment" label="Комментарий" />
        </Tab>
      </Tabs>
      <DestinationModal isOpen={isOpenFrom} onSet={setFrom} closeModal={closeFromModal} />
      <DestinationModal isOpen={isOpenTo} onSet={setTo} closeModal={closeToModal} />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  })
)(Form)
