import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Contact } from "./Contact"
import { getUrl, prepare } from "./utils"

const ContactStore = types
  .model("ContactStore", {
    data: types.optional(Contact, {
      insurance_kinds: [],
      emails: [],
      phones: []
    }),
    meta: types.optional(types.map(types.number), {}),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get l_name() {
      return self.data.l_name
    },

    get f_name() {
      return self.data.f_name
    },

    get p_name() {
      return self.data.p_name
    },

    get name() {
      return self.data.name
    },

    get position() {
      return self.data.position
    },

    get emails() {
      return self.data.emails
    },

    get phones() {
      return self.data.phones
    },
    
    get insurance_kinds() {
      return self.data.insurance_kinds
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, client_id, ...options } = params
      const url = getUrl(params)
      return instance
        .get(url, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")

      return instance
        .get(`/contacts/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const url = getUrl(params)
      const data = prepare(params)
      return instance
        .post(url, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const url = getUrl(params)
      const data = prepare(params)
      return instance
        .put(url, { data })
        .then((response) => this.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { ...self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ContactStore
