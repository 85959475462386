import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import formatDate from "utils/formatDate"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import ReportStore from "stores/ReportStore/Kvs"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import Tabsss from "pages/Budgets/components/Tabs"

const budgetStore = BudgetStore.create()
const reportStore = ReportStore.create()

const Show = (props) => {
  const { id } = props.match.params

  useEffect(() => {
    budgetStore.fetch({ id })
  }, [id])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>{budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}</BreadcrumbItem>
        <BreadcrumbItem active>План продаж</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budgetStore.data.id}/edit`}>Радактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <br />
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: "4300px" }}>
          <thead style={{ background: "#e5efdc" }}>
            <tr>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 1КВ{budgetStore.data.year}
              </th>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 2КВ{budgetStore.data.year}
              </th>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 3КВ{budgetStore.data.year}
              </th>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 4КВ{budgetStore.data.year}
              </th>
            </tr>
            <tr>
              <th style={{ border: "1px solid white" }}>1КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ЯНВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ФЕВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>МАРТ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>2КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>АПР{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>МАЙ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ИЮНЬ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>3КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ИЮЛЬ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>АВГ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>СЕН{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>4КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ОКТ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>НОЯ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ДЕК{budgetStore.data.year.toString().slice(2, 4)}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {budgetStore.data.budget_items.map((item, itemIndex) => (
                <Fragment key={`item=${itemIndex}`}>
                  {itemIndex % 3 === 0 && (
                    <td style={{ verticalAlign: "middle", width: "200px" }}>
                      {formatMoney(
                        budgetStore.data.budget_items
                          .slice(itemIndex, itemIndex + 3)
                          .reduce((a, b) => a + parseMoney(b.cost), 0)
                      )}
                    </td>
                  )}
                  <td>{formatMoney(item.cost)}</td>
                </Fragment>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default observer(Show)
