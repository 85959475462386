import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import InsuranceProductStore from "stores/InsuranceProductStore/List"
import Breadcrumbs from "pages/Insurers/components/Breadcrumbs"
import Tabs from "pages/Insurers/components/Tabs"
import { BreadcrumbItem, Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"

const insurerStore = InsurerStore.create()
const insuranceProductStore = InsuranceProductStore.create()

const List = (props) => {
  useEffect(() => {
    const { insurer_id } = props.match.params
    insurerStore.fetch({ id: insurer_id })
    insuranceProductStore.fetch({ ...getParams(), insurer_id })
  }, [])

  const { insurer_id } = props.match.params

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/insurers/${insurer_id}`}>{insurerStore.name}</BreadcrumbItem>
        <BreadcrumbItem active>Страховые продукты</BreadcrumbItem>
      </Breadcrumbs>
      <Tabs {...{ id: insurer_id }} />
      <br />
      <Card>
        <Button url={`/insurers/${insurer_id}/insurance_products/new`}>Создать</Button>
      </Card>
      <Table store={insuranceProductStore} />
      <Paginate store={insuranceProductStore} />
    </Fragment>
  )
}

export default observer(List)
