import React, { Fragment } from "react"
import Documents from "./Documents"
import { observer } from "mobx-react"

const DocumentsTab = ({ store, handleSubmit, values, setFieldValue, ...p }) => {
  const { ownership_kinds, payment_kinds } = store.filtersOptions
  return (
    <Fragment>
      <br />
      <Documents store={store} />
    </Fragment>
  )
}

export default observer(DocumentsTab)
