import React, { Fragment } from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { ResetButton, SelectInput, Submit, TextInput, DateInput, CurrencyInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import BillSelect from "components/Common/BillSelect"

import CounterpartSelect from "components/Common/CounterpartSelect"

const Filter = ({ store, handleSubmit, defaultFilter }) => {
  const { kinds, cash_kinds, forms } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <BillSelect name="by_bill" />
        </div>
        <div className="col-3">
          <CurrencyInput name="by_cost" label="Сумма" />
        </div>
        <div className="col-3">
          <CounterpartSelect name="by_counterpart" />
        </div>
        <div className="col-3">
          <TextInput name="by_comment" label="Назначение платежа" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_from" label="Дата с" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_to" label="Дата по" />
        </div>
        <div className="col-3">
          <SelectInput name="by_form" label="Форма расчета" options={forms} isClearable />
        </div>
        <div className="col-3">
          <SelectInput name="by_cash_kind" label="Вид кассы" options={cash_kinds} isClearable />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      {store.meta.statistics
        .filter((i) => ["CF Приход", "CF Расход", "CF Баланс"].includes(i[0]))
        .map((i) => (
          <Fragment key={`statustic-${i}`}>
            &nbsp;&nbsp;&nbsp;&nbsp;{i[0]}: <b>{i[1]}</b>
          </Fragment>
        ))}
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => {
      formikBag.props.resetSelected()
      filterSubmitHandler({ ...formikBag.props.defaultFilter, ...values, by_operation_kind: '0' }, formikBag)
    }
  })
)(Filter)
