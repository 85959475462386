import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import customDateTime from "types/customDateTime"
import Client from "./Client"
import Source from "./Source"
import Creator from "./Creator"
import Kind from "./Kind"
import newCustomEnum from "types/newCustomEnum"

const Appeal = types.model("Appeal", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(Kind),
  client: types.maybeNull(Client),
  channel_client: types.maybeNull(Client),
  channel_subagent: types.maybeNull(Client),
  creator: types.maybeNull(Creator),
  creator_id: types.maybeNull(types.integer),
  description: types.maybeNull(types.string),
  source: types.maybeNull(Source),
  source_id: types.maybeNull(types.integer),
  status: types.maybeNull(newCustomEnum),
  feedback_on: types.maybeNull(customDate),
  created_at: types.maybeNull(customDateTime)
})

export default Appeal
