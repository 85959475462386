import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"
import customIdValue from "types/customIdValue"

const SubBill = types.model("SubBill", {
  id: types.maybeNull(types.integer),
  first_level_name: types.maybeNull(types.string),
  second_level_name: types.maybeNull(types.string),
  short_name: types.maybeNull(types.string)
})

const Bill = types.model("Bill", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  form: types.maybeNull(newCustomEnum),
  first_level_name: types.maybeNull(types.string),
  first_level_bill: types.maybeNull(SubBill),
  second_level_name: types.maybeNull(types.string),
  second_level_bill: types.maybeNull(SubBill),
  third_level_name: types.maybeNull(types.string),
  index: types.maybeNull(types.string),
  short_name: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  comment: types.maybeNull(types.string)
})

const billDefaultValue = {
  kind: { value: 0 },
  form: { value: 0 },
  first_level_name: "",
  second_level_name: "",
  third_level_name: "",
  index: "",
  short_name: "",
  comment: ""
}

export { Bill, billDefaultValue }
