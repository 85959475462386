import React from "react"
import { observer } from "mobx-react"
import { useFormikContext } from "formik"
import { DateInput } from "components/UI"
import caclulateDurationInDays from "utils/caclulateDurationInDays"

const FinishOnInput = ({ disabled = false, required = false }) => {
  const {
    values: { start_on },
    setFieldValue
  } = useFormikContext()

  const onChange = (finish_on) => {
    setFieldValue("finish_on", finish_on)
    setFieldValue("duration", caclulateDurationInDays(start_on, finish_on))
  }

  return (
    <DateInput
      name="finish_on"
      label="Дата окончания"
      required={required}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

export default observer(FinishOnInput)
