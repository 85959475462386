import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import ContractTaskStore from "stores/ContractTaskStore/List"
import Breadcrumbs from "pages/ContractTasks/components/Breadcrumbs"
import { Card, Paginate } from "components/UI"
import Table from "./components/Table"
import Filter from "./components/Filter"
import { historyPushFromStore } from "utils/historyPushWithStore"
import TabsBlock from "pages/ContractTasks/components/TabsBlock"

const contractProceedingTaskStore = ContractTaskStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    contractProceedingTaskStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <TabsBlock />
      <br />
      <Card>
        <Filter store={contractProceedingTaskStore} />
      </Card>
      <Table store={contractProceedingTaskStore} />
      <Paginate store={contractProceedingTaskStore} />
    </Fragment>
  )
}

export default observer(List)
