import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import customTime from "types/customTime"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"
import Channel from "./Channel"
import InsuranceKind from "./InsuranceKind"
import Insurer from "./Insurer"
import Contact from "./Contact"
import InsuranceProduct from "./InsuranceProduct"
import Client from "./Client"
import Subagent from "./Subagent"
import CargoKind from "./CargoKind"
import CargoPrefabricated from "./CargoPrefabricated"
import SpSkRetention from "./SpSkRetention"
import ContractInstallment from "./ContractInstallment"
import ContractReturnment from "./ContractReturnment"
import CargoСombined from "./CargoСombined"
import Cargo from "./Cargo"
// import TransportKind from "./TransportKind"
import User from "./User"
import ContractProperty from "./ContractProperty"
import Avr from "./Avr"
import Bso from "./Bso"
import ContractAuto from "./ContractAuto"
import Subdivision from "./Subdivision"
import Courier from "./Courier"

const Parent = types.model("Parent", {
  id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string)
})

const Child = types.model("Child", {
  id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string)
})

const ContractDms = types.model("ContractDms", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  number_of_insured: types.maybeNull(types.number),
  current_number_of_insured: types.maybeNull(types.number),
  date_on: types.maybeNull(customDate),
  comment: types.maybeNull(types.string)
})

const ContractMortgage = types.model("ContractMortgage", {
  id: types.maybeNull(types.integer),
  risks: types.maybeNull(types.array(types.frozen()))
})

const ContractGoTransport = types.model("ContractGoTransport", {
  id: types.maybeNull(types.integer),
  limit_per_case: types.maybeNull(types.string),
  total_limit: types.maybeNull(types.string)
})

const ContractGoEstate = types.model("ContractGoEstate", {
  id: types.maybeNull(types.integer),
  object: types.maybeNull(newCustomEnum),
  kind: types.maybeNull(types.string),
  total_limit: types.maybeNull(types.string)
})

const Kv = types.model("Kv", {
  id: types.maybeNull(types.integer),
  rate: types.maybeNull(types.string),
  by_contract: types.maybeNull(types.string),
  by_paid_sp: types.maybeNull(types.string),
  channel: types.maybeNull(Channel)
})

const Accident = types.model("Accident", {
  id: types.maybeNull(types.integer),
  ss_cost: types.maybeNull(types.string),
  risk: types.maybeNull(newCustomEnum),
  other: types.maybeNull(types.string)
})

const ContractOsgop = types.model("ContractOsgop", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  harm_to_life: types.maybeNull(types.string),
  harm_to_health: types.maybeNull(types.string),
  harm_to_property: types.maybeNull(types.string)
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.string
})

const Document = types.model("Document", {
  id: types.maybeNull(types.integer),
  document_kind: types.maybeNull(DocumentKind),
  kind: types.maybeNull(newCustomEnum),
  submission: types.maybeNull(newCustomEnum),
  submission_status: types.maybeNull(newCustomEnum),
  submission_holder: types.maybeNull(types.string),
  submission_on: types.maybeNull(customDate),
  submission_other: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  version_id: types.maybeNull(types.integer),
  attachments: types.maybeNull(types.array(Attachment))
})

const Scan = types.model("Scan", {
  id: types.maybeNull(types.integer),
  document_kind: types.maybeNull(DocumentKind),
  document_kind_other: types.maybeNull(types.string),
  kind: types.maybeNull(newCustomEnum),
  comment: types.maybeNull(types.string),
  attachments: types.maybeNull(types.array(Attachment))
})

const Track = types.model("Track", {
  id: types.maybeNull(types.integer),
  created_at: types.maybeNull(customDate),
  event: types.maybeNull(types.string),
  item_type: types.maybeNull(types.string),
  user: types.maybeNull(types.frozen())
})

const Court = types.model("Court", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const ContractTask = types.model("ContractTask", {
  id: types.maybeNull(types.integer),
  subject: types.maybeNull(types.string),
  date_on: types.maybeNull(customDate),
  time_at: types.maybeNull(customTime),
  done_at: types.maybeNull(customDate),
  kind: types.maybeNull(newCustomEnum),
  status: types.maybeNull(newCustomEnum),
  performer: types.maybeNull(User),
  result: types.maybeNull(types.string),
  attachments: types.maybeNull(types.array(Attachment)),
  location: types.maybeNull(types.string),
  participant: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  court: types.maybeNull(Court),
  insurance_kind: types.maybeNull(InsuranceKind),
  parent: types.maybeNull(types.frozen()),
  creator: types.maybeNull(types.frozen()),
})

const ContractProceeding = types.model("ContractProceeding", {
  id: types.maybeNull(types.integer),
  subject: types.maybeNull(types.string),
  status: types.maybeNull(newCustomEnum),
  side: types.maybeNull(newCustomEnum),
  plaintiff: types.maybeNull(Client),
  plaintiff_comment: types.maybeNull(types.string),
  defendant: types.maybeNull(Client),
  defendant_comment: types.maybeNull(types.string),
  requirement_duty: types.maybeNull(types.string),
  requirement_penalty: types.maybeNull(types.string),
  requirement_fee: types.maybeNull(types.string),
  requirement_expense: types.maybeNull(types.string),
  bargain: types.maybeNull(types.string),
  case_number: types.maybeNull(types.string),
  court: types.maybeNull(Court),
  judge: types.maybeNull(types.string),
  result: types.maybeNull(newCustomEnum),
  result_comment: types.maybeNull(types.string),
  recovered_duty: types.maybeNull(types.string),
  recovered_penalty: types.maybeNull(types.string),
  recovered_expense: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  contract_tasks: types.array(ContractTask)
})

const ContractBankruptcy = types.model("ContractBankruptcy", {
  id: types.maybeNull(types.integer),
  subject: types.maybeNull(types.string),
  status: types.maybeNull(newCustomEnum),
  side: types.maybeNull(newCustomEnum),
  debtor: types.maybeNull(Client),
  debtor_comment: types.maybeNull(types.string),
  сreditor: types.maybeNull(Client),
  сreditor_comment: types.maybeNull(types.string),
  requirement_duty: types.maybeNull(types.string),
  requirement_penalty: types.maybeNull(types.string),
  requirement_fee: types.maybeNull(types.string),
  requirement_expense: types.maybeNull(types.string),
  bargain: types.maybeNull(types.string),
  case_number: types.maybeNull(types.string),
  court: types.maybeNull(Court),
  arbitration_manager: types.maybeNull(types.string),
  result: types.maybeNull(newCustomEnum),
  result_comment: types.maybeNull(types.string),
  recovered_duty: types.maybeNull(types.string),
  recovered_penalty: types.maybeNull(types.string),
  recovered_expense: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  contract_tasks: types.array(ContractTask)
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  avrs: types.maybeNull(types.array(Avr, [])),
  contract_returnment_avrs: types.maybeNull(types.array(Avr, [])),
  insurer: types.maybeNull(Insurer),
  insurer_contact: types.maybeNull(Contact),
  insurance_product: types.maybeNull(InsuranceProduct),
  channel: types.maybeNull(Channel),
  client: types.maybeNull(Client),
  insurance_kind: types.maybeNull(InsuranceKind),
  user: types.maybeNull(User),
  subdivision: types.maybeNull(Subdivision),
  customer_inviter: types.maybeNull(types.string),
  conclusion_on: types.maybeNull(customDate),
  number: types.maybeNull(types.string),
  policy_number: types.maybeNull(types.string),
  start_on: types.maybeNull(customDate),
  finish_on: types.maybeNull(customDate),
  duration: types.maybeNull(types.integer),
  comment: types.maybeNull(types.string),

  sp_accrual_on: types.maybeNull(customDate),
  sp_accrual_cost: types.maybeNull(types.string),
  sp_accrual_control_on: types.maybeNull(customDate),
  sp_paid_inflector: types.maybeNull(customEnum),
  contract_installments: types.maybeNull(types.array(ContractInstallment)),
  contract_returnments: types.maybeNull(types.array(ContractReturnment)),
  bsos: types.maybeNull(types.array(Bso)),

  subagent: types.maybeNull(Subagent),
  // backoffice
  sp_paid_sk_on: types.maybeNull(customDate),
  sp_sk_form: types.maybeNull(types.integer),
  sp_sk_retention: types.maybeNull(SpSkRetention),
  sp_sk_cost: types.maybeNull(types.string),

  // kv_sp_cost: types.maybeNull(types.string),
  // kv_sp_on: types.maybeNull(customDate),
  // kv_cost: types.maybeNull(types.string),

  kvs: types.maybeNull(types.array(Kv)),
  documents: types.maybeNull(types.array(Document)),
  scans: types.maybeNull(types.array(Scan)),

  aspod_on: types.maybeNull(customDate),
  aspod_number: types.maybeNull(types.string),

  avr_formation_on: types.maybeNull(customDate),
  avr_sk_on: types.maybeNull(customDate),
  avr_sk_paid_on: types.maybeNull(customDate),
  avr_on: types.maybeNull(customDate),
  avr_number: types.maybeNull(types.string),
  avr_cost: types.maybeNull(types.string),
  avr_original_on: types.maybeNull(customDate),
  // cargo

  cargo_beneficiary: types.maybeNull(types.string),
  cargos: types.maybeNull(types.array(Cargo)),
  transport_kinds: types.maybeNull(types.array(customEnum)),
  cargo_kind: types.maybeNull(CargoKind),
  cargo_procedure_for_submitting_declaration: types.maybeNull(CargoKind),
  cargo_procedure_for_certificate_issue: types.maybeNull(CargoKind),
  cargo_procedure_for_sp_payment: types.maybeNull(CargoKind),
  cargo_prefabricated: types.maybeNull(CargoPrefabricated),
  cargo_deposit_sp_cost: types.maybeNull(types.string),
  cargo_total_cost: types.maybeNull(types.string),
  cargo_condition: types.maybeNull(types.string),
  cargo_tarif: types.maybeNull(types.string),
  cargo_route: types.maybeNull(types.string),
  cargo_combined: types.maybeNull(CargoСombined),
  // cargo_carrier: types.maybeNull(types.string),
  // cargo_surveyer: types.maybeNull(types.string),
  cargo_comment: types.maybeNull(types.string),
  cargo_responsibility_from: types.maybeNull(customDate),
  cargo_responsibility_to: types.maybeNull(customDate),

  // property
  property_comment: types.maybeNull(types.string),
  contract_properties: types.maybeNull(types.array(ContractProperty)),
  use_bso: types.maybeNull(newCustomEnum),
  customer_inviter_is_subagent: types.maybeNull(newCustomEnum),
  parent: types.maybeNull(Parent),
  child: types.maybeNull(Child),

  contract_auto: types.maybeNull(ContractAuto),
  contract_accidents: types.maybeNull(types.array(Accident)),
  contract_go_transport: types.maybeNull(ContractGoTransport),
  contract_go_estate: types.maybeNull(ContractGoEstate),
  contract_osgop: types.maybeNull(ContractOsgop),
  contract_dms: types.maybeNull(ContractDms),
  contract_mortgage: types.maybeNull(ContractMortgage),
  contract_proceeding: types.maybeNull(ContractProceeding),
  contract_bankruptcy: types.maybeNull(ContractBankruptcy),

  status: types.maybeNull(newCustomEnum),
  kind: types.maybeNull(newCustomEnum),
  has_additional: types.maybeNull(types.boolean),
  additional_agreement_on: types.maybeNull(customDate),
  additional_start_on: types.maybeNull(customDate),
  additional_agreement_number: types.maybeNull(types.string),
  additional_agreement_comment: types.maybeNull(types.string),
  additional_agreement_kind: types.maybeNull(newCustomEnum),
  versions: types.maybeNull(types.array(types.frozen())),
  tracking: types.maybeNull(types.array(Track)),
  borderos: types.array(types.frozen()),
  parcel_borderos: types.array(types.frozen()),
  shipments: types.array(types.frozen()),
  is_prolongation: types.maybeNull(types.boolean),
  is_need_to_prolongation: types.maybeNull(types.boolean),
  cancel_prolongation_cause: types.maybeNull(types.string),
  is_without_kv: types.maybeNull(types.boolean),
  is_clone: types.maybeNull(types.boolean),
  couriers: types.array(Courier),
  fix_kv_channel: types.maybeNull(Channel),
  tag_list: types.maybeNull(types.array(types.string)),
  contract_scans: types.maybeNull(types.array(types.frozen())),
  was_released: types.maybeNull(types.boolean)
  // fix_kv_costs: types.frozen()
})

export { Contract }
