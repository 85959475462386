import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"
import customDate from "types/customDate"
import customTime from "types/customTime"

const Client = types.model("Client", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
})

const Court = types.model("Court", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const ContractTask = types.model("ContractTask", {
  id: types.maybeNull(types.integer),
  subject: types.maybeNull(types.string),
  date_on: types.maybeNull(customDate),
  time_at: types.maybeNull(customTime),
  done_at: types.maybeNull(customDate),
  kind: types.maybeNull(newCustomEnum),
  status: types.maybeNull(newCustomEnum),
  performer: types.maybeNull(User),
  result: types.maybeNull(types.string),
  attachments: types.maybeNull(types.array(Attachment)),
  location: types.maybeNull(types.string),
  participant: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  court: types.maybeNull(Court),
  parent: types.maybeNull(types.frozen()),
  contract: types.maybeNull(Contract),
  creator: types.maybeNull(User),
  insurance_kind: types.maybeNull(InsuranceKind),
})

export { ContractTask }
