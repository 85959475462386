import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import ChannelStore from "stores/ChannelStore/List"
import Breadcrumbs from "pages/Channels/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"

const channelStore = ChannelStore.create()

const List = () => {
  useEffect(() => {
    channelStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/channels/new">Создать</Button>
      </Card>
      <Table store={channelStore} />
      <Paginate store={channelStore} />
    </Fragment>
  )
}

export default observer(List)
