import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { RadioButtonGroup, RadioInput } from "components/UI"
import SubagentSelect from "components/Common/SubagentSelect/index.jsx"
import DocumentFlowBsoItems from "./DocumentFlowBsoItems"

const SpecificationTab = ({ store, values, ...props }) => {
  const { recipients } = store.filtersOptions
  return (
    <Fragment>
      <br />
      <RadioButtonGroup label="Получатель БСО" name="document_flow_bso.recipient.value">
        {recipients.map(({ label, value }) => (
          <RadioInput key={`document_flow_bso_recipient_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {values.document_flow_bso.recipient.value.toString() === "1" && (
        <SubagentSelect name="document_flow_bso.subagent" label="Партнер" />
      )}
      <DocumentFlowBsoItems />
    </Fragment>
  )
}

export default observer(SpecificationTab)
