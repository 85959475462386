import React from "react"
import { Route, Switch } from "react-router-dom"
import List from "./containers/List"
import CheckPermissions from "components/CheckPermissions"

const KvCosts = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("kv_costs")}>
    <Switch>
      <Route path="/kv_costs" component={List} />
    </Switch>
  </CheckPermissions>
)

export default KvCosts
