import React, { useContext } from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import AuthStoreContext from "contexts/AuthStoreContext"
import { withRouter } from "react-router-dom"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import differenceInDays from "date-fns/differenceInDays"

const Table = ({
  history,
  store,
  selectedRows,
  totalSelectedRow,
  isCheckedRow,
  onCheckedRow,
  isCheckedAllRows,
  onCheckedAllRows,
  resetSelected
}) => {
  const { data: currentUser } = useContext(AuthStoreContext)

  const { page } = store.meta.pagination

  const cost_sum = Object.values(selectedRows)
    .flat()
    .map((i) => parseMoney(i.cost))
    .reduce((a, b) => a + b, 0)

  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllRows(page)} onChange={(e) => onCheckedAllRows(e, page)} />
          </th>
          <th>Страховая компания</th>
          <th>Канал</th>
          <th>Страхователь</th>
          <th>Вид страхования</th>
          <th>№ договора</th>
          <th style={{width: '150px'}}>Оплаченное СП</th>
          <th>Дата оплаты СП</th>
          <th style={{width: '150px'}}>КВ по опл. СП</th>
          <th>№ АВР</th>
          <th>Дата подписания АВР</th>
          <th>V выставления</th>
          <th>Дата оплаты АВР</th>
          <th>V оборота</th>
        </tr>
      </thead>
      <tbody>
        {store.data.map((i, index) => (
          <tr key={`kv-cost-${index}`}>
            <td>
              <input type="checkbox" checked={isCheckedRow(i, page)} onChange={() => onCheckedRow(i, page)} />
            </td>
            <td>{i.contract_installment.contract.insurer.name}</td>
            <td>{i.channel.name}</td>
            <td>{i.contract_installment.contract.client.name}</td>
            <td>{i.contract_installment.contract.insurance_kind.name}</td>
            <td>{i.contract_installment.contract.number}</td>
            <td>{i.contract_installment.payment_cost}</td>
            <td>{formatDate(i.contract_installment.payment_on)}</td>
            <td>{i.cost}</td>
            <td>{i.avr.number}</td>
            <td>{formatDate(i.avr.signed_on)}</td>
            <td>{Math.abs(differenceInDays(i.avr.signed_on, i.contract_installment.payment_on) + 1)}</td>
            <td>{formatDate(i.avr.payment_on)}</td>
            <td>{differenceInDays(i.avr.payment_on, i.contract_installment.payment_on) + 1}</td>
          </tr>
        ))}
      </tbody>
      <tfoot className="thead-light">
        <tr>
          <th colSpan="13">
            Выбрано: {totalSelectedRow} | Сумма: {formatMoney(cost_sum)}
          </th>
          <th>
            <button
              type="button"
              className="btn btn-danger"
              title="Сбросить выделение"
              onClick={resetSelected}
              disabled={totalSelectedRow === 0}
            >
              <i className="fas fa-sync-alt"></i>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  )
}

export default withRouter(observer(Table))
