import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import CourtStore from "stores/CourtStore/One"
import ContactStore from "stores/ContactStore/One"
import Breadcrumbs from "pages/Courts/containers/Contacts/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import { fioHuman } from "stores/ContactStore/utils"

class Show extends Component {
  courtStore = CourtStore.create()
  contactStore = ContactStore.create()

  componentDidMount() {
    const { id, court_id } = this.props.match.params
    this.courtStore.fetch({ id: court_id })
    this.contactStore.fetch({ id, court_id })
  }

  render() {
    const {
      courtStore: { data: court },
      contactStore: { f_name, p_name, l_name, position, emails, phones }
    } = this
    return (
      <Fragment>
        <Breadcrumbs {...{ court }}>
          <BreadcrumbItem active>{fioHuman({ l_name, f_name, p_name })}</BreadcrumbItem>
        </Breadcrumbs>
        <br />
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>ФИО</td>
                  <td style={{ width: "50%" }}>{fioHuman({ l_name, f_name, p_name })}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Должность</td>
                  <td style={{ width: "50%" }}>{position}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Emails</td>
                  <td style={{ width: "50%" }}>{emails.map(({ value }) => value).join(", ")}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Телефоны</td>
                  <td style={{ width: "50%" }}>{phones.map(({ value }) => value).join(", ")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
