import React from "react"
import { observer } from "mobx-react"
import {
  ComposedChart,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts"
import formatMoney from "utils/formatMoney"

const ProgressAccumulationTable = ({ store }) => {
  const { prev_fact: prev_facts, fact: facts, year: year } = store.data.table
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябь",
    "Ноябрь",
    "Декабрь"
  ]

  let dataMax = 0

  let totalPrevFact = parseFloat(prev_facts[0])
  let totalFact = parseFloat(facts[0])

  const data = months.map((month, index) => {
    const prev_fact = parseFloat(prev_facts[index])
    const fact = parseFloat(facts[index])

    if (index > 0) {
      totalPrevFact = totalPrevFact + prev_fact
      totalFact = totalFact + fact
    }

    // if (fact == 0) {
    //   totalFact = 0
    // }

    const max = Math.max(prev_fact, fact)
    if (dataMax < max) {
      dataMax = max
    }

    const percent = Math.round((totalFact / totalPrevFact) * 100) - 100
    return {
      name: month,
      "%": isFinite(percent) ? percent : 0,
      [`Факт ${year - 1}`]: parseFloat(totalPrevFact.toFixed(2)),
      [`Факт ${year}`]: parseFloat(totalFact.toFixed(2))
    }
  })

  dataMax = Math.round(dataMax + dataMax * 0.1)

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 30,
            bottom: 20,
            left: 30
          }}
        >
          <XAxis dataKey="name" />
          <YAxis domain={["auto", dataMax]} type="number" yAxisId="1" />
          <YAxis domain={["auto", "auto"]} orientation="right" type="number" yAxisId="2" />
          <Tooltip formatter={(value, name, props) => formatMoney(value)} />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Line type="monotone" dataKey="%" stroke="#a5a5a5" yAxisId="2" />
          <Bar dataKey={`Факт ${year - 1}`} fill="#4571c4" yAxisId="1" />
          <Bar dataKey={`Факт ${year}`} fill="#ed7d32" yAxisId="1" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
export default observer(ProgressAccumulationTable)
