import React from "react"
import { observer } from "mobx-react"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"

const Table = ({ store }) => {
  return (
    <div style={{ width: "100%", height: "500px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={store.data.map((i) => {
            return { ...i, "КВ по нач. СП": parseMoney(i["КВ по нач. СП"]) }
          })}
          margin={{
            top: 5,
            right: 30,
            left: 30,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip formatter={(value, name, props) => formatMoney(value)} />
          <Legend />
          {store.meta.colors.map((color, index) => (
            <Line type="monotone" dataKey={`КВ по нач. СП ${index + 1}`} stroke={`#${color}`} activeDot={{ r: 8 }} />
          ))}
        </LineChart>
      </ResponsiveContainer>
      {store.meta.colors.map((color, index) => (
        <h6 style={{ color: `#${color}`, marginTop: "20px", marginBottom: "20px" }}>
          КВ по нач. СП (итого): {formatMoney(store.meta.statistic[`КВ по нач. СП ${index + 1}`].sum)} | Макс. значение:{" "}
          {formatMoney(store.meta.statistic[`КВ по нач. СП ${index + 1}`].max)} | Мин. значение:{" "}
          {formatMoney(store.meta.statistic[`КВ по нач. СП ${index + 1}`].min)} | Среднее значение:{" "}
          {formatMoney(store.meta.statistic[`КВ по нач. СП ${index + 1}`].avg)}
        </h6>
      ))}
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={store.data.map((i) => {
            return { ...i, "СП начисленная": parseMoney(i["СП начисленная"]) }
          })}
          margin={{
            top: 5,
            right: 30,
            left: 30,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip formatter={(value, name, props) => formatMoney(value)} />
          <Legend />
          {store.meta.colors.map((color, index) => (
            <Line type="monotone" dataKey={`СП начисленная ${index + 1}`} stroke={`#${color}`} activeDot={{ r: 8 }} />
          ))}
        </LineChart>
      </ResponsiveContainer>
      {store.meta.colors.map((color, index) => (
        <h6 style={{ color: `#${color}`, marginTop: "20px", marginBottom: "20px" }}>
          СП начисленная (итого): {formatMoney(store.meta.statistic[`СП начисленная ${index + 1}`].sum)} | Макс. значение:{" "}
          {formatMoney(store.meta.statistic[`СП начисленная ${index + 1}`].max)} | Мин. значение:{" "}
          {formatMoney(store.meta.statistic[`СП начисленная ${index + 1}`].min)} | Среднее значение:{" "}
          {formatMoney(store.meta.statistic[`СП начисленная ${index + 1}`].avg)}
        </h6>
      ))}
    </div>
  )
}

export default observer(Table)
