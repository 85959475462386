import { types } from "mobx-state-tree"
import customDateTime from "types/customDateTime"
import newCustomEnum from "types/newCustomEnum"

const Email = types.model("Email", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const Phone = types.model("Phone", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Specialization = types.model("Specialization", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Contact = types.model("Contact", {
  id: types.maybeNull(types.integer),
  l_name: types.maybeNull(types.string),
  f_name: types.maybeNull(types.string),
  p_name: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  insurance_kinds: types.maybeNull(types.array(InsuranceKind)),
  emails: types.maybeNull(types.array(Email)),
  phones: types.maybeNull(types.array(Phone))
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Creator = types.model("Creator", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  subdivision: types.maybeNull(Subdivision)
})

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.string
})

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const AgencyContract = types.model("AgencyContract", {
  id: types.maybeNull(types.integer),
  document_kind: types.maybeNull(DocumentKind),
  channel: types.maybeNull(Channel),
  target: types.maybeNull(newCustomEnum),
})

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  short_name: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  inn: types.maybeNull(types.string),
  site: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  // phone: types.maybeNull(types.string),
  contacts: types.maybeNull(types.array(Contact)),
  specializations: types.maybeNull(types.array(Specialization)),
  agency_contracts: types.maybeNull(types.array(AgencyContract)),
  creator: types.maybeNull(Creator),
  updated_at: types.maybeNull(customDateTime)
})

export { Insurer }
