import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import BsoBatchStore from "stores/BsoBatchStore/List"

const bsoBatchStore = BsoBatchStore.create()

const BsoBatchSelect = ({ name = "bso_batch", bso_kind = null, required = false }) => {
  const { values } = useFormikContext()
  const bso_batch = getIn(values, name, {}) || {}

  useEffect(() => {
    bso_kind && bsoBatchStore.fetch({ filter: { by_bso_kind_id: bso_kind.id } })
  }, [bso_kind])

  const bsoBatchOptions = bso_batch.id ? [{ label: bso_batch.name, value: bso_batch.id }] : bsoBatchStore.bsoBatchOptions

  const loadBsoBatchOptions = (inputValue) =>
    bsoBatchStore.fetch({ filter: { by_name: inputValue }, limit: 0 }).then(({ bsoBatchOptions }) => bsoBatchOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label="Серия БСО"
        required={required}
        options={bsoBatchOptions}
        isSearchable
        loadOptions={loadBsoBatchOptions}
        isDisabled={!bso_kind}
      />
    </div>
  )
}

export default observer(BsoBatchSelect)
