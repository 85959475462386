import React, { Fragment } from "react"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import {
  AddressInput,
  InnInput,
  RadioButtonGroup,
  RadioInput,
  OgrnInput,
  TextArea,
  TextInput,
  KppInput,
  PaymentAccountInput,
  BikInput,
  CorrespondentAccountInput,
  BankInput
} from "components/UI"
import Documents from "./Documents"
import HoldingSelect from "components/Common/HoldingSelect"
import UserSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/UserSelect"
import SubdivisionSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/SubdivisionSelect"
import IndustrySelect from "./IndustrySelect"
import StrengthSelect from "./StrengthSelect"

const InfoTab = ({ store, handleSubmit, values, setFieldValue, ...p }) => {
  const { ownership_kinds, legal_supports } = store.filtersOptions
  return (
    <Tabs defaultActiveKey="info">
      <Tab eventKey="info" title="Общие сведения">
        <br />
        <RadioButtonGroup label="Тип" name="kind">
          <RadioInput label="Физическое лицо" id="individual" />
          <RadioInput label="Юридическое лицо" id="entity" />
        </RadioButtonGroup>
        {values.kind === "individual" && (
          <React.Fragment>
            <TextInput name="l_name" label="Фамилия" required />
            <TextInput name="f_name" label="Имя" required />
            <TextInput name="p_name" label="Отчество" />
            <TextInput name="position" label="Должность" />
            <UserSelect name="user" label="Создал" />
            <SubdivisionSelect name="subdivision" label="Подразделение" />
            <TextArea name="comment" label="Комментарий" />
          </React.Fragment>
        )}
        {values.kind === "entity" && (
          <Fragment>
            <TextInput name="organization_name" label="Наименование" required />
            <RadioButtonGroup label="Форма собственности" name="ownership_kind.value">
              {ownership_kinds.map(({ label, value }) => (
                <RadioInput key={`kind_${value}`} label={label} id={value} />
              ))}
            </RadioButtonGroup>
            <InnInput
              name="inn"
              label="ИНН"
              kind={values.ownership_kind.value && values.ownership_kind.value.toString() === "2" ? "inn" : "entity"}
              required
            />
            <HoldingSelect name="holding.id" />
            <AddressInput name="address" label="Фактический адрес" />
            <UserSelect name="user" label="Создал" />
            <SubdivisionSelect name="subdivision" label="Подразделение" />
            <TextArea name="comment" label="Комментарий" />
          </Fragment>
        )}
      </Tab>
      {values.kind === "entity" && (
        <Tab eventKey="additional" title="Доп. сведения">
          <br />
          <IndustrySelect name="industry" label="Отрасль" />
          <TextInput name="kind_of_action" label="Вид деятельности" />
          <StrengthSelect name="strength" />
          <RadioButtonGroup label="Юридическое обеспечение" name="legal_support.value">
            {legal_supports.map(({ label, value }) => (
              <RadioInput key={`legal_support_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
        </Tab>
      )}
      {values.kind === "entity" && (
        <Tab eventKey="requisites" title="Банковские реквизиты">
          <br />
          <TextInput name="full_name" label="Полное наименование" />
          <TextInput name="director" label="ФИО генерального директора" />
          <KppInput name="kpp" label="КПП" />
          <OgrnInput
            name="ogrn"
            label="ОГРН"
            kind={values.ownership_kind.value && values.ownership_kind.value.toString() === "2" ? "inn" : "entity"}
          />
          <AddressInput name="legal_address" label="Юридический адрес" />
          <BankInput
            name="bank"
            label="Банк"
            onChange={({ value, data }) => {
              setFieldValue("bank", value)
              setFieldValue("bik", data.bic)
              setFieldValue("correspondent_account", data.correspondent_account)
            }}
          />
          <PaymentAccountInput name="payment_account" label="Расчетный счет" />
          <BikInput name="bik" label="БИК" />
          <CorrespondentAccountInput name="correspondent_account" label="Корр.счет" />
        </Tab>
      )}
      <Tab eventKey="client_documents" title="Документы">
        <br />
        <Documents store={store} />
      </Tab>
    </Tabs>
  )
}

export default InfoTab
