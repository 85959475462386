import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Contacts from "./containers/Contacts"
import Contracts from "./containers/Contracts"
import CheckPermissions from "components/CheckPermissions"

const Subagents = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("subagents")}>
    <Switch>
      <Route path="/subagents/:id/edit" component={Edit} />
      <Route path="/subagents/:id/contacts" component={Contacts} />
      <Route path="/subagents/:id/contracts" component={Contracts} />
      <Route path="/subagents/new" component={Add} />
      <Route path="/subagents/:id" component={Show} />
      <Route path="/subagents" component={List} />
    </Switch>
  </CheckPermissions>
)

export default Subagents
