import React from "react"
import classNames from "classnames"
import { Link, withRouter } from "react-router-dom"

const NavLink = ({ isActive, to, children }) => (
  <li className={classNames("nav-item", { active: isActive })}>
    <Link className="nav-link" to={to}>
      {children}
    </Link>
  </li>
)

export default withRouter(({ location, ...props }) => {
  const isActive = location.pathname.startsWith(props.to)
  return <NavLink {...props} isActive={isActive} />
})
