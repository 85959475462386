import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import customDate from "types/customDate"
import customEnum from "types/customEnum"
import format from "date-fns/format"
import toOptions from "utils/newToOptions"
import { Loss } from "./Loss"

const Filter = types.model("Filter", {
  by_channel: types.maybeNull(customEnum),
  by_insurer: types.maybeNull(customEnum),
  by_insurance_kind: types.maybeNull(customEnum),
  by_client: types.maybeNull(customEnum),
  by_date_on_from: types.maybeNull(customDate),
  by_date_on_to: types.maybeNull(customDate),
  by_status: types.maybeNull(types.string),
  by_result: types.maybeNull(types.string)
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const LossStore = types
  .model("Loss", {
    data: types.array(Loss, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { kinds, submissions } = self.meta.filters_options
      return {
        kinds: toOptions(kinds),
        submissions: toOptions(submissions)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      const { contract_id, ...options } = params
      const url = contract_id ? `/contracts/${contract_id}/losses` : `/losses`

      if (params.filter) {
        const { by_date_on_from, by_date_on_to } = params.filter
        if (by_date_on_from) {
          params.filter = { ...params.filter, by_date_on_from: format(by_date_on_from, "yyyy-MM-dd") }
        }
        if (by_date_on_to) {
          params.filter = { ...params.filter, by_date_on_to: format(by_date_on_to, "yyyy-MM-dd") }
        }
      }

      return instance
        .get(url, { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(params) {
      const { id, contract_id } = params
      self.setState("pending")
      const url = contract_id ? `/contracts/${contract_id}/losses/${id}` : `/losses/${id}`
      return instance.delete(url)
      // .then(response => dispatch(destroyUserSuccess(response)))
      // .catch(error => dispatch(destroyUserFailure(error)))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default LossStore
