import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import { Breadcrumb, BreadcrumbItem, Card, Paginate } from "components/UI"
import Filter from "./components/Filter"
import Table from "./components/Table"
import ContractStore from "stores/ContractStore/PaymentPlan"
import { historyPushFromStore } from "utils/historyPushWithStore"
import getParams from "utils/getParams"
import useSelectAll from "hooks/useSelectAll"

const сontractStore = ContractStore.create()

const Prolongations = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    сontractStore.fetch(getParams())
  }, [])

  const [
    selectedRows,
    totalSelectedRow,
    isCheckedRow,
    onCheckedRow,
    isCheckedAllRows,
    onCheckedAllRows,
    resetSelected
  ] = useSelectAll(сontractStore.data.toJSON(), false)

  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem active>План выплат СА</BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <Filter store={сontractStore} />
      </Card>
      <Table
        store={сontractStore}
        selectedRows={selectedRows}
        totalSelectedRow={totalSelectedRow}
        isCheckedRow={isCheckedRow}
        onCheckedRow={onCheckedRow}
        isCheckedAllRows={isCheckedAllRows}
        onCheckedAllRows={onCheckedAllRows}
        resetSelected={resetSelected}
      />
      <Paginate store={сontractStore} />
    </Fragment>
  )
}

export default observer(Prolongations)
