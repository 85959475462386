import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const BsoBatch = types.model("BsoBatch", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  status: types.maybeNull(newCustomEnum)
})

const BsoKind = types.model("BsoKind", {
  id: types.maybeNull(types.integer),
  insurer: types.maybeNull(Insurer),
  insurance_kind: types.maybeNull(InsuranceKind),
  name: types.maybeNull(types.string),
  numbers_count: types.maybeNull(types.integer),
  comment: types.maybeNull(types.string),
  bso_batches: types.array(BsoBatch)
})

export { BsoKind }
