import React, { useContext, useEffect } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import dashboard from "assets/images/dashboard.jpg"
import analiticIcon from "assets/images/icons/analitic-kv.png"
import avrIcon from "assets/images/icons/avr.png"
import cashFlowIcon from "assets/images/icons/cash-flow.png"
import ddsIcon from "assets/images/icons/dds.png"
import debirReestrIcon from "assets/images/icons/debir-reestr.png"
import debitIcon from "assets/images/icons/debit.png"
import { ProgressBar } from "react-bootstrap"
import { PieChart, Pie, Label, Cell, Tooltip } from "recharts"
import DashboardStore from "stores/DashboardStore"
import { observer } from "mobx-react"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import { Fragment } from "react"

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 }
]
const COLORS = ["#4e73df", "#ffc107"]

const dashboardStore = DashboardStore.create()

const Dashboard = () => {
  useEffect(() => {
    dashboardStore.fetch()
  }, [])

  const { data: currentUser } = useContext(AuthStoreContext)
  const name = String(currentUser?.name).split(" ")[1]

  return (
    <Fragment>
      <div style={{ position: "relative" }}>
        <div>
          <img src={dashboard} width="100%" />
        </div>
        <div className="container-fluid" style={{ position: "absolute", zIndex: 1000, top: 0 }}>
          {/* {[5, 23].includes(currentUser.id) && (
            <div className="row">
              <div
                className="col"
                style={{
                  // backgroundColor: "white",
                  marginTop: "1.5rem",
                  marginRight: "0.75rem",
                  marginLeft: "0.75rem",
                  padding: "0.75rem",
                  borderRadius: "5px"
                }}
              >
                <div className="row">
                  <div
                    className="col-2"
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      style={{
                        // background: "#ffc107",
                        width: "170px",
                        minHeight: "110px",
                        padding: "0.75rem",
                        borderRadius: "5px",
                        textAlign: "center"
                      }}
                    >
                      <a href="/operations" style={{ textAlign: "center" }}>
                        <img src={ddsIcon} />
                        <p>ДДС</p>
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      style={{
                        // background: "#ffc107",
                        width: "170px",
                        minHeight: "110px",
                        padding: "0.75rem",
                        borderRadius: "5px",
                        textAlign: "center"
                      }}
                    >
                      <a href="/reports/cashflow" style={{ textAlign: "center" }}>
                        <img src={cashFlowIcon} />
                        <p>Cash-flow</p>
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      style={{
                        // background: "#ffc107",
                        width: "170px",
                        minHeight: "110px",
                        padding: "0.75rem",
                        borderRadius: "5px",
                        textAlign: "center"
                      }}
                    >
                      <a href="/reports/debits" style={{ textAlign: "center" }}>
                        <img src={debitIcon} />
                        <p>Дебиторка</p>
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      style={{
                        // background: "#ffc107",
                        width: "170px",
                        minHeight: "110px",
                        padding: "0.75rem",
                        borderRadius: "5px",
                        textAlign: "center"
                      }}
                    >
                      <a href="/debits" style={{ textAlign: "center" }}>
                        <img src={debirReestrIcon} />
                        <p>Реестр дебиторки</p>
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      style={{
                        // background: "#ffc107",
                        width: "170px",
                        minHeight: "110px",
                        padding: "0.75rem",
                        borderRadius: "5px",
                        textAlign: "center"
                      }}
                    >
                      <a href="/reports/analytics_kv" style={{ textAlign: "center" }}>
                        <img src={analiticIcon} />
                        <p>Аналитика КВ</p>
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      style={{
                        // background: "#ffc107",
                        width: "170px",
                        minHeight: "110px",
                        padding: "0.75rem",
                        borderRadius: "5px",
                        textAlign: "center"
                      }}
                    >
                      <a href="/avrs" style={{ textAlign: "center" }}>
                        <img src={avrIcon} />
                        <p>АВР</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          <div className="row">
            {Object.values([dashboardStore.data["month"], dashboardStore.data["quarter"]]).map((i, index) => (
              <div
                className="col"
                style={{
                  backgroundColor: "white",
                  marginTop: "1.5rem",
                  marginRight: "0.75rem",
                  marginLeft: "0.75rem",
                  padding: "0.75rem",
                  borderRadius: "5px"
                }}
              >
                <h6 style={{ color: "#4e73df" }}>КВ по оплаченной СП за {i.title}</h6>
                <h3 style={{ color: "#4e73df", fontWeight: "bold" }}>
                  {formatMoney(i.by_paid_sp_fact)}₽{" "}
                  <span style={{ color: "grey", fontSize: "16px" }}>
                    из{" "}
                    {index === 0
                      ? formatMoney(dashboardStore.data.month_plan)
                      : formatMoney(dashboardStore.data.quarter_plan)}
                    ₽
                  </span>
                </h3>
                <p>
                  <strong>{formatMoney(i.previous_by_paid_sp_fact)}₽</strong>
                  <br />
                  за прошлый год
                </p>
                <br />
                <ProgressBar now={(i.days_passed / (i.days_passed + i.days_until)) * 100} />
                <p className="float-left">Дней прошло: {i.days_passed}</p>
                <p className="float-right">До конца периода: {i.days_until}</p>
                <div style={{ position: "absolute", top: "0.75rem", right: "0.75rem" }}>
                  <PieChart width={120} height={120}>
                    <Pie
                      data={[
                        { name: "План", value: (parseMoney(i.by_paid_sp_fact) / parseMoney(i.by_paid_sp_plan)) * 100 },
                        {
                          name: "Факт",
                          value: 100 - (parseMoney(i.by_paid_sp_fact) / parseMoney(i.by_paid_sp_plan)) * 100
                        }
                      ]}
                      cx={55}
                      cy={55}
                      innerRadius={40}
                      outerRadius={60}
                      fill="#8884d8"
                      paddingAngle={0}
                      isAnimationActive={false}
                    >
                      {data.map((entry, index) => (
                        <Cell fill={COLORS[index % COLORS.length]} />
                      ))}
                      <Label
                        value={((parseMoney(i.by_paid_sp_fact) / parseMoney(i.by_paid_sp_plan)) * 100).toFixed(3) + "%"}
                        position="center"
                      />
                    </Pie>
                  </PieChart>
                </div>
              </div>
            ))}
          </div>
          {dashboardStore.data.kpi && (
            <div className="row">
              <div
                className="col"
                style={{
                  backgroundColor: "white",
                  marginTop: "1.5rem",
                  marginRight: "0.75rem",
                  marginLeft: "0.75rem",
                  padding: "0.75rem",
                  borderRadius: "5px"
                }}
              >
                <h6 style={{ color: "#4e73df" }}>Дебиторка всего</h6>
                <h3 style={{ color: "#4e73df", fontWeight: "bold" }}>{formatMoney(dashboardStore.data.total)}₽</h3>
                <p>
                  <strong>{formatMoney(dashboardStore.data.kpi)}₽</strong>
                  <br />
                  KPI уровня дебиторки
                </p>
                <br />
                {dashboardStore.data.turnover_rate >= dashboardStore.data.avr_average && (
                  <Fragment>
                    <ProgressBar
                      variant="success"
                      now={(dashboardStore.data.avr_average / dashboardStore.data.turnover_rate) * 100}
                    />
                    <br />
                    <ProgressBar now={100} />
                  </Fragment>
                )}
                {dashboardStore.data.turnover_rate < dashboardStore.data.avr_average && (
                  <Fragment>
                    <ProgressBar variant="success" now={100} />
                    <br />
                    <ProgressBar now={(dashboardStore.data.turnover_rate / dashboardStore.data.avr_average) * 100} />
                  </Fragment>
                )}
                <p className="float-left">
                  <i className="fa fa-circle" style={{ color: "#4e73df" }} />
                  &nbsp;KPI: {dashboardStore.data.turnover_rate}
                </p>
                <p className="float-right">
                  <i className="fa fa-circle" style={{ color: "#1cc88a" }} />
                  &nbsp;Скорость: {dashboardStore.data.avr_average}
                </p>
                <div style={{ position: "absolute", top: "0.75rem", right: "0.75rem" }}>
                  <PieChart width={120} height={120}>
                    <Pie
                      data={[
                        { name: "Не выставленно", value: parseMoney(dashboardStore.data.not_expose) },
                        {
                          name: "Не оплаченно",
                          value: parseMoney(dashboardStore.data.not_paid)
                        }
                      ]}
                      cx={55}
                      cy={55}
                      innerRadius={40}
                      outerRadius={60}
                      fill="#8884d8"
                      paddingAngle={0}
                      isAnimationActive={false}
                    >
                      {data.map((entry, index) => (
                        <Cell fill={COLORS[index % COLORS.length]} />
                      ))}
                      <Label
                        value={
                          dashboardStore.data["excess"] > 0
                            ? `+${dashboardStore.data["excess"]}%`
                            : `${dashboardStore.data["excess"]}%`
                        }
                        position="center"
                        style={{
                          fill: dashboardStore.data["excess"] > 0 ? "rgba(255, 0, 0, 1)" : "rgba(0, 128, 0)"
                        }}
                      />
                    </Pie>
                    <Tooltip formatter={(value, name, props) => formatMoney(value)} />
                  </PieChart>
                </div>
              </div>
              <div
                className="col"
                style={{
                  marginRight: "0.75rem",
                  marginLeft: "0.75rem",
                  padding: "0.75rem",
                  borderRadius: "5px"
                }}
              ></div>

              {currentUser.subdivision.id != 8 && (
                <div
                  className="col"
                  style={{
                    marginRight: "0.75rem",
                    marginLeft: "0.75rem",
                    padding: "0.75rem",
                    borderRadius: "5px"
                  }}
                ></div>
              )}

              {currentUser.subdivision.id == 8 && (
                <div
                  className="col"
                  style={{
                    backgroundColor: "white",
                    marginTop: "1.5rem",
                    marginRight: "0.75rem",
                    marginLeft: "0.75rem",
                    padding: "0.75rem",
                    borderRadius: "5px"
                  }}
                >
                  <h6 style={{ color: "#4e73df" }}>Баланс</h6>
                  <h3 style={{ color: "#4e73df", fontWeight: "bold" }}>
                    {formatMoney(
                      parseMoney(dashboardStore.data.placements_active) -
                        parseMoney(dashboardStore.data.placements_passive)
                    )}
                    ₽
                  </h3>
                  <br />
                  <Fragment>
                    <ProgressBar variant="success" now={100} />
                    <br />
                    <ProgressBar
                      now={(dashboardStore.data.placements_passive / dashboardStore.data.placements_active) * 100}
                    />
                  </Fragment>
                  <p className="float-left">
                    <i className="fa fa-circle" style={{ color: "#1cc88a" }} />
                    &nbsp;Активы: {formatMoney(dashboardStore.data.placements_active)}
                  </p>
                  <p className="float-right">
                    <i className="fa fa-circle" style={{ color: "#4e73df" }} />
                    &nbsp;Пассивы: {formatMoney(dashboardStore.data.placements_passive)}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default observer(Dashboard)
