import { types } from "mobx-state-tree"
import customDate from "types/customDate"

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  short_name: types.maybeNull(types.string)
})

const Holding = types.model("Holding", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  curator: types.maybeNull(User),
  subdivision: types.maybeNull(Subdivision),
  clients_count: types.maybeNull(types.number),
  creator: types.maybeNull(User),
  created_at: types.maybeNull(customDate)
})

export { Holding }
