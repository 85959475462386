import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import CheckPermissions from "components/CheckPermissions"

const Subdivisions = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("subdivisions")}>
    <Switch>
      <Route path="/subdivisions/:id/edit" component={Edit} />
      <Route path="/subdivisions/new" component={Add} />
      <Route path="/subdivisions/:id" component={Show} />
      <Route path="/subdivisions" component={List} />
    </Switch>
  </CheckPermissions>
)

export default Subdivisions
