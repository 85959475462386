import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import customEnum from "types/customEnum"
import Channel from "./Channel"

const Filter = types.model("Filter", {
  // by_insurer_id: types.maybeNull(types.integer),
  // by_channel_id: types.maybeNull(types.integer),

  by_channel: types.maybeNull(customEnum),
  by_insurer: types.maybeNull(customEnum),
  by_document_kind: types.maybeNull(customEnum),
  by_specialization: types.maybeNull(customEnum),
  by_finish_on_from: types.maybeNull(customDate),
  by_finish_on_to: types.maybeNull(customDate),
  by_target: types.maybeNull(types.integer),
  by_number: types.maybeNull(types.union(types.integer, types.string)),
  by_parent_number: types.maybeNull(types.union(types.integer, types.string))
})

const FilterOptions = types.model("FilterOptions", {
  channels: types.array(Channel, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

export default Meta
