import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import BsoKindStore from "stores/BsoKindStore/List"

const bsoKindStore = BsoKindStore.create()

const BsoKindSelect = ({ name = "by_bso_kind", insurer, required = false }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    bsoKindStore.fetch({ filter: { by_insurer_id: insurer.id } })
  }, [])

  const bsoKind = getIn(values, name, {})

  const bsoKindOptions = bsoKind.id ? [{ label: bsoKind.name, value: bsoKind.id }] : bsoKindStore.bsoKindOptions

  const loadBsoKindOptions = (inputValue) =>
    bsoKindStore.fetch({ filter: { by_name: inputValue }, limit: 0 }).then(({ bsoKindOptions }) => bsoKindOptions)
  // by_insurer_id
  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label="Вид БСО"
        required={required}
        options={bsoKindOptions}
        isSearchable
        loadOptions={loadBsoKindOptions}
      />
    </div>
  )
}

export default observer(BsoKindSelect)
