import React, { useContext } from "react"
import { Tab, Tabs } from "components/UI"
import { observer } from "mobx-react"
import AuthStoreContext from "contexts/AuthStoreContext"

const TabsBlock = ({ store }) => {
  const { id } = store.data
  const { data: currentUser } = useContext(AuthStoreContext)
  const mapping = {
    [`/contracts/${id}`]: "Договор",
    [`/contracts/${id}/contract_documents`]: "Сдача документов",
    [`/contracts/${id}/contract_scans`]: "Сканы документов",
    [`/contracts/${id}/backoffice`]: "Бэк-офис",
    [`/contracts/${id}/operations`]: "Страховая премия",
    // [`/contracts/${id}/operation_consumptions`]: "ДДС"
  }
  if (
    store.data.insurance_kind.id &&
    store.data.insurance_kind.id.toString() === "10" &&
    store.data.cargo_kind.id.toString() === "1"
  ) {
    
    if (store.data.client.id === 1319 && store.data.insurer.id === 2 && store.data.insurance_kind.id === 10 && store.data.cargo_kind.id === 1) {
      mapping[`/contracts/${id}/parcel_borderos`] = "Бордеро"
    } else {
      mapping[`/contracts/${id}/borderos`] = "Бордеро"
    }
    if (store.data.client.id === 1319 && store.data.insurer.id === 2 && store.data.insurance_kind.id === 10 && store.data.cargo_kind.id === 1) {
      mapping[`/contracts/${id}/parcels`] = "Отгрузки"
    } else {
      mapping[`/contracts/${id}/shipments`] = "Отгрузки"
    }
  }

  mapping[`/contracts/${id}/operation_consumptions`] = "ДДС"

  if ([5, 23].includes(currentUser.id)) {
    mapping[`/contracts/${id}/tracking`] = "Трекинг"
  }
  return (
    <Tabs>
      {Object.entries(mapping).map((i) => {
        const active = window.location.pathname === i[0]
        return (
          <Tab key={i[0]} active={active} to={i[0]}>
            {i[1]}
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default observer(TabsBlock)
