import React, { useContext } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"

const Destroy = ({ action, disabled = false, confirmMessage = "Уверены?" }) => {
  const onClick = (e) => {
    e.preventDefault()
    if (window.confirm(confirmMessage)) {
      action()
    }
  }

  const { data: currentUser } = useContext(AuthStoreContext)
  // disabled = disabled || ![3, 5, 23].includes(currentUser.id)

  return (
    <button type="button" className="btn btn-link btn-action" disabled={disabled} onClick={onClick}>
      <i className="fas fa-trash-alt" />
    </button>
  )
}
export default Destroy
