import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"
import customDate from "types/customDate"

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
  // subdivision: types.maybeNull(Subdivision)
})

const Creator = types.model("Creator", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  subdivision: types.maybeNull(Subdivision)
})

const Avr = types.model("Avr", {
  id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string)
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string)
})

const Counterpart = types.model("Counterpart", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.string
})

const Bill = types.model("Bill", {
  id: types.maybeNull(types.integer),
  index: types.maybeNull(types.string),
  short_name: types.maybeNull(types.string),
  first_level_bill_name: types.maybeNull(types.string),
  second_level_bill_name: types.maybeNull(types.string),
  third_level_bill_name: types.maybeNull(types.string),
  name: types.maybeNull(types.string)
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const OperationScan = types.model("OperationScan", {
  id: types.maybeNull(types.integer),
  operation_id: types.maybeNull(types.integer),
  document_kind: types.maybeNull(DocumentKind),
  document_kind_other: types.maybeNull(types.string),
  kind: types.maybeNull(newCustomEnum),
  comment: types.maybeNull(types.string),
  attachments: types.maybeNull(types.array(Attachment))
})

const Project = types.model("Project", {
  id: types.maybeNull(types.integer),
  main: types.frozen(),
  sub: types.frozen(),
})

const Operation = types.model("Operation", {
  id: types.maybeNull(types.integer),
  status: types.maybeNull(newCustomEnum),
  kind: types.maybeNull(newCustomEnum),
  operation_kind: types.maybeNull(newCustomEnum),
  subdivision: types.maybeNull(Subdivision),
  date_on: types.maybeNull(customDate),
  refund_on: types.maybeNull(customDate),
  cost: types.maybeNull(types.string),
  currency: types.maybeNull(newCustomEnum),
  comment: types.maybeNull(types.string),
  channel: types.maybeNull(Channel),
  form: types.maybeNull(newCustomEnum),
  cash_kind: types.maybeNull(newCustomEnum),
  moving_kind: types.maybeNull(newCustomEnum),
  is_need_fiscal: types.maybeNull(types.boolean),
  // is_prepayment: types.maybeNull(types.boolean),
  prepayment: types.maybeNull(newCustomEnum),
  is_prolongation: types.maybeNull(types.boolean),
  fiscal: types.maybeNull(types.boolean),
  user: types.maybeNull(User),
  creator: types.maybeNull(Creator),
  avrs: types.maybeNull(types.array(Avr)),
  contracts: types.maybeNull(types.array(Contract)),
  relation_contract: types.maybeNull(Contract),
  counterpart: types.maybeNull(Counterpart),
  bill: types.maybeNull(Bill),
  balance_bill: types.maybeNull(Bill),
  from_bill: types.maybeNull(Bill),
  to_bill: types.maybeNull(Bill),

  cashier_from: types.maybeNull(User),
  cashier_to: types.maybeNull(User),
  bank: types.maybeNull(types.string),
  operation_scans: types.maybeNull(types.array(OperationScan)),
  updated_at: types.maybeNull(customDate),
  payment_plan_id: types.maybeNull(types.integer),
  month: types.maybeNull(types.integer),
  quarter: types.maybeNull(types.integer),
  year: types.maybeNull(types.integer),
  project: types.maybeNull(Project),
  is_selfemployed: types.maybeNull(types.boolean),
  rate: types.maybeNull(types.string),
  duration: types.maybeNull(types.integer),
})

export { Operation }
