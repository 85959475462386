import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Breadcrumb, BreadcrumbItem } from "components/UI"
import formatMoney from "utils/formatMoney"
import { Link } from "react-router-dom"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import { PieChart, Pie, Label, Cell, Tooltip } from "recharts"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from "recharts"

import instance from "connection/instance"

const Operations = (props) => {
  const [data, setData] = useState({ channels: [], rows: [] })

  useEffect(() => {
    instance.get("/reports/operations").then(({ data }) => {
      setData(data)
    })
  }, [])

  const rr = []
  if (data.rows) {
    data.rows.forEach((i) => {
      const row = rr.find((rr2) => rr2.name === i.insurer_name) || {
        name: i.insurer_name,
        Банк: 0,
        Касса: 0,
        Итого: 0
      }

      Object.values(i.operations).forEach((operation) => {
        row["Банк"] = row["Банк"] + operation.bank
        row["Касса"] = row["Касса"] + operation.cashbox
        row["Итого"] = row["Итого"] + operation.total
      })

      const rowIndex = rr.findIndex((rr2) => rr2.name === i.insurer_name)

      if (rowIndex > -1) {
        rr[rowIndex] = row
      } else {
        rr.push(row)
      }
    })
  }

  rr.sort((a, b) => (a["Итого"] > b["Итого"] ? 1 : b["Итого"] > a["Итого"] ? -1 : 0)).reverse()

  const dd = []
  if (data.rows) {
    data.rows.forEach((i) => {
      Object.keys(i.operations).forEach((key) => {
        const channelName = data.channels.find((channel) => channel[0] === parseInt(key, 10))[1]

        const row = dd.find((dd2) => dd2.name === channelName) || {
          name: channelName,
          Банк: 0,
          Касса: 0,
          Итого: 0
        }

        row["Банк"] = row["Банк"] + i.operations[key].bank
        row["Касса"] = row["Касса"] + i.operations[key].cashbox
        row["Итого"] = row["Итого"] + i.operations[key].total

        const rowIndex = dd.findIndex((dd2) => dd2.name === channelName)

        if (rowIndex > -1) {
          dd[rowIndex] = row
        } else {
          dd.push(row)
        }
      })
    })
  }

  dd.sort((a, b) => (a["Итого"] > b["Итого"] ? 1 : b["Итого"] > a["Итого"] ? -1 : 0)).reverse()

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="recharts-default-tooltip custom-tooltip">
          <p className="recharts-tooltip-label custom-tooltip-final-label-style">{label}</p>
          <ul className="recharts-tooltip-item-list custom-tooltip-list-style">
            <li className="recharts-tooltip-item custom-tooltip-final-item-style" style={{ color: "#4e73df" }}>
              <span className="recharts-tooltip-item-name">Банк</span>
              <span className="recharts-tooltip-item-separator"> : </span>
              <span className="recharts-tooltip-item-value">{formatMoney(payload[0].payload["Банк"])}</span>
            </li>
            <li className="recharts-tooltip-item custom-tooltip-final-item-style" style={{ color: "#ffc107" }}>
              <span className="recharts-tooltip-item-name">Касса</span>
              <span className="recharts-tooltip-item-separator"> : </span>
              <span className="recharts-tooltip-item-value">{formatMoney(payload[0].payload["Касса"])}</span>
            </li>
            <li className="recharts-tooltip-item custom-tooltip-final-item-style" style={{ color: "#fe0707" }}>
              <span className="recharts-tooltip-item-name">Итого</span>
              <span className="recharts-tooltip-item-separator"> : </span>
              <span className="recharts-tooltip-item-value">{formatMoney(payload[0].payload["Итого"])}</span>
            </li>
          </ul>
        </div>
      )
    }

    return null
  }

  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem active>Отчет СП</BreadcrumbItem>
      </Breadcrumb>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Состав">
          <br />
          <table className="table">
            <thead>
              <tr>
                <th scope="col">СТРАХОВАЯ ПРЕМИЯ ВСЕГО</th>
                <th scope="col">СТРАХОВАЯ ПРЕМИЯ В КАССЕ</th>
                <th scope="col">СТРАХОВАЯ ПРЕМИЯ В БАНКЕ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ color: "#4e73df", fontWeight: "bold" }}>
                  {formatMoney(
                    data.rows
                      .map((i) => i.operations)
                      .flat()
                      .map((i) => Object.values(i))
                      .flat()
                      .reduce((a, b) => a + b.total, 0)
                  )}
                </td>
                <td style={{ color: "#4e73df", fontWeight: "bold" }}>
                  {formatMoney(
                    data.rows
                      .map((i) => i.operations)
                      .flat()
                      .map((i) => Object.values(i))
                      .flat()
                      .reduce((a, b) => a + b.cashbox, 0)
                  )}
                </td>
                <td style={{ color: "#4e73df", fontWeight: "bold" }}>
                  {formatMoney(
                    data.rows
                      .map((i) => i.operations)
                      .flat()
                      .map((i) => Object.values(i))
                      .flat()
                      .reduce((a, b) => a + b.bank, 0)
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <div className="row">
            <div className="col">
              <h6 style={{ fontWeight: "bold", textAlign: "center" }}>СТРАХОВАЯ ПРЕМИЯ В РАЗРЕЗЕ СТРАХОВЫХ КОМПАНИЙ</h6>
              <ResponsiveContainer width="100%" height={50 + rr.length * 50}>
                <BarChart
                  data={rr || []}
                  layout="vertical"
                  barSize={30}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis type="category" dataKey="name" width={200} />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  <Bar dataKey="Банк" stackId="a" fill="#4e73df" />
                  <Bar dataKey="Касса" stackId="b" fill="#ffc107" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <h6 style={{ fontWeight: "bold", textAlign: "center" }}>СТРАХОВАЯ ПРЕМИЯ В РАЗРЕЗЕ СТРАХОВАТЕЛЕЙ</h6>
              <ResponsiveContainer width="100%" height={50 + dd.length * 50}>
                <BarChart
                  data={dd || []}
                  layout="vertical"
                  barSize={30}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis type="category" dataKey="name" width={200} />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  <Bar dataKey="Банк" stackId="a" fill="#4e73df" />
                  <Bar dataKey="Касса" stackId="b" fill="#ffc107" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Tab>
        <Tab eventKey="numbers" title="Цифры">
          <br />
          <div style={{ overflowX: "scroll" }}>
            <table className="table table-bordered table-hover table-striped" style={{ width: "4300px" }}>
              <thead style={{ background: "#e5efdc" }}>
                <tr>
                  <th style={{ border: "1px solid white" }} rowSpan={2}>
                    Страховая компания
                  </th>
                  <th style={{ border: "1px solid white" }} rowSpan={2}>
                    № Договора страхования
                  </th>
                  <th style={{ border: "1px solid white" }} colSpan={3}>
                    ИТОГО ВСЕМ ДОГОВОРАМ
                  </th>
                  {data.channels.map((channel) => (
                    <th style={{ border: "1px solid white" }} colSpan={3}>
                      {channel[1]}
                    </th>
                  ))}
                </tr>
                <tr>
                  <th style={{ border: "1px solid white" }}>Итого</th>
                  <th style={{ border: "1px solid white" }}>Касса</th>
                  <th style={{ border: "1px solid white" }}>Банк</th>
                  {data.channels.map((channel) => (
                    <Fragment>
                      <th style={{ border: "1px solid white" }}>Итого</th>
                      <th style={{ border: "1px solid white" }}>Касса</th>
                      <th style={{ border: "1px solid white" }}>Банк</th>
                    </Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    {formatMoney(
                      data.rows
                        .map((i) => i.operations)
                        .flat()
                        .map((i) => Object.values(i))
                        .flat()
                        .reduce((a, b) => a + b.total, 0)
                    )}
                  </td>
                  <td>
                    {formatMoney(
                      data.rows
                        .map((i) => i.operations)
                        .flat()
                        .map((i) => Object.values(i))
                        .flat()
                        .reduce((a, b) => a + b.cashbox, 0)
                    )}
                  </td>
                  <td>
                    {formatMoney(
                      data.rows
                        .map((i) => i.operations)
                        .flat()
                        .map((i) => Object.values(i))
                        .flat()
                        .reduce((a, b) => a + b.bank, 0)
                    )}
                  </td>

                  {data.channels.map((channel) => (
                    <Fragment>
                      <td>{formatMoney(data.rows.reduce((a, b) => a + (b.operations[channel[0]]?.total || 0), 0))}</td>
                      <td>
                        {formatMoney(data.rows.reduce((a, b) => a + (b.operations[channel[0]]?.cashbox || 0), 0))}
                      </td>
                      <td>{formatMoney(data.rows.reduce((a, b) => a + (b.operations[channel[0]]?.bank || 0), 0))}</td>
                    </Fragment>
                  ))}
                </tr>
                {data.rows.map((row) => (
                  <tr>
                    <td>{row.insurer_name}</td>
                    <td>
                      <Link to={`/contracts/${row.contract_id}/operations/`}>{row.contract_number}</Link>
                    </td>
                    <td>
                      {formatMoney(
                        Object.values(row.operations)
                          .flat()
                          .reduce((a, b) => a + b.total, 0)
                      )}
                    </td>
                    <td>
                      {formatMoney(
                        Object.values(row.operations)
                          .flat()
                          .reduce((a, b) => a + b.cashbox, 0)
                      )}
                    </td>
                    <td>
                      {formatMoney(
                        Object.values(row.operations)
                          .flat()
                          .reduce((a, b) => a + b.bank, 0)
                      )}
                    </td>
                    {data.channels.map((channel) => (
                      <Fragment>
                        <td>{formatMoney(row.operations[channel[0]]?.total || 0)}</td>
                        <td>{formatMoney(row.operations[channel[0]]?.cashbox || 0)}</td>
                        <td>{formatMoney(row.operations[channel[0]]?.bank || 0)}</td>
                      </Fragment>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Tab>
      </Tabs>
    </Fragment>
  )
}

export default observer(Operations)
