import React from "react"
import Attachment from "./Attachment"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  file: null
}

const Attachments = ({ name }) => (
  <NestedFields name={name} label="Файлы" component={Attachment} defaultValues={defaultValues} />
)

export default Attachments
