import React, { useState, useEffect, Fragment, useContext } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput, Button } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"
import SubagentModal from "./SubagentModal"
import { useFormikContext, getIn } from "formik"

import SubagentStore from "stores/SubagentStore/List"

const subagentStore = SubagentStore.create()

const SubagentSelect = ({ name = "subagent", required = false, ...props }) => {
  useEffect(() => {
    subagentStore.search({ limit: 0 })
  }, [])
  // const { subagentStore } = useContext(ContractFormContext)
  const { values, setFieldValue } = useFormikContext()
  const subagent = getIn(values, name)

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const subagentOptions = subagent.id ? [{ label: subagent.name, value: subagent.id }] : subagentStore.subagentOptions

  const loadSubagentOptions = (inputValue) =>
    subagentStore.search({ filter: { by_name: inputValue }, limit: 0 }).then(({ subagentOptions }) => subagentOptions)

  const onSubagentAdded = (option) => {
    subagentOptions.push(option)
    setFieldValue(name, { id: option.value, name: option.label })
  }

  return (
    <Fragment>
      <div className="form-group">
        <AsyncSelectInput
          name={`${name}.id`}
          label="Субагент"
          required={required}
          options={subagentOptions}
          isSearchable
          loadOptions={loadSubagentOptions}
          {...props}
        />
        <Button variant="success" onClick={openModal} disabled={props.isDisabled}>
          Создать субагента
        </Button>
      </div>
      <SubagentModal {...{ isOpen, closeModal, onSubagentAdded }} />
    </Fragment>
  )
}

export default observer(SubagentSelect)
