import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import HoldingStore from "stores/HoldingStore/List"

const holdingStore = HoldingStore.create()

const HoldingSelect = ({ name = "holding", required = false, label = "Холдинг / Группа компаний", ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    holdingStore.fetch()
  }, [])

  const getOptions = () => {
    const { holdingOptions } = holdingStore
    const { id: value, name: label } = getIn(values, name) || { id: null, name: null }
    if (value) {
      holdingOptions.concat([{ label, value }])
    }
    return holdingOptions
  }

  const holdingOptions = getOptions()

  const loadHoldingOptions = (value) =>
    holdingStore
      .fetch({ filter: { by_name: value }, limit: 0 })
      .then(({ holdingOptions }) => holdingOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={holdingOptions}
        required={required}
        isSearchable
        loadOptions={loadHoldingOptions}
        {...props}
      />
    </div>
  )
}

export default observer(HoldingSelect)
