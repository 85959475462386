import React, { Fragment, useEffect, useState, useContext } from "react"
import formatDate from "utils/formatDate"
import Bbutton from "react-bootstrap/Button"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import { Button, Destroy } from "components/UI"
import EditModal from "./components/ContractTaskModal"
import ShowModal from "./components/ShowModal"
import useContractTask from "./hooks/useContractTask"
import useModalTrigger from "hooks/useModalTrigger"
import { observer } from "mobx-react"
import isBefore from "date-fns/isBefore"
import formatDateTime from "utils/formatDateTime"
import formatTime from "utils/formatTime"
import classNames from "classnames"
import instance from "connection/instance"
import AuthStoreContext from "contexts/AuthStoreContext"

function mapChildren(data) {
  const parents = data.filter((object) => !object.parent.id)
  const children = data.filter((object) => object.parent.id).sort((a, b) => b.date_on - a.date_on)

  for (let i = 0; i < children.length; i++) {
    const parentIndex = parents.map((object) => object.id).indexOf(children[i].parent.id)
    parents.splice(parentIndex + 1, 0, children[i])
  }
  return parents
}

const contract_bankruptcies_status = (contractStore, status) => {
  const statuses = ["preparation", "observation", "contest", "closed"]
  const onClick = (status) => {
    contractStore.update({
      ...contractStore.data,
      ...{ contract_bankruptcy: { ...contractStore.data.contract_bankruptcy, status: { value: statuses[status] } } }
    })
  }
  return (
    <ButtonGroup size="sm">
      <Bbutton variant="success" active={statuses.indexOf(status?.value) >= 0} onClick={() => onClick(0)}>
        Подготовка
      </Bbutton>
      <Bbutton variant="success" active={statuses.indexOf(status?.value) >= 1} onClick={() => onClick(1)}>
        Наблюдение
      </Bbutton>
      <Bbutton variant="success" active={statuses.indexOf(status?.value) >= 2} onClick={() => onClick(2)}>
        Конкурс
      </Bbutton>
      <Bbutton variant="success" active={statuses.indexOf(status?.value) >= 3} onClick={() => onClick(3)}>
        Закрыто
      </Bbutton>
    </ButtonGroup>
  )
}

const rowClass = (i) => {
  if (i.status.value == "done") return "table-success"
  if (i.status.value == "in_work" && isBefore(i.date_on, new Date())) return "table-danger"
  if (i.status.value == "in_work") return "table-warning"
}

const ContractBankruptcy = ({ contractStore, contract_bankruptcy }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  const { contractBankruptcyTask, createContractTask, updateContractTask, resetContractTask } = useContractTask({
    contract_taskable_type: 'ContractBankruptcy', contract_taskable_id: contract_bankruptcy.id
  })
  const [contractBankruptcyTasks, setContractTasks] = useState(contract_bankruptcy.contract_tasks)
  const [isEditModalOpen, editModalData, openEditModal, closeEditModal] = useModalTrigger(contractBankruptcyTask)
  const [isShowModalOpen, showModalData, openShowModal, closeShowModal] = useModalTrigger({})

  useEffect(() => {
    resetContractTask({ contract_taskable_type: 'ContractBankruptcy', contract_taskable_id: contract_bankruptcy.id })
  }, [contract_bankruptcy.id])

  const destroyContractTask = async (id) => {
    await instance.delete(`/pages/contract_tasks/${id}`)
    setContractTasks(contractBankruptcyTasks.filter((i) => i.id !== id && i.parent?.id !== id))
  }

  return (
    <Fragment>
      <table className="table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Стадия</td>
            <td>{contract_bankruptcies_status(contractStore, contract_bankruptcy.status)}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Предмет договора (описание)</td>
            <td>{contract_bankruptcy.subject}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Должник</td>
            <td>
              <span
                style={{
                  background: contract_bankruptcy.side?.value === "debtor" ? "#1cc88a" : "#ed969e",
                  padding: "3px",
                  color: "#000",
                  borderRadius: "3px"
                }}
              >
                {contract_bankruptcy.side?.value === "debtor"
                  ? contract_bankruptcy.debtor?.name
                  : contract_bankruptcy.debtor_comment}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Кредитор</td>
            <td>
              <span
                style={{
                  background: contract_bankruptcy.side?.value === "сreditor" ? "#1cc88a" : "#ed969e",
                  padding: "3px",
                  color: "#000",
                  borderRadius: "3px"
                }}
              >
                {contract_bankruptcy.side?.value === "сreditor"
                  ? contract_bankruptcy.сreditor?.name
                  : contract_bankruptcy.сreditor_comment}
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Требования</td>
            <td>
              Основной долг: {contract_bankruptcy.requirement_duty}
              <br />
              Неустойка: {contract_bankruptcy.requirement_penalty}
              <br />
              Пошлина: {contract_bankruptcy.requirement_fee}
              <br />
              Судебные расходы: {contract_bankruptcy.requirement_expense}
            </td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Макс./Мин. сумма для торга</td>
            <td>{contract_bankruptcy.bargain}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Суд</td>
            <td>{contract_bankruptcy.court?.name}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Дело №</td>
            <td>{contract_bankruptcy.case_number}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Арбитражный управляющий</td>
            <td>{contract_bankruptcy.arbitration_manager}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Доп.информация</td>
            <td>{contract_bankruptcy.comment}</td>
          </tr>
          <tr>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td style={{ width: "50%", borderTop: "2px solid #e5e5e5" }}>Результат</td>
            <td style={{ borderTop: "2px solid #e5e5e5" }}>
              {contract_bankruptcy.result?.value == "atypical"
                ? contract_bankruptcy.result_comment
                : contract_bankruptcy.result?.label}
            </td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Вызыскано</td>
            <td>
              Сумма взыскания: {contract_bankruptcy.recovered_duty}
              <br />
              Сумма неустойки: {contract_bankruptcy.recovered_penalty}
              <br />
              Сумма судебных расходов: {contract_bankruptcy.recovered_expense}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <EditModal
        isOpen={isEditModalOpen}
        closeModal={closeEditModal}
        entity={editModalData}
        insurance_kind={contractStore.data.insurance_kind}
        contract_bankruptcy={contract_bankruptcy}
        handleSubmit={async (values) => {
          if (values.id) {
            const data = await updateContractTask(values)
            setContractTasks(
              contractBankruptcyTasks.map((i) => {
                if (i.id === data.id) {
                  return { ...i, ...data }
                }
                return i
              })
            )
          } else {
            const data = await createContractTask(values)
            setContractTasks([...contractBankruptcyTasks, data])
          }

          closeEditModal()
        }}
      />
      <ShowModal
        isOpen={isShowModalOpen}
        closeModal={closeShowModal}
        entity={showModalData}
        contract_bankruptcy={contract_bankruptcy}
      />
      <Button onClick={() => openEditModal(contractBankruptcyTask)}>+ Задача</Button>
      <br />
      <br />
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>#</th>
            <th>Дата/время</th>
            <th>Тип задачи</th>
            <th>Тема</th>
            <th>Исполнитель</th>
            <th>Закрыто</th>
            <th>Результат</th>
            <th className="actions" style={{ width: "74px" }} />
          </tr>
        </thead>
        <tbody>
          {mapChildren(contractBankruptcyTasks.sort((a, b) => a.date_on - b.date_on)).map((i) => (
            <tr className={rowClass(i)}>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.id}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.parent.id ? (
                  <i className="fas fa-list-check" title="Подзадача"></i>
                ) : (
                  <i className="fas fa-clipboard" title="Задача"></i>
                )}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {[formatDate(i.date_on), formatTime(i.time_at)].filter(Boolean).join(" в ")}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.kind.label}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.subject}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.performer?.name}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {formatDateTime(i.done_at)}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.result}
              </td>
              <td>
                <button
                  type="button"
                  title="Добавить подзадачу"
                  disabled={i.parent.id}
                  className="btn btn-link btn-action"
                  onClick={() => openEditModal({ ...contractBankruptcyTask, parent_id: i.id })}
                >
                  <i className="fas fa-plus" />
                </button>
                <button
                  type="button"
                  title="Редактировать"
                  className="btn btn-link btn-action"
                  onClick={() => openEditModal(i)}
                >
                  <i className="fas fa-edit" />
                </button>
                <Destroy
                  action={() => destroyContractTask(i.id)}
                  disabled={![5, 26].includes(currentUser.id) && currentUser.id !== i.creator.id}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  )
}

export default observer(ContractBankruptcy)
