const prepare = (params = {}) => {
  const {
    kind: { value: kind },
    instance: { value: instance },
    ...other
  } = params
  return { ...other, kind: parseInt(kind), instance: parseInt(instance) }
}

export { prepare }
