import React, { Fragment } from "react"
import compose from "utils/compose"
import { FieldArray, withFormik } from "formik"
import { observer } from "mobx-react"
import { withRouter } from "react-router"
import {
  Card,
  CardTitle,
  InnInput,
  RadioButtonGroup,
  RadioInput,
  SelectInput,
  Submit,
  TextArea,
  TextInput
} from "components/UI"
import Contacts from "./Contacts"
import Emails from "./Emails"
import Phones from "./Phones"

import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import InfoTab from "./InfoTab"

const Form = ({ store, handleSubmit, ...props }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Fragment>
        <br />
        <Tabs defaultActiveKey="info">
          <Tab eventKey="info" title="Информация о субагенте">
            <br />
            <InfoTab {...{ store, ...props }} />
          </Tab>
          {props.values.kind === "individual" && (
            <Tab eventKey="contacts" title="Контактные данные">
              <br />
              <FieldArray name="emails" component={Emails} />
              <FieldArray name="phones" component={Phones} />
            </Tab>
          )}
          {props.values.kind === "entity" && (
            <Tab eventKey="contacts" title="Контактные лица">
              <br />
              <FieldArray name="contacts" component={Contacts} />
            </Tab>
          )}
        </Tabs>
      </Fragment>
      {/* <RadioButtonGroup label="Тип" name="kind">
        <RadioInput label="Физическое лицо" id="individual" />
        <RadioInput label="Юридическое лицо" id="entity" />
      </RadioButtonGroup>
      {values.kind === "individual" && (
        <React.Fragment>
          <TextInput name="l_name" label="Фамилия" />
          <TextInput name="f_name" label="Имя" />
          <TextInput name="p_name" label="Отчество" />
          <TextInput name="organization_name" label="Название организации" />
          <RadioButtonGroup label="Форма собственности организации" name="ownership_kind">
            <RadioInput label="ООО" id="ooo" />
            <RadioInput label="ОАО" id="oao" />
            <RadioInput label="ЗАО" id="zao" />
          </RadioButtonGroup>
          <TextInput name="position" label="Должность" />
          <FieldArray name="emails" component={Emails} />
          <FieldArray name="phones" component={Phones} />
          <Card>
            <CardTitle>Адрес</CardTitle>
            <TextInput name="street" label="Улица" />
            <TextInput name="index" label="Почтовый индекс" />
            <TextInput name="city" label="Город" />
          </Card>
          <TextArea name="memo" label="Мемо" />
        </React.Fragment>
      )}
      {values.kind === "entity" && (
        <React.Fragment>
          <TextInput name="organization_name" label="Название" />
          <RadioButtonGroup label="Форма собственности" name="ownership_kind">
            <RadioInput label="ООО" id="ooo" />
            <RadioInput label="ОАО" id="oao" />
            <RadioInput label="ЗАО" id="zao" />
            <RadioInput label="ИП" id="ip" />
          </RadioButtonGroup>
          <InnInput name="inn" label="ИНН" />
          <Card>
            <CardTitle>Юридический адрес</CardTitle>
            <TextInput name="street" label="Улица" />
            <TextInput name="index" label="Почтовый индекс" />
            <TextInput name="city" label="Город" />
          </Card>
          <TextArea name="memo" label="Мемо" />
          <FieldArray name="contacts" component={Contacts} />
        </React.Fragment>
      )} */}
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  }),
  observer
)(Form)
