import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Provider } from "./Provider"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"
import toOptions from "utils/newToOptions"

const Filter = types.model("Filter", {
  by_name: types.maybeNull(types.string),
  by_kind: types.maybeNull(types.integer),
  by_activity_kind: types.maybeNull(customEnum)
})

const FilterOptions = types.model("FilterOptions", {
  kinds: types.array(newCustomEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filter: {
    by_name: "",
    by_kind: null,
    by_activity_kind: { id: null, name: null }
  },
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const ProviderStore = types
  .model("Provider", {
    data: types.array(Provider, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get providerOptions() {
      return self.data.map(({ id, name }) => {
        return {
          label: name,
          value: id
        }
      })
    },

    get filtersOptions() {
      const { kinds } = self.meta.filters_options
      return { kinds: toOptions(kinds) }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      return instance
        .get("/providers", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    search(params = {}) {
      self.setState("pending")
      return instance
        .get("/providers/search", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/providers/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      applySnapshot(self, data)
      // if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ProviderStore
