import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import customEnum from "types/customEnum"
import customDate from "types/customDate"
import { Bso } from "./Bso"
import format from "date-fns/format"
import toOptions from "utils/newToOptions"
import newCustomEnum from "types/newCustomEnum"

const Filter = types.model("Filter", {
  by_insurer: types.maybeNull(customEnum),
  by_channel: types.maybeNull(customEnum),
  by_updated_at_from: types.maybeNull(customDate),
  by_updated_at_to: types.maybeNull(customDate),
  by_bso_kind_id: types.maybeNull(types.number),
  by_bso_batch_id: types.maybeNull(types.number),
  by_form_status: types.maybeNull(types.number)
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const FilterOptions = types.model("FilterOptions", {
  form_statuses: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number),
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filter: {
    by_insurer: { id: null, name: null },
    by_channel: { id: null, name: null },
    by_updated_at_from: null,
    by_updated_at_to: null,
    by_bso_kind_id: null,
    by_bso_batch_id: null,
    by_form_status: null
  },
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  },
  filters_options: {}
}

const BsoStore = types
  .model("Bso", {
    data: types.array(Bso, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get bsoOptions() {
      return self.data.map(({ id, number }) => {
        return {
          label: number,
          value: id
        }
      })
    },

    get filtersOptions() {
      const { form_statuses } = self.meta.filters_options
      return { form_statuses: toOptions(form_statuses) }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_updated_at_from, by_updated_at_to } = params.filter
        if (by_updated_at_from) {
          params.filter = { ...params.filter, by_updated_at_from: format(by_updated_at_from, "yyyy-MM-dd") }
        }
        if (by_updated_at_to) {
          params.filter = { ...params.filter, by_updated_at_to: format(by_updated_at_to, "yyyy-MM-dd") }
        }
      }

      return instance
        .get("/bsos", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/bsos/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default BsoStore
