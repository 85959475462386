import React from "react"
import ReactQuill from "react-quill"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn, ErrorMessage } from "formik"
import { observer } from "mobx-react"
import "react-quill/dist/quill.snow.css"

const TextArea = ({ name, label, ...props }) => {
  const id = idGenerator()
  const { errors, values, handleBlur, setFieldValue } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || ""
  const className = classnames({ "is-invalid": error })
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["clean"]
    ]
  }
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <ReactQuill
        {...{ id, className, name, ...props }}
        modules={modules}
        value={value}
        // onBlur={handleBlur}
        onChange={value => setFieldValue(name, value)}
      />
      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default compose(
  observer,
  connect
)(TextArea)
