import Breadcrumbs from "pages/Subagents/components/Breadcrumbs"
import React, { Component, Fragment } from "react"
import SubagentStore from "stores/SubagentStore/One"
import { BreadcrumbItem, Button, Card } from "components/UI"
import { observer } from "mobx-react"
import Tabs from "pages/Subagents/components/Tabs"

class Show extends Component {
  subagentStore = SubagentStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.subagentStore.fetch({ id })
  }

  render() {
    const {
      subagentStore: {
        data: { id, emails, f_name, kind, l_name, comment, name, organization_name, p_name, phones, position }
      }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumbs>
        <Tabs {...{ id }} />
        <br />
        <Card>
          <Button url={`/subagents/${id}/edit`}>Редактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            {kind === "individual" && (
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan="2">Субагент</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Фамилия</td>
                    <td style={{ width: "50%" }}>{l_name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Имя</td>
                    <td style={{ width: "50%" }}>{f_name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Отчество</td>
                    <td style={{ width: "50%" }}>{p_name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Должность</td>
                    <td style={{ width: "50%" }}>{position}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Комментарий</td>
                    <td style={{ width: "50%" }} dangerouslySetInnerHTML={{ __html: comment }} />
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Emails</td>
                    <td style={{ width: "50%" }}>
                      {emails.map(({ value }) => (
                        <Fragment>
                          <a href={`emailto:${value}`}>{value}</a>
                          <br />
                        </Fragment>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Телефоны</td>
                    <td style={{ width: "50%" }}>
                      {phones.map(({ value }) => (
                        <Fragment>
                          <a href={`tel:${value}`}>{value}</a>
                          <br />
                        </Fragment>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            {kind === "entity" && (
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan="2">Субагент</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Название</td>
                    <td style={{ width: "50%" }}>{organization_name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Комментарий</td>
                    <td style={{ width: "50%" }} dangerouslySetInnerHTML={{ __html: comment }} />
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
