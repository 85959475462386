import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { withRouter } from "react-router-dom"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"

const status = (days) => {
  switch (true) {
    case days < 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Просрочено" />
    case days >= 0 && days <= 7:
      return <i className="fa fa-circle" style={{ color: "yellow" }} title="Срок подходит" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="В плане" />
  }
}

const spStatus = (payment_on) => {
  if (payment_on) {
    return <i className="fa fa-circle" style={{ color: "green" }} title="СП оплачена" />
  }
  return <i className="fa fa-circle" style={{ color: "red" }} title="СП не оплачена" />
}

const Table = ({
  store,
  history,
  selectedRows,
  totalSelectedRow,
  isCheckedRow,
  onCheckedRow,
  isCheckedAllRows,
  onCheckedAllRows,
  resetSelected
}) => {
  const { page } = store.meta.pagination
  const balance = Object.values(selectedRows)
    .flat()
    .map((i) => parseMoney(i.subagent_cost))
    .reduce((a, b) => a + b, 0)
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllRows(page)} onChange={(e) => onCheckedAllRows(e, page)} />
          </th>
          <th scope="col">ID договора</th>
          <th scope="col">Субагент</th>
          <th scope="col">Страховая компания</th>
          <th scope="col">Страхователь</th>
          <th scope="col">Вид страхования</th>
          <th scope="col">№ договора</th>
          <th scope="col">Статус оплаты СП</th>
          <th scope="col">Сумма к выплате СА</th>
          <th scope="col">План. дата выплаты СА</th>
          <th scope="col">Дней до выплаты СА</th>
          <th scope="col">Статус выплаты</th>
        </tr>
      </thead>
      <tbody>
        {store.data.map((row, index) => (
          <tr key={`contract-installment-${index}`}>
            <td>
              <input type="checkbox" checked={isCheckedRow(row, page)} onChange={() => onCheckedRow(row, page)} />
            </td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.contract.id}</td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
              {row.contract.subagent.name}
            </td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
              {row.contract.insurer.name}
            </td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
              {row.contract.client.name}
            </td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
              {row.contract.insurance_kind.name}
            </td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
              {row.contract.number}
            </td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
              {spStatus(row.payment_on)}
            </td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.subagent_cost}</td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
              {formatDate(row.subagent_cost_planned_on)}
            </td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
              {row.subagent_until_to_payment}
            </td>
            <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
              {status(row.subagent_until_to_payment)}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot className="thead-light">
        <tr>
          <th colSpan="13">
            Выбрано: {totalSelectedRow} | Сумма: {formatMoney(balance)}
          </th>
          <th>
            <button
              type="button"
              className="btn btn-danger"
              title="Сбросить выделение"
              onClick={resetSelected}
              disabled={totalSelectedRow === 0}
            >
              <i className="fas fa-sync-alt"></i>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  )
}

export default withRouter(observer(Table))
