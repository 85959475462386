import React, { Fragment, useEffect, useState } from "react"
import Breadcrumbs from "pages/Projects/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import useProject from "pages/Projects/hooks/useProject"
import Form from "pages/Projects/components/Form"

const Edit = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { project, editProject, updateProject } = useProject()

  useEffect(() => {
    async function fetchData() {
      await editProject(props.match.params.id)
      setIsLoaded(true)
    }
    fetchData()
  }, [])

  if (!isLoaded) return null

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{project.main}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={project} handleSubmit={async (values) => updateProject(values)} />
    </Fragment>
  )
}

export default Edit
