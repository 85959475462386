import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import DestinationStore from "stores/DestinationStore/List"
import Breadcrumbs from "pages/Destinations/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Filter from "./components/Filter"
import Table from "./components/Table"

const destinationStore = DestinationStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    destinationStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/destinations/new">Создать</Button>
      </Card>
      <Card>
        <Filter store={destinationStore} />
      </Card>
      <Table store={destinationStore} />
      <Paginate store={destinationStore} />
    </Fragment>
  )
}

export default observer(List)
