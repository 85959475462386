import React from "react"
import { Destroy, Edit, Sort } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { Link } from "react-router-dom"
import { observer } from "mobx-react"
import sortChangeHandler from "utils/sortChangeHandler"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th>Название</th>
        <th>Адрес</th>
        <th>Комментарий</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((i, index) => (
        <tr key={`courts-${index}`}>
          <td>
            <Link to={`/courts/${i.id}`}>{i.name}</Link>
          </td>
          <td>{i.address}</td>
          <td>{i.comment}</td>
          <td>
            <Edit url={`/courts/${i.id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, i.id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
