import Breadcrumbs from "pages/Insurers/components/Breadcrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import InsurerDocumentsStore from "stores/InsurerDocumentStore/One"
import Form from "pages/Insurers/containers/InsurerDocuments/components/Form"
import { BreadcrumbItem } from "components/UI"

const Add = (props) => {
  const { insurer_id } = props.match.params
  const insurerStore = InsurerStore.create()
  const insurerScanStore = InsurerDocumentsStore.create()

  useEffect(() => {
    insurerStore.fetch({ id: insurer_id })
    // insurerScanStore.fetchMeta({ insurer_id: insurer_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{insurer_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/insurers/${insurer_id}/insurer_documents`}>Документы</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form {...{ store: insurerScanStore, insurerStore }} />
    </Fragment>
  )
}

export default observer(Add)
