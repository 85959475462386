import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import KvCostStore from "stores/KvCostStore/List"
import Breadcrumbs from "pages/KvCosts/components/Breadcrumbs"
import { Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"
import Filter from "./components/Filter"
import useSelectAll from "hooks/useSelectAll"

const kvCostStore = KvCostStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    kvCostStore.fetchReport(getParams())
  }, [])

  const [
    selectedRows,
    totalSelectedRow,
    isCheckedRow,
    onCheckedRow,
    isCheckedAllRows,
    onCheckedAllRows,
    resetSelected
  ] = useSelectAll(kvCostStore.data.toJSON())

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Filter store={kvCostStore} resetSelected={resetSelected} />
      </Card>
      <Table
        store={kvCostStore}
        selectedRows={selectedRows}
        totalSelectedRow={totalSelectedRow}
        isCheckedRow={isCheckedRow}
        onCheckedRow={onCheckedRow}
        isCheckedAllRows={isCheckedAllRows}
        onCheckedAllRows={onCheckedAllRows}
        resetSelected={resetSelected}
      />
      <Paginate store={kvCostStore} action="fetchReport" />
    </Fragment>
  )
}

export default observer(List)
