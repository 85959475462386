import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const Table = ({ store, selected, setSelected }) => {
  // console.log(contractInstallments.map((i) => i.id))


  const onChecked = (contract) => {
    const found = selected.find((i) => i.id === contract.id)
    if (found) {
      setSelected(selected.filter((i) => i.id !== contract.id))
    } else {
      setSelected(selected.concat([contract]))
    }
  }

  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Страховая компания</th>
          <th scope="col">Страхователь</th>
          <th scope="col">Вид страхования</th>
          <th scope="col">Номер договора</th>
          <th scope="col">Оплаченная СП</th>
          <th scope="col">Дата начала договора</th>
        </tr>
      </thead>
      <tbody>
        {store.data.map((contract, index) => (
          <tr key={`contract-${index}`}>
            <td>
              <input
                type="checkbox"
                name="contract.id"
                value={contract.id}
                checked={selected.find((i) => i.id === contract.id)}
                onChange={() => onChecked({id: contract.id, number: contract.number})}
              />
            </td>
            <td>{contract.insurer.name}</td>
            <td>{contract.client.name}</td>
            <td>{contract.insurance_kind.name}</td>
            <td>{contract.number}</td>
            <td>
              {formatMoney(
                contract.contract_installments.reduce((accumulator, i) => accumulator + parseMoney(i.payment_cost), 0)
              )}
            </td>
            <td>{formatDate(contract.start_on)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default observer(Table)
