import React from "react"
import { observer } from "mobx-react"
import Modal from "react-modal"
import { Button } from "components/UI"

const ExportModal = ({ isOpen, setIsOpen, data, ...props }) => {
  const closeModal = () => setIsOpen(false)
  // console.log(data)
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className="modal" ariaHideApp={false}>
      <div className="modal-dialog modal-xxl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Импорт из .xlsx</h5>
            <button type="button" className="close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Номер строки</th>
                  <th>Номер столбца</th>
                  <th>Сообщение</th>
                </tr>
              </thead>
              <tbody>
                {data.map((i) => (
                  <tr>
                    <td>{i.index}</td>
                    <td>{i.departure_on ? "C" : "D"}</td>
                    <td>{i.departure_on ? "КОД415 / ОШИБКА ФОРМАТА" : "КОД409 / ДУБЛЬ"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <Button onClick={closeModal}>Закрыть</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(ExportModal)
