import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import BorderoStore from "stores/BorderoStore/One"
import Form from "pages/Contracts/containers/Borderos/components/Form"
import { BreadcrumbItem } from "components/UI"

const contractStore = ContractStore.create()
const borderoStore = BorderoStore.create()

const Add = (props) => {
  borderoStore.reset()
  const { contract_id } = props.match.params

  const [number, setNumber] = useState("")

  useEffect(() => {
    contractStore.fetch({ id: contract_id }).then(({ data }) => {
      setNumber(`${data.number}/${data.borderos.length + 1}`)
    })
  }, [])

  borderoStore.applyData({
    status: 200,
    data: {
      data: {
        ...{
          start_on: null,
          finish_on: null,
          number: null,
          ss_cost: null,
          sp_cost: null,
          comment: null,
          currency: { value: 0 },
          documents: []
        },
        number: number
      }
    }
  })

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contract_id}/borderos`}>Бордеро</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form {...{ store: borderoStore, contractStore }} />
    </Fragment>
  )
}

export default observer(Add)
