import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import InsuranceProductStore from "stores/InsuranceProductStore/One"
import Breadcrumbs from "pages/Insurers/containers/InsuranceProducts/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import { bsoHuman } from "stores/InsuranceProductStore/utils"

class Show extends Component {
  insurerStore = InsurerStore.create()
  insuranceProductStore = InsuranceProductStore.create()

  componentDidMount() {
    const { id, insurer_id } = this.props.match.params
    this.insurerStore.fetch({ id: insurer_id })
    this.insuranceProductStore.fetch({ id, insurer_id })
  }

  render() {
    const {
      insurerStore: { data: insurer },
      insuranceProductStore: { name, bso, comments, risks }
    } = this
    return (
      <Fragment>
        <Breadcrumbs {...{ insurer }}>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumbs>
        <br />
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Название страхового продукта</td>
                  <td style={{ width: "50%" }}>{name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>БСО</td>
                  <td style={{ width: "50%" }}>{bsoHuman(bso)}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Комментарий</td>
                  <td style={{ width: "50%" }}>{comments}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Риски (Ставка КВ, %)</td>
                  <td style={{ width: "50%" }}>{risks.map(({ name, rate }) => `${name} (${rate}%)`).join(", ")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
