import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { withRouter } from "react-router-dom"
import AuthStoreContext from "contexts/AuthStoreContext"

const Table = ({ store, history }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Страховая компания</th>
          <th scope="col">Специализации</th>
          <th scope="col">Каналы продаж / Сегмент</th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {store.data.map(({ id, name, specializations, agency_contracts }, index) => (
          <tr key={`insurers-${index}`}>
            <td onClick={() => history.push(`/insurers/${id}`)}>{id}</td>
            <td onClick={() => history.push(`/insurers/${id}`)}>
              <Link to={`/insurers/${id}`}>{name}</Link>
            </td>
            <td onClick={() => history.push(`/insurers/${id}`)}>{specializations.map((i) => i.name).join(", ")}</td>
            <td
              onClick={() => history.push(`/insurers/${id}`)}
              dangerouslySetInnerHTML={{
                __html: agency_contracts
                  .filter((i) => i.document_kind?.id === 4)
                  .map((i) => `${i.channel.name}/${i.target.label}`)
                  .join("<br /> ")
              }}
            ></td>
            <td>
              <Edit url={`/insurers/${id}/edit`} disabled={![7, 8, 9].includes(currentUser.subdivision.id)} />
              <Destroy
                action={() => afterDestroyFetch(store, id)}
                disabled={![7, 8, 9].includes(currentUser.subdivision.id)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default withRouter(observer(Table))
