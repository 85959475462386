import React from "react"
import { Route, Switch } from "react-router-dom"
import Login from "./containers/Login"

const Auth = () => (
  <Switch>
    <Route path="/auth/login" component={Login} />
  </Switch>
)

export default Auth
