import React, { Fragment, useContext } from "react"
import { getIn } from "formik"
import { TextInput, RadioButtonGroup, RadioInput } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"
import AuthStoreContext from "contexts/AuthStoreContext"

const RecipientCargo = ({ prefix = null, form }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const { courierStore } = useContext(ContractFormContext)
  const { recipient_cargo_kinds } = courierStore.filtersOptions

  const { data: currentUser } = useContext(AuthStoreContext)
  const isDisabled =
    (["2", "3", "4", "5"].includes(form.values.status.value.toString()) &&
      currentUser.subdivision.id.toString() != "7" &&
      currentUser.id.toString() != "48") ||
    (["5"].includes(form.values.status.value.toString()) &&
      (currentUser.subdivision.id.toString() == "7" || currentUser.id.toString() == "48"))
  return (
    <Fragment>
      <RadioButtonGroup label="Что везем" name={path("kind.value")} disabled={isDisabled}>
        {recipient_cargo_kinds.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {getIn(form.values, path("kind.value")).toString() === "4" && (
        <TextInput name={path("kind_other")} label="Другое" disabled={isDisabled} />
      )}
    </Fragment>
  )
}
export default RecipientCargo
