import React from "react"
import Relation from "./Relation"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  comment: "",
  relation_avr: {
    id: null,
    number: null
  }
}

const Relations = () => (
  <NestedFields name="avr_relations" label="Связи с другими АВР" component={Relation} defaultValues={defaultValues} />
)

export default Relations
