import React, { useContext } from "react"
import { AsyncSelectInput, Button } from "components/UI"
import ContactFormContext from "contexts/ContactFormContext"

const InsuranceKind = ({ name, index, insurance_kind, form, h: { replace, remove } }) => {
  const { insuranceKindStore } = useContext(ContactFormContext)

  if (insurance_kind._destroy) return null
  const onDestroyClick = () =>
    insurance_kind.id ? replace(index, { ...insurance_kind, _destroy: true }) : remove(index)

  const insuranceKindOptions = (() => {
    const { id, name } = form.values.insurance_kinds[index]
    return id ? [{ label: name, value: id }] : insuranceKindStore.insuranceKindOptions
  })()

  const loadInsuranceKindOptions = (inputValue) =>
    insuranceKindStore
      .fetch({ filter: { by_name: inputValue }, limit: 0 })
      .then(({ insuranceKindOptions }) => insuranceKindOptions)

  return (
    <div key={`insurance_kinds.${index}`}>
      <AsyncSelectInput
        name={`${name}.${index}.id`}
        label="Вид страхования"
        options={insuranceKindOptions}
        isSearchable
        loadOptions={loadInsuranceKindOptions}
      />
      <Button variant="danger" onClick={onDestroyClick}>
        Удалить вид страхования
      </Button>
      <hr />
    </div>
  )
}
export default InsuranceKind
