import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { Link } from "react-router-dom"
import { observer } from "mobx-react"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th>Средний уровень дебиторки (рубли)</th>
        <th>Скорость оборачиваемости дебиторки (дни)</th>
        <th>Скорость оплаты АВР</th>
        <th>Срок выставления АВР после оплаты СП (дни)</th>
        <th scope="col" className="actions one" />
      </tr>
    </thead>
    <tbody>
      {store.data.map(({ id, ...row }, index) => (
        <tr key={`debit_kpis-${index}`}>
          <td>
            <Link to={`/debit_kpis/${id}`}>{row.average_level}</Link>
          </td>
          <td>{row.turnover_rate}</td>
          <td>{row.delay_period}</td>
          <td>{row.exposure_period}</td>
          <td>
            <Edit url={`/debit_kpis/${id}/edit`} />
            {/* <Destroy action={() => afterDestroyFetch(store, id)} /> */}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
