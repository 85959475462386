import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { withRouter } from "react-router-dom"

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Стадия</th>
        <th scope="col">Результат</th>
        <th scope="col">Страхователь</th>
        <th scope="col">Страховая компания</th>
        <th scope="col">Дата</th>
        <th scope="col">Вид страхования</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`losses-${index}`}>
          <td onClick={() => history.push(`/losses/${row.id}`)}>{row.id}</td>
          <td onClick={() => history.push(`/losses/${row.id}`)}>{row.status.label}</td>
          <td onClick={() => history.push(`/losses/${row.id}`)}>{row.result.label}</td>
          <td onClick={() => history.push(`/losses/${row.id}`)}>
            {row.contract.client.name}
          </td>
          <td onClick={() => history.push(`/losses/${row.id}`)}>
            {row.contract.insurer.name}
          </td>
          <td onClick={() => history.push(`/losses/${row.id}`)}>
            {formatDate(row.date_on)}
          </td>
          <td onClick={() => history.push(`/losses/${row.id}`)}>
            {row.contract.insurance_kind.name}
          </td>
          <td>
            <Edit url={`/losses/${row.id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, {id: row.id})} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
