import objectToFormData from "object-to-formdata"

const documentsToFormData = (self, params) => {
  const {
    id,
    documents,
    document_kind,
    insurer,
    channel,
    parent: { id: parent_id },
    creator,
    updated_at,
    target: { value: target },
    ...attributes
  } = params

  return objectToFormData(
    {
      data: {
        ...attributes,
        document_kind_id: document_kind?.id,
        insurer_id: insurer?.id,
        channel_id: channel?.id,
        parent_id: parent_id,
        target: target,
        documents_attributes: documents.map((document, index) => {
          document.file = self.documentFiles[index]
          return document
        })
      }
    },
    { indices: true }
  )
}

export default documentsToFormData
