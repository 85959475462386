import { historyPushToStore } from "utils/historyPushWithStore"

const filterSubmitHandler = (values, formikBag, params = {}, action = 'fetch') => {
  const {
    props: { history, store },
    setSubmitting
  } = formikBag
  return store[action]({ ...params, filter: values })
    .then(({ meta }) => {
      const { pathname, search } = window.location
      historyPushToStore(history, pathname, `?search_id=${meta.search_id}`)
      history.push({
        search: `?search_id=${meta.search_id}`
      })
    })
    .finally(() => setSubmitting(false))
}

export default filterSubmitHandler
