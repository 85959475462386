import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import BsoKindStore from "stores/BsoKindStore/One"
import InsurerStore from "stores/InsurerStore/List"
import ChannelStore from "stores/ChannelStore/List"
import Breadcrumbs from "pages/BsoKinds/components/Breadcrumbs"
import Form from "pages/BsoKinds/components/Form"
import { BreadcrumbItem } from "components/UI"

class Edit extends Component {
  bso_kindStore = BsoKindStore.create()
  insurerStore = InsurerStore.create()
  channelStore = ChannelStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.bso_kindStore.fetch({ id })
    this.insurerStore.fetch()
    this.channelStore.fetch()
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .update(values)
      .then(() => history.push("/bso_kinds", {referer: window.location.pathname }))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { bso_kindStore: store, handleSubmit, insurerStore, channelStore } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{store.name}</BreadcrumbItem>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit, insurerStore, channelStore }} />
      </Fragment>
    )
  }
}

export default observer(Edit)
