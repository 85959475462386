import React, { Fragment, useEffect, useState } from "react"
import getParams from "utils/getParams"
import FullCalendar from "@fullcalendar/react"
import { Card } from "components/UI"
import dayGridPlugin from "@fullcalendar/daygrid"
import Filter from "./components/Filter"
import ContractTaskStore from "stores/ContractTaskStore/List"
import { historyPushFromStore } from "utils/historyPushWithStore"
import { observer } from "mobx-react"
import ShowCostModal from "./components/ShowCostModal"
import useModalTrigger from "hooks/useModalTrigger"
import formatDate from "utils/formatDate"
import formatTime from "utils/formatTime"
import TabsBlock from "pages/ContractTasks/components/TabsBlock"
import Breadcrumbs from "pages/ContractTasks/components/Breadcrumbs"
import { Breadcrumb, BreadcrumbItem } from "components/UI"

const contractProceedingTaskStore = ContractTaskStore.create()

const getBackgound = (kind) => {
  return {
    session: "#ca3a12",
    post: "#dc986e",
    document: "#e0e8f5",
    call: "#3e60b1",
    meeting: "#f8fe4c",
    email: "#9bb566"
  }[kind.value]
}

const Calendar = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    // contractProceedingTaskStore.fetchCalendar(getParams())
  }, [])

  const [filter, setFilter] = useState(getParams())

  useEffect(() => {
    const { by_date_on_from, by_date_on_to, ...other_filter } = filter
    contractProceedingTaskStore.fetchCalendar({
      by_date_on_from,
      by_date_on_to,
      filter: other_filter
    })
  }, [filter])

  const events = contractProceedingTaskStore.data.map((i) => {
    return {
      title: `${i.kind.label} / ${i.contract.name} / ${[formatDate(i.date_on), formatTime(i.time_at)].filter(Boolean).join(" в ")} / ${
        i.performer.name
      }`,
      start: i.date_on,
      end: i.date_on,
      backgroundColor: getBackgound(i.kind),
      textColor: "#000",
      extendedProps: i
    }
  })

  const [isShowCostModalOpen, editModalData, openShowCostModal, closeShowCostModal] = useModalTrigger({
    extendedProps: {}
  })

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Календарь</BreadcrumbItem>
      </Breadcrumbs>
      <TabsBlock />
      <br />
      <Card>
        <Filter store={contractProceedingTaskStore} filter={filter} />
      </Card>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        locale="ru"
        firstDay={1}
        dayMaxEventRows={true}
        views={{
          timeGrid: {
            dayMaxEventRows: 3
          }
        }}
        initialDate={new Date()}
        // datesSet={({ start, end }) => {
        //   contractProceedingTaskStore.fetchCalendar({
        //     ...getParams(),
        //     start_str: start,
        //     end_str: end
        //   })
        // }}
        datesSet={({ start, end }) => setFilter({ ...getParams(), by_date_on_from: start, by_date_on_to: end })}
        eventClick={({ event }) => openShowCostModal(event)}
        events={events}
        moreLinkContent="Еще"
        displayEventTime={false}
        buttonText={{
          today: "Сегодня",
          day: "День",
          week: "Неделя",
          month: "Месяц"
        }}
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay" // user can switch between the two
        }}
      />
      <ShowCostModal isOpen={isShowCostModalOpen} closeModal={closeShowCostModal} data={editModalData} />
      <br />
      <div>
        <p>
          <span
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "5px",
              display: "inline-block",
              background: "#ca3a12"
            }}
          />
          &nbsp;Суд
        </p>
        <p>
          <span
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "5px",
              display: "inline-block",
              background: "#dc986e"
            }}
          />
          &nbsp;Почта
        </p>
        <p>
          <span
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "5px",
              display: "inline-block",
              background: "#e0e8f5"
            }}
          />
          &nbsp;Документ
        </p>
        <p>
          <span
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "5px",
              display: "inline-block",
              background: "#3e60b1"
            }}
          />
          &nbsp;Звонок
        </p>
        <p>
          <span
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "5px",
              display: "inline-block",
              background: "#f8fe4c"
            }}
          />
          &nbsp;Встреча
        </p>
        <p>
          <span
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "5px",
              display: "inline-block",
              background: "#9bb566"
            }}
          />
          &nbsp;Эл. письмо
        </p>
      </div>
    </Fragment>
  )
}

export default observer(Calendar)
