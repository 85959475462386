import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import DocumentFlowStore from "stores/DocumentFlowStore/One"
import InsurerStore from "stores/InsurerStore/List"
import ChannelStore from "stores/ChannelStore/List"
import Breadcrumbs from "pages/SentDocumentFlows/components/Breadcrumbs"
import Form from "pages/SentDocumentFlows/components/Form"
import { BreadcrumbItem } from "components/UI"

class Add extends Component {
  documentFlowStore = DocumentFlowStore.create()
  insurerStore = InsurerStore.create()
  channelStore = ChannelStore.create()

  componentDidMount() {
    this.documentFlowStore.fetchMeta()
    this.insurerStore.fetch()
    this.channelStore.fetch()
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .create(values)
      .then(() => history.push("/sent_document_flows"))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { documentFlowStore: store, handleSubmit, insurerStore, channelStore } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>Создать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit, insurerStore, channelStore }} />
      </Fragment>
    )
  }
}

export default observer(Add)
