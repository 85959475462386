import React, { Fragment, useEffect } from "react"
import Breadcrumbs from "pages/PaymentPlans/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import usePaymentPlan from "pages/PaymentPlans/hooks/usePaymentPlan"
import Form from "pages/PaymentPlans/components/Form"

const Add = () => {
  const { paymentPlan, newPaymentPlan, createPaymentPlan } = usePaymentPlan()

  useEffect(() => {
    newPaymentPlan()
  }, [])
  
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={paymentPlan} handleSubmit={async (values) => createPaymentPlan(values)} />
    </Fragment>
  )
}

export default Add
