import React, { useEffect, useContext } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import OrderSubjectStore from "stores/OrderSubjectStore/List"
import AuthStoreContext from "contexts/AuthStoreContext"

const orderSubjectStore = OrderSubjectStore.create()

const OrderSubjectSelect = ({ name = "order_subject", required = false, label = "Тема приказа", ...props }) => {
  const { values } = useFormikContext()
  const { data: currentUser } = useContext(AuthStoreContext)

  useEffect(() => {
    orderSubjectStore.fetch()
  }, [])

  const getOptions = () => {
    let { orderSubjectOptions } = orderSubjectStore
    const { id: value, name: label } = getIn(values, name.replace(/\.id$/, "")) || { id: null, name: null }
    if (value && !orderSubjectOptions.find((i) => i.value === value)) {
      orderSubjectOptions = orderSubjectOptions.concat([{ label, value }])
    }
    if (currentUser.id == 57) {
      orderSubjectOptions = orderSubjectOptions.map((i) => {
        return { ...i, isDisabled: i.value != 3 }
      })
    }
    return orderSubjectOptions
  }

  const orderSubjectOptions = getOptions()

  const loadOrderSubjectOptions = (value) =>
    orderSubjectStore
      .fetch({ filter: { by_name: value }, limit: 0 })
      .then(({ orderSubjectOptions }) => orderSubjectOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={orderSubjectOptions}
        isSearchable
        loadOptions={loadOrderSubjectOptions}
        required={required}
        {...props}
      />
    </div>
  )
}

export default observer(OrderSubjectSelect)
