import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "components/UI"
import Form from "./components/Form"

const ContractTaskModal = ({ isOpen, closeModal, entity, contract_proceeding, insurance_kind, handleSubmit }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>&nbsp;</ModalHeader>
      <ModalBody>
        <Form entity={entity} contract_proceeding={contract_proceeding} insurance_kind={insurance_kind} handleSubmit={handleSubmit} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ContractTaskModal
