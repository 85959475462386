import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import DestinationStore from "stores/DestinationStore/One"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "components/UI"
import Form from "pages/Destinations/components/Form"

const DestinationModal = ({ isOpen, closeModal, data }) => {
  const destinationStore = DestinationStore.create({
    data: data
  })

  const handleSubmit = (values, { props: { store }, setErrors }) =>
    store
      .create(values)
      .then(() => closeModal())
      .catch(({ response }) => setErrors(response.data.errors))

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Типовой адресат</ModalHeader>
      <ModalBody>
        <Form {...{ store: destinationStore, handleSubmit }} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(DestinationModal)
