import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import CarModelStore from "stores/CarModelStore/One"
import Breadcrumbs from "pages/CarModels/components/Breadcrumbs"
import Form from "pages/CarModels/components/Form"
import { BreadcrumbItem } from "components/UI"

class Edit extends Component {
  carBrandStore = CarModelStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.carBrandStore.fetch({ id })
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .update(values)
      .then(() => history.push("/car_models"))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { carBrandStore: store, handleSubmit } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{store.name}</BreadcrumbItem>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit }} />
      </Fragment>
    )
  }
}

export default observer(Edit)
