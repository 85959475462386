import React from "react"
import Email from "./Email"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  value: null
}

const Emails = ({ name = "emails", required = false }) => (
  <NestedFields name={name} label="Emails" component={Email} defaultValues={defaultValues} required={required} />
)

export default Emails
