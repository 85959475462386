import React, { useContext } from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import formatDateTime from "utils/formatDateTime"
import AuthStoreContext from "contexts/AuthStoreContext"

const Table = ({ store, insurer_id }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Тип документа</th>
          <th scope="col">Файл</th>
          <th scope="col">Комментарии</th>
          <th scope="col">Создал</th>
          <th scope="col">Дата редактирования</th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {store.data.map((i) => (
          <tr>
            <td>{i.id}</td>
            <td>{String(i.document_kind.id) === "18" ? i.document_kind_other : i.document_kind.name}</td>
            <td>
              {i.attachments
                .filter((i) => i.file.url)
                .map((attachment) => (
                  <a
                    key={`attachment-${attachment.id}`}
                    href={attachment.file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {decodeURI(attachment.file.url.replace(/^.*[\\|/]/, ""))}
                    <br />
                  </a>
                ))}
            </td>
            <td>{i.comment}</td>
            <td>{i.creator.name}</td>
            <td>{formatDateTime(i.updated_at)}</td>
            <td>
              <Edit
                url={`/insurers/${insurer_id}/insurer_documents/${i.id}/edit`}
                disabled={![7, 8, 9].includes(currentUser.subdivision.id)}
              />
              <Destroy
                action={() =>
                  afterDestroyFetch(store, { id: i.id, insurer_id: i.insurer_id }, { insurer_id: i.insurer_id })
                }
                disabled={![7, 8, 9].includes(currentUser.subdivision.id)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default observer(Table)
