import React from "react"
import Scan from "./Scan"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  document_kind: { id: null },
  document_kind_other: null,
  kind: 0,
  comment: null,
  attachments: []
}

const Scans = () => (
  <NestedFields name="scans" label="Электронные версии документов" component={Scan} defaultValues={defaultValues} />
)

export default Scans
