import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import customDate from "types/customDate"
import { Contact } from "./Contact"
import { getUrl } from "./utils"

const Filter = types.model("Filter", {
  by_insurer_id: types.maybeNull(types.string),
  by_channel_id: types.maybeNull(types.string),
  by_prolongation_on: types.maybeNull(customDate)
})

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const FilterOptions = types.model("FilterOptions", {
  channels: types.array(Channel, []),
  insurers: types.array(Insurer, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filter: {},
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const ContactStore = types
  .model("Contact", {
    data: types.array(Contact, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views(self => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { channels, insurers } = self.meta.filters_options
      const channelOptions = channels.map(({ id: value, name: label }) => {
        return {
          label,
          value
        }
      })
      const insurerOptions = insurers.map(({ id: value, name: label }) => {
        return {
          label,
          value
        }
      })
      return [channelOptions, insurerOptions]
    },

    get contactOptions() {
      return self.data.map(({ id, l_name, f_name }) => {
        return {
          label: [l_name, f_name].join(" "),
          value: id
        }
      }) 
    },
  }))
  .actions(self => ({
    fetch(params = {}) {
      self.setState("pending")

      const url = getUrl(params)

      return instance
        .get(url, { params })
        .then(response => self.resetStore(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    search(url, params = {}) {
      self.setState("pending")
      return instance
        .get(url, { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(params) {
      self.setState("pending")
      const { id, client_id, ...options } = params
      const url = getUrl(params)
      return instance.delete(url)
      // .then(response => dispatch(destroyUserSuccess(response)))
      // .catch(error => dispatch(destroyUserFailure(error)))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ContactStore
