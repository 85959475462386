import React, { useState, Fragment, useContext } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput, Button } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"
import ClientModal from "./ClientModal"
import { useFormikContext } from "formik"

const ClientSelect = (props) => {
  const { clientStore } = useContext(ContractFormContext)
  const {
    values: { client },
    setFieldValue
  } = useFormikContext()

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const clientOptions = client.id ? [{ label: client.name, value: client.id }] : clientStore.clientOptions

  const loadClientOptions = (inputValue) =>
    clientStore.search({ filter: { by_name: inputValue }, limit: 0 }).then(({ clientOptions }) => clientOptions)

  const onClientAdded = (option) => {
    clientOptions.push(option)
    setFieldValue("client", { id: option.value, name: option.label })
  }

  return (
    <Fragment>
      <div className="form-group">
        <AsyncSelectInput
          name="client.id"
          label="Страхователь"
          required
          options={clientOptions}
          isSearchable
          loadOptions={loadClientOptions}
          {...props}
        />
        <Button variant="success" onClick={openModal} disabled={props.isDisabled}>
          Создать страхователя
        </Button>
      </div>
      <ClientModal {...{ isOpen, closeModal, onClientAdded }} />
    </Fragment>
  )
}

export default observer(ClientSelect)
