import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import InsuranceKindStore from "stores/InsuranceKindStore/List"

const insuranceKindStore = InsuranceKindStore.create()

const InsuranceKindSelect = ({ name = "insurance_kind", required = false, ...props }) => {
  const { values, setFieldValue } = useFormikContext()
  const insurance_kind = getIn(values, name, {}) || {}

  useEffect(() => {
    insuranceKindStore.fetch({ order_by_ordered: "asc" })
  }, [])

  const insuranceKindOptions = insurance_kind.id
    ? [{ label: insurance_kind.name, value: insurance_kind.id }]
    : insuranceKindStore.insuranceKindOptions

  const loadInsuranceKindOptions = (inputValue) =>
    insuranceKindStore
      .fetch({ order_by_ordered: "asc", filter: { by_name: inputValue }, limit: 0 })
      .then(({ insuranceKindOptions }) => insuranceKindOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label="Вид страхования"
        required={required}
        options={insuranceKindOptions}
        isSearchable
        loadOptions={loadInsuranceKindOptions}
        {...props}
      />
    </div>
  )
}

export default observer(InsuranceKindSelect)
