import React, { Fragment, useContext } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import { CurrencyInput, DateInput, RadioButtonGroup, RadioInput } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"

const AvrInstallment = ({ prefix = null, form, index }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const isMultiple = form.values.payment_kind.value.toString() == "1"
  const { avrStore } = useContext(ContractFormContext)
  const { avr_installment_payment_kinds } = avrStore.filtersOptions

  const { data: currentUser } = useContext(AuthStoreContext)
  const disabled = form.values.payment_status.value.toString() === "1" && ![5, 23].includes(currentUser.id)

  return (
    <Fragment>
      {isMultiple && <h5>Платеж № {index + 1}</h5>}
      <DateInput name={path("payment_on")} label="Дата оплаты АВР" disabled={disabled}  />
      {!isMultiple && (
        <RadioButtonGroup label="Форма оплаты АВР" name={path("payment_kind.value")} disabled={disabled} >
          {avr_installment_payment_kinds.map(({ label, value }) => (
            <RadioInput key={`payment_kind_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
      )}
      {isMultiple && <CurrencyInput name={path("payment_cost")} label="Сумма оплаченного КВ" disabled={disabled} />}
    </Fragment>
  )
}
export default AvrInstallment
