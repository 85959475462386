import React, { Fragment } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { RadioButtonGroup, RadioInput, Submit, TextInput } from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"
import SubBillSelect from "components/Common/SubBillSelect"

const Form = ({ store, values, handleSubmit }) => {
  const { kinds, forms } = store.filtersOptions
  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <RadioButtonGroup label="Форма счета" name="form.value">
          {forms.map(({ label, value }) => (
            <RadioInput key={`form-${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
        <RadioButtonGroup label="Счета и суб-счета" name="kind.value" disabled={values.id}>
          {kinds.map(({ label, value }) => (
            <RadioInput key={`kind-${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
        {values.kind.value.toString() === "0" && <TextInput name="first_level_name" label="Основной счет" required />}
        {values.kind.value.toString() === "1" && (
          <Fragment>
            <SubBillSelect name="first_level_bill.id" label="Основной счет" required kind="0" />
            <TextInput name="second_level_name" label="Суб-счет 1 порядка" required />
          </Fragment>
        )}
        {values.kind.value.toString() === "2" && (
          <Fragment>
            <SubBillSelect name="first_level_bill.id" label="Основной счет" required kind="0" />
            <SubBillSelect name="second_level_bill.id" label="Суб-счет 1 порядка" required kind="1" />
            <TextInput name="third_level_name" label="Суб-счет 2 порядка" required />
          </Fragment>
        )}
        <TextInput name="index" label="Индекс" required />
        <TextInput name="short_name" label="Краткое название счета" required />
        <TextInput name="comment" label="Комментарий" />
        <Submit>Сохранить</Submit>
      </form>
    </Fragment>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/bills")
  }),
  observer
)(Form)
