import React from "react"
import { Route, Switch } from "react-router-dom"
import List from "./containers/List"
import Calendar from "./containers/Calendar"

const ContractTasks = () => (
  <Switch>
    <Route path="/contract_tasks/calendar" component={Calendar} />
    <Route path="/contract_tasks" component={List} />
  </Switch>
)

export default ContractTasks
