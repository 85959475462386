import React from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"

const Edit = ({ url, disabled = false }) => (
  <Link to={url} style={{ paddingRight: "3px" }} className={classnames({"disabled-edit": disabled})}>
    <i className="fas fa-edit" />
  </Link>
)

export default Edit
