import React, { Fragment, useContext } from "react"
import { CurrencyInput, RadioButtonGroup, RadioInput, TextInput } from "components/UI"
import { observer } from "mobx-react"
import ContractFormContext from "contexts/ContractFormContext"
import { useFormikContext } from "formik"

const Accident = ({ prefix = null, index, form }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const { values } = useFormikContext()
  const { contractStore } = useContext(ContractFormContext)
  const { contractAccidentRisksOptions } = contractStore.filtersOptions
  return (
    <Fragment>
      <RadioButtonGroup label="Риск" name={path("risk.value")}>
        {contractAccidentRisksOptions.map(({ label, value }) => (
          <RadioInput key={`accident_risk_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {values.contract_accidents[index].risk.value == 4 && <TextInput name={path("other")} label="Другое" />}
      <CurrencyInput name={path("ss_cost")} label="Страховая сумма, руб." required={form.values.status.value.toString() === "1"} />
    </Fragment>
  )
}

export default observer(Accident)
