import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { TextArea } from "components/UI"
// import { FieldArray } from "formik"
import { getIn } from "formik"
import ContractProperty from "./ContractProperty"
import NestedFields from "components/UI/NestedFields"

const PropertiesTab = ({ store, values, ...props }) => {
  return (
    <Fragment>
      <br />
      {/* <FieldArray name="contract_properties" component={ContractProperties} /> */}
      <NestedFields
        name="contract_properties"
        label="Подвид страхования"
        component={ContractProperty}
        defaultValues={{
          kind: { id: 0 },
          beneficiary: null,
          territory: null,
          inflector: { id: 0 },
          total_ss_cost: null,
          terms: null,
          franchise: null,
          properties: [{ object: null, ss_cost: null }]
        }}
        disabledDestroy={({ prefix }) => values.has_additional && getIn(values, prefix).id}
      />
      <TextArea name="property_comment" label="Комментарий" />
    </Fragment>
  )
}

export default observer(PropertiesTab)
