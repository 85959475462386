import React from "react"
import compose from "utils/compose"
import { DateInput, ResetButton, SelectInput, Submit, TextInput } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import BsoKindSelect from "./BsoKindSelect"
import BsoBatchSelect from "./BsoBatchSelect"

const Filter = ({ store, values, handleSubmit }) => {
  const { form_statuses } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <InsurerSelect name="by_insurer" />
        </div>
        <div className="col-3">
          <ChannelSelect name="by_channel" />
        </div>
        {/* <div className="col-3">
          <DateInput name="by_updated_at_from" label="Период с" />
        </div>
        <div className="col-3">
          <DateInput name="by_updated_at_to" label="Период по" />
        </div> */}
        <div className="col-3">
          <BsoKindSelect name="by_bso_kind" insurer={values.by_insurer} />
        </div>
        <div className="col-3">
          <BsoBatchSelect name="by_bso_batch" bso_kind={values.by_bso_kind} />
        </div>
        <div className="col-3">
          <TextInput name="by_number" label="Номер БСО" />
        </div>
        <div className="col-3">
          <SelectInput name="by_form_status" label="Статус БСО" options={form_statuses} />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
