import React, { Fragment, useEffect, useState } from "react"
import Breadcrumbs from "pages/PaymentPlans/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import usePaymentPlan from "pages/PaymentPlans/hooks/usePaymentPlan"
import Form from "pages/PaymentPlans/components/Form"

const Edit = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { paymentPlan, editPaymentPlan, updatePaymentPlan } = usePaymentPlan()

  useEffect(() => {
    async function fetchData() {
      await editPaymentPlan(props.match.params.id)
      setIsLoaded(true)
    }
    fetchData()
  }, [])

  if (!isLoaded) return null

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{paymentPlan.comment}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form entity={paymentPlan} handleSubmit={async (values) => updatePaymentPlan(values)} />
    </Fragment>
  )
}

export default Edit
