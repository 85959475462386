import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import customEnum from "types/customEnum"
import Cargo from "./Cargo"
// import TransportKind from "./TransportKind"
import newCustomEnum from "types/newCustomEnum"

const Document = types.model("Document", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const Shipment = types.model("Shipment", {
  id: types.maybeNull(types.integer),
  contract_id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string),
  cargo_prefabricated: types.maybeNull(customEnum),
  cargo_route: types.maybeNull(types.string),
  cargo_shipment_on: types.maybeNull(customDate),
  cargo_combined: types.maybeNull(customEnum),
  comment: types.maybeNull(types.string),
  cargos: types.maybeNull(types.array(Cargo)),
  transport_kinds: types.maybeNull(types.array(customEnum)),
  currency: types.maybeNull(newCustomEnum),
  documents: types.maybeNull(types.array(Document)),
  ss_cost: types.maybeNull(types.string),
  sp_cost: types.maybeNull(types.string),
})

export default Shipment
