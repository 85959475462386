import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Button, Card, Modal, ModalHeader, ModalBody, ModalFooter, Paginate } from "components/UI"
import ContractStore from "stores/ContractStore/List"
import Table from "./Table"
import Filter from "./Filter"

const contractStore = ContractStore.create()

const ParentModal = ({ isOpen, closeModal, setFieldValue }) => {
  const [selected, setSelected] = useState({ id: null, number: null })

  useEffect(() => {
    contractStore.suggest()
  }, [isOpen])

  const setContract = () => {
    // console.log(selected)
    setFieldValue("contract", selected.toJSON())
    closeModal()
  }

  const handleSubmit = (values) => contractStore.suggest({ filter: { ...values } })

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Выбрать родительский договор</ModalHeader>
      <ModalBody>
        <Card>
          <Filter handleSubmit={handleSubmit} store={contractStore} />
        </Card>
        <Table store={contractStore} selected={selected} setSelected={setSelected} />
        <Paginate store={contractStore} params={contractStore.meta.filter} />
        <Button onClick={setContract}>Сохранить</Button>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(ParentModal)
