import React, { useState, Fragment } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { AsyncSelectInput, Button, Card, DateInput, SelectInput, Submit, TextArea } from "components/UI"
import ClientModal from "./ClientModal"

const Form = ({ store, handleSubmit, clientStore, subagentStore, ...props }) => {
  const [kindOptions, sourceOptions] = store.filtersOptions

  // const subagentOptions = subagentStore.subagentOptions

  // const onSubagentInputChange = value => {
  //   subagentStore.fetch({ filter: { by_name: value }, limit: 0 })
  // }

  const statusOptions = [
    { label: "Отказано", value: 0 },
    { label: "В работе", value: 1 },
    { label: "Закрыто", value: 2 }
  ]

  const clientOptions = (() => {
    const { id, name } = props.values.client
    return id ? [{ label: name, value: id }] : clientStore.clientOptions
  })()

  const onClientAdded = option => {
    clientOptions.push(option)
    props.setFieldValue("client", { id: option.value, name: option.label })
  }

  const loadClientOptions = inputValue =>
    clientStore.fetch({ filter: { by_name: inputValue }, limit: 0 }).then(({ clientOptions }) => clientOptions)

  const channelClientOptions = (() => {
    const { id, name } = props.values.channel_client
    return id ? [{ label: name, value: id }] : clientStore.clientOptions
  })()

  const loadChannelClientOptions = inputValue =>
    clientStore.fetch({ filter: { by_name: inputValue }, limit: 0 }).then(({ clientOptions }) => clientOptions)

  const channelSubagentOptions = (() => {
    const { id, name } = props.values.channel_subagent
    return id ? [{ label: name, value: id }] : subagentStore.subagentOptions
  })()

  const loadChannelSubagentOptions = inputValue =>
    subagentStore.fetch({ filter: { by_name: inputValue }, limit: 0 }).then(({ subagentOptions }) => subagentOptions)

  const [isOpen, setIsOpen] = useState(false)

  const { source_id } = props.values

  if (source_id === 4) {
    props.setFieldValue("channel_client.id", null)
  }

  if (source_id === 5) {
    props.setFieldValue("channel_subagent.id", null)
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <AsyncSelectInput
            name="client.id"
            label="Страхователь"
            options={clientOptions}
            isSearchable
            loadOptions={loadClientOptions}
          />
          <Button variant="success" onClick={() => setIsOpen(true)}>
            Создать страхователя
          </Button>
        </div>
        <Card>
          <SelectInput name="source_id" label="Канал поступления" options={sourceOptions} isSearchable />
          {source_id === 4 && (
            <AsyncSelectInput
              name="channel_subagent.id"
              label="Субагент"
              options={channelSubagentOptions}
              isSearchable
              loadOptions={loadChannelSubagentOptions}
            />
          )}
          {source_id === 5 && (
            <AsyncSelectInput
              name="channel_client.id"
              label="Страхователь"
              options={channelClientOptions}
              isSearchable
              loadOptions={loadChannelClientOptions}
            />
          )}
        </Card>
        <SelectInput name="kind.id" label="Тип" options={kindOptions} />
        <DateInput name="feedback_on" label="Дата обратной свзяи" />
        <TextArea name="description" label="Описание" />
        <SelectInput name="status" label="Статус" options={statusOptions} />
        <Submit>Сохранить</Submit>
      </form>
      <ClientModal {...{ isOpen, setIsOpen, onClientAdded }} />
    </Fragment>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  }),
  observer
)(Form)
