import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import InsurerStore from "stores/InsurerStore/List"

const insurerStore = InsurerStore.create()

const InsurerSelect = ({ name = "insurer", required = false, ...props }) => {
  const { values } = useFormikContext()
  const insurer = getIn(values, name, {}) || {}

  useEffect(() => {
    insurerStore.search({ order_by_ordered: "asc" })
  }, [])

  const insurerOptions = insurer.id ? [{ label: insurer.name, value: insurer.id }] : insurerStore.insurerOptions

  const loadInsurerOptions = (inputValue) =>
    insurerStore
      .search({ order_by_ordered: "asc", filter: { by_name: inputValue }, limit: 0 })
      .then(({ insurerOptions }) => insurerOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label="Страховая компания"
        required={required}
        options={insurerOptions}
        isSearchable
        loadOptions={loadInsurerOptions}
        {...props}
      />
    </div>
  )
}

export default observer(InsurerSelect)
