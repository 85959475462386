import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Contacts from "./containers/Contacts"
import InsurerDocuments from "./containers/InsurerDocuments"
import InsuranceProducts from "./containers/InsuranceProducts"
import AgencyContracts from "./containers/AgencyContracts"
import Finances from "./containers/Finances"
import PrivateRoute from "components/PrivateRoute"

const Insurers = () => (
  <Switch>
    <PrivateRoute
      path="/insurers/:id/edit"
      component={Edit}
      condition={(currentUser) => currentUser.privileges.includes("insurers")}
    />
    <Route path="/insurers/:id/contacts" component={Contacts} />
    <Route path="/insurers/:id/insurance_products" component={InsuranceProducts} />
    <Route path="/insurers/:id/agency_contracts" component={AgencyContracts} />
    <Route path="/insurers/:id/insurer_documents" component={InsurerDocuments} />
    <Route path="/insurers/:id/finances" component={Finances} />
    <PrivateRoute
      path="/insurers/new"
      component={Add}
      condition={(currentUser) => currentUser.privileges.includes("insurers")}
    />
    <Route path="/insurers/:id" component={Show} />
    <Route path="/insurers" component={List} />
  </Switch>
)

export default Insurers
