import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import SignerStore from "stores/SignerStore/One"
import Breadcrumbs from "pages/Signers/components/Breadcrumbs"
import Form from "pages/Signers/components/Form"
import { BreadcrumbItem } from "components/UI"

class Edit extends Component {
  signerStore = SignerStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.signerStore.fetch({ id })
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .update(values)
      .then(() => history.push("/signers"))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { signerStore: store, handleSubmit } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{store.user.name}</BreadcrumbItem>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit }} />
      </Fragment>
    )
  }
}

export default observer(Edit)
