import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import App from "./pages"
import * as serviceWorker from "./serviceWorker"
import * as Sentry from "@sentry/browser"

if (process.env.NODE_ENV !== "development") {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
