import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import SalePlanStore from "stores/SalePlanStore/One"
import Breadcrumbs from "pages/SalePlans/components/Breadcrumbs"
import Form from "pages/SalePlans/components/Form"
import { BreadcrumbItem } from "components/UI"

class Edit extends Component {
  sale_planStore = SalePlanStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.sale_planStore.fetch({ id })
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .update(values)
      .then(() => history.push("/sale_plans"))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { sale_planStore: store, handleSubmit } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{store.name}</BreadcrumbItem>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit }} />
      </Fragment>
    )
  }
}

export default observer(Edit)
