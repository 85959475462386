import React from "react"
import { observer } from "mobx-react"

const Checkbox = ({ bsos, bso, toggleChange }) => {
  return <input type="checkbox" checked={bsos.map((i) => i.id).includes(bso.id)} onChange={() => toggleChange(bso)} />
}

const Table = ({ data, bsos, toggleChange }) => {
  // console.log(bsos.map((i) => i.id))
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col" className="actions one">
            #
          </th>
          <th scope="col">Вид</th>
          <th scope="col">Серия</th>
          <th scope="col">Номер</th>
          <th scope="col">Статус геолокации</th>
        </tr>
      </thead>
      <tbody>
        {data.map((bso, index) => (
          <tr key={`bso-${index}`}>
            <td>
              <Checkbox {...{ bsos, bso, toggleChange }} />
            </td>
            <td>{bso.bso_kind.name}</td>
            <td>{bso.bso_batch.name}</td>
            <td>{bso.number}</td>
            <td>{bso.geolocation_status.label}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default observer(Table)
