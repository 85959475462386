import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import CourtStore from "stores/CourtStore/One"
import ContactStore from "stores/ContactStore/One"
import Breadcrumbs from "pages/Courts/containers/Contacts/components/Breadcrumbs"
import Form from "pages/Courts/containers/Contacts/components/Form"
import { BreadcrumbItem } from "components/UI"

class Edit extends Component {
  courtStore = CourtStore.create()
  contactStore = ContactStore.create()

  componentDidMount() {
    const { id, court_id } = this.props.match.params
    this.courtStore.fetch({ id: court_id })
    this.contactStore.fetch({ id, court_id })
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) => {
    const { id: court_id } = this.courtStore.data
    return store
      .update({ ...values, court_id })
      .then(() => history.push(`/courts/${court_id}/contacts`))
      .catch(({ response }) => setErrors(response.data.errors))
  }

  render() {
    const {
      courtStore,
      contactStore: store,
      handleSubmit
    } = this
    return (
      <Fragment>
        <Breadcrumbs {...{ court: courtStore.data }}>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit, courtStore }} />
      </Fragment>
    )
  }
}

export default observer(Edit)
