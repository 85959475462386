import React from "react"
import compose from "utils/compose"
import { DateInput, TextInput, ResetButton, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"

const Filter = ({ store, values, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <TextInput name="by_sender" label="Отправитель" />
      </div>
      <div className="col-3">
        <TextInput name="by_sender_address" label="Адрес отправителя" />
      </div>
      <div className="col-3">
        <TextInput name="by_recipient" label="Получатель" />
      </div>
      <div className="col-3">
        <TextInput name="by_recipient_address" label="Адрес получателя" />
      </div>
      <div className="col-3">
        <DateInput name="by_departure_on_from" label="Дата отправки с" />
      </div>
      <div className="col-3">
        <DateInput name="by_departure_on_to" label="Дата отправки по" />
      </div>
      <div className="col-3">
        <TextInput name="by_number" label="№ накладной/заказа" />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
  </form>
)

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) =>
      filterSubmitHandler( values, formikBag, { contract_id: formikBag.props.contract_id })
  }),
  observer
)(Filter)
