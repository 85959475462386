import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { AddressInput, PhoneInput, Submit, TextInput } from "components/UI"
import OrganizationSelect from "components/Common/OrganizationSelect"

const Form = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="name" label="Название Адресата" required />
    {/* <TextInput name="organization" label="Организация" /> */}
    <OrganizationSelect />
    <TextInput name="contact" label="Имя адресата (контактное лицо)" />
    <PhoneInput name="phone" label="Телефон" />
    <AddressInput name="address" label="Адрес" />
    <TextInput name="comment" label="Комментарий" />
    <Submit>Сохранить</Submit>
  </form>
)

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  })
)(Form)
