import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import customEnum from "types/customEnum"
import customDate from "types/customDate"
import format from "date-fns/format"
import newCustomEnum from "types/newCustomEnum"
import { DocumentFlow } from "./DocumentFlow"
import toOptions from "utils/newToOptions"

const Filter = types.model("Filter", {
  by_executor: types.maybeNull(customEnum),
  by_kind: types.maybeNull(types.number),
  by_status: types.maybeNull(types.number),
  by_created_at_from: types.maybeNull(customDate),
  by_created_at_to: types.maybeNull(customDate)
})

const FilterOptions = types.model("FilterOptions", {
  kinds: types.array(newCustomEnum, []),
  statuses: types.array(newCustomEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filter: {
    by_executor: { id: null, name: null },
    by_kind: null,
    by_status: null,
    by_created_at_from: null,
    by_created_at_to: null
  },
  filters_options: {
    kinds: [],
    statuses: []
  },
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const DocumentFlowStore = types
  .model("DocumentFlow", {
    data: types.array(DocumentFlow, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { kinds, statuses } = self.meta.filters_options
      return {
        kindsOptions: toOptions(kinds),
        statusesOptions: toOptions(statuses)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_created_at_from, by_created_at_to } = params.filter
        if (by_created_at_from) {
          params.filter = { ...params.filter, by_created_at_from: format(by_created_at_from, "yyyy-MM-dd") }
        }
        if (by_created_at_to) {
          params.filter = { ...params.filter, by_created_at_to: format(by_created_at_to, "yyyy-MM-dd") }
        }
      }

      return instance
        .get("/document_flows", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/document_flows/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const {
        status,
        data: { data, meta }
      } = response

      if (status === 200) applySnapshot(self, { data, meta: { ...metaDefaultValue, ...meta } })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default DocumentFlowStore
