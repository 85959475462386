import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Organization } from "./Organization"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"
import toOptions from "utils/newToOptions"

const Filter = types.model("Filter", {
  by_name: types.maybeNull(types.string)
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const OrganizationStore = types
  .model("Organization", {
    data: types.array(Organization, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get organizationOptions() {
      return self.data.map(({ id, name, organizationable_id, organizationable_type }) => {
        return {
          label: name,
          value: id,
          data: {
            organizationable_id: organizationable_id,
            organizationable_type: organizationable_type
          }
        }
      })
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      return instance
        .get("/organizations", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    search(params = {}) {
      self.setState("pending")
      return instance
        .get("/organizations/search", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/organizations/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      applySnapshot(self, data)
      // if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default OrganizationStore
