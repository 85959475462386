import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"

const Filter = types.model("Filter", {
  by_birthday_on: types.maybeNull(customDate),
  by_status: types.maybeNull(types.integer),
  by_position: types.maybeNull(customEnum),
  by_subdivision: types.maybeNull(customEnum),
  by_name: types.maybeNull(types.string)
})

const FilterOptions = types.model("FilterOptions", {
  mols: types.array(newCustomEnum, []),
  statuses: types.array(newCustomEnum, []),
  positions: types.array(customEnum, []),
  subdivisions: types.array(customEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filter: {
    by_birthday_on: null,
    by_position: { id: null, name: null },
    by_status: null,
    by_subdivision: { id: null, name: null },
    by_name: null,
  },
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 0
  }
}

export { Meta, metaDefaultValue }
