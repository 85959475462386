import { createContext } from "react"

const ContractFormContext = createContext({
  store: null,
  clientStore: null,
  insuranceProductStore: null,
  subagentStore: null
})

export default ContractFormContext
