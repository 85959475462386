import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import CourierStore from "stores/CourierStore/One"
import Breadcrumbs from "pages/Couriers/components/Breadcrumbs"
import { BreadcrumbItem, Card, Button } from "components/UI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import formatDate from "utils/formatDate"
import formatDateTime from "utils/formatDateTime"
import formatMoney from "utils/formatMoney"
import instance from "connection/instance"
import DestinationModal from "./../../components/DestinationModal"
import RouteModal from "./../../components/RouteModal"

const courierStore = CourierStore.create()

// const userStatus = (status) => {
//   switch (status.value) {
//     case 0:
//       return <i className="fa fa-circle" style={{ color: "green" }} title="Работает" />
//     case 1:
//       return <i className="fa fa-circle" style={{ color: "red" }} title="Не работает" />
//     default:
//       return <i className="fa fa-circle" style={{ color: "yellow" }} title="Отпуск" />
//   }
// }

const Show = (props) => {
  const { id } = props.match.params

  useEffect(() => {
    courierStore.fetch({ id })
  }, [id])

  const aa = (link) => {
    instance.get(link, { responseType: "blob" }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "Заявка на курьера.pdf") //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }

  const [destinationModalData, setDestinationModalData] = useState({})
  const [routeModalData, setRouteModalData] = useState({})

  const [isDestinationModalOpen, setIsDestinationModalOpen] = useState(false)
  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false)
  const openDestinationModal = () => {
    setIsDestinationModalOpen(true)
  }
  const openRouteModal = () => {
    const { from_contact, from_phone, from_address, from_organization } = courierStore.data.toJSON()

    const { to_contact, to_phone, to_address, to_organization } = courierStore.data.recipients[0].toJSON()

    setRouteModalData({
      from_contact,
      from_phone,
      from_address,
      from_organization,
      to_contact,
      to_phone,
      to_address,
      to_organization
    })

    setIsRouteModalOpen(true)
  }

  const openFromDestinationModal = () => {
    const {
      from_contact: contact,
      from_phone: phone,
      from_address: address,
      from_organization: organization
    } = courierStore.data.toJSON()
    setDestinationModalData({
      contact,
      phone,
      address,
      organization
    })
    openDestinationModal()
  }

  const openToDestinationModal = (index) => {
    const {
      to_contact: contact,
      to_phone: phone,
      to_address: address,
      to_organization: organization
    } = courierStore.data.recipients[index].toJSON()
    setDestinationModalData({
      contact,
      phone,
      address,
      organization
    })
    openDestinationModal()
  }

  const closeDestinationModal = () => setIsDestinationModalOpen(false)
  const closeRouteModal = () => setIsRouteModalOpen(false)

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{courierStore.id}</BreadcrumbItem>
      </Breadcrumbs>
      <Tabs defaultActiveKey="common">
        <Tab eventKey="common" title="Общие сведения">
          <br />
          <Card>
            <Button url={`/couriers/${courierStore.data.id}/edit`}>Редактировать</Button>&nbsp;
            <Button onClick={() => aa(`/couriers/${courierStore.data.id}/export`)} className="btn btn-info">
              Скачать
            </Button>
            &nbsp;
            <Button url={`/couriers/${courierStore.data.id}/edit?clone=true`}>Копировать</Button>
          </Card>
          <table className="table">
            <tbody>
              <tr>
                <td>Статус</td>
                <td style={{ width: "50%" }}>{courierStore.data.status.label}</td>
              </tr>
              <tr>
                <td>Заказчик</td>
                <td style={{ width: "50%" }}>{courierStore.data.user.name}</td>
              </tr>
              <tr>
                <td>Дата создания</td>
                <td style={{ width: "50%" }}>{formatDateTime(courierStore.data.created_at)}</td>
              </tr>
              <tr>
                <td>Оператор</td>
                <td style={{ width: "50%" }}>{courierStore.data.status_changed_by.name}</td>
              </tr>
              <tr>
                <td>Дата исполнения</td>
                <td style={{ width: "50%" }}>{formatDateTime(courierStore.data.decisioned_at)}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className="table">
            <tbody>
              <tr>
                <td>Срочность</td>
                <td style={{ width: "50%" }}>{courierStore.data.urgency.label}</td>
              </tr>
              {courierStore.data.urgency.value.toString() === "1" && (
                <tr>
                  <td>Обоснование</td>
                  <td style={{ width: "50%" }}>{courierStore.data.justification_of_urgency}</td>
                </tr>
              )}
              <tr>
                <td>Тип доставки</td>
                <td style={{ width: "50%" }}>{courierStore.data.delivery_kind.label}</td>
              </tr>
              <tr>
                <td>Сложность маршрута</td>
                <td style={{ width: "50%" }}>{courierStore.data.delivery_complexity.label}</td>
              </tr>
              <tr>
                <td>Связанные договоры</td>
                <td style={{ width: "50%" }}>
                  {courierStore.data.contracts.map((i) => (
                    <Fragment>{i.number}&nbsp;</Fragment>
                  ))}
                </td>
              </tr>
              <tr>
                <td>Задание</td>
                <td style={{ width: "50%" }}>{courierStore.data.task}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className="table">
            <tbody>
              <tr>
                <td>Служба доставки</td>
                <td style={{ width: "50%" }}>{courierStore.data.provider.name}</td>
              </tr>
              <tr>
                <td>Стоимость доставки</td>
                <td style={{ width: "50%" }}>{formatMoney(courierStore.data.cost)}</td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab eventKey="users" title="Маршрут">
          <br />
          <table className="table">
            <thead>
              <tr>
                <th colSpan="2">
                  Отправитель&nbsp;
                  <Button onClick={openFromDestinationModal} size="sm" className="float-right">
                    Создать типовой адресат
                  </Button>
                  <Button onClick={openRouteModal} size="sm" className="float-right">
                    Создать типовой маршрут
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Контактное лицо</td>
                <td style={{ width: "50%" }}>{courierStore.data.from_contact}</td>
              </tr>
              <tr>
                <td>Организация</td>
                <td style={{ width: "50%" }}>{courierStore.data.from_organization.name}</td>
              </tr>
              <tr>
                <td>Адрес</td>
                <td style={{ width: "50%" }}>{courierStore.data.from_address}</td>
              </tr>
              <tr>
                <td>Телефон</td>
                <td style={{ width: "50%" }}>{courierStore.data.from_phone}</td>
              </tr>
              <tr>
                <td>Дата отправки</td>
                <td style={{ width: "50%" }}>{formatDate(courierStore.data.from_date_on)}</td>
              </tr>
              <tr>
                <td>Время отправки</td>
                <td style={{ width: "50%" }}>
                  с {courierStore.data.from_start} до {courierStore.data.from_finish}
                </td>
              </tr>
              <tr>
                <td>Временные границы</td>
                <td style={{ width: "50%" }}>{courierStore.data.from_time_kind.label}</td>
              </tr>
              <tr>
                <td>Комментарий</td>
                <td style={{ width: "50%" }}>{courierStore.data.from_comment}</td>
              </tr>
            </tbody>
          </table>
          {courierStore.data.recipients.map((i, index) => (
            <table key={`recipient-${i}`} className="table">
              <thead>
                <tr>
                  <th colSpan="2">
                    Получатель {index + 1}
                    <Button onClick={() => openToDestinationModal(index)} size="sm" className="float-right">
                      Создать типовой адресат
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Контактное лицо</td>
                  <td style={{ width: "50%" }}>{i.to_contact}</td>
                </tr>
                <tr>
                  <td>Организация</td>
                  <td style={{ width: "50%" }}>{i.to_organization.name}</td>
                </tr>
                <tr>
                  <td>Адрес</td>
                  <td style={{ width: "50%" }}>{i.to_address}</td>
                </tr>
                <tr>
                  <td>Телефон</td>
                  <td style={{ width: "50%" }}>{i.to_phone}</td>
                </tr>
                <tr>
                  <td>Дата получения</td>
                  <td style={{ width: "50%" }}>{formatDate(i.to_date_on)}</td>
                </tr>
                <tr>
                  <td>Время получения</td>
                  <td style={{ width: "50%" }}>
                    с {i.to_start} до {i.to_finish}
                  </td>
                </tr>
                <tr>
                  <td>Временные границы</td>
                  <td style={{ width: "50%" }}>{i.to_time_kind.label}</td>
                </tr>
                <tr>
                  <td>Комментарий</td>
                  <td style={{ width: "50%" }}>{i.to_comment}</td>
                </tr>
                <tr>
                  <td>Грузы</td>
                  <td style={{ width: "50%" }}>
                    {i.recipient_cargos.map((i) => (i.kind.value === 4 ? i.kind_other : i.kind.label)).join(", ")}
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </Tab>
      </Tabs>
      <DestinationModal
        isOpen={isDestinationModalOpen}
        closeModal={closeDestinationModal}
        data={destinationModalData}
      />
      <RouteModal isOpen={isRouteModalOpen} closeModal={closeRouteModal} data={routeModalData} />
    </Fragment>
  )
}

export default observer(Show)
