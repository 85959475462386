import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect, useContext } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import LossesStore from "stores/LossStore/One"
import { BreadcrumbItem, Button, Card } from "components/UI"
import formatDate from "utils/formatDate"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Bbutton from "react-bootstrap/Button"
import AuthStoreContext from "contexts/AuthStoreContext"
import { Link } from "react-router-dom"

const contractStore = ContractStore.create()
const lossStore = LossesStore.create()

const lossStatus = (lossStore, status, currentUser) => {
  const statuses = ["declared", "collect_document", "review", "closed"]
  const onClick = (status) => {
    lossStore.update({
      ...lossStore.data,
      status: { value: statuses[status] },
      status_updated_on: new Date(),
      responsible: { id: currentUser.id }
    })
  }
  return (
    <ButtonGroup size="sm">
      <Bbutton variant="success" active={statuses.indexOf(status?.value) >= 0} onClick={() => onClick(0)}>
        Заявлен
      </Bbutton>
      <Bbutton variant="success" active={statuses.indexOf(status?.value) >= 1} onClick={() => onClick(1)}>
        Сбор документов
      </Bbutton>
      <Bbutton variant="success" active={statuses.indexOf(status?.value) >= 2} onClick={() => onClick(2)}>
        Рассмотрение убытка
      </Bbutton>
      <Bbutton variant="success" active={statuses.indexOf(status?.value) >= 3} onClick={() => onClick(3)}>
        Закрыт
      </Bbutton>
    </ButtonGroup>
  )
}

const Show = (props) => {
  const { id, contract_id } = props.match.params
  useEffect(() => {
    contractStore.fetch({ id: contract_id })
    lossStore.fetch({ id, contract_id })
  }, [id, contract_id])
  console.log(lossStore.isFetched)

  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contractStore.id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contractStore.id}/losses`}>Убытки</BreadcrumbItem>
        <BreadcrumbItem active>{lossStore.id}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${contractStore.id}/losses/${lossStore.data.id}/edit`}>Редактировать</Button>
      </Card>
      <br />
      <div className="row">
        <div className="col-12">
          <table className="table">
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Стадия</td>
                <td>{lossStatus(lossStore, lossStore.data.status, currentUser)}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата начала текущей стадии</td>
                <td>{formatDate(lossStore.data.status_updated_on)}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Ответственный</td>
                <td style={{ width: "50%" }}>{lossStore.data.responsible.name}</td>
              </tr>
              <tr>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Страхователь</td>
                <td style={{ width: "50%" }}>{contractStore.client.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Контактное лицо Страхователя</td>
                <td style={{ width: "50%" }}>{lossStore.data.client_contact?.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Страховая компания</td>
                <td style={{ width: "50%" }}>{contractStore.insurer.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Контактное лицо Страховой компании</td>
                <td style={{ width: "50%" }}>{lossStore.data.insurer_contact?.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Договор страхования</td>
                <td style={{ width: "50%" }}><Link to={`/contracts/${lossStore.data.contract?.id}`}>{lossStore.data.contract?.number}</Link></td>
              </tr>
              <tr>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата события / заявления</td>
                <td style={{ width: "50%" }}>
                  {formatDate(lossStore.data.date_on)} / {formatDate(lossStore.data.statement_on)}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Описание / обстоятельства</td>
                <td style={{ width: "50%" }}>{lossStore.data.description}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Резерв убытка, руб.</td>
                <td style={{ width: "50%" }}>{lossStore.data.reserve}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Комментарии</td>
                <td style={{ width: "50%" }} dangerouslySetInnerHTML={{ __html: lossStore.data.comment }} />
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Исполнитель</td>
                <td style={{ width: "50%" }}>{lossStore.data.executor.name}</td>
              </tr>
              <tr>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Результат / Форма выплаты</td>
                <td style={{ width: "50%" }}>
                  {lossStore.data.result.label} / {lossStore.data.form.label}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Сумма выплаты</td>
                <td style={{ width: "50%" }}>{lossStore.data.payout}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Документы</td>
                <td style={{ width: "50%" }}>
                  {lossStore.data.documents.map((i, index) =>
                    i.attachments
                      .filter((i) => i.file.url)
                      .map((attachment) => (
                        <a
                          key={`attachment-${attachment.id}`}
                          href={attachment.file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {decodeURI(attachment.file.url.replace(/^.*[\\|/]/, ""))}
                          <br />
                        </a>
                      ))
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

export default observer(Show)
