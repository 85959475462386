import { types } from "mobx-state-tree"

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  pagination: types.maybeNull(Paginate)
})

export default Meta
