import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { DateInput, TextInput, CurrencyInput } from "components/UI"

const AwardTab = ({ store, handleSubmit, values, setFieldValue, ...props }) => {
  return (
    <Fragment>
      <br />
      <CurrencyInput name="order_award.salary" label="Сумма премирования" required />
      <DateInput name="order_award.date_on" label="Дата премирования (не позднее)" required />
      <TextInput name="order_award.reason" label="Основание премирования" required />
      <TextInput name="order_award.comment" label="Комментарий" />
    </Fragment>
  )
}

export default observer(AwardTab)
