import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import HoldingStore from "stores/HoldingStore/One"
import ClientStore from "stores/ClientStore/List"
import Breadcrumbs from "pages/Holdings/components/Breadcrumbs"
import Tabs from "pages/Holdings/components/Tabs"
import { Paginate } from "components/UI"
import { BreadcrumbItem } from "components/UI"
import Table from "./components/Table"
// import Filter from "./components/Filter"

const holdingStore = HoldingStore.create()
const clientStore = ClientStore.create()

const List = (props) => {
  const { holding_id } = props.match.params

  useEffect(() => {
    holdingStore.fetch({ id: holding_id })
    clientStore.fetch({ ...getParams(), filter: { by_holding_id: holding_id } })
  }, [])

  // грязный хак, не удалять!
  console.log(clientStore.isFetched)

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/holdings/${holdingStore.id}`}>{holdingStore.name}</BreadcrumbItem>
        <BreadcrumbItem active>Договоры</BreadcrumbItem>
      </Breadcrumbs>
      <Tabs {...{ id: holding_id }} />
      <br />
      <Table store={clientStore} />
      <Paginate store={clientStore} params={{ by_holding_id: holding_id }} />
    </Fragment>
  )
}

export default observer(List)
