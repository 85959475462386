import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { DateInput, TextInput, CurrencyInput, SelectInput } from "components/UI"
import PositionSelect from "components/Common/PositionSelect"
import SubdivisionSelect from "components/Common/SubdivisionSelect"

const HireTab = ({ store, handleSubmit, values, setFieldValue, ...props }) => {
  return (
    <Fragment>
      <br />
      <DateInput
        name="order_hire.hire_on"
        label="Дата приема на работу"
        required
        value={values.order_hire?.hire_on ? values.order_hire.hire_on : values.date_on}
      />
      <CurrencyInput name="order_hire.salary" label="Оклад" required />
      <SelectInput
        name="order_hire.probation.value"
        label="Испытательный срок, мес."
        required
        options={[
          { label: "1", value: 0 },
          { label: "2", value: 1 },
          { label: "3", value: 2 }
        ]}
      />
      <TextInput
        name="order_hire.contract_number"
        label="Номер трудового договора"
        required
      />
      <DateInput
        name="order_hire.contract_date_on"
        label="Дата трудового договора"
        required
      />
      <SubdivisionSelect name="order_hire.subdivision.id" label="Подразделение" required />
      <PositionSelect name="order_hire.position.id" label="Должность" required />
      <TextInput name="order_hire.conditions" label="Условия приема на работу / характер работы" />
      <TextInput name="order_hire.comment" label="Комментарий" />
    </Fragment>
  )
}

export default observer(HireTab)
