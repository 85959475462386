import React, { Fragment, useState, useEffect } from "react"
import { observer } from "mobx-react"
import { Button, Card, Destroy } from "components/UI"
import Modal from "./Modal"
import BsoStore from "stores/BsoStore/One"
import { DropdownButton, Dropdown } from "react-bootstrap"
import { useFormikContext, getIn } from "formik"

const bsoStore = BsoStore.create()

const BsoTab = ({ store, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  const setBsos = (bsos) =>
    props.setFieldValue(
      "bsos",
      props.values.bsos
        .map((i) => {
          return { ...i, _destroy: true, is_current: false }
        })
        .concat(
          bsos.map((i) => {
            return { ...i, is_current: true }
          })
        )
    )

  useEffect(() => {
    bsoStore.fetchMeta()
  }, [])

  const { form_statuses, geolocation_statuses } = bsoStore.filtersOptions

  const setGeolocationStatus = (index, geolocation_status) => (e) => {
    props.setFieldValue(`bsos.${index}.geolocation_status`, geolocation_status)
    bsoStore.update({ ...props.values.bsos[index], geolocation_status: geolocation_status })
  }

  const onDestroyClick = (id) => (e) =>
    props.setFieldValue(
      "bsos",
      props.values.bsos.map((i) => (i.id === id ? { ...i, _destroy: true } : i))
    )

  const { errors } = useFormikContext()
  const error = getIn(errors, "bsos", false)

  return (
    <Fragment>
      <br />
      <Card>
        <Button
          onClick={() => setIsOpen(true)}
          disabled={!props.values.channel.id || !props.values.insurer.id || !props.values.insurance_kind.id}
        >
          Добавить
        </Button>
        {error && <div className="invalid-feedback">{error[0]}</div>}
        {(!props.values.channel.id || !props.values.insurer.id || !props.values.insurance_kind.id) && (
          <Fragment>
            &nbsp;&nbsp;<span>Для выбора БСО необходимо обязательно указать СК и Канал продаж</span>
          </Fragment>
        )}
      </Card>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col">Вид</th>
            <th scope="col">Серия</th>
            <th scope="col">Номер</th>
            <th scope="col">Геолокация</th>
            <th scope="col" className="actions one" />
          </tr>
        </thead>
        <tbody>
          {props.values.bsos
            .sort((a, b) => a.is_current - b.is_current)
            .reverse()
            .map((bso, index) => (
              <tr key={`bso-${index}`}>
                <td>{bso.bso_kind.name}</td>
                <td>{bso.bso_batch.name}</td>
                <td>{bso.number}</td>
                <td>
                  <DropdownButton id="dropdown-basic-button" title={bso.geolocation_status.label}>
                    {geolocation_statuses.map((geolocation_status) => (
                      <Dropdown.Item onClick={setGeolocationStatus(index, geolocation_status)}>
                        {geolocation_status.label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </td>
                <td>{bso.is_current && <Destroy action={onDestroyClick(bso.id)} />}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal {...{ values: props.values, isOpen, setIsOpen, setBsos }} />
    </Fragment>
  )
}

export default observer(BsoTab)
