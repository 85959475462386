import instance from "connection/instance"
import { types, applySnapshot } from "mobx-state-tree"
import formatDate from "utils/formatDate"
import { User, userDefaultValue } from "./User"
import toOptions from "utils/toOptions"
import newToOptions from "utils/newToOptions"
import state from "types/state"
import { prepare } from "./utils"
import { Meta, metaDefaultValue } from "./OneMeta"

const UserStore = types
  .model("UserStore", {
    data: types.optional(User, userDefaultValue),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(state)
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get f_name() {
      return self.data.f_name
    },

    get l_name() {
      return self.data.l_name
    },

    get p_name() {
      return self.data.p_name
    },

    get name() {
      return self.data.name
    },

    get position() {
      const { position } = self.data
      return position ? position.name : ""
    },

    get subdivision() {
      const { subdivision } = self.data
      return subdivision ? subdivision.name : ""
    },

    get birthday_on() {
      const { birthday_on } = self.data
      return birthday_on ? formatDate(birthday_on) : ""
    },

    get mol() {
      const { mol } = self.data
      return mol ? mol.label : ""
    },

    get emails() {
      return self.data.emails
    },

    get phones() {
      return self.data.phones
    },
    
    get channel() {
      return self.data.channel
    },

    get filtersOptions() {
      const { mols, statuses, positions, subdivisions } = self.meta.filters_options
      return {
        molsOptions: newToOptions(mols),
        statusesOptions: newToOptions(statuses),
        positionsOptions: toOptions(positions),
        subdivisionsOptions: toOptions(subdivisions)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/users/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/users/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/users`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/users/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { ...self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default UserStore
