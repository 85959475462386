import { types } from "mobx-state-tree"
import customIdValue from "types/customIdValue"

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  emails: types.maybeNull(types.array(customIdValue)),
  phones: types.maybeNull(types.array(customIdValue))
})

export default User
