import React, { useEffect } from "react"
import compose from "utils/compose"
import { AsyncSelectInput, DateInput, ResetButton, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import InsuranceKindStore from "stores/InsuranceKindStore/List"
import InsurerStore from "stores/InsurerStore/List"

const insuranceKindStore = InsuranceKindStore.create()
const insurerStore = InsurerStore.create()

const Filter = ({ values, handleSubmit, ...props }) => {
  useEffect(() => {
    insuranceKindStore.fetch()
    insurerStore.fetch()
  }, [])

  const insuranceKindOptions = (() => {
    if (values.by_insurance_kind) {
      const { id, name } = values.by_insurance_kind
      const options = insuranceKindStore.insuranceKindOptions
      if (id) {
        options.concat([{ label: name, value: id }])
      }
      return options
    }
    return insuranceKindStore.insuranceKindOptions
  })()

  const loadInsuranceKindOptions = (value) =>
    insuranceKindStore
      .fetch({ filter: { by_name: value }, limit: 0 })
      .then(({ insuranceKindOptions }) => insuranceKindOptions)

  const insurerOptions = (() => {
    if (values.by_insurer) {
      const { id, name } = values.by_insurer
      const options = insurerStore.insurerOptions
      if (id) {
        options.concat([{ label: name, value: id }])
      }
      return options
    }
    return insurerStore.insurerOptions
  })()

  const loadInsurerOptions = (value) =>
    insurerStore.fetch({ filter: { by_name: value }, limit: 0 }).then(({ insurerOptions }) => insurerOptions)

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <AsyncSelectInput
            name="by_insurer.id"
            label="Страховая компания"
            options={insurerOptions}
            isSearchable
            loadOptions={loadInsurerOptions}
          />
        </div>
        <div className="col-3">
          <AsyncSelectInput
            name="by_insurance_kind.id"
            label="Вид страхования"
            options={insuranceKindOptions}
            isSearchable
            loadOptions={loadInsuranceKindOptions}
          />
        </div>
        <div className="col-3">
          <DateInput name="by_start_on_from" label="Период с" />
        </div>
        <div className="col-3">
          <DateInput name="by_start_on_to" label="Период по" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton defaultFilter={props.defaultFilter} />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    // handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
    handleSubmit: (values, formikBag) => filterSubmitHandler({ ...formikBag.props.defaultFilter, ...values }, formikBag)
  }),
  observer
)(Filter)
