import parseMoney from "utils/parseMoney"

const prepare = (params = {}) => {
  const {
    status: { value: status },
    bill: { id: bill_id },
    budget_nor_items,
    ...other
  } = params
  return {
    ...other,
    status,
    bill_id,
    budget_nor_items: budget_nor_items.map((i) => {
      return { ...i, cost: parseMoney(i.cost) }
    })
  }
}

export { prepare }
