import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import BillStore from "stores/BillStore/One"
import Breadcrumbs from "pages/Bills/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"

class Show extends Component {
  billStore = BillStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.billStore.fetch({ id })
  }

  render() {
    const data = this.billStore.data
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{data.short_name}</BreadcrumbItem>
        </Breadcrumbs>
        <Card>
          <Button url={`/bills/${data.id}/edit`}>Радактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Форма счета</td>
                  <td style={{ width: "50%" }}>{data.form.label}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Счета и суб-счета</td>
                  <td style={{ width: "50%" }}>{data.kind.label}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Основной счет</td>
                  <td style={{ width: "50%" }}>
                    {data.kind.value.toString() === "0" ? data.first_level_name : data.first_level_bill.first_level_name}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Суб-счет 1-го порядка</td>
                  <td style={{ width: "50%" }}>
                    {["1", "2"].includes(data.kind.value.toString()) ? (data.second_level_name || data.second_level_bill.second_level_name) : "-"}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Суб-счет 2-го порядка</td>
                  <td style={{ width: "50%" }}>{data.kind.value.toString() === "2" ? data.third_level_name : "-"}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Индекс</td>
                  <td style={{ width: "50%" }}>{data.index}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Краткое название счета</td>
                  <td style={{ width: "50%" }}>{data.short_name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Комментарий</td>
                  <td style={{ width: "50%" }}>{data.comment}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
