import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import DestinationStore from "stores/DestinationStore/One"
import Breadcrumbs from "pages/Destinations/components/Breadcrumbs"
import Form from "pages/Destinations/components/Form"
import { BreadcrumbItem } from "components/UI"

class Add extends Component {
  destinationStore = DestinationStore.create()

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .create(values)
      .then(() => history.push("/destinations"))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { destinationStore: store, handleSubmit } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>Создать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit }} />
      </Fragment>
    )
  }
}

export default observer(Add)
