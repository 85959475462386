import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { TextInput, CurrencyInput } from "components/UI"

const FinancesTab = (props) => {
  const path = {
    4: "order_hire",
    5: "order_change_position",
    8: "order_salary_change"
  }[props.values.order_subject.id]

  return (
    <Fragment>
      <br />
      <CurrencyInput name={`${path}.o2`} label="О2" />
      <TextInput name={`${path}.o2_comment`} label="Комментарий" />
    </Fragment>
  )
}

export default observer(FinancesTab)
