import React, { useContext, useEffect } from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import { useFormikContext } from "formik"
import AuthStoreContext from "contexts/AuthStoreContext"

const StandartCustomerInviters = ({ disabled = false }) => {
  const { values, setFieldValue } = useFormikContext()
  const { data: currentUser } = useContext(AuthStoreContext)

  useEffect(() => {
    if (values.id === null) {
      if ([5, 6, 7, 10, 13, 14, 16].includes(currentUser.id)) {
        setFieldValue("customer_inviter", currentUser.name.split(" ")[0])
      }
      if (currentUser.subdivision.id === 6) {
        setFieldValue("customer_inviter", "Прокошева")
      }
      if (currentUser.subdivision.id === 9) {
        setFieldValue("customer_inviter", "Мищенкова")
      }
    }
  }, [])

  const names = [
    "Пузанов",
    "Москович",
    "Прокошева",
    "Котельникова",
    "Тюнев",
    "Квиатковская",
    "Иванов",
    "Дузкрятченко",
    "Мищенкова",
    "Юхименко",
    "Мартынов"
  ]

  const onClick = (name) => {
    setFieldValue("customer_inviter", name)
  }

  return (
    <ButtonGroup>
      {names.map((name) => (
        <Button variant="success" onClick={() => onClick(name)}>
          {name}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default StandartCustomerInviters
