import React, { Fragment } from "react"
import { observer } from "mobx-react"
import classNames from "classnames"

const MortgageTab = ({ store, values, setFieldValue, errors }) => {
  const error = errors["contract_mortgage"]
  return (
    <Fragment>
      <br />
      <div className={classNames("form-group", { " is-invalid": error })}>
        <label for="tPcNMOMcPk">Риски</label>
        <fieldset>
          <div class="form-check-inline form-check">
            {[
              { label: "Несчастный случай", value: "0" },
              { label: "Имущество", value: "1" },
              { label: "Титул", value: "2" }
            ].map(({ label, value }) => (
              <Fragment>
                <input
                  type="checkbox"
                  class="form-check-input"
                  id={`risk-${value}`}
                  onChange={() => {
                    const risks = values.contract_mortgage.risks || []
                    if (risks.includes(value)) {
                      setFieldValue(
                        "contract_mortgage.risks",
                        risks.filter((i) => i !== value)
                      )
                    } else {
                      setFieldValue("contract_mortgage.risks", risks.concat([value]))
                    }
                  }}
                  checked={values.contract_mortgage?.risks?.includes(value)}
                />
                <label class="form-check-label" for={`risk-${value}`}>
                  {label}
                </label>
                &nbsp; &nbsp;
              </Fragment>
            ))}
          </div>
        </fieldset>
        <div class="invalid-feedback">не может быть пустым</div>
      </div>
    </Fragment>
  )
}

export default observer(MortgageTab)
