import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { DateInput, TextInput, SelectInput } from "components/UI"
import addYears from "date-fns/addYears"
import subDays from "date-fns/subDays"

const VacationTab = ({ setFieldValue }) => {
  return (
    <Fragment>
      <br />
      <SelectInput
        name="order_vacation.kind.value"
        label="Вид отпуска"
        required
        options={[
          { label: "Ежегодный основной оплачиваемый", value: 0 },
          { label: "Ежегодный дополнительный оплачиваемый", value: 1 },
          { label: "Декретный", value: 2 },
          { label: "Учебный", value: 3 },
          { label: "Без сохранения заработной платы", value: 4 },
          { label: "Нетиповой", value: 5 }
        ]}
      />
      <DateInput
        name="order_vacation.perion_start_on"
        label="За период работы с"
        onChange={(e) => {
          setFieldValue("order_vacation.perion_start_on", e)
          setFieldValue("order_vacation.perion_finish_on", subDays(addYears(e, 1), 1))
        }}
      />
      <DateInput name="order_vacation.perion_finish_on" label="За период работы по" disabled />
      <DateInput name="order_vacation.vacation_start_on" label="Отпуск с" required />
      <DateInput name="order_vacation.vacation_finish_on" label="Отпуск по" required />
      <TextInput name="order_vacation.comment" label="Комментарий" />
    </Fragment>
  )
}

export default observer(VacationTab)
