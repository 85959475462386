import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import RiskStore from "stores/RiskStore/One"
import Breadcrumbs from "pages/Risks/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"

class Show extends Component {
  riskStore = RiskStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.riskStore.fetch({ id })
  }

  render() {
    const {
      riskStore: {
        data: { id, name, comment }
      }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumbs>
        <Card>
          <Button url={`/risks/${id}/edit`}>Редактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Название</td>
                  <td style={{ width: "50%" }}>{name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Комментарий</td>
                  <td style={{ width: "50%" }}>{comment}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
