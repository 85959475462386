import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import OrderSubjectStore from "stores/OrderSubjectStore/One"
import Breadcrumbs from "pages/OrderSubjects/components/Breadcrumbs"
import Form from "pages/OrderSubjects/components/Form"
import { BreadcrumbItem } from "components/UI"

class Edit extends Component {
  order_subjectStore = OrderSubjectStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.order_subjectStore.fetch({ id })
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .update(values)
      .then(() => history.push("/order_subjects"))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { order_subjectStore: store, handleSubmit } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem url={`/order_subjects/${store.id}`}>{store.name}</BreadcrumbItem>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit }} />
      </Fragment>
    )
  }
}

export default observer(Edit)
