import React from "react"
import compose from "utils/compose"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { withRouter } from "react-router"
import { AddressInput, EmailInput, Submit, TextInput } from "components/UI"
import { Emails, Phones } from "components/Common"
import formSubmitHandler from "utils/formSubmitHandler"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import InfoTab from "./InfoTab"

const Form = ({ store, handleSubmit, ...props }) => (
  <form onSubmit={handleSubmit}>
    <Tabs defaultActiveKey="info">
      <Tab eventKey="info" title="Инфо о сотруднике">
        <br />
        <InfoTab {...{ store, ...props }} />
      </Tab>
      <Tab eventKey="contacts" title="Контактные данные">
        <br />
        <AddressInput name="address" label="Адрес регистрации" />
        <Emails />
        <Phones />
      </Tab>
      <Tab eventKey="account" title="Учетная запись Sandy">
        <br />
        <EmailInput name="email" label="Email" />
        <TextInput name="password" label="Пароль" type="password" />
        <TextInput name="password_confirmation" label="Подтверждение пароля" type="password" />
      </Tab>
    </Tabs>
    <Submit>Сохранить</Submit>
  </form>
)

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/users")
  }),
  observer
)(Form)
