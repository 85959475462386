import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { FieldArray } from "formik"
import {
  CheckboxInput,
  CheckboxGroup,
  CurrencyInput,
  DateInput,
  PercentageInput,
  RadioButtonGroup,
  RadioInput,
  TextArea,
  TextInput
} from "components/UI"
import Cargos from "./Cargos"

const CargoTab = ({ store, ...props }) => {
  const {
    cargoKindOptions,
    cargoProcedureForSubmittingDeclarationOptions,
    cargoProcedureForCertificateIssueOptions,
    cargoProcedureForSpPaymentOptions,
    cargoPrefabricatedOptions,
    cargoCombinedOptions,
    transportKindsOptions
  } = store.filtersOptions
  return (
    <Fragment>
      <br />
      <RadioButtonGroup label="Договор" name="cargo_kind.id">
        {cargoKindOptions.map(({ label, value }) => (
          <RadioInput
            key={`cargo_kind_${value}`}
            label={label}
            id={value}
            onChange={(e) => {
              props.setFieldValue("cargo_kind.id", e.target.value)
              if (e.target.value.toString() === "1") {
                props.setFieldValue("sp_paid_inflector.id", 1)
                props.setFieldValue("contract_installments", [])
              }

              if (e.target.value.toString() === "1") {
                // Генеральный
                props.setFieldValue("is_need_to_prolongation", true)
                props.setFieldValue("cancel_prolongation_cause", '')
              } else {
                props.setFieldValue("is_need_to_prolongation", false)
                props.setFieldValue("cancel_prolongation_cause", 'Разовый договор')
              }
            }}
          />
        ))}
      </RadioButtonGroup>
      {/* <TextInput name="cargo_beneficiary" label="Выгодоприобретатель" /> */}
      <RadioButtonGroup label="Тип договора" name="cargo_prefabricated.id">
        {cargoPrefabricatedOptions.map(({ label, value }) => (
          <RadioInput
            key={`cargo_prefabricated_${value}`}
            label={label}
            id={value}
            onChange={(e) => {
              props.setFieldValue(`cargo_prefabricated.id`, e.target.value)
              props.setFieldValue(`cargos`, props.values.cargos.slice(0, 1))
            }}
          />
        ))}
      </RadioButtonGroup>
      <FieldArray name="cargos" component={Cargos} />

      {parseInt(props.values.cargo_kind.id) === 1 && (
        <Fragment>
          <TextInput name="cargo_route" label="Маршруты" />
          <CheckboxGroup label="Виды транспорта" name="transport_kinds">
            {transportKindsOptions.map(({ label, value }) => (
              <CheckboxInput key={`transport_kinds_${value}`} label={label} id={value} />
            ))}
          </CheckboxGroup>
          <RadioButtonGroup label="Порядок подачи деклараций" name="cargo_procedure_for_submitting_declaration.id">
            {cargoProcedureForSubmittingDeclarationOptions.map(({ label, value }) => (
              <RadioInput key={`cargo_procedure_for_submitting_declaration_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>

          <RadioButtonGroup
            label="Порядок выдачи сертификатов на отгрузки"
            name="cargo_procedure_for_certificate_issue.id"
          >
            {cargoProcedureForCertificateIssueOptions.map(({ label, value }) => (
              <RadioInput key={`cargo_procedure_for_certificate_issue_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>

          {/* <RadioButtonGroup label="Порядок оплаты сп" name="cargo_procedure_for_sp_payment.id">
            {cargoProcedureForSpPaymentOptions.map(({ label, value }) => (
              <RadioInput key={`cargo_procedure_for_sp_payment_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup> */}
          {parseInt(props.values.cargo_procedure_for_sp_payment.id) === 1 && (
            <CurrencyInput name="cargo_deposit_sp_cost" label="Сумма депозитной сп" />
          )}
        </Fragment>
      )}

      {parseInt(props.values.cargo_kind.id) === 0 && (
        <Fragment>
          <CurrencyInput name="cargo_total_cost" label="Общая сс по отгрузке (итого)" readOnly />
          <TextInput name="cargo_condition" label="Условия страхования" />
          <TextInput name="cargo_route" label="Маршрут" />
          <PercentageInput name="cargo_tarif" label="Тарифная ставка, %" />
          <DateInput name="cargo_responsibility_from" label="Период ответственности с" />
          <DateInput name="cargo_responsibility_to" label="Период ответственности по" />
          <CheckboxGroup label="Виды транспорта" name="transport_kinds">
            {transportKindsOptions.map(({ label, value }) => (
              <CheckboxInput key={`transport_kinds_${value}`} label={label} id={value} />
            ))}
          </CheckboxGroup>
          {/* <RadioButtonGroup label="Перевозка комбинированная" name="cargo_combined.id">
            {cargoCombinedOptions.map(({ label, value }) => (
              <RadioInput key={`cargo_combined_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup> */}
        </Fragment>
      )}

      <TextArea name="cargo_comment" label="Комментарий" />
    </Fragment>
  )
}

export default observer(CargoTab)
