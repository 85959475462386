import React, { Fragment, useEffect, useContext } from "react"
import { observer } from "mobx-react"
import InsurerDocumentsStore from "stores/InsurerDocumentStore/List"
import Breadcrumbs from "pages/Insurers/components/Breadcrumbs"
import { Button, Card, Paginate, BreadcrumbItem } from "components/UI"
import InsurerStore from "stores/InsurerStore/One"
import Tabs from "pages/Insurers/components/Tabs"
import Table from "./components/Table"
import AuthStoreContext from "contexts/AuthStoreContext"

const insurerStore = InsurerStore.create()
const insurerScanStore = InsurerDocumentsStore.create()

const List = (props) => {
  const { insurer_id } = props.match.params
  useEffect(() => {
    insurerStore.fetch({ id: insurer_id })
    insurerScanStore.fetch({ insurer_id })
  }, [])

  const { data: currentUser } = useContext(AuthStoreContext)

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/insurers/${insurerStore.id}`}>{insurerStore.name}</BreadcrumbItem>
        <BreadcrumbItem active>Документы</BreadcrumbItem>
      </Breadcrumbs>
      <Tabs {...{ id: insurer_id }} />
      <br />
      <Card>
        <Button
          url={`/insurers/${insurer_id}/insurer_documents/new`}
          disabled={![7, 8, 9].includes(currentUser.subdivision.id)}
        >
          Создать
        </Button>
      </Card>
      <Table store={insurerScanStore} insurer_id={insurer_id} />
      <Paginate store={insurerScanStore} params={{ insurer_id: insurer_id }} />
    </Fragment>
  )
}

export default observer(List)
