import React from "react"
import { Link } from "react-router-dom"

const BreadcrumbItem = ({ active = false, url, children }) => {
  if (active) {
    return (
      <li className="breadcrumb-item active" aria-current="page">
        {children}
      </li>
    )
  }
  return (
    <li className="breadcrumb-item">
      <Link to={url}>{children}</Link>
    </li>
  )
}

export default BreadcrumbItem
