import React from "react"
import OperationScan from "./OperationScan"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  document_kind: {},
  document_kind_other: null,
  kind: { value: 0 },
  comment: null,
  attachments: []
}

const OperationScans = () => (
  <NestedFields name="operation_scans" label="Документы" component={OperationScan} defaultValues={defaultValues} />
)

export default OperationScans
