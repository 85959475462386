import React, { Fragment } from "react"
import compose from "utils/compose"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { withRouter } from "react-router"
import { DateInput, ResetButton, SelectInput, Submit, TextInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"

const Filter = ({ store, values, handleSubmit }) => {
  const { statusesOptions, paymentStatusesOptions, originalStatusesOptions } = store.filtersOptions

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <InsurerSelect name="by_insurer" />
        </div>
        <div className="col-3">
          <ChannelSelect name="by_channel" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_start" label="Дата АВР с" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_finish" label="Дата АВР по" />
        </div>
        <div className="col-3">
          <TextInput name="by_number" label="Номер АВР" />
        </div>
        <div className="col-3">
          <SelectInput name="by_status" label="Статус АВР" options={statusesOptions} isClearable />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_payment_status"
            label="Статус оплаты АВР"
            options={paymentStatusesOptions}
            isClearable
          />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_original_status"
            label="Статус оригинала АВР"
            options={originalStatusesOptions}
            isClearable
          />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      {store.meta.statistics.map((row, index) => (
        <Fragment key={`statistics-${index}`}>
          &nbsp;&nbsp;&nbsp;&nbsp;{row[0]}: {row[1]}
        </Fragment>
      ))}
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
