import { useState } from "react"
import instance from "connection/instance"

const defaultData = { operations: [] }

const prepareData = (data) => {
  const { operations, ...other } = data
  return {
    ...other,
    operation_ids: operations.map(i => i.id)
  }
}

const usePaymentPlan = (data = defaultData) => {
  const [paymentPlan, setPaymentPlan] = useState(data)

  const showPaymentPlan = async (id) => {
    const response = await instance.get(`/pages/payment_plans/${id}`)
    setPaymentPlan(response.data.payment_plan)
    return data
  }
  
  const newPaymentPlan = async (params) => {
    const response = await instance.get("/pages/payment_plans/new", { params })
    setPaymentPlan(response.data.payment_plan)
    return data
  }

  const editPaymentPlan = async (id) => {
    const { data } = await instance.get(`/pages/payment_plans/${id}/edit`)
    setPaymentPlan(data.payment_plan)
    return data
  }

  const createPaymentPlan = async (data) => {
    const response = await instance.post("/pages/payment_plans", { data: prepareData(data) })
    setPaymentPlan(response.data.payment_plan)
    return data
  }

  const updatePaymentPlan = async (data) => {
    const response = await instance.put(`/pages/payment_plans/${data.id}`, { data: prepareData(data) })
    setPaymentPlan(response.data.payment_plan)
    return data
  }

  return { paymentPlan, setPaymentPlan, showPaymentPlan, newPaymentPlan, editPaymentPlan, createPaymentPlan, updatePaymentPlan }
}

export default usePaymentPlan
