import React from "react"
import { Route, Switch } from "react-router-dom"
import List from "./containers/List"
import Show from "./containers/Show"
import CheckPermissions from "components/CheckPermissions"

const InboxDocumentFlows = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("document_flows")}>
    <Switch>
      <Route path="/inbox_document_flows/:id" component={Show} />
      <Route path="/inbox_document_flows" component={List} />
    </Switch>
  </CheckPermissions>
)

export default InboxDocumentFlows
