import React from "react"
import classnames from "classnames"
import capitalize from "utils/capitalize"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn, ErrorMessage } from "formik"
import { observer } from "mobx-react"

const TextInput = ({ name, label, type = "text", capitalizeValue = true, required = false, ...props }) => {
  const id = idGenerator()
  const { errors, values, handleBlur, handleChange } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || props.value || ""
  const className = classnames("form-control", { "is-invalid": error })
  return (
    <div className={classnames("form-group", { required: required })}>
      <label htmlFor={id}>{label}</label>
      <input
        value={capitalizeValue ? capitalize(value.toString()) : value}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="off"
        {...{ className, id, name, type, ...props }}
      />
      {error && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  )
}
export default compose(observer, connect)(TextInput)
