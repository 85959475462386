const prepare = (params = {}) => {
  const {
    curator: { id: curator_id },
    subdivision: { id: subdivision_id },
    creator,
    contract,
    clients_count,
    ...other
  } = params
  return {
    ...other,
    curator_id,
    subdivision_id
  }
}

export { prepare }
