import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import React, { Component, Fragment } from "react"
import ClientStore from "stores/ClientStore/One"
import { BreadcrumbItem, Button, Card } from "components/UI"
import { observer } from "mobx-react"
import TabsBlock from "pages/Clients/components/Tabs"
import formatDate from "utils/formatDate"
import formatDateTime from "utils/formatDateTime"
import { Link } from "react-router-dom"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

class Show extends Component {
  clientStore = ClientStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.clientStore.fetch({ id })
  }

  render() {
    const {
      clientStore: {
        data: {
          id,
          emails,
          f_name,
          kind,
          l_name,
          comment,
          name,
          organization_name,
          ownership_kind,
          other_ownership_kind,
          p_name,
          birthday_on,
          phones,
          position,
          inn,
          address,
          kind_of_action,
          created_at,
          user,
          subdivision,
          strength,
          holding,
          industry,
          legal_support
        }
      }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumbs>
        <TabsBlock store={this.clientStore} />
        {kind === "individual" && (
          <Fragment>
            <br />
            <Card>
              <Button url={`/clients/${id}/edit`}>Редактировать</Button>
            </Card>
            <div className="row">
              <div className="col-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2">Страхователь</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>Фамилия</td>
                      <td style={{ width: "50%" }}>{l_name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Имя</td>
                      <td style={{ width: "50%" }}>{f_name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Отчество</td>
                      <td style={{ width: "50%" }}>{p_name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Дата рождения</td>
                      <td style={{ width: "50%" }}>{formatDate(birthday_on)}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Должность</td>
                      <td style={{ width: "50%" }}>{position}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Адрес регистрации</td>
                      <td style={{ width: "50%" }}>{address}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Комментарий</td>
                      <td style={{ width: "50%" }} dangerouslySetInnerHTML={{ __html: comment }} />
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Телефоны</td>
                      <td style={{ width: "50%" }}>
                        {phones.map(({ value }) => (
                          <Fragment>
                            <a href={`tel:${value}`}>{value}</a>
                            <br />
                          </Fragment>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Emails</td>
                      <td style={{ width: "50%" }}>
                        {emails.map(({ value }) => (
                          <Fragment>
                            <a href={`emailto:${value}`}>{value}</a>
                            <br />
                          </Fragment>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Создал</td>
                      <td style={{ width: "50%" }}>{[user.name, subdivision.short_name].join(", ")}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Создано</td>
                      <td style={{ width: "50%" }}>{formatDateTime(created_at)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Fragment>
        )}
        {kind === "entity" && (
          <Fragment>
            <br />
            <Tabs defaultActiveKey="common">
              <Tab eventKey="common" title="Общие сведения">
                <br />
                <Card>
                  <Button url={`/clients/${id}/edit`}>Редактировать</Button>
                </Card>
                <div className="row">
                  <div className="col-12">
                    {kind === "entity" && (
                      <Fragment>
                        <table className="table">
                          <thead>
                            <tr>
                              <th colSpan="2">Страхователь</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ width: "50%" }}>Название</td>
                              <td style={{ width: "50%" }}>
                                {organization_name} {kind != "individual" && ownership_kind.value == 9 ? other_ownership_kind : ownership_kind.label}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>ИНН</td>
                              <td style={{ width: "50%" }}>{inn}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Холдинг / Группа компаний</td>
                              <td style={{ width: "50%" }}>
                                {" "}
                                <Link to={`/holdings/${holding.id}`}>{holding.name}</Link>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Фактический адрес</td>
                              <td style={{ width: "50%" }}>{address}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Комментарий</td>
                              <td style={{ width: "50%" }} dangerouslySetInnerHTML={{ __html: comment }} />
                            </tr>
                          </tbody>
                        </table>
                        <table className="table">
                          <thead>
                            <tr>
                              <th colSpan="2">Дополнительные сведения</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ width: "50%" }}>Отрасль</td>
                              <td style={{ width: "50%" }}>{industry && industry.name}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Вид деятельности</td>
                              <td style={{ width: "50%" }}>{kind_of_action}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Количество штатных сотрудников</td>
                              <td style={{ width: "50%" }}>{strength.name}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Юридическое обеспечение</td>
                              <td style={{ width: "50%" }}>{legal_support.label}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Создал</td>
                              <td style={{ width: "50%" }}>{[user.name, subdivision.short_name].join(", ")}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Дата создания</td>
                              <td style={{ width: "50%" }}>{formatDateTime(created_at)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Fragment>
                    )}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="bank" title="Банковские реквизиты">
                <br />
                <Card>
                  <Button url={`/clients/${id}/edit`}>Редактировать</Button>
                </Card>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2">Страхователь</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>Полное наименование</td>
                      <td style={{ width: "50%" }}>{this.clientStore.data.full_name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>ФИО генерального директора</td>
                      <td style={{ width: "50%" }}>{this.clientStore.data.director}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>ИНН</td>
                      <td style={{ width: "50%" }}>{this.clientStore.data.inn}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>ОГРН</td>
                      <td style={{ width: "50%" }}>{this.clientStore.data.ogrn}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Юридический адрес</td>
                      <td style={{ width: "50%" }}>{this.clientStore.data.legal_address}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Банк</td>
                      <td style={{ width: "50%" }}>{this.clientStore.data.bank}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Расчетный счет</td>
                      <td style={{ width: "50%" }}>{this.clientStore.data.payment_account}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>БИК</td>
                      <td style={{ width: "50%" }}>{this.clientStore.data.bik}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Корр.счет</td>
                      <td style={{ width: "50%" }}>{this.clientStore.data.correspondent_account}</td>
                    </tr>
                  </tbody>
                </table>
              </Tab>
            </Tabs>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default observer(Show)
