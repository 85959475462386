import { types } from "mobx-state-tree"

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  full_name: types.maybeNull(types.string),
  kind: types.maybeNull(types.string),
  inn: types.maybeNull(types.string),
  kpp: types.maybeNull(types.string),
  director: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  bank: types.maybeNull(types.string),
  payment_account: types.maybeNull(types.string),
  bik: types.maybeNull(types.string),
  correspondent_account: types.maybeNull(types.string)
})

export default Channel
