import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import ClientStore from "stores/ClientStore/List"

const clientStore = ClientStore.create()

const ClientSelect = ({ name = "client", required = false, label = "Страхователь", ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    clientStore.search()
  }, [])

  const getOptions = () => {
    const { clientOptions } = clientStore
    const { id: value, name: label } = getIn(values, name.replace(".id", "")) || { id: null, name: null }
    if (value) {
      return clientOptions.concat([{ label, value }])
    }
    return clientOptions
  }

  const clientOptions = getOptions()

  const loadClientOptions = (value) =>
    clientStore.search({ filter: { by_name: value }, limit: 0 }).then(({ clientOptions }) => clientOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={clientOptions}
        required={required}
        isSearchable
        loadOptions={loadClientOptions}
        {...props}
      />
    </div>
  )
}

export default observer(ClientSelect)
