const prepare = (params = {}) => {
  const {
    customer: { id: customer_id },
    executor: { id: executor_id },
    urgency: { value: urgency },
    kind: { value: kind },
    status: { value: status },
    document_flow_bso,
    created_at,
    ...other
  } = params
  return {
    ...other,
    customer_id,
    executor_id,
    urgency: parseInt(urgency),
    kind: parseInt(kind),
    status: parseInt(status),
    document_flow_bso: {
      id: document_flow_bso.id,
      recipient: parseInt(document_flow_bso.recipient.value),
      subagent_id: document_flow_bso.subagent ? document_flow_bso.subagent.value : null,
      items: document_flow_bso.items.map((i) => {
        const {
          insurer: { id: insurer_id },
          bso_kind: { id: bso_kind_id },
          ...other
        } = i
        return {
          ...other,
          insurer_id,
          bso_kind_id
        }
      })
    }
  }
}

export { prepare }
