import React, { Fragment } from "react"
import { observer } from "mobx-react"

const SpecificationTab = ({ store }) => {
  return (
    <Fragment>
      <table className="table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Получатель БСО</td>
            <td style={{ width: "50%" }}>{store.data.document_flow_bso.recipient.label}</td>
          </tr>
          {store.data.document_flow_bso.subagent && store.data.document_flow_bso.subagent.name && (
            <tr>
              <td style={{ width: "50%" }}>Партнер</td>
              <td style={{ width: "50%" }}>{store.data.document_flow_bso.subagent.name}</td>
            </tr>
          )}
        </tbody>
      </table>
      {store.data.document_flow_bso.items.map((i, index) => (
        <table className="table">
          <thead>
            <tr>
              <th colSpan="2">Вид {++index}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>Страховая компания</td>
              <td style={{ width: "50%" }}>{i.insurer.name}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Вид БСО</td>
              <td style={{ width: "50%" }}>{i.bso_kind.name}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Киличество БСО</td>
              <td style={{ width: "50%" }}>{i.quantity}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Доступный лимит БСО</td>
              <td style={{ width: "50%" }}></td>
            </tr>
          </tbody>
        </table>
      ))}
    </Fragment>
  )
}

export default observer(SpecificationTab)
