import React, { useEffect } from "react"
import { observer } from "mobx-react"
import ClientStore from "stores/ClientStore/One"
import Form from "./components/Form"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "components/UI"
import ContactFormContext from "contexts/ContactFormContext"
import InsuranceKindStore from "stores/InsuranceKindStore/List"

const insuranceKindStore = InsuranceKindStore.create()

const ClientModal = ({ isOpen, closeModal, onClientAdded }) => {
  const clientStore = ClientStore.create()
  
  useEffect(() => {
    clientStore.fetchMeta()
    insuranceKindStore.fetch()
  }, [isOpen])

  const handleSubmit = (values, { props: { store }, setErrors }) =>
    store
      .create(values)
      .then(() => {
        onClientAdded({ label: store.name, value: store.data.id })
        closeModal()
      })
      .catch(({ response }) => setErrors(response.data.errors))

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Создать страхователя</ModalHeader>
      <ModalBody>
        <ContactFormContext.Provider value={{ insuranceKindStore }}>
          <Form store={clientStore} handleSubmit={handleSubmit} />
        </ContactFormContext.Provider>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(ClientModal)
