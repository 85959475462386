import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const Document = types.model("Document", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const Bordero = types.model("Bordero", {
  id: types.maybeNull(types.integer),
  contract_id: types.maybeNull(types.integer),
  start_on: types.maybeNull(customDate),
  finish_on: types.maybeNull(customDate),
  date_on: types.maybeNull(customDate),
  number: types.maybeNull(types.string),
  ss_cost: types.maybeNull(types.string),
  sp_cost: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  currency: types.maybeNull(newCustomEnum),
  documents: types.maybeNull(types.array(Document))
})

export default Bordero
