import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import DocumentFlowStore from "stores/DocumentFlowStore/List"
import Breadcrumbs from "pages/SentDocumentFlows/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"
import Filter from "./components/Filter"

const documentFlowStore = DocumentFlowStore.create()

const List = (props) => {
  useEffect(() => {
    const { state } = props.history.location
    if (state && state.referer && /\/document_flows\/\d+\/edit/i.test(state.referer)) {
      historyPushFromStore(props.history, window.location.pathname)
    }

    documentFlowStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/sent_document_flows/new">Создать</Button>
      </Card>
      <Card>
        <Filter store={documentFlowStore} />
      </Card>
      <Table store={documentFlowStore} />
      <Paginate store={documentFlowStore} />
    </Fragment>
  )
}

export default observer(List)
