import React from "react"
import { observer } from "mobx-react"
import { Breadcrumb, BreadcrumbItem } from "components/UI"

const Breadcrumbs = ({ client: { id, name }, children }) => (
  <Breadcrumb>
    <BreadcrumbItem active={!children} url="/clients">
      Страхователи
    </BreadcrumbItem>
    <BreadcrumbItem url={`/clients/${id}`}>{name}</BreadcrumbItem>
    <BreadcrumbItem url={`/clients/${id}/contacts`}>Контактные лица</BreadcrumbItem>
    {children}
  </Breadcrumb>
)

export default observer(Breadcrumbs)
