import React from "react"
import { Breadcrumb, BreadcrumbItem } from "components/UI"

const Breadcrumbs = ({ children }) => (
  <Breadcrumb>
    <BreadcrumbItem active={!children} url="/insurers">Страховые компании</BreadcrumbItem>
    {children}
  </Breadcrumb>
)

export default Breadcrumbs