import React, { useState } from "react"
import { observer } from "mobx-react"
import { getIn, useFormikContext } from "formik"
import { Link } from "react-router-dom"
import { Button } from "components/UI"
import Modal from "./Modal"
import classNames from "classnames"

const RelationAgencyContract = ({ name, required = false, disabled = false, insurer_id, channel_id }) => {
  const { values, errors, setFieldValue, ...p } = useFormikContext()

  const parent = getIn(values, name)
  const error = getIn(errors, "parent_id")

  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const onDestroy = () => {
    setFieldValue(name, { id: null, number: null })
  }

  return (
    <div className={classNames("form-group", { required: required })}>
      <label>
        Связанный договор:&nbsp;
        {parent?.id ? (
          <Link target="_blank" to={`/agency_contracts/${parent.id}`}>
            {parent.number}
          </Link>
        ) : (
          "не выбран"
        )}
      </label>
      <fieldset>
        <Button onClick={openModal} disabled={disabled}>
          Выбрать
        </Button>
        &nbsp;
        <Button onClick={onDestroy} disabled={disabled} variant="danger">
          Удалить
        </Button>
      </fieldset>
      {error && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {error[0]}
        </div>
      )}
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        setFieldValue={setFieldValue}
        name={name}
        insurer_id={insurer_id}
        channel_id={channel_id}
      />
    </div>
  )
}

export default observer(RelationAgencyContract)
