import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Courier } from "./Courier"
import { prepare } from "./utils"
import newCustomEnum from "types/newCustomEnum"
import newToOptions from "utils/newToOptions"

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, []),
  delivery_services: types.array(newCustomEnum, []),
  urgencies: types.array(newCustomEnum, []),
  delivery_kinds: types.array(newCustomEnum, []),
  delivery_complexities: types.array(newCustomEnum, []),
  recipient_cargo_kinds: types.array(newCustomEnum, []),
  from_time_kinds: types.array(newCustomEnum, []),
  to_time_kinds: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    statuses: [],
    delivery_services: [],
    urgencies: [],
    delivery_kinds: [],
    delivery_complexities: [],
    recipient_cargo_kinds: [],
    from_time_kinds: [],
    to_time_kinds: []
  }
}

const CourierStore = types
  .model("CourierStore", {
    data: types.optional(Courier, {
      status: { value: 0 },
      delivery_service: { value: null },
      from_organization: {},
      from_date_on: null,
      user: { id: null },
      status_changed_by: { id: null },
      urgency: { value: 0 },
      delivery_kind: { value: 0 },
      delivery_complexity: { value: 0 },
      from_time_kind: { value: 1 },
      recipients: [],
      contracts: [],
      provider: { id: null },
    }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get filtersOptions() {
      const {
        statuses,
        delivery_services,
        urgencies,
        delivery_kinds,
        delivery_complexities,
        recipient_cargo_kinds,
        from_time_kinds,
        to_time_kinds
      } = self.meta.filters_options
      return {
        statuses: newToOptions(statuses),
        delivery_services: newToOptions(delivery_services),
        urgencies: newToOptions(urgencies),
        delivery_kinds: newToOptions(delivery_kinds),
        delivery_complexities: newToOptions(delivery_complexities),
        recipient_cargo_kinds: newToOptions(recipient_cargo_kinds),
        from_time_kinds: newToOptions(from_time_kinds),
        to_time_kinds: newToOptions(to_time_kinds)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/couriers/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/couriers/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/couriers`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/couriers/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default CourierStore
