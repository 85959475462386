import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import PrivateRoute from "components/PrivateRoute"

const Operations = () => (
  <Switch>
    <PrivateRoute
      path="/operations/:id/edit"
      component={Edit}
      condition={(currentUser) => currentUser.privileges.includes("operations")}
    />
    <PrivateRoute
      path="/operations/new"
      component={Add}
      condition={(currentUser) => currentUser.privileges.includes("operations")}
    />
    <Route path="/operations/:id" component={Show} />
    <PrivateRoute
      path="/operations"
      component={List}
      condition={(currentUser) => currentUser.privileges.includes("operations")}
    />
  </Switch>
)

export default Operations
