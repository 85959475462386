import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import ContractStore from "stores/ContractStore/One"
import Tabsss from "pages/Contracts/components/Tabs"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import compareAsc from "date-fns/compareAsc"
import SubTabsss from "pages/Contracts/components/SubTabs"
import classNames from "classnames"
import { Link } from "react-router-dom"

const contractStore = ContractStore.create()

const Finances = (props) => {
  const { id: contract_id } = props.match.params

  useEffect(() => {
    contractStore.fetch({ id: contract_id })
  }, [contract_id])

  const {
    id,
    kvs,
    contract_installments,
    contract_returnments,
    sp_accrual_cost,
    sp_accrual_control_on,
    channel,
    subagent
  } = contractStore

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{id}</BreadcrumbItem>
        <BreadcrumbItem active>Финансовый блок</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?additional=true`}>
            {contractStore.insurance_kind.id === 14 ? "Аддендум" : "Доп. соглашение"}
          </Button>
        )}
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?prolongation=true`}>Продление</Button>
        )}
        &nbsp;
        <Button url={`/contracts/${id}/edit?clone=true`}>Копировать</Button>
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <SubTabsss store={contractStore} />
      <br />
      <h4 className="text-center">Все расчеты указаны в рублях</h4>
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>Начисленная СП, итого</td>
            <td>
              {formatMoney(
                parseMoney(sp_accrual_cost) - contract_returnments.reduce((a, i) => a + parseMoney(i.payment_cost), 0)
              )}
            </td>
          </tr>
          <tr>
            <td>КВ, %</td>
            <td>
              {kvs[0].rate} / {channel.name}
            </td>
          </tr>
          {contractStore.data.fix_kv_channel.id && (
            <tr>
              <td>КВ фикс., руб.</td>
              <td>
                {formatMoney(
                  contractStore.data.contract_installments.reduce((a, b) => a + parseMoney(b.fix_kv_cost), 0)
                )}{" "}
                / {contractStore.data.fix_kv_channel.name}
              </td>
            </tr>
          )}
          {kvs.length > 1 &&
            kvs.slice(1).map((kv, index) => (
              <tr>
                <td>{`КВ доп. ${index + 1}, %`}</td>
                <td>
                  {kv.rate} / {kv.channel.name}
                </td>
              </tr>
            ))}
          <tr>
            <td>Всего по договору КВ по начисленной СП</td>
            <td>
              {formatMoney(
                (parseMoney(sp_accrual_cost) -
                  contract_returnments.reduce((a, i) => a + parseMoney(i.payment_cost), 0)) *
                  kvs.reduce((a, i) => a + parseMoney(i.rate), 0) *
                  0.01 +
                  contract_installments.reduce((a, b) => a + parseMoney(b.fix_kv_cost), 0)
              )}
            </td>
          </tr>
          <tr>
            <td>Всего по договору КВ по оплаченной СП</td>
            <td>
              {formatMoney(
                contract_installments.reduce((a, i) => a + parseMoney(i.payment_cost), 0) *
                  kvs.reduce((a, i) => a + parseMoney(i.rate), 0) *
                  0.01 -
                  contract_returnments.reduce((a, i) => a + parseMoney(i.payment_cost), 0) *
                    kvs.reduce((a, i) => a + parseMoney(i.rate), 0) *
                    0.01 +
                  contract_installments.reduce((a, b) => (a + (b.payment_on ? parseMoney(b.fix_kv_cost) : 0)), 0)
              )}
            </td>
          </tr>
          {contract_returnments.length > 0 && (
            <tr>
              <td>Возврат КВ</td>
              <td>
                {formatMoney(
                  contract_returnments.reduce((a, i) => a + parseMoney(i.payment_cost), 0) *
                    kvs.reduce((a, b) => a + parseMoney(b.rate), 0) *
                    0.01
                )}
              </td>
            </tr>
          )}
          <tr>
            <td>Наименование субагента</td>
            <td>
              {subagent && subagent.name ? (
                <Link to={`/subagents/${subagent.id}`}>{subagent.name}</Link>
              ) : (
                "Без субагента"
              )}
            </td>
          </tr>
          <tr>
            <td>Всего сумма к выплате субагенту</td>
            <td>
              {subagent &&
                formatMoney(
                  contract_installments.reduce((a, i) => a + parseMoney(i.subagent_cost), 0) -
                    contract_returnments.reduce((a, i) => a + parseMoney(i.subagent_cost), 0)
                )}
            </td>
          </tr>
        </tbody>
      </table>
      <br />

      <table className="table scroll">
        <thead>
          <tr>
            <th>Дата очередного платежа</th>
            <th>Сумма очередного платежа</th>
            <th>КВ по нач. СП</th>
            <th>Дата оплаты СП</th>
            <th>Форма</th>
            <th>Сумма опл. СП</th>
            <th>КВ по опл. СП</th>
            {contractStore.data.fix_kv_channel.id && <th>Фикс. КВ</th>}
            {kvs.length > 1 && kvs.slice(1).map((kv, index) => <th>{`КВ доп. ${index + 1}`}</th>)}
            <th>Сумма к выплате СА</th>
            <th>План. дата выплаты СА</th>
            <th>Факт. дата выплаты СА</th>
          </tr>
        </thead>
        <tbody>
          {[contract_installments, contract_returnments]
            .flat()
            .sort((a, b) => compareAsc(a.sp_payment_on, b.sp_payment_on))
            .reverse()
            .map((contract_installment, index) => (
              <tr className={classNames({ "table-danger": contract_installment.hasOwnProperty("returnment") })}>
                <td>{formatDate(contract_installment.sp_payment_on)}</td>
                <td>{contract_installment.sp_payment_cost}</td>
                <td>
                  {formatMoney(
                    parseMoney(contract_installment.sp_payment_cost) *
                      kvs.reduce((a, b) => a + parseMoney(b.rate), 0) *
                      0.01 +
                      parseMoney(contract_installment.fix_kv_cost)
                  )}
                </td>
                <td>{formatDate(contract_installment.payment_on)}</td>
                <td>{contract_installment.sp_sk_payment_form.label == "Касса" ? "К" : "Р/С"}</td>
                <td>{contract_installment.payment_cost}</td>
                <td>{formatMoney(parseMoney(contract_installment.payment_cost) * parseMoney(kvs[0].rate) * 0.01)}</td>
                {contractStore.data.fix_kv_channel.id && <td>{formatMoney(contract_installment.payment_on ? contract_installment.fix_kv_cost : 0)}</td>}
                {kvs.length > 1 &&
                  kvs
                    .slice(1)
                    .map((kv) => (
                      <td>{formatMoney(parseMoney(contract_installment.payment_cost) * parseMoney(kv.rate) * 0.01)}</td>
                    ))}
                <td>{contract_installment.subagent_cost}</td>
                <td>{formatDate(contract_installment.subagent_cost_planned_on)}</td>
                <td>{formatDate(contract_installment.subagent_cost_fact_on)}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>
              {formatMoney(
                contract_installments.reduce((a, i) => a + parseMoney(i.payment_cost), 0) -
                  contract_returnments.reduce((a, i) => a + parseMoney(i.payment_cost), 0)
              )}
            </th>
            <th>
              {formatMoney(
                contract_installments.reduce(
                  (a, i) => a + parseMoney(i.payment_cost) * parseMoney(kvs[0].rate) * 0.01,
                  0
                ) -
                  contract_returnments.reduce(
                    (a, i) => a + parseMoney(i.payment_cost) * parseMoney(kvs[0].rate) * 0.01,
                    0
                  )
              )}
            </th>
            {contractStore.data.fix_kv_channel.id && (
              <th>
                {formatMoney(
                  contract_installments.reduce((a, b) => a + (b.payment_on ? parseMoney(b.fix_kv_cost) : 0), 0) -
                    contract_returnments.reduce((a, b) => a + parseMoney(b.fix_kv_cost), 0)
                )}
              </th>
            )}
            {kvs.length > 1 &&
              kvs
                .slice(1)
                .map((kv) => (
                  <th>
                    {formatMoney(
                      contract_installments.reduce(
                        (a, i) => a + parseMoney(i.payment_cost) * parseMoney(kv.rate) * 0.01,
                        0
                      ) -
                        contract_returnments.reduce(
                          (a, i) => a + parseMoney(i.payment_cost) * parseMoney(kv.rate) * 0.01,
                          0
                        )
                    )}
                  </th>
                ))}
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </Fragment>
  )
}

export default observer(Finances)
