import React from "react"
import { getIn, ErrorMessage } from "formik"
import { Button, Card, CardTitle } from "components/UI"
import Email from "./Email"

const Emails = ({ name, push, form, ...h }) => {
  const onAddClick = () => push({ value: "" })
  const error = getIn(form.errors, name, false)
  return (
    <Card>
      <CardTitle>Emails</CardTitle>
      {getIn(form.values, name, false).map((email, index) => (
        <Email {...{ key: `${index}`, name, index, email, h }} />
      ))}
      <Button variant="success" onClick={onAddClick}>
        Добавить email
      </Button>
      {error && Array.isArray(error) && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </Card>
  )
}

export default Emails
