import React from "react"
import { Route, Switch } from "react-router-dom"
import List from "./containers/List"

const Documents = () => (
  <Switch>
    <Route path="/providers/:provider_id/documents" component={List} />
  </Switch>
)

export default Documents
