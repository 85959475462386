import React from "react"
import { getIn, ErrorMessage } from "formik"
import { Button, Card, CardTitle } from "components/UI"
import InsuranceKind from "./InsuranceKind"

const InsuranceKinds = ({ name, push, form, ...h }) => {
  const onAddClick = () => push({ value: "" })
  const error = getIn(form.errors, name, false)
  return (
    <Card>
      <CardTitle>Виды страхования</CardTitle>
      {getIn(form.values, name, false).map((insurance_kind, index) => (
        <InsuranceKind {...{ key: `${index}`, name, index, insurance_kind, form, h }} />
      ))}
      <Button variant="success" onClick={onAddClick}>
        Добавить вид страхования
      </Button>
      {error && Array.isArray(error) && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </Card>
  )
}

export default InsuranceKinds
