import React, { Fragment } from "react"
import { observer } from "mobx-react"
import formatMoney from "utils/formatMoney"

const Table = ({ store }) => {
  if (store.data.table.length === 0) return null
  return (
    <div style={{ overflowX: "scroll" }}>
      <table className="table table-bordered table-striped table-hover" style={{ width: "3300px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col" colSpan="2"></th>
            <th scope="col">Янв</th>
            <th scope="col">Фев</th>
            <th scope="col">Март</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              1КВ
            </th>
            <th scope="col">Апр</th>
            <th scope="col">Май</th>
            <th scope="col">Июнь</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              2КВ
            </th>
            <th scope="col">Июль</th>
            <th scope="col">Авг</th>
            <th scope="col">Сент</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              3КВ
            </th>
            <th scope="col">Окт</th>
            <th scope="col">Ноя</th>
            <th scope="col">Дек</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              4КВ
            </th>
            <th scope="col">Год</th>
          </tr>
        </thead>
        <tbody>
          {store.data.table.map((row, index) => (
            <Fragment>
              <tr style={index === store.data.table.length - 1 ? { background: "#e5efdc" } : {}}>
                <td rowSpan="3">{row.subdivision.short_name}</td>
                <td>План</td>
                {row.plan.map((i, index) => (
                  <td style={(index + 1) % 4 === 0 ? { background: "#fef2d0" } : {}}>{formatMoney(i)}</td>
                ))}
                <td rowSpan="3">{row.subdivision.short_name}</td>
              </tr>
              <tr>
                <td>Факт</td>
                {row.fact.map((i, index) => (
                  <td style={(index + 1) % 4 === 0 ? { background: "#fef2d0" } : {}}>{formatMoney(i)}</td>
                ))}
              </tr>
              <tr style={index === store.data.table.length - 1 ? { background: "#e5efdc" } : {}}>
                <td>% исп.</td>
                {row.persent.map((i, index) => (
                  <td style={(index + 1) % 4 === 0 ? { background: "#fef2d0" } : {}}>{i}</td>
                ))}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default observer(Table)
