import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { withRouter } from "react-router-dom"

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ID договора</th>
        <th scope="col">Субагент</th>
        <th scope="col">Страховая компания</th>
        <th scope="col">Страхователь</th>
        <th scope="col">Вид страхования</th>
        <th scope="col">№ договора</th>
        <th scope="col">Сумма к выплате СА</th>
        <th scope="col">Факт. дата выплаты СА</th>
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`contract-installment-${index}`}>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.contract.id}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.contract.subagent.name}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.contract.insurer.name}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.contract.client.name}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.contract.insurance_kind.name}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.contract.number}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.subagent_cost}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{formatDate(row.subagent_cost_fact_on)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
