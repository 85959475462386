import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { RadioButtonGroup, RadioInput, TextArea, SelectInput } from "components/UI"
import UserSelect from "../../../Contracts/components/Form/InfoTab/CommonTab/UserSelect"

const CommonTab = ({ store, values, ...props }) => {
  const { urgencies, kinds } = store.filtersOptions
  return (
    <Fragment>
      <br />
      <SelectInput name="kind.value" label="Тип" options={kinds} />
      <UserSelect name="customer" label="Отправитель" />
      <UserSelect name="executor" label="Получатель" />
      <RadioButtonGroup label="Срочность" name="urgency.value">
        {urgencies.map(({ label, value }) => (
          <RadioInput key={`urgency_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <TextArea name="comment" />
    </Fragment>
  )
}

export default observer(CommonTab)
