import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Client } from "./Client"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"
import toOptions from "utils/newToOptions"

const Filter = types.model("Filter", {
  by_kind: types.maybeNull(types.integer),
  by_legal_support: types.maybeNull(types.integer),
  by_strength: types.maybeNull(customEnum),
  by_industry: types.maybeNull(customEnum),
  by_name: types.maybeNull(types.string),
  by_tag: types.maybeNull(types.integer),
  order_by_name: types.maybeNull(types.string)
})

const FilterOptions = types.model("FilterOptions", {
  kinds: types.array(newCustomEnum, []),
  legal_supports: types.array(newCustomEnum, []),
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const ClientStore = types
  .model("Client", {
    data: types.array(Client, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get clientOptions() {
      return self.data.map(({ id, name }) => {
        return {
          label: name,
          value: id
        }
      }) 
    },

    get filtersOptions() {
      const { kinds, legal_supports } = self.meta.filters_options
      return { kinds: toOptions(kinds), legal_supports: toOptions(legal_supports) }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      return instance
        .get("/clients", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },
    
    search(params = {}) {
      self.setState("pending")
      return instance
        .get("/clients/search", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    export(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { index } = params.filter
        if (index) {
          params.filter = { ...params.filter, index: index.toString() }
        }
      }

      return instance
        .get("/clients/export_xlsx", {
          params,
          responseType: "arraybuffer",
          headers: {
            Accept: "application/vnd.ms-excel"
          }
        })
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/clients/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      applySnapshot(self, data)
      // if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ClientStore
