import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import ContactStore from "stores/ContactStore/One"
import InsuranceKindStore from "stores/InsuranceKindStore/List"
import Breadcrumbs from "pages/Insurers/containers/Contacts/components/Breadcrumbs"
import Form from "pages/Insurers/containers/Contacts/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContactFormContext from "contexts/ContactFormContext"

class Edit extends Component {
  insurerStore = InsurerStore.create()
  contactStore = ContactStore.create()
  insuranceKindStore = InsuranceKindStore.create()

  componentDidMount() {
    const { id, insurer_id } = this.props.match.params
    this.insurerStore.fetch({ id: insurer_id })
    this.contactStore.fetch({ id, insurer_id })
    this.insuranceKindStore.fetch()
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) => {
    const { id: insurer_id } = this.insurerStore.data
    return store
      .update({ ...values, insurer_id })
      .then(() => history.push(`/insurers/${insurer_id}/contacts`))
      .catch(({ response }) => setErrors(response.data.errors))
  }

  render() {
    const {
      insurerStore: { data: insurer },
      contactStore: store,
      handleSubmit,
      insuranceKindStore
    } = this
    return (
      <Fragment>
        <Breadcrumbs {...{ insurer }}>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <ContactFormContext.Provider value={{ insuranceKindStore }}>
          <Form {...{ store, handleSubmit }} />
        </ContactFormContext.Provider>
      </Fragment>
    )
  }
}

export default observer(Edit)
