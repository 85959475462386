import objectToFormData from "object-to-formdata"

const prepare = (params = {}) => {
  const {
    subdivision,
    created_at,
    decisioned_at,
    status_changed_by,
    user: { id: user_id },
    status: { value: status },
    delivery_service: { value: delivery_service },
    delivery_complexity: { value: delivery_complexity },
    delivery_kind: { value: delivery_kind },
    urgency: { value: urgency },
    from_time_kind: { value: from_time_kind },
    recipients,
    contracts,
    provider: { id: provider_id },
    ...other
  } = params
  return {
    ...other,
    user_id,
    status,
    delivery_service,
    delivery_complexity: parseInt(delivery_complexity),
    delivery_kind: parseInt(delivery_kind),
    urgency: parseInt(urgency),
    from_time_kind: parseInt(from_time_kind),
    recipients: recipients.map((recipient) => {
      const {
        recipient_cargos,
        to_time_kind: { value: to_time_kind },
        ...other
      } = recipient

      return {
        ...other,
        recipient_cargos: recipient_cargos.map((recipient_cargo) => {
          const {
            kind: { value: kind },
            ...other
          } = recipient_cargo
          return {
            ...other,
            kind: parseInt(kind)
          }
        }),
        to_time_kind: parseInt(to_time_kind)
      }
    }),
    contract_ids: contracts.filter((i) => !i._destroy).map((i) => i.id),
    provider_id,
  }
}

export { prepare }
