import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import ContractStore from "stores/ContractStore/One"
import Tabsss from "pages/Contracts/components/Tabs"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import SubTabsss from "pages/Contracts/components/SubTabs"
import ContractProceeding from "./components/ContractProceeding"
import ContractBankruptcy from "./components/ContractBankruptcy"

const contractStore = ContractStore.create()

const Specification = (props) => {
  const { id: contract_id } = props.match.params

  useEffect(() => {
    contractStore.fetch({ id: contract_id })
  }, [contract_id])

  const {
    id,
    insurance_kind,
    comment,
    contract_properties,
    contract_auto,
    contract_accidents,
    contract_go_transport,
    contract_osgop,
    contract_go_estate,
    contract_mortgage,
    contract_proceeding,
    contract_bankruptcy
  } = contractStore

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{id}</BreadcrumbItem>
        <BreadcrumbItem active>Спецификация договора</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?additional=true`}>
            {contractStore.insurance_kind.id === 14 ? "Аддендум" : "Доп. соглашение"}
          </Button>
        )}
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?prolongation=true`}>Продление</Button>
        )}
        &nbsp;
        <Button url={`/contracts/${id}/edit?clone=true`}>Копировать</Button>
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <SubTabsss store={contractStore} />
      <br />
      {insurance_kind.id == 7 && (
        <Fragment>
          <table className="table">
            <tbody>
              <tr>
                <th colSpan={3}>Информация о количестве застрахованных</th>
              </tr>
              <tr>
                <td>Количество застрахованных на дату заключения договора, чел.</td>
                <td>{contractStore.data.contract_dms.number_of_insured}</td>
                <td>{formatDate(contractStore.data.conclusion_on)}</td>
              </tr>
              <tr>
                <td>Текущее количество застрахованных, чел.</td>
                <td>{contractStore.data.contract_dms.current_number_of_insured}</td>
                <td>{formatDate(contractStore.data.contract_dms.date_on)}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className="table">
            <tbody>
              <tr>
                <th>Спецпредложения / Бонусы ДМС</th>
              </tr>
              <tr>
                <td dangerouslySetInnerHTML={{ __html: contractStore.data.contract_dms.comment }} />
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
      {insurance_kind.id == 10 && (
        <Fragment>
          {contractStore.data.cargo_kind.id.toString() === "0" && (
            <Fragment>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Договор</td>
                    <td>{contractStore.data.cargo_kind.name}</td>
                  </tr>
                  <tr>
                    <td>Тип договора</td>
                    <td>{contractStore.data.cargo_prefabricated.name}</td>
                  </tr>
                  <tr>
                    <td>Маршрут</td>
                    <td>{contractStore.data.cargo_route}</td>
                  </tr>
                  <tr>
                    <td>Виды транспорта</td>
                    <td>{contractStore.data.transport_kinds.map((i) => i.name).join(", ")}</td>
                  </tr>
                  <tr>
                    <td>Условия страхования</td>
                    <td>{contractStore.data.cargo_condition}</td>
                  </tr>
                  <tr>
                    <td>Период ответственности</td>
                    <td>
                      {formatDate(contractStore.data.cargo_responsibility_from)} -{" "}
                      {formatDate(contractStore.data.cargo_responsibility_to)}
                    </td>
                  </tr>
                  <tr>
                    <td>Комментарий</td>
                    <td dangerouslySetInnerHTML={{ __html: contractStore.data.cargo_comment }} />
                  </tr>
                </tbody>
              </table>
              <table className="table">
                <thead>
                  <tr>
                    <th>Наименование груза</th>
                    <th>Валюта договора</th>
                    <th>Страховая сумма/лимит ответственности</th>
                    <th>Франшиза</th>
                    <th>Тарифная ставка, %</th>
                  </tr>
                </thead>
                <tbody>
                  {contractStore.data.cargos.map((cargo, index) => (
                    <tr>
                      <td>{cargo.name}</td>
                      <td>{cargo.currency.label}</td>
                      <td>{cargo.cost}</td>
                      <td>{cargo.franchise}</td>
                      {index == 0 && (
                        <td className="align-middle" rowSpan="2">
                          {contractStore.data.cargo_tarif}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Fragment>
          )}
          {contractStore.data.cargo_kind.id.toString() === "1" && (
            <Fragment>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Договор</td>
                    <td>{contractStore.data.cargo_kind.name}</td>
                  </tr>
                  <tr>
                    <td>Тип договора</td>
                    <td>{contractStore.data.cargo_prefabricated.name}</td>
                  </tr>
                  <tr>
                    <td>Маршрут</td>
                    <td>{contractStore.data.cargo_route}</td>
                  </tr>
                  <tr>
                    <td>Виды транспорта</td>
                    <td>{contractStore.data.transport_kinds.map((i) => i.name).join(", ")}</td>
                  </tr>
                  <tr>
                    <td>Порядок подачи деклараций</td>
                    <td>{contractStore.data.cargo_procedure_for_submitting_declaration.name}</td>
                  </tr>
                  <tr>
                    <td>Порядок выдачи сертификатов на отгрузки</td>
                    <td>{contractStore.data.cargo_procedure_for_certificate_issue.name}</td>
                  </tr>
                  <tr>
                    <td>Комментарий</td>
                    <td dangerouslySetInnerHTML={{ __html: contractStore.data.cargo_comment }} />
                  </tr>
                </tbody>
              </table>
              <table className="table">
                <thead>
                  <tr>
                    <th>Наименование груза</th>
                    <th>Валюта договора</th>
                    <th>Страховая сумма/лимит ответственности</th>
                    <th>Условия страхования</th>
                    <th>Франшиза</th>
                    <th>Тарифная ставка, %</th>
                  </tr>
                </thead>
                <tbody>
                  {contractStore.data.cargos.map((cargo, index) => (
                    <tr>
                      <td>{cargo.name}</td>
                      <td>{cargo.currency.label}</td>
                      <td>{cargo.cost}</td>
                      <td>{cargo.condition}</td>
                      <td>{cargo.franchise}</td>
                      <td>{cargo.tariff}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Fragment>
          )}
        </Fragment>
      )}
      {insurance_kind.id == 11 && (
        <Fragment>
          <table className="table">
            <thead>
              <tr>
                <th>Дополнительная информация</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td dangerouslySetInnerHTML={{ __html: comment }} />
              </tr>
            </tbody>
          </table>
          <table className="table">
            <thead>
              <tr>
                <th>Подвид страхования</th>
                <th>Объект страхования</th>
                <th>Страховая сумма</th>
                <th>Территория страхования</th>
                <th>Франшиза</th>
                <th>Условия договора</th>
              </tr>
            </thead>
            <tbody>
              {contract_properties.map((contract_property) =>
                contract_property.properties.map((property, index) => (
                  <tr>
                    {index == 0 && (
                      <td rowSpan={contract_property.properties.length} className="align-middle">
                        {contract_property.kind.name}
                      </td>
                    )}
                    <td>{property.object}</td>
                    <td>{property.ss_cost}</td>
                    <td>{contract_property.territory}</td>
                    <td>{contract_property.franchise}</td>
                    <td>{contract_property.terms}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Fragment>
      )}
      {[9, 13, 14, 15].includes(insurance_kind.id) && (
        <table className="table">
          <thead>
            <tr>
              <th>Риски</th>
              <th>Марка/модель</th>
              <th>Гос. №</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{insurance_kind.id == 9 ? contract_auto.risk.label : insurance_kind.name}</td>
              <td>
                {contract_auto.car_brand.label} / {contract_auto.car_model.label}
              </td>
              <td>{contract_auto.number}</td>
            </tr>
          </tbody>
        </table>
      )}
      {insurance_kind.id == 35 && (
        <table className="table">
          <thead>
            <tr>
              <th>Риск</th>
              <th>Страховая сумма</th>
            </tr>
          </thead>
          <tbody>
            {contract_accidents.map((contract_accident) => (
              <tr>
                <td>{contract_accident.risk.value == 4 ? contract_accident.other : contract_accident.risk.label}</td>
                <td>{contract_accident.ss_cost}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {[18, 37].includes(insurance_kind.id) && (
        <table className="table">
          <thead>
            <tr>
              <th>Лимит ответственности</th>
              <th>Сумма, руб.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>По одному случаю</td>
              <td>{contract_go_transport.limit_per_case}</td>
            </tr>
            <tr>
              <td>Общий по договору</td>
              <td>{contract_go_transport.total_limit}</td>
            </tr>
          </tbody>
        </table>
      )}
      {insurance_kind.id == 36 && (
        <table className="table">
          <thead>
            <tr>
              <th>Вид транспорта</th>
              <th>Риск</th>
              <th>Страховая сумма</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="3" className="align-middle">
                {contract_osgop.kind.label}
              </td>
              <td>Причинение вреда жизни пассажира</td>
              <td>{contract_osgop.harm_to_life}</td>
            </tr>
            <tr>
              <td>Причинение вреда здоровью пассажира</td>
              <td>{contract_osgop.harm_to_health}</td>
            </tr>
            <tr>
              <td>Причинение вреда имуществу пассажира</td>
              <td>{contract_osgop.harm_to_property}</td>
            </tr>
          </tbody>
        </table>
      )}
      {[16, 17].includes(insurance_kind.id) && (
        <table className="table">
          <thead>
            <tr>
              <th>{insurance_kind.id == 16 ? "Вид ответственности" : "Объект страхования"}</th>
              <th>Лимит ответственности</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{insurance_kind.id == 16 ? contract_go_estate.kind : contract_go_estate.object.label}</td>
              <td>{contract_go_estate.total_limit}</td>
            </tr>
          </tbody>
        </table>
      )}
      {[54].includes(insurance_kind.id) && (
        <table className="table">
          <thead>
            <tr>
              <th>Риски</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
            {[
              { label: "Несчастный случай", value: "0" },
              { label: "Имущество", value: "1" },
              { label: "Титул", value: "2" }
            ].map((i) => (
              <tr>
                <td style={{ width: "50%" }}>{i.label}</td>
                <td style={{ width: "50%" }}>
                  {contract_mortgage?.risks?.includes(i.value) ? (
                    <i className="fas fa-check" style={{ color: "green" }}></i>
                  ) : (
                    <i class="fas fa-times" style={{ color: "red" }}></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {[43].includes(insurance_kind.id) && (
        <ContractProceeding contractStore={contractStore} contract_proceeding={contract_proceeding} />
      )}
      {[44].includes(insurance_kind.id) && (
        <ContractBankruptcy contractStore={contractStore} contract_bankruptcy={contract_bankruptcy} />
      )}
    </Fragment>
  )
}

export default observer(Specification)
