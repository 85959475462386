import React from "react"
import { observer } from "mobx-react"
import {
  ComposedChart,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts"
import formatMoney from "utils/formatMoney"

const AccumulationTable = ({ store }) => {
  const { plan: plans, fact: facts } = store.data.table
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябь",
    "Ноябрь",
    "Декабрь"
  ]

  let dataMax = 0

  let totalPlan = parseFloat(plans[0])
  let totalFact = parseFloat(facts[0])

  const data = months.map((month, index) => {
    const plan = parseFloat(plans[index])
    const fact = parseFloat(facts[index])

    if (index > 0) {
      totalPlan = totalPlan + plan
      totalFact = totalFact + fact
    }

    // if (fact == 0) {
    //   totalFact = 0
    // }

    const max = Math.max(plan, fact)
    if (dataMax < max) {
      dataMax = max
    }

    const percent = Math.round((totalFact / totalPlan) * 100) 
    return {
      name: month,
      "%": isFinite(percent) ? percent : 0,
      План: parseFloat(totalPlan.toFixed(2)),
      Факт: parseFloat(totalFact.toFixed(2))
    }
  })

  dataMax = Math.round(dataMax + dataMax * 0.1)

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 30,
            bottom: 20,
            left: 30
          }}
        >
          <XAxis dataKey="name" />
          <YAxis domain={["auto", dataMax]} type="number" yAxisId="1" />
          <YAxis domain={["auto", "auto"]} orientation="right" type="number" yAxisId="2" />
          <Tooltip formatter={(value, name, props) => formatMoney(value)} />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Line type="monotone" dataKey="%" stroke="#a5a5a5" yAxisId="2" />
          <Bar dataKey="План" fill="#4571c4" yAxisId="1" />
          <Bar dataKey="Факт" fill="#ed7d32" yAxisId="1" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
export default observer(AccumulationTable)
