import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Specification from "./containers/Specification"
import Insured from "./containers/Insured"
import Additionals from "./containers/Additionals"
import Finances from "./containers/Finances"
import Backoffice from "./containers/Backoffice"
import Borderos from "./containers/Borderos"
import ParcelBorderos from "./containers/ParcelBorderos"
import Shipments from "./containers/Shipments"
import Parcels from "./containers/Parcels"
import ContractDocuments from "./containers/ContractDocuments"
import ContractScans from "./containers/ContractScans"
import Tracking from "./containers/Tracking"
import Operations from "./containers/Operations"
import OperationConsumptions from "./containers/OperationConsumptions"
import Losses from "./containers/Losses"
import CheckPermissions from "components/CheckPermissions"

const Contracts = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("contracts")}>
    <Switch>
      <Route path="/contracts/:id/edit" component={Edit} />
      <Route path="/contracts/:id/specification" component={Specification} />
      <Route path="/contracts/:id/insured" component={Insured} />
      <Route path="/contracts/:id/additionals" component={Additionals} />
      <Route path="/contracts/:id/finances" component={Finances} />
      <Route path="/contracts/:id/backoffice" component={Backoffice} />
      <Route path="/contracts/:id/borderos" component={Borderos} />
      <Route path="/contracts/:id/parcel_borderos" component={ParcelBorderos} />
      <Route path="/contracts/:id/shipments" component={Shipments} />
      <Route path="/contracts/:id/parcels" component={Parcels} />
      <Route path="/contracts/:id/contract_documents" component={ContractDocuments} />
      <Route path="/contracts/:id/contract_scans" component={ContractScans} />
      <Route path="/contracts/:id/tracking" component={Tracking} />
      <Route path="/contracts/:id/operations" component={Operations} />
      <Route path="/contracts/:id/operation_consumptions" component={OperationConsumptions} />
      <Route path="/contracts/:id/losses" component={Losses} />
      <Route path="/contracts/new" component={Add} />
      <Route path="/contracts/:id" component={Show} />
      <Route path="/contracts" component={List} />
    </Switch>
  </CheckPermissions>
)

export default Contracts
