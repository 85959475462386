import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Destination } from "./Destination"

const Filter = types.model("Filter", {
  by_name: types.maybeNull(types.string),
  by_organization: types.maybeNull(types.string),
  by_contact: types.maybeNull(types.string),
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filter: {
    by_name: "",
    by_organization: "",
    by_contact: ""
  },
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const DestinationStore = types
  .model("Destination", {
    data: types.array(Destination, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views(self => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get destinationOptions() {
      return self.data.map(({ id, name }) => {
        return {
          label: name,
          value: id
        }
      })
    }
  }))
  .actions(self => ({
    fetch(params = {}) {
      self.setState("pending")

      return instance
        .get("/destinations", { params })
        .then(response => self.resetStore(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/destinations/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default DestinationStore
