import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { withRouter } from "react-router-dom"
import differenceInDays from "date-fns/differenceInDays"

const status = (days) => {
  switch (true) {
    case days < 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Договор завершился" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Договор действует" />
  }
}

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Страховая компания</th>
        <th scope="col">Страхователь</th>
        <th scope="col">Вид страхования</th>
        <th scope="col">№ договора</th>
        <th scope="col">Срок действия договора</th>
        <th scope="col">Статус</th>
        <th scope="col">Форма</th>
        <th scope="col" style={{width: '150px'}}>СП оплаченное</th>
        <th scope="col" style={{width: '150px'}}>КВ по СП опл.</th>
        <th scope="col">Дата оплаты СП</th>
        {/* <th scope="col">КВ по опл. СП</th> */}
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`contract-installment-${index}`}>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.contract.id}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
            {row.contract.insurer.name}
          </td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
            {row.contract.client.name}
          </td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
            {row.contract.insurance_kind.name}
          </td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.contract.number}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
            {formatDate(row.contract.start_on)} - {formatDate(row.contract.finish_on)}
          </td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
            {status(differenceInDays(row.contract.finish_on, new Date()))}
          </td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
            {row.sp_sk_payment_form.label == "Касса" ? "К" : "Р/С"}
          </td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.payment_cost}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.kv_cost_sum}</td>
          <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>
            {formatDate(row.payment_on)}
          </td>
          {/* <td onClick={() => window.open(`/contracts/${row.contract.id}`, "_blank").focus()}>{row.kv_by_paid_sp}</td> */}
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
