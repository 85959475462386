import React from "react"
import { Tab, Tabs } from "components/UI"

const TabsBlock = ({ id }) => {
  const mapping = {
    [`/subagents/${id}`]: "Карточка субагента",
    [`/subagents/${id}/contacts`]: "Контактные лица",
    [`/subagents/${id}/contracts`]: "Договоры"
  }
  return (
    <Tabs>
      {Object.entries(mapping).map(i => {
        const active = window.location.pathname === i[0]
        return (
          <Tab key={i[0]} active={active} to={i[0]}>
            {i[1]}
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default TabsBlock
