import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import Parcel from "./Parcel"
import newToOptions from "utils/newToOptions"
import newCustomEnum from "types/newCustomEnum"
import { prepare } from "./utils"

const FilterOptions = types.model("FilterOptions", {
  parcel_statuses: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {}
}

const ParcelStore = types
  .model("ParcelStore", {
    data: types.optional(Parcel, {
      status: { value: 0 },
      bordero: {id: null},
      creator: {},
      documents: []
    }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get number() {
      return self.data.number
    },

    get filtersOptions() {
      const { parcel_statuses } = self.meta.filters_options
      return {
        parcelStatusesOptions: newToOptions(parcel_statuses)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, contract_id, ...options } = params
      return instance
        .get(`/contracts/${contract_id}/parcels/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta(params = {}) {
      self.setState("pending")
      const { id, contract_id, ...options } = params
      return instance
        .get(`/contracts/${contract_id}/parcels/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const { contract_id } = params
      const data = prepare(params)
      return instance
        .post(`/contracts/${contract_id}/parcels`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const { id, contract_id } = params
      const data = prepare(params)
      return instance
        .put(`/contracts/${contract_id}/parcels/${id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { ...self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ParcelStore
