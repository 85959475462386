import React from "react"
import { Route, Switch } from "react-router-dom"
import Sales from "./containers/Sales"
import NewSales from "./containers/NewSales"
import Forecast from "./containers/Forecast"
import ContractInstallments from "./containers/ContractInstallments"
import Prolongations from "./containers/Prolongations"
import Payments from "./containers/Payments"
import CancelProlongations from "./containers/CancelProlongations"
import Kvs from "./containers/Kvs"
import Couriers from "./containers/Couriers"
import PaymentPlan from "./containers/PaymentPlan"
import PaymentFact from "./containers/PaymentFact"
import AnalyticsKv from "./containers/AnalyticsKv"
import Cashflow from "./containers/Cashflow"
import Debits from "./containers/Debits"
import Operations from "./containers/Operations"
import PrivateRoute from "components/PrivateRoute"

const Reports = () => (
  <Switch>
    <Route path="/reports/sales" component={Sales} />
    <Route path="/reports/new_sales" component={NewSales} />
    <Route path="/reports/forecast" component={Forecast} />
    <Route path="/reports/contract_installments" component={ContractInstallments} />
    <Route path="/reports/prolongations" component={Prolongations} />
    <Route path="/reports/payments" component={Payments} />
    <Route path="/reports/cancel_prolongations" component={CancelProlongations} />
    <Route path="/reports/payment_plan" component={PaymentPlan} />
    <Route path="/reports/payment_fact" component={PaymentFact} />
    <Route path="/reports/analytics_kv" component={AnalyticsKv} />
    <PrivateRoute
      condition={(currentUser) => [5, 23].includes(currentUser.id)}
      path="/reports/cashflow"
      component={Cashflow}
    />
    <PrivateRoute
      condition={(currentUser) =>
        currentUser.privileges.includes("report_couriers") ||
        currentUser.subdivision.id == 7 ||
        currentUser.subdivision.id == 8 ||
        currentUser.id.toString() == "48"
      }
      path="/reports/сouriers"
      component={Couriers}
    />
    <PrivateRoute
      condition={(currentUser) => currentUser.privileges.includes("reports")}
      path="/reports/kvs"
      component={Kvs}
    />
    <PrivateRoute
      condition={(currentUser) => [5, 23, 48].includes(currentUser.id) || [7, 8].includes(currentUser.subdivision.id)}
      path="/reports/debits"
      component={Debits}
    />
    <Route
      // condition={(currentUser) => [5, 23].includes(currentUser.id) || [7, 8].includes(currentUser.subdivision.id)}
      path="/reports/operations"
      component={Operations}
    />
  </Switch>
)

export default Reports
