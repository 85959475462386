import { useState } from "react"
import instance from "connection/instance"
import afterDestroyFetch from "utils/newAfterDestroyFetch"

const usePaymentPlans = (collection) => {
  const [paymentPlans, setPaymentPlans] = useState(collection)

  const fetchPaymentPlans = async (params) => {
    const response = await instance.get("/pages/payment_plans", { params })
    setPaymentPlans(response.data.payment_plans)
    return response.data
  }

  const destroyPaymentPlan = async (id) => await instance.delete(`/pages/payment_plans/${id}`)
  const destroyPaymentPlanWithFetch = async (params) =>
    afterDestroyFetch(paymentPlans, fetchPaymentPlans, destroyPaymentPlan, params)

  return { paymentPlans, fetchPaymentPlans, destroyPaymentPlan, destroyPaymentPlanWithFetch }
}

export default usePaymentPlans
