import React, { Fragment, useEffect, useState, useContext } from "react"
import Breadcrumbs from "pages/PaymentPlans/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card, CardTitle } from "components/UI"
import usePaymentPlan from "pages/PaymentPlans/hooks/usePaymentPlan"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"
import formatDateTime from "utils/formatDateTime"
import instance from "connection/instance"
import AuthStoreContext from "contexts/AuthStoreContext"
import classnames from "classnames"

const Show = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { paymentPlan, setPaymentPlan, showPaymentPlan } = usePaymentPlan()
  const { data: currentUser } = useContext(AuthStoreContext)

  useEffect(() => {
    async function fetchData() {
      await showPaymentPlan(props.match.params.id)
      setIsLoaded(true)
    }
    fetchData()
  }, [])

  if (!isLoaded) return null

  const onCoordination = async () => {
    const response = await instance.get(`/pages/payment_plans/${paymentPlan.id}/coordination`, {
      headers: { "X-Requested-With": "XMLHttpRequest" }
    })
    setPaymentPlan(response.data.payment_plan)
  }

  const onAccept = async () => {
    const response = await instance.get(`/pages/payment_plans/${paymentPlan.id}/accept`, {
      headers: { "X-Requested-With": "XMLHttpRequest" }
    })
    setPaymentPlan(response.data.payment_plan)
  }

  const onAccountantReject = async () => {
    const response = await instance.get(`/pages/payment_plans/${paymentPlan.id}/accountant_reject`, {
      headers: { "X-Requested-With": "XMLHttpRequest" }
    })
    setPaymentPlan(response.data.payment_plan)
  }

  const onReleased = async () => {
    const response = await instance.get(`/pages/payment_plans/${paymentPlan.id}/released`, {
      headers: { "X-Requested-With": "XMLHttpRequest" }
    })
    setPaymentPlan(response.data.payment_plan)
  }

  const isEditDisabled =
    paymentPlan.status.value === "released" ||
    paymentPlan.status.value === "payment" ||
    (paymentPlan.status.value === "coordination" && currentUser.id !== 5)

  const isCoordinationDisabled =
    paymentPlan.status.value === "released" ||
    paymentPlan.status.value === "payment" ||
    (paymentPlan.status.value === "coordination" && currentUser.id !== 5)

  const isAcceptDisabled =
    paymentPlan.status.value === "released" || paymentPlan.status.value === "payment" || currentUser.id !== 5

  const isAccountantReject =
    paymentPlan.status.value === "released" ||
    (paymentPlan.status.value === "draft" && currentUser.id !== 5) ||
    (paymentPlan.status.value === "coordination" && currentUser.id !== 5)

  const isReleasedDisabled =
    paymentPlan.status.value === "released" ||
    (paymentPlan.status.value === "draft" && currentUser.id !== 5) ||
    (paymentPlan.status.value === "coordination" && currentUser.id !== 5)

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{paymentPlan.comment}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button
          url={`/payment_plans/${paymentPlan.id}/edit`}
          className={classnames({ "disabled-link": isEditDisabled })}
        >
          Редактировать
        </Button>
        &nbsp;
        <Button variant="warning" onClick={onCoordination} disabled={isCoordinationDisabled}>
          Отправить
        </Button>
        &nbsp;
        <Button variant="success" onClick={onAccept} disabled={isAcceptDisabled}>
          Согласовать
        </Button>
        &nbsp;
        <Button variant="danger" onClick={onAccountantReject} disabled={isAccountantReject}>
          Отказать
        </Button>
        &nbsp;
        <Button variant="success" onClick={onReleased} disabled={isReleasedDisabled}>
          Оплачен
        </Button>
      </Card>
      <div className="row">
        <div className="col-12">
          <table className="table">
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Статус</td>
                <td style={{ width: "50%" }}>{paymentPlan.status.label}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Форма расчета</td>
                <td style={{ width: "50%" }}>{paymentPlan.form.label}</td>
              </tr>
              <tr className="table-warning">
                <td style={{ width: "50%" }}>Комментарий</td>
                <td style={{ width: "50%" }}>{paymentPlan.comment}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Кассир</td>
                <td style={{ width: "50%" }}>{paymentPlan.cashier.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата/время создания</td>
                <td style={{ width: "50%" }}>{formatDateTime(paymentPlan.created_at)}</td>
              </tr>
            </tbody>
          </table>
          <Card>
            <CardTitle>Реестр платежей</CardTitle>
            <table className="table table-hover">
              <thead className="thead-light">
                <tr>
                  <th>ID</th>
                  <th>P&L счет</th>
                  <th>Юр. лицо расчетов</th>
                  <th>Контрагент</th>
                  <th style={{ width: "150px" }}>Сумма</th>
                  <th>Дата операции</th>
                  <th>Назначение платежа</th>
                </tr>
              </thead>
              <tbody>
                {paymentPlan.operations.map((i, index) => (
                  <tr key={`operation-${index}`}>
                    <td onClick={() => window.open(`/operations/${i.id}`, "_blank").focus()}>{i.id}</td>
                    <td onClick={() => window.open(`/operations/${i.id}`, "_blank").focus()}>{i.bill.short_name}</td>
                    <td onClick={() => window.open(`/operations/${i.id}`, "_blank").focus()}>{i.channel.name}</td>
                    <td onClick={() => window.open(`/operations/${i.id}`, "_blank").focus()}>{i.counterpart.name}</td>
                    <td onClick={() => window.open(`/operations/${i.id}`, "_blank").focus()}>{i.cost}</td>
                    <td onClick={() => window.open(`/operations/${i.id}`, "_blank").focus()}>{formatDate(i.date_on)}</td>
                    <td onClick={() => window.open(`/operations/${i.id}`, "_blank").focus()}>{i.comment + (i.kind.value.toString() === "2" ? ` / ${i.moving_kind.label}` : "")}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}></td>
                  <td>
                    <strong>{formatMoney(paymentPlan.payment)}</strong>
                  </td>
                  <td colSpan={2}></td>
                </tr>
              </tfoot>
            </table>
          </Card>
        </div>
      </div>
    </Fragment>
  )
}

export default Show
