import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import DestinationStore from "stores/DestinationStore/One"
import Breadcrumbs from "pages/Destinations/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"

class Show extends Component {
  destinationStore = DestinationStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.destinationStore.fetch({ id })
  }

  render() {
    const {
      destinationStore: {
        data: { id, name, organization, contact, phone, address, comment, creator }
      }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumbs>
        <Card>
          <Button url={`/destinations/${id}/edit`}>Редактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Название Адресата</td>
                  <td style={{ width: "50%" }}>{name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Организация</td>
                  <td style={{ width: "50%" }}>{organization.name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Имя адресата (контактное лицо)</td>
                  <td style={{ width: "50%" }}>{contact}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Телефон</td>
                  <td style={{ width: "50%" }}>{phone}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Адрес</td>
                  <td style={{ width: "50%" }}>{address}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Комментарий</td>
                  <td style={{ width: "50%" }}>{comment}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Создал</td>
                  <td style={{ width: "50%" }}>{creator?.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
