import React, { useContext } from "react"
import { observer } from "mobx-react"
import AuthStoreContext from "contexts/AuthStoreContext"
import Ivanvov from "./Ivanvov"
import Gulanskiy from "./Gulanskiy"
import Moskovich from "./Moskovich"
import Directorate from "./Directorate"
import BRP from "./BRP"
import Backoffice from "./Backoffice"
import Legal from "./Legal"

const SideBar = () => {
  const { data: currentUser } = useContext(AuthStoreContext)
  if (currentUser.id == 5 || currentUser.id == 23) {
    return <Ivanvov />
  }
  if (currentUser.id == 3) {
    return <Gulanskiy />
  }
  if (currentUser.id == 10) {
    return <Moskovich />
  }
  if (currentUser.subdivision?.id == 7 || currentUser.id == 48) {
    return <Backoffice />
  }
  if (currentUser.subdivision?.id == 8 || currentUser.id == 26) {
    return <Directorate />
  }
  if (currentUser.subdivision?.id == 9) {
    return <Legal />
  }
  return <BRP />
}

export default observer(SideBar)
