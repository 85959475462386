import React from "react"
import compose from "utils/compose"
import { DateInput, TextInput, ResetButton, Submit, SelectInput } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import UserSelect from "components/Common/UserSelect"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import OrderSubjectSelect from "components/Common/OrderSubjectSelect"

const Filter = ({ store, handleSubmit }) => {
  const { statuses } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <OrderSubjectSelect name="by_order_subject.id" />
        </div>
        <div className="col-3">
          <TextInput name="by_number" label="Номер приказа" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_start" label="Дата составления с" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_finish" label="Дата составления по" />
        </div>
        <div className="col-3">
          <SelectInput name="by_status" label="Статус" options={statuses} />
        </div>
        <div className="col-3">
          <UserSelect name="by_user.id" label="ФИО сотрудника" />
        </div>
        <div className="col-3">
          <SubdivisionSelect name="by_subdivision.id" />
        </div>
        <div className="col-3">
          <TextInput name="by_user_number" label="Таб номер" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  })
)(Filter)
