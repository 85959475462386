import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer)
})

const Kv = types.model("Kv", {
  id: types.maybeNull(types.integer),
  rate: types.maybeNull(types.string),
  channel: types.maybeNull(Channel)
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  kvs: types.maybeNull(types.array(Kv))
})

const ContractInstallment = types.model("ContractInstallment", {
  id: types.maybeNull(types.integer),
  payment_cost: types.maybeNull(types.string),
  payment_on: types.maybeNull(customDate),
  sp_payment_cost: types.maybeNull(types.string),
  sp_payment_on: types.maybeNull(customDate),
  sp_payment_kind: types.maybeNull(newCustomEnum),
  sp_sk_payment_on: types.maybeNull(customDate),
  sp_sk_payment_cost: types.maybeNull(types.string),
  sp_sk_payment_form: types.maybeNull(newCustomEnum),
  sp_sinergy_payment_on: types.maybeNull(customDate),
  sp_sinergy_payment_cost: types.maybeNull(types.string),
  sp_sinergy_payment_form: types.maybeNull(newCustomEnum),
  contract_payment_status: types.maybeNull(newCustomEnum),
  kv_by_paid_sp: types.maybeNull(types.string),
  contract: types.maybeNull(Contract),
  fix_kv_cost: types.maybeNull(types.string),
})
const ContractReturnment = types.model("ContractReturnment", {
  id: types.maybeNull(types.integer),
  payment_cost: types.maybeNull(types.string),
  payment_on: types.maybeNull(customDate),
  sp_payment_cost: types.maybeNull(types.string),
  sp_payment_on: types.maybeNull(customDate),
  sp_payment_kind: types.maybeNull(newCustomEnum),
  sp_sk_payment_on: types.maybeNull(customDate),
  sp_sk_payment_cost: types.maybeNull(types.string),
  sp_sk_payment_form: types.maybeNull(newCustomEnum),
  sp_sinergy_payment_on: types.maybeNull(customDate),
  sp_sinergy_payment_cost: types.maybeNull(types.string),
  sp_sinergy_payment_form: types.maybeNull(newCustomEnum),
  contract_payment_status: types.maybeNull(newCustomEnum),
  kv_by_paid_sp: types.maybeNull(types.string),
  contract: types.maybeNull(Contract)
})

const AvrInstallment = types.model("AvrInstallment", {
  id: types.maybeNull(types.integer),
  control_payment_on: types.maybeNull(customDate),
  payment_on: types.maybeNull(customDate),
  payment_kind: types.maybeNull(newCustomEnum),
  payment_cost: types.maybeNull(types.string)
})

const Avr = types.model("Avr", {
  id: types.maybeNull(types.integer),
  // kind: types.maybeNull(newCustomEnum),
  // status: types.maybeNull(newCustomEnum),
  number: types.maybeNull(types.string),
  date_on: types.maybeNull(customDate),
  // insurer: types.maybeNull(Insurer),
  // start_on: types.maybeNull(customDate),
  // finish_on: types.maybeNull(customDate),
  channel: types.maybeNull(Channel),
  // kv_by_hold: types.maybeNull(newCustomEnum),
  // former: types.maybeNull(newCustomEnum),
  // former_on: types.maybeNull(customDate),
  // transfer_on: types.maybeNull(customDate),
  // payment_on: types.maybeNull(customDate),
  // original_receipt_on: types.maybeNull(customDate),
  actual_payment_on: types.maybeNull(customDate),
  // total_sp_paid: types.maybeNull(types.string),
  // total_commission_by_paid_sp: types.maybeNull(types.string),
  contract_installments: types.array(ContractInstallment),
  contract_returnments: types.array(ContractReturnment),
  // created_at: types.maybeNull(customDate),
  // payment_status: types.maybeNull(types.string),
  // original_status: types.maybeNull(types.string),
  kv_by_paid_sp: types.maybeNull(types.string),
  // user: types.maybeNull(User)
  payment_kind: types.maybeNull(newCustomEnum),
  payment_status: types.maybeNull(newCustomEnum),
  avr_installments: types.array(AvrInstallment),
  kv_costs: types.array(types.frozen()),
})

export default Avr
