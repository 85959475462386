import React from "react"
import compose from "utils/compose"
import { DateInput, Button, Submit, SelectInput } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import NestedFields from "components/UI/NestedFields"
import FilterItem from "./FilterItem"

const Filter = ({ store, values, handleSubmit, setFieldValue }) => {
  const reset = () => {
    setFieldValue("by_period_from", null)
    setFieldValue("by_period_to", null)
    setFieldValue("by_slice_kind", "1")
    setFieldValue("by_subdivision", {})
    setFieldValue("properties", [{}])
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <SelectInput
            name="by_slice_kind"
            label="Срез"
            options={[
              {
                label: "Сутки",
                value: "0"
              },
              {
                label: "Месяц",
                value: "1"
              },
              {
                label: "Год",
                value: "2"
              }
            ]}
          />
        </div>
      </div>
      <FilterItem values={values} />
      <br />
      <br />
      <Submit>Найти</Submit>
      &nbsp;
      <Button variant="danger" onClick={reset} title="Сбросить фильтр">
        <i className="fas fa-sync-alt"></i>
      </Button>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter, properties: [{}] }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
