import React, { Fragment, useEffect, useContext } from "react"
import { observer } from "mobx-react"
import OperationStore from "stores/OperationStore/One"
import Breadcrumbs from "pages/Operations/components/Breadcrumbs"
import Form from "pages/Operations/components/Form"
import { BreadcrumbItem } from "components/UI"
import AuthStoreContext from "contexts/AuthStoreContext"

const Add = () => {
  const operationStore = OperationStore.create()
  const { data: currentUser } = useContext(AuthStoreContext)

  useEffect(() => {
    operationStore.fetchMeta()
  }, [])

  if (currentUser.subdivision.id.toString() === "7" || currentUser.id.toString() == "48") {
    operationStore.applyData({
      status: 200,
      data: {
        data: {
          ...operationStore.data,
          cash_kind: { value: 1 }
        }
      }
    })
  }

  operationStore.applyData({
    status: 200,
    data: {
      data: {
        ...operationStore.data,
        project: {
          id: 1,
          name: "SI-NERGY Project"
        }
      }
    }
  })

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={operationStore} />
    </Fragment>
  )
}

export default observer(Add)
