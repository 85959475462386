import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Nalogs from "./containers/Nalogs"
import Plan from "./containers/Plan"

const BudgetBudgetFots = () => (
  <Switch>
    <Route path="/budgets/:budget_id/budget_fots/nalogs" component={Nalogs} />
    <Route path="/budgets/:budget_id/budget_fots/plan" component={Plan} />
    <Route path="/budgets/:budget_id/budget_fots/:id/edit" component={Edit} />
    <Route path="/budgets/:budget_id/budget_fots/new" component={Add} />
    <Route path="/budgets/:budget_id/budget_fots/:id" component={Show} />
    <Route path="/budgets/:budget_id/budget_fots" component={List} />
  </Switch>
)

export default BudgetBudgetFots