import React, { Fragment, useState, useContext } from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { Button, ResetButton, SelectInput, Submit, TextInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import StrengthSelect from "pages/Clients/components/Form/InfoTab/StrengthSelect"
import IndustrySelect from "pages/Clients/components/Form/InfoTab/IndustrySelect"
import UserSelect from "components/Common/UserSelect"
import TagSelect from "components/Common/TagSelect"
import AuthStoreContext from "contexts/AuthStoreContext"

const Filter = ({ store, handleSubmit }) => {
  const { kinds, legal_supports } = store.filtersOptions
  const [disabled, setDisabled] = useState(false)
  const { data: currentUser } = useContext(AuthStoreContext)

  const onExportButtonHandler = () => {
    setDisabled(true)
    store.export().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "clients.xlsx")
      document.body.appendChild(link)
      link.click()
      setDisabled(false)
    })
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <TextInput name="by_name" label="Страхователь" />
        </div>
        <div className="col-3">
          <SelectInput name="by_kind" label="Тип" options={kinds} />
        </div>
        <div className="col-3">
          <SelectInput name="by_legal_support" label="Юр.обеспечение" options={legal_supports} />
        </div>
        <div className="col-3">
          <StrengthSelect name="by_strength" />
        </div>
        <div className="col-3">
          <IndustrySelect name="by_industry" />
        </div>
        <div className="col-3">
          <UserSelect name="by_user.id" label="Создатель" />
        </div>
        <div className="col-3">
          <TagSelect name="by_tag" label="Теги" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      &nbsp;
      {[5, 23].includes(currentUser.id) && (
        <Button onClick={onExportButtonHandler} disabled={disabled}>
          {disabled && (
            <Fragment>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
            </Fragment>
          )}
          Экспорт в .xlsx
        </Button>
      )}
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
