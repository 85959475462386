import React from "react"
import Document from "./Document"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  attachments: [],
  document_kind: {
    id: 21
  }
}

const Documents = () => (
  <NestedFields name="documents" label="Документы" component={Document} defaultValues={defaultValues} />
)

export default Documents
