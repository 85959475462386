import React from "react"
import { Destroy, Edit, Sort } from "components/UI"
import { Link } from "react-router-dom"
import formatDateTime from "utils/formatDateTime"
import { observer } from "mobx-react"
import sortChangeHandler from "utils/sortChangeHandler"
import afterDestroyFetch from "utils/afterDestroyFetch"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Форма счета</th>
        <th scope="col">Индекс</th>
        <th scope="col">Основной</th>
        <th scope="col">Суб-счет 1-го порядка</th>
        <th scope="col">Суб-счет 2-го порядка</th>
        <th scope="col">Комментарии</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((i, index) => (
        <tr key={`bills-${index}`}>
          <td>{i.id}</td>
          <td>{i.form.label}</td>
          <td>{i.index}</td>
          <td>{i.kind.value.toString() === "0" ? i.first_level_name : i.first_level_bill.first_level_name}</td>
          <td>{["1", "2"].includes(i.kind.value.toString()) ? (i.second_level_name || i.second_level_bill.second_level_name) : "-"}</td>
          <td>{i.kind.value.toString() === "2" ? i.third_level_name : "-"}</td>
          <td>{i.comment}</td>
          <td>
            <Edit url={`/bills/${i.id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, i.id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
