import React, { useEffect } from "react"
import compose from "utils/compose"
import { AsyncSelectInput, DateInput, ResetButton, SelectInput, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import UserStore from "stores/UserStore/List"
import filterSubmitHandler from "utils/filterSubmitHandler"

const userStore = UserStore.create()

const Filter = ({ store, values, handleSubmit }) => {
  useEffect(() => {
    userStore.fetch()
  }, [])

  const { statusesOptions } = store.filtersOptions

  const userOptions = (() => {
    if (values.by_user) {
      const { id, name } = values.by_user
      const options = userStore.userOptions
      if (id) {
        options.concat([{ label: name, value: id }])
      }
      return options
    }
    return userStore.userOptions
  })()

  const loadUserOptions = (value) =>
    userStore.fetch({ filter: { by_name: value }, limit: 0 }).then(({ userOptions }) => userOptions)

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <AsyncSelectInput
            name="by_user.id"
            label="Консультант"
            options={userOptions}
            isSearchable
            loadOptions={loadUserOptions}
          />
        </div>
        <div className="col-3">
          <SelectInput name="by_status" label="Статус" options={statusesOptions} />
        </div>
        <div className="col-3">
          <DateInput name="by_created_at" label="Дата создания" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
