import React from "react"
import { Link } from "react-router-dom"
import { Destroy, Edit } from "components/UI"
import { bsoHuman } from "stores/InsuranceProductStore/utils"
import { observer } from "mobx-react"
import afterDestroyFetch from "utils/afterDestroyFetch"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Название страхового продукта</th>
        <th scope="col">БСО</th>
        <th scope="col">Риски (Ставка КВ, %)</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map(({ id, insurer_id, name, risks, bso }, index) => (
        <tr key={`insurers-${index}`}>
          <td>
            <Link to={`/insurers/${insurer_id}/insurance_products/${id}`}>{name}</Link>
          </td>
          <td>{bsoHuman(bso)}</td>
          <td>{risks.map(({ name, rate }) => `${name} (${rate}%)`).join(", ")}</td>
          <td>
            <Edit url={`/insurers/${insurer_id}/insurance_products/${id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
