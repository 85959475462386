import React, { useContext, useState } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import { observer } from "mobx-react"
import { Button, Card, Destroy } from "components/UI"
import ParcelsModal from "./ParcelsModal"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import { useFormikContext, getIn } from "formik"

const Parcels = ({ store, values, setParcels, contract_id }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  const { start_on: by_payment_on_start, finish_on: by_payment_on_finish } = values
  const isDisabledAdd =
    store.data.status.value.toString() === "1" &&
    store.data.contract_installment.payment_on &&
    store.data.contract_installment.payment_cost &&
    store.data.contract_installment.payment_cost !== "0.00"
  // !insurer.id ||
  // !channel.id ||
  // values.avr_installments.filter((i) => i.payment_on && parseMoney(i.payment_cost) > 0).length > 0 ||
  // (values.payment_status.value.toString() === "1" && ![5, 23].includes(currentUser.id))

  const isDisabledDestroy =
    store.data.status.value.toString() === "1" &&
    store.data.contract_installment.payment_on &&
    store.data.contract_installment.payment_cost &&
    store.data.contract_installment.payment_cost !== "0.00"
  // values.avr_installments.filter((i) => i.payment_on && parseMoney(i.payment_cost) > 0).length > 0 ||
  // (values.payment_status.value.toString() === "1" && ![5, 23].includes(currentUser.id))

  const [isOpen, setIsOpen] = useState(false)

  const onDestroyClick = (id) => setParcels(values.parcels.map((i) => (i.id === id ? { ...i, _destroy: true } : i)))

  const parcels = JSON.parse(JSON.stringify(values.parcels.filter((i) => !i._destroy)))

  const toggleChange = (parcel) => {
    const index = parcels.findIndex((i) => i.id === parcel.id)
    if (index > -1) {
      setParcels(parcels.filter((i) => i.id !== parcel.id))
    } else {
      setParcels(parcels.concat(parcel))
    }
  }

  const { errors } = useFormikContext()
  const error = getIn(errors, "parcels", false)

  return (
    <Card>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col">№ п/п</th>
            <th scope="col">Дата отправки</th>
            <th scope="col">№ накладной / заказа</th>
            <th scope="col">Адрес отправителя</th>
            <th scope="col">Адрес получателя</th>
            <th scope="col">Страховая сумма</th>
            <th scope="col">Страховая премия</th>
            <th scope="col">Комментарии</th>
            <th scope="col" className="actions one" />
          </tr>
        </thead>
        <tbody>
          {parcels.map((parcel, index) => (
            <tr key={`parcel-${index}`}>
              <td>{parcel.id}</td>
              <td>{formatDate(parcel.departure_on)}</td>
              <td>{parcel.number}</td>
              <td>{parcel.sender_address}</td>
              <td>{parcel.recipient_address}</td>
              <td>{parcel.cost}</td>
              <td>{parcel.sp}</td>
              <td>{parcel.comment}</td>
              <td>
                <Destroy action={() => onDestroyClick(parcel.id)} disabled={isDisabledDestroy} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colspan="5"></th>
            <th>{formatMoney(parcels.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0))}</th>
            <th colspan="3">{formatMoney(parcels.map((i) => parseMoney(i.sp)).reduce((a, b) => a + b, 0))}</th>
          </tr>
        </tfoot>
      </table>
      <Button onClick={() => setIsOpen(true)} disabled={isDisabledAdd}>
        Добавить
      </Button>
      {error && <div className="invalid-feedback">{error[0]}</div>}
      <ParcelsModal
        {...{
          isOpen,
          setIsOpen,
          setParcels,
          parcels,
          toggleChange,
          by_payment_on_start,
          by_payment_on_finish,
          contract_id
        }}
      />
    </Card>
  )
}
export default observer(Parcels)
