const prepare = (params = {}) => {
  const {
    document_kind: { id: document_kind_id },
    creator,
    ...other
  } = params
  return { ...other, document_kind_id }
}

export { prepare }
