import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BillStore from "stores/BillStore/One"
import Breadcrumbs from "pages/Bills/components/Breadcrumbs"
import Form from "pages/Bills/components/Form"
import { BreadcrumbItem } from "components/UI"

const billStore = BillStore.create()

const Edit = (props) => {
  useEffect(() => {
    const { id } = props.match.params
    billStore.fetch({ id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/bills/${billStore.id}`}>{billStore.data.short_name}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={billStore} />
    </Fragment>
  )
}

export default observer(Edit)
