import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { Link } from "react-router-dom"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import ContractStore from "stores/ContractStore/One"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import Tabsss from "pages/Contracts/components/Tabs"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import Badge from "react-bootstrap/Badge"
import uniqBy from "lodash.uniqby"
import { withRouter } from "react-router-dom"

const contractStore = ContractStore.create()

const getBadgeVariant = (value) => {
  return ["primary", "secondary", "warning", "warning", "info", "success"][value]
}

const Backoffice = ({ history, ...props }) => {
  const { id: contract_id } = props.match.params

  useEffect(() => {
    contractStore.fetch({ id: contract_id })
  }, [contract_id])

  const { id, bsos, avrs, couriers } = contractStore

  const status = (avr) => {
    const kvSumByAvr = avr.contract_installments
      .map(
        (i) =>
          parseMoney(i.payment_cost) *
          parseMoney(i.contract.kvs.find((i) => i.channel.id === avr.channel.id)?.rate) *
          0.01
      )
      .reduce((a, b) => a + b, 0)

    const paidKv =
      avr.payment_kind.value.toString() === "0"
        ? avr.avr_installments[0]?.payment_on
          ? kvSumByAvr
          : 0
        : avr.avr_installments
            // .filter((i) => i.payment_on)
            .map((i) => parseMoney(i.payment_cost))
            .reduce((a, b) => a + b, 0)

    return (
      kvSumByAvr - paidKv <= 0 &&
      (avr.payment_kind.value.toString() === "0"
        ? formatDate(avr.avr_installments[0]?.payment_on)
        : formatDate(avr.avr_installments[avr.avr_installments.length - 1]?.payment_on))
    )
  }

  const paymentStatus = (avr) => {
    switch (avr.payment_status.value) {
      case 0: {
        return <i className="fa fa-circle" style={{ color: "red" }} title="Не оплачен" />
      }
      case 1: {
        return <i className="fa fa-circle" style={{ color: "green" }} title="Оплачен" />
      }
      default: {
        return <i className="fa fa-circle" style={{ color: "yellow" }} title="Оплачен частично" />
      }
    }
  }

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{id}</BreadcrumbItem>
        <BreadcrumbItem active>Бэк-офис</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?additional=true`}>
            {contractStore.insurance_kind.id === 14 ? "Аддендум" : "Доп. соглашение"}
          </Button>
        )}
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?prolongation=true`}>Продление</Button>
        )}
        &nbsp;
        <Button url={`/contracts/${id}/edit?clone=true`}>Копировать</Button>
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <Tabs defaultActiveKey="avr">
        <Tab eventKey="avr" title="АВР">
          <br />
          <table className="table">
            <thead>
              <tr>
                <th>КВ по оплаченной СП</th>
                <th>Дата АВР</th>
                <th>№ АВР</th>
                <th>Фактическая дата оплаты АВР/оплата КВ</th>
                <th>Статус оплаты КВ</th>
              </tr>
            </thead>
            <tbody>
              {avrs.map((avr) => (
                <Fragment>
                  <tr>
                    <td>
                      {formatMoney(
                        avr.kv_costs
                          .filter((i) => i.contract_installment.contract.id === id)
                          .map((i) => parseMoney(i.cost))
                          .reduce((a, b) => a + b, 0)
                      )}
                    </td>
                    <td>{formatDate(avr.date_on)}</td>
                    <td>
                      <Link to={`/avrs/${avr.id}`}>{avr.number}</Link>
                    </td>
                    <td>{status(avr)}</td>
                    <td>{paymentStatus(avr)}</td>
                  </tr>
                </Fragment>
              ))}
              {contractStore.data.contract_returnment_avrs.map((avr) => (
                <Fragment>
                  <tr className="table-danger">
                    <td>
                      {formatMoney(
                        avr.contract_returnments
                          .filter((i) => i.contract.id === id)
                          .map(
                            (i) =>
                              parseMoney(i.payment_cost) *
                              parseMoney(i.contract.kvs.find((i) => i.channel.id === avr.channel.id)?.rate) *
                              0.01
                          )
                          .reduce((a, b) => a + b, 0)
                      )}
                    </td>
                    <td>{formatDate(avr.date_on)}</td>
                    <td>
                      <Link to={`/avrs/${avr.id}`}>{avr.number}</Link>
                    </td>
                    <td>{status(avr)}</td>
                    <td>{paymentStatus(avr)}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </Tab>
        <Tab eventKey="couriers" title="Курьерские отправки">
          <br />
          <table className="table table-hover">
            <thead>
              <tr>
                <th>ID</th>
                <th>Дата</th>
                <th>Заказчик</th>
                <th>Отправитель</th>
                <th>Получатель</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody>
              {couriers.map((row) => (
                <tr>
                  <td onClick={() => history.push(`/couriers/${row.id}`)}>{row.id}</td>
                  <td onClick={() => history.push(`/couriers/${row.id}`)}>{formatDate(row.from_date_on)}</td>
                  <td onClick={() => history.push(`/couriers/${row.id}`)}>{row.user.name}</td>
                  <td onClick={() => history.push(`/couriers/${row.id}`)}>{row.from_organization.name}</td>
                  <td onClick={() => history.push(`/couriers/${row.id}`)}>
                    {uniqBy(row.recipients, (i) => i.to_organization.id)
                      .map((i) => i.to_organization.name)
                      .join(", ")}
                  </td>
                  <td onClick={() => history.push(`/couriers/${row.id}`)}>
                    <Badge pill variant={getBadgeVariant(row.status.value)}>
                      {row.status.label}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Tab>
        <Tab eventKey="bso" title="БСО">
          <br />
          <table className="table">
            <thead>
              <tr>
                <th>№ ПП</th>
                <th>Вид БСО</th>
                <th>Серия</th>
                <th>№ БСО</th>
                <th>Статус БСО</th>
                <th>Геолокация</th>
                <th>Подразделение</th>
              </tr>
            </thead>
            <tbody>
              {bsos
                .sort((a, b) => a.is_current - b.is_current)
                .reverse()
                .map((i) => (
                  <tr>
                    <td>{i.id}</td>
                    <td>{i.bso_kind.name}</td>
                    <td>{i.bso_batch.name}</td>
                    <td>{i.number}</td>
                    <td>{i.form_status.label}</td>
                    <td>{i.geolocation_status.label}</td>
                    <td>
                      {i.geolocation_status.value.toString() === "1" && i.contract
                        ? i.contract.user.subdivision.name
                        : "-"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </Fragment>
  )
}

export default withRouter(observer(Backoffice))
