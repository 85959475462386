import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import BudgetMotivationsStore from "stores/BudgetMotivationStore/One"
import Form from "pages/Budgets/containers/BudgetMotivations/components/Form"
import { BreadcrumbItem } from "components/UI"

const budgetStore = BudgetStore.create()
const budgetMotivationStore = BudgetMotivationsStore.create()

const Add = (props) => {
  const { budget_id } = props.match.params

  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetMotivationStore.fetchMeta({ budget_id: budget_id })
  }, [])

  useEffect(() => {
    budgetMotivationStore.reset()
    if (budgetStore.data.kind.value.toString() === "1") {
      budgetMotivationStore.applyData({
        status: 200,
        data: {
          data: {
            ...budgetMotivationStore.data,
            kind: { value: 1 }
          }
        }
      })
    }
  }, [budgetStore.isFetched, budgetMotivationStore.isFetched])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem url={`/budgets/${budget_id}/budget_motivations`}>Мотивация</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>

      <Form {...{ store: budgetMotivationStore, budgetStore }} />
    </Fragment>
  )
}

export default observer(Add)
