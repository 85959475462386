import React, { useContext, Fragment } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import { Route } from "react-router-dom"

const PrivateRoute = ({ component: Component, condition, ...rest }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <Route
      {...rest}
      render={(props) =>
        condition(currentUser) ? (
          <Component {...props} />
        ) : (
          <Fragment>
            <h1>ОШИБКА 401</h1>
            <p>У вас нет прав для доступа к этой странице. Обратитесь к системному администратору.</p>
          </Fragment>
        )
      }
    />
  )
}

export default PrivateRoute
