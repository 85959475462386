import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { DateInput, TextInput } from "components/UI"

const OrderFireByAgreementTab = ({ store, handleSubmit, values, setFieldValue, ...props }) => {
  return (
    <Fragment>
      <br />
      <DateInput name="order_fire_by_agreement.date_on" label="Дата увольнения" required />
      <TextInput name="order_fire_by_agreement.reason" label="Основание расторжения трудового договора (увольнения)" required />
      <TextInput name="order_fire_by_agreement.reason_document" label="Основание (документ)" required />
      <TextInput name="order_fire_by_agreement.comment" label="Комментарий" />
    </Fragment>
  )
}

export default observer(OrderFireByAgreementTab)
