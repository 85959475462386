import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import ContractDocumentStore from "stores/ReportStore/ContractDocuments"
import Breadcrumbs from "pages/ContractDocuments/components/Breadcrumbs"
import { Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"
import Filter from "./components/Filter"

const contractDocumentsStore = ContractDocumentStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    contractDocumentsStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Filter store={contractDocumentsStore} />
      </Card>
      <Table store={contractDocumentsStore} />
      <Paginate store={contractDocumentsStore} />
      <table className="table" style={{ width: "500px" }}>
        <tr>
          <th className="table-danger">Договор действует и не сдан</th>
        </tr>
      </table>
    </Fragment>
  )
}

export default observer(List)
