import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { DateInput, RadioButtonGroup, RadioInput, TextInput, CurrencyInput, Submit } from "components/UI"
import Documents from "./Documents"
import formSubmitHandler from "utils/formSubmitHandler"
import StandardPeriods from "./StandardPeriods"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import Parcels from "./Parcels"

const Form = ({ store, values, contractStore, handleSubmit, setFieldValue, contract_id }) => {
  // const { statusesOptions } = store.filtersOptions

  const statusesOptions = [
    { value: 0, label: "Черновик" },
    { value: 1, label: "Выпущен" }
  ]

  const { cargoCurrenciesOptions } = contractStore.filtersOptions

  // const setParcels = (parcels) =>
  //   setFieldValue("parcels", values.parcels.concat(parcels))
  const setParcels = (parcels) => setFieldValue("parcels", parcels)

  // const { id: contract_id } = contractStore.data

  const disabled = store.data.status.value.toString() === '1'
  const disabledStatus = store.data.status.value.toString() === '1' && store.data.contract_installment.payment_on && store.data.contract_installment.payment_cost

  return (
    <form onSubmit={handleSubmit}>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Общие сведения">
          <br />
          <RadioButtonGroup label="Статус" name="status.value" disabled={disabledStatus}>
            {statusesOptions.map(({ label, value }) => (
              <RadioInput key={`status_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <DateInput name="start_on" label="Начало отчетного периода" disabled={disabled} />
          <StandardPeriods disabled={disabled} />
          <DateInput name="finish_on" label="Конец отчетного периода" disabled={disabled} />
          <DateInput name="date_on" label="Дата бордеро" disabled={disabled} />
          <TextInput name="number" label="№ ген.договора / порядковый № бордеро" required disabled={disabled} />
          <RadioButtonGroup label="Валюта договора" name="currency.value" disabled={disabled}>
            {cargoCurrenciesOptions.map(({ label, value }) => (
              <RadioInput key={`cargo_currency_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <TextInput name="comment" label="Комментарий" />
          <Documents name="documents" disabled={disabled} />
        </Tab>
        <Tab eventKey="parcels" title="Реестр отгрузок">
          <br />
          <Parcels {...{ store, values, setParcels, contract_id }} />
        </Tab>
      </Tabs>

      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: contract_id } = formikBag.props.contractStore.data
      formSubmitHandler({ ...values, contract_id: contract_id }, formikBag, `/contracts/${contract_id}/parcel_borderos`)
    }
  }),
  observer
)(Form)
