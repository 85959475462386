import parseMoney from "utils/parseMoney"

const prepare = (params = {}) => {
  const {
    status: { value: status },
    basis: { value: basis },
    subdivisions,
    ...other
  } = params
  return {
    ...other,
    status,
    basis,
    subdivision_ids: subdivisions.map(i => i.id),
  }
}

export { prepare }
