import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import Breadcrumbs from "pages/Insurers/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import Tabs from "pages/Insurers/components/Tabs"
import formatDateTime from "utils/formatDateTime"
import ReactAutolinker from "react-autolinker"

class Show extends Component {
  insurerStore = InsurerStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.insurerStore.fetch({ id })
  }

  render() {
    const {
      insurerStore: { id, short_name, name, address, inn, site, specializations, comment, creator, updated_at }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumbs>
        <Tabs {...{ id }} />
        <br />
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Наименование (полное)</td>
                  <td style={{ width: "50%" }}>{name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Наименование (краткое)</td>
                  <td style={{ width: "50%" }}>{short_name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>ИНН</td>
                  <td style={{ width: "50%" }}>{inn}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Юридический адрес</td>
                  <td style={{ width: "50%" }}>{address}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Сайт</td>
                  <td style={{ width: "50%" }}><ReactAutolinker text={site} /></td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Специализации</td>
                  <td style={{ width: "50%" }}>{specializations.map((i) => i.name).join(", ")}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Комментарий</td>
                  <td style={{ width: "50%" }} dangerouslySetInnerHTML={{ __html: comment }} />
                </tr>
                <tr>
                  <td colSpan={2}></td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Редактор</td>
                  <td style={{ width: "50%" }}>{creator?.name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Подразделение</td>
                  <td style={{ width: "50%" }}>{creator?.subdivision?.name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Дата редактирования</td>
                  <td style={{ width: "50%" }}>{formatDateTime(updated_at)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
