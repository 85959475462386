import React, { Fragment } from "react"
import { CurrencyInput, DateInput, TextInput, RadioButtonGroup, RadioInput, TextArea } from "components/UI"
import { observer } from "mobx-react"
import UserSelect from "components/Common/UserSelect"
import ContactSelect from "components/Common/ContactSelect"
import ParentSelect from "./ParentSelect"

const InfoTab = ({ values }) => {
  const results = [
    { label: "В работе", value: "in_work" },
    { label: "Отказ", value: "refuse" },
    { label: "Выплачено", value: "payout" }
  ]

  const forms = [
    { label: "Натуральная", value: "non_cash" },
    { label: "Денежная", value: "cash" }
  ]

  // if (!values.contract) return null

  return (
    <Fragment>
      <ParentSelect />
      <TextInput name="1" label="Страхователь" disabled value={values.contract.client?.name} />
      <ContactSelect
        name="client_contact.id"
        label="Контактное лицо Страхователя"
        url={`/clients/${values.contract.client?.id}/contacts`}
      />
      <TextInput name="2" label="Страховая компания" disabled value={values.contract.insurer?.name} />
      <ContactSelect
        name="insurer_contact.id"
        label="Контактное лицо Страховой компании"
        url={`/insurers/${values.contract.insurer?.id}/contacts`}
      />
      <DateInput name="date_on" label="Дата события" />
      <DateInput name="statement_on" label="Дата заявления убытка" />
      <TextInput name="description" label="Описание / обстоятельства" />
      <CurrencyInput name="reserve" label="Резерв убытка, руб." />
      <TextArea name="comment" label="Комментарии" />
      <UserSelect name="executor.id" label="Исполнитель" />
      <RadioButtonGroup label="Результат" name="result.value">
        {results.map(({ label, value }) => (
          <RadioInput key={`result_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Форма выплаты" name="form.value">
        {forms.map(({ label, value }) => (
          <RadioInput key={`form_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <CurrencyInput name="payout" label="Сумма выплаты" />
    </Fragment>
  )
}

export default observer(InfoTab)
