import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import StrengthStore from "stores/StrengthStore/One"
import Breadcrumbs from "pages/Strengths/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"

class Show extends Component {
  strengthStore = StrengthStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.strengthStore.fetch({ id })
  }

  render() {
    const { strengthStore: store } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{store.name}</BreadcrumbItem>
        </Breadcrumbs>
        <Card>
          <Button url={`/strengths/${store.data.id}/edit`}>Редактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Название</td>
                  <td style={{ width: "50%" }}>{store.data.name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>ИНН</td>
                  <td style={{ width: "50%" }}>{store.data.inn}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>КПП</td>
                  <td style={{ width: "50%" }}>{store.data.kpp}</td>
                </tr>
                {store.data.kind === "entity" && (
                  <tr>
                    <td style={{ width: "50%" }}>Директор</td>
                    <td style={{ width: "50%" }}>{store.data.director}</td>
                  </tr>
                )}
                <tr>
                  <td style={{ width: "50%" }}>Юридический адрес</td>
                  <td style={{ width: "50%" }}>{store.data.address}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Банк</td>
                  <td style={{ width: "50%" }}>{store.data.bank}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Расчетный счет</td>
                  <td style={{ width: "50%" }}>{store.data.payment_account}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>БИК</td>
                  <td style={{ width: "50%" }}>{store.data.bik}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Корр.счет</td>
                  <td style={{ width: "50%" }}>{store.data.correspondent_account}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
