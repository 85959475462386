import React from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import addMonths from "date-fns/addMonths"
import subDays from "date-fns/subDays"
import { useFormikContext } from "formik"

const StandardPeriods = ({ disabled = false }) => {
  const {
    values: { start_on },
    setFieldValue
  } = useFormikContext()

  const setFinishOn = (finish_on) => {
    setFieldValue("finish_on", finish_on)
  }

  const plusOneMonth = () => setFinishOn(subDays(addMonths(start_on, 1), 1))
  const plusThreeMonth = () => setFinishOn(subDays(addMonths(start_on, 3), 1))

  return (
    <div className="form-group">
      <ButtonGroup>
        <Button variant="success" onClick={plusOneMonth} disabled={!start_on || disabled}>
          + 1 месяц
        </Button>
        <Button variant="success" onClick={plusThreeMonth} disabled={!start_on || disabled}>
          + 3 месяца
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default StandardPeriods
