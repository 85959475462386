import React, { useEffect } from "react"
import AsyncCreatableSelect from "react-select/async-creatable"
import TagStore from "stores/TagStore/List"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn, useFormikContext } from "formik"
import { observer } from "mobx-react"

const tagStore = TagStore.create()

const TagListSelect = ({ name = "tagList", label, options, required = false, ...props }) => {
  const id = idGenerator()
  const { errors, values, handleBlur, setFieldValue } = props.formik
  const error = getIn(errors, name, false)
  const value = (getIn(values, name, false) || []).map((i) => {
    return {
      label: i,
      value: i
    }
  })
  // console.log(getIn(values, name, []) || [])
  const className = classnames("react-select", { "is-invalid": error })
  // const _value = options.find((option) => String(option.value).toString() === String(value).toString())

  const handleChange = (value) => {
    setFieldValue(name, value ? value.map((i) => i.label) : [])
  }

  const errorStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#e74a3b"
    })
  }

  const loadUserOptions = (value) =>
    tagStore.search({ filter: { by_name: value }, limit: 0 }).then(({ tagOptions }) => {
      return tagOptions
    })
  return (
    <div className={classnames("form-group", { required: required })}>
      <label htmlFor={id}>{label}</label>
      <AsyncCreatableSelect
        isMulti
        defaultOptions
        value={value}
        placeholder="Выберите элементы"
        loadOptions={loadUserOptions}
        name={name}
        formatCreateLabel={(inputValue) => `Создать "${inputValue}"`}
        onChange={handleChange}
      />
    </div>
  )
}

export default compose(observer, connect)(TagListSelect)
