import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import {
  BikInput,
  CorrespondentAccountInput,
  InnInput,
  KppInput,
  PaymentAccountInput,
  RadioButtonGroup,
  RadioInput,
  Submit,
  TextInput
} from "components/UI"

const Form = ({ handleSubmit, values }) => (
  <form onSubmit={handleSubmit}>
    <RadioButtonGroup label="Тип" name="kind">
      <RadioInput label="ИП" id="individual" />
      <RadioInput label="Юридическое лицо" id="entity" />
    </RadioButtonGroup>
    <TextInput name="name" label="Название" />
    <TextInput name="full_name" label="Полное наименование" />
    <InnInput name="inn" label="ИНН" kind={values.kind} />
    <KppInput name="kpp" label="КПП" />
    {values.kind === "entity" && <TextInput name="director" label="Директор" />}
    <TextInput name="address" label="Юридический адрес" />
    <TextInput name="bank" label="Банк" />
    <PaymentAccountInput name="payment_account" label="Расчетный счет" />
    <BikInput name="bik" label="БИК" />
    <CorrespondentAccountInput name="correspondent_account" label="Корр.счет" />
    <Submit>Сохранить</Submit>
  </form>
)

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  })
)(Form)
