import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import ClientStore from "stores/ClientStore/One"
import DocumentStore from "stores/ClientDocumentStore/List"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import Tabs from "pages/Clients/components/Tabs"
import { Card, Paginate, Button } from "components/UI"
import { BreadcrumbItem } from "components/UI"
import Table from "./components/Table"

const clientStore = ClientStore.create()
const documentStore = DocumentStore.create()

const List = (props) => {
  const { client_id } = props.match.params

  useEffect(() => {
    clientStore.fetch({ id: client_id })
    documentStore.fetch({ ...getParams(), filter: { by_client_id: client_id } })
  }, [])

  // грязный хак, не удалять!
  console.log(documentStore.isFetched)

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/clients/${clientStore.id}`}>{clientStore.name}</BreadcrumbItem>
        <BreadcrumbItem active>Документы</BreadcrumbItem>
      </Breadcrumbs>
      <Tabs store={clientStore} />
      <br />
      <Card>
        <Button url={`/clients/${clientStore.id}/edit`}>Редактировать</Button>
      </Card>
      <Table store={documentStore} />
      {/* <Paginate store={documentStore} params={{ by_client_id: client_id }} /> */}
    </Fragment>
  )
}

export default observer(List)
