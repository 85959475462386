import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import toOptions from "utils/newToOptions"
import newCustomEnum from "types/newCustomEnum"
import { Operation } from "./Operation"
import { prepare } from "./utils"

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, []),
  kinds: types.array(newCustomEnum, []),
  forms: types.array(newCustomEnum, []),
  operation_kinds: types.array(newCustomEnum, []),
  cash_kinds: types.array(newCustomEnum, []),
  moving_kinds: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    statuses: [],
    kinds: [],
    forms: [],
    operation_kinds: [],
    cash_kinds: [],
    moving_kinds: []
  }
}

const OperationStore = types
  .model("OperationStore", {
    data: types.optional(Operation, {
      status: { value: 0 },
      kind: { value: 1 },
      form: { value: 0 },
      operation_kind: { value: 0 },
      cash_kind: { value: 0 },
      moving_kind: { value: 0 },
      prepayment: { value: 0 },
      subdivision: {},
      counterpart: {},
      channel: {},
      user: {},
      bill: {},
      cashier_from: {},
      cashier_to: {},
      project: {},
      avrs: [],
      contracts: [],
      relation_contract: {},
      is_need_fiscal: false,
      fiscal: false,
      operation_scans: [],
      is_prepayment: false,
      year: new Date().getFullYear(),
      is_selfemployed: false,
      is_prolongation: false,
      currency: {value: 0},
      rate: '0.000'
    }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get filtersOptions() {
      const { statuses, kinds, forms, operation_kinds, cash_kinds, moving_kinds } = self.meta.filters_options
      return {
        statuses: toOptions(statuses),
        kinds: toOptions(kinds),
        forms: toOptions(forms),
        operation_kinds: toOptions(operation_kinds),
        cash_kinds: toOptions(cash_kinds),
        moving_kinds: toOptions(moving_kinds)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/operations/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/operations/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(data = {}) {
      self.setState("pending")
      return instance
        .post(`/operations`, { data: prepare(data) })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(data = {}) {
      self.setState("pending")
      const { id } = data
      return instance
        .put(`/operations/${id}`, { data: prepare(data) })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    },

    accept(data = {}) {
      self.setState("pending")
      const { id } = data
      return instance
        .get(`/operations/${id}/accept`, { headers: { "X-Requested-With": "XMLHttpRequest" } })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    reject(data = {}) {
      self.setState("pending")
      const { id } = data
      return instance
        .get(`/operations/${id}/reject`, { headers: { "X-Requested-With": "XMLHttpRequest" } })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    }
  }))

export default OperationStore
