import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import AppealStore from "stores/AppealStore/One"
import Breadcrumbs from "pages/Appeals/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"

class Show extends Component {
  appealStore = AppealStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.appealStore.fetch({ id })
  }

  render() {
    const {
      appealStore: { id, created_at, creator, client, source, kind, feedback_on }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{id}</BreadcrumbItem>
        </Breadcrumbs>
        <Card>
          <Button url={`/appeals/${id}/edit`}>Радактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Порядковый номер</td>
                  <td style={{ width: "50%" }}>{id}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Дата и время регистрации</td>
                  <td style={{ width: "50%" }}>{created_at}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Консультант</td>
                  <td style={{ width: "50%" }}>{creator}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Страхователь</td>
                  <td style={{ width: "50%" }}>{client}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Канал поступления</td>
                  <td style={{ width: "50%" }}>{source}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Тип</td>
                  <td style={{ width: "50%" }}>{kind}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Дата обратной связи</td>
                  <td style={{ width: "50%" }}>{feedback_on}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
