import { types } from "mobx-state-tree"
import Risk from "./Risk"

const FilterOptions = types.model("FilterOptions", {
  risks: types.array(Risk, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

export default Meta
