import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Button, Card, Modal, ModalHeader, ModalBody, ModalFooter, Paginate } from "components/UI"
import AvrStore from "stores/AvrStore/List"
import Table from "./Table"
import Filter from "./Filter"

const avrStore = AvrStore.create()

const RelationModal = ({ isOpen, closeModal, setFieldValue, name }) => {
  const [selected, setSelected] = useState({ id: null, number: null })

  useEffect(() => {
    avrStore.fetch()
  }, [isOpen])

  const setRelation = () => {
    setFieldValue(name, selected)
    closeModal()
  }

  const handleSubmit = (values) => avrStore.fetch({ filter: values })

  // console.log(avrStore.meta).filter

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Выбрать связанный АВР</ModalHeader>
      <ModalBody>
        <Card>
          <Filter handleSubmit={handleSubmit} />
        </Card>
        <Table store={avrStore} selected={selected} setSelected={setSelected} />
        <Paginate store={avrStore} params={avrStore.meta.filter} saveToStore={false} />
        <Button onClick={setRelation}>Сохранить</Button>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(RelationModal)
