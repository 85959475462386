import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const BudgetMotivationCoefficientLimit = types.model("BudgetMotivationCoefficientLimit", {
  id: types.maybeNull(types.integer),
  bottom_limit: types.maybeNull(types.string),
  upper_limit: types.maybeNull(types.string),
  coefficient: types.maybeNull(types.string),
})

const BudgetMotivationCoefficient = types.model("BudgetMotivationCoefficient", {
  id: types.maybeNull(types.integer),
  budget_id: types.maybeNull(types.integer),
  status: types.maybeNull(newCustomEnum),
  basis: types.maybeNull(newCustomEnum),
  subdivisions: types.maybeNull(types.array(Subdivision)),
  comment: types.maybeNull(types.string),
  budget_motivation_coefficient_limits: types.maybeNull(types.array(BudgetMotivationCoefficientLimit))
})

export { BudgetMotivationCoefficient }
