import React, { useState } from "react"
import { observer } from "mobx-react"
import { getIn, useFormikContext } from "formik"
import { Link } from "react-router-dom"
import { Button } from "components/UI"
import classnames from "classnames"
import Modal from "./Modal"

const RelationContract = ({ name }) => {
  const { values, errors, setFieldValue, ...p } = useFormikContext()
  const error = getIn(errors, name, false)

  const relation_contracts = getIn(values, name)

  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const required = values.status.value.toString() === '1' && values.bill && values.bill?.index?.startsWith('701')

  return (
    <div className={classnames("form-group", { required: required })}>
      <label>
        Связанные договоры:&nbsp;
        {relation_contracts.map((relation_contract, index) => [
          index > 0 && ", ",
          <Link target="_blank" to={`/contracts/${relation_contract.id}`}>
            {relation_contract.number}
          </Link>
        ])}
      </label>
      <fieldset>
        <Button onClick={openModal}>Выбрать</Button>
      </fieldset>
      <Modal
        isOpen={isOpen}
        relation_contracts={relation_contracts}
        closeModal={closeModal}
        setFieldValue={setFieldValue}
        name={name}
        operation_kind={values.operation_kind}
      />
      {error && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {error[0]}
        </div>
      )}
    </div>
  )
}

export default observer(RelationContract)
