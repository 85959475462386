import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import AgencyContractStore from "stores/AgencyContractStore/One"
import Breadcrumbs from "pages/AgencyContracts/components/Breadcrumbs"
import Form from "pages/AgencyContracts/components/Form"
import { BreadcrumbItem } from "components/UI"
import qs from "qs"

const agencyContractStore = AgencyContractStore.create()

const Edit = (props) => {
  useEffect(() => {
    const { id } = props.match.params
    agencyContractStore.fetch({ id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/agency_contracts/${agencyContractStore.id}`}>{agencyContractStore.data.id}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={agencyContractStore} />
    </Fragment>
  )
}

export default observer(Edit)
