import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { withRouter } from "react-router-dom"

const status = (status) => {
  switch (status.value) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th>ID</th>
        <th>Статус</th>
        <th>Тема приказа</th>
        <th>Дата составления</th>
        <th>Номер приказа</th>
        <th>ФИО сотрудника</th>
        <th>Комментарии</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`orders-${index}`}>
          <td onClick={() => history.push(`/orders/${row.id}`)}>{row.id}</td>
          <td onClick={() => history.push(`/orders/${row.id}`)}>{status(row.status)}</td>
          <td onClick={() => history.push(`/orders/${row.id}`)}>{row.order_subject.name}</td>
          <td onClick={() => history.push(`/orders/${row.id}`)}>{formatDate(row.date_on)}</td>
          <td onClick={() => history.push(`/orders/${row.id}`)}>{row.number}</td>
          <td onClick={() => history.push(`/orders/${row.id}`)}>{row.user.name}</td>
          <td onClick={() => history.push(`/orders/${row.id}`)}>{row.comment}</td>
          <td>
            <Edit url={`/orders/${row.id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, row.id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
