import React from "react"
import ReactPaginate from "react-paginate"
import qs from "qs"
import { historyPushToStore } from "utils/historyPushWithStore"

const Paginate = ({ store, fetch, params = null, modifyPath = true, ...props }) => {
  const { page, total_pages } = store.pagination
  params = params || store.filter // Это для сохранения поиска в модалках
  const onPageChangeHandler = (page) => {
    if (!params) {
      const { search } = window.location
      params = qs.parse(search, { ignoreQueryPrefix: true })
      modifyPath &&
        window.history.pushState(null, "", window.location.pathname + "?" + qs.stringify({ ...params, page }))
    }
    fetch({ filter: params, page })
  }
  if (total_pages === 1) {
    return null
  }
  return (
    <nav>
      <ReactPaginate
        activeClassName="active"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        disabledClassName="disabled"
        forcePage={page - 1}
        marginPagesDisplayed={5}
        nextClassName="page-item"
        nextLabel="&raquo;"
        nextLinkClassName="page-link"
        // onPageChange={({ selected }) => onPageChangeHandler(selected + 1)}
        onPageChange={({ selected }) => {
          onPageChangeHandler(selected + 1)
          const { pathname, search } = window.location
          historyPushToStore(pathname, search)
        }}
        pageClassName="page-item"
        pageCount={total_pages}
        pageLinkClassName="page-link"
        pageRangeDisplayed={5}
        previousClassName="page-item"
        previousLabel="&laquo;"
        previousLinkClassName="page-link"
      />
    </nav>
  )
}

export default Paginate
