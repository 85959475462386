import React from "react"
import { Tab, Tabs } from "components/UI"
import { observer } from "mobx-react"

const TabsBlock = ({ store }) => {
  const { id } = store.data
  const mapping = {
    [`/budgets/${id}`]: "Общие сведения"
  }

  if (store.data.kind.value.toString() === "0") {
    mapping[`/budgets/${id}/summary`] = "Свод"
    if (window.location.pathname === `/budgets/${id}/user_sale_plans/plan`) {
      mapping[`/budgets/${id}/user_sale_plans/plan`] = "План продаж"
    } else {
      mapping[`/budgets/${id}/user_sale_plans`] = "План продаж"
    }
    if (window.location.pathname === `/budgets/${id}/budget_fots/plan`) {
      mapping[`/budgets/${id}/budget_fots/plan`] = "ФОТ"
    } else if (window.location.pathname === `/budgets/${id}/budget_fots/nalogs`) {
      mapping[`/budgets/${id}/budget_fots/nalogs`] = "ФОТ"
    } else {
      mapping[`/budgets/${id}/budget_fots`] = "ФОТ"
    }
    if (window.location.pathname === `/budgets/${id}/budget_nors/plan`) {
      mapping[`/budgets/${id}/budget_nors/plan`] = "НОР"
    } else {
      mapping[`/budgets/${id}/budget_nors`] = "НОР"
    }
    if (window.location.pathname === `/budgets/${id}/budget_motivations/plan`) {
      mapping[`/budgets/${id}/budget_motivations/plan`] = "Мотивация"
    } else if (window.location.pathname === `/budgets/${id}/budget_motivations/accrued`) {
      mapping[`/budgets/${id}/budget_motivations/accrued`] = "Мотивация"
    } else if (window.location.pathname === `/budgets/${id}/budget_motivations/to_payoff`) {
      mapping[`/budgets/${id}/budget_motivations/to_payoff`] = "Мотивация"
    } else if (window.location.pathname === `/budgets/${id}/budget_motivations/paid`) {
      mapping[`/budgets/${id}/budget_motivations/paid`] = "Мотивация"
    } else {
      mapping[`/budgets/${id}/budget_motivations`] = "Мотивация"
    }
    mapping[`/budgets/${id}/turnover`] = "Налог с оборота"
  }

  if (store.data.kind.value.toString() === "1") {
    if (window.location.pathname === `/budgets/${id}/company_summary/tec_result_plan`) {
      mapping[`/budgets/${id}/company_summary/tec_result_plan`] = "Свод"
    } else if (window.location.pathname === `/budgets/${id}/company_summary/tec_result_fact`) {
      mapping[`/budgets/${id}/company_summary/tec_result_fact`] = "Свод"
    } else if (window.location.pathname === `/budgets/${id}/company_summary/fin_result_plan`) {
      mapping[`/budgets/${id}/company_summary/fin_result_plan`] = "Свод"
    } else if (window.location.pathname === `/budgets/${id}/company_summary/fin_result_fact`) {
      mapping[`/budgets/${id}/company_summary/fin_result_fact`] = "Свод"
    } else {
      mapping[`/budgets/${id}/company_summary/tec_result_plan`] = "Свод"
    }

    if (window.location.pathname === `/budgets/${id}/coefficient/motivation`) {
      mapping[`/budgets/${id}/coefficient/motivation`] = "Коэффициенты"
    } else if (window.location.pathname === `/budgets/${id}/budget_motivation_coefficients`) {
      mapping[`/budgets/${id}/budget_motivation_coefficients`] = "Коэффициенты"
    } else {
      mapping[`/budgets/${id}/coefficient/tech_params`] = "Коэффициенты"
    }
    if (window.location.pathname === `/budgets/${id}/budget_fots/plan`) {
      mapping[`/budgets/${id}/budget_fots/plan`] = "ФОТ"
    } else {
      mapping[`/budgets/${id}/budget_fots`] = "ФОТ"
    }
    if (window.location.pathname === `/budgets/${id}/budget_nors/plan`) {
      mapping[`/budgets/${id}/budget_nors/plan`] = "НОР"
    } else {
      mapping[`/budgets/${id}/budget_nors`] = "НОР"
    }
    if (window.location.pathname === `/budgets/${id}/budget_motivations/plan`) {
      mapping[`/budgets/${id}/budget_motivations/plan`] = "Мотивация"
    } else {
      mapping[`/budgets/${id}/budget_motivations`] = "Мотивация"
    }
  }

  return (
    <Tabs>
      {Object.entries(mapping).map((i) => {
        const active = window.location.pathname === i[0]
        return (
          <Tab key={i[0]} active={active} to={i[0]}>
            {i[1]}
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default observer(TabsBlock)
