import React from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "react-router-dom"
import { observer } from "mobx-react"
import afterDestroyFetch from "utils/afterDestroyFetch"

const userStatus = (status) => {
  switch (status.value) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Работает" />
    case 1:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не работает" />
    default:
      return <i className="fa fa-circle" style={{ color: "yellow" }} title="Отпуск" />
  }
}

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ФИО</th>
        <th scope="col">Должность</th>
        <th scope="col">Подразделение</th>
        <th scope="col">Статус</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map(({ id, name, position, subdivision, status }, index) => (
        <tr key={`users-${index}`}>
          <td>
            <Link to={`/users/${id}`}>{name}</Link>
          </td>
          <td>{position.name}</td>
          <td>{subdivision.short_name}</td>
          <td>{userStatus(status)}</td>
          <td>
            <Edit url={`/users/${id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
