import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Contacts from "./containers/Contacts"
import Contracts from "./containers/Contracts"
import Documents from "./containers/Documents"
import CheckPermissions from "components/CheckPermissions"

const Clients = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("clients")}>
    <Switch>
      <Route path="/clients/:id/edit" component={Edit} />
      <Route path="/clients/:id/contacts" component={Contacts} />
      <Route path="/clients/:id/contracts" component={Contracts} />
      <Route path="/clients/:id/documents" component={Documents} />
      <Route path="/clients/new" component={Add} />
      <Route path="/clients/:id" component={Show} />
      <Route path="/clients" component={List} />
    </Switch>
  </CheckPermissions>
)

export default Clients
