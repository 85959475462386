import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import OrderStore from "stores/OrderStore/One"
import Breadcrumbs from "pages/Orders/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import InfoTab from "./components/InfoTab"
import HireTab from "./components/HireTab"
import SalaryChangeTab from "./components/SalaryChangeTab"
import VacationTab from "./components/VacationTab"
import ChangePositionTab from "./components/ChangePositionTab"
import FireAtWillTab from "./components/FireAtWillTab"
import FireByAgreementTab from "./components/FireByAgreementTab"
import AwardTab from "./components/AwardTab"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

const status = (status) => {
  switch (status.value) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

class Show extends Component {
  orderStore = OrderStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.orderStore.fetch({ id })
  }

  render() {
    const {
      orderStore: { data }
    } = this

    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>Приказ №{data.number}</BreadcrumbItem>
        </Breadcrumbs>
        <Card>
          <Button url={`/orders/${data.id}/edit`}>Редактировать</Button>
        </Card>
        <Card>Статус приказа:&nbsp;{status(data.status)}</Card>
        <Tabs defaultActiveKey="info">
          <Tab eventKey="info" title="Общие сведения">
            <br />
            <InfoTab {...{ store: this.orderStore, ...this.props }} />
          </Tab>
          {data.order_subject.id === 4 && (
            <Tab eventKey="hire" title="Спецификация">
              <br />
              <HireTab {...{ store: this.orderStore, ...this.props }} />
            </Tab>
          )}
          {data.order_subject.id === 8 && (
            <Tab eventKey="salary_change" title="Спецификация">
              <br />
              <SalaryChangeTab {...{ store: this.orderStore, ...this.props }} />
            </Tab>
          )}
          {data.order_subject.id === 3 && (
            <Tab eventKey="vacation" title="Спецификация">
              <br />
              <VacationTab {...{ store: this.orderStore, ...this.props }} />
            </Tab>
          )}
          {data.order_subject.id === 5 && (
            <Tab eventKey="сhange_position" title="Спецификация">
              <br />
              <ChangePositionTab {...{ store: this.orderStore, ...this.props }} />
            </Tab>
          )}
          {data.order_subject.id === 6 && (
            <Tab eventKey="order_fire_at_will" title="Спецификация">
              <br />
              <FireAtWillTab {...{ store: this.orderStore, ...this.props }} />
            </Tab>
          )}
          {data.order_subject.id === 7 && (
            <Tab eventKey="order_fire_by_agreement" title="Спецификация">
              <br />
              <FireByAgreementTab {...{ store: this.orderStore, ...this.props }} />
            </Tab>
          )}
          {data.order_subject.id === 2 && (
            <Tab eventKey="order_award" title="Спецификация">
              <br />
              <AwardTab {...{ store: this.orderStore, ...this.props }} />
            </Tab>
          )}
        </Tabs>
      </Fragment>
    )
  }
}

export default observer(Show)
