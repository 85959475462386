import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"

const Cargo = types.model("Cargo", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  cost: types.maybeNull(types.string),
  condition: types.maybeNull(types.string),
  franchise: types.maybeNull(types.string),
  tariff: types.maybeNull(types.string),
  currency: types.maybeNull(newCustomEnum),
})

export default Cargo
