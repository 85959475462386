import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import state from "types/state"
import format from "date-fns/format"
import Appeal from "./Appeal"
import Meta from "./ListMeta"
import toOptions from "utils/newToOptions"

const metaDefaultValue = {
  filter: {
    by_user: { id: null, name: null }
  },
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const AppealStore = types
  .model("Appeal", {
    data: types.array(Appeal, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(state)
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { statuses } = self.meta.filters_options
      return { statusesOptions: toOptions(statuses) }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_created_at } = params.filter
        if (by_created_at) {
          params.filter = { ...params.filter, by_created_at: format(by_created_at, "yyyy-MM-dd") }
        }
      }

      return instance
        .get("/appeals", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/appeals/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default AppealStore
