import React, { Fragment } from "react"
import { observer } from "mobx-react"

const Table = ({ store }) => (
  <Fragment>
    <table className="table table-hover">
      <tbody>
        {store.data.statistics.map((row, index) => (
          <tr key={`report-sales-${index}`}>
            {row.map((col) => (
              <td width="50%">{col}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">Наименование показателя</th>
          <th scope="col">План</th>
          <th scope="col">Факт</th>
          <th scope="col">% исполнения</th>
        </tr>
      </thead>
      <tbody>
        {store.data.table.map((row, index) => (
          <tr key={`report-sales-${index}`}>
            {row.map((col) => (
              <td>{col}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </Fragment>
)

export default observer(Table)
