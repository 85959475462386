import "./styles.scss"
import DatePicker, { registerLocale } from "react-datepicker"
import React from "react"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn, ErrorMessage } from "formik"
import { observer } from "mobx-react"
import { ru } from "date-fns/locale"
// import MaskedInput from "react-text-mask"

const TimeInput = ({ name, label, required = false, ...props }) => {
  const id = idGenerator()
  const { errors, values, handleBlur, setFieldValue } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false)
  const className = classnames("form-control", { "is-invalid": error })
  registerLocale("ru", ru)

  return (
    <div className={classnames("form-group", { required: required })}>
      <label htmlFor={id}>{label}</label>
      <DatePicker
        autoComplete="off"
        dateFormat="H:mm"
        isClearable
        locale="ru"
        onBlur={handleBlur}
        onChange={(e) => setFieldValue(name, e)}
        selected={value}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        
        // customInput={<MaskedInput mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]} />}
        {...{ className, id, name, ...props }}
      />
      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default compose(observer, connect)(TimeInput)
