import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import SourceStore from "stores/SourceStore/List"
import Breadcrumbs from "pages/Sources/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"

const sourceStore = SourceStore.create()

const List = () => {
  useEffect(() => {
    sourceStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/sources/new">Создать</Button>
      </Card>
      <Table store={sourceStore} />
      <Paginate store={sourceStore} />
    </Fragment>
  )
}

export default observer(List)
