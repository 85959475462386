import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import { Breadcrumb, BreadcrumbItem, Card, Paginate } from "components/UI"
import Filter from "./components/Filter"
import Table from "./components/Table"
import ContractInstallmentStore from "stores/ContractInstallmentStore/Report"
import { historyPushFromStore } from "utils/historyPushWithStore"
import getParams from "utils/getParams"

const сontractInstallmentStore = ContractInstallmentStore.create()

const ContractInstallments = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    сontractInstallmentStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem active>Реестр рассрочек</BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <Filter store={сontractInstallmentStore} />
      </Card>
      <Table store={сontractInstallmentStore} />
      <Paginate store={сontractInstallmentStore} />
    </Fragment>
  )
}

export default observer(ContractInstallments)
