const fioHuman = ({ f_name, l_name, p_name }) => `${l_name || ""} ${f_name || ""} ${p_name || ""}`

const getUrl = ({ id, client_id, subagent_id, provider_id, court_id, insurer_id }) => {
  const keys = { client_id, subagent_id, provider_id, court_id, insurer_id }

  const url = Object.keys(keys).find((i) => keys[i] !== undefined)

  if (id) {
    return `/${url.replace("_id", "s")}/${keys[url]}/contacts/${id}`
  }

  return `/${url.replace("_id", "s")}/${keys[url]}/contacts`
}

const prepare = (params = {}) => {
  const { name, insurance_kinds, ...other } = params

  return {
    ...other,
    insurance_kind_ids: insurance_kinds.map((i) => i.value)
  }
}

export { fioHuman, getUrl, prepare }
