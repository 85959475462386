import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Provider } from "./Provider"
import { prepare } from "./utils"
import newCustomEnum from "types/newCustomEnum"
import newToOptions from "utils/newToOptions"

const FilterOptions = types.model("FilterOptions", {
  ownership_kinds: types.array(newCustomEnum, []),
  payment_kinds: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    ownership_kinds: []
  }
}

const ProviderStore = types
  .model("ProviderStore", {
    data: types.optional(Provider, {
      kind: "individual",
      organization_name: "",
      ownership_kind: { value: "0" },
      payment_kind: { value: "0" },
      f_name: "",
      l_name: "",
      p_name: "",
      position: "",
      street: "",
      index: "",
      city: "",
      emails: [],
      phones: [],
      contacts: [],
      user: {},
      activity_kind: { id: null },
      subdivision: { id: null },
      holding: { id: null },
      industry: { id: null },
      // strength: { id: null },
      documents: []
    }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get name() {
      if (self.data.kind === "individual") {
        return [self.data.l_name, self.data.f_name, self.data.p_name].join(" ")
      } else {
        return self.data.organization_name
      }
    },

    get filtersOptions() {
      const { ownership_kinds, payment_kinds } = self.meta.filters_options
      return { ownership_kinds: newToOptions(ownership_kinds), payment_kinds: newToOptions(payment_kinds) }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/providers/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/providers/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/providers`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/providers/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ProviderStore
