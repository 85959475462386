import React, { useEffect } from "react"
import { observer } from "mobx-react"
import KvCostStore from "stores/KvCostStore/List"
import Modal from "react-modal"
import { Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"
import Filter from "./components/Filter"

const kvCostStore = KvCostStore.create()

const KvCostsModal = ({
  isOpen,
  setIsOpen,
  channel_id,
  insurer_id,
  kv_costs: kvCosts,
  by_payment_on_start,
  by_payment_on_finish,
  toggleChange,
  ...props
}) => {
  const defaultFilter = {
    by_kind: 'fix_kv',
    by_channel: { id: channel_id },
    by_insurer: { id: insurer_id },
    by_contract_payment_status: "paid",
    by_payment_on_start: by_payment_on_start,
    by_payment_on_finish: by_payment_on_finish
  }

  useEffect(() => {
    kvCostStore.fetch({
      filter: defaultFilter
    })
  }, [isOpen])

  const closeModal = () => setIsOpen(false)

  const onSave = () => {
    props.setKvCosts(kvCosts)
    closeModal()
  }

  const onFilterSubmit = (values, { props: { store } }) => store.fetch({ filter: { ...defaultFilter, ...values } })
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className="modal" ariaHideApp={false}>
      <div className="modal-dialog modal-xxl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Добавить</h5>
            <button type="button" className="close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Card>
              <Filter store={kvCostStore} handleSubmit={onFilterSubmit} defaultFilter={defaultFilter} />
            </Card>
            <Table store={kvCostStore} toggleChange={toggleChange} kvCosts={kvCosts} />
            <Paginate store={kvCostStore} params={{ ...defaultFilter, ...kvCostStore.meta.filter }} />
          </div>
          <div className="modal-footer">
            <Button onClick={onSave}>Сохранить</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(KvCostsModal)
