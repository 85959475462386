import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import { Link } from "react-router-dom"
import Tabsss from "pages/Contracts/components/Tabs"
import SubTabsss from "pages/Contracts/components/SubTabs"
import Badge from "react-bootstrap/Badge"

const contractStore = ContractStore.create()

const paymentStatus = (contract_installments) => {
  const count = contract_installments.filter((i) => i.payment_on).length
  switch (count) {
    case 0:
      return (
        <i className="fa fa-circle" style={{ color: "red" }} title="Ни один из платежей СП не оплачен Страхователем" />
      )
    case contract_installments.length:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Все платежи СП оплачены Страхователем" />
    default:
      return (
        <i
          className="fa fa-circle"
          style={{ color: "orange" }}
          title="Хотя бы один из платежей СП оплачен Страхователем"
        />
      )
  }
}

const documentStatus = (status) => {
  switch (status.value) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    case 1:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const dd = (data) => {
  return data.insurance_kind.id === 14 ? (
    data.versions
      .map((i) => (
        <Fragment>
          {i.additional_agreement_number}
          <br />
          {i.additional_agreement_kind && (
            <Badge variant={i.additional_agreement_kind.toString() === "0" ? "success" : "warning"}>
              {i.additional_agreement_kind.toString() === "0" ? "Бумажный" : "Электронный"}
            </Badge>
          )}
          <br />
        </Fragment>
      ))
      .reverse()
      .concat(
        <Fragment>
          {data.number}
          <br />
          <Badge variant={data.kind.value.toString() === "0" ? "success" : "warning"}>{data.kind.label}</Badge>
        </Fragment>
      )
  ) : (
    <Fragment>
      {data.number}
      <br />
      <Badge variant={data.kind.value.toString() === "0" ? "success" : "warning"}>{data.kind.label}</Badge>
    </Fragment>
  )
}

const Show = (props) => {
  const { id: contract_id } = props.match.params

  useEffect(() => {
    contractStore.fetch({ id: contract_id })
  }, [contract_id])

  const {
    id,
    status,
    insurance_kind,
    insurer,
    insurer_contact,
    channel,
    client,
    customer_inviter_is_subagent,
    customer_inviter,
    conclusion_on,
    number,
    start_on,
    finish_on,
    comment,
    user,
    contract_installments,
    subagent,
    parent,
    child,
    documents,
    scans,
    subdivision,
    is_need_to_prolongation,
    cancel_prolongation_cause,
    versions
  } = contractStore

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{id}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?additional=true`}>
            {contractStore.insurance_kind.id === 14 ? "Аддендум" : "Доп. соглашение"}
          </Button>
        )}
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?prolongation=true`}>Продление</Button>
        )}
        &nbsp;
        <Button url={`/contracts/${id}/edit?clone=true`}>Копировать</Button>
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <SubTabsss store={contractStore} />
      <br />
      <table className="table">
        <thead>
          <tr>
            <th colSpan="2">Страхователь</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Наименование</td>
            <td style={{ width: "50%" }}>
              <Link to={`/clients/${client.id}`}>
                {client.name} {client.kind != "individual" && (client.ownership_kind.value == 9 ? client.other_ownership_kind : client.ownership_kind.label)}
              </Link>
            </td>
          </tr>
          {client.contacts && client.contacts[0] && (
            <Fragment>
              <tr>
                <td style={{ width: "50%" }}>Контактное лицо</td>
                <td style={{ width: "50%" }}>{client.contacts[0].name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Email</td>
                <td style={{ width: "50%" }}>
                  {client.contacts[0].emails.map(({ id, value }) => (
                    <a key={id} href={`emailto:${value}`}>
                      {value}
                    </a>
                  ))}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Телефон</td>
                <td style={{ width: "50%" }}>
                  {client.contacts[0].phones.map(({ id, value }, index) => (
                    <Fragment>
                      <a key={id} href={`tel:${value}`}>
                        {value}
                      </a>
                      {index < client.contacts[0].phones.length - 1 ? ", " : ""}
                    </Fragment>
                  ))}
                </td>
              </tr>
            </Fragment>
          )}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan="2">Договор</th>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Страховая компания</td>
            <td style={{ width: "50%" }}>
              <Link to={`/insurers/${insurer.id}`}>{insurer.name}</Link>
            </td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Вид страхования</td>
            <td style={{ width: "50%" }}>{insurance_kind.name}</td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Родительский договор</td>
            <td style={{ width: "50%" }}>
              {parent && parent.id ? <Link to={`/contracts/${parent.id}`}>{parent.number}</Link> : <p>Нет</p>}
            </td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Дочерний договор</td>
            <td style={{ width: "50%" }}>
              {child && child.id ? <Link to={`/contracts/${child.id}`}>{child.number}</Link> : <p>Нет</p>}
            </td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Лицо, пригласившее страхователя</td>
            <td style={{ width: "50%" }}>
              {customer_inviter_is_subagent.value.toString() === "0" ? customer_inviter : subagent.name}
            </td>
          </tr>
          <tr>
            <td style={{ width: "50%" }}>Канал продаж</td>
            <td style={{ width: "50%" }}>{channel.name}</td>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th>Дата заключения</th>
            <th>Статус документа</th>
            <th>Статус оплаты</th>
            <th>№ договора</th>
            <th>Срок действия договора</th>
            <th>Куратор договора от СК</th>
            <th>Исполнитель (Си-нерджи)</th>
            <th>Дополнительная информация</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{conclusion_on}</td>
            <td>{documentStatus(status)}</td>
            <td>{paymentStatus(contract_installments)}</td>
            <td>{dd(contractStore.data)}</td>
            <td>
              {start_on} - {finish_on}
              {is_need_to_prolongation === false && (
                <Fragment>
                  <br />
                  Договор без продления
                  <br />
                  Причина: {cancel_prolongation_cause}
                </Fragment>
              )}
            </td>
            <td>
              {insurer_contact.id && `${insurer_contact.l_name} ${insurer_contact.f_name}`}
              <br />
              {insurer_contact.id && insurer_contact.phones.map((i) => i.value).join(", ")}
              <br />
              {insurer_contact.id && insurer_contact.emails.map((i) => i.value).join(", ")}
            </td>
            <td>
              {user.name}
              <br />
              {user.phones.map((i) => i.value).join(", ")}
              <br />
              {user.emails.map((i) => i.value).join(", ")}
              <br />
              {subdivision.name}
            </td>
            <td dangerouslySetInnerHTML={{ __html: comment }} />
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export default observer(Show)
