import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { withRouter } from "react-router-dom"

const status = (days) => {
  switch (true) {
    case days < 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Договор завершился" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Договор действует" />
  }
}

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Страховая компания</th>
        <th scope="col">Страхователь</th>
        <th scope="col">Вид страхования</th>
        <th scope="col">№ договора</th>
        <th scope="col">КВ по договору</th>
        <th scope="col">Срок действия договора</th>
        <th scope="col">Причина отказа от продления</th>
        <th scope="col">Статус</th>
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`contract-installment-${index}`}>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.id}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.insurer.name}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.client.name}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.insurance_kind.name}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.number}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.kvs_payments}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{formatDate(row.start_on)} - {formatDate(row.finish_on)}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{row.cancel_prolongation_cause}</td>
          <td onClick={() => window.open(`/contracts/${row.id}`, "_blank").focus()}>{status(row.until_to_prolongations)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
