import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import BudgetNorsStore from "stores/BudgetNorStore/One"
import Form from "pages/Budgets/containers/BudgetNors/components/Form"
import { BreadcrumbItem } from "components/UI"

const budgetStore = BudgetStore.create()
const budgetNorStore = BudgetNorsStore.create()

const Edit = (props) => {
  const { id, budget_id } = props.match.params
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetNorStore.fetch({ id, budget_id })
  }, [id, budget_id])
  console.log(budgetNorStore.isFetched)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>{budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}</BreadcrumbItem>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}/budget_nors`}>НОР</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        <BreadcrumbItem active>{budgetNorStore.id}</BreadcrumbItem>
      </Breadcrumbs>

      <Form {...{ store: budgetNorStore, budgetStore }} />
    </Fragment>
  )
}

export default observer(Edit)
