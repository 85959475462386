import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import instance from "connection/instance"

const ff = (col) => {
  let total = 0
  if (document.getElementById("data")) {
    document
      .getElementById("data")
      .querySelectorAll("tr")
      .forEach((el) => {
        total += parseMoney(el.childNodes[col]?.innerHTML)
      })
    document.getElementById(`total-${col - 2}`).innerHTML = formatMoney(total)
  }

  let total2 = 0

  if (document.getElementById("data2")) {
    document
      .getElementById("data2")
      .querySelectorAll("tr")
      .forEach((el) => {
        total2 += parseMoney(el.childNodes[col]?.innerHTML)
      })
    document.getElementById(`total2-${col - 2}`).innerHTML = formatMoney(total2)
  }
}

const ffdd = (i) => {
  if (i.basis.value.toString() === "0") {
    if (i.user_sale_plan_kind.value.toString() === "0") {
      return "Подразделение (итого)"
    }
    return i.user_sale_plan.name
  }
  return "-"
}

const dd = (fact, name, month, values) => {
  if (values.user_sale_plan_kind.value.toString() === "0") {
    return fact.reduce((a, b) => a + parseMoney(b.costs[month]), 0)
  }
  if (values.kind.value.toString() === "1" && values.user_sale_plan_kind.value.toString() === "1") {
    const data = fact.find((i) => i.id === values.user_sale_plan.id)
    if (data) {
      return data.costs[month]
    }
  }
  if (values.kind.value.toString() === "0" && values.user_sale_plan_kind.value.toString() === "1") {
    const data = fact.find((i) => i.id === values.user_sale_plan.id)
    if (data) {
      return data.costs[month]
    }
  }
  const data = fact.find((i) => i.user.name === name)
  if (data) {
    return data.costs[month]
  }
  return 0
}

const Company = ({ budget_id, budgetStore, budgetMotivationStore }) => {
  useEffect(() => {
    ff(3)
    ff(4)
    ff(5)
    ff(6)
    ff(7)
    ff(8)
    ff(9)
    ff(10)
    ff(11)
    ff(12)
    ff(13)
    ff(14)
    ff(15)
    ff(16)
    ff(17)
    ff(18)
    ff(19)
  })

  const [fact, setFact] = useState([])
  const [tecResults, setTecResults] = useState([])
  useEffect(() => {
    instance.get(`/budgets/${budget_id}/user_sale_plans/fact`).then(({ data }) => {
      setFact(data)
    })
    instance.get(`/budgets/${budget_id}/budget_subdivision_summaries/tec_results_plan`).then(({ data }) => {
      setTecResults(data.data)
    })
  }, [])

  const re = (values, budgetStore) => {
    if (
      values.basis.value.toString() === "1" &&
      values.condition.value.toString() === "0" &&
      values.rate_kind.value.toString() === "1"
    ) {
      return (
        <Fragment>
          <td>
            {formatMoney(
              parseFloat(values.quarter_1_amount) +
                parseFloat(values.quarter_2_amount) +
                parseFloat(values.quarter_3_amount) +
                parseFloat(values.quarter_4_amount)
            )}
          </td>
          {budgetStore.data.budget_items.map((item, itemIndex) => (
            <Fragment key={`item=${itemIndex}`}>
              {itemIndex % 3 === 0 && (
                <td style={{ verticalAlign: "top", width: "200px" }}>
                  {formatMoney(values[`quarter_${itemIndex / 3 + 1}_amount`])}
                </td>
              )}
              <td>{(itemIndex + 1) % 3 === 0 ? formatMoney(values[`quarter_${(itemIndex + 1) / 3}_amount`]) : ""}</td>
            </Fragment>
          ))}
        </Fragment>
      )
    }

    if (values.condition.value.toString() === "0") {
      return (
        <Fragment>
          <td>
            {formatMoney((tecResults["ТЕХ. РЕЗ."] || []).reduce((a, b) => a + (parseMoney(b) * values.rate) / 100, 0))}
          </td>
          {(tecResults["ТЕХ. РЕЗ."] || []).map((item, itemIndex) => (
            <Fragment key={`item=${itemIndex}`}>
              {itemIndex % 3 === 0 && (
                <td style={{ verticalAlign: "top", width: "200px" }}>
                  {formatMoney(
                    (tecResults["ТЕХ. РЕЗ."] || [])
                      .slice(itemIndex, itemIndex + 3)
                      .reduce((a, b) => a + (parseMoney(b) * values.rate) / 100, 0)
                  )}
                </td>
              )}
              <td>{formatMoney((parseMoney(item) * values.rate) / 100)}</td>
            </Fragment>
          ))}
        </Fragment>
      )
    }

    if (values.rate_kind.value.toString() === "1" && ["0", "1", "2"].includes(values.periodicity.value.toString())) {
      if (values.periodicity.value.toString() === "0") {
        return (
          <Fragment>
            <td>{formatMoney(values.amount)}</td>
            {budgetStore.data.budget_items.map((item, itemIndex) => (
              <Fragment key={`item=${itemIndex}`}>
                {itemIndex % 3 === 0 && <td style={{ verticalAlign: "top", width: "200px" }}></td>}
                <td>{(itemIndex + 1) % 12 === 0 ? formatMoney(values.amount) : ""}</td>
              </Fragment>
            ))}
          </Fragment>
        )
      }

      if (values.periodicity.value.toString() === "1") {
        return (
          <Fragment>
            <td>
              {formatMoney(
                parseFloat(values.quarter_1_amount) +
                  parseFloat(values.quarter_2_amount) +
                  parseFloat(values.quarter_3_amount) +
                  parseFloat(values.quarter_4_amount)
              )}
            </td>
            {budgetStore.data.budget_items.map((item, itemIndex) => (
              <Fragment key={`item=${itemIndex}`}>
                {itemIndex % 3 === 0 && (
                  <td style={{ verticalAlign: "top", width: "200px" }}>
                    {formatMoney(values[`quarter_${itemIndex / 3 + 1}_amount`])}
                  </td>
                )}
                <td>{(itemIndex + 1) % 3 === 0 ? formatMoney(values[`quarter_${(itemIndex + 1) / 3}_amount`]) : ""}</td>
              </Fragment>
            ))}
          </Fragment>
        )
      }

      if (values.periodicity.value.toString() === "2") {
        return (
          <Fragment>
            <td>
              {formatMoney(
                parseFloat(values.month_1_amount) +
                  parseFloat(values.month_2_amount) +
                  parseFloat(values.month_3_amount) +
                  parseFloat(values.month_4_amount) +
                  parseFloat(values.month_5_amount) +
                  parseFloat(values.month_6_amount) +
                  parseFloat(values.month_7_amount) +
                  parseFloat(values.month_8_amount) +
                  parseFloat(values.month_9_amount) +
                  parseFloat(values.month_10_amount) +
                  parseFloat(values.month_11_amount) +
                  parseFloat(values.month_12_amount)
              )}
            </td>
            {budgetStore.data.budget_items.map((item, itemIndex) => (
              <Fragment key={`item=${itemIndex}`}>
                {itemIndex % 3 === 0 && (
                  <td style={{ verticalAlign: "top", width: "200px" }}>
                    {formatMoney(
                      parseMoney(values[`month_${itemIndex + 1}_amount`]) +
                        parseMoney(values[`month_${itemIndex + 2}_amount`]) +
                        parseMoney(values[`month_${itemIndex + 3}_amount`])
                    )}
                  </td>
                )}
                <td>{formatMoney(values[`month_${itemIndex + 1}_amount`])}</td>
              </Fragment>
            ))}
          </Fragment>
        )
      }
    } else {
      return (
        <Fragment>
          <td>
            {formatMoney(
              budgetStore.data.budget_items.reduce(
                (a, b, currentIndex) =>
                  a +
                  (parseMoney(
                    dd(
                      fact,
                      values.kind.value.toString() === "0" ? "План подразделения (прочее)" : values.user.name,
                      currentIndex,
                      values
                    )
                  ) *
                    parseMoney(values.rate)) /
                    100,
                0
              )
            )}
          </td>
          {budgetStore.data.budget_items.map((item, itemIndex) => (
            <Fragment key={`item=${itemIndex}`}>
              {itemIndex % 3 === 0 && (
                <td style={{ verticalAlign: "top", width: "200px" }}>
                  {formatMoney(
                    budgetStore.data.budget_items
                      .slice(itemIndex, itemIndex + 3)
                      .reduce(
                        (a, b, currentIndex) =>
                          a +
                          (dd(
                            fact,
                            values.kind.value.toString() === "0" ? "План подразделения (прочее)" : values.user.name,
                            itemIndex + currentIndex,
                            values
                          ) *
                            parseMoney(values.rate)) /
                            100,
                        0
                      )
                  )}
                </td>
              )}
              <td>
                {formatMoney(
                  (parseMoney(
                    dd(
                      fact,
                      values.kind.value.toString() === "0" ? "План подразделения (прочее)" : values.user.name,
                      itemIndex,
                      values
                    )
                  ) *
                    parseMoney(values.rate)) /
                    100
                )}
              </td>
            </Fragment>
          ))}
        </Fragment>
      )
    }
  }

  return (
    <div style={{ overflowX: "scroll" }}>
      <table className="table table-bordered" style={{ width: "4300px" }}>
        <thead>
          <tr>
            <th colSpan={20}>Мотивация от Плана Продаж (План)</th>
          </tr>
          <tr>
            <th rowSpan={2}>#</th>
            <th rowSpan={2}>Мотивация</th>
            <th rowSpan={2}></th>
            <th rowSpan={2}>ИТОГО {budgetStore.data.year}</th>
            <th colSpan={4}>План 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
          </tr>
          <tr>
            <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
            <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
            <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
          </tr>
          <tr>
            <th></th>
            <th>Подразделение/Сотрудник</th>
            <th>Метрики мотивации</th>

            <th id="total-1"></th>
            <th id="total-2"></th>
            <th id="total-3"></th>
            <th id="total-4"></th>
            <th id="total-5"></th>
            <th id="total-6"></th>
            <th id="total-7"></th>
            <th id="total-8"></th>
            <th id="total-9"></th>
            <th id="total-10"></th>
            <th id="total-11"></th>
            <th id="total-12"></th>
            <th id="total-13"></th>
            <th id="total-14"></th>
            <th id="total-15"></th>
            <th id="total-16"></th>
            <th id="total-17"></th>
          </tr>
        </thead>
        {budgetMotivationStore.data.length > 0 && (
          <tbody id="data">
            {budgetMotivationStore.data
              .filter((values) => values.condition.value.toString() !== "1" && values.basis.value.toString() == "0")
              .map((values) => (
                <tr>
                  <td>{values.id}</td>
                  <td>{values.kind.value.toString() === "0" ? budgetStore.data.subdivision.name : values.user.name}</td>
                  <td>
                    {values.periodicity.label}
                    <br />
                    {ffdd(values)}
                    <br />
                    {values.condition.label}
                  </td>
                  {values.rate_kind.value.toString() === "1" &&
                  ["0", "1", "2"].includes(values.periodicity.value.toString()) ? (
                    <Fragment>
                      {values.periodicity.value.toString() === "0" && (
                        <Fragment>
                          <td>{formatMoney(values.amount)}</td>
                          {budgetStore.data.budget_items.map((item, itemIndex) => (
                            <Fragment key={`item=${itemIndex}`}>
                              {itemIndex % 3 === 0 && itemIndex !== 9 && (
                                <td style={{ verticalAlign: "top", width: "200px" }}></td>
                              )}
                              {itemIndex % 3 === 0 && itemIndex === 9 && (
                                <td style={{ verticalAlign: "top", width: "200px" }}>{formatMoney(values.amount)}</td>
                              )}
                              <td>{(itemIndex + 1) % 12 === 0 ? formatMoney(values.amount) : ""}</td>
                            </Fragment>
                          ))}
                        </Fragment>
                      )}
                      {values.periodicity.value.toString() === "1" && (
                        <Fragment>
                          <td>
                            {formatMoney(
                              parseFloat(values.quarter_1_amount) +
                                parseFloat(values.quarter_2_amount) +
                                parseFloat(values.quarter_3_amount) +
                                parseFloat(values.quarter_4_amount)
                            )}
                          </td>
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                            <Fragment key={`item=${month}`}>
                              {month % 3 === 0 && (
                                <td style={{ verticalAlign: "top", width: "200px" }}>
                                  {formatMoney(values[`quarter_${month / 3 + 1}_amount`])}
                                </td>
                              )}
                              <td>
                                {(month + 1) % 3 === 0 ? formatMoney(values[`quarter_${(month + 1) / 3}_amount`]) : ""}
                              </td>
                            </Fragment>
                          ))}
                        </Fragment>
                      )}
                      {values.periodicity.value.toString() === "2" && (
                        <Fragment>
                          <td>
                            {formatMoney(
                              parseFloat(values.month_1_amount) +
                                parseFloat(values.month_2_amount) +
                                parseFloat(values.month_3_amount) +
                                parseFloat(values.month_4_amount) +
                                parseFloat(values.month_5_amount) +
                                parseFloat(values.month_6_amount) +
                                parseFloat(values.month_7_amount) +
                                parseFloat(values.month_8_amount) +
                                parseFloat(values.month_9_amount) +
                                parseFloat(values.month_10_amount) +
                                parseFloat(values.month_11_amount) +
                                parseFloat(values.month_12_amount)
                            )}
                          </td>
                          {budgetStore.data.budget_items.map((item, itemIndex) => (
                            <Fragment key={`item=${itemIndex}`}>
                              {itemIndex % 3 === 0 && (
                                <td style={{ verticalAlign: "top", width: "200px" }}>
                                  {formatMoney(
                                    parseMoney(values[`month_${itemIndex + 1}_amount`]) +
                                      parseMoney(values[`month_${itemIndex + 2}_amount`]) +
                                      parseMoney(values[`month_${itemIndex + 3}_amount`])
                                  )}
                                </td>
                              )}
                              <td>{formatMoney(values[`month_${itemIndex + 1}_amount`])}</td>
                            </Fragment>
                          ))}
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <td>
                        {formatMoney(
                          budgetStore.data.budget_items.reduce(
                            (a, b, currentIndex) =>
                              a +
                              (parseMoney(
                                dd(
                                  fact,
                                  values.kind.value.toString() === "0"
                                    ? "План подразделения (прочее)"
                                    : values.user.name,
                                  currentIndex,
                                  values
                                )
                              ) *
                                parseMoney(values.rate)) /
                                100,
                            0
                          )
                        )}
                      </td>
                      {budgetStore.data.budget_items.map((item, itemIndex) => (
                        <Fragment key={`item=${itemIndex}`}>
                          {itemIndex % 3 === 0 && (
                            <td style={{ verticalAlign: "top", width: "200px" }}>
                              {formatMoney(
                                budgetStore.data.budget_items
                                  .slice(itemIndex, itemIndex + 3)
                                  .reduce(
                                    (a, b, currentIndex) =>
                                      a +
                                      (dd(
                                        fact,
                                        values.kind.value.toString() === "0"
                                          ? "План подразделения (прочее)"
                                          : values.user.name,
                                        itemIndex + currentIndex,
                                        values
                                      ) *
                                        parseMoney(values.rate)) /
                                        100,
                                    0
                                  )
                              )}
                            </td>
                          )}
                          <td>
                            {formatMoney(
                              (parseMoney(
                                dd(
                                  fact,
                                  values.kind.value.toString() === "0"
                                    ? "План подразделения (прочее)"
                                    : values.user.name,
                                  itemIndex,
                                  values
                                )
                              ) *
                                parseMoney(values.rate)) /
                                100
                            )}
                          </td>
                        </Fragment>
                      ))}
                    </Fragment>
                  )}
                </tr>
              ))}
          </tbody>
        )}
        <thead>
          <tr>
            <th colSpan={20}>Мотивация от Тех.реза (План)</th>
          </tr>
          <tr>
            <th rowSpan={2}>#</th>
            <th rowSpan={2}>Мотивация</th>
            <th rowSpan={2}></th>
            <th rowSpan={2}>ИТОГО {budgetStore.data.year}</th>
            <th colSpan={4}>План 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
          </tr>
          <tr>
            <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
            <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
            <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
          </tr>
          <tr>
            <th></th>
            <th>Подразделение/Сотрудник</th>
            <th>Метрики мотивации</th>

            <th id="total2-1"></th>
            <th id="total2-2"></th>
            <th id="total2-3"></th>
            <th id="total2-4"></th>
            <th id="total2-5"></th>
            <th id="total2-6"></th>
            <th id="total2-7"></th>
            <th id="total2-8"></th>
            <th id="total2-9"></th>
            <th id="total2-10"></th>
            <th id="total2-11"></th>
            <th id="total2-12"></th>
            <th id="total2-13"></th>
            <th id="total2-14"></th>
            <th id="total2-15"></th>
            <th id="total2-16"></th>
            <th id="total2-17"></th>
          </tr>
        </thead>
        {budgetMotivationStore.data.length > 0 && (
          <tbody id="data2">
            {budgetMotivationStore.data
              .filter((values) => values.condition.value.toString() !== "1" && values.basis.value.toString() == "1")
              .map((values) => (
                <tr>
                  <td>{values.id}</td>
                  <td>{values.kind.value.toString() === "0" ? budgetStore.data.subdivision.name : values.user.name}</td>
                  <td>
                    {values.periodicity.label}
                    <br />
                    {ffdd(values)}
                    <br />
                    {values.condition.label}
                  </td>
                  {re(values, budgetStore)}
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </div>
  )
}

export default observer(Company)
