import React from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import caclulateDurationInDays from "utils/caclulateDurationInDays"
import addDays from "date-fns/addDays"
import { useFormikContext } from "formik"

const StandardPeriods = ({ disabled = false }) => {
  const {
    values: { date_on },
    setFieldValue
  } = useFormikContext()

  const setFinishOn = (refund_on) => {
    setFieldValue("refund_on", refund_on)
    setFieldValue("duration", caclulateDurationInDays(date_on, refund_on))
  }

  const plusDays = (num) => setFinishOn(addDays(date_on, num))

  return (
    <ButtonGroup>
      <Button variant="success" onClick={() => plusDays(7)} disabled={!date_on || disabled}>
        + 7 дней
      </Button>
      <Button variant="success" onClick={() => plusDays(14)} disabled={!date_on || disabled}>
        + 14 дней
      </Button>
      <Button variant="success" onClick={() => plusDays(21)} disabled={!date_on || disabled}>
        + 21 дней
      </Button>
      <Button variant="success" onClick={() => plusDays(28)} disabled={!date_on || disabled}>
        + 28 дней
      </Button>
    </ButtonGroup>
  )
}

export default StandardPeriods
