import React, { Component } from "react"
import { observer } from "mobx-react"
import BsoStore from "stores/BsoStore/List"
import BsoKindStore from "stores/BsoKindStore/List"
import { Button, Card, Paginate, Modal, ModalHeader, ModalBody, ModalFooter } from "components/UI"
import Table from "./Table"
import Filter from "./Filter"

class BsosModal extends Component {
  bsoStore = BsoStore.create()
  bsoKindStore = BsoKindStore.create()
  bsos = []

  state = {
    bsos: []
  }

  componentDidUpdate(prevProps) {
    // const {
    //   channel: { id: prev_channel_id },
    //   insurer: { id: prev_insurer_id }
    // } = prevProps.values
    // const {
    //   channel: { id: channel_id },
    //   insurer: { id: insurer_id }
    // } = this.props.values
    // if (prev_channel_id !== channel_id || prev_insurer_id !== insurer_id) {
    //   this.bsoStore.fetch({ filter: this.defaultFilter() })
    // }
    // if (prevProps.values.bsos !== this.props.values.bsos) {
    //   this.setState({ bsos: this.props.values.bsos })
    // }
    if (prevProps.isOpen !== this.props.isOpen) {
      this.bsoStore.fetch({ filter: this.defaultFilter() })
      this.bsoKindStore.fetch({ filter: { by_insurer_id: this.props.values.insurer.id } })
    }
  }

  defaultFilter() {
    const {
      channel: { id: channel_id },
      insurer: { id: insurer_id },
      insurance_kind: { id: insurance_kind_id },
    } = this.props.values
    return {
      by_form_status: 0,
      by_channel: { id: channel_id },
      by_insurer: { id: insurer_id },
      by_insurance_kind: { id: insurance_kind_id }
    }
  }

  onSave = () => {
    this.props.setBsos(JSON.parse(JSON.stringify(this.state.bsos)))
    this.closeModal()
  }

  toggleChange = (bso) => {
    const index = this.state.bsos.findIndex((i) => i.id === bso.id)
    if (index > -1) {
      this.setState({
        bsos: this.state.bsos.filter((i) => i.id !== bso.id)
      })
    } else {
      this.setState({
        bsos: this.state.bsos.concat(bso)
      })
    }
  }

  closeModal = () => {
    this.props.setIsOpen(false)
  }

  onFilterSubmit = (values, { props: { store } }) => store.fetch({ filter: { ...this.defaultFilter(), ...values } })

  render() {
    const {
      closeModal,
      onSave,
      toggleChange,
      bsoStore: store,
      props: { isOpen }
    } = this
    const { data } = store

    return (
      <Modal isOpen={isOpen} onRequestClose={closeModal}>
        <ModalHeader>Добавить БСО</ModalHeader>
        <ModalBody>
          <Card>
            <Filter store={store} insurer={this.props.values.insurer} handleSubmit={this.onFilterSubmit} />
          </Card>
          <Table {...{ data: data.toJSON(), toggleChange, bsos: JSON.parse(JSON.stringify(this.state.bsos)) }} />
          <Paginate store={store} params={this.defaultFilter()} saveToStore={false}/>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSave}>Сохранить</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default observer(BsosModal)
