import { types } from "mobx-state-tree"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"

const FilterOptions = types.model("FilterOptions", {
  mols: types.array(newCustomEnum, []),
  statuses: types.array(newCustomEnum, []),
  positions: types.array(customEnum, []),
  subdivisions: types.array(customEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {}
}

export { Meta, metaDefaultValue }
