import axios from "axios"
import Qs from "qs"
import format from "date-fns/format"
import convertErrorsObject from "./interceptors/convertErrorsObject"
import notAuthorized from "./interceptors/notAuthorized"

// dirty hack for axios date serialization
Date.prototype.toISOString = function() {
  return format(this, "yyyy-MM-dd HH:mm:ss")
}

const instance = axios.create({
  baseURL: "/api",
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: "brackets" })
})

instance.interceptors.response.use(...convertErrorsObject)
instance.interceptors.response.use(...notAuthorized)

export default instance
