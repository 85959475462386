import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import PrivateRoute from "components/PrivateRoute"

const AgencyContracts = () => (
  <Switch>
    <PrivateRoute
      path="/agency_contracts/:id/edit"
      component={Edit}
      condition={(currentUser) => currentUser.privileges.includes("agency_contracts")}
    />
    <PrivateRoute
      path="/agency_contracts/new"
      component={Add}
      condition={(currentUser) => currentUser.privileges.includes("agency_contracts")}
    />
    <Route path="/agency_contracts/:id" component={Show} />
    <Route
      path="/agency_contracts"
      component={List}
      // condition={(currentUser) => currentUser.privileges.includes("agency_contracts")}
    />
  </Switch>
)

export default AgencyContracts
