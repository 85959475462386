import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import SubagentStore from "stores/SubagentStore/One"
import ContractStore from "stores/ContractStore/List"
import Breadcrumbs from "pages/Subagents/components/Breadcrumbs"
import Tabs from "pages/Subagents/components/Tabs"
import { Card, Paginate } from "components/UI"
import { BreadcrumbItem } from "components/UI"
import Table from "./components/Table"
import Filter from "./components/Filter"

const subagentStore = SubagentStore.create()
const contractStore = ContractStore.create()

const List = (props) => {
  const { subagent_id } = props.match.params

  useEffect(() => {
    subagentStore.fetch({ id: subagent_id })
    contractStore.fetch({ ...getParams(), filter: { by_subagent_id: subagent_id } })
  }, [])

  // грязный хак, не удалять!
  console.log(contractStore.isFetched)

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/subagents/${subagentStore.id}`}>{subagentStore.name}</BreadcrumbItem>
        <BreadcrumbItem active>Договоры</BreadcrumbItem>
      </Breadcrumbs>
      <Tabs {...{ id: subagent_id }} />
      <br />
      <Card>
        <Filter store={contractStore} defaultFilter={{ by_subagent_id: subagent_id }} />
      </Card>
      <Table store={contractStore} />
      <Paginate store={contractStore} params={{ by_subagent_id: subagent_id }} />
    </Fragment>
  )
}

export default observer(List)
