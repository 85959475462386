import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import PositionStore from "stores/PositionStore/List"
import Breadcrumbs from "pages/Positions/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"

const positionsStore = PositionStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    positionsStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/positions/new">Создать</Button>
      </Card>
      <Table store={positionsStore} />
      <Paginate store={positionsStore} />
    </Fragment>
  )
}

export default observer(List)
