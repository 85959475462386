const prepare = (params = {}) => {
  const {
    insurer: { id: insurer_id },
    insurance_kind: { id: insurance_kind_id },
    bso_batches,
    ...other
  } = params
  return {
    ...other,
    insurer_id,
    insurance_kind_id,
    bso_batches: bso_batches.map((i) => {
      const {
        status: { value: status },
        ...other
      } = i
      return {
        ...other,
        status: parseInt(status)
      }
    })
  }
}

export { prepare }
