import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import ContractStore from "stores/ContractStore/One"
import Tabsss from "pages/Contracts/components/Tabs"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import SubTabsss from "pages/Contracts/components/SubTabs"

const contractStore = ContractStore.create()

const Specification = (props) => {
  const { id: contract_id } = props.match.params

  useEffect(() => {
    contractStore.fetch({ id: contract_id })
  }, [contract_id])

  const {
    id,
    insurance_kind,
    comment,
    contract_properties,
    contract_auto,
    contract_accidents,
    contract_go_transport,
    contract_osgop,
    contract_go_estate
  } = contractStore

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{id}</BreadcrumbItem>
        <BreadcrumbItem active>Реестр застрахованных</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?additional=true`}>
            {contractStore.insurance_kind.id === 14 ? "Аддендум" : "Доп. соглашение"}
          </Button>
        )}
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${id}/edit?prolongation=true`}>Продление</Button>
        )}
        &nbsp;
        <Button url={`/contracts/${id}/edit?clone=true`}>Копировать</Button>
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <SubTabsss store={contractStore} />
      <br />
    </Fragment>
  )
}

export default observer(Specification)
