import React, { useContext, useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import ChannelStore from "stores/ChannelStore/List"
import AuthStoreContext from "contexts/AuthStoreContext"

const channelStore = ChannelStore.create()

const ChannelSelect = ({ name = "channel", required = false, disabled = false, ...props }) => {
  const { values } = useFormikContext()
  const channel = getIn(values, name, {}) || {}

  useEffect(() => {
    channel.id ? channelStore.fetchAround({ id: channel.id }) : channelStore.fetch({ order_by_ordered: "asc" })
  }, [channel.id])

  const getOptions = () => {
    const { channelOptions } = channelStore
    const { id: value, name: label } = getIn(values, name.replace(".id", "")) || { id: null, name: null }
    if (value) {
      return channelOptions.concat([{ label, value }])
    }
    return channelOptions
  }

  const channelOptions = getOptions()

  const loadChannelOptions = (value) =>
    channelStore.fetch({ filter: { by_name: value } }).then(({ options }) => options)

  const { data: currentUser } = useContext(AuthStoreContext)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label="Канал продаж"
        required={required}
        options={channelOptions}
        isSearchable
        loadOptions={loadChannelOptions}
        isDisabled={disabled}
        isOptionDisabled={(option) => ![3, 5, 27, 46, 48, 49, 55].includes(currentUser.id) && option.value == 3}
        {...props}
      />
    </div>
  )
}

export default observer(ChannelSelect)
