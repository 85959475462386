import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import InsuranceProductStore from "stores/InsuranceProductStore/One"
import Breadcrumbs from "pages/Insurers/containers/InsuranceProducts/components/Breadcrumbs"
import Form from "pages/Insurers/containers/InsuranceProducts/components/Form"
import { BreadcrumbItem } from "components/UI"

class Edit extends Component {
  insurerStore = InsurerStore.create()
  insuranceProductStore = InsuranceProductStore.create()

  componentDidMount() {
    const { id, insurer_id } = this.props.match.params
    this.insurerStore.fetch({ id: insurer_id })
    this.insuranceProductStore.fetch({ id, insurer_id })
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) => {
    const { id: insurer_id } = this.insurerStore.data
    return store
      .update({ ...values, insurer_id })
      .then(() => history.push(`/insurers/${insurer_id}/insurance_products`))
      .catch(({ response }) => setErrors(response.data.errors))
  }

  render() {
    const {
      insurerStore: { data: insurer },
      insuranceProductStore: store,
      handleSubmit
    } = this
    return (
      <Fragment>
        <Breadcrumbs {...{ insurer }}>
          <BreadcrumbItem url={`/insurers/${insurer.id}/insurance_products/${store.id}`}>{store.name}</BreadcrumbItem>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit }} />
      </Fragment>
    )
  }
}

export default observer(Edit)
