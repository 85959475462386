import Breadcrumbs from "pages/Insurers/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import InsurerDocumentsStore from "stores/InsurerDocumentStore/One"
import Form from "pages/Insurers/containers/InsurerDocuments/components/Form"
import { BreadcrumbItem } from "components/UI"

const insurerStore = InsurerStore.create()
const insurerScanStore = InsurerDocumentsStore.create()

const Edit = (props) => {
  const { id, insurer_id } = props.match.params
  useEffect(() => {
    insurerStore.fetch({ id: insurer_id })
    insurerScanStore.fetch({ id, insurer_id })
  }, [id, insurer_id])
  console.log(insurerScanStore.isFetched)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{insurerStore.id}</BreadcrumbItem>
        <BreadcrumbItem url={`/insurers/${insurerStore.id}/insurer_documents`}>Документы</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        <BreadcrumbItem active>{insurerScanStore.number}</BreadcrumbItem>
      </Breadcrumbs>
      <Form {...{ store: insurerScanStore, insurerStore }} />
    </Fragment>
  )
}

export default observer(Edit)
