import React from "react"
import { Destroy, Edit } from "components/UI"
import { observer } from "mobx-react"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { Link } from "react-router-dom"

const subdivisionStatus = (status) => {
  switch (status.value) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не действующее" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Действующее" />
  }
}

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Наименование полное</th>
        <th scope="col">Наименование краткое</th>
        <th scope="col">Статус</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map(({ id, name, short_name, status }, index) => (
        <tr key={`subdivisions-${index}`}>
          <td>
            <Link to={`/subdivisions/${id}`}>{name}</Link>
          </td>
          <td>{short_name}</td>
          <td>{subdivisionStatus(status)}</td>
          <td>
            <Edit url={`/subdivisions/${id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
