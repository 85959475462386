import { types } from "mobx-state-tree"

const Organization = types.model("Organization", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  organizationable_id: types.maybeNull(types.integer),
  organizationable_type: types.maybeNull(types.string)
})

export { Organization }
