import {
  RadioButtonGroup,
  RadioInput,
  SelectInput,
  Submit,
  DateInput,
  TextInput,
  MultipleFileInput,
  AddressInput,
  TimeInput
} from "components/UI"
import { withFormik } from "formik"
import UserSelect from "components/Common/UserSelect"
import CourtSelect from "components/Common/CourtSelect"
import instance from "connection/instance"
import { Fragment, useState } from "react"
import InsuranceKindSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsuranceKindSelect"

const Form = ({ handleSubmit, contract_proceeding, ...form }) => {
  const { setFieldValue, setSubmitting } = form
  const [showAdditional, setShowAdditional] = useState(false)

  const onFileInputChange = (e) => {
    setSubmitting(true)
    const formData = new FormData()
    formData.append("data[file]", e.target.files[0])
    formData.append("data[documentable_type]", "ContractTask")
    instance
      .post("/documents", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(({ data }) => {
        setFieldValue("attachments", [...form.values.attachments, data.data])
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onRemove = (id) =>
    setFieldValue(
      "attachments",
      form.values.attachments.filter((i) => i.id.toString() !== id.toString())
    )
  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup label="Статус" name="status.value">
        {[
          { label: "В работе", value: "in_work" },
          { label: "Готово", value: "done" }
        ].map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <TextInput
        name="1"
        label="Договор"
        value={[
          contract_proceeding.side?.value === "plaintiff"
            ? contract_proceeding.plaintiff?.name
            : contract_proceeding.plaintiff_comment,
          contract_proceeding.side?.value === "defendant"
            ? contract_proceeding.defendant?.name
            : contract_proceeding.defendant_comment
        ].join(' / ')}
        disabled
      />
      <InsuranceKindSelect name="insurance_kind" isDisabled />
      <SelectInput
        name="kind"
        label="Тип"
        options={[
          { label: "Суд", value: "session" },
          { label: "Почта", value: "post" },
          { label: "Документ", value: "document" },
          { label: "Звонок", value: "call" },
          { label: "Встреча", value: "meeting" },
          { label: "Эл.письмо", value: "email" }
        ]}
      />
      <TextInput name="subject" label="Тема" required />
      <DateInput name="date_on" label="Дата" required />
      <TimeInput name="time_at" label="Время" />
      <UserSelect name="performer.id" label="Исполнитель задачи" setCurrentUser />
      <div className="form-group">
        <a href="#" onClick={() => setShowAdditional(!showAdditional)}>
          + Добавить локацию, участников, комментарии, документы и пр.
        </a>
      </div>
      {showAdditional && (
        <Fragment>
          <AddressInput name="location" label="Локация" />
          <TextInput name="participant" label="Участники" />
          <CourtSelect name="court.id" />
          <TextInput name="comment" label="Комментарии" />
          <MultipleFileInput name="attachments" onChange={(e) => onFileInputChange(e)} onRemove={onRemove} />
        </Fragment>
      )}
      <TextInput name="result" label="Результат" required={form.values.status.value === "done"} />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ entity, insurance_kind }) => {
    const { kind } = entity
    return { ...entity, kind: kind?.value, insurance_kind: insurance_kind }
  },
  handleSubmit: async (values, formikBag) => {
    try {
      await formikBag.props.handleSubmit(values)
    } catch (error) {
      formikBag.setErrors(error.response.data.errors)
    }
  }
})(Form)
