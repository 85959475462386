import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import { Button, BreadcrumbItem, Card } from "components/UI"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/CompanySummary/components/Tabs"
import instance from "connection/instance"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const budgetStore = BudgetStore.create()

const summator = (nestedArray) => {
  const result = Array(12)
    .fill()
    .map((_, i) => 0)
  nestedArray.forEach((subdivision) => {
    subdivision.forEach((value, index) => {
      result[index] = result[index] + parseMoney(value)
    })
  })
  return result
}

const FinResults = (props) => {
  const { id, budget_id } = props.match.params
  const [showMonths, setShowMonts] = useState(true)
  const [showSubdivisions, setShowSubdivisions] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
  }, [id, budget_id])

  useEffect(() => {
    instance.get(`/budgets/${budget_id}/budget_company_summaries/fin_results_fact`).then(({ data }) => {
      setData(data.data)
    })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>Свод</BreadcrumbItem>
        <BreadcrumbItem active>Фин. рез. (Факт)</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      <div className="row">
        <div className="col-lg-12">
        <div class="form-group">
          <label for="ERAjuorWqK">Вид столбцов</label>
          <fieldset>
            <div class="form-check-inline form-check">
              <input
                class="form-check-input"
                id="show_months_false"
                type="radio"
                name="months"
                value={false}
                checked={showMonths === false}
                onChange={() => setShowMonts(false)}
              />
              <label class="form-check-label" for="show_months_false">
                Свернуть мес.
              </label>
            </div>
            <div class="form-check-inline form-check">
              <input
                class="form-check-input"
                id="show_months_true"
                type="radio"
                name="months"
                value={true}
                checked={showMonths === true}
                onChange={() => setShowMonts(true)}
              />
              <label class="form-check-label" for="show_months_true">
                Развернуть мес.
              </label>
            </div>
          </fieldset>
        </div>
        <div class="form-group">
          <label for="ERAjuorWqK">Вид строк</label>
          <fieldset>
            <div class="form-check-inline form-check">
              <input
                class="form-check-input"
                id="bEADGzWZgi"
                type="radio"
                name="budget.id"
                value={false}
                checked={showSubdivisions === false}
                onChange={() => setShowSubdivisions(false)}
              />
              <label class="form-check-label" for="bEADGzWZgi">
                Свернуть подразделения
              </label>
            </div>
            <div class="form-check-inline form-check">
              <input
                class="form-check-input"
                id="zQVzlyBoiV"
                type="radio"
                name="budget.id"
                value={true}
                checked={showSubdivisions === true}
                onChange={() => setShowSubdivisions(true)}
              />
              <label class="form-check-label" for="zQVzlyBoiV">
                Развернуть подразделения
              </label>
            </div>
          </fieldset>
        </div>
        </div>
      </div>

      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: showMonths ? "4300px" : "1500px" }}>
          <thead style={{ background: "#e5efdc" }}>
            <th style={{ border: "1px solid white" }}>Статья бюджета</th>
            {showSubdivisions && <th style={{ border: "1px solid white" }}>Подр.</th>}
            <th style={{ border: "1px solid white" }}>Год</th>
            <th style={{ border: "1px solid white" }}>1КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>ЯНВ{budgetStore.data.year.toString().slice(2, 4)}</th>
                <th style={{ border: "1px solid white" }}>ФЕВ{budgetStore.data.year.toString().slice(2, 4)}</th>
                <th style={{ border: "1px solid white" }}>МАРТ{budgetStore.data.year.toString().slice(2, 4)}</th>
              </Fragment>
            )}
            <th style={{ border: "1px solid white" }}>2КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>АПР{budgetStore.data.year.toString().slice(2, 4)}</th>
                <th style={{ border: "1px solid white" }}>МАЙ{budgetStore.data.year.toString().slice(2, 4)}</th>
                <th style={{ border: "1px solid white" }}>ИЮНЬ{budgetStore.data.year.toString().slice(2, 4)}</th>
              </Fragment>
            )}
            <th style={{ border: "1px solid white" }}>3КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>ИЮЛЬ{budgetStore.data.year.toString().slice(2, 4)}</th>
                <th style={{ border: "1px solid white" }}>АВГ{budgetStore.data.year.toString().slice(2, 4)}</th>
                <th style={{ border: "1px solid white" }}>СЕН{budgetStore.data.year.toString().slice(2, 4)}</th>
              </Fragment>
            )}
            <th style={{ border: "1px solid white" }}>4КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>ОКТ{budgetStore.data.year.toString().slice(2, 4)}</th>
                <th style={{ border: "1px solid white" }}>НОЯ{budgetStore.data.year.toString().slice(2, 4)}</th>
                <th style={{ border: "1px solid white" }}>ДЕК{budgetStore.data.year.toString().slice(2, 4)}</th>
              </Fragment>
            )}
          </thead>
          <tbody>
            {Object.entries(data).map((i) => (
              <Fragment>
                <tr>
                  <td rowSpan={showSubdivisions && i[1][0]['subdivision_name'] !== null ? i[1].length + 2 : 2}>{i[0]}</td>
                </tr>
                <tr>
                  {showSubdivisions && i[1][0]['subdivision_name'] !== null ? <td>Итого</td> : (showSubdivisions ? <td></td> : null)}
                  <td>
                    {formatMoney(
                      i[1]
                        .map((subdivision) => subdivision.values)
                        .flat()
                        .reduce((a, b) => a + parseMoney(b), 0)
                    )}
                  </td>
                  {summator(i[1].map((subdivision) => subdivision.values)).map((value, index) => (
                    <Fragment>
                      {index % 3 === 0 && (
                        <td>
                          {formatMoney(
                            summator(i[1].map((subdivision) => subdivision.values))
                              .slice(index, index + 3)
                              .reduce((a, b) => a + parseMoney(b), 0)
                          )}
                        </td>
                      )}
                      {showMonths && <td>{formatMoney(value)}</td>}
                    </Fragment>
                  ))}
                </tr>
                {showSubdivisions && i[1][0]['subdivision_name'] !== null &&
                  i[1].map((subdivision) => (
                    <tr>
                      <td>{subdivision.subdivision_name}</td>
                      <td>{formatMoney(subdivision.values.reduce((a, b) => a + parseMoney(b), 0))}</td>
                      {subdivision.values.map((value, index) => (
                        <Fragment>
                          {index % 3 === 0 && (
                            <td>
                              {formatMoney(
                                subdivision.values.slice(index, index + 3).reduce((a, b) => a + parseMoney(b), 0)
                              )}
                            </td>
                          )}
                          {showMonths && <td>{formatMoney(value)}</td>}
                        </Fragment>
                      ))}
                    </tr>
                  ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default observer(FinResults)
