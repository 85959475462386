import React, { Fragment, useContext } from "react"
import { observer } from "mobx-react"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import UserSelect from "components/Common/UserSelect"
import { CheckboxInput, RadioButtonGroup, RadioInput } from "components/UI"
import AuthStoreContext from "contexts/AuthStoreContext"
import RelationSelect from "./components/RelationSelect"
import RelationContract from "./components/RelationContract"

const Specification = ({ values, store }) => {
  const { forms, cash_kinds } = store.filtersOptions
  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <Fragment>
      <RelationSelect name="avrs" />
      <RelationContract name="contracts" />
      <ChannelSelect label="Юр.лицо, по которому проходит операция" />
      <RadioButtonGroup label="Форма расчета" name="form.value">
        {forms.map(({ label, value }) => (
          <RadioInput key={`form_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>

      <div className="form-group">
        <CheckboxInput name="is_selfemployed" label="Самозянятый" />
        <br />
      </div>
      <div className="form-group">
        <CheckboxInput name="is_need_fiscal" label="Требуется фискализация" />
        <br />
      </div>
      <div className="form-group">
        <CheckboxInput name="fiscal" label="Фискализация сделана" />
        <br />
      </div>
      <div className="form-group">
        <RadioButtonGroup label="Аванс.отчет" name="prepayment.value">
          {[
            {
              label: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="red"
                  className="bi bi-x"
                  viewBox="0 0 20 20"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              ),
              value: "0"
            },
            {
              label: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="green"
                  className="bi bi-check"
                  viewBox="0 0 20 20"
                >
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                </svg>
              ),
              value: "1"
            },
            {
              label: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="red"
                  className="bi bi-sign-stop"
                  viewBox="0 0 20 20"
                >
                  <path d="M3.16 10.08c-.931 0-1.447-.493-1.494-1.132h.653c.065.346.396.583.891.583.524 0 .83-.246.83-.62 0-.303-.203-.467-.637-.572l-.656-.164c-.61-.147-.978-.51-.978-1.078 0-.706.597-1.184 1.444-1.184.853 0 1.386.475 1.436 1.087h-.645c-.064-.32-.352-.542-.797-.542-.472 0-.77.246-.77.6 0 .261.196.437.553.522l.654.161c.673.164 1.06.487 1.06 1.11 0 .736-.574 1.228-1.544 1.228Zm3.427-3.51V10h-.665V6.57H4.753V6h3.006v.568H6.587Z" />
                  <path
                    fill-rule="evenodd"
                    d="M11.045 7.73v.544c0 1.131-.636 1.805-1.661 1.805-1.026 0-1.664-.674-1.664-1.805V7.73c0-1.136.638-1.807 1.664-1.807 1.025 0 1.66.674 1.66 1.807Zm-.674.547v-.553c0-.827-.422-1.234-.987-1.234-.572 0-.99.407-.99 1.234v.553c0 .83.418 1.237.99 1.237.565 0 .987-.408.987-1.237Zm1.15-2.276h1.535c.82 0 1.316.55 1.316 1.292 0 .747-.501 1.289-1.321 1.289h-.865V10h-.665V6.001Zm1.436 2.036c.463 0 .735-.272.735-.744s-.272-.741-.735-.741h-.774v1.485h.774Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M4.893 0a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146A.5.5 0 0 0 11.107 0H4.893ZM1 5.1 5.1 1h5.8L15 5.1v5.8L10.9 15H5.1L1 10.9V5.1Z"
                  />
                </svg>
              ),
              value: "2"
            }
          ].map(({ label, value }) => (
            <RadioInput key={`prepayment_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
      </div>
      <RadioButtonGroup label="Вид кассы" name="cash_kind.value" disabled={values.form.value.toString() === "1"}>
        {cash_kinds.map(({ label, value }) => (
          <RadioInput key={`cash_kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <UserSelect name="user.id" label="Кассир" required isDisabled={currentUser.id !== 5} setCurrentUser />
    </Fragment>
  )
}

export default observer(Specification)
