import { types } from "mobx-state-tree"
import customDate from "types/customDate"

const Avr = types.model("Avr", {
  id: types.maybeNull(types.integer),
  date_on: types.maybeNull(customDate),
  number: types.maybeNull(types.string),
  payment_on: types.maybeNull(customDate),
  signed_on: types.maybeNull(customDate),
  status: types.frozen()
})

const ContractInstallment = types.model("ContractInstallment", {
  id: types.maybeNull(types.integer),
  contract: types.frozen(),
  contract_payment_status: types.frozen(),
  fix_kv_cost: types.maybeNull(types.string),
  payment_cost: types.maybeNull(types.string),
  payment_on: types.maybeNull(customDate)
})

const KvCost = types.model("KvCost", {
  id: types.maybeNull(types.integer),
  avr: types.maybeNull(Avr),
  channel: types.frozen(),
  contract_installment: types.maybeNull(ContractInstallment),
  cost: types.maybeNull(types.string),
  kind: types.maybeNull(types.string),
  kv: types.frozen()
})

export { KvCost }
