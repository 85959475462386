import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import Breadcrumbs from "pages/Users/components/Breadcrumbs"
import Form from "pages/Users/components/Form"
import { BreadcrumbItem } from "components/UI"
import UserStore from "stores/UserStore/One"

const userStore = UserStore.create()

const Edit = (props) => {
  const { id } = props.match.params
  useEffect(() => {
    userStore.fetch({ id })
  }, [id])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/users/${userStore.id}`}>{userStore.name}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={userStore} />
    </Fragment>
  )
}

export default observer(Edit)
