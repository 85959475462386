import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import ParcelBorderoStore from "stores/ParcelBorderoStore/One"
import { Button, Card, BreadcrumbItem } from "components/UI"
import formatDate from "utils/formatDate"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import { Link } from "react-router-dom"

const contractStore = ContractStore.create()
const parcel_borderoStore = ParcelBorderoStore.create()

const status = (status) => {
  switch (status) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const Edit = (props) => {
  const { id, contract_id } = props.match.params
  useEffect(() => {
    contractStore.fetch({ id: contract_id })
    parcel_borderoStore.fetch({ id, contract_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contract_id}/parcel_borderos`}>Бордеро</BreadcrumbItem>
        <BreadcrumbItem active>{parcel_borderoStore.number}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${contract_id}/parcel_borderos/${parcel_borderoStore.data.id}/edit`}>
          Редактировать
        </Button>
      </Card>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Общие сведения">
          <br />
          <div className="row">
            <div className="col-12">
              <table className="table">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Статус</td>
                    <td style={{ width: "50%" }}>{status(parcel_borderoStore.data.status.value)}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>№ бордеро</td>
                    <td style={{ width: "50%" }}>{parcel_borderoStore.data.number}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Дата бордеро</td>
                    <td style={{ width: "50%" }}>{formatDate(parcel_borderoStore.data.date_on)}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Отчетный период</td>
                    <td style={{ width: "50%" }}>
                      {formatDate(parcel_borderoStore.data.start_on)} - {formatDate(parcel_borderoStore.data.finish_on)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Комментарий</td>
                    <td style={{ width: "50%" }}>{parcel_borderoStore.data.comment}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table className="table">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Общая страховая сумма</td>
                    <td style={{ width: "50%" }}>
                      {formatMoney(
                        parcel_borderoStore.data.parcels.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Общая страховая премия</td>
                    <td style={{ width: "50%" }}>
                      {formatMoney(
                        parcel_borderoStore.data.parcels.map((i) => parseMoney(i.sp)).reduce((a, b) => a + b, 0)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Общее количество отгрузок</td>
                    <td style={{ width: "50%" }}>{parcel_borderoStore.data.parcels.length}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table className="table">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Создал</td>
                    <td style={{ width: "50%" }}>{parcel_borderoStore.data.creator.name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Дата создания</td>
                    <td style={{ width: "50%" }}>{formatDate(parcel_borderoStore.data.created_at)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Tab>
        <Tab eventKey="parcels" title="Реестр отгрузок">
          <br />
          <table className="table table-hover">
            <thead className="thead-light">
              <tr>
                <th scope="col">№ п/п</th>
                <th scope="col">Дата отправки</th>
                <th scope="col">№ накладной / заказа</th>
                <th scope="col">Адрес отправителя</th>
                <th scope="col">Адрес получателя</th>
                <th scope="col">Страховая сумма</th>
                <th scope="col">Страховая премия</th>
                <th scope="col">Комментарии</th>
              </tr>
            </thead>
            <tbody>
              {parcel_borderoStore.data.parcels.map((parcel, index) => (
                <tr key={`parcel-${index}`}>
                  <td>{parcel.id}</td>
                  <td>{formatDate(parcel.departure_on)}</td>
                  <td>
                    <Link to={`/contracts/${contract_id}/parcels/${parcel.id}`}>{parcel.number}</Link>
                  </td>
                  <td>{parcel.sender_address}</td>
                  <td>{parcel.recipient_address}</td>
                  <td>{parcel.cost}</td>
                  <td>{parcel.sp}</td>
                  <td>{parcel.comment}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colspan="5"></th>
                <th>
                  {formatMoney(
                    parcel_borderoStore.data.parcels.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0)
                  )}
                </th>
                <th colspan="2">
                  {formatMoney(
                    parcel_borderoStore.data.parcels.map((i) => parseMoney(i.sp)).reduce((a, b) => a + b, 0)
                  )}
                </th>
              </tr>
            </tfoot>
          </table>
        </Tab>
      </Tabs>
    </Fragment>
  )
}

export default observer(Edit)
