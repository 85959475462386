import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import DocumentKindStore from "stores/DocumentKindStore/List"
import Breadcrumbs from "pages/DocumentKinds/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"

const document_kindStore = DocumentKindStore.create()

const List = () => {
  useEffect(() => {
    document_kindStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/document_kinds/new">Создать</Button>
      </Card>
      <Table store={document_kindStore} />
      <Paginate store={document_kindStore} />
    </Fragment>
  )
}

export default observer(List)
