import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import BsoKindStore from "stores/BsoKindStore/One"
import Breadcrumbs from "pages/BsoKinds/components/Breadcrumbs"
import formatDate from "utils/formatDate"
import { BreadcrumbItem, Button, Card } from "components/UI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import { Link } from "react-router-dom"

class Show extends Component {
  bso_kindStore = BsoKindStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.bso_kindStore.fetch({ id })
  }

  render() {
    const { bso_kindStore: store } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{store.number}</BreadcrumbItem>
        </Breadcrumbs>
        <Tabs defaultActiveKey="info">
          <Tab eventKey="info" title="Карточка АВР">
            <br />
            <Card>
              <Button url={`/bso_kinds/${store.data.id}/edit`}>Редактировать</Button>&nbsp;
              <Button url={`#`}>Печать</Button>&nbsp;
            </Card>
            <div className="row">
              <div className="col-12">
                <table className="table">
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>Страховая компания</td>
                      <td style={{ width: "50%" }}>{store.insurer.name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Канал продаж</td>
                      <td style={{ width: "50%" }}>{store.channel.name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Создал</td>
                      <td style={{ width: "50%" }}>{store.user.full_name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Дата создания</td>
                      <td style={{ width: "50%" }}>{store.created_at}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Период отчета с</td>
                      <td style={{ width: "50%" }}>{store.start_on}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Период отчета по</td>
                      <td style={{ width: "50%" }}>{store.finish_on}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>№ АВР</td>
                      <td style={{ width: "50%" }}>{store.number}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Дата АВР</td>
                      <td style={{ width: "50%" }}>{store.date_on}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>КВ по удержанию</td>
                      <td style={{ width: "50%" }}>{store.kv_by_hold.label}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Кто формирует АВР</td>
                      <td style={{ width: "50%" }}>{store.former.label}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Tab>
          <Tab eventKey="registry" title="Реестр договоров">
            <br />
            <table className="table">
              <thead>
                <tr>
                  <th>№ п/п</th>
                  <th>Номер договора</th>
                  <th>Страхователь</th>
                  <th>Дата оплаты премии страхователем</th>
                  <th>Сумма оплаченной премии страхователем</th>
                  <th>Ставка вознаграждения, %</th>
                  <th>Сумма вознаграждения, руб.</th>
                </tr>
              </thead>
              <tbody>
                {store.contract_installments.map(({ id, contract, payment_on, payment_cost, kv_by_paid_sp }, index) => (
                  <tr key={`contract_installment_${id}`}>
                    <td>{++index}</td>
                    <td>
                      <Link to={`/contracts/${contract.id}`}>{contract.number}</Link>
                    </td>
                    <td>
                      <Link to={`/clients/${contract.client.id}`}>{contract.client.name}</Link>
                    </td>
                    <td>{formatDate(payment_on)}</td>
                    <td>{payment_cost}</td>
                    <td>{contract[0].rate}</td>
                    <td>{kv_by_paid_sp}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan="4">Итого</th>
                  <th>
                    {formatMoney(
                      store.contract_installments
                        .map((i) => parseMoney(i.payment_cost))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2)
                    )}
                  </th>
                  <th>&nbsp;</th>
                  <th>
                    {formatMoney(
                      store.contract_installments
                        .map((i) => parseMoney(i.kv_by_paid_sp))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2)
                    )}
                  </th>
                </tr>
              </tfoot>
            </table>
          </Tab>
          <Tab eventKey="backoffice" title="Бэк-офис">
            <br />
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Дата формирования АВР / дата передачи АВР в СИ-НЕРДЖИ</td>
                  <td style={{ width: "50%" }}>{store.former_on}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Дата передачи в СК подписанного АВР</td>
                  <td style={{ width: "50%" }}>{store.transfer_on}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Контрольная дата оплаты АВР СК</td>
                  <td style={{ width: "50%" }}>{store.payment_on}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Бумажный АВР или ЭЦП</td>
                  <td style={{ width: "50%" }}>{store.kind.label}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Дата получения оригинала подписанного АВР из СК</td>
                  <td style={{ width: "50%" }}>{store.original_receipt_on}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Фактическая дата оплаты АВР</td>
                  <td style={{ width: "50%" }}>{store.actual_payment_on}</td>
                </tr>
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </Fragment>
    )
  }
}

export default observer(Show)
