import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import ShipmentStore from "stores/ShipmentStore/One"
import Form from "pages/Contracts/containers/Shipments/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"

const contractStore = ContractStore.create()
const shipmentStore = ShipmentStore.create()

const Add = (props) => {
  const { contract_id } = props.match.params

  const [number, setNumber] = useState("")

  useEffect(() => {
    contractStore.fetch({ id: contract_id }).then(({ data }) => {
      setNumber(`${data.number}/${data.shipments.length + 1}`)
    })
  }, [])

  shipmentStore.applyData({
    status: 200,
    data: {
      data: {
        ...{
          cargo_prefabricated: { id: 0 },
          cargos: [],
          cargo_route: null,
          cargo_shipment_on: null,
          cargo_combined: { id: 0 },
          transport_kinds: [],
          comment: null,
          currency: { value: 0 },
          documents: []
        },
        number: number
      }
    }
  })

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contract_id}/shipments`}>Отгрузки</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <ContractFormContext.Provider value={{ contractStore }}>
        <Form {...{ store: shipmentStore, contractStore }} />
      </ContractFormContext.Provider>
    </Fragment>
  )
}

export default observer(Add)
