import React from "react"
import { Tab, Tabs } from "components/UI"
import { observer } from "mobx-react"

const TabsBlock = ({ store }) => {
  const { id } = store.data
  const mapping = {
    [`/budgets/${id}/budget_motivations`]: "Реестр",
    [`/budgets/${id}/budget_motivations/plan`]: "План",
    [`/budgets/${id}/budget_motivations/accrued`]: "Начисленная",
    [`/budgets/${id}/budget_motivations/to_payoff`]: "К выплате",
    [`/budgets/${id}/budget_motivations/paid`]: "Выплачено",
  }
  return (
    <Tabs>
      {Object.entries(mapping).map((i) => {
        const active = window.location.pathname === i[0]
        return (
          <Tab key={i[0]} active={active} to={i[0]}>
            {i[1]}
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default observer(TabsBlock)
