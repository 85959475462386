import React from "react"
import { Route, Switch } from "react-router-dom"
import List from "./containers/List"
import CheckPermissions from "components/CheckPermissions"
import { observer } from "mobx-react"

const Debits = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("debits")}>
    <Switch>
      <Route path="/debits" component={List} />
    </Switch>
  </CheckPermissions>
)

export default observer(Debits)
