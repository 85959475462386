import React, { useState } from "react"
import { observer } from "mobx-react"
import { useFormikContext } from "formik"
import { Link } from "react-router-dom"
import { Button } from "components/UI"
import Modal from "./Modal"

const ParentSelect = ({ disabled = false, client }) => {
  const {
    values: { number, parent },
    setFieldValue
  } = useFormikContext()

  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const onDestroy = () => {
    setFieldValue("parent", { id: null, number: null })
  }
  return (
    <div className="form-group">
      <label>
        Родительский договор:&nbsp;
        {parent.id ? (
          <Link target="_blank" to={`/contracts/${parent.id}`}>
            {parent.number}
          </Link>
        ) : (
          "не выбран"
        )}
      </label>
      <fieldset>
        <Button onClick={openModal} disabled={disabled}>
          Выбрать
        </Button>
        &nbsp;
        <Button onClick={onDestroy} disabled={disabled} variant="danger">
          Удалить
        </Button>
      </fieldset>
      <Modal isOpen={isOpen} closeModal={closeModal} setFieldValue={setFieldValue} client={client} number={number} />
    </div>
  )
}

export default observer(ParentSelect)
