import { types } from "mobx-state-tree"

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Organization = types.model("Organization", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  organizationable_id: types.maybeNull(types.integer),
  organizationable_type: types.maybeNull(types.string)
})

const Destination = types.model("Destination", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  organization: types.maybeNull(Organization),
  // organizationable_type: types.maybeNull(types.string),
  // organizationable_id: types.maybeNull(types.integer),
  contact: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  creator: types.maybeNull(User)
})

export { Destination }
