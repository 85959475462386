import objectToFormData from "object-to-formdata"

const prepare = (params = {}) => {
  const {
    name,
    ownership_kind: { value: ownership_kind },
    legal_support: { value: legal_support },
    contacts,
    user,
    user: { id: user_id },
    subdivision: { id: subdivision_id },
    holding: { id: holding_id },
    industry: { id: industry_id },
    strength: { id: strength_id },
    created_at,
    ...other
  } = params
  return {
    ...other,
    ownership_kind: parseInt(ownership_kind),
    legal_support: parseInt(legal_support),
    user_id,
    subdivision_id,
    holding_id,
    industry_id,
    strength_id,
    contacts: contacts.map((i) => {
      const { insurance_kinds: insurance_kinds, ...other } = i
      return {
        ...other,
        insurance_kind_ids: insurance_kinds.filter((i) => !i._destroy).map((i) => i.id)
      }
    })
  }
}

export { prepare }
