import React, { Fragment, useState } from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import Documents from "./../Documents"
import { Button, Card } from "components/UI"

const FireAtWillTab = ({ store }) => {
  const { data } = store

  const [disabled, setDisabled] = useState(false)

  const onExportButtonHandler = () => {
    // console.log(values)
    setDisabled(true)
    store.export({ id: store.id }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "order.xlsx")
      document.body.appendChild(link)
      link.click()
      setDisabled(false)
    })
  }
  return (
    <div className="row">
      <div className="col-12">
        <Card>
          <Button onClick={onExportButtonHandler} disabled={disabled}>
            {disabled && (
              <Fragment>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
              </Fragment>
            )}
            Экспорт в .xlsx
          </Button>
        </Card>
        <table className="table">
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>Дата увольнения</td>
              <td style={{ width: "50%" }}>{formatDate(data.order_fire_at_will.date_on)}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Основание расторжения трудового договора (увольнения)</td>
              <td style={{ width: "50%" }}>{data.order_fire_at_will.reason}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Основание (документ)</td>
              <td style={{ width: "50%" }}>{data.order_fire_at_will.reason_document}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Комментарии</td>
              <td style={{ width: "50%" }}>{data.order_fire_at_will.comment}</td>
            </tr>
          </tbody>
        </table>
        <Documents store={store} />
      </div>
    </div>
  )
}

export default observer(FireAtWillTab)
