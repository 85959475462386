import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import Form from "pages/Budgets/components/Form"
import { BreadcrumbItem } from "components/UI"

const budgetStore = BudgetStore.create()

const Edit = (props) => {
  useEffect(() => {
    const { id } = props.match.params
    budgetStore.fetch({ id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>{budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={budgetStore} />
    </Fragment>
  )
}

export default observer(Edit)
