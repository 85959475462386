import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import SubagentStore from "stores/SubagentStore/List"

const subagentStore = SubagentStore.create()

const SubagentSelect = ({ name = "subagent", required = false, label = "Субагент", ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    subagentStore.search()
  }, [])

  const getOptions = () => {
    const { subagentOptions } = subagentStore
    const { id: value, name: label } = getIn(values, name.replace(/\.id$/, "")) || { id: null, name: null }
    if (value) {
      return subagentOptions.concat([{ label, value }])
    }
    return subagentOptions
  }

  const subagentOptions = getOptions()

  const loadSubagentOptions = (value) =>
    subagentStore.search({ filter: { by_name: value }, limit: 0 }).then(({ subagentOptions }) => subagentOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={subagentOptions}
        required={required}
        isSearchable
        loadOptions={loadSubagentOptions}
        {...props}
      />
    </div>
  )
}

export default observer(SubagentSelect)
