import React, { Fragment, useContext } from "react"
import { Button, CurrencyInput, PercentageInput, TextInput, RadioButtonGroup, RadioInput } from "components/UI"
import parseMoney from "utils/parseMoney"
import ContractFormContext from "contexts/ContractFormContext"

const Cargo = ({ index, cargo, h: { replace, remove }, form, name }) => {
  const { contractStore } = useContext(ContractFormContext)
  const { cargoCurrenciesOptions } = contractStore.filtersOptions

  if (cargo._destroy) return null

  const caclulateCargoTotalCost = (cargos, currentValue) => {
    const cargo_total_cost = cargos
      .filter((i, _index) => _index !== index)
      .reduce((a, b) => a + parseMoney(b.cost), parseMoney(currentValue))
      .toFixed(2)
    form.setFieldValue("cargo_total_cost", cargo_total_cost)
  }

  const onDestroyClick = () => {
    cargo.id ? replace(index, { ...cargo, _destroy: true }) : remove(index)
    caclulateCargoTotalCost(form.values.cargos, 0)
  }

  return (
    <Fragment>
      <TextInput name={`${name}.${index}.name`} label="Наименование груза" />
      <RadioButtonGroup label="Валюта договора" name={`${name}.${index}.currency.value`}>
        {cargoCurrenciesOptions.map(({ label, value }) => (
          <RadioInput key={`cargo_currency_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <CurrencyInput
        name={`${name}.${index}.cost`}
        label="Страховая сумма/Лимит ответственности"
        onChange={(e) => {
          form.setFieldValue(`${name}.${index}.cost`, e.target.value)
          caclulateCargoTotalCost(form.values.cargos, e.target.value)
        }}
      />
      {form.values.cargo_kind.id.toString() === "1" && (
        <TextInput name={`${name}.${index}.condition`} label="Условия страхования" />
      )}
      <CurrencyInput name={`${name}.${index}.franchise`} label="Франшиза" />
      {form.values.cargo_kind.id.toString() === "1" && (
        <PercentageInput name={`${name}.${index}.tariff`} label="Тарифная ставка" />
      )}
      <Button variant="danger" onClick={onDestroyClick}>
        Удалить груз
      </Button>
      <hr />
    </Fragment>
  )
}
export default Cargo
