import Select from "react-select"
import React from "react"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn, ErrorMessage } from "formik"
import { observer } from "mobx-react"

const SelectInput = ({
  name,
  label,
  options,
  selectedOption,
  required = false,
  withEmptyValue = false,
  isClearable = false,
  ...props
}) => {
  const id = idGenerator()
  const { errors, values, handleBlur, setFieldValue } = props.formik
  const error = getIn(errors, name.replace(".id", "_id").replace(".value", ""), false)
  const value = getIn(values, name, false)
  const className = classnames("react-select", { "is-invalid": error })
  const _value = options.find((option) => String(option.value).toString() === String(value).toString())

  const errorStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#e74a3b"
    })
  }

  if (withEmptyValue) {
    options = [{ value: "", label: "" }].concat(options)
  }

  return (
    <div className={classnames("form-group", { required: required })}>
      <label htmlFor={id}>{label}</label>
      <Select
        classNamePrefix={withEmptyValue && "input-field-select"}
        {...{ className, id, name, ...props }}
        escapeClearsValue={false}
        noOptionsMessage={() => "Нет элементов для выбора"}
        onBlur={handleBlur}
        onChange={(option) => setFieldValue(name, option?.value)}
        options={options}
        placeholder="Выберите элементы"
        value={_value || ""}
        isClearable={isClearable}
        clearValue={(i) => console.log(i)}
        styles={error && errorStyles}
        {...props}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

export default compose(observer, connect)(SelectInput)
