import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import { withRouter } from "react-router-dom"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"
import sortBy from "lodash.sortby"

const status = (status) => {
  switch (status) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const cost = (i) => {
  if (i.rate_kind.value.toString() === "0") {
    return i.rate
  }
  if (i.periodicity.value.toString() === "0") {
    return i.amount
  }
  if (i.periodicity.value.toString() === "1") {
    return formatMoney(
      parseFloat(i.quarter_1_amount) +
        parseFloat(i.quarter_2_amount) +
        parseFloat(i.quarter_3_amount) +
        parseFloat(i.quarter_4_amount)
    )
  }
  return formatMoney(
    parseFloat(i.month_1_amount) +
      parseFloat(i.month_2_amount) +
      parseFloat(i.month_3_amount) +
      parseFloat(i.month_4_amount) +
      parseFloat(i.month_5_amount) +
      parseFloat(i.month_6_amount) +
      parseFloat(i.month_7_amount) +
      parseFloat(i.month_8_amount) +
      parseFloat(i.month_9_amount) +
      parseFloat(i.month_10_amount) +
      parseFloat(i.month_11_amount) +
      parseFloat(i.month_12_amount)
  )
}

const ff = (i) => {
  if (i.basis.value.toString() === "0") {
    if (i.user_sale_plan_kind.value.toString() === "0") {
      return "Подразделение (итого)"
    }
    return i.user_sale_plan.name
  }
  return "-"
}

const Table = ({ history, store, budgetStore }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Статус</th>
        <th scope="col">Подразделение / Сотрудник</th>
        <th scope="col">Периодичность выплат</th>
        <th scope="col">Базис</th>
        <th scope="col">План продаж</th>
        <th scope="col">Условия</th>
        <th scope="col">Ставка / Сумма</th>
        <th scope="col">Действует с… по …</th>
        <th scope="col">Комментарии</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {sortBy(store.data, (i) => i.kind.value.toString() === "0" ? budgetStore.data.subdivision.name : i.user.name).map((i) => (
        <tr>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivations/${i.id}`)}>{i.id}</td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivations/${i.id}`)}>
            {status(i.status.value)}
          </td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivations/${i.id}`)}>
            {i.kind.value.toString() === "0" ? budgetStore.data.subdivision.name : i.user.name}
          </td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivations/${i.id}`)}>
            {i.periodicity.label}
          </td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivations/${i.id}`)}>{i.basis.label}</td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivations/${i.id}`)}>{ff(i)}</td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivations/${i.id}`)}>
            {i.condition.label}
          </td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivations/${i.id}`)}>{cost(i)}</td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivations/${i.id}`)}>
            {formatDate(i.start_on)} - {formatDate(i.finish_on)}
          </td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivations/${i.id}`)}>{i.comment}</td>
          <td>
            <Edit url={`/budgets/${i.budget_id}/budget_motivations/${i.id}/edit`} />
            <Destroy
              action={() => afterDestroyFetch(store, { id: i.id, budget_id: i.budget_id }, { budget_id: i.budget_id })}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
