import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const LossDocument = types.model("LossDocument", {
  id: types.maybeNull(types.integer),
  attachments: types.maybeNull(types.array(Attachment))
})

const Client = types.model("Client", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Contact = types.model("Contact", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  number: types.maybeNull(types.string),
  client: types.maybeNull(Client),
  insurer: types.maybeNull(Insurer),
  insurance_kind: types.maybeNull(InsuranceKind)
})

const Loss = types.model("Loss", {
  id: types.maybeNull(types.integer),
  contract_id: types.maybeNull(types.integer),
  status: types.maybeNull(newCustomEnum),
  status_updated_on: types.maybeNull(customDate),
  date_on: types.maybeNull(customDate),
  statement_on: types.maybeNull(customDate),
  description: types.maybeNull(types.string),
  reserve: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  responsible: types.maybeNull(User),
  executor: types.maybeNull(User),
  result: types.maybeNull(newCustomEnum),
  form: types.maybeNull(newCustomEnum),
  payout: types.maybeNull(types.string),
  documents: types.maybeNull(types.array(LossDocument)),
  contract: types.maybeNull(Contract),
  client_contact: types.maybeNull(Contact),
  insurer_contact: types.maybeNull(Contact),
})

export { Loss }
