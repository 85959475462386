import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import ProviderStore from "stores/ProviderStore/List"

const providerStore = ProviderStore.create()

const ProviderSelect = ({ name = "provider", required = false, label = "Поставщик", activity_kind_id = null, isMulti = false, ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    providerStore.fetch({ filter: { by_activity_kind_id: activity_kind_id, order_by_name: "asc" } })
  }, [activity_kind_id])

  const getOptions = () => {
    const { providerOptions } = providerStore
    const { id: value, name: label } = getIn(values, name.replace(/\.id$/, "")) || { id: null, name: null }
    if (value && !providerOptions.find(i => i.value === value)) {
      return providerOptions.concat([{ label, value }])
    }
    return providerOptions
  }

  const providerOptions = getOptions()

  const loadProviderOptions = (value) =>
    providerStore
      .fetch({ filter: { by_name: value, by_activity_kind_id: activity_kind_id, order_by_name: "asc" }, limit: 0 })
      .then(({ providerOptions }) => providerOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label={label}
        required={required}
        options={providerOptions}
        isSearchable
        isMulti={isMulti}
        loadOptions={loadProviderOptions}
        {...props}
      />
    </div>
  )
}

export default observer(ProviderSelect)
