import React, { Fragment, useContext } from "react"
import { DateInput, TextInput, RadioButtonGroup, RadioInput, SelectInput, Submit } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"
import DocumentKindSelect from "components/Common/DocumentKindSelect"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { useFormikContext, getIn, withFormik } from "formik"
import { withRouter } from "react-router"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ store, values, handleSubmit, setFieldValue }) => {
  const { contractStore } = useContext(ContractFormContext)
  const { kinds, submissions } = store.filtersOptions

  return (
    <form onSubmit={handleSubmit}>
      <DocumentKindSelect name="document_kind.id" ids={[4, 6, 19, 18]} />
      {String(getIn(values, "document_kind.id")) === "18" && (
        <TextInput name="document_kind_other" label="Укажите тип документа" />
      )}
      {String(getIn(contractStore.data, "document_kind.id")) === "6" && (
        <SelectInput
          name="version_id"
          label="№ Доп. соглашения / Аддендума"
          options={contractStore.versions.map((i) => {
            return {
              label: i.additional_agreement_number,
              value: i.id
            }
          })}
        />
      )}
      <RadioButtonGroup label="Вид документа" name="kind.value">
        {kinds.map(({ label, value }) => (
          <RadioInput
            key={`kind_${value}`}
            label={label}
            id={value}
            onChange={(e) => {
              setFieldValue("kind.value", e.target.value)
              if (parseInt(e.target.value) === 2 && parseInt(values.submission.value) === 0) {
                setFieldValue("submission_holder", contractStore.insurer.name)
              }
            }}
          />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Геолокация документа" name="submission.value">
        {submissions.map(({ label, value }) => (
          <RadioInput
            key={`submission_${value}`}
            label={label}
            id={value}
            onChange={(e) => {
              setFieldValue("submission.value", e.target.value)
              if (parseInt(e.target.value) === 0 && parseInt(values.kind.value) === 2) {
                setFieldValue("submission_holder", contractStore.insurer.name)
              }
            }}
          />
        ))}
      </RadioButtonGroup>
      {getIn(values, "submission.value").toString() === "2" && <TextInput name="submission_other" label="Иное" />}
      <TextInput name="submission_holder" label="Кому сдан документ" />
      <DateInput name="submission_on" label="Дата сдачи документа" />
      <TextInput name="comment" label="Комментарий" />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: contract_id } = formikBag.props.contractStore.data
      formSubmitHandler(
        { ...values, contract_id: contract_id },
        formikBag,
        `/contracts/${contract_id}/contract_documents`
      )
    }
  }),
  observer
)(Form)
