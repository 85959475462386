import React, { Fragment, useContext } from "react"
import { DateInput, TextInput, RadioButtonGroup, RadioInput } from "components/UI"
import Attachments from "./Attachments"
import ContractFormContext from "contexts/ContractFormContext"
import { useFormikContext, getIn } from "formik"
import DocumentKindSelect from "components/Common/DocumentKindSelect"

const Scan = ({ prefix = null }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const { contractStore } = useContext(ContractFormContext)
  const { scanKinds } = contractStore.filtersOptions
  const { values } = useFormikContext()
  return (
    <Fragment>
      <DocumentKindSelect name={path("document_kind.id")} />
      {String(getIn(values, path("document_kind.id"))) === "18" && (
        <TextInput name={path("document_kind_other")} label="Иное" />
      )}
      <RadioButtonGroup label="Вид документа" name={path("kind.value")}>
        {scanKinds.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <TextInput name={path("comment")} label="Комментарий" />
      <Attachments name={path("attachments")} />
    </Fragment>
  )
}
export default Scan
