import React from "react"
import { MultipleFileInput, TextInput, RadioButtonGroup, RadioInput, Submit } from "components/UI"
import DocumentKindSelect from "components/Common/DocumentKindSelect"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { getIn, withFormik } from "formik"
import { withRouter } from "react-router"
import formSubmitHandler from "utils/formSubmitHandler"
import instance from "connection/instance"
import { FieldArray } from "formik"

const Form = ({ store, values, handleSubmit, setFieldValue, setSubmitting }) => {
  const { kinds } = store.filtersOptions

  const onFileInputChange = (e, push) => {
    setSubmitting(true)
    const formData = new FormData()
    formData.append("data[file]", e.target.files[0])
    formData.append("data[documentable_type]", "InsurerDocument")
    instance
      .post("/documents", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(({ data }) => {
        push(data.data)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onRemove = (id) =>
    setFieldValue(
      "attachments",
      values.attachments.filter((i) => i.id.toString() !== id.toString())
    )

  return (
    <form onSubmit={handleSubmit}>
      <DocumentKindSelect name="document_kind.id" ids={[4, 26, 6, 27, 28, 29, 30, 31, 32, 18]} />
      {String(getIn(values, "document_kind.id")) === "18" && (
        <TextInput name="document_kind_other" label="Укажите тип документа" />
      )}
      <TextInput name="comment" label="Комментарий" />
      <FieldArray
        name="attachments"
        render={({ push }) => (
          <MultipleFileInput
            name="attachments"
            onChange={(e) => onFileInputChange(e, push)}
            onRemove={onRemove}
          />
        )}
      />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: insurer_id } = formikBag.props.insurerStore.data
      formSubmitHandler({ ...values, insurer_id: insurer_id }, formikBag, `/insurers/${insurer_id}/insurer_documents`)
    }
  }),
  observer
)(Form)
