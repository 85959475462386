import React, { Component, Fragment } from "react"
import afterDestroyFetch from "utils/afterDestroyFetch"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import ProviderStore from "stores/ProviderStore/One"
import ContactStore from "stores/ContactStore/List"
import Breadcrumbs from "pages/Providers/components/Breadcrumbs"
import Tabs from "pages/Providers/components/Tabs"
import { Button, Card, Paginate } from "components/UI"
import { BreadcrumbItem } from "components/UI"
import Table from "./components/Table"

class List extends Component {
  providerStore = ProviderStore.create()
  contactStore = ContactStore.create()

  componentDidMount() {
    const { provider_id } = this.props.match.params
    this.providerStore.fetch({ id: provider_id })
    this.contactStore.fetch({ ...getParams(), provider_id })
  }

  onDestroyClick = (id) => {
    const { id: provider_id } = this.providerStore
    afterDestroyFetch(this.contactStore, { id, provider_id }, { provider_id })
  }

  onPageChange = (params) => {
    const { id: provider_id } = this.providerStore
    this.contactStore.fetch({ ...params, provider_id })
  }

  render() {
    const {
      props: {
        match: {
          params: { provider_id }
        }
      },
      onDestroyClick,
      providerStore,
      contactStore
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem url={`/providers/${providerStore.id}`}>{providerStore.name}</BreadcrumbItem>
          <BreadcrumbItem active>Контактные лица</BreadcrumbItem>
        </Breadcrumbs>
        <Tabs store={providerStore} />
        <br />
        <Card>
          <Button url={`/providers/${provider_id}/contacts/new`}>Создать</Button>
        </Card>
        <Table {...{ data: contactStore.data.toJSON(), provider_id, onDestroyClick }} />
        <Paginate store={contactStore} />
      </Fragment>
    )
  }
}

export default observer(List)
