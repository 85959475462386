import { types } from "mobx-state-tree"

const Email = types.model("Email", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const Phone = types.model("Phone", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const Contact = types.model("Contact", {
  id: types.maybeNull(types.integer),
  l_name: types.maybeNull(types.string),
  f_name: types.maybeNull(types.string),
  p_name: types.maybeNull(types.string),
  emails: types.maybeNull(types.array(Email)),
  phones: types.maybeNull(types.array(Phone))
})

const Country = types.model("Country", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Subagent = types.model("Subagent", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  organization_name: types.maybeNull(types.string),
  ownership_kind: types.maybeNull(types.string),
  kind_of_action: types.maybeNull(types.string),
  inn: types.maybeNull(types.string),
  director: types.maybeNull(types.string),
  kpp: types.maybeNull(types.string),
  ogrn: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  legal_address: types.maybeNull(types.string),
  bank: types.maybeNull(types.string),
  payment_account: types.maybeNull(types.string),
  bik: types.maybeNull(types.string),
  correspondent_account: types.maybeNull(types.string),

  f_name: types.maybeNull(types.string),
  l_name: types.maybeNull(types.string),
  p_name: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  emails: types.maybeNull(types.array(Email)),
  phones: types.maybeNull(types.array(Phone)),
  contacts: types.maybeNull(types.array(Contact))
})

export { Subagent }
