import React, { useContext } from "react"
import { Destroy, Edit } from "components/UI"
import formatDateTime from "utils/formatDateTime"
import formatMoney from "utils/formatMoney"
import { withRouter } from "react-router-dom"
import AuthStoreContext from "contexts/AuthStoreContext"

const Table = ({ collection, destroyPaymentPlanWithFetch, history }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Статус</th>
          <th scope="col">Форма расчета</th>
          <th scope="col">Кассир</th>
          <th scope="col">Сумма итого</th>
          <th scope="col">Дата создания</th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {collection.data.map((i, index) => (
          <tr key={`paymentPlans-${index}`}>
            <td onClick={() => history.push(`/payment_plans/${i.id}`)}>{i.id}</td>
            <td
              onClick={() => history.push(`/payment_plans/${i.id}`)}
              style={{ color: i.status.value == "rejected" && "red" }}
            >
              {i.status.label}
            </td>
            <td onClick={() => history.push(`/payment_plans/${i.id}`)}>{i.form.label}</td>
            <td onClick={() => history.push(`/payment_plans/${i.id}`)}>{i.cashier.name}</td>
            <td onClick={() => history.push(`/payment_plans/${i.id}`)}>{formatMoney(i.payment)}</td>
            <td onClick={() => history.push(`/payment_plans/${i.id}`)}>{formatDateTime(i.created_at)}</td>
            <td>
              <Edit
                url={`/payment_plans/${i.id}/edit`}
                disabled={
                  i.status.value === "released" ||
                  i.status.value === "payment" ||
                  (i.status.value === "coordination" && currentUser.id !== 5)
                }
              />
              <Destroy
                action={() => destroyPaymentPlanWithFetch(i.id)}
                disabled={
                  i.status.value === "released" || i.status.value === "payment" || i.status.value === "coordination"
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default withRouter(Table)
