import React, { Fragment, useEffect, useContext } from "react"
import { observer } from "mobx-react"
import AgencyContractStore from "stores/AgencyContractStore/One"
import Breadcrumbs from "pages/AgencyContracts/components/Breadcrumbs"
import Form from "pages/AgencyContracts/components/Form"
import { BreadcrumbItem } from "components/UI"
import AuthStoreContext from "contexts/AuthStoreContext"

const Add = () => {
  const agencyContractStore = AgencyContractStore.create()
  const { data: currentUser } = useContext(AuthStoreContext)

  useEffect(() => {
    agencyContractStore.fetchMeta()
  }, [])

  if (currentUser.subdivision.id.toString() === "7" || currentUser.id.toString() == "48") {
    agencyContractStore.applyData({
      status: 200,
      data: {
        data: {
          ...agencyContractStore.data,
          cash_kind: { value: 1 }
        }
      }
    })
  }

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={agencyContractStore} />
    </Fragment>
  )
}

export default observer(Add)
