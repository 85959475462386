import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import { withRouter } from "react-router-dom"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const status = (status) => {
  switch (status) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const Table = ({ history, store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Статус</th>
        <th scope="col">Базиз расчета</th>
        <th scope="col">Подразделения</th>
        <th scope="col">Комментарии</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((i) => (
        <tr>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivation_coefficients/${i.id}`)}>{i.id}</td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivation_coefficients/${i.id}`)}>
            {status(i.status.value)}
          </td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivation_coefficients/${i.id}`)}>
            {i.basis.label}
          </td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivation_coefficients/${i.id}`)}>
            {i.subdivisions.map((i) => i.name).join(", ")}
          </td>
          <td onClick={() => history.push(`/budgets/${i.budget_id}/budget_motivation_coefficients/${i.id}`)}>
            {i.comment}
          </td>
          <td>
            <Edit url={`/budgets/${i.budget_id}/budget_motivation_coefficients/${i.id}/edit`} />
            <Destroy
              action={() => afterDestroyFetch(store, { id: i.id, budget_id: i.budget_id }, { budget_id: i.budget_id })}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
