import React, { useContext } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { AsyncSelectInput, DateInput, RadioButtonGroup, RadioInput, Submit, TextInput } from "components/UI"
import ContractInstallments from "./ContractInstallments"
import ContractReturnments from "./ContractReturnments"
import FixKvs from "./FixKvs"
import InsurerContactSelect from "./InsurerContactSelect"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import AvrInstallments from "./AvrInstallments"
import Documents from "./Documents"
import Relations from "./Relations"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import formatDate from "utils/formatDate"
import addDays from "date-fns/addDays"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import formSubmitHandler from "utils/formSubmitHandler"

const Form = ({ handleSubmit, values, store, insurerStore, channelStore, setFieldValue }) => {
  const { kinds, kv_by_holds, formers, statuses, payment_kinds } = store.filtersOptions

  const insurerOptions = (() => {
    const { id, name } = values.insurer
    return id ? [{ label: name, value: id }] : insurerStore.insurerOptions
  })()

  const loadInsurerOptions = (input) =>
    insurerStore
      .fetch({ order_by_ordered: "asc", filter: { by_name: input }, limit: 0 })
      .then(({ insurerOptions }) => insurerOptions)

  const setKvCosts = (kv_costs) =>
    setFieldValue("kv_costs", values.kv_costs.filter((i) => i.kind === "fix_kv").concat(kv_costs))
  const setKvCostReturnments = (contract_installments) => setFieldValue("kv_cost_returnments", contract_installments)
  const setFixKvCosts = (kv_costs) =>
    setFieldValue("kv_costs", values.kv_costs.filter((i) => i.kind === "kv").concat(kv_costs))

  const setContractReturnments = (contract_returnments) => setFieldValue("contract_returnments", contract_returnments)

  const kvSumByAvr = values.kv_costs.filter(i => !i._destroy).map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0)

  const sumByReturnments = values.contract_returnments.filter(i => !i._destroy)
    .map((i) =>
      i.contract.fix_kv_channel?.id
        ? parseMoney(i.fix_kv_cost)
        : parseMoney(i.payment_cost) *
          parseMoney(i.contract.kvs.find((i) => i.channel.id === values.channel.id)?.rate) *
          0.01
    )
    .reduce((a, b) => a + b, 0)

  const paidKv =
    values.payment_kind.value.toString() === "0"
      ? values.avr_installments[0]?.payment_on
        ? kvSumByAvr
        : 0
      : values.avr_installments
          // .filter((i) => i.payment_on)
          .map((i) => parseMoney(i.payment_cost))
          .reduce((a, b) => a + b, 0)

  const { data: currentUser } = useContext(AuthStoreContext)
  const disabled = values.payment_status.value.toString() === "1" && ![5, 23].includes(currentUser.id)

  return (
    <form onSubmit={handleSubmit}>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Общие сведения АВР">
          <br />
          <RadioButtonGroup label="Статус" name="status.value" disabled={disabled}>
            {statuses.map(({ label, value }) => (
              <RadioInput key={`status_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <AsyncSelectInput
            name="insurer.id"
            label="Cтраховая компания"
            options={insurerOptions}
            isSearchable
            loadOptions={loadInsurerOptions}
            required
            isDisabled={disabled}
          />
          <InsurerContactSelect isDisabled={disabled} />
          <ChannelSelect name="channel" required={true} isDisabled={disabled} />
          <DateInput name="start_on" label="Дата начала периода отчета" disabled={disabled} />
          <DateInput name="finish_on" label="Дата завершения периода отчета" disabled={disabled} />
          <TextInput name="number" label="№ АВР" disabled={disabled} />
          <DateInput name="date_on" label="Дата АВР" disabled={disabled} />

          {/* <CurrencyInput name="total_sp_paid" label="Итого оплаченная СП по всем договорам страхования в АВР" />
      <CurrencyInput name="total_commission_by_paid_sp" label="Итого сумма КВ по всем договорам страхования в АВР" /> */}
          <RadioButtonGroup label="КВ по удержанию" name="kv_by_hold.value" disabled={disabled}>
            {kv_by_holds.map(({ label, value }) => (
              <RadioInput key={`kv_by_hold_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <RadioButtonGroup label="Кто формирует АВР" name="former.value" disabled={disabled}>
            {formers.map(({ label, value }) => (
              <RadioInput key={`former_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <TextInput name="comment" label="Комментарий" />
        </Tab>
        <Tab eventKey="contracts" title="Реестр договоров">
          <br />
          <h6>Платежи СП</h6>
          <ContractInstallments {...{ values, setKvCosts }} />
          <h6>Возвраты СП</h6>
          <ContractReturnments {...{ values, setKvCostReturnments }} />
          <h6>Фикс. КВ</h6>
          <FixKvs {...{ values, setKvCosts: setFixKvCosts }} />
        </Tab>
        <Tab eventKey="backoffice" title="Бэк-офис">
          <br />
          <Tabs defaultActiveKey="tracking">
            <Tab eventKey="tracking" title="Трекинг">
              <br />
              <RadioButtonGroup label="Бумажный АВР или ЭЦП" name="kind.value" disabled={disabled}>
                {kinds.map(({ label, value }) => (
                  <RadioInput
                    key={`kind_${value}`}
                    label={label}
                    id={value}
                    onChange={(e) => {
                      setFieldValue("kind.value", e.target.value)
                      if (parseInt(e.target.value) === 0) {
                        setFieldValue("transfer_on", null)
                        setFieldValue("original_receipt_on", null)
                      } else {
                        setFieldValue("transfer_on", values.signed_on)
                        setFieldValue("original_receipt_on", values.signed_on)
                      }
                    }}
                  />
                ))}
              </RadioButtonGroup>
              <DateInput
                name="former_on"
                label="Дата формирования АВР / дата передачи АВР в СИ-НЕРДЖИ"
                required={["1", "2"].includes(values.status.value.toString())}
                disabled={disabled}
              />
              <DateInput
                name="signed_on"
                label="Дата подписания АВР"
                required={values.status.value.toString() === "2"}
                onChange={(e) => {
                  setFieldValue("signed_on", e)
                  if (values.kind.value.toString() === "1") {
                    setFieldValue("transfer_on", e)
                    setFieldValue("original_receipt_on", e)
                  }
                }}
                disabled={disabled}
              />
              <DateInput
                name="transfer_on"
                label="Дата передачи в СК подписанного АВР"
                disabled={values.kind.value.toString() === "1"}
                required={values.status.value.toString() === "2" && values.kind.value.toString() === "0"}
              />
              {/* <DateInput name="payment_on" label="Контрольная дата оплаты АВР СК" /> */}
              <DateInput
                name="original_receipt_on"
                label="Дата получения оригинала подписанного АВР из СК"
                disabled={values.kind.value.toString() === "1"}
              />
              {/* <DateInput name="actual_payment_on" label="Фактическая дата оплаты АВР" /> */}
            </Tab>
            <Tab eventKey="copies" title="Копии АВР">
              <br />
              <Documents />
            </Tab>
            <Tab eventKey="related" title="Связанные АВР">
              <br />
              <Relations />
            </Tab>
          </Tabs>
        </Tab>
        <Tab eventKey="finance" title="Финансовый блок">
          <br />
          <table className="table table-hover">
            <thead>
              <tr>
                <th colSpan="2">Начисления КВ по АВР</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Дата подписания АВР</td>
                <td>{formatDate(values.signed_on)}</td>
              </tr>
              <tr>
                <td>Сумма КВ по АВР</td>
                {/* <td>{formatMoney(kvSumByAvr - sumByReturnments)}</td> */}
                <td>
                  {formatMoney(
                    values.kv_costs.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0) +
                      values.kv_cost_returnments.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0)
                  )}
                </td>
              </tr>
              <tr>
                <td>Контрольная дата оплаты по АВР</td>
                <td>{values.signed_on && formatDate(addDays(values.signed_on, 15))}</td>
              </tr>
              <tr>
                <td>Оплаченное КВ (итого)</td>
                <td>{formatMoney(Math.abs(paidKv - sumByReturnments))}</td>
              </tr>
              <tr>
                <td>Задолженность по АВР</td>
                <td>{formatMoney(Math.abs(kvSumByAvr - paidKv - sumByReturnments))}</td>
              </tr>
            </tbody>
          </table>
          <RadioButtonGroup label="Тип" name="payment_kind.value" disabled={disabled}>
            {payment_kinds.map(({ label, value }) => (
              <RadioInput
                key={`payment_kind_${value}`}
                label={label}
                id={value}
                onChange={(e) => {
                  setFieldValue("payment_kind.value", e.target.value)
                  if (parseInt(e.target.value) === 0) {
                    setFieldValue(
                      "avr_installments",
                      values.avr_installments.map((i, index) => {
                        if (index !== 0) {
                          return { ...i, _destroy: true }
                        } else {
                          return i
                        }
                      })
                    )
                  } else {
                    setFieldValue("avr_installments.0.payment_on", null)
                    setFieldValue("avr_installments.0.payment_kind.value", 0)
                  }
                }}
              />
            ))}
          </RadioButtonGroup>
          <AvrInstallments name="avr_installments" />
        </Tab>
      </Tabs>
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data.toJSON() }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/avrs")
  }),
  observer
)(Form)
