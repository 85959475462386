import React from "react"
import { Tab, Tabs } from "components/UI"
import { observer } from "mobx-react"

const TabsBlock = ({ store }) => {
  const { id } = store.data
  const mapping = {
    [`/contracts/${id}`]: "Общие сведения",
    [`/contracts/${id}/specification`]: "Спецификация договора"
  }

  if (store.data.insurance_kind.id === 7 && store.data.contract_dms?.kind?.value?.toString() === "1") {
    mapping[`/contracts/${id}/insured`] = "Реестр застрахованных"
  }

  mapping[`/contracts/${id}/finances`] = "Финансовый блок"
  mapping[`/contracts/${id}/additionals`] = store.data.insurance_kind.id === 14 ? "Аддендумы" : "Доп. соглашения"
  if (![41, 42, 43, 44, 45, 46, 47].includes(store.data.insurance_kind.id)) {
    mapping[`/contracts/${id}/losses`] = "Убытки"
  }

  return (
    <Tabs>
      {Object.entries(mapping).map((i) => {
        const active = window.location.pathname === i[0]
        return (
          <Tab key={i[0]} active={active} to={i[0]}>
            {i[1]}
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default observer(TabsBlock)
