import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import BorderoStore from "stores/BorderoStore/One"
import Form from "pages/Contracts/containers/Borderos/components/Form"
import { BreadcrumbItem } from "components/UI"

const contractStore = ContractStore.create()
const borderoStore = BorderoStore.create()

const Edit = (props) => {
  const { id, contract_id } = props.match.params
  useEffect(() => {
    contractStore.fetch({ id: contract_id })
    borderoStore.fetch({ id, contract_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contract_id}/borderos`}>Бордеро</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        <BreadcrumbItem active>{borderoStore.number}</BreadcrumbItem>
      </Breadcrumbs>
      <Form {...{ store: borderoStore, contractStore }} />
    </Fragment>
  )
}

export default observer(Edit)
