import React from "react"
import { RadioInput, Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"

const Table = ({ store, selected, setSelected }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col" className="actions one">
          #
        </th>
        <th scope="col">Название</th>
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`document_kinds-${index}`}>
          <td>
            <input
              type="radio"
              name="row.id"
              value={row.id}
              checked={selected.id === row.id}
              onChange={() =>
                setSelected({
                  id: row.id,
                  from_organization: row.from_organization,
                  from_address: row.from_address,
                  from_comment: row.from_comment,
                  from_contact: row.from_contact,
                  from_phone: row.from_phone,
                  to_organization: row.to_organization,
                  to_address: row.to_address,
                  to_comment: row.to_comment,
                  to_contact: row.to_contact,
                  to_phone: row.to_phone
                })
              }
            />
          </td>
          <td>{row.name}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
