import React, { Fragment, useState } from "react"
import compose from "utils/compose"
import { Button, TextInput, ResetButton, SelectInput, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"

const Filter = ({ values, store, handleSubmit }) => {
  const { forms } = store.filtersOptions
  const [disabled, setDisabled] = useState(false)

  const onExportButtonHandler = () => {
    // console.log(values)
    setDisabled(true)
    store.export({ filter: values }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "bills.xlsx")
      document.body.appendChild(link)
      link.click()
      setDisabled(false)
    })
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <SelectInput name="by_form" label="Форма счета" options={forms} />
        </div>
        <div className="col-3">
          <TextInput name="by_index" label="Индекс" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      &nbsp;
      <Button onClick={onExportButtonHandler} disabled={disabled}>
        {disabled && (
          <Fragment>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
          </Fragment>
        )}
        Экспорт в .xlsx
      </Button>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
