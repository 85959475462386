import React, { Fragment } from "react"
import Accident from "./Accident"
import NestedFields from "components/UI/NestedFields"
import { observer } from "mobx-react"

const defaultValues = {
  risk: { value: 0 },
  ss_cost: 0
}

const AccidentTab = (props) => (
  <Fragment>
    <br />
    <NestedFields name="contract_accidents" label="Риски" component={Accident} defaultValues={defaultValues} />
  </Fragment>
)

export default observer(AccidentTab)
