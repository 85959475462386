import { toast } from "react-toastify"

const formSubmitHandler = (values, formikBag, path) => {
  const {
    props: { history, store },
    setErrors,
    setSubmitting
  } = formikBag
  const promise = values.id ? store.update(values) : store.create(values)
  return promise
    .then(() => history.push(path))
    .catch(({ response }) => {
      setErrors(response.data.errors)
      toast.error("Ошибка сохранения. Проверьте форму на наличие ошибок.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      setSubmitting(false)
    })
}

export default formSubmitHandler
