import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import SubdivisionStore from "stores/SubdivisionStore/One"
import Breadcrumbs from "pages/Subdivisions/components/Breadcrumbs"
import { BreadcrumbItem, Card, Button } from "components/UI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import formatDateTime from "utils/formatDateTime"
import { Link } from "react-router-dom"

const subdivisionStore = SubdivisionStore.create()

const userStatus = (status) => {
  switch (status.value) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Работает" />
    case 1:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не работает" />
    default:
      return <i className="fa fa-circle" style={{ color: "yellow" }} title="Отпуск" />
  }
}

const Show = (props) => {
  const { id } = props.match.params

  useEffect(() => {
    subdivisionStore.fetch({ id })
  }, [id])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{subdivisionStore.name}</BreadcrumbItem>
      </Breadcrumbs>
      <Tabs defaultActiveKey="common">
        <Tab eventKey="common" title="Общие сведения">
          <br />
          <Card>
            <Button url={`/subdivisions/${subdivisionStore.data.id}/edit`}>Редактировать</Button>&nbsp;
          </Card>
          <table className="table">
            <tbody>
              <tr>
                <td>Наименование полное</td>
                <td style={{ width: "50%" }}>{subdivisionStore.data.name}</td>
              </tr>
              <tr>
                <td>Наименование краткое</td>
                <td style={{ width: "50%" }}>{subdivisionStore.data.short_name}</td>
              </tr>
              <tr>
                <td>Руководитель подразделения</td>
                <td style={{ width: "50%" }}>
                  <Link to={`/users/${subdivisionStore.data.head.id}`}>{subdivisionStore.data.head.name}</Link>
                </td>
              </tr>
              <tr>
                <td>Вид подразделения</td>
                <td style={{ width: "50%" }}>{subdivisionStore.data.kind.label}</td>
              </tr>
              <tr>
                <td>Ключевое направление деятельности</td>
                <td style={{ width: "50%" }}>{subdivisionStore.data.direction}</td>
              </tr>
              <tr>
                <td>Тип подразделения</td>
                <td style={{ width: "50%" }}>{subdivisionStore.data.kind_of_interaction.label}</td>
              </tr>
              <tr>
                <td>Финансовая модель</td>
                <td style={{ width: "50%" }}>{subdivisionStore.data.finance_model.label}</td>
              </tr>
              <tr>
                <td>Комментарий</td>
                <td style={{ width: "50%" }}>{subdivisionStore.data.comment}</td>
              </tr>
              <tr>
                <td>Создано</td>
                <td style={{ width: "50%" }}>{formatDateTime(subdivisionStore.data.created_at)}</td>
              </tr>
              <tr>
                <td>Создал</td>
                <td style={{ width: "50%" }}>{subdivisionStore.data.creator.name}</td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab eventKey="users" title="Персонал">
          <br />
          <table className="table">
            <thead>
              <tr>
                <th>ФИО</th>
                <th>Должность</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody>
              {subdivisionStore.data.users.map((i) => (
                <tr>
                  <td>{i.name}</td>
                  <td>{i.position.name}</td>
                  <td>{userStatus(i.status)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </Fragment>
  )
}

export default observer(Show)
