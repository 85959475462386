import { types } from "mobx-state-tree"
import isDate from "date-fns/isDate"
import toDate from "date-fns/toDate"
import parseISO from "date-fns/parseISO"

const customDate = types.custom({
  fromSnapshot(value) {
    return value && parseISO(value)
  },
  toSnapshot(value) {
    return value && toDate(value)
  },
  isTargetType(value) {
    return isDate(value)
  },
  getValidationMessage(value) {
    if (isDate(value)) {
      return "Invalid date-fns object"
    }
    return ""
  }
})

export default customDate
