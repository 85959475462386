import React from "react"
import { Destroy, Edit } from "components/UI"
import { observer } from "mobx-react"
import afterDestroyFetch from "utils/afterDestroyFetch"
import formatDate from "utils/formatDate"
import Badge from "react-bootstrap/Badge"
import uniqBy from "lodash.uniqby"
import { withRouter } from "react-router-dom"

const getBadgeVariant = (value) => {
  return ["primary", "secondary", "warning", "warning", "info", "success"][value]
}

const urgencyStatus = (value) => {
  switch (value) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Планово" />
    case 1:
      return <i className="fa fa-circle" style={{ color: "red" }} title="День в день" />
    default:
      return <i className="fa fa-circle" style={{ color: "red" }} title="День в день" />
  }
}

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Дата</th>
        <th scope="col">Срочность</th>
        <th scope="col">Заказчик</th>
        <th scope="col">Отправитель</th>
        <th scope="col">Получатель</th>
        <th scope="col">Служба доставки</th>
        <th scope="col">Стоимость</th>
        <th scope="col">Статус</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`couriers-${index}`}>
          <td onClick={() => history.push(`/couriers/${row.id}`)}>{row.id}</td>
          <td onClick={() => history.push(`/couriers/${row.id}`)}>{formatDate(row.from_date_on)}</td>
          <td onClick={() => history.push(`/couriers/${row.id}`)}>{urgencyStatus(row.urgency.value)}</td>
          <td onClick={() => history.push(`/couriers/${row.id}`)}>{row.user.name}</td>
          <td onClick={() => history.push(`/couriers/${row.id}`)}>{[row.from_organization.name, row.from_contact].filter(Boolean).join(' / ')}</td>
          <td onClick={() => history.push(`/couriers/${row.id}`)}>
            {uniqBy(row.recipients, (i) => i.to_organization.id)
              .map((i) => [i.to_organization.name, i.to_contact].filter(Boolean).join(' / '))
              .join(", ")}
          </td>
          <td onClick={() => history.push(`/couriers/${row.id}`)}>{row.provider.name}</td>
          <td onClick={() => history.push(`/couriers/${row.id}`)}>{row.cost}</td>
          <td onClick={() => history.push(`/couriers/${row.id}`)}>
            <Badge pill variant={getBadgeVariant(row.status.value)}>
              {row.status.label}
            </Badge>
          </td>
          <td>
            <Edit url={`/couriers/${row.id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, row.id)} disabled={![0, 2].includes(row.status.value)} confirmMessage="Вы действительно хотите удалить Заявку?" />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
