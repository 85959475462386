import React, { Component } from "react"
import { observer } from "mobx-react"
import ClientStore from "stores/ClientStore/One"
import Form from "pages/Clients/components/Form"
import Modal from "react-modal"
import { Button } from "components/UI"

class ClientModal extends Component {
  clientStore = ClientStore.create()

  componentDidMount() {
    this.clientStore.fetchMeta()
  }

  handleSubmit = (values, { props: { store }, setErrors }) =>
    store
      .create(values)
      .then(() => {
        this.props.onClientAdded({ label: store.name, value: store.data.id })
        this.closeModal()
      })
      .catch(({ response }) => setErrors(response.data.errors))

  closeModal = () => {
    const { isOpen, setIsOpen } = this.props
    setIsOpen(!isOpen)
  }

  render() {
    const {
      clientStore: store,
      handleSubmit,
      props: { isOpen },
      closeModal
    } = this
    return (
      <Modal isOpen={isOpen} onRequestClose={closeModal} className="modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Создать страхователя</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form {...{ store, handleSubmit }} />
            </div>
            <div className="modal-footer">
              <Button onClick={closeModal} variant="secondary">Закрыть</Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default observer(ClientModal)
