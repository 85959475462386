const prepare = (params = {}) => {
  const {
    responsible,
    document_kind,
    status,
    contract,
    kind: { value: kind },
    submission: { value: submission },
    submission_status: { value: submission_status },
    ...other
  } = params
  return { ...other, document_kind_id: document_kind?.id, kind, submission, submission_status }
}

export { prepare }
