import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import instance from "connection/instance"

const UserSalePlanSelect = ({
  name = "user_sale_plan.id",
  label = "План продаж",
  budget_id,
  required = false,
  ...props
}) => {
  console.log(budget_id)
  const [options, setOptions] = useState([])
  useEffect(() => {
    if (budget_id) {
      loadOptions().then((data) => setOptions(data))
    }
  }, [budget_id])
  const loadOptions = async (value) => {
    const { data } = await instance.get(`/budgets/${budget_id}/user_sale_plans/suggest`, {
      params: { filter: { by_name: value }, limit: 0 }
    })

    return data.user_sale_plans.map((i) => {
      return {
        label: i.name,
        value: i.id
      }
    })
  }

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={options}
        required={required}
        isSearchable
        loadOptions={loadOptions}
        {...props}
      />
    </div>
  )
}

export default observer(UserSalePlanSelect)
