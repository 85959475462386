import React from "react"
import { Route, Switch } from "react-router-dom"
import Structure from "./containers/Structure"
import Diagrams from "./containers/Diagrams"
import Reestr from "./containers/Reestr"
import CheckPermissions from "components/CheckPermissions"

const Placements = () => (
  // <CheckPermissions condition={(privileges) => privileges.includes("placements")}>
    <Switch>
      <Route path="/placements/structure" component={Structure} />
      <Route path="/placements/diagrams" component={Diagrams} />
      <Route path="/placements/reestr" component={Reestr} />
    </Switch>
  // </CheckPermissions>
)

export default Placements
