import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import DocumentKindStore from "stores/DocumentKindStore/One"
import Breadcrumbs from "pages/DocumentKinds/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"

class Show extends Component {
  document_kindStore = DocumentKindStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.document_kindStore.fetch({ id })
  }

  render() {
    const { document_kindStore: store } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{store.name}</BreadcrumbItem>
        </Breadcrumbs>
      </Fragment>
    )
  }
}

export default observer(Show)
