import React from "react"
import compose from "utils/compose"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { Submit, TextInput, RadioButtonGroup, RadioInput } from "components/UI"
import formSubmitHandler from "utils/newFormSubmitHandler"
import ProjectSelect from "components/Common/ProjectSelect"

const Form = ({ handleSubmit, values }) => {
  const kinds = [
    { label: "Основной", value: "main" },
    { label: "Суб-проект", value: "sub" }
  ]
  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup label="Тип" name="kind">
        {kinds.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {values.kind == "main" && <TextInput name="main" label="Основной проект" required />}
      {values.kind == "sub" && <ProjectSelect name="parent.id" required kind="main" />}
      {values.kind == "sub" && <TextInput name="sub" label="Суб-проект" required />}
      <TextInput name="comment" label="Комментарий" />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ entity }) => {
      const { kind } = entity
      return { ...entity, kind: kind?.value }
    },
    handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/projects")
  })
)(Form)
