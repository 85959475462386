import React, { Fragment } from "react"
import { observer } from "mobx-react"
import CommonTab from "./CommonTab"
import BsoTab from "./BsoTab"
import DocumentsTab from "./DocumentsTab"
import ScansTab from "./ScansTab"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

const InfoTab = (props) => (
  <Fragment>
    <br />
    <Tabs defaultActiveKey="common">
      <Tab eventKey="common" title="Общие сведения">
        <CommonTab {...props} />
      </Tab>
      {props.values.use_bso.value.toString() === "1" && (
        <Tab eventKey="bso" title="БСО">
          <BsoTab {...props} />
        </Tab>
      )}
      {/* <Tab eventKey="documents" title="Сдача документов">
        <DocumentsTab {...props} />
      </Tab>
      <Tab eventKey="scans" title="Сканы документов">
        <ScansTab {...props} />
      </Tab> */}
    </Tabs>
  </Fragment>
)

export default observer(InfoTab)
