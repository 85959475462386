import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"
import customDate from "types/customDate"

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  position: types.maybeNull(customEnum),
  status: types.maybeNull(newCustomEnum),
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  short_name: types.maybeNull(types.string),
  kind: types.maybeNull(newCustomEnum),
  kind_of_interaction: types.maybeNull(newCustomEnum),
  finance_model: types.maybeNull(newCustomEnum),
  status: types.maybeNull(newCustomEnum),
  direction: types.maybeNull(types.string),
  head: types.maybeNull(User),
  creator: types.maybeNull(User),
  comment: types.maybeNull(types.string),
  created_at: types.maybeNull(customDate),
  users: types.maybeNull(types.array(User))
})

export { Subdivision }
