const prepare = (params = {}) => {
  const {
    creator,
    ...other
  } = params
  return {
    ...other,
  }
}

export { prepare }
