import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import ParcelBordero from "./ParcelBordero"
import newCustomEnum from "types/newCustomEnum"
import { prepare } from "./utils"
import toOptions from "utils/newToOptions"

const initialState = {
  start_on: null,
  finish_on: null,
  number: null,
  comment: null,
  currency: { value: 0 },
  status: { value: 0 },
  documents: [],
  creator: {},
  parcels: [],
}

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {}
}

const ParcelBorderoStore = types
  .model("ParcelBorderoStore", {
    data: types.optional(ParcelBordero, initialState),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get number() {
      return self.data.number
    },

    get filtersOptions() {
      const { statuses } = self.meta.filters_options
      return { statusesOptions: toOptions(statuses) }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, contract_id, ...options } = params
      return instance
        .get(`/contracts/${contract_id}/parcel_borderos/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta(params = {}) {
      self.setState("pending")
      const { id, contract_id, ...options } = params
      return instance
        .get(`/contracts/${contract_id}/parcel_borderos/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const { contract_id } = params
      const data = prepare(params)
      return instance
        .post(`/contracts/${contract_id}/parcel_borderos`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const { id, contract_id } = params
      const data = prepare(params)
      return instance
        .put(`/contracts/${contract_id}/parcel_borderos/${id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { ...self.data, meta: data.meta })

      return self
    },

    reset: () => {
      applySnapshot(self, initialState)
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ParcelBorderoStore
