import React, { Fragment, useState } from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import addYears from "date-fns/addYears"
import differenceInDays from "date-fns/differenceInDays"
import Documents from "./../Documents"
import { Button, Card } from "components/UI"

const VacationTab = ({ store }) => {
  const { data } = store

  const [disabled, setDisabled] = useState(false)

  const onExportButtonHandler = () => {
    // console.log(values)
    setDisabled(true)
    store.export({ id: store.id }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "order.xlsx")
      document.body.appendChild(link)
      link.click()
      setDisabled(false)
    })
  }

  return (
    <div className="row">
      <div className="col-12">
        <Card>
          <Button onClick={onExportButtonHandler} disabled={disabled}>
            {disabled && (
              <Fragment>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
              </Fragment>
            )}
            Экспорт в .xlsx
          </Button>
        </Card>
        <table className="table">
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>Вид отпуска</td>
              <td style={{ width: "50%" }}>{data.order_vacation.kind?.label}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>За период работы</td>
              <td style={{ width: "50%" }}>
                {data.order_vacation.perion_start_on && formatDate(data.order_vacation.perion_start_on)} -{" "}
                {data.order_vacation.perion_start_on && formatDate(addYears(data.order_vacation.perion_start_on, 1))}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Отпуск</td>
              <td style={{ width: "50%" }}>
                {formatDate(data.order_vacation.vacation_start_on)} -{" "}
                {formatDate(data.order_vacation.vacation_finish_on)}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Дней отпуска</td>
              <td style={{ width: "50%" }}>
                {differenceInDays(data.order_vacation.vacation_finish_on, data.order_vacation.vacation_start_on) + 1}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Комментарии</td>
              <td style={{ width: "50%" }}>{data.order_vacation.comment}</td>
            </tr>
          </tbody>
        </table>
        <Documents store={store} />
      </div>
    </div>
  )
}

export default observer(VacationTab)
