import React from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"

const Button = ({ children, variant = "primary", url, size = null, className = "", disabled = false, ...props }) => {
  const classNames = classnames(
    "btn",
    { [`btn-${size}`]: size },
    { "disabled-edit": disabled },
    `btn-${variant}`,
    className
  )
  if (url) {
    return (
      <Link className={classNames} to={url} {...props}>
        {children}
      </Link>
    )
  }
  return (
    <button type="button" className={classNames} {...props}>
      {children}
    </button>
  )
}

export default Button
