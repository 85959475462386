import set from "lodash.set"

const convertErrorsObject = (errorsObj = {}) => {
  let newErrorsObj = {}
  Object.keys(errorsObj).forEach(key => {
    const path = key.replace(/\[(\d+)\]/g, ".$1")
    set(newErrorsObj, path, errorsObj[key])
  })
  console.log(newErrorsObj)
  return newErrorsObj
}

export default convertErrorsObject
