import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { BsoKind } from "./BsoKind"
import { prepare } from "./utils"
import newCustomEnum from "types/newCustomEnum"
import toOptions from "utils/newToOptions"

const FilterOptions = types.model("FilterOptions", {
  bso_batch_statuses: types.array(newCustomEnum, []),
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    bso_batch_statuses: []
  }
}

const BsoKindStore = types
  .model("BsoKindStore", {
    data: types.optional(BsoKind, {
      insurer: {
        id: null,
        name: null
      },
      insurance_kind: {
        id: null,
        name: null
      },
      bso_batches: []
    }),
    meta: types.optional(Meta, {}),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { bso_batch_statuses } = self.meta.filters_options
      return { bso_batch_statuses: toOptions(bso_batch_statuses) }
    },

    get id() {
      return self.data.id
    },

    get name() {
      return self.data.name
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/bso_kinds/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/bso_kinds/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/bso_kinds`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/bso_kinds/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default BsoKindStore
