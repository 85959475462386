import React from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { ResetButton, SelectInput, Submit, TextInput, DateInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import UserSelect from "components/Common/UserSelect"
import ClientSelect from "components/Common/ClientSelect"
import InsuranceKindSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsuranceKindSelect"

const Filter = ({ store, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <SelectInput
            name="by_kind"
            label="Тип задачи"
            isClearable
            options={[
              { label: "Суд", value: "session" },
              { label: "Почта", value: "post" },
              { label: "Документ", value: "document" },
              { label: "Звонок", value: "call" },
              { label: "Встреча", value: "meeting" },
              { label: "Эл.письмо", value: "email" }
            ]}
          />
        </div>
        <div className="col-3">
          <TextInput name="by_subject" label="Тема" />
        </div>
        <div className="col-3">
          <UserSelect name="by_performer.id" label="Исполнитель" />
        </div>
        <div className="col-3">
          <UserSelect name="by_creator.id" label="Назначил задачу" />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_status"
            label="Статус"
            isClearable
            options={[
              { label: "В работе", value: "in_work" },
              { label: "Готово", value: "done" }
            ]}
          />
        </div>
        <div className="col-3">
          <TextInput name="by_result" label="Результат" />
        </div>
        <div className="col-3">
          <ClientSelect name="by_contract_client.id" label="Заказчик" />
        </div>
        <div className="col-3">
          <TextInput name="by_plaintiff_or_defendant" label="Истец / Ответчик" />
        </div>
        <div className="col-3">
          <TextInput name="by_debtor_or_сreditor" label="Должник / Кредитор" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_from" label="Дата задачи с" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_to" label="Дата задачи по" />
        </div>
        <div className="col-3">
          <DateInput name="by_done_at_from" label="Дата закрытия с" />
        </div>
        <div className="col-3">
          <DateInput name="by_done_at_to" label="Дата закрытия по" />
        </div>
        <div className="col-3">
          <TextInput name="by_contract_id" label="ID договора" />
        </div>
        <div className="col-3">
          <TextInput name="by_contract_proceeding_subject" label="Предмет договора" />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_parent"
            label="Подзадачи"
            isClearable
            options={[
              { label: "Показать", value: "show" },
              { label: "Скрыть", value: "hide" }
            ]}
          />
        </div>
        <div className="col-3">
          <InsuranceKindSelect name="by_insurance_kind" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  })
)(Filter)
