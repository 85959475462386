import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  binding: types.maybeNull(newCustomEnum),
  client_binding: types.maybeNull(newCustomEnum)
})

export { DocumentKind }
