import React, { useContext } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router-dom"
import { Submit } from "components/UI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import formSubmitHandler from "utils/formSubmitHandler"
import InfoTab from "./InfoTab"
import DocumentsTab from "./DocumentsTab"
import HireTab from "./HireTab"
import SalaryChangeTab from "./SalaryChangeTab"
import VacationTab from "./VacationTab"
import ChangePositionTab from "./ChangePositionTab"
import OrderFireAtWillTab from "./OrderFireAtWillTab"
import AwardTab from "./AwardTab"
import OrderFireByAgreementTab from "./OrderFireByAgreementTab"
import FinancesTab from "./FinancesTab"
import AuthStoreContext from "contexts/AuthStoreContext"

const Form = ({ store, handleSubmit, ...props }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <form onSubmit={handleSubmit}>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Общие сведения">
          <InfoTab {...{ store, ...props }} />
        </Tab>
        {props.values.order_subject.id === 4 && (
          <Tab eventKey="hire" title="Спецификация">
            <HireTab {...{ store, ...props }} />
          </Tab>
        )}
        {props.values.order_subject.id === 8 && (
          <Tab eventKey="salary_change" title="Спецификация">
            <SalaryChangeTab {...{ store, ...props }} />
          </Tab>
        )}
        {props.values.order_subject.id === 3 && (
          <Tab eventKey="vacation" title="Спецификация">
            <VacationTab {...{ store, ...props }} />
          </Tab>
        )}
        {props.values.order_subject.id === 5 && (
          <Tab eventKey="change_position" title="Спецификация">
            <ChangePositionTab {...{ store, ...props }} />
          </Tab>
        )}
        {props.values.order_subject.id === 6 && (
          <Tab eventKey="fire_at_will" title="Спецификация">
            <OrderFireAtWillTab {...{ store, ...props }} />
          </Tab>
        )}
        {props.values.order_subject.id === 2 && (
          <Tab eventKey="award" title="Спецификация">
            <AwardTab {...{ store, ...props }} />
          </Tab>
        )}
        {props.values.order_subject.id === 7 && (
          <Tab eventKey="fire_by_agreement" title="Спецификация">
            <OrderFireByAgreementTab {...{ store, ...props }} />
          </Tab>
        )}
        {[5, 23].includes(currentUser.id) && [4, 5, 8].includes(props.values.order_subject.id) && (
          <Tab eventKey="finances" title="Финансы">
            <FinancesTab {...{ store, ...props }} />
          </Tab>
        )}
        <Tab eventKey="documents" title="Копии документов">
          <DocumentsTab {...{ store, ...props }} />
        </Tab>
      </Tabs>

      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/orders")
  }),
  observer
)(Form)
