import React from "react"
import { Destroy, Edit, Sort } from "components/UI"
import { Link } from "react-router-dom"
import afterDestroyFetch from "utils/afterDestroyFetch"
import sortChangeHandler from "utils/sortChangeHandler"
import { observer } from "mobx-react"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">
          <Sort name="order_by_name" onSortChange={() => sortChangeHandler(store)}>
            Субагент
          </Sort>
        </th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map(({ id, name }, index) => (
        <tr key={`subagents-${index}`}>
          <td>
            <Link to={`/subagents/${id}`}>{name}</Link>
          </td>
          <td>
            <Edit url={`/subagents/${id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
