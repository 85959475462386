import React, { Component, Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import Breadcrumbs from "pages/Users/components/Breadcrumbs"
import Form from "pages/Users/components/Form"
import { BreadcrumbItem } from "components/UI"
import UserStore from "stores/UserStore/One"

const Add = () => {
  const userStore = UserStore.create()
  
  useEffect(() => {
    userStore.fetchMeta()
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={userStore} />
    </Fragment>
  )
}

export default observer(Add)
