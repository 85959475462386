const prepare = (params = {}) => {
  const {
    insurer: { id: insurer_id },
    channel: { id: channel_id },
    bso_kind: { id: bso_kind_id },
    bso_batch: { id: bso_batch_id },
    form_status: { value: form_status },
    number_status: { value: number_status },
    geolocation_status: { value: geolocation_status },
    contract,
    ...other
  } = params
  return {
    ...other,
    insurer_id,
    channel_id,
    bso_kind_id,
    bso_batch_id,
    form_status,
    number_status,
    geolocation_status
  }
}

export { prepare }
