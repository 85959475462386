import React from "react"
import RecipientCargo from "./RecipientCargo"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  kind: { value: 0 },
  kind_other: null
}

const RecipientCargos = ({ name, isDisabled }) => (
  <NestedFields
    name={name}
    label="Грузы"
    component={RecipientCargo}
    defaultValues={defaultValues}
    disabledAdd={isDisabled}
    disabledDestroy={isDisabled}
  />
)

export default RecipientCargos
