import Breadcrumbs from "pages/Bsos/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import BsoStore from "stores/BsoStore/One"
import { BreadcrumbItem, Button, Card } from "components/UI"
import { observer } from "mobx-react"

const bsoStore = BsoStore.create()

const Show = (props) => {
  useEffect(() => {
    const { id } = props.match.params
    bsoStore.fetch({ id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{bsoStore.full_name}</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/bsos/${bsoStore.id}/edit`}>Редактировать</Button>
      </Card>
      <div className="row">
        <div className="col-12">
          <table className="table">
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Фамилия</td>
                <td style={{ width: "50%" }}>{bsoStore.l_name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Имя</td>
                <td style={{ width: "50%" }}>{bsoStore.f_name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Отчество</td>
                <td style={{ width: "50%" }}>{bsoStore.p_name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Подразделение</td>
                <td style={{ width: "50%" }}>{bsoStore.subdivision}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Должность</td>
                <td style={{ width: "50%" }}>{bsoStore.position}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата рождения</td>
                <td style={{ width: "50%" }}>{bsoStore.birthday_on}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>MOЛ</td>
                <td style={{ width: "50%" }}>{bsoStore.mol}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Emails</td>
                <td style={{ width: "50%" }}>
                  {bsoStore.emails.map(({ id, value }) => (
                    <a key={`email-${id}`} href={`emailto:${value}`} rel="noopener noreferrer">
                      {value}
                    </a>
                  ))}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Телефоны</td>
                <td style={{ width: "50%" }}>
                  {bsoStore.phones.map(({ id, value }) => (
                    <a key={`phone-${id}`} href={`tel:${value}`} rel="noopener noreferrer">
                      {value}
                    </a>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

export default observer(Show)
