import currency from "currency.js"

const formatMoney = (value) => {

  // if (typeof value === "number") {
  //   value = (Math.round(value * 100).toFixed(2) / 100).toFixed(2)
  // }
  let a = String(value).split(".")
  if (a.length > 1) {
    a[1] = a[1].slice(0, 3)
  }
  
  // a.join('.')
  return currency(a.join('.'), { symbol: "", separator: " ", decimal: "." }).format()
}

export default formatMoney
