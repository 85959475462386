import React from "react"
import { Sort } from "components/UI"
import formatDate from "utils/formatDate"
import { observer } from "mobx-react"
import { withRouter } from "react-router-dom"
import sortChangeHandler from "utils/sortChangeHandler"
import { isAfter, parseISO } from "date-fns"

const status = (status) => {
  switch (status.value) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не сдан" />
    case 1:
      return <i className="fa fa-circle" style={{ color: "yellow" }} title="Сдано в Бэк-офис" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Сдано в СК" />
  }
}

const row_color = (i) => {
  if (i.status.value === 0 && isAfter(new Date(), parseISO(i.contract.start_on))) {
    return "table-danger"
  }
  return null
}

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th>ID договора</th>
        <th>Страховая компания</th>
        <th>Страхователь</th>
        <th>Вид стахования</th>
        <th>Тип документа</th>
        <th>Вид документа</th>
        <th>Кому/куда сдан</th>
        <th>
          <Sort name="order_by_submission_on" onSortChange={() => sortChangeHandler(store)}>
            Дата сдачи
          </Sort>
        </th>
        <th>Дней с даты договора</th>
        <th>Статус</th>
      </tr>
    </thead>
    <tbody>
      {store.data.map((i, index) => (
        <tr key={`contract_documents-${index}`} className={row_color(i)}>
          <td onClick={() => window.open(`/contracts/${i.contract.id}`, "_blank").focus()}>{i.contract.id}</td>
          <td onClick={() => window.open(`/contracts/${i.contract.id}`, "_blank").focus()}>{i.contract.insurer.name}</td>
          <td onClick={() => window.open(`/contracts/${i.contract.id}`, "_blank").focus()}>{i.contract.client.name}</td>
          <td onClick={() => window.open(`/contracts/${i.contract.id}`, "_blank").focus()}>{i.contract.insurance_kind.name}</td>
          <td onClick={() => window.open(`/contracts/${i.contract.id}`, "_blank").focus()}>{i.document_kind.name}</td>
          <td onClick={() => window.open(`/contracts/${i.contract.id}`, "_blank").focus()}>{i.kind.label}</td>
          <td onClick={() => window.open(`/contracts/${i.contract.id}`, "_blank").focus()}>
            {i.submission_holder}/{String(i.submission.value) == "2" ? i.submission_other : i.submission.label}
          </td>
          <td onClick={() => window.open(`/contracts/${i.contract.id}`, "_blank").focus()}>
            {i.submission_on ? formatDate(i.submission_on) : "-"}
          </td>
          <td onClick={() => window.open(`/contracts/${i.contract.id}`, "_blank").focus()}>{i.contract.days_from_conclusion_on}</td>
          <td onClick={() => window.open(`/contracts/${i.contract.id}`, "_blank").focus()}>{status(i.status)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
