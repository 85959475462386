import React from "react"
import compose from "utils/compose"
import { TextInput, ResetButton, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import DocumentKindSelect from "components/Common/DocumentKindSelect"

const Filter = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <ChannelSelect name="by_channel" />
      </div>
      <div className="col-3">
        <DocumentKindSelect name="by_document_kind.id" ids={[4, 6, 26, 33]} />
      </div>
      <div className="col-3">
        <TextInput name="by_number" label="№" />
      </div>
      <div className="col-3">
        <TextInput name="by_parent_number" label="Связанный договор" />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
  </form>
)

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) =>
      filterSubmitHandler(values, formikBag, { insurer_id: formikBag.props.match.params.insurer_id })
  }),
  observer
)(Filter)
