import React from "react"
import { observer } from "mobx-react"
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts"
import formatMoney from "utils/formatMoney"

const TableByQuart = ({ store }) => {
  const { plan: plans, fact: facts } = store.data.table
  const quarters = ["1КВ", "2КВ", "3КВ", "4КВ"]

  let dataMax = 0
  const data = quarters.map((month, index) => {
    const plan = Object.values(plans)
      .slice(index * 3, index * 3 + 3)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    const fact = Object.values(facts)
      .slice(index * 3, index * 3 + 3)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)

    const max = Math.max(plan, fact)
    if (dataMax < max) {
      dataMax = max
    }

    const percent = Math.round((fact / plan) * 100) 
    return {
      name: month,
      "%": isFinite(percent) ? percent : 0,
      План: parseFloat(plan.toFixed(2)),
      Факт: parseFloat(fact.toFixed(2))
    }
  })
  dataMax = Math.round(dataMax + dataMax * 0.1)

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 30,
            bottom: 20,
            left: 30
          }}
        >
          <XAxis dataKey="name" />
          <YAxis domain={["auto", dataMax]} type="number" yAxisId="1" />
          <YAxis domain={["auto", "auto"]} orientation="right" type="number" yAxisId="2" />
          <Tooltip formatter={(value, name, props) => formatMoney(value)} />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Line type="monotone" dataKey="%" stroke="#a5a5a5" yAxisId="2" />
          <Bar dataKey="План" fill="#4571c4" yAxisId="1" />
          <Bar dataKey="Факт" fill="#ed7d32" yAxisId="1" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
export default observer(TableByQuart)
