import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import store from "store"
// import _pick from "lodash/pick"

// import { verifyJWT } from "utils/jwt"
// import { Payload } from "./Payload"

// const USER_ATTRS = [
//   "data.id",
//   "data.email",
//   "data.first_name",
//   "data.last_name",
//   "data.middle_name",
//   "data.role",
//   "token",
//   "payload"
// ]

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const AuthStore = types
  .model("AuthStore", {
    data: types.optional(
      types.model({
        id: types.maybeNull(types.integer),
        email: types.maybe(types.string),
        name: types.maybe(types.string),
        subdivision: types.maybeNull(Subdivision),
        privileges: types.maybeNull(types.array(types.string))
      }),
      {
        id: null,
        email: "",
        name: "",
        privileges: []
      }
    ),
    token: types.maybe(types.string)
  })
  .views((self) => ({
    get isAuthenticated() {
      return !!self.token
    },

    get id() {
      return self.data.id
    },

    get name() {
      return self.data.name
    },

    get subdivision() {
      return self.data.subdivision
    }
  }))
  .actions((self) => ({
    afterCreate() {
      const data = self.readFromLocalStorage()
      if (!data) return

      self.updateUser(data)
      self.setDefaultsHeader(data.token)
    },

    login(values = {}) {
      const data = { user: values }

      const config = {
        headers: { Authorization: null }
      }

      return instance
        .post("/auth/sign_in", data, config)
        .then((response) => self.writeToLocalStorage(response))
        .then(({ headers: { authorization: token } }) => self.setDefaultsHeader(token))
        .catch((error) => Promise.reject(error))
    },

    logout() {
      return instance
        .delete("/auth/sign_out")
        .catch((error) => console.log("Catch: ", error))
        .then((response) => {
          store.remove("authStore")
          self.updateUser({data: {subdivision: {}}})
          self.unsetDefaultHeader()
        })
    },

    // fetchProfile(data) {
    //   instance.get("/profile").then(response => self.assignSettings(response))
    // },

    assignSettings(response) {
      const { setting } = response.data.client
      applySnapshot(self.setting, setting)
    },

    updateUser(data) {
      applySnapshot(self, data)
      return data
    },

    setDefaultsHeader(token) {
      instance.defaults.headers.common.Authorization = token
    },

    unsetDefaultHeader() {
      instance.defaults.headers.common.Authorization = undefined
    },

    writeToLocalStorage(response) {
      store.set("authStore", { data: response.data, token: response.headers.authorization })
      self.updateUser({ data: response.data, token: response.headers.authorization })
      return response
    },

    readFromLocalStorage() {
      return store.get("authStore")
    }
  }))

export default AuthStore
