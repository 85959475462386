import format from "date-fns/format"
import parseISO from "date-fns/parseISO"

const formatDateTime = (date) => {
  if (!date) return null
  if (typeof date === "string" || date instanceof String) {
    date = parseISO(date)
  }
  return format(date, "dd.MM.yyyy в HH:mm")
}

export default formatDateTime