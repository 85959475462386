import React from "react"
import { Link } from "react-router-dom"
import formatDate from "utils/formatDate"

const paymentStatus = (contract_installments) => {
  const not_null_subagent_cost = contract_installments.filter((i) => parseInt(i.subagent_cost) > 0)
  const not_null_subagent_cost_fact_on = not_null_subagent_cost.filter((i) => i.subagent_cost_fact_on)
  if (not_null_subagent_cost.length === not_null_subagent_cost_fact_on.length) {
    return <i className="fa fa-circle" style={{ color: "green" }} />
  }
  return <i className="fa fa-circle" style={{ color: "red" }} />
}

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Страхователь</th>
        <th scope="col">№ договора</th>
        <th scope="col">Вид страхования</th>
        <th scope="col">Срок действия договора</th>
        <th scope="col">Исполнитель (СИ-НЕРДЖИ)</th>
        <th scope="col">Статус выплаты субагенту</th>
      </tr>
    </thead>
    <tbody>
      {store.data.map((i, index) => (
        <tr key={`subagents-${index}`}>
          <td>
            <Link to={`/clients/${i.client.id}`}>{i.client.name}</Link>
          </td>
          <td>
            <Link to={`/contracts/${i.id}`}>{i.number}</Link>
          </td>
          <td>{i.insurance_kind.name}</td>
          <td>
            {formatDate(i.start_on)} - {formatDate(i.finish_on)}
          </td>
          <td>{i.user.name}</td>
          <td>{paymentStatus(i.contract_installments)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
