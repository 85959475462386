import React, { Fragment, useContext } from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import formatTime from "utils/formatTime"
import formatDateTime from "utils/formatDateTime"
import { withRouter } from "react-router-dom"
import isBefore from "date-fns/isBefore"
import { Link } from "react-router-dom"
import useModalTrigger from "hooks/useModalTrigger"
import ShowModal from "pages/ContractTasks/containers/List/components/ShowModal"
import classNames from "classnames"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { Destroy } from "components/UI"
import AuthStoreContext from "contexts/AuthStoreContext"

const rowClass = (i) => {
  if (i.status.value == "done") return "table-success"
  if (i.status.value == "in_work" && isBefore(i.date_on, new Date())) return "table-danger"
  if (i.status.value == "in_work") return "table-warning"
}

const Table = ({ store, history }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  const [isShowModalOpen, showModalData, openShowModal, closeShowModal] = useModalTrigger({})
  return (
    <Fragment>
      <ShowModal isOpen={isShowModalOpen} closeModal={closeShowModal} entity={showModalData} />
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th>#</th>
            <th>Дата/время</th>
            <th>Договор</th>
            <th>Тип задачи</th>
            <th>Тема</th>
            <th>Исполнитель</th>
            <th>Закрыто</th>
            <th>Результат</th>
            <th className="actions one" />
          </tr>
        </thead>
        <tbody>
          {store.data.map((i, index) => (
            <tr key={`contract_tasks-${index}`} className={rowClass(i)}>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.id}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.parent.id ? (
                  <i className="fas fa-list-check" title="Подзадача"></i>
                ) : (
                  <i className="fas fa-clipboard" title="Задача"></i>
                )}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {[formatDate(i.date_on), formatTime(i.time_at)].filter(Boolean).join(" в ")}
              </td>
              <td className={classNames({ "font-italic": i.parent.id })}>
                <Link target="_blank" to={`/contracts/${i.contract.id}/specification`}>
                  {i.contract.name}
                </Link>
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.kind.label}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.subject}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.performer?.name}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {formatDateTime(i.done_at)}
              </td>
              <td onClick={() => openShowModal(i)} className={classNames({ "font-italic": i.parent.id })}>
                {i.result}
              </td>
              <td>
                <Destroy
                  action={() => afterDestroyFetch(store, i.id)}
                  disabled={![5, 26].includes(currentUser.id) && currentUser.id !== i.creator.id}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  )
}

export default withRouter(observer(Table))
