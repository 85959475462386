import React from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { ResetButton, SelectInput, Submit, TextInput, DateInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import UserSelect from "components/Common/UserSelect"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import ProviderSelect from "components/Common/ProviderSelect"

const Filter = ({ store, handleSubmit }) => {
  const { statuses, urgencies, delivery_services } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <TextInput name="by_from_organization" label="Отправитель" />
        </div>
        <div className="col-3">
          <TextInput name="by_to_organization" label="Получатель" />
        </div>
        <div className="col-3">
          <SelectInput name="by_status" label="Статус" options={statuses} withEmptyValue />
        </div>
        <div className="col-3">
          <SelectInput name="by_urgency" label="Срочность" options={urgencies} withEmptyValue />
        </div>
        <div className="col-3">
          <DateInput name="by_from_date_on_from" label="Дата заявки с" />
        </div>
        <div className="col-3">
          <DateInput name="by_from_date_on_to" label="Дата заявки по" />
        </div>
        <div className="col-3">
          <ProviderSelect label="Служба доставки" name="by_provider" activity_kind_id="5" />
        </div>
        <div className="col-3">
          <SubdivisionSelect name="by_subdivision.id" />
        </div>
        <div className="col-3">
          <UserSelect name="by_user.id" label="Заказчик" />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_cost"
            label="Стоимость доставки"
            withEmptyValue
            options={[
              { label: "Стоимость установлена", value: 1 },
              { label: "Стоимость не установлена", value: 0 }
            ]}
          />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  })
)(Filter)
