import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Button, Card, Modal, ModalHeader, ModalBody, ModalFooter, Paginate } from "components/UI"
import ContractStore from "stores/ContractStore/List"
import Table from "./Table"
import Filter from "./Filter"

const contractStore = ContractStore.create()

const ParentModal = ({ isOpen, closeModal, setFieldValue, client }) => {
  const [selected, setSelected] = useState([])

  useEffect(() => {
    contractStore.fetch()
  }, [isOpen])

  const setContract = () => {
    setFieldValue("contracts", selected)
    closeModal()
  }

  const handleSubmit = (values) => contractStore.fetch({ filter: values })

  // console.log(contractStore.meta).filter

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Выбрать родительский договор</ModalHeader>
      <ModalBody>
        <Card>
          <Filter handleSubmit={handleSubmit} client={client} />
        </Card>
        <Table store={contractStore} selected={selected} setSelected={setSelected} />
        <Paginate store={contractStore} params={contractStore.meta.filter} />
        <Button onClick={setContract}>Сохранить</Button>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(ParentModal)
