import { getIn } from "formik"

const prepare = (params = {}) => {
  const {
    user: { id: user_id },
    channel: { id: channel_id },
    creator: { id: creator_id },
    order_subject: { id: order_subject_id },
    signer: { id: signer_id },
    status: { value: status },
    order_salary_change,
    order_fire_at_will,
    order_hire,
    order_vacation,
    order_change_position,
    order_award,
    order_fire_by_agreement,
    user_number,
    user_subdivision,
    user_position,
    comment,
    subdivision,
    ...other
  } = params

  let data = {
    ...other,
    user_id,
    channel_id,
    creator_id,
    order_subject_id,
    signer_id,
    status: parseInt(status)
  }

  if (order_subject_id === 8) {
    data.order_salary_change = order_salary_change || { salary: "false" }
  }
  if (order_subject_id === 4) {
    const probation = getIn(order_hire, "probation.value", null)

    const {
      position,
      subdivision,
      ...other_order_hire
    } = order_hire
    const position_id = getIn(position, "id", null)
    const subdivision_id = getIn(subdivision, "id", null)

    data.order_hire = { ...other_order_hire, probation, position_id, subdivision_id } || { hire_on: "false" }
  }
  if (order_subject_id === 3) {
    const {
      kind,
      ...other_order_vacation
    } = order_vacation || {}
    const kind2 = getIn(kind, "value", null)
    data.order_vacation = { ...other_order_vacation, kind: kind2 } || { vacation_start_on: "false" }
  }
  if (order_subject_id === 5) {
    const {
      position,
      subdivision,
      ...other_order_change_position
    } = order_change_position
    const position_id = getIn(position, "id", null)
    const subdivision_id = getIn(subdivision, "id", null)
    data.order_change_position = { ...other_order_change_position, position_id, subdivision_id } || { date_on: "false" }
  }
  if (order_subject_id === 6) {
    data.order_fire_at_will = order_fire_at_will || { date_on: "false" }
  }
  if (order_subject_id === 2) {
    data.order_award = order_award || { date_on: "false" }
  }
  if (order_subject_id === 7) {
    data.order_fire_by_agreement = order_fire_by_agreement || { date_on: "false" }
  }

  return data
}

export { prepare }
