import React from "react"
import BsoBatch from "./BsoBatch"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  status: { value: 0 }
}

const BsoBatches = () => (
  <NestedFields name="bso_batches" label="Серии БСО" component={BsoBatch} defaultValues={defaultValues} />
)

export default BsoBatches
