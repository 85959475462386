import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import formatMoney from "utils/formatMoney"
import Tabsss from "pages/Budgets/components/Tabs"
import instance from "connection/instance"
import classnames from "classnames"

const budgetStore = BudgetStore.create()

const Summary = (props) => {
  const { id } = props.match.params
  const [summary, setSummary] = useState([])
  const [summaryFact, setSummaryFact] = useState([])
  const [сumulative, setСumulative] = useState([])

  useEffect(() => {
    budgetStore.fetch({ id })

    instance.get(`/budgets/${id}/summary`).then(({ data }) => {
      setSummary(data)
    })
    instance.get(`/budgets/${id}/summary_fact`).then(({ data }) => {
      setSummaryFact(data)
    })
    instance.get(`/budgets/${id}/cumulative`).then(({ data }) => {
      setСumulative(data)
    })
  }, [id])

  const [showMonths, setShowMonts] = useState(true)

  const year = budgetStore.data.year.toString().slice(2, 4)

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>Свод</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budgetStore.data.id}/edit`}>Радактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <br />

      <div className="form-group">
        <label for="ERAjuorWqK">Вид</label>
        <fieldset>
          <div className="form-check-inline form-check">
            <input
              className="form-check-input"
              id="bEADGzWZgi"
              type="radio"
              name="budget.id"
              value={false}
              checked={showMonths === false}
              onChange={() => setShowMonts(false)}
            />
            <label className="form-check-label" for="bEADGzWZgi">
              Свернуть мес.
            </label>
          </div>
          <div className="form-check-inline form-check">
            <input
              className="form-check-input"
              id="zQVzlyBoiV"
              type="radio"
              name="budget.id"
              value={true}
              checked={showMonths === true}
              onChange={() => setShowMonts(true)}
            />
            <label className="form-check-label" for="zQVzlyBoiV">
              Развернуть мес.
            </label>
          </div>
        </fieldset>
      </div>
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered summary" style={{ width: showMonths ? "4300px" : "1500px" }}>
          <thead>
            <tr className="table-primary">
              <th colSpan={18}>План Свод</th>
            </tr>
            <tr>
              <th>Статья бюджета</th>
              <th>Год</th>
              <th>1КВ{year}</th>
              {showMonths && (
                <Fragment>
                  <th>ЯНВ{year}</th>
                  <th>ФЕВ{year}</th>
                  <th>МАРТ{year}</th>
                </Fragment>
              )}
              <th>2КВ{year}</th>
              {showMonths && (
                <Fragment>
                  <th>АПР{year}</th>
                  <th>МАЙ{year}</th>
                  <th>ИЮНЬ{year}</th>
                </Fragment>
              )}
              <th>3КВ{year}</th>
              {showMonths && (
                <Fragment>
                  <th>ИЮЛЬ{year}</th>
                  <th>АВГ{year}</th>
                  <th>СЕН{year}</th>
                </Fragment>
              )}
              <th>4КВ{year}</th>
              {showMonths && (
                <Fragment>
                  <th>ОКТ{year}</th>
                  <th>НОЯ{year}</th>
                  <th>ДЕК{year}</th>
                </Fragment>
              )}
            </tr>
          </thead>
          <tbody>
            {summary.map((i) => (
              <tr className={classnames({ header: i.name === "ТЕХ. РЕЗ." })}>
                <td>{i.name}</td>
                <td>{formatMoney(i.year)}</td>
                {new Array(12).fill(0).map((_, month) => (
                  <Fragment>
                    {month % 3 == 0 && <td>{formatMoney(i.quarters[month / 3])}</td>}
                    {showMonths && <td>{formatMoney(i.months[month])}</td>}
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
          <thead>
            <tr className="table-primary">
              <th colSpan={18}>Факт Свод</th>
            </tr>
            <tr>
              <th>Статья бюджета</th>
              <th>Год</th>
              <th>1КВ{year}</th>
              {showMonths && (
                <Fragment>
                  <th>ЯНВ{year}</th>
                  <th>ФЕВ{year}</th>
                  <th>МАРТ{year}</th>
                </Fragment>
              )}
              <th>2КВ{year}</th>
              {showMonths && (
                <Fragment>
                  <th>АПР{year}</th>
                  <th>МАЙ{year}</th>
                  <th>ИЮНЬ{year}</th>
                </Fragment>
              )}
              <th>3КВ{year}</th>
              {showMonths && (
                <Fragment>
                  <th>ИЮЛЬ{year}</th>
                  <th>АВГ{year}</th>
                  <th>СЕН{year}</th>
                </Fragment>
              )}
              <th>4КВ{year}</th>
              {showMonths && (
                <Fragment>
                  <th>ОКТ{year}</th>
                  <th>НОЯ{year}</th>
                  <th>ДЕК{year}</th>
                </Fragment>
              )}
            </tr>
          </thead>
          <tbody>
            {summaryFact.map((i) => (
              <tr className={classnames({ header: i.name === "ТЕХ. РЕЗ." })}>
                <td>{i.name}</td>
                <td>{formatMoney(i.year)}</td>
                {new Array(12).fill(0).map((_, month) => (
                  <Fragment>
                    {month % 3 == 0 && <td>{formatMoney(i.quarters[month / 3])}</td>}
                    {showMonths && <td>{formatMoney(i.months[month])}</td>}
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
          <thead>
            <tr className="table-primary">
              <th colSpan={19}>План-факт СВОД накопительным</th>
            </tr>
            <tr>
              <th>Статья бюджета</th>
              <th>ИТОГО{year}</th>
              <th>3 мес.</th>
              {showMonths && (
                <Fragment>
                  <th>ЯНВ{year}</th>
                  <th>ФЕВ{year}</th>
                  <th>МАРТ{year}</th>
                </Fragment>
              )}
              <th>6 мес.</th>
              {showMonths && (
                <Fragment>
                  <th>АПР{year}</th>
                  <th>МАЙ{year}</th>
                  <th>ИЮНЬ{year}</th>
                </Fragment>
              )}
              <th>9 мес.</th>
              {showMonths && (
                <Fragment>
                  <th>ИЮЛЬ{year}</th>
                  <th>АВГ{year}</th>
                  <th>СЕН{year}</th>
                </Fragment>
              )}
              <th>12 мес.</th>
              {showMonths && (
                <Fragment>
                  <th>ОКТ{year}</th>
                  <th>НОЯ{year}</th>
                  <th>ДЕК{year}</th>
                </Fragment>
              )}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {сumulative.map((i) => (
              <Fragment>
                <tr>
                  <td rowSpan={3}>{i.name}</td>

                  <td>{formatMoney(i.year.plan)}</td>
                  {new Array(12).fill(0).map((_, month) => (
                    <Fragment>
                      {month % 3 == 0 && <td>{formatMoney(i.quarters[month / 3].plan)}</td>}
                      {showMonths && <td>{formatMoney(i.months[month].plan)}</td>}
                    </Fragment>
                  ))}
                  <td>План</td>
                </tr>
                <tr>
                  <td>{formatMoney(i.year.fact)}</td>
                  {new Array(12).fill(0).map((_, month) => (
                    <Fragment>
                      {month % 3 == 0 && <td>{formatMoney(i.quarters[month / 3].fact)}</td>}
                      {showMonths && <td>{formatMoney(i.months[month].fact)}</td>}
                    </Fragment>
                  ))}
                  <td>Факт</td>
                </tr>
                <tr>
                  <td>{i.year.percentage}%</td>
                  {new Array(12).fill(0).map((_, month) => (
                    <Fragment>
                      {month % 3 == 0 && <td>{i.quarters[month / 3].percentage}%</td>}
                      {showMonths && <td>{i.months[month].percentage}%</td>}
                    </Fragment>
                  ))}
                  <td>% исп.</td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default observer(Summary)
