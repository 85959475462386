import React from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { TextInput, ResetButton, SelectInput, Submit, DateInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import DocumentKindSelect from "components/Common/DocumentKindSelect"
import SpecializationSelect from "components/Common/SpecializationSelect"

const Filter = ({ store, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <InsurerSelect name="by_insurer" />
        </div>
        <div className="col-3">
          <ChannelSelect name="by_channel" />
        </div>
        <div className="col-3">
          <DateInput name="by_finish_on_from" label="Период окончания с" />
        </div>
        <div className="col-3">
          <DateInput name="by_finish_on_to" label="Период окончания по" />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_target"
            label="Целевой сегмент"
            options={[
              { label: "ЮЛ", value: "0" },
              { label: "ФЛ", value: "1" },
              { label: "ЮЛ + ФЛ", value: "2" }
            ]}
            isClearable
          />
        </div>
        <div className="col-3">
          <DocumentKindSelect name="by_document_kind.id" ids={[4, 6, 26, 33]} />
        </div>
        <div className="col-3">
          <SpecializationSelect name="by_specialization.id" />
        </div>
        <div className="col-3">
          <TextInput name="by_number" label="№" />
        </div>
        <div className="col-3">
          <TextInput name="by_parent_number" label="Связанный договор" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  })
)(Filter)
