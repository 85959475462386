import React, { Fragment, useEffect, useState } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import BsoStore from "stores/BsoStore/List"
import Breadcrumbs from "pages/Bsos/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import Filter from "./components/Filter"
import Table from "./components/Table"
import Modal from "./components/Modal"
import { historyPushFromStore } from "utils/historyPushWithStore"

const bsoStore = BsoStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    bsoStore.fetch(getParams())
  }, [])

  const [selected, setSelected] = useState([])

  const onChecked = (id) => (e) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((i) => i !== id))
    } else {
      setSelected(selected.concat([id]))
    }
  }

  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const aa = selected.length ? bsoStore.data.find((i) => i.id === selected[0]) : {}
  // console.log(selected)

  console.log(bsoStore.isFetched)
  // console.log(bsoStore.toJSON())

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/bsos/new">Создать</Button>
        &nbsp;
        {selected.length === 1 && <Button onClick={openModal}>Клонировать</Button>}
      </Card>
      <Card>
        <Filter store={bsoStore} />
      </Card>

      <Modal isOpen={isOpen} closeModal={closeModal} store={aa} />
      <Table store={bsoStore} selected={selected} onChecked={onChecked} />
      <Paginate store={bsoStore} />
    </Fragment>
  )
}

export default observer(List)
