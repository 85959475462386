import React from "react"
import compose from "utils/compose"
import { TextInput, DateInput, ResetButton, Submit, Button } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"

const Filter = ({ defaultFilter, handleSubmit, checkedAll, values }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <TextInput name="by_sender" label="Отправитель" />
        </div>
        <div className="col-3">
          <TextInput name="by_sender_address" label="Адрес отправителя" />
        </div>
        <div className="col-3">
          <TextInput name="by_recipient" label="Получатель" />
        </div>
        <div className="col-3">
          <TextInput name="by_recipient_address" label="Адрес получателя" />
        </div>
        <div className="col-3">
          <DateInput name="by_departure_on_from" label="Дата отправки с" />
        </div>
        <div className="col-3">
          <DateInput name="by_departure_on_to" label="Дата отправки по" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton defaultFilter={defaultFilter} />
      &nbsp;
      <Button onClick={() => checkedAll(values)}>Выбрать найденное</Button>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) =>
      filterSubmitHandler({ ...formikBag.props.defaultFilter, ...values }, formikBag, {
        contract_id: formikBag.props.contract_id
      })
  }),
  observer
)(Filter)
