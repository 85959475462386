const prepare = (params = {}) => {
  const {
    currency: { value: currency },
    ...other
  } = params

  return { ...other, currency: parseInt(currency) }
}

export { prepare }
