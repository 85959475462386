import React from "react"
import { FieldArray } from "formik"
import { Button, TextInput } from "components/UI"
import Emails from "components/Common/Emails"
import Phones from "components/Common/Phones"

const Contact = ({ index, contact, h: { replace, remove } }) => {
  if (contact._destroy) return null
  const onDestroyClick = () => (contact.id ? replace(index, { ...contact, _destroy: true }) : remove(index))
  return (
    <div key={`contacts.${index}`}>
      <TextInput name={`contacts.${index}.l_name`} label="Фамилия" />
      <TextInput name={`contacts.${index}.f_name`} label="Имя" />
      <TextInput name={`contacts.${index}.p_name`} label="Отчество" />
      <TextInput name={`contacts.${index}.position`} label="Должность" />
      {/* <FieldArray name={`contacts.${index}.insurance_kinds`} component={InsuranceKinds} /> */}
      <FieldArray name={`contacts.${index}.emails`} component={Emails} />
      <FieldArray name={`contacts.${index}.phones`} component={Phones} />
      <Button variant="danger" onClick={onDestroyClick}>
        Удалить контакт
      </Button>
      <hr />
    </div>
  )
}
export default Contact
