import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BillStore from "stores/BillStore/One"
import Breadcrumbs from "pages/Bills/components/Breadcrumbs"
import Form from "pages/Bills/components/Form"
import { BreadcrumbItem } from "components/UI"

const Add = () => {
  const billStore = BillStore.create()

  useEffect(() => {
    billStore.fetchMeta()
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={billStore} />
    </Fragment>
  )
}

export default observer(Add)
