import React, { Fragment, useContext } from "react"
import { observer } from "mobx-react"
import { CurrencyInput, RadioButtonGroup, RadioInput } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"

const OsgopTab = ({ store }) => {
  const { contractStore } = useContext(ContractFormContext)
  const { contractOsgopKindsOptions } = contractStore.filtersOptions
  return (
    <Fragment>
      <br />
      <RadioButtonGroup label="Вид транспорта" name={"contract_osgop.kind.value"}>
        {contractOsgopKindsOptions.map(({ label, value }) => (
          <RadioInput key={`contract_osgop_kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <CurrencyInput name="contract_osgop.harm_to_life" label="Причинение вреда жизни пассажира, cтраховая сумма, руб." />
      <CurrencyInput name="contract_osgop.harm_to_health" label="Причинение вреда здоровью пассажира, cтраховая сумма, руб." />
      <CurrencyInput name="contract_osgop.harm_to_property" label="Причинение вреда имуществу пассажира, cтраховая сумма, руб." />
    </Fragment>
  )
}

export default observer(OsgopTab)
