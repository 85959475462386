import React from "react"
import { Button, Card, CardTitle } from "components/UI"
import { getIn } from "formik"
import Cargo from "./Cargo"

const Cargos = ({ name, push, form, ...h }) => {
  const onAddClick = () =>
    push({ name: null, cost: 0, condition: null, franchise: null, tariff: null, currency: { value: 0 } })
  const cargos = getIn(form.values, name)
  const cargo_prefabricated = getIn(
    form.values,
    name.split(".").length > 1
      ? name.split(".").slice(0, -1).concat(["cargo_prefabricated"]).join(".")
      : "cargo_prefabricated"
  )
  const disabled = parseInt(cargo_prefabricated.id) === 0 && cargos.length > 0
  return (
    <Card>
      <CardTitle>Грузы</CardTitle>
      {cargos.map((cargo, index) => (
        <Cargo {...{ key: `${index}`, index, cargo, h, form, name }} />
      ))}
      <Button variant="success" onClick={onAddClick} disabled={disabled}>
        Добавить груз
      </Button>
    </Card>
  )
}

export default Cargos
