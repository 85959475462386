import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import instance from "connection/instance"
import { Button } from "components/UI"

const List = () => {
  const [shoutdown, setShoutdown] = useState(false)
  useEffect(() => {
    instance.get("/shoutdowns").then(({ data }) => {
      setShoutdown(data.shoutdown)
    })
  }, [])

  const toggle = () => {
    const message = shoutdown ? "Вы уверены, что хотите перезапустить Систему?" : "Вы уверены, что хотите сделать SHUTDOWN Системы?"
    if (window.confirm(message)) {
      instance.put("/shoutdowns", { shoutdown: !shoutdown }).then(({ data }) => {
        setShoutdown(!shoutdown)
      })
    }
  }
  return (
    <div>
      <p>Статус: {shoutdown ? "Shoutdowned" : "Started"}</p>
      <Button variant={shoutdown ? "success" : "danger"} onClick={toggle}>{shoutdown ? "Start" : "Shoutdown"}</Button>
    </div>
  )
}

export default observer(List)
