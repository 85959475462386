import Breadcrumbs from "pages/Insurers/containers/InsuranceProducts/components/Breadcrumbs"
import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import InsuranceProductStore from "stores/InsuranceProductStore/One"
import Form from "pages/Insurers/containers/InsuranceProducts/components/Form"
import { BreadcrumbItem } from "components/UI"

class Add extends Component {
  insurerStore = InsurerStore.create()
  insuranceProductStore = InsuranceProductStore.create()

  componentDidMount() {
    const { insurer_id } = this.props.match.params
    this.insurerStore.fetch({ id: insurer_id })
    this.insuranceProductStore.fetchMeta({ insurer_id })
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) => {
    const { id: insurer_id } = this.insurerStore.data
    return store
      .create({ ...values, insurer_id })
      .then(() => history.push(`/insurers/${insurer_id}/insurance_products`))
      .catch(({ response }) => setErrors(response.data.errors))
  }

  render() {
    const { insurerStore, insuranceProductStore: store, handleSubmit } = this
    return (
      <Fragment>
        <Breadcrumbs {...{ insurer: insurerStore.data }}>
          <BreadcrumbItem active>Создать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit }} />
      </Fragment>
    )
  }
}

export default observer(Add)
