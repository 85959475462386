import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetMotivationsStore from "stores/BudgetMotivationStore/List"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { Button, Card, BreadcrumbItem } from "components/UI"
import BudgetStore from "stores/BudgetStore/One"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/BudgetMotivations/components/Tabs"
import instance from "connection/instance"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"

const budgetStore = BudgetStore.create()
const budgetMotivationStore = BudgetMotivationsStore.create()

const ToPayoff = (props) => {
  const { budget_id } = props.match.params
  const [accrued, setAccrued] = useState([])
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetMotivationStore.fetchAll({ budget_id })

    instance.get(`/budgets/${budget_id}/budget_motivations/to_payoff`).then(({ data }) => {
      setAccrued(data)
    })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>Мотивация</BreadcrumbItem>
        <BreadcrumbItem active>К выплате</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: "4300px" }}>
          <thead>
            <tr>
              <th colSpan={21}>Мотивация от Плана Продаж (к выплате)</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th>ИТОГО {budgetStore.data.year.toString().slice(-2)}</th>
              <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>#</th>
              <th>Подразделение/Сотрудник</th>
              <th>Метрики мотивации</th>
              <th>
                {formatMoney(
                  accrued
                    .map((values) => values.months.reduce((a, b) => a + parseMoney(b), 0))
                    .reduce((a, b) => a + parseMoney(b), 0)
                )}
              </th>
              {Array(12)
                .fill(null)
                .map((_, month) => (
                  <Fragment key={`item=${month}`}>
                    {month % 3 === 0 && (
                      <th style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          accrued.map((values) => values.quarters[month / 3]).reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </th>
                    )}
                    <th>
                      {formatMoney(
                        accrued.map((values) => values.months[month]).reduce((a, b) => a + parseMoney(b), 0)
                      )}
                    </th>
                  </Fragment>
                ))}
            </tr>
          </thead>
          <tbody>
            {accrued.map((values) => (
              <tr>
                <td>{values.id}</td>
                <td>{values.name}</td>
                <td dangerouslySetInnerHTML={{ __html: Object.values(values.metrics).join("<br />") }} />
                <td>{formatMoney(values.year)}</td>
                {values.months.map((value, month) => (
                  <Fragment key={`item=${month}`}>
                    {month % 3 === 0 && (
                      <td style={{ verticalAlign: "middle", width: "200px" }}>
                        {values.quarters[month / 3] != null ? formatMoney(values.quarters[month / 3]) : ""}
                      </td>
                    )}
                    <td>{value != null ? formatMoney(value) : ""}</td>
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default observer(ToPayoff)
