import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import InsuranceKindStore from "stores/InsuranceKindStore/List"

const insuranceKindStore = InsuranceKindStore.create()

const SubagentSelect = ({ name = "insurance_kind", required = false, label = "Вид страхования", ...props }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    insuranceKindStore.fetch({ order_by_ordered: "asc" })
  }, [])

  const getOptions = () => {
    const { insuranceKindOptions } = insuranceKindStore
    const { id: value, name: label } = getIn(values, name) || { id: null, name: null }
    if (value) {
      insuranceKindOptions.concat([{ label, value }])
    }
    return insuranceKindOptions
  }

  const insuranceKindOptions = getOptions()

  const loadInsuranceKindOptions = (value) =>
    insuranceKindStore
      .fetch({ order_by_ordered: "asc", filter: { by_name: value }, limit: 0 })
      .then(({ insuranceKindOptions }) => insuranceKindOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={insuranceKindOptions}
        required={required}
        isSearchable
        loadOptions={loadInsuranceKindOptions}
        {...props}
      />
    </div>
  )
}

export default observer(SubagentSelect)
