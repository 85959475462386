import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const OrderSubject = types.model("OrderSubject", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Position = types.model("Position", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  number: types.maybeNull(types.string),
  subdivision: types.maybeNull(Subdivision),
  position: types.maybeNull(Position)
})

const Signer = types.model("Signer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  user: types.maybeNull(User)
})

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const OrderDocument = types.model("OrderDocument", {
  id: types.maybeNull(types.integer),
  attachments: types.maybeNull(types.array(Attachment)),
  document_kind: types.maybeNull(DocumentKind),
  document_kind_other: types.maybeNull(types.string)
})

const OrderHire = types.model("OrderHire", {
  id: types.maybeNull(types.integer),
  hire_on: types.maybeNull(customDate),
  salary: types.maybeNull(types.string),
  probation: types.maybeNull(newCustomEnum),
  conditions: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  contract_number: types.maybeNull(types.string),
  contract_date_on: types.maybeNull(customDate),
  position: types.maybeNull(Position),
  subdivision: types.maybeNull(Subdivision),
  o2: types.maybeNull(types.string),
  o2_comment: types.maybeNull(types.string)
})

const OrderSalaryChange = types.model("OrderSalaryChange", {
  id: types.maybeNull(types.integer),
  date_on: types.maybeNull(customDate),
  salary: types.maybeNull(types.string),
  number: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  o2: types.maybeNull(types.string),
  o2_comment: types.maybeNull(types.string)
})

const OrderVacation = types.model("OrderVacation", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  perion_start_on: types.maybeNull(customDate),
  perion_finish_on: types.maybeNull(customDate),
  vacation_start_on: types.maybeNull(customDate),
  vacation_finish_on: types.maybeNull(customDate),
  comment: types.maybeNull(types.string)
})

const OrderChangePosition = types.model("OrderChangePosition", {
  id: types.maybeNull(types.integer),
  date_on: types.maybeNull(customDate),
  kind: types.maybeNull(types.string),
  position: types.maybeNull(Position),
  subdivision: types.maybeNull(Subdivision),
  salary: types.maybeNull(types.string),
  reason_document: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  o2: types.maybeNull(types.string),
  o2_comment: types.maybeNull(types.string)
})

const OrderFireAtWill = types.model("OrderFireAtWill", {
  id: types.maybeNull(types.integer),
  date_on: types.maybeNull(customDate),
  reason: types.maybeNull(types.string),
  reason_document: types.maybeNull(types.string),
  comment: types.maybeNull(types.string)
})

const OrderFireByAgreement = types.model("OrderFireByAgreement", {
  id: types.maybeNull(types.integer),
  date_on: types.maybeNull(customDate),
  reason: types.maybeNull(types.string),
  reason_document: types.maybeNull(types.string),
  comment: types.maybeNull(types.string)
})

const OrderAward = types.model("OrderAward", {
  id: types.maybeNull(types.integer),
  salary: types.maybeNull(types.string),
  date_on: types.maybeNull(customDate),
  reason: types.maybeNull(types.string),
  comment: types.maybeNull(types.string)
})

const Order = types.model("Order", {
  id: types.maybeNull(types.integer),
  status: types.maybeNull(newCustomEnum),
  creator: types.maybeNull(User),
  date_on: types.maybeNull(customDate),
  number: types.maybeNull(types.string),
  channel: types.maybeNull(Channel),
  order_subject: types.maybeNull(OrderSubject),
  signer: types.maybeNull(Signer),
  user: types.maybeNull(User),
  documents: types.array(OrderDocument),
  order_hire: types.maybeNull(OrderHire),
  order_salary_change: types.maybeNull(OrderSalaryChange),
  order_vacation: types.maybeNull(OrderVacation),
  order_change_position: types.maybeNull(OrderChangePosition),
  order_fire_at_will: types.maybeNull(OrderFireAtWill),
  order_award: types.maybeNull(OrderAward),
  order_fire_by_agreement: types.maybeNull(OrderFireByAgreement),
  user_number: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  created_at: types.maybeNull(customDate)
})

export { Order }
