import React from "react"
import formatDate from "utils/formatDate"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import { observer } from "mobx-react"
// const popover = (
//   <Popover id="popover-basic">
//     <Popover.Content>Данный платеж уже включен в другой АВР</Popover.Content>
//   </Popover>
// )

const UpdatingPopover = React.forwardRef(({ popper, children, show: _, ...props }, ref) => (
  <Popover ref={ref} {...props}>
    <Popover.Content>{children}</Popover.Content>
  </Popover>
))

const Checkbox = ({ kv_cost, kvCosts, toggleChange }) => {
  if (kv_cost.avr?.id) {
    return (
      <OverlayTrigger
        overlay={
          <UpdatingPopover id="popover-contained">
            Данный платеж уже включен в другой АВР (№ {kv_cost.avr.id})
          </UpdatingPopover>
        }
      >
        <span className="d-inline-block">
          <input
            type="checkbox"
            checked={kvCosts.map((i) => i.id).includes(kv_cost.id)}
            onChange={() => toggleChange(kv_cost)}
            disabled={true}
          />
        </span>
      </OverlayTrigger>
    )
  } else {
    return (
      <input
        type="checkbox"
        checked={kvCosts.map((i) => i.id).includes(kv_cost.id)}
        onChange={() => toggleChange(kv_cost)}
      />
    )
  }
}

const Table = ({ toggleChange, store, kvCosts }) => {
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Cтатус оплаты договора</th>
          <th scope="col">Cтатус сдачи договора</th>
          <th scope="col">Страховая компания</th>
          <th scope="col">Страхователь</th>
          <th scope="col">Вид страхования</th>
          <th scope="col">Номер договора</th>
          <th scope="col">Оплаченная СП</th>
          <th scope="col">Дата оплаты СП</th>
          <th scope="col">Ставка КВ, %</th>
          <th scope="col">КВ по оплаченной СП</th>
          <th scope="col">Дата начала договора</th>
        </tr>
      </thead>
      <tbody>
        {store.data.map((kv_cost, index) => (
          <tr key={`kv_cost-${index}`}>
            <td>
              <Checkbox {...{ kv_cost, kvCosts, toggleChange }} />
            </td>
            <td>{kv_cost.contract_installment.contract_payment_status.label}</td>
            <td>{}</td>
            <td>{kv_cost.contract_installment.contract.insurer.name}</td>
            <td>{kv_cost.contract_installment.contract.client.name}</td>
            <td>{kv_cost.contract_installment.contract.insurance_kind.name}</td>
            <td>{kv_cost.contract_installment.contract.number}</td>
            <td>{kv_cost.contract_installment.payment_cost}</td>
            <td>{formatDate(kv_cost.contract_installment.payment_on)}</td>
            <td>{kv_cost.kv.rate}</td>
            <td>{kv_cost.cost}</td>
            <td>{formatDate(kv_cost.contract_installment.contract.start_on)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default observer(Table)
