import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.string
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const ContractScan = types.model("ContractScan", {
  id: types.maybeNull(types.integer),
  contract_id: types.maybeNull(types.integer),
  document_kind: types.maybeNull(DocumentKind),
  document_kind_other: types.maybeNull(types.string),
  kind: types.maybeNull(newCustomEnum),
  comment: types.maybeNull(types.string),
  attachments: types.maybeNull(types.array(Attachment))
})

export { ContractScan }
