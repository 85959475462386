import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import OperationStore from "stores/OperationStore/List"
import Breadcrumbs from "pages/Operations/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"
import Filter from "./components/Filter"
import useSelectAll from "hooks/useSelectAll"
import TabsBlock from "./components/TabsBlock"

const operationStore = OperationStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    operationStore.fetch(getParams())
  }, [])

  const [
    selectedRows,
    totalSelectedRow,
    isCheckedRow,
    onCheckedRow,
    isCheckedAllRows,
    onCheckedAllRows,
    resetSelected
  ] = useSelectAll(operationStore.data.toJSON())

  return (
    <Fragment>
      <Breadcrumbs />
      <TabsBlock />
      <br/>
      <Card>
        <Button url="/operations/new">Создать</Button>
      </Card>
      <Card>
        <Filter store={operationStore} resetSelected={resetSelected} />
      </Card>
      <Table
        store={operationStore}
        selectedRows={selectedRows}
        totalSelectedRow={totalSelectedRow}
        isCheckedRow={isCheckedRow}
        onCheckedRow={onCheckedRow}
        isCheckedAllRows={isCheckedAllRows}
        onCheckedAllRows={onCheckedAllRows}
        resetSelected={resetSelected}
      />
      <Paginate store={operationStore} />
    </Fragment>
  )
}

export default observer(List)
