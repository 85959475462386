const prepare = (params = {}) => {
  const {
    insurer: { id: insurer_id },
    insurer_contact: { id: insurer_contact_id },
    channel: { id: channel_id },
    kv_by_hold: { value: kv_by_hold },
    former: { value: former },
    kind: { value: kind },
    status: { value: status },
    payment_status,
    original_status,
    kv_by_paid_sp,
    user,
    created_at,
    payment_kind: { value: payment_kind },
    avr_installments,
    avr_relations,
    // payment_status: { value: payment_status },
    operations,
    ...other
  } = params
  return {
    ...other,
    insurer_id,
    insurer_contact_id,
    channel_id,
    kv_by_hold,
    former,
    kind,
    status,
    payment_kind,
    avr_installments: avr_installments.map((avr_installment) => {
      const {
        payment_kind: { value: payment_kind },
        ...other
      } = avr_installment

      return {
        payment_kind: parseInt(payment_kind),
        ...other
      }
    }),
    avr_relations: avr_relations.map((avr_relation) => {
      const {
        relation_avr: { id: relation_avr_id },
        ...other
      } = avr_relation

      return {
        relation_avr_id,
        ...other
      }
    }),
  }
}

export { prepare }
