import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import CheckPermissions from "components/CheckPermissions"

const PaumentPlans = () => (
  // <CheckPermissions condition={(privileges) => privileges.includes("payment_plans")}>
  <Switch>
    <Route
      path="/payment_plans/:id/edit"
      component={Edit}
      condition={(currentUser) => currentUser.privileges.includes("operations")}
    />
    <Route
      path="/payment_plans/new"
      component={Add}
      condition={(currentUser) => currentUser.privileges.includes("operations")}
    />
    <Route path="/payment_plans/:id" component={Show} />
    <Route
      path="/payment_plans"
      component={List}
      condition={(currentUser) => currentUser.privileges.includes("operations")}
    />
  </Switch>
  // </CheckPermissions>
)

export default PaumentPlans
