import React from "react"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn } from "formik"
import { observer } from "mobx-react"

const CheckboxGroup = ({ label, name, children, disabled = false, ...props }) => {
  const id = idGenerator()
  const { errors } = props.formik
  const error = getIn(errors, name.replace(".value", ""), false)
  const className = classnames("form-group", { "is-invalid": error })
  const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, { name: name, disabled }))

  return (
    <div className={className}>
      <label htmlFor={id}>{label}</label>
      <fieldset>{childrenWithProps}</fieldset>
      {error && <div className="invalid-feedback">{error[0]}</div>}
    </div>
  )
}

export default compose(connect, observer)(CheckboxGroup)
