import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import CourierStore from "stores/CourierStore/One"
import Breadcrumbs from "pages/Couriers/components/Breadcrumbs"
import Form from "pages/Couriers/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"
import qs from "qs"

const courierStore = CourierStore.create()

const Edit = (props) => {
  const { id } = props.match.params
  useEffect(() => {
    courierStore.fetch({ id })
  }, [id])

  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const clone = params.clone == "true"

  let aa = "Редактировать"

  useEffect(() => {
    if (clone) {
      const recipients = JSON.parse(JSON.stringify(courierStore.data.recipients.toJSON())).map((i) => {
        return {
          ...i,
          to_comment: null,
          to_date_on: null,
          to_start: null,
          to_finish: null
        }
      })

      courierStore.applyData({
        status: 200,
        data: {
          data: {
            ...courierStore.data,
            id: null,
            is_clone: true,
            status: { value: 0 },
            contracts: [],
            task: null,
            delivery_service: { value: null },
            cost: null,
            from_comment: null,
            from_date_on: null,
            from_start: null,
            from_finish: null,
            recipients: recipients
          }
        }
      })
    }
  }, [courierStore.isFetched])

  if (clone) {
    aa = "Копирование"
  }

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/couriers/${courierStore.id}`}>{courierStore.id}</BreadcrumbItem>
        <BreadcrumbItem active>{aa}</BreadcrumbItem>
      </Breadcrumbs>
      <ContractFormContext.Provider value={{ courierStore }}>
        <Form store={courierStore} />
      </ContractFormContext.Provider>
    </Fragment>
  )
}

export default observer(Edit)
