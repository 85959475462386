import React, { Fragment, useEffect, useContext } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import AgencyContractStore from "stores/AgencyContractStore/List"
import Breadcrumbs from "pages/AgencyContracts/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"
import Filter from "./components/Filter"
import TabsBlock from "./components/TabsBlock"
import AuthStoreContext from "contexts/AuthStoreContext"

const agencyContractStore = AgencyContractStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    agencyContractStore.fetch(getParams())
  }, [])

  const { data: currentUser } = useContext(AuthStoreContext)

  return (
    <Fragment>
      <Breadcrumbs />
      <TabsBlock />
      <br />
      <Card>
        <Button url="/agency_contracts/new" disabled={![7, 8, 9].includes(currentUser.subdivision.id)}>
          Создать
        </Button>
      </Card>
      <Card>
        <Filter store={agencyContractStore} />
      </Card>
      <Table store={agencyContractStore} />
      <Paginate store={agencyContractStore} />
    </Fragment>
  )
}

export default observer(List)
