import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"

const Email = types.model("Email", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const Phone = types.model("Phone", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const Contact = types.model("Contact", {
  id: types.maybeNull(types.integer),
  l_name: types.maybeNull(types.string),
  f_name: types.maybeNull(types.string),
  p_name: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  emails: types.maybeNull(types.array(Email)),
  phones: types.maybeNull(types.array(Phone))
})

const Court = types.model("Court", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  instance: types.maybeNull(newCustomEnum),
  name: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  contacts: types.maybeNull(types.array(Contact)),
})

export { Court }
