import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"
import customDateTime from "types/customDateTime"

const Document = types.model("Document", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const ContractInstallment = types.model("User", {
  id: types.maybeNull(types.integer),
  avrs: types.frozen(),
  payment_on: types.maybeNull(customDate),
  payment_cost: types.maybeNull(types.string),
})

const ParcelBordero = types.model("ParcelBordero", {
  id: types.maybeNull(types.integer),
  contract_id: types.maybeNull(types.integer),
  start_on: types.maybeNull(customDate),
  finish_on: types.maybeNull(customDate),
  date_on: types.maybeNull(customDate),
  number: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  status: types.maybeNull(newCustomEnum),
  currency: types.maybeNull(newCustomEnum),
  documents: types.maybeNull(types.array(Document)),
  parcels: types.maybeNull(types.array(types.frozen())),
  creator: types.maybeNull(User),
  created_at: types.maybeNull(customDateTime),
  contract_installment: types.maybeNull(ContractInstallment),
})

export default ParcelBordero
