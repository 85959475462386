import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { Button, TextInput } from "components/UI"

const Form = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="email" label="Email" capitalizeValue={false} />
    <TextInput name="password" label="Пароль" type="password" capitalizeValue={false} />
    <Button type="submit">Войти</Button>
  </form>
)

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  })
)(Form)
