import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import toOptions from "utils/newToOptions"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"
import { Loss } from "./Loss"
import { prepare } from "./utils"

const LossStore = types
  .model("LossStore", {
    data: types.optional(Loss, {
      client_contact: { id: null },
      insurer_contact: { id: null },
      responsible: { id: null },
      executor: { id: null },
      status: { value: "declared" },
      result: { value: "in_work" },
      form: { value: "non_cash" },
      documents: [],
      contract: {}
    }),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get filtersOptions() {
      const { kinds, submissions } = self.meta.filters_options
      return {
        kinds: toOptions(kinds),
        submissions: toOptions(submissions)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, contract_id, ...options } = params
      const url = contract_id ? `/contracts/${contract_id}/losses/${id}` : `/losses/${id}`
      return instance
        .get(url, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta(params = {}) {
      self.setState("pending")
      const { contract_id, ...options } = params
      return instance
        .get(`/contracts/${contract_id}/losses/meta`, { options })
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/losses`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/losses/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default LossStore
