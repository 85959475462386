import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import customEnum from "types/customEnum"
import { BsoBatch } from "./BsoBatch"
import toOptions from "utils/toOptions"

const Filter = types.model("Filter", {
  by_insurer: types.maybeNull(customEnum)
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filter: {
    by_insurer: { id: null, name: null }
  },
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const BsoBatchStore = types
  .model("BsoBatch", {
    data: types.array(BsoBatch, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get bsoBatchOptions() {
      return toOptions(self.data)
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      return instance
        .get("/bso_batches", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default BsoBatchStore
