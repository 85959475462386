import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import BudgetMotivationCoefficientsStore from "stores/BudgetMotivationCoefficientStore/One"
import Form from "pages/Budgets/containers/BudgetMotivationCoefficients/components/Form"
import { BreadcrumbItem } from "components/UI"
// import BudgetFormContext from "contexts/BudgetFormContext"

const budgetStore = BudgetStore.create()
const budgetMotivationCoefficientsStore = BudgetMotivationCoefficientsStore.create()

const Show = (props) => {
  const { id, budget_id } = props.match.params
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetMotivationCoefficientsStore.fetch({ id, budget_id })
  }, [id, budget_id])
  console.log(budgetMotivationCoefficientsStore.isFetched)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>Коэффициенты</BreadcrumbItem>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}/budget_motivation_coefficients`}>
          Коэф. мотивации
        </BreadcrumbItem>
        <BreadcrumbItem active>{budgetMotivationCoefficientsStore.id}</BreadcrumbItem>
      </Breadcrumbs>
      <br />
      <div className="row">
        <div className="col-12">
          <table className="table">
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Статус</td>
                <td style={{ width: "50%" }}>{budgetMotivationCoefficientsStore.data.status.label}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Базис расчета</td>
                <td style={{ width: "50%" }}>{budgetMotivationCoefficientsStore.data.basis.label}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Подразделения</td>
                <td style={{ width: "50%" }}>
                  {budgetMotivationCoefficientsStore.data.subdivisions.map((i) => i.name).join(", ")}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Комментарий</td>
                <td style={{ width: "50%" }}>
                  {budgetMotivationCoefficientsStore.data.comment}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className="table">
            <thead>
              <tr>
                <th>Лимит</th>
                <th>Исполнение плана от, %</th>
                <th>Исполнение плана до, %</th>
                <th>Поправочный коэфициент</th>
              </tr>
            </thead>
            <tbody>
              {budgetMotivationCoefficientsStore.data.budget_motivation_coefficient_limits.map((i, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{i.bottom_limit}</td>
                  <td>{i.upper_limit}</td>
                  <td>{i.coefficient}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

export default observer(Show)
