import React, { useState } from "react"
import { observer } from "mobx-react"
import { getIn, useFormikContext } from "formik"
import { Link } from "react-router-dom"
import { Button } from "components/UI"
import Modal from "./Modal"

const RelationSelect = ({ name, disabled = false }) => {
  const { values, setFieldValue } = useFormikContext()

  const relation_avrs = getIn(values, name)

  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const onDestroy = () => {
    setFieldValue(name, { id: null, number: null })
  }
  
  return (
    <div className="form-group">
      <label>
        Связанные АВР:&nbsp;
        {relation_avrs.map((relation_avr, index) => ([
          index > 0 && ", ",
          <Link target="_blank" to={`/avrs/${relation_avr.id}`}>
            {relation_avr.number}
          </Link>
        ]))}
      </label>
      <fieldset>
        <Button onClick={openModal} disabled={disabled}>Выбрать</Button>
      </fieldset>
      <Modal isOpen={isOpen} relation_avrs={relation_avrs} closeModal={closeModal} setFieldValue={setFieldValue} name={name} />
    </div>
  )
}

export default observer(RelationSelect)
