import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import PrivateRoute from "components/PrivateRoute"

const InsurerDocuments = () => (
  <Switch>
    <PrivateRoute
      path="/insurers/:insurer_id/insurer_documents/:id/edit"
      component={Edit}
      condition={(currentUser) => currentUser.privileges.includes("insurers")}
    />
    <PrivateRoute
      path="/insurers/:insurer_id/insurer_documents/new"
      component={Add}
      condition={(currentUser) => currentUser.privileges.includes("insurers")}
    />
    <Route path="/insurers/:insurer_id/insurer_documents/:id" component={Show} />
    <Route path="/insurers/:insurer_id/insurer_documents" component={List} />
  </Switch>
)

export default InsurerDocuments
