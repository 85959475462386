import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import SubdivisionStore from "stores/SubdivisionStore/One"
import Breadcrumbs from "pages/Subdivisions/components/Breadcrumbs"
import Form from "pages/Subdivisions/components/Form"
import { BreadcrumbItem } from "components/UI"

const subdivisionStore = SubdivisionStore.create()

const Edit = (props) => {
  const { id } = props.match.params
  useEffect(() => {
    subdivisionStore.fetch({ id })
  }, [id])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{subdivisionStore.name}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={subdivisionStore} />
    </Fragment>
  )
}

export default observer(Edit)
