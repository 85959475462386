import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import ShipmentStore from "stores/ShipmentStore/One"
import Form from "pages/Contracts/containers/Shipments/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"

const contractStore = ContractStore.create()
const shipmentStore = ShipmentStore.create()

const Edit = (props) => {
  const { id, contract_id } = props.match.params
  useEffect(() => {
    contractStore.fetch({ id: contract_id })
    shipmentStore.fetch({ id, contract_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contract_id}/shipments`}>Отгрузки</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        <BreadcrumbItem active>{shipmentStore.number}</BreadcrumbItem>
      </Breadcrumbs>
      <ContractFormContext.Provider value={{ contractStore }}>
        <Form {...{ store: shipmentStore, contractStore }} />
      </ContractFormContext.Provider>
    </Fragment>
  )
}

export default observer(Edit)
