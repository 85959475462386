import React from "react"
import DocumentFlowBsoItem from "./DocumentFlowBsoItem"
import NestedFields from "components/UI/NestedFields"
import { observer } from "mobx-react"

const defaultValues = {
  insurer: { id: null },
  bso_kind: { id: null },
  quantity: 0
}

const DocumentFlowBsoItems = () => (
  <NestedFields
    name="document_flow_bso.items"
    // label="Что здесь вписать?"
    component={DocumentFlowBsoItem}
    defaultValues={defaultValues}
  />
)

export default observer(DocumentFlowBsoItems)
