import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const BsoKind = types.model("BsoKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  subdivision: types.maybeNull(Subdivision)
})

const Subagent = types.model("Subagent", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const DocumentFlowBsoItem = types.model("DocumentFlowBsoItem", {
  id: types.maybeNull(types.integer),
  quantity: types.maybeNull(types.string),
  insurer: types.maybeNull(Insurer),
  bso_kind: types.maybeNull(BsoKind),
})

const DocumentFlowBso = types.model("DocumentFlowBso", {
  id: types.maybeNull(types.integer),
  recipient: types.maybeNull(newCustomEnum),
  subagent: types.maybeNull(Subagent),
  items: types.maybeNull(types.array(DocumentFlowBsoItem))
})

const DocumentFlow = types.model("DocumentFlow", {
  id: types.maybeNull(types.integer),
  urgency: types.maybeNull(newCustomEnum),
  customer: types.maybeNull(User),
  executor: types.maybeNull(User),
  kind: types.maybeNull(newCustomEnum),
  status: types.maybeNull(newCustomEnum),
  comment: types.maybeNull(types.string),
  document_flow_bso: types.maybeNull(DocumentFlowBso),
  created_at: customDate
})

export { DocumentFlow }
