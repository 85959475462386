import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import RouteStore from "stores/RouteStore/One"
import Breadcrumbs from "pages/Routes/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"

class Show extends Component {
  routeStore = RouteStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.routeStore.fetch({ id })
  }

  render() {
    const {
      routeStore: {
        data: {
          id,
          name,
          from_organization,
          from_contact,
          from_phone,
          from_address,
          from_comment,
          to_organization,
          to_contact,
          to_phone,
          to_address,
          to_comment,
          creator
        }
      }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumbs>
        <Card>
          <Button url={`/routes/${id}/edit`}>Редактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            <br />
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Название маршрута</td>
                  <td style={{ width: "50%" }}>{name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Создал</td>
                  <td style={{ width: "50%" }}>{creator?.name}</td>
                </tr>
              </tbody>
            </table>

            <table className="table">
              <thead>
                <tr>
                  <th colSpan="2">Отправитель</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Организация</td>
                  <td style={{ width: "50%" }}>{from_organization.name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Имя адресата (контактное лицо)</td>
                  <td style={{ width: "50%" }}>{from_contact}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Телефон</td>
                  <td style={{ width: "50%" }}>{from_phone}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Адрес</td>
                  <td style={{ width: "50%" }}>{from_address}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Комментарий</td>
                  <td style={{ width: "50%" }}>{from_comment}</td>
                </tr>
              </tbody>
            </table>

            <table className="table">
              <thead>
                <tr>
                  <th colSpan="2">Получатель</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Организация</td>
                  <td style={{ width: "50%" }}>{to_organization.name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Имя адресата (контактное лицо)</td>
                  <td style={{ width: "50%" }}>{to_contact}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Телефон</td>
                  <td style={{ width: "50%" }}>{to_phone}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Адрес</td>
                  <td style={{ width: "50%" }}>{to_address}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Комментарий</td>
                  <td style={{ width: "50%" }}>{to_comment}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
