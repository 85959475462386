import React, { useContext } from "react"
import Kv from "./Kv"
import NestedFields from "components/UI/NestedFields"
import { observer } from "mobx-react"
import { useFormikContext } from "formik"
import AuthStoreContext from "contexts/AuthStoreContext"

const defaultValues = {
  rate: 0,
  by_contract: 0,
  by_paid_sp: 0,
  channel: { id: null }
}

const Kvs = () => {
  const { values } = useFormikContext()
  // const collection = values.kvs.slice(1)

  const { data: currentUser } = useContext(AuthStoreContext)

  const hasAvr = values.contract_installments.some((i) => i.avrs.length > 0)
  const disabled = ![3, 5, 10, 23, 49, 57].includes(currentUser.id) || hasAvr
  return (
    <NestedFields
      name="kvs"
      // label="КВ по договору страхования"
      component={Kv}
      defaultValues={defaultValues}
      // showDestroy={(index) => index > 0}
      disabledAdd={values.has_additional || String(values.is_without_kv) === "true"}
      minIndex={1}
      // collection={collection}
      disabledDestroy={disabled}
    />
  )
}

export default observer(Kvs)
