import React, { Fragment, useContext } from "react"
import { Button, Card, CurrencyInput, DateInput, RadioButtonGroup, RadioInput } from "components/UI"
import { setSpSinergyPaymentCost } from "./caclulateCommissionByPaidSp"
import { getIn } from "formik"
import parseMoney from "utils/parseMoney"
import AuthStoreContext from "contexts/AuthStoreContext"
import Accordion from "react-bootstrap/Accordion"
import BCard from "react-bootstrap/Card"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"

const getDate = (contract_installment) => {
  if (contract_installment.sp_sk_payment_on) return formatDate(contract_installment.sp_sk_payment_on)
  if (contract_installment.sp_payment_on) return formatDate(contract_installment.sp_payment_on)
  return "-"
}

const ContractInstallment = ({
  index,
  contract_installment,
  store,
  form,
  activeKey,
  setActiveKey,
  h: { replace, remove }
}) => {
  const { sp_sinergy_payment_forms, sp_sk_payment_forms } = store.filtersOptions
  const { data: currentUser } = useContext(AuthStoreContext)

  if (contract_installment._destroy) return null

  const onDestroyClick = () => {
    contract_installment.id ? replace(index, { ...contract_installment, _destroy: true }) : remove(index)
    form.setFieldValue(
      "sp_accrual_cost",
      form.values.contract_installments.reduce((a, b) => a + (b._destroy ? 0 : parseMoney(b.sp_payment_cost)), 0) -
        parseMoney(contract_installment.sp_payment_cost)
    )
  }

  let background = "#f8f9fc"
  if (
    contract_installment.sp_payment_on &&
    contract_installment.sp_payment_cost &&
    contract_installment.sp_payment_cost !== "0.00" &&
    contract_installment.sp_sk_payment_on
  ) {
    background = "#c3e6cb"
  }
  if (
    contract_installment.sp_payment_on &&
    contract_installment.sp_payment_cost &&
    contract_installment.sp_payment_cost !== "0.00" &&
    !contract_installment.sp_sk_payment_on
  ) {
    background = "#ffeeba"
  }
  if (
    !contract_installment.sp_payment_on &&
    (!contract_installment.sp_payment_cost || !contract_installment.sp_payment_cost === "0.00") &&
    !contract_installment.sp_sk_payment_on
  ) {
    background = "#f5c6cb"
  }

  const hasAvr = contract_installment.avrs.length > 0

  return (
    <BCard>
      <Accordion.Toggle
        style={{ background: background }}
        as={BCard.Header}
        eventKey={index.toString()}
        onClick={(e) => (activeKey === index.toString() ? setActiveKey(null) : setActiveKey(index.toString()))}
      >
        {form.values.cargo_kind.id.toString() === "1" ? "СП по бордеро №" : "Платеж №"} {index + 1} | Дата:{" "}
        {getDate(contract_installment)} | СП: {formatMoney(contract_installment.sp_payment_cost)}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index.toString()}>
        <div style={{ padding: "1.25rem" }}>
          <div key={`contract_installments.${index}`}>
            <Fragment>
              <h5>
                {form.values.cargo_kind.id.toString() === "1" ? "СП по бордеро №" : "Платеж №"} {index + 1}
              </h5>
              <DateInput
                name={`contract_installments.${index}.sp_payment_on`}
                label="Дата очередного платежа"
                required={form.values.status.value.toString() === "1"}
                disabled={
                  (form.values.status.value.toString() === "1" &&
                    form.initialValues.status.value.toString() === "1" &&
                    form.values.contract_installments[index].id) ||
                  (form.values.has_additional &&
                    form.values.contract_installments[index].id &&
                    getIn(form.values, `contract_installments.${index}.sp_sk_payment_on`)) ||
                  form.values.cargo_kind.id.toString() === "1" ||
                  (form.values.contract_installments[index].id &&
                    form.values.status.value.toString() === "1" &&
                    form.initialValues.status.value.toString() === "1" &&
                    getIn(form.values, `contract_installments.${index}.sp_sk_payment_on`) &&
                    getIn(form.values, `contract_installments.${index}.sp_sk_payment_cost`)) ||
                  hasAvr
                }
              />
              <CurrencyInput
                name={`contract_installments.${index}.sp_payment_cost`}
                label="Сумма очередного платежа"
                required={form.values.status.value.toString() === "1"}
                disabled={
                  (form.values.status.value.toString() === "1" &&
                    form.initialValues.status.value.toString() === "1" &&
                    form.values.contract_installments[index].id) ||
                  (form.values.has_additional &&
                    form.values.contract_installments[index].id &&
                    getIn(form.values, `contract_installments.${index}.sp_sk_payment_on`)) ||
                  form.values.cargo_kind.id.toString() === "1" ||
                  (form.values.contract_installments[index].id &&
                    form.values.status.value.toString() === "1" &&
                    form.initialValues.status.value.toString() === "1" &&
                    getIn(form.values, `contract_installments.${index}.sp_sk_payment_on`) &&
                    getIn(form.values, `contract_installments.${index}.sp_sk_payment_cost`)) ||
                  hasAvr
                }
                onChange={(e) => {
                  form.setFieldValue(`contract_installments.${index}.sp_payment_cost`, e.target.value)
                  form.setFieldValue(
                    "sp_accrual_cost",
                    form.values.contract_installments.reduce(
                      (a, b, i) => a + (b._destroy || i === index ? 0 : parseMoney(b.sp_payment_cost)),
                      0
                    ) + parseMoney(e.target.value)
                  )
                  if (form.values.contract_installments[index].is_payments_equal) {
                    form.setFieldValue(`contract_installments.${index}.sp_sk_payment_cost`, e.target.value)
                  }
                }}
              />
            </Fragment>

            {[0, 2].includes(parseInt(contract_installment.sp_payment_kind.value)) && (
              <Fragment>
                <DateInput
                  name={`contract_installments.${index}.sp_sk_payment_on`}
                  label="Дата оплаты СП Страхователем в СК"
                  disabled={
                    (form.values.contract_installments[index] &&
                      form.values.contract_installments[index].avrs.some((i) => i.payment_status.value === 1)) ||
                    hasAvr
                  }
                  onChange={(e) => {
                    form.setFieldValue(`contract_installments.${index}.sp_sk_payment_on`, e)
                    if (e === null) {
                      form.setFieldValue(`contract_installments.${index}.sp_sk_payment_cost`, null)
                    } else {
                      form.setFieldValue(
                        `contract_installments.${index}.sp_sk_payment_cost`,
                        form.values.contract_installments[index].sp_payment_cost
                      )
                    }
                  }}
                  maxDate={[3, 5].includes(currentUser.id) ? null : new Date()}
                />
                <RadioButtonGroup
                  label="Форма оплаты СП Страхователем в СК"
                  name={`contract_installments.${index}.sp_sk_payment_form.value`}
                  disabled={
                    (form.values.contract_installments[index] &&
                      form.values.contract_installments[index].avrs.some((i) => i.payment_status.value === 1)) ||
                    hasAvr
                  }
                >
                  {sp_sinergy_payment_forms.map(({ label, value }) => (
                    <RadioInput
                      key={`contract_installments.${index}.sp_sk_payment_form_${value}`}
                      label={label}
                      id={value}
                    />
                  ))}
                </RadioButtonGroup>
              </Fragment>
            )}

            {[1, 2].includes(parseInt(contract_installment.sp_payment_kind.value)) && (
              <Fragment>
                <DateInput
                  name={`contract_installments.${index}.sp_sinergy_payment_on`}
                  label="Дата оплаты СП в СИ-нерджи"
                  disabled={
                    ((form.values.status.value.toString() === "1" &&
                      form.initialValues.status.value.toString() === "1") ||
                      form.values.has_additional) &&
                    getIn(form.values, `contract_installments.${index}.sp_sinergy_payment_on`)
                  }
                />
                <CurrencyInput
                  name={`contract_installments.${index}.sp_sinergy_payment_cost`}
                  label="Сумма поступившей СП в СИ-нерджи"
                  onChange={(e) => {
                    form.setFieldValue(`contract_installments.${index}.sp_sinergy_payment_cost`, e.target.value)
                    form.setFieldValue("kvs.0.by_paid_sp", setSpSinergyPaymentCost(form.values, index, e.target.value))
                  }}
                />
                <RadioButtonGroup
                  label="Форма оплаты СП в СИ-нерджи"
                  name={`contract_installments.${index}.sp_sinergy_payment_form.value`}
                  disabled={
                    (form.values.status.value.toString() === "1" &&
                      form.initialValues.status.value.toString() === "1") ||
                    (form.values.has_additional && form.values.contract_installments[index].id)
                  }
                >
                  {sp_sk_payment_forms.map(({ label, value }) => (
                    <RadioInput
                      key={`contract_installments.${index}.sp_sinergy_payment_form_${value}`}
                      label={label}
                      id={value}
                    />
                  ))}
                </RadioButtonGroup>
              </Fragment>
            )}

            {form.values.subagent.id && (
              <Card style={{ background: "#f8f9fa" }}>
                <CurrencyInput
                  name={`contract_installments.${index}.subagent_cost`}
                  label="Сумма к выплате субагенту"
                  required={form.values.status.value.toString() === "1"}
                  disabled={
                    (form.values.status.value.toString() === "1" &&
                      form.initialValues.status.value.toString() === "1" &&
                      getIn(form.values, `contract_installments.${index}.id`)) ||
                    (form.values.has_additional && form.values.contract_installments[index].id)
                  }
                />
                <DateInput
                  name={`contract_installments.${index}.subagent_cost_planned_on`}
                  label="Планируемая дата выплаты субагенту"
                  disabled={
                    (form.values.status.value.toString() === "1" &&
                      form.initialValues.status.value.toString() === "1" &&
                      getIn(form.values, `contract_installments.${index}.id`)) ||
                    (form.values.has_additional && form.values.contract_installments[index].id)
                  }
                />
                <DateInput
                  name={`contract_installments.${index}.subagent_cost_fact_on`}
                  label="Фактическая дата выплаты субагенту"
                  disabled={
                    (form.values.has_additional && form.values.contract_installments[index].id) ||
                    (![5, 23, 49, 55, 57].includes(currentUser.id) && currentUser.subdivision.id !== 9)
                  }
                />
              </Card>
            )}

            {((form.values.status.value.toString() === "0" &&
              getIn(form.values, `contract_installments.${index}.avrs`, 0).length === 0) ||
              (form.values.status.value.toString() === "1" &&
                !getIn(form.values, `contract_installments.${index}.id`)) ||
              (form.values.status.value.toString() === "1" &&
                getIn(form.values, `contract_installments.${index}.payment_on`) === "" &&
                getIn(form.values, `contract_installments.${index}.payment_cost`) === "") ||
              hasAvr) && (
              <Fragment>
                <Button variant="danger" onClick={onDestroyClick}>
                  Удалить платеж
                </Button>
                <hr />
              </Fragment>
            )}
          </div>
        </div>
      </Accordion.Collapse>
    </BCard>
  )
}
export default ContractInstallment
