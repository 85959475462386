import React from "react"
import compose from "utils/compose"
import formSubmitHandler from "utils/formSubmitHandler"
import { observer } from "mobx-react"
import { Button, TextInput } from "components/UI"
import Modal from "react-modal"
import { withFormik } from "formik"
import BsoStore from "stores/BsoStore/One"

const bsoStore = BsoStore.create()

const Mmodal = ({ isOpen, closeModal, values }) => {
  const handleSubmit = () => {
    bsoStore.cloneee(values)
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className="modal" ariaHideApp={false}>
      <div className="modal-dialog modal-xxl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Клонировать</h5>
            <button type="button" className="close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <TextInput name="number_from" disabled label="Номер БСО c" />
              <TextInput name="number_to" label="Номер БСО по" />
            </form>
          </div>
          <div className="modal-footer">
            <Button onClick={handleSubmit}>Сохранить</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ id: store.id, number_from: store.number, number_to: null }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/bsos")
  }),
  observer
)(Mmodal)
