import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"

const Filter = types.model("Filter", {
  by_user: types.maybeNull(customEnum),
  by_status: types.maybeNull(types.string),
  by_created_at: types.maybeNull(customDate),
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const FilterOptions = types.model("FilterOptions", {
  users: types.array(User, []),
  statuses: types.array(newCustomEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

export default Meta
