import React from "react"
import { Destroy, Edit } from "components/UI"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import formatDate from "utils/formatDate"
import { observer } from "mobx-react"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { withRouter } from "react-router-dom"
import { getIn } from "formik"

const paymentStatus = (contract_installments) => {
  const count = contract_installments.filter((i) => i.payment_on).length
  switch (count) {
    case 0:
      return (
        <i className="fa fa-circle" style={{ color: "red" }} title="Ни один из платежей СП не оплачен Страхователем" />
      )
    case contract_installments.length:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Все платежи СП оплачены Страхователем" />
    default:
      return (
        <i
          className="fa fa-circle"
          style={{ color: "orange" }}
          title="Хотя бы один из платежей СП оплачен Страхователем"
        />
      )
  }
}

const documentStatus = (status) => {
  switch (status.value) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    case 1:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">№</th>
        <th scope="col">Страховая компания</th>
        <th scope="col">Страхователь</th>
        <th scope="col">Вид страхования</th>
        <th scope="col">№ договора</th>
        <th scope="col">Период</th>
        <th scope="col">Опл. СП</th>
        <th scope="col">КВ по опл. СП</th>
        <th scope="col">Статус</th>
        <th scope="col">Опл. СП</th>
        <th scope="col">
          <i className="fas fa-paperclip"></i>
        </th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map(
        (
          {
            id,
            insurer,
            client,
            insurance_kind,
            number,
            status,
            contract_installments,
            contract_returnments,
            kvs,
            start_on,
            finish_on,
            versions,
            contract_scans
          },
          index
        ) => (
          <tr key={`contracts-${index}`}>
            <td onClick={() => history.push(`/contracts/${id}`)}>{id}</td>
            <td onClick={() => history.push(`/contracts/${id}`)}>{insurer.name}</td>

            <td onClick={() => history.push(`/contracts/${id}`)}>
              {client.kind === "individual"
                ? client.name
                : `${client.name} ${client.ownership_kind.value == 9 ? client.other_ownership_kind : client.ownership_kind.label}`}
            </td>
            <td onClick={() => history.push(`/contracts/${id}`)}>{insurance_kind.name}</td>
            <td onClick={() => history.push(`/contracts/${id}`)}>
              {insurance_kind.id === 14
                ? getIn(versions[versions.length - 1], "additional_agreement_number", number)
                : number}
            </td>
            <td onClick={() => history.push(`/contracts/${id}`)}>
              {formatDate(start_on)} - {formatDate(finish_on)}
            </td>
            <td onClick={() => history.push(`/contracts/${id}`)}>
              {formatMoney(
                contract_installments.reduce((accumulator, i) => accumulator + parseMoney(i.payment_cost), 0)
              )}
            </td>
            <td onClick={() => history.push(`/contracts/${id}`)}>
              {formatMoney(
                contract_installments.reduce((a, i) => a + parseMoney(i.payment_cost), 0) *
                  kvs.reduce((a, i) => a + parseMoney(i.rate), 0) *
                  0.01 -
                  contract_returnments.reduce((a, i) => a + parseMoney(i.payment_cost), 0) *
                    kvs.reduce((a, i) => a + parseMoney(i.rate), 0) *
                    0.01 +
                  contract_installments.reduce((a, b) => a + (b.payment_on ? parseMoney(b.fix_kv_cost) : 0), 0)
              )}
            </td>
            <td onClick={() => history.push(`/contracts/${id}`)}>{documentStatus(status)}</td>
            <td onClick={() => history.push(`/contracts/${id}`)}>{paymentStatus(contract_installments)}</td>
            <td onClick={() => history.push(`/contracts/${id}`)}>
              {contract_scans.length > 0 ? <i className="fas fa-paperclip"></i> : "-"}
            </td>
            <td>
              <Edit url={`/contracts/${id}/edit`} />
              <Destroy action={() => afterDestroyFetch(store, id)} />
            </td>
          </tr>
        )
      )}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
