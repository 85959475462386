import React, { Component, Fragment } from "react"
import afterDestroyFetch from "utils/afterDestroyFetch"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import CourtStore from "stores/CourtStore/One"
import ContactStore from "stores/ContactStore/List"
import Breadcrumbs from "pages/Courts/components/Breadcrumbs"
import Tabs from "pages/Courts/components/Tabs"
import { Button, Card, Paginate } from "components/UI"
import { BreadcrumbItem } from "components/UI"
import Table from "./components/Table"

class List extends Component {
  courtStore = CourtStore.create()
  contactStore = ContactStore.create()

  componentDidMount() {
    const { court_id } = this.props.match.params
    this.courtStore.fetch({ id: court_id })
    this.contactStore.fetch({ ...getParams(), court_id })
  }

  onDestroyClick = (id) => {
    const { id: court_id } = this.courtStore
    afterDestroyFetch(this.contactStore, { id, court_id }, { court_id })
  }

  onPageChange = (params) => {
    const { id: court_id } = this.courtStore
    this.contactStore.fetch({ ...params, court_id })
  }

  render() {
    const {
      props: {
        match: {
          params: { court_id }
        }
      },
      onDestroyClick,
      courtStore,
      contactStore
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem url={`/courts/${courtStore.id}`}>{courtStore.data.name}</BreadcrumbItem>
          <BreadcrumbItem active>Контактные лица</BreadcrumbItem>
        </Breadcrumbs>
        <Tabs store={courtStore} />
        <br />
        <Card>
          <Button url={`/courts/${court_id}/contacts/new`}>Создать</Button>
        </Card>
        <Table {...{ data: contactStore.data.toJSON(), court_id, onDestroyClick }} />
        <Paginate store={contactStore} />
      </Fragment>
    )
  }
}

export default observer(List)
