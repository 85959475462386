import React from "react"
import { Link } from "react-router-dom"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Название Страхователя</th>
      </tr>
    </thead>
    <tbody>
      {store.data.map((i, index) => (
        <tr key={`clients-${index}`}>
          <td><Link to={`/clients/${i.id}`}>{i.name}</Link></td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
