const prepare = (params = {}) => {
  const {
    kind: { id: kind_id },
    client: { id: client_id },
    channel_client: { id: channel_client_id },
    channel_subagent: { id: channel_subagent_id },
    ...other
  } = params
  return { ...other, kind_id, client_id, channel_client_id, channel_subagent_id }
}

export { prepare }
