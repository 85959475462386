import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const caclulateCommissionByPaidSp = ({ rate, contract_installments }) =>
  formatMoney(
    contract_installments
      .map((contract_installment) => {
        const { sp_payment_kind, sp_sk_payment_cost, sp_sinergy_payment_cost } = contract_installment
        return caclulateCommissionForSingularize({ sp_payment_kind, sp_sk_payment_cost, sp_sinergy_payment_cost, rate })
      })
      .reduce((a, b) => a + b, 0)
  )

const caclulateCommissionForSingularize = ({ sp_payment_kind, sp_sk_payment_cost, sp_sinergy_payment_cost, rate }) => {
  sp_sk_payment_cost = parseMoney(sp_sk_payment_cost)
  sp_sinergy_payment_cost = parseMoney(sp_sinergy_payment_cost)
  rate = parseMoney(rate)

  switch (parseInt(sp_payment_kind.value)) {
    case 0: {
      return sp_sk_payment_cost * (rate * 0.01)
    }
    case 1: {
      return sp_sinergy_payment_cost * (rate * 0.01)
    }
    default: {
      return (sp_sk_payment_cost + sp_sinergy_payment_cost) * (rate * 0.01)
    }
  }
}

const setSpSinergyPaymentCost = (values, index, sp_sinergy_payment_cost) => {
  sp_sinergy_payment_cost = parseMoney(sp_sinergy_payment_cost)
  const contract_installments = values.contract_installments.map((contract_installment, i) => {
    if (index === i) {
      return { ...contract_installment, sp_sinergy_payment_cost }
    }
    return contract_installment
  })
  return caclulateCommissionByPaidSp({ ...values, contract_installments })
}

const setSpSkPaymentCost = (values, index, sp_sk_payment_cost) => {
  sp_sk_payment_cost = parseMoney(sp_sk_payment_cost)
  const contract_installments = values.contract_installments.map((contract_installment, i) => {
    if (index === i) {
      return { ...contract_installment, sp_sk_payment_cost }
    }
    return contract_installment
  })
  return caclulateCommissionByPaidSp({ rate: values.kvs[0].rate, contract_installments })
}

export { caclulateCommissionByPaidSp, setSpSinergyPaymentCost, setSpSkPaymentCost }
