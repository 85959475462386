import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import LossesStore from "stores/LossStore/One"
import Form from "pages/Contracts/containers/Losses/components/Form"
import { BreadcrumbItem } from "components/UI"

const Add = (props) => {
  const { contract_id } = props.match.params
  const contractStore = ContractStore.create()
  const lossStore = LossesStore.create()

  useEffect(() => {
    contractStore.fetch({ id: contract_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contract_id}/losses`}>Убытки</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form {...{ store: lossStore, contractStore }} />
    </Fragment>
  )
}

export default observer(Add)
