import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import InsuranceKindStore from "stores/InsuranceKindStore/List"
import Breadcrumbs from "pages/InsuranceKinds/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"

const insuranceKindStore = InsuranceKindStore.create()

const List = () => {
  useEffect(() => {
    insuranceKindStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/insurance_kinds/new">Создать</Button>
      </Card>
      <Table store={insuranceKindStore} />
      <Paginate store={insuranceKindStore} />
    </Fragment>
  )
}

export default observer(List)
