import React, { Fragment } from "react"
import { TextInput } from "components/UI"
import Attachments from "./Attachments"
import DocumentKindSelect from "components/Common/DocumentKindSelect"

const Document = ({ prefix = null }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  return (
    <Fragment>
      <DocumentKindSelect name={path("document_kind.id")} />
      <TextInput name={path("comment")} label="Комментарий" />
      <Attachments name={path("attachments")} />
    </Fragment>
  )
}
export default Document
