const prepare = (params = {}) => {
  const {
    kind: { value: kind },
    ...other
  } = params
  return {
    ...other,
    kind
  }
}

export { prepare }
