import React, { useContext } from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { withRouter } from "react-router-dom"
import Badge from "react-bootstrap/Badge"
import AuthStoreContext from "contexts/AuthStoreContext"

const status = ({ finish_on, termination_on, start_on, is_prolongation }) => {
  if (is_prolongation) {
    if (new Date() < start_on && !termination_on) {
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не действует" />
    }
    if (new Date() >= start_on && !termination_on) {
      return <i className="fa fa-circle" style={{ color: "green" }} title="Действует" />
    }
    if (new Date() < termination_on) {
      return <i className="fa fa-circle" style={{ color: "green" }} title="Действует" />
    }
    if (new Date() >= termination_on) {
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не действует" />
    }
  } else {
    if (new Date() < start_on) {
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не действует" />
    }
    if (new Date() > finish_on) {
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не действует" />
    }
    if (new Date() >= start_on && new Date() <= finish_on && !termination_on) {
      return <i className="fa fa-circle" style={{ color: "green" }} title="Действует" />
    }
    if (new Date() <= finish_on && new Date() < termination_on) {
      return <i className="fa fa-circle" style={{ color: "green" }} title="Действует" />
    }
    if (new Date() >= termination_on) {
      return <i className="fa fa-circle" style={{ color: "red" }} title="Не действует" />
    }
  }
}

const Table = ({ history, store }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th>ID</th>
          <th>Статус</th>
          <th>Страховая компания</th>
          <th>Канал продаж</th>
          <th>Вид документа</th>
          <th>№</th>
          <th>Осн.документ</th>
          <th>Дата заключения</th>
          <th>Срок действия договора</th>
          <th>Дата расторжения</th>
          <th>Целевой сегмент</th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {store.data.map((i, index) => (
          <tr key={`agency_contracts-${index}`}>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>{i.id}</td>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>{status(i)}</td>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>{i.insurer.name}</td>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>{i.channel.name}</td>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>{i.document_kind?.name}</td>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>{i.number}</td>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>
              {i.document_kind?.id === 4 ? "-" : i.parent?.number}
            </td>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>
              {i.is_prolongation ? (
                <Badge variant="success">{formatDate(i.conclusion_on)}</Badge>
              ) : (
                formatDate(i.conclusion_on)
              )}
            </td>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>
              {formatDate(i.start_on)} - {!i.is_prolongation && formatDate(i.finish_on)}
            </td>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>{formatDate(i.termination_on)}</td>
            <td onClick={() => history.push(`/agency_contracts/${i.id}`)}>{i.target.label}</td>
            <td>
              <Edit url={`/agency_contracts/${i.id}/edit`} disabled={![7, 8, 9].includes(currentUser.subdivision.id)} />
              <Destroy
                action={() => afterDestroyFetch(store, i.id)}
                disabled={![7, 8, 9].includes(currentUser.subdivision.id)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default withRouter(observer(Table))
