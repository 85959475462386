import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Insurer } from "./Insurer"
import { prepare } from "./utils"

const InsurerStore = types
  .model("InsurerStore", {
    data: types.optional(Insurer, { name: "", requisites: "", contacts: [], specializations: [] }),
    meta: types.optional(types.map(types.number), {}),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get contacts() {
      return self.data.contacts
    },

    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get name() {
      return self.data.name
    },

    get short_name() {
      return self.data.short_name
    },

    get address() {
      return self.data.address
    },

    get inn() {
      return self.data.inn
    },

    get comment() {
      return self.data.comment
    },

    get site() {
      return self.data.site
    },

    get creator() {
      return self.data.creator
    },

    get updated_at() {
      return self.data.updated_at
    },

    // get phone() {
    //   return self.data.phone
    // },

    get specializations() {
      return self.data.specializations
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/insurers/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/insurers`, { data })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/insurers/${data.id}`, { data })
        .then((response) => this.resetStore(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default InsurerStore
