import React from "react"
import { Button, SelectInput, TextInput } from "components/UI"

const Risk = ({ index, risk, store, h: { replace, remove, ...o } }) => {
  if (risk._destroy) return null
  const onDestroyClick = () => (risk.id ? replace(index, { ...risk, _destroy: true }) : remove(index))
  const [riskOptions] = store.filtersOptions
  return (
    <div key={`risks.${index}`}>
      <SelectInput name={`risks.${index}.risk_id`} label="Риск" options={riskOptions} />
      <TextInput name={`risks.${index}.rate`} label="Ставка КВ, %" />
      <Button variant="danger" onClick={onDestroyClick}>
        Удалить риск
      </Button>
      <hr />
    </div>
  )
}
export default Risk
