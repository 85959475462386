import React from "react"
import { Destroy, Edit, Sort } from "components/UI"
import { observer } from "mobx-react"
import afterDestroyFetch from "utils/afterDestroyFetch"
import formatDate from "utils/formatDate"
import sortChangeHandler from "utils/sortChangeHandler"

const Table = ({ store, selected, onChecked }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        {/* <th scope="col">№ п/п</th> */}
        <th scope="col">#</th>
        {/* <th scope="col">
          <Sort name="order_by_updated_at" onSortChange={() => sortChangeHandler(store)}>
            Дата изменений
          </Sort>
        </th> */}
        <th scope="col">
          <Sort name="order_by_insurer" onSortChange={() => sortChangeHandler(store)}>
            Страховая компания
          </Sort>
        </th>
        <th scope="col">Канал продаж</th>
        <th scope="col">Вид БСО</th>
        <th scope="col">Серия БСО</th>
        <th scope="col">
          <Sort name="order_by_number" onSortChange={() => sortChangeHandler(store)}>
            Номер БСО
          </Sort>
        </th>
        <th scope="col">Статус бланка</th>
        <th scope="col">Статус геолокации</th>
        <th scope="col">Подразделение</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map(
        (
          { id, updated_at, number, insurer, channel, bso_kind, bso_batch, form_status, geolocation_status, contract },
          index
        ) => (
          <tr key={`bsos-${index}`}>
            {/* <td>
              <input
                type="checkbox"
                selected={selected.includes(id)}
                onChange={onChecked(id)}
                disabled={!(form_status.value == 0 && geolocation_status.value == 0)}
              />
            </td> */}
            <td>{id}</td>
            {/* <td>{formatDate(updated_at)}</td> */}
            <td>{insurer.name}</td>
            <td>{channel.name}</td>
            <td>{bso_kind.name}</td>
            <td>{bso_batch.name}</td>
            <td>{number}</td>
            <td>{form_status.label}</td>
            <td>{geolocation_status.label}</td>
            <td>{contract.user && geolocation_status.value.toString() === "1" ? contract.user.subdivision.name : "-"}</td>
            <td>
              <Edit url={`/bsos/${id}/edit`} />
              <Destroy action={() => afterDestroyFetch(store, id)} />
            </td>
          </tr>
        )
      )}
    </tbody>
  </table>
)

export default observer(Table)
