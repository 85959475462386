import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import LossesStore from "stores/LossStore/One"
import Form from "pages/Contracts/containers/Losses/components/Form"
import { BreadcrumbItem } from "components/UI"

const contractStore = ContractStore.create()
const lossStore = LossesStore.create()

const Edit = (props) => {
  const { id, contract_id } = props.match.params
  useEffect(() => {
    contractStore.fetch({ id: contract_id })
    lossStore.fetch({ id, contract_id })
  }, [id, contract_id])
  console.log(lossStore.isFetched)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contractStore.id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contractStore.id}/losses`}>Убытки</BreadcrumbItem>
        <BreadcrumbItem active>{lossStore.id}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form {...{ store: lossStore, contractStore }} />
    </Fragment>
  )
}

export default observer(Edit)
