import React from "react"
import { Button } from "components/UI"
import { connect } from "formik"
import compose from "utils/compose"
import { observer } from "mobx-react"

const ResetButton = ({ formik: { setFieldValue, values }, defaultFilter }) => {
  const reset = () => {
    Object.keys(values).forEach((i) => {
      if (values[i] && JSON.stringify(Object.keys(values[i])) === JSON.stringify(["value", "label"])) {
        setFieldValue(i, { value: null, label: null })
      } else if (values[i] && JSON.stringify(Object.keys(values[i])) === JSON.stringify(["id", "name"])) {
        setFieldValue(i, { id: null, name: null })
      } else {
        setFieldValue(i, null)
      }
    })
    if (defaultFilter) {
      Object.keys(defaultFilter).forEach((i) => {
        if (values[i] && JSON.stringify(Object.keys(values[i])) === JSON.stringify(["value", "label"])) {
          setFieldValue(i, { value: defaultFilter[i].value, label: defaultFilter[i].label })
        } else if (values[i] && JSON.stringify(Object.keys(values[i])) === JSON.stringify(["id", "name"])) {
          setFieldValue(i, { id: defaultFilter[i].id, name: defaultFilter[i].name })
        } else {
          setFieldValue(i, defaultFilter[i])
        }
      })
    }
  }

  return (
    <Button variant="danger" onClick={reset} title="Сбросить фильтр">
      <i className="fas fa-sync-alt"></i>
    </Button>
  )
}

export default compose(observer, connect)(ResetButton)
