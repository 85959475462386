import { types } from "mobx-state-tree"
import isDate from "date-fns/isDate"
import toDate from "date-fns/toDate"
import parseISO from "date-fns/parseISO"

function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

const customTime = types.custom({
  fromSnapshot(value) {
    return value && convertDateToUTC(parseISO(value))
  },
  toSnapshot(value) {
    return value && toDate(value)
  },
  isTargetType(value) {
    return isDate(value)
  },
  getValidationMessage(value) {
    if (isDate(value)) {
      return "Invalid date-fns object"
    }
    return ""
  }
})

export default customTime
