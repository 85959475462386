import { types } from "mobx-state-tree"
import customDateTime from "types/customDateTime"
import newCustomEnum from "types/newCustomEnum"

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.string
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Creator = types.model("Creator", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  subdivision: types.maybeNull(Subdivision)
})

const InsurerDocument = types.model("InsurerDocument", {
  id: types.maybeNull(types.integer),
  insurer_id: types.maybeNull(types.integer),
  document_kind: types.maybeNull(DocumentKind),
  document_kind_other: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  attachments: types.maybeNull(types.array(Attachment)),
  creator: types.maybeNull(Creator),
  updated_at: types.maybeNull(customDateTime)
})

export { InsurerDocument }
