import React, { Fragment, useContext } from "react"
import { observer } from "mobx-react"
import { TextInput, CurrencyInput, RadioButtonGroup, RadioInput } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"

const GoEstateTab = ({ store, ...props }) => {
  const { contractStore } = useContext(ContractFormContext)
  const { contractGoEstateObjectsOptions } = contractStore.filtersOptions
  return (
    <Fragment>
      <br />
      {props.values.insurance_kind.id == 16 && (
        <TextInput
          name="contract_go_estate.kind"
          label="Вид ответственности"
          required={props.values.status.value.toString() === "1"}
        />
      )}
      {props.values.insurance_kind.id == 17 && (
        <RadioButtonGroup label="Объект страхования" name={"contract_go_estate.object.value"}>
          {contractGoEstateObjectsOptions.map(({ label, value }) => (
            <RadioInput key={`contract_go_estate_object_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
      )}
      <CurrencyInput
        name="contract_go_estate.total_limit"
        label="Лимит ответственности, руб."
        required={props.values.status.value.toString() === "1"}
      />
    </Fragment>
  )
}

export default observer(GoEstateTab)
