import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Paginate } from "components/UI"
import BudgetStore from "stores/BudgetStore/List"
import Table from "./Table"

const budgetStore = BudgetStore.create()

const ParentModal = ({ isOpen, closeModal, setFieldValue, year }) => {
  const [selected, setSelected] = useState({ id: null, year: null })

  useEffect(() => {
    budgetStore.fetch({ filter: { by_year_not: year } })
  }, [isOpen])

  const setParent = () => {
    setFieldValue("parent", selected)
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Выбрать родительский бюджет</ModalHeader>
      <ModalBody>
        <Table store={budgetStore} selected={selected} setSelected={setSelected} />
        <Paginate store={budgetStore} params={budgetStore.meta.filter} />
        <Button onClick={setParent}>Сохранить</Button>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(ParentModal)
