import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractScansStore from "stores/ContractScanStore/List"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { Button, Card, Paginate, BreadcrumbItem } from "components/UI"
import ContractStore from "stores/ContractStore/One"
import Tabsss from "pages/Contracts/components/Tabs"
import Table from "./components/Table"

const contractStore = ContractStore.create()
const contractScanStore = ContractScansStore.create()

const List = (props) => {
  const { contract_id } = props.match.params
  useEffect(() => {
    contractStore.fetch({ id: contract_id })
    contractScanStore.fetch({ contract_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem active>Сканы документов</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${contract_id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${contract_id}/edit?additional=true`}>Доп. соглашение</Button>
        )}
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${contract_id}/edit?prolongation=true`}>Продление</Button>
        )}
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <Card>
        <Button url={`/contracts/${contract_id}/contract_scans/new`}>Создать</Button>
      </Card>
      <Table store={contractScanStore} />
      <Paginate store={contractScanStore} params={{ contract_id: contract_id }} />
    </Fragment>
  )
}

export default observer(List)
