import React, { Fragment } from "react"
import { observer } from "mobx-react"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"

const Table = ({ store }) => {
  if (store.data.table.length === 0) return null

  let planTotal = 0
  let planMonthTotal = 0
  let factTotal = 0
  let factMonthTotal = 0

  return (
    <div style={{ overflowX: "scroll" }}>
      <table className="table table-bordered table-striped table-hover" style={{ width: "3300px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col" colSpan="2"></th>
            <th scope="col">Янв</th>
            <th scope="col">Фев</th>
            <th scope="col">Март</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              3 мес.
            </th>
            <th scope="col">Апр</th>
            <th scope="col">Май</th>
            <th scope="col">Июнь</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              6 мес.
            </th>
            <th scope="col">Июль</th>
            <th scope="col">Авг</th>
            <th scope="col">Сент</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              9 мес.
            </th>
            <th scope="col">Окт</th>
            <th scope="col">Ноя</th>
            <th scope="col">Дек</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              12 мес.
            </th>
            <th scope="col">Год</th>
          </tr>
        </thead>
        <tbody>
          {store.data.table.map((row, index) => {
            let planTotal = 0
            let planMonthTotal = 0
            let factTotal = 0
            let factMonthTotal = 0

            let percentPlanTotal = 0
            let percentPlanMonthTotal = 0
            let percentFactTotal = 0
            let percentFactMonthTotal = 0

            return (
              <Fragment>
                <tr style={index === store.data.table.length - 1 ? { background: "#e5efdc" } : {}}>
                  <td rowSpan="3">{row.subdivision.short_name}</td>
                  <td>План</td>
                  {row.plan.map((i, index) => {
                    if ((index + 1) % 4 === 0) {
                      planMonthTotal = planMonthTotal + parseMoney(i)
                    } else {
                      planTotal = planTotal + parseMoney(i)
                    }

                    if (index == 16) {
                      return <td>{formatMoney(i)}</td>
                    }

                    return (
                      <td style={(index + 1) % 4 === 0 ? { background: "#fef2d0" } : {}}>
                        {(index + 1) % 4 === 0 ? formatMoney(planMonthTotal) : formatMoney(planTotal)}
                      </td>
                    )
                  })}
                  <td rowSpan="3">{row.subdivision.short_name}</td>
                </tr>
                <tr>
                  <td>Факт</td>
                  {row.fact.map((i, index) => {
                    if ((index + 1) % 4 === 0) {
                      factMonthTotal = factMonthTotal + parseMoney(i)
                    } else {
                      factTotal = factTotal + parseMoney(i)
                    }

                    if (index == 16) {
                      return <td>{formatMoney(i)}</td>
                    }

                    return (
                      <td style={(index + 1) % 4 === 0 ? { background: "#fef2d0" } : {}}>
                        {(index + 1) % 4 === 0 ? formatMoney(factMonthTotal) : formatMoney(factTotal)}
                      </td>
                    )
                  })}
                </tr>
                <tr style={index === store.data.table.length - 1 ? { background: "#e5efdc" } : {}}>
                  <td>% исп.</td>
                  {row.fact.map((i, index) => {
                    if ((index + 1) % 4 === 0) {
                      percentFactMonthTotal = percentFactMonthTotal + parseMoney(i)
                      percentPlanMonthTotal = percentPlanMonthTotal + parseMoney(row.plan[index])
                    } else {
                      percentFactTotal = percentFactTotal + parseMoney(i)
                      percentPlanTotal = percentPlanTotal + parseMoney(row.plan[index])
                    }

                    if (index == 16) {
                      return (
                        <td>
                          {(row.plan[index] > 0 ? (parseMoney(i) / parseMoney(row.plan[index])) * 100 : 0).toFixed(3)}
                        </td>
                      )
                    }

                    return (
                      <td style={(index + 1) % 4 === 0 ? { background: "#fef2d0" } : {}}>
                        {(index + 1) % 4 === 0
                          ? (percentPlanMonthTotal > 0
                              ? (percentFactMonthTotal / percentPlanMonthTotal) * 100
                              : 0
                            ).toFixed(3)
                          : (percentPlanTotal > 0 ? (percentFactTotal / percentPlanTotal) * 100 : 0).toFixed(3)}
                      </td>
                    )
                  })}
                </tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default observer(Table)
