import React from "react"
import { Tab, Tabs } from "components/UI"

const TabsBlock = ({ id }) => {
  const mapping = {
    [`/insurers/${id}`]: "Общие сведения",
    [`/insurers/${id}/insurer_documents`]: "Документы",
    [`/insurers/${id}/contacts`]: "Контактные лица",
    // [`/insurers/${id}/insurance_products`]: "Страховые продукты",
    [`/insurers/${id}/agency_contracts`]: "Агентские договоры",
    [`/insurers/${id}/finances`]: "Финансы"
  }
  return (
    <Tabs>
      {Object.entries(mapping).map(i => {
        const active = window.location.pathname === i[0]
        return (
          <Tab key={i[0]} active={active} to={i[0]}>
            {i[1]}
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default TabsBlock
