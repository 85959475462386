import React from "react"
import { Destroy, Edit } from "components/UI"
import { observer } from "mobx-react"
import afterDestroyFetch from "utils/afterDestroyFetch"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Название</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map(({ id, name }, index) => (
        <tr key={`strengths-${index}`}>
          <td>
            {name}
          </td>
          <td>
            <Edit url={`/strengths/${id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
