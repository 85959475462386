import React from "react"
import { observer } from "mobx-react"
import { Breadcrumb, BreadcrumbItem } from "components/UI"

const Breadcrumbs = ({ court: { id, name }, children }) => (
  <Breadcrumb>
    <BreadcrumbItem active={!children} url="/courts">
      Поставщики
    </BreadcrumbItem>
    <BreadcrumbItem url={`/courts/${id}`}>{name}</BreadcrumbItem>
    <BreadcrumbItem url={`/courts/${id}/contacts`}>Контактные лица</BreadcrumbItem>
    {children}
  </Breadcrumb>
)

export default observer(Breadcrumbs)
