import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { Submit, TextInput, RadioButtonGroup, RadioInput } from "components/UI"

const Form = ({ store, handleSubmit }) => {
  const { bindings, client_bindings } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup label="Привязка документа" name="binding.value">
        {bindings.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Привязка к страхователю" name="client_binding.value">
        {client_bindings.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <TextInput name="name" label="Название" />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  }),
  observer
)(Form)
