import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const Client = types.model("Client", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Avr = types.model("Avr", {
  id: types.maybeNull(types.integer),
  channel: types.maybeNull(Channel)
})

const Kv = types.model("Kv", {
  id: types.maybeNull(types.integer),
  rate: types.maybeNull(types.string),
  by_contract: types.maybeNull(types.string),
  by_paid_sp: types.maybeNull(types.string),
  channel: types.maybeNull(Channel)
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string),
  conclusion_on: types.maybeNull(customDate),
  client: types.maybeNull(Client),
  insurance_kind: types.maybeNull(InsuranceKind),
  insurer: types.maybeNull(Insurer),
  start_on: types.maybeNull(customDate),
  finish_on: types.maybeNull(customDate),
  kvs: types.maybeNull(types.array(Kv)),
  sp_accrual_cost: types.maybeNull(types.string),
  fix_kv_channel: types.maybeNull(Channel)
})

const ContractInstallment = types.model("ContractInstallment", {
  id: types.maybeNull(types.integer),
  avrs: types.maybeNull(types.array(Avr)),
  contract: types.maybeNull(Contract),
  payment_cost: types.maybeNull(types.string),
  payment_on: types.maybeNull(customDate),
  sp_payment_cost: types.maybeNull(types.string),
  sp_payment_on: types.maybeNull(customDate),
  sp_payment_kind: types.maybeNull(newCustomEnum),
  sp_sk_payment_on: types.maybeNull(customDate),
  sp_sk_payment_cost: types.maybeNull(types.string),
  sp_sk_payment_form: types.maybeNull(newCustomEnum),
  sp_sinergy_payment_on: types.maybeNull(customDate),
  sp_sinergy_payment_cost: types.maybeNull(types.string),
  sp_sinergy_payment_form: types.maybeNull(newCustomEnum),
  contract_payment_status: types.maybeNull(newCustomEnum),
  kv_by_paid_sp: types.maybeNull(types.string),
  until_to_payment: types.maybeNull(types.integer),
  tmp_payment_cost: types.maybeNull(types.string),
  tmp_payment_on: types.maybeNull(customDate),
  fix_kv_cost: types.maybeNull(types.string),
  total_kv_cost: types.maybeNull(types.string),
  kv_cost_sum: types.maybeNull(types.string),
})

export { ContractInstallment }
