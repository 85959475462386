import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import Documents from "./../Documents"

const SalaryChangeTab = ({ store }) => {
  const { data } = store
  return (
    <div className="row">
      <div className="col-12">
        <table className="table">
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>Дата изменения оклада</td>
              <td style={{ width: "50%" }}>{formatDate(data.order_salary_change.date_on)}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Оклад. руб.</td>
              <td style={{ width: "50%" }}>{data.order_salary_change.salary}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Номер ДС к трудовому договору</td>
              <td style={{ width: "50%" }}>{data.order_salary_change.number}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Дата ДС к трудовому договору</td>
              <td style={{ width: "50%" }}>{formatDate(data.date_on)}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Комментарии</td>
              <td style={{ width: "50%" }}>{data.order_salary_change.comment}</td>
            </tr>
          </tbody>
        </table>
        <Documents store={store} />
      </div>
    </div>
  )
}

export default observer(SalaryChangeTab)
