import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.string
})

const Responsible = types.model("Responsible", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Client = types.model("Client", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  insurer: types.maybeNull(Insurer),
  client: types.maybeNull(Client),
  insurance_kind: types.maybeNull(InsuranceKind),
  days_from_conclusion_on: types.maybeNull(types.integer),
  start_on: types.maybeNull(customDate),
})

const ContractDocument = types.model("ContractDocument", {
  id: types.maybeNull(types.integer),
  contract_id: types.maybeNull(types.integer),
  document_kind: types.maybeNull(DocumentKind),
  document_kind_other: types.maybeNull(types.string),
  responsible: types.maybeNull(Responsible),
  kind: types.maybeNull(newCustomEnum),
  submission: types.maybeNull(newCustomEnum),
  submission_status: types.maybeNull(newCustomEnum),
  submission_holder: types.maybeNull(types.string),
  submission_on: types.maybeNull(customDate),
  submission_other: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  version_id: types.maybeNull(types.integer),
  contract: types.maybeNull(Contract),
  status: types.maybeNull(types.string)
  // attachments: types.maybeNull(types.array(Attachment))
})

export { ContractDocument }
