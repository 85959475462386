import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import ParcelBorderoStore from "stores/ParcelBorderoStore/One"
import Form from "pages/Contracts/containers/ParcelBorderos/components/Form"
import { BreadcrumbItem } from "components/UI"

const contractStore = ContractStore.create()
const parcel_borderoStore = ParcelBorderoStore.create()

const Add = (props) => {
  parcel_borderoStore.reset()
  const { contract_id } = props.match.params

  const [number, setNumber] = useState("")

  // useEffect(() => {
  //   parcel_borderoStore.fetchMeta({ contract_id })
  // }, [])

  useEffect(() => {
    contractStore.fetch({ id: contract_id }).then(({ data }) => {
      setNumber(`${data.number}/${data.parcel_borderos.length + 1}`)
    })
  }, [])

  parcel_borderoStore.applyData({
    status: 200,
    data: {
      data: {
        ...{
          start_on: null,
          finish_on: null,
          number: null,
          comment: null,
          status: { value: 0 },
          currency: { value: 0 },
          documents: [],
          parcels: [],
        },
        number: number
      }
    }
  })

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contract_id}/parcel_borderos`}>Бордеро</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form {...{ store: parcel_borderoStore, contractStore, contract_id }} />
    </Fragment>
  )
}

export default observer(Add)
