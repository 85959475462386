import React from "react"
import { observer } from "mobx-react"

const Table = ({ store, selected, setSelected }) => {
  console.log(selected)
  // console.log(contractInstallments.map((i) => i.id))
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col" className="actions one">#</th>
          <th scope="col">Страховая компания</th>
          <th scope="col">Канал продаж</th>
          <th scope="col">Номер АВР</th>
          <th scope="col">Сумма</th>
        </tr>
      </thead>
      <tbody>
        {store.data.map((row, index) => (
          <tr key={`avr-${index}`}>
            <td>
              <input
                type="checkbox"
                name="contract.id"
                value={row.id}
                checked={selected.map((i) => i.id).includes(row.id)}
                onChange={() => {
                  const id = row.id
                  const ids = selected.map((i) => i.id).includes(row.id)
                    ? selected.filter((i) => i.id !== id)
                    : selected.concat([{ id: row.id, number: row.number }])
                  setSelected(ids)
                }}
              />
            </td>
            <td>{row.insurer.name}</td>
            <td>{row.channel.name}</td>
            <td>{row.number}</td>
            <td>{row.kv_sum}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default observer(Table)
