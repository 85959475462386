import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Bso } from "./Bso"
import { prepare } from "./utils"
import newCustomEnum from "types/newCustomEnum"
import toOptions from "utils/newToOptions"

const FilterOptions = types.model("FilterOptions", {
  form_statuses: types.array(newCustomEnum, []),
  geolocation_statuses: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    form_statuses: [],
    geolocation_statuses: []
  }
}

const BsoStore = types
  .model("BsoStore", {
    data: types.optional(Bso, {
      insurer: {
        id: null,
        name: null
      },
      channel: {
        id: null,
        name: null
      },
      bso_kind: {
        id: null,
        name: null
      },
      bso_batch: {
        id: null,
        name: null
      },
      form_status: {
        value: 0
      },
      geolocation_status: {
        value: 0
      },
      number_status: {
        value: 1
      },
      contract: {
        
      }
    }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { form_statuses, geolocation_statuses } = self.meta.filters_options
      return { form_statuses: toOptions(form_statuses), geolocation_statuses: toOptions(geolocation_statuses) }
    },

    get id() {
      return self.data.id
    },

    get number() {
      return self.data.number
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/bsos/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/bsos/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/bsos`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/bsos/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    cloneee(params = {}) {
      self.setState("pending")
      return instance
        .post(`/bsos/${params.id}/clone`, { data: params })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },


    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default BsoStore
