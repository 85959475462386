import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { SalePlan } from "./SalePlan"
import toOptions from "utils/newToOptions"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"
import { prepare } from "./utils"

const Item = types.model("Item", {
  id: types.maybeNull(types.number),
  short_name: types.maybeNull(types.string)
})

const FilterOptions = types.model("FilterOptions", {
  kinds: types.array(newCustomEnum, []),
  items: types.maybeNull(types.array(Item, []))
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    kinds: [],
    items: []
  }
}

const SalePlanStore = types
  .model("SalePlanStore", {
    data: types.optional(SalePlan, {
      name: "",
      year: "2021",
      kind: { value: null },
      items: [
        { id: 1, short_name: "БКС" },
        { id: 2, short_name: "БЛС" },
        { id: 4, short_name: "БРП" },
        { id: 5, short_name: "БПП" },
        { id: 6, short_name: "БКК" }
      ].map(({ id, short_name }) => {
        return {
          id,
          short_name,
          costs: Array(12)
            .fill()
            .map((e, month) => {
              return {
                month: month + 1,
                cost: "0"
              }
            })
        }
      })
    }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get name() {
      return self.data.name
    },

    get filtersOptions() {
      const { kinds } = self.meta.filters_options
      return {
        kinds: toOptions(kinds)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/sale_plans/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/sale_plans/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/sale_plans`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/sale_plans/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { ...self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default SalePlanStore
