import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import customDate from "types/customDate"
import customEnum from "types/customEnum"
import format from "date-fns/format"

const Insurer = types.model("Insurer", {
  id: types.integer,
  name: types.string
})

const Client = types.model("Client", {
  id: types.integer,
  name: types.string
})

const Subagent = types.model("Subagent", {
  id: types.integer,
  name: types.string
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.integer,
  name: types.string
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  subagent: types.maybeNull(Subagent),
  insurer: types.maybeNull(Insurer),
  client: types.maybeNull(Client),
  insurance_kind: types.maybeNull(InsuranceKind),
  number: types.maybeNull(types.string),
  finish_on: types.maybeNull(customDate),
  until_to_prolongations: types.maybeNull(types.integer),
  kvs_payments: types.maybeNull(types.string)
})

const ContractInstallment = types.model("ContractInstallment", {
  id: types.maybeNull(types.integer),
  contract: types.maybeNull(Contract),
  subagent_cost: types.maybeNull(types.string),
  subagent_cost_planned_on: types.maybeNull(customDate),
  subagent_until_to_payment: types.maybeNull(types.number),
  payment_on: types.maybeNull(customDate)
})

const Filter = types.model("Filter", {
  by_insurer: types.maybeNull(customEnum),
  by_channel: types.maybeNull(customEnum),
  // by_start_on_from: types.maybeNull(customDate),
  // by_start_on_to: types.maybeNull(customDate),
  by_subagent_cost_planned_on_start: types.maybeNull(customDate),
  by_subagent_cost_planned_on_finish: types.maybeNull(customDate),
  by_client: types.maybeNull(customEnum),
  by_number: types.maybeNull(types.union(types.integer, types.string)),
  by_payment_on_from: types.maybeNull(customDate),
  by_payment_on_to: types.maybeNull(customDate),
  by_user: types.maybeNull(customEnum),
  by_subdivision: types.maybeNull(customEnum),
  by_insurance_kind: types.maybeNull(customEnum),
  by_subagent: types.maybeNull(customEnum),
  by_subagent_payment_status: types.maybeNull(types.integer)
})

const FilterOptions = types.model("FilterOptions", {
  channels: types.array(customEnum, []),
  insurers: types.array(customEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Statistics = types.model("Statistics", {
  payment_cost: types.string,
  kv_by_paid_sp: types.string
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number),
  statistics: types.frozen()
})

const metaDefaultValue = {
  filter: {
    by_insurer: { id: null, name: null },
    by_channel: { id: null, name: null },
    // by_start_on_from: null,
    // by_start_on_to: null,
    by_conclusion_on_from: null,
    by_conclusion_on_to: null,
    by_client: { id: null, name: null },
    by_number: null,
    by_payment_on_from: null,
    by_payment_on_to: null,
    by_user: { id: null, name: null },
    by_subdivision: { id: null, name: null },
    by_insurance_kind: { id: null, name: null },
    by_subagent: { id: null, name: null }
  },
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  },
  statistics: []
}

const ContractStore = types
  .model("Contract", {
    data: types.array(ContractInstallment, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { channels, insurers } = self.meta.filters_options
      const channelOptions = channels.map(({ id: value, name: label }) => {
        return {
          label,
          value
        }
      })
      const insurerOptions = insurers.map(({ id: value, name: label }) => {
        return {
          label,
          value
        }
      })
      return [channelOptions, insurerOptions]
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_subagent_cost_planned_on_start, by_subagent_cost_planned_on_finish } = params.filter
        if (by_subagent_cost_planned_on_start) {
          params.filter = {
            ...params.filter,
            by_subagent_cost_planned_on_start: format(by_subagent_cost_planned_on_start, "yyyy-MM-dd")
          }
        }
        if (by_subagent_cost_planned_on_finish) {
          params.filter = {
            ...params.filter,
            by_subagent_cost_planned_on_finish: format(by_subagent_cost_planned_on_finish, "yyyy-MM-dd")
          }
        }
      }

      return instance
        .get("/contract_installments/payments_plan", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const {
        status,
        data: { data, meta }
      } = response

      if (status === 200) applySnapshot(self, { data, meta: { ...metaDefaultValue, ...meta } })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ContractStore
