import React from "react"
import compose from "utils/compose"
import { DateInput, ResetButton, SelectInput, Submit, TextInput } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"

const Filter = ({ store, values, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <DateInput name="by_date_on_from" label="Дата бордеро с" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_to" label="Дата бордеро по" />
        </div>
        <div className="col-3">
          <TextInput name="by_number" label="Номер бордеро" />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_status"
            label="Статус"
            options={[
              { label: "Черновик", value: "draft" },
              { label: "Выпущен", value: "released" }
            ]}
          />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag, {contract_id: formikBag.props.contract_id})
  }),
  observer
)(Filter)
