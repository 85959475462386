import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import OperationStore from "stores/OperationStore/One"
import Breadcrumbs from "pages/Operations/components/Breadcrumbs"
import Form from "pages/Operations/components/Form"
import { BreadcrumbItem } from "components/UI"
import qs from "qs"

const operationStore = OperationStore.create()

const Edit = (props) => {
  useEffect(() => {
    const { id } = props.match.params
    operationStore.fetch({ id })
  }, [])

  let aa = "Редактировать"
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const clone = params.clone == "true"

  if (clone) {
    aa = "Копирование"
    operationStore.applyData({
      status: 200,
      data: {
        data: {
          ...operationStore.data,
          id: null,
          is_clone: true,
          status: {
            value: 0
          },
          avr: { id: null },
          contract: { id: null },
          operation_scans: [],
          contracts: [],
          avrs: []
        }
      }
    })
    console.log(operationStore.data)
  }

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/operations/${operationStore.id}`}>{operationStore.data.id}</BreadcrumbItem>
        <BreadcrumbItem active>{aa}</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={operationStore} />
    </Fragment>
  )
}

export default observer(Edit)
