import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"
import customIdValue from "types/customIdValue"

const Position = types.model("Position", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const UserDocument = types.model("UserDocument", {
  id: types.maybeNull(types.integer),
  comment: types.maybeNull(types.string),
  attachments: types.maybeNull(types.array(Attachment)),
  document_kind: types.maybeNull(DocumentKind),
  document_kind_other: types.maybeNull(types.string)
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  short_name: types.maybeNull(types.string)
})

const OrderSubject = types.model("OrderSubject", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const OrderHire = types.model("OrderHire", {
  id: types.maybeNull(types.integer),
  hire_on: types.maybeNull(customDate),
  salary: types.maybeNull(types.string),
  probation: types.maybeNull(newCustomEnum),
  conditions: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  contract_number: types.maybeNull(types.string),
  contract_date_on: types.maybeNull(customDate),
  o2: types.maybeNull(types.string),
  o2_comment: types.maybeNull(types.string),
  position: types.maybeNull(Position),
  subdivision: types.maybeNull(Subdivision),
})

const OrderFireAtWill = types.model("OrderFireAtWill", {
  id: types.maybeNull(types.integer),
  date_on: types.maybeNull(customDate),
  reason: types.maybeNull(types.string),
  reason_document: types.maybeNull(types.string),
  comment: types.maybeNull(types.string)
})

const OrderFireByAgreement = types.model("OrderFireByAgreement", {
  id: types.maybeNull(types.integer),
  date_on: types.maybeNull(customDate),
  reason: types.maybeNull(types.string),
  reason_document: types.maybeNull(types.string),
  comment: types.maybeNull(types.string)
})

const OrderChangePosition = types.model("OrderChangePosition", {
  id: types.maybeNull(types.integer),
  date_on: types.maybeNull(customDate),
  kind: types.maybeNull(types.string),
  position: types.maybeNull(Position),
  subdivision: types.maybeNull(Subdivision),
  salary: types.maybeNull(types.string),
  reason_document: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  o2: types.maybeNull(types.string),
  o2_comment: types.maybeNull(types.string)
})

const OrderVacation = types.model("OrderVacation", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  perion_start_on: types.maybeNull(customDate),
  perion_finish_on: types.maybeNull(customDate),
  vacation_start_on: types.maybeNull(customDate),
  vacation_finish_on: types.maybeNull(customDate),
  comment: types.maybeNull(types.string)
})

const OrderSalaryChange = types.model("OrderSalaryChange", {
  id: types.maybeNull(types.integer),
  date_on: types.maybeNull(customDate),
  salary: types.maybeNull(types.string),
  number: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  o2: types.maybeNull(types.string),
  o2_comment: types.maybeNull(types.string),
  o2: types.maybeNull(types.string),
  o2_comment: types.maybeNull(types.string)
})

const Order = types.model("Order", {
  id: types.maybeNull(types.integer),
  date_on: types.maybeNull(customDate),
  number: types.maybeNull(types.string),
  order_subject: types.maybeNull(OrderSubject),
  order_hire: types.maybeNull(OrderHire),
  order_fire_at_will: types.maybeNull(OrderFireAtWill),
  order_fire_by_agreement: types.maybeNull(OrderFireByAgreement),
  order_change_position: types.maybeNull(OrderChangePosition),
  order_vacation: types.maybeNull(OrderVacation),
  order_salary_change: types.maybeNull(OrderSalaryChange),
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  email: types.maybeNull(types.string),
  password: types.maybeNull(types.string),
  password_confirmation: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  f_name: types.maybeNull(types.string),
  l_name: types.maybeNull(types.string),
  p_name: types.maybeNull(types.string),
  position: types.maybeNull(customEnum),
  channel: types.maybeNull(customEnum),
  subdivision: types.maybeNull(Subdivision),
  birthday_on: types.maybeNull(customDate),
  mol: types.maybeNull(newCustomEnum),
  status: types.maybeNull(newCustomEnum),
  emails: types.maybeNull(types.array(customIdValue)),
  phones: types.maybeNull(types.array(customIdValue)),
  privileges: types.maybeNull(types.array(types.string)),

  number: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  inn: types.maybeNull(types.string),
  snils: types.maybeNull(types.string),
  hiring_on: types.maybeNull(customDate),
  fired_on: types.maybeNull(customDate),
  fired_reason: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  documents: types.maybeNull(types.array(UserDocument)),

  passport_number: types.maybeNull(types.string),
  passport_issued_on: types.maybeNull(customDate),
  passport_issued_by: types.maybeNull(types.string),

  actual_net: types.maybeNull(types.string),
  actual_salary: types.maybeNull(types.string),
  orders: types.array(Order)
})

const userDefaultValue = {
  emails: [],
  phones: [],
  position: {},
  subdivision: {},
  channel: {},
  mol: { value: 0 },
  status: { value: 0 },
  documents: []
}

export { User, userDefaultValue }
