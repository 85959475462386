import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import CourierStore from "stores/CourierStore/One"
import Breadcrumbs from "pages/Couriers/components/Breadcrumbs"
import Form from "pages/Couriers/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"

const Add = () => {
  const courierStore = CourierStore.create()
  
  useEffect(() => {
    courierStore.fetchMeta()
  }, [])
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>

      <ContractFormContext.Provider value={{ courierStore }}>
        <Form store={courierStore} />
      </ContractFormContext.Provider>
    </Fragment>
  )
}

export default observer(Add)
