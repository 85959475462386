import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import getParams from "utils/getParams"
import ParcelStore from "stores/ParcelStore/List"
import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import { Button, Card, Paginate, BreadcrumbItem } from "components/UI"
import ContractStore from "stores/ContractStore/One"
import Tabsss from "pages/Contracts/components/Tabs"
import Filter from "./components/Filter"
import Table from "./components/Table"
import ImportModal from "./components/ImportModal"
import ExportModal from "./components/ExportModal"
import instance from "connection/instance"
import useSelectAll from "hooks/useSelectAll"
import { historyPushFromStore } from "utils/historyPushWithStore"

const contractStore = ContractStore.create()
const parcelStore = ParcelStore.create()

const List = (props) => {
  const { contract_id } = props.match.params
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    contractStore.fetch({ id: contract_id })
    parcelStore.fetch({ ...getParams(), contract_id })
  }, [])

  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [importModalData, setImportModalData] = useState([])
  const [exportModalData, setExportModalData] = useState([])

  const onClick = () => {
    const clickEvent = document.createEvent("MouseEvents")
    clickEvent.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    document.getElementById(`import`).click()
  }

  const onChangeFile = (e) => {
    const formData = new FormData()
    formData.append("data[file]", e.target.files[0])
    formData.append("data[contract_id]", contract_id)
    instance
      .post(`/contracts/${contract_id}/parcels/import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(({ data }) => {
        alert(`Успешно импортировано ${data.data.count} отгрузок`)
        parcelStore.fetch({ contract_id })
      })
      .catch(({ response }) => {
        // alert("Произошла ошибка")
        setIsImportModalOpen(true)
        setImportModalData(response.data.errors)
      })
  }

  const [disabled, setDisabled] = useState(false)
  const [disabledAll, setDisabledAll] = useState(false)

  const [
    selectedRows,
    totalSelectedRow,
    isCheckedRow,
    onCheckedRow,
    isCheckedAllRows,
    onCheckedAllRows,
    resetSelected
  ] = useSelectAll(parcelStore.data.toJSON(), false)

  const onExportButtonHandler = () => {
    const parcels = Object.values(selectedRows).flat()
    setDisabled(true)
    parcelStore
      .export({ data: { ids: parcels.map((i) => i.id) }, contract_id: contract_id })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "export.zip")
        document.body.appendChild(link)
        link.click()
        setDisabled(false)
      })
      .catch(({ response }) => {
        setDisabled(false)
        setIsExportModalOpen(true)
        const enc = new TextDecoder("utf-8")
        const data = JSON.parse(enc.decode(new Uint8Array(response.data)))
        setExportModalData(data.errors)
      })
  }

  const onExportAllButtonHandler = () => {
    setDisabledAll(true)
    parcelStore
      .exportAll({ ...getParams(), contract_id: contract_id })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "export.zip")
        document.body.appendChild(link)
        link.click()
        setDisabledAll(false)
      })
      .catch(() => {
        setDisabledAll(false)
      })
  }

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem active>Отгрузки</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/contracts/${contract_id}/edit`}>Редактировать</Button>
        &nbsp;
        {contractStore.status.value.toString() === "1" && (
          <Button url={`/contracts/${contract_id}/edit?additional=true`}>Доп. соглашение</Button>
        )}
      </Card>
      <Tabsss store={contractStore} />
      <br />
      <Card>
        <Button url={`/contracts/${contract_id}/parcels/new`}>Создать</Button>
        &nbsp;
        <Fragment>
          <input
            id="import"
            type="file"
            onChange={onChangeFile}
            style={{ display: "none" }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <button type="button" className="btn btn-primary" onClick={onClick}>
            Импорт из .xlsx
          </button>
        </Fragment>
        &nbsp;
        <Button onClick={onExportButtonHandler} disabled={totalSelectedRow === 0 || disabled}>
          {disabled && (
            <Fragment>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
            </Fragment>
          )}
          Экспорт в .pdf
        </Button>
        &nbsp;
        <Button
          onClick={onExportAllButtonHandler}
          disabled={Object.values(parcelStore.meta.filter).every((i) => i === null || i === undefined) || disabledAll}
        >
          {disabledAll && (
            <Fragment>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
            </Fragment>
          )}
          Экспортировать найденное в .pdf
        </Button>
      </Card>
      <Card>
        <Filter store={parcelStore} contract_id={contract_id} />
      </Card>
      <Table
        store={parcelStore}
        selectedRows={selectedRows}
        totalSelectedRow={totalSelectedRow}
        isCheckedRow={isCheckedRow}
        onCheckedRow={onCheckedRow}
        isCheckedAllRows={isCheckedAllRows}
        onCheckedAllRows={onCheckedAllRows}
        resetSelected={resetSelected}
        contract_id={contract_id}
      />
      <Paginate store={parcelStore} additionalParams={{ contract_id: contract_id }}  />
      <ImportModal isOpen={isImportModalOpen} setIsOpen={setIsImportModalOpen} data={importModalData} />
      <ExportModal isOpen={isExportModalOpen} setIsOpen={setIsExportModalOpen} data={exportModalData} />
    </Fragment>
  )
}

export default observer(List)
