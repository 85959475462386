import { types } from "mobx-state-tree"

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Organization = types.model("Organization", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  organizationable_id: types.maybeNull(types.integer),
  organizationable_type: types.maybeNull(types.string)
})

const Route = types.model("Route", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  from_organization: types.maybeNull(Organization),
  from_contact: types.maybeNull(types.string),
  from_phone: types.maybeNull(types.string),
  from_address: types.maybeNull(types.string),
  from_comment: types.maybeNull(types.string),

  to_organization: types.maybeNull(Organization),
  to_contact: types.maybeNull(types.string),
  to_phone: types.maybeNull(types.string),
  to_address: types.maybeNull(types.string),
  to_comment: types.maybeNull(types.string),
  creator: types.maybeNull(User)
})

export { Route }
