import { types } from "mobx-state-tree"
import customEnum from "types/customEnum"
import customIdValue from "types/customIdValue"

const Contact = types.model("Contact", {
  id: types.maybeNull(types.integer),
  l_name: types.maybeNull(types.string),
  f_name: types.maybeNull(types.string),
  p_name: types.maybeNull(types.string),
  emails: types.maybeNull(types.array(customIdValue)),
  phones: types.maybeNull(types.array(customIdValue)),
  insurance_kinds: types.maybeNull(types.array(customEnum)),
})

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  contacts: types.maybeNull(types.array(Contact))
})

export default Insurer
