import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import AppealStore from "stores/AppealStore/One"
import ClientStore from "stores/ClientStore/List"
import SubagentStore from "stores/SubagentStore/List"
import Breadcrumbs from "pages/Appeals/components/Breadcrumbs"
import Form from "pages/Appeals/components/Form"
import { BreadcrumbItem } from "components/UI"

class Edit extends Component {
  appealStore = AppealStore.create()
  clientStore = ClientStore.create()
  subagentStore = SubagentStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.appealStore.fetchMeta()
    this.appealStore.fetch({ id })
    this.clientStore.fetch({ limit: 0 })
    this.subagentStore.fetch({ limit: 0 })
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .update(values)
      .then(() => history.push("/appeals"))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { appealStore: store, handleSubmit, clientStore, subagentStore } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem url={`/appeals/${store.id}`}>{store.id}</BreadcrumbItem>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit, clientStore, subagentStore }} />
      </Fragment>
    )
  }
}

export default observer(Edit)
