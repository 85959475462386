import React, { useContext, useState } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import { observer } from "mobx-react"
import { Button, Card, Destroy } from "components/UI"
import KvCostsModal from "./KvCostsModal"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import { useFormikContext, getIn } from "formik"

const ContractInstallments = ({ values, setKvCosts }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  const { insurer, channel, start_on: by_payment_on_start, finish_on: by_payment_on_finish } = values
  const isDisabledAdd =
    !insurer.id ||
    !channel.id ||
    values.avr_installments.filter((i) => i.payment_on && parseMoney(i.payment_cost) > 0).length > 0 ||
    (values.payment_status.value.toString() === "1" && ![5, 23].includes(currentUser.id))

  const isDisabledDestroy =
    values.avr_installments.filter((i) => i.payment_on && parseMoney(i.payment_cost) > 0).length > 0 ||
    (values.payment_status.value.toString() === "1" && ![5, 23].includes(currentUser.id))

  const [isOpen, setIsOpen] = useState(false)

  const onDestroyClick = (id) => setKvCosts(values.kv_costs.map((i) => (i.id === id ? { ...i, _destroy: true } : i)))

  const kv_costs = values.kv_costs.filter((i) => i.kind === 'kv' && !i._destroy)

  const toggleChange = (kvCost) => {
    const index = kv_costs.findIndex((i) => i.id === kvCost.id)
    if (index > -1) {
      setKvCosts(kv_costs.filter((i) => i.id !== kvCost.id))
    } else {
      setKvCosts(kv_costs.concat(kvCost))
    }
  }

  const { errors } = useFormikContext()
  const error = getIn(errors, "kv_costs", false)

  return (
    <Card>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Номер договора</th>
            <th scope="col">Страхователь</th>
            <th scope="col">Дата оплаты премии страхователем</th>
            <th scope="col">Сумма оплаченной страхователем премии</th>
            <th scope="col">Ставка вознаграждения, %</th>
            <th scope="col">Вознаграждение</th>
            <th scope="col" className="actions one" />
          </tr>
        </thead>
        <tbody>
          {kv_costs.map((kv_cost, index) => (
            <tr key={`kv-cost-${index}`}>
              <td>{++index}</td>
              <td>{kv_cost.contract_installment.contract.number}</td>
              <td>{kv_cost.contract_installment.contract.client.name}</td>
              <td>{formatDate(kv_cost.contract_installment.payment_on)}</td>
              <td>{kv_cost.contract_installment.payment_cost}</td>
              <td>{kv_cost.kv.rate}</td>
              <td>{kv_cost.cost}</td>
              <td>
                <Destroy
                  action={() => onDestroyClick(kv_cost.id)}
                  disabled={isDisabledDestroy}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan="4"></th>
            <th>
              {formatMoney(
                kv_costs.map((i) => parseMoney(i.contract_installment.payment_cost)).reduce((a, b) => a + b, 0)
              )}
            </th>
            <th></th>
            <th colSpan="2">{formatMoney(kv_costs.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0))}</th>
          </tr>
        </tfoot>
      </table>
      <Button onClick={() => setIsOpen(true)} disabled={isDisabledAdd}>
        Добавить
      </Button>
      {error && <div className="invalid-feedback">{error[0]}</div>}
      <KvCostsModal
        {...{
          isOpen,
          setIsOpen,
          setKvCosts,
          kv_costs,
          toggleChange,
          by_payment_on_start,
          by_payment_on_finish,
          insurer_id: insurer.id,
          channel_id: channel.id
        }}
      />
    </Card>
  )
}
export default observer(ContractInstallments)
