import React from "react"
import { Route, Switch } from "react-router-dom"
import TechParams from "./containers/TechParams"
// import Motivation from "./containers/Motivation"

const BudgetBudgetNors = () => (
  <Switch>
    <Route path="/budgets/:budget_id/coefficient/tech_params" component={TechParams} />
    {/* <Route path="/budgets/:budget_id/coefficient/motivation" component={Motivation} /> */}
  </Switch>
)

export default BudgetBudgetNors