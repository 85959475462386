import React from "react"
import MaskedInput from "react-text-mask"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn, ErrorMessage } from "formik"
import { observer } from "mobx-react"

const NumberInput = ({ name, label, required = false, type = "text", ...props }) => {
  const id = idGenerator()
  const { errors, values, handleBlur, handleChange } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || ""
  const className = classnames("form-control", { "is-invalid": error })
  let component = (
    <input
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      autoComplete="off"
      {...{ className, id, name, type, ...props }}
    />
  )
  if (String(values.insurance_kind.id) === "14") {
    component = (
      <MaskedInput
        mask={[
          /[АВЕКМНОРСТУХ]/,
          /[АВЕКМНОРСТУХ]/,
          /[АВЕКМНОРСТУХ]/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ]}
        {...{ className, id, name, type, value, ...props }}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="off"
      />
    )
  }

  return (
    <div className={classnames("form-group", { required: required })}>
      <label htmlFor={id}>{label}</label>
      {component}
      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default compose(observer, connect)(NumberInput)
