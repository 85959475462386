import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"
import customDate from "types/customDate"

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string)
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Organization = types.model("Organization", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  organizationable_id: types.maybeNull(types.integer),
  organizationable_type: types.maybeNull(types.string)
})

const RecipientCargo = types.model("Recipient", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  kind_other: types.maybeNull(types.string)
})

const Provider = types.model("Provider", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Recipient = types.model("Recipient", {
  id: types.maybeNull(types.integer),
  to_organization: types.maybeNull(Organization),
  to_contact: types.maybeNull(types.string),
  to_phone: types.maybeNull(types.string),
  to_address: types.maybeNull(types.string),
  to_comment: types.maybeNull(types.string),
  to_date_on: types.maybeNull(customDate),
  to_start: types.maybeNull(types.string),
  to_finish: types.maybeNull(types.string),
  to_time_kind: types.maybeNull(newCustomEnum),
  recipient_cargos: types.maybeNull(types.array(RecipientCargo, []))
})

const Courier = types.model("Courier", {
  id: types.maybeNull(types.integer),
  created_at: types.maybeNull(customDate),
  status: types.maybeNull(newCustomEnum),
  delivery_service: types.maybeNull(newCustomEnum),
  cost: types.maybeNull(types.string),
  user: types.maybeNull(User),
  urgency: types.maybeNull(newCustomEnum),
  justification_of_urgency: types.maybeNull(types.string),
  delivery_kind: types.maybeNull(newCustomEnum),
  delivery_complexity: types.maybeNull(newCustomEnum),
  task: types.maybeNull(types.string),

  from_organization: types.maybeNull(Organization),
  from_contact: types.maybeNull(types.string),
  from_phone: types.maybeNull(types.string),
  from_address: types.maybeNull(types.string),
  from_comment: types.maybeNull(types.string),
  from_date_on: types.maybeNull(customDate),
  from_start: types.maybeNull(types.string),
  from_finish: types.maybeNull(types.string),
  from_time_kind: types.maybeNull(newCustomEnum),

  // to_organization: types.maybeNull(Organization),
  // to_contact: types.maybeNull(types.string),
  // to_phone: types.maybeNull(types.string),
  // to_address: types.maybeNull(types.string),
  // to_comment: types.maybeNull(types.string),

  recipients: types.maybeNull(types.array(Recipient, [])),
  contracts: types.array(Contract),
  status_changed_by: types.maybeNull(User),
  decisioned_at: types.maybeNull(customDate),
  is_clone: types.maybeNull(types.boolean),
  provider: types.maybeNull(Provider)
})

export { Courier }
