import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Paginate } from "components/NewUI"
import useOperations from "pages/PaymentPlans/hooks/useOperations"
import useSelectAll from "hooks/useSelectAll"
import Table from "./Table"
import Filter from "./Filter"

const RelationModal = ({ isOpen, closeModal, initSelected, setFieldValue, form }) => {
  const { operations, fetchOperations } = useOperations({ data: [] })

  useEffect(() => {
    fetchOperations({ filter: { by_form: form } })
  }, [form])

  // const { selectedRows, totalSelectedRow, isCheckedRow, onCheckedRow, isCheckedAllRows, onCheckedAllRows } =
  //   useSelectRow(operations.data, initSelected)

  const [
    selectedRows,
    totalSelectedRow,
    isCheckedRow,
    onCheckedRow,
    isCheckedAllRows,
    onCheckedAllRows,
    resetSelected,
    setSelected
  ] = useSelectAll(operations.data, false)

  const updateOperations = () => {
    setFieldValue("operations", Object.values(selectedRows).flat())
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Добавить</ModalHeader>
      <ModalBody>
        {/* <Card>
          <Filter handleSubmit={handleSubmit} />
        </Card> */}
        <Table
          store={operations}
          selectedRows={selectedRows}
          isCheckedRow={isCheckedRow}
          onCheckedRow={onCheckedRow}
          isCheckedAllRows={isCheckedAllRows}
          onCheckedAllRows={onCheckedAllRows}
        />
        <Paginate store={operations} fetch={fetchOperations} params={{ by_form: form }} />
        <Button onClick={updateOperations}>Сохранить</Button>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(RelationModal)
