import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Тип документа</th>
        <th scope="col">Файл</th>
        <th scope="col">Комментарии</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((i) => (
        <tr>
          <td>
            {String(i.document_kind.id) === "18" ? i.document_kind_other : i.document_kind.name}
          </td>
          <td>
            {i.attachments
              .filter((i) => i.file.url)
              .map((attachment) => (
                <a
                  key={`attachment-${attachment.id}`}
                  href={attachment.file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {decodeURI(attachment.file.url.replace(/^.*[\\|/]/, ""))}
                  <br />
                </a>
              ))}
          </td>
          <td>{i.comment}</td>
          <td>
            <Edit url={`/contracts/${i.contract_id}/contract_scans/${i.id}/edit`} />
            <Destroy
              action={() =>
                afterDestroyFetch(store, { id: i.id, contract_id: i.contract_id }, { contract_id: i.contract_id })
              }
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
