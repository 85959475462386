import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { CarBrand } from "./CarBrand"
import newCustomEnum from "types/newCustomEnum"
import toOptions from "utils/newToOptions"

const Filter = types.model("Filter", {
  by_kind: types.maybeNull(types.integer),
  by_name: types.maybeNull(types.string),
  order_by_name: types.maybeNull(types.string)
})

const FilterOptions = types.model("FilterOptions", {
  kinds: types.array(newCustomEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const CarBrandStore = types
  .model("CarBrand", {
    data: types.array(CarBrand, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get carBrandOptions() {
      return self.data.map(({ id, value }) => {
        return {
          label: value,
          value: id
        }
      }) 
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      return instance
        .get("/car_brands", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/car_brands/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      // applySnapshot(self, data)
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default CarBrandStore
