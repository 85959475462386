import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { Button } from "components/UI"

const CommonTab = ({ store }) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <td style={{ width: "50%" }}>Сформировано (от)</td>
          <td style={{ width: "50%" }}>{store.customer.name}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Подразделение (от)</td>
          <td style={{ width: "50%" }}>{store.customer.subdivision.name}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Получатель (кому)</td>
          <td style={{ width: "50%" }}>{store.executor.name}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Подразделение (кому)</td>
          <td style={{ width: "50%" }}>{store.executor.subdivision.name}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Срочность</td>
          <td style={{ width: "50%" }}>{store.urgency.label}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Дата создания</td>
          <td style={{ width: "50%" }}>{formatDate(store.created_at)}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Статус</td>
          <td style={{ width: "50%" }}>
            {store.status.label}&nbsp;&nbsp;&nbsp;
            {store.status.value.toString() == "0" && (
              <Button size="sm" onClick={() => store.setStatus(1)}>
                Перевести в статус "Отправлено"
              </Button>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default observer(CommonTab)
