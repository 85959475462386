import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { DateInput, RadioButtonGroup, RadioInput, TextInput, CurrencyInput, Submit } from "components/UI"
import Documents from "./Documents"
import formSubmitHandler from "utils/formSubmitHandler"
import StandardPeriods from "./StandardPeriods"

const Form = ({ contractStore, handleSubmit }) => {
  const { cargoCurrenciesOptions } = contractStore.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <DateInput name="start_on" label="Начало отчетного периода" />
      <StandardPeriods />
      <DateInput name="finish_on" label="Конец отчетного периода" />
      <DateInput name="date_on" label="Дата бордеро" />
      <TextInput name="number" label="№ ген.договора / порядковый № бордеро" required />
      <RadioButtonGroup label="Валюта договора" name="currency.value">
        {cargoCurrenciesOptions.map(({ label, value }) => (
          <RadioInput key={`cargo_currency_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <CurrencyInput name="ss_cost" label="Общая СС по бордеро (итого)" />
      <CurrencyInput name="sp_cost" label="Общая СП по бордеро (итого)" />
      <TextInput name="comment" label="Комментарий" />
      <Documents name="documents" />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: contract_id } = formikBag.props.contractStore.data
      formSubmitHandler({ ...values, contract_id: contract_id }, formikBag, `/contracts/${contract_id}/borderos`)
    }
  }),
  observer
)(Form)
