import React from "react"
import MaskedInput from "react-text-mask"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn, ErrorMessage } from "formik"
import { observer } from "mobx-react"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
// import formatMoney from "utils/formatMoney"

const formatMoney = (value) => {
  // return (Math.floor(value * 100) / 100).toFixed(2)

  if (typeof value === "number") {
    value = ((value * 100).toFixed(2) / 100).toFixed(2)
  }

  return String(value)
    .replace(/[^\d.]/g, "")
    .replace(/\d(?=(\d{3})+\.)/g, "$& ")
}

const CurrencyInput = ({ name, label, type = "text", required = false, bare = false, ...props }) => {
  const id = idGenerator()
  const { errors, values, handleBlur, handleChange } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || ""
  const className = classnames("form-control", { "is-invalid": error })

  const currencyMask = createNumberMask({
    ...{
      prefix: "",
      suffix: "",
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: " ",
      allowDecimal: true,
      decimalSymbol: ".",
      decimalLimit: 2,
      integerLimit: 12,
      allowNegative: false,
      allowLeadingZeroes: false
    },
    ...props
  })

  if (bare) {
    return (
      <MaskedInput
        mask={currencyMask}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="off"
        {...{ className, id, name, type, value: formatMoney(value), ...props }}
      />
    )
  }

  return (
    <div className={classnames("form-group", { required: required })}>
      <label htmlFor={id}>{label}</label>
      <MaskedInput
        mask={currencyMask}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="off"
        {...{ className, id, name, type, value: formatMoney(value), ...props }}
      />
      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default compose(observer, connect)(CurrencyInput)
