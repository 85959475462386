import React from "react"
import { getIn, ErrorMessage } from "formik"
import { Button, Card, CardTitle } from "components/UI"
import Contact from "./Contact"

const Contacts = ({ name, push, form, ...h }) => {
  const onAddClick = () =>
    push({ l_name: "", f_name: "", p_name: "", position: "", insurance_kinds: [], emails: [], phones: [] })
  const error = getIn(form.errors, name, false)
  return (
    <Card>
      <CardTitle>Контакты</CardTitle>
      {form.values.contacts.map((contact, index) => (
        <Contact {...{ key: `${index}`, index, contact, h }} />
      ))}
      <Button variant="success" onClick={onAddClick}>
        Добавить контакт
      </Button>
      {error && Array.isArray(error) && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </Card>
  )
}

export default Contacts
