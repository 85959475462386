import { types } from "mobx-state-tree"

const CarBrand = types.model("CarBrand", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const CarModel = types.model("CarModel", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string),
  car_brand: types.maybeNull(CarBrand)
})

export { CarModel }
