import Breadcrumbs from "pages/Contracts/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import ContractStore from "stores/ContractStore/One"
import ParcelStore from "stores/ParcelStore/One"
import { BreadcrumbItem, Button, Card } from "components/UI"
import formatDate from "utils/formatDate"
import { Link } from "react-router-dom"

const contractStore = ContractStore.create()
const parcelStore = ParcelStore.create()

const status = (status) => {
  if (status.value === 0) {
    return <i className="fa fa-circle" style={{ color: "red" }} />
  }
  return <i className="fa fa-circle" style={{ color: "green" }} />
}

const Show = (props) => {
  const { id, contract_id } = props.match.params
  useEffect(() => {
    contractStore.fetch({ id: contract_id })
    parcelStore.fetch({ id, contract_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{contract_id}</BreadcrumbItem>
        <BreadcrumbItem url={`/contracts/${contract_id}/parcels`}>Отгрузки</BreadcrumbItem>
        <BreadcrumbItem active>{parcelStore.number}</BreadcrumbItem>
      </Breadcrumbs>
      <br />
      <Card>
        <Button url={`/contracts/${contract_id}/parcels/${parcelStore.id}/edit`}>Редактировать</Button>
      </Card>
      <div className="row">
        <div className="col-12">
          <table className="table">
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Статус</td>
                <td style={{ width: "50%" }}>{status(parcelStore.data.status)}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата отправки</td>
                <td style={{ width: "50%" }}>{formatDate(parcelStore.data.departure_on)}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>№ накладной / № заказа</td>
                <td style={{ width: "50%" }}>{parcelStore.data.number}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Отправитель</td>
                <td style={{ width: "50%" }}>{parcelStore.data.sender}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Адрес отправителя</td>
                <td style={{ width: "50%" }}>{parcelStore.data.sender_address}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Получатель</td>
                <td style={{ width: "50%" }}>{parcelStore.data.recipient}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Адрес получателя</td>
                <td style={{ width: "50%" }}>{parcelStore.data.recipient_address}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Страховая сумма</td>
                <td style={{ width: "50%" }}>{parcelStore.data.cost}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Страховая премия</td>
                <td style={{ width: "50%" }}>{parcelStore.data.sp}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className="table">
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Страховой сертификат</td>
                <td style={{ width: "50%" }}>{parcelStore.data.sertificate_number}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>№ бордеро</td>
                <td style={{ width: "50%" }}>
                  {parcelStore.data.parcel_bordero ? (
                    <Link to={`/contracts/${contract_id}/parcel_borderos/${parcelStore.data.parcel_bordero.id}`}>
                      {parcelStore.data.parcel_bordero.number}
                    </Link>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата бордеро</td>
                <td style={{ width: "50%" }}>
                  {parcelStore.data.parcel_bordero ? formatDate(parcelStore.data.parcel_bordero.date_on) : "-"}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Комментарий</td>
                <td style={{ width: "50%" }}>{parcelStore.data.comment}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Создал</td>
                <td style={{ width: "50%" }}>{parcelStore.data.creator.name}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Дата создания</td>
                <td style={{ width: "50%" }}>{formatDate(parcelStore.data.created_at)}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className="table">
            <thead>
              <tr>
                <th>Тип документа</th>
                <th>Файл</th>
                <th>Комментарий</th>
              </tr>
            </thead>
            <tbody>
              {parcelStore.data.documents.map((i, index) => (
                <tr key={`avr-document-${index}`}>
                  <td style={{ width: "25%" }}>
                    {String(i.document_kind.id) === "18" ? i.document_kind_other : i.document_kind.name}
                  </td>
                  <td style={{ width: "50%" }}>
                    {i.attachments
                      .filter((i) => i.file.url)
                      .map((attachment) => (
                        <a
                          key={`attachment-${attachment.id}`}
                          href={attachment.file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {decodeURI(attachment.file.url.replace(/^.*[\\|/]/, ""))}
                          <br />
                        </a>
                      ))}
                  </td>

                  <td style={{ width: "25%" }}>{i.comment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

export default observer(Show)
