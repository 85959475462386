import React, { Fragment } from "react"
import { MultipleFileInput } from "components/UI"
import instance from "connection/instance"
import { FieldArray } from "formik"

const Document = ({ prefix = null, form, index }) => {
  const { setFieldValue, setSubmitting } = form
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const ids = form.values.kind == "entity" ? [3, 18] : [1, 2, 20, 10, 9, 18]

  const onFileInputChange = (e, push) => {
    setSubmitting(true)
    const formData = new FormData()
    formData.append("data[file]", e.target.files[0])
    formData.append("data[documentable_type]", "LossDocument")
    instance
      .post("/documents", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(({ data }) => {
        push(data.data)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onRemove = (id) =>
    setFieldValue(
      path("attachments"),
      form.values.documents[index].attachments.filter((i) => i.id.toString() !== id.toString())
    )

  return (
    <Fragment>
      <FieldArray
        name={path("attachments")}
        render={({ push }) => (
          <MultipleFileInput
            name={path("attachments")}
            onChange={(e) => onFileInputChange(e, push)}
            onRemove={onRemove}
          />
        )}
      />
    </Fragment>
  )
}
export default Document
