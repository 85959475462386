import React, { useContext, Fragment } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"

const CheckPermissions = ({ condition, children }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  if (!condition(currentUser.privileges)) {
    return (
      <Fragment>
        <h1>ОШИБКА 401</h1>
        <p>У вас нет прав для доступа к этой странице. Обратитесь к системному администратору.</p>
      </Fragment>
    )
  }
  return children
}

export default CheckPermissions
