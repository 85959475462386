import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetMotivationsStore from "stores/BudgetMotivationStore/List"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { Button, Card, BreadcrumbItem } from "components/UI"
import BudgetStore from "stores/BudgetStore/One"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/BudgetMotivations/components/Tabs"
import Company from "./components/Company"
import Subdivision from "./components/Subdivision"

const budgetStore = BudgetStore.create()
const budgetMotivationStore = BudgetMotivationsStore.create()

const List = (props) => {
  const { budget_id } = props.match.params
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetMotivationStore.fetchAll({ budget_id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>Мотивация</BreadcrumbItem>
        <BreadcrumbItem active>Начисленная</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      {budgetStore.data.kind.value.toString() === "0" ? (
        <Subdivision budget_id={budget_id} budgetStore={budgetStore} budgetMotivationStore={budgetMotivationStore} />
      ) : (
        <Company budget_id={budget_id} budgetStore={budgetStore} budgetMotivationStore={budgetMotivationStore} />
      )}
    </Fragment>
  )
}

export default observer(List)
