import React from "react"
import compose from "utils/compose"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { withRouter } from "react-router"
import { DateInput, ResetButton, SelectInput, Submit } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import UserSelect from "components/Common/UserSelect"

const Filter = ({ store, handleSubmit }) => {
  const { kindsOptions, statusesOptions } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <UserSelect name="by_customer.id" label="Отправитель" />
        </div>
        <div className="col-3">
          <SelectInput name="by_kind" label="Тип" options={kindsOptions} />
        </div>
        <div className="col-3">
          <SelectInput name="by_status" label="Статус" options={statusesOptions} />
        </div>
        <div className="col-3">
          <DateInput name="by_created_at_from" label="Дата создания с" />
        </div>
        <div className="col-3">
          <DateInput name="by_created_at_to" label="Дата создания по" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
