import React, { Fragment } from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { ResetButton, Submit, TextInput, DateInput } from "components/UI"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import filterSubmitHandler from "utils/filterSubmitHandler"
import ClientSelect from "components/Common/ClientSelect"
import UserSelect from "components/Common/UserSelect"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import InsuranceKindSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsuranceKindSelect"

const Filter = ({ store, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <InsurerSelect name="by_insurer" />
      </div>
      <div className="col-3">
        <ClientSelect name="by_client.id" />
      </div>
      <div className="col-3">
        <UserSelect name="by_user.id" label="Исполнитель" />
      </div>
      <div className="col-3">
        <InsuranceKindSelect name="by_insurance_kind" />
      </div>
      <div className="col-3">
        <TextInput name="by_number" label="№ договора" />
      </div>
      <div className="col-3">
        <SubdivisionSelect name="by_subdivision.id" />
      </div>
      <div className="col-3">
        <DateInput name="by_payment_on_start" label="Период отчета с" />
      </div>
      <div className="col-3">
        <DateInput name="by_payment_on_finish" label="Период отчета по" />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
    {store.meta.statistics.map((row, index) => (
      <Fragment>
        &nbsp;&nbsp;&nbsp;&nbsp;{row[0]}: {row[1]}
      </Fragment>
    ))}
  </form>
)

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => store.meta.filter,
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
