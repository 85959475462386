import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/One"
import Breadcrumbs from "pages/Insurers/components/Breadcrumbs"
import { BreadcrumbItem } from "components/UI"
import Tabs from "pages/Insurers/components/Tabs"
import instance from "connection/instance"
import formatMoney from "utils/formatMoney"

const insurerStore = InsurerStore.create()

const Finances = (props) => {
  const [data, setData] = useState({})
  useEffect(() => {
    const { id } = props.match.params
    insurerStore.fetch({ id })

    instance.get(`/insurers/${id}/finances`).then(({ data }) => setData(data))
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{insurerStore.data.name}</BreadcrumbItem>
      </Breadcrumbs>
      <Tabs {...{ id: insurerStore.data.id }} />
      <br />
      <div className="row">
        {/* <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th>Структура сборов / срез по годам</th>
                {Object.keys(data.total || {}).map((year) => (
                  <Fragment>
                    <th>{year}</th>
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>СП (опл.)</td>
                {Object.values(data.total || {}).map((i) => (
                  <Fragment>
                    <td>{formatMoney(i["СП (опл.)"])}</td>
                  </Fragment>
                ))}
              </tr>
              <tr>
                <td>КВ по опл. СП (полное)</td>
                {Object.values(data.total || {}).map((i) => (
                  <Fragment>
                    <td>{formatMoney(i["КВ по опл. СП (полное)"])}</td>
                  </Fragment>
                ))}
              </tr>
              <tr>
                <td>Кол-во договоров (по котором опл. СП)</td>
                {Object.values(data.total || {}).map((i) => (
                  <Fragment>
                    <td>{i["Кол-во договоров (по котором опл. СП)"]}</td>
                  </Fragment>
                ))}
              </tr>
            </tbody>
          </table>
        </div> */}

        <div className="col-12">
          <div style={{ overflowX: "scroll" }}>
            <table className="table table-striped" style={{ width: "4000px" }}>
              <thead>
                <tr className="table-primary">
                  <th>Структура сборов / срез по годам</th>
                  {Object.keys(data.total || {}).map((year) => (
                    <Fragment>
                      <th colSpan={5}>{year}</th>
                    </Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>СП (опл.)</td>
                  {Object.values(data.total || {}).map((i) => (
                    <Fragment>
                      <td colSpan={5}>{formatMoney(i["СП (опл.)"])}</td>
                    </Fragment>
                  ))}
                </tr>
                <tr>
                  <td>КВ по опл. СП (полное)</td>
                  {Object.values(data.total || {}).map((i) => (
                    <Fragment>
                      <td colSpan={5}>{formatMoney(i["КВ по опл. СП (полное)"])}</td>
                    </Fragment>
                  ))}
                </tr>
                <tr>
                  <td>Кол-во договоров (по котором опл. СП)</td>
                  {Object.values(data.total || {}).map((i) => (
                    <Fragment>
                      <td colSpan={5}>{i["Кол-во договоров (по котором опл. СП)"]}</td>
                    </Fragment>
                  ))}
                </tr>
                <tr>
                  <td colSpan={30}>&nbsp;</td>
                </tr>
              </tbody>
              <thead>
                <tr className="table-primary">
                  <th>Структура портфеля / срез по годам</th>
                  {Object.keys(data.data_by_insurance_kind || {}).map((year) => (
                    <Fragment>
                      <th colSpan={5}>{year}</th>
                    </Fragment>
                  ))}
                </tr>
                <tr class="table-info">
                  <th></th>
                  {Object.keys(data.data_by_insurance_kind || {}).map((year) => (
                    <Fragment>
                      <th>СП (опл.)</th>
                      <th>
                        Доля
                        <br />в общем объеме
                      </th>
                      <th>КВ по опл. СП</th>
                      <th>
                        Доля
                        <br />в общем объеме
                      </th>
                      <th>
                        Кол-во договоров
                        <br />
                        (по котором опл. СП)
                      </th>
                    </Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(Object.values(data.data_by_insurance_kind || {})[0] || []).map((year, index) => (
                  <tr>
                    <td>{year[0]}</td>
                    {Object.values(data.data_by_insurance_kind || {}).map((_, yearIndex) => (
                      <Fragment>
                        <td>{formatMoney(Object.values(data.data_by_insurance_kind || {})[yearIndex][index][1])}</td>
                        <td>{Object.values(data.data_by_insurance_kind || {})[yearIndex][index][2]}</td>
                        <td>{formatMoney(Object.values(data.data_by_insurance_kind || {})[yearIndex][index][3])}</td>
                        <td>{Object.values(data.data_by_insurance_kind || {})[yearIndex][index][4]}</td>
                        <td>{Object.values(data.data_by_insurance_kind || {})[yearIndex][index][5]}</td>
                      </Fragment>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default observer(Finances)
