import React, { Fragment, useEffect, useContext, useState } from "react"
import { observer } from "mobx-react"
import Breadcrumbs from "pages/Reports/components/Breadcrumbs"
import { BreadcrumbItem, Card } from "components/UI"
import Filter from "./components/Filter"
import Table from "./components/Table"
import TableByQuart from "./components/TableByQuart"
import AccumulationTable from "./components/AccumulationTable"
import ProgressTable from "./components/ProgressTable"
import ProgressAccumulationTable from "./components/ProgressAccumulationTable"
import ReportStore from "stores/ReportStore/AnalyticsKv"
import { historyPushFromStore } from "utils/historyPushWithStore"
import AuthStoreContext from "contexts/AuthStoreContext"

const reportStore = ReportStore.create()

const AnalyticsKv = (props) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  const [year, setYear] = useState(new Date().getFullYear())
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    reportStore.fetch({
      filter: {
        year: year,
        subdivision_id:
          [7, 8].includes(currentUser.subdivision.id) || currentUser.id.toString() == "48"
            ? null
            : currentUser.subdivision.id
      }
    })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Аналитика КВ</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Filter store={reportStore} setYear={setYear} />
      </Card>
      <h4 className="text-center">План-факт КВ</h4>
      <Table store={reportStore} />
      <h4 className="text-center">План-факт КВ(поквартально)</h4>
      <TableByQuart store={reportStore} />
      <h4 className="text-center">Исполнение плана по КВ накопительным итогом</h4>
      <AccumulationTable store={reportStore} />
      <h4 className="text-center">Темп прироста КВ</h4>
      <ProgressTable store={reportStore} year={year} />
      <h4 className="text-center">Темп прироста КВ накопительные итогом</h4>
      <ProgressAccumulationTable store={reportStore} year={year} />
    </Fragment>
  )
}

export default observer(AnalyticsKv)
