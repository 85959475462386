import store from "store"

const success = (response) => {
  return response
}

const error = (error) => {
  if (401 === error.response.status) {
    store.remove("authStore")
    if (error.response.config.url !== "/api/auth/sign_in") {
      window.location = "/auth/login"
    }
  }
  return Promise.reject(error)
}

export default [success, error]
