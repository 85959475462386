import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import SubagentStore from "stores/SubagentStore/One"
import ContactStore from "stores/ContactStore/One"
import InsuranceKindStore from "stores/InsuranceKindStore/List"
import Breadcrumbs from "pages/Subagents/containers/Contacts/components/Breadcrumbs"
import Form from "pages/Subagents/containers/Contacts/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContactFormContext from "contexts/ContactFormContext"

class Edit extends Component {
  subagentStore = SubagentStore.create()
  contactStore = ContactStore.create()
  insuranceKindStore = InsuranceKindStore.create()

  componentDidMount() {
    const { id, subagent_id } = this.props.match.params
    this.subagentStore.fetch({ id: subagent_id })
    this.contactStore.fetch({ id, subagent_id })
    this.insuranceKindStore.fetch()
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) => {
    const { id: subagent_id } = this.subagentStore.data
    return store
      .update({ ...values, subagent_id })
      .then(() => history.push(`/subagents/${subagent_id}/contacts`))
      .catch(({ response }) => setErrors(response.data.errors))
  }

  render() {
    const {
      subagentStore: { data: subagent },
      contactStore: store,
      handleSubmit,
      insuranceKindStore
    } = this
    return (
      <Fragment>
        <Breadcrumbs {...{ subagent }}>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <ContactFormContext.Provider value={{ insuranceKindStore }}>
          <Form {...{ store, handleSubmit }} />
        </ContactFormContext.Provider>
      </Fragment>
    )
  }
}

export default observer(Edit)
