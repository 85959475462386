import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { DateInput, TextInput, CurrencyInput } from "components/UI"

const OrderFireAtWillTab = ({ store, handleSubmit, values, setFieldValue, ...props }) => {
  return (
    <Fragment>
      <br />
      <DateInput name="order_fire_at_will.date_on" label="Дата увольнения" required />
      <TextInput name="order_fire_at_will.reason" label="Основание расторжения трудового договора (увольнения)" required />
      <TextInput name="order_fire_at_will.reason_document" label="Основание (документ)" required />
      <TextInput name="order_fire_at_will.comment" label="Комментарий" />
    </Fragment>
  )
}

export default observer(OrderFireAtWillTab)
