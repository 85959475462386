import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import Shipment from "./Shipment"
import { prepare } from "./utils"

const ShipmentStore = types
  .model("ShipmentStore", {
    data: types.optional(Shipment, {
      number: null,
      cargo_prefabricated: { id: 0 },
      cargos: [],
      cargo_route: null,
      cargo_shipment_on: null,
      cargo_combined: { id: 0 },
      transport_kinds: [],
      comment: null,
      currency: { value: 0 },
      documents: []
    }),
    meta: types.optional(types.map(types.number), {}),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },
    
    get number() {
      return self.data.number
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, contract_id, ...options } = params
      return instance
        .get(`/contracts/${contract_id}/shipments/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")

      return instance
        .get(`/Shipments/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const { contract_id } = params
      const data = prepare(params)
      return instance
        .post(`/contracts/${contract_id}/shipments`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const { id, contract_id } = params
      const data = prepare(params)
      return instance
        .put(`/contracts/${contract_id}/shipments/${id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { ...self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ShipmentStore
