import React from "react"
import classnames from "classnames"

const Card = ({ children, required, style = {} }) => (
  <div className={classnames("card", { required: required })} style={style}>
    <div className="card-body">{children}</div>
  </div>
)

export default Card
