import React from "react"
import compose from "utils/compose"
import { CurrencyInput, TextInput, ResetButton, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"

const Filter = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <TextInput name="by_number" label="Номер АВР" />
      </div>
      <div className="col-3">
        <InsurerSelect name="by_insurer" />
      </div>
      <div className="col-3">
        <ChannelSelect name="by_channel" />
      </div>
      <div className="col-3">
        <CurrencyInput name="by_kv_sum" label="Сумма" />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
  </form>
)

export default compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => ({
      by_client: {
        id: props.client?.id,
        name: props.client?.name
      }
    }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  }),
  observer
)(Filter)
