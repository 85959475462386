import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { Submit, TextInput } from "components/UI"

const Form = ({ handleSubmit, values }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="name" label="Название" />
    <Submit>Сохранить</Submit>
  </form>
)

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  })
)(Form)
