import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import Scans from "./Scans"
import ContractDocumentStore from "stores/ContractDocumentStore/List"
import ContactFormContext from "contexts/ContactFormContext"

const contractDocumentStore = ContractDocumentStore.create()

const CommonTab = ({ store, ...props }) => {
  // useEffect(() => {
  //   contractDocumentStore.fetchMeta()
  // }, [])
  return (
    <Fragment>
      <br />
      <ContactFormContext.Provider value={{ contractDocumentStore }}>
        <Scans />
      </ContactFormContext.Provider>
    </Fragment>
  )
}

export default observer(CommonTab)
