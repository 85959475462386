import { types } from "mobx-state-tree"
import customDate from "types/customDate"

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const Document = types.model("Document", {
  id: types.maybeNull(types.integer),
  comment: types.maybeNull(types.string),
  document_kind: types.maybeNull(DocumentKind),
  document_kind_other: types.maybeNull(types.string),
  attachments: types.maybeNull(types.array(Attachment))
})

export default Document
