import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import state from "types/state"
import formatDate from "utils/formatDate"
import formatDateTime from "utils/formatDateTime"
import toOptions from "utils/toOptions"
import Appeal from "./Appeal"
import Meta from "./OneMeta"
import { prepare } from "./utils"

const dataDefaultValue = {
  channel_client: { id: null },
  channel_subagent: { id: null },
  client: { id: null },
  kind: { id: null }
}

const metaDefaultValue = {
  filters_options: {}
}

const AppealStore = types
  .model("AppealStore", {
    data: types.optional(Appeal, dataDefaultValue),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(state)
  })
  .views(self => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get created_at() {
      const { created_at } = self.data
      return created_at && formatDateTime(created_at)
    },

    get creator() {
      const { creator } = self.data
      return creator && creator.name
    },

    get client() {
      const { client } = self.data
      return client && client.name
    },

    get source() {
      const { source } = self.data
      return source && source.name
    },

    get kind() {
      const { kind } = self.data
      return kind && kind.name
    },

    get feedback_on() {
      const { feedback_on } = self.data
      return feedback_on && formatDate(feedback_on)
    },

    get description() {
      return self.data.description
    },

    get filtersOptions() {
      const { kinds, sources } = self.meta.filters_options
      return [toOptions(kinds), toOptions(sources)]
    }
  }))
  .actions(self => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id } = params
      return instance
        .get(`/appeals/${id}`)
        .then(response => self.applyData(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/appeals/meta`)
        .then(response => self.applyMeta(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/appeals`, { data })
        .then(response => self.applyData(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/appeals/${data.id}`, { data })
        .then(response => self.applyData(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    applyData(response) {
      const {
        status,
        data: { data }
      } = response
      if (status === 200) applySnapshot(self, { ...self, data })
      return self
    },

    applyMeta(response) {
      const {
        status,
        data: { meta }
      } = response
      if (status === 200) applySnapshot(self, { ...self, meta })
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default AppealStore
