import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import DocumentFlowStore from "stores/DocumentFlowStore/One"
import Breadcrumbs from "pages/SentDocumentFlows/components/Breadcrumbs"
import formatDate from "utils/formatDate"
import { BreadcrumbItem, Button, Card } from "components/UI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import { Link } from "react-router-dom"
import CommonTab from "./CommonTab"
import SpecificationTab from "./SpecificationTab"

class Show extends Component {
  documentFlowStore = DocumentFlowStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.documentFlowStore.fetch({ id })
  }

  render() {
    const { documentFlowStore: store } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{store.id}</BreadcrumbItem>
        </Breadcrumbs>
        <Tabs defaultActiveKey="common">
          <Tab eventKey="common" title="Общие сведения">
            <br />
            <Card>
              <Button url={`/sent_document_flows/${store.data.id}/edit`}>Редактировать</Button>&nbsp;
            </Card>
            <CommonTab store={store} />
          </Tab>
          <Tab eventKey="specification" title="Спецификация">
            <br />
            <SpecificationTab store={store} />
          </Tab>
          <Tab eventKey="params" title="Технические параметры">
            {/* <CommonTab {...{ store, ...props }} /> */}
          </Tab>
        </Tabs>
      </Fragment>
    )
  }
}

export default observer(Show)
