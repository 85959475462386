import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik, FieldArray } from "formik"
import { withRouter } from "react-router"
import formSubmitHandler from "utils/formSubmitHandler"
import { Submit } from "components/UI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import InfoTab from "./InfoTab"
import Documents from "./InfoTab/Documents"

const Form = ({ store, values, setFieldValue, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Общие сведения">
          <br />
          <InfoTab values={values} store={store} setFieldValue={setFieldValue} />
        </Tab>
        <Tab eventKey="documents" title="Документы">
          <br />
          <FieldArray name="documents" render={props => <Documents {...props} store={store} />} />
        </Tab>
      </Tabs>
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/agency_contracts")
  }),
  observer
)(Form)
