import React from "react"
import compose from "utils/compose"
import { DateInput, ResetButton, SelectInput, Submit, TextInput } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"

const Filter = ({ store, handleSubmit }) => {
  const { positionsOptions, subdivisionsOptions, statusesOptions } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <SelectInput name="by_subdivision.id" label="Подразделение" options={subdivisionsOptions} />
        </div>
        <div className="col-3">
          <SelectInput name="by_position.id" label="Должность" options={positionsOptions} />
        </div>
        <div className="col-3">
          <DateInput name="by_birthday_on" label="Дата рождения" />
        </div>
        <div className="col-3">
          <SelectInput name="by_status" label="Статус" options={statusesOptions} />
        </div>
        <div className="col-3">
          <TextInput name="by_name" label="ФИО" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  })
)(Filter)
