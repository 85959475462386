import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import Documents from "./../Documents"

const ChangePositionTab = ({ store }) => {
  const {data} = store
  return (
    <div className="row">
      <div className="col-12">
        <table className="table">
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>Дата перевода на другую должность</td>
              <td style={{ width: "50%" }}>{formatDate(data.order_change_position.date_on)}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Вид перевода</td>
              <td style={{ width: "50%" }}>{data.order_change_position.kind}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Новое подразделение</td>
              <td style={{ width: "50%" }}>{data.order_change_position.subdivision.name}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Новая должность</td>
              <td style={{ width: "50%" }}>{data.order_change_position.position.name}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Новый оклад</td>
              <td style={{ width: "50%" }}>{data.order_change_position.salary}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Основание (документ)</td>
              <td style={{ width: "50%" }}>{data.order_change_position.reason_document}</td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Комментарии</td>
              <td style={{ width: "50%" }}>{data.order_change_position.comment}</td>
            </tr>
          </tbody>
        </table>
        <Documents store={store} />
      </div>
    </div>
  )
}

export default observer(ChangePositionTab)
