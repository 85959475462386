import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { Card, CardTitle, CurrencyInput, TextInput, RadioButtonGroup, RadioInput } from "components/UI"
import ClientSelect from "components/Common/ClientSelect"
import CourtSelect from "components/Common/CourtSelect"

const ProceedingTab = ({ store, values }) => {
  return (
    <Fragment>
      <br />
      <TextInput name="contract_proceeding.subject" label="Предмет договора (описание)" required />
      <RadioButtonGroup label="Сторона" name={"contract_proceeding.side.value"}>
        {[
          { label: "Истец", value: "plaintiff" },
          { label: "Ответчик", value: "defendant" }
        ].map(({ label, value }) => (
          <RadioInput key={`contract_proceeding_side_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {values.contract_proceeding.side?.value === "plaintiff" && (
        <ClientSelect name="contract_proceeding.plaintiff.id" label="Истец" required />
      )}
      {values.contract_proceeding.side?.value === "defendant" && (
        <TextInput name="contract_proceeding.plaintiff_comment" label="Истец" required />
      )}
      {values.contract_proceeding.side?.value === "defendant" && (
        <ClientSelect name="contract_proceeding.defendant.id" label="Ответчик" required />
      )}
      {values.contract_proceeding.side?.value === "plaintiff" && (
        <TextInput name="contract_proceeding.defendant_comment" label="Ответчик" required />
      )}
      <Fragment>
        <hr />
        <h6>Требования</h6>
        <div className="row">
          <div className="col-3">
            <CurrencyInput name="contract_proceeding.requirement_duty" label="Основной долг" />
          </div>
          <div className="col-3">
            <CurrencyInput name="contract_proceeding.requirement_penalty" label="Неустойка" />
          </div>
          <div className="col-3">
            <CurrencyInput name="contract_proceeding.requirement_fee" label="Пошлина" />
          </div>
          <div className="col-3">
            <CurrencyInput name="contract_proceeding.requirement_expense" label="Судебные расходы" />
          </div>
        </div>
      </Fragment>
      <CurrencyInput name="contract_proceeding.bargain" label="Макс./Мин. сумма для торга" />
      <TextInput name="contract_proceeding.case_number" label="№ дела" />
      <CourtSelect name="contract_proceeding.court.id" />
      <TextInput name="contract_proceeding.judge" label="Судья 1 инстанции" />
      <TextInput name="contract_proceeding.comment" label="Доп.информация" />
      <Card>
        <CardTitle>Результат</CardTitle>
        <RadioButtonGroup name={"contract_proceeding.result.value"}>
          {[
            { label: "В работе", value: "in_work" },
            { label: "Выиграно", value: "won" },
            { label: "Проиграно", value: "lost" },
            { label: "Нетиповой", value: "atypical" }
          ].map(({ label, value }) => (
            <RadioInput key={`contract_proceeding_result_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
        {values.contract_proceeding.result?.value === "atypical" && (
          <TextInput name="contract_proceeding.result_comment" />
        )}
        <Fragment>
          <hr />
          <h6>Взыскано</h6>
          <div className="row">
            <div className="col-3">
              <CurrencyInput name="contract_proceeding.recovered_duty" label="Сумма взыскания" />
            </div>
            <div className="col-3">
              <CurrencyInput name="contract_proceeding.recovered_penalty" label="Сумма неустойки" />
            </div>
            <div className="col-3">
              <CurrencyInput name="contract_proceeding.recovered_expense" label="Сумма судебных расходов" />
            </div>
          </div>
        </Fragment>
      </Card>
    </Fragment>
  )
}

export default observer(ProceedingTab)
