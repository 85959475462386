import { useState } from "react"
import instance from "connection/instance"
import parseISO from "date-fns/parseISO"

function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

const defaultData = {
  status: { value: "in_work" },
  kind: { value: "session" },
  attachments: [],
  performer: { id: null },
  court: { id: null }
}

const prepare = (params = {}) => {
  const {
    status: { value: status },
    performer: { id: performer_id },
    court: { id: court_id },
    insurance_kind: { id: insurance_kind_id },
    attachments,
    ...other
  } = params
  return {
    ...other,
    status,
    performer_id,
    court_id,
    insurance_kind_id,
    attachment_ids: attachments.map((i) => i.id)
  }
}

const convert = (params = {}) => {
  return {
    ...params,
    date_on: params.date_on ? parseISO(params.date_on) : null,
    time_at: params.time_at ? convertDateToUTC(parseISO(params.time_at)) : null
  }
}

const useContractTask = (data = {}) => {
  const [contractBankruptcyTask, setContractTask] = useState({ ...defaultData, ...data })

  const createContractTask = async (data) => {
    const {
      data: { contract_task }
    } = await instance.post("/pages/contract_tasks", { data: prepare(data) })
    // setContractTask(contract_task)
    return convert(contract_task)
  }

  const updateContractTask = async (data) => {
    const {
      data: { contract_task }
    } = await instance.put(`/pages/contract_tasks/${data.id}`, { data: prepare(data) })
    // setContractTask(contract_task)
    return convert(contract_task)
  }

  const resetContractTask = (data = {}) => setContractTask({ ...defaultData, ...data })

  return {
    contractBankruptcyTask,
    setContractTask,
    createContractTask,
    updateContractTask,
    resetContractTask
  }
}

export default useContractTask
