import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">№ отгрузки</th>
        <th scope="col">Наименование грузов</th>
        <th scope="col">Валюта договора</th>
        <th scope="col">Страховая премия</th>
        <th scope="col">Маршрут</th>
        <th scope="col">Дата отгрузки</th>
        <th scope="col">Доп. информация</th>
        <th scope="col">Копия декларации</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`shipments-${index}`}>
          <td>{row.number}</td>
          <td>{row.cargos.map((i) => i.name).join(", ")}</td>
          <td>{row.currency.label}</td>
          <td>{row.sp_cost}</td>
          <td>{row.cargo_route}</td>
          <td>{formatDate(row.cargo_shipment_on)}</td>
          <td>{row.comment}</td>
          <td>
            {row.documents.map((document) => (
              <a
                key={`document-${document.id}`}
                href={document.file.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {decodeURI(document.file.url.replace(/^.*[\\|/]/, ""))}
                <br />
              </a>
            ))}
          </td>
          <td>
            <Edit url={`/contracts/${row.contract_id}/shipments/${row.id}/edit`} />
            <Destroy
              action={() =>
                afterDestroyFetch(store, { id: row.id, contract_id: row.contract_id }, { contract_id: row.contract_id })
              }
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
