import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { Button } from "components/UI"

const CommonTab = ({ store }) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <td style={{ width: "50%" }}>Сформировано (от)</td>
          <td style={{ width: "50%" }}>{store.customer.name}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Подразделение (от)</td>
          <td style={{ width: "50%" }}>{store.customer.subdivision.name}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Получатель (кому)</td>
          <td style={{ width: "50%" }}>{store.executor.name}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Подразделение (кому)</td>
          <td style={{ width: "50%" }}>{store.executor.subdivision.name}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Срочность</td>
          <td style={{ width: "50%" }}>{store.urgency.label}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Дата создания</td>
          <td style={{ width: "50%" }}>{formatDate(store.created_at)}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Статус</td>
          <td style={{ width: "50%" }}>
            {store.status.label}&nbsp;&nbsp;&nbsp;
            {store.status.value.toString() == "1" && (
              <Button size="sm" onClick={() => store.setStatus(2)}>
                Перевести в статус "Получено"
              </Button>
            )}&nbsp;&nbsp;&nbsp;
            {store.status.value.toString() == "2" && (
              <Button size="sm" onClick={() => store.setStatus(3)}>
                Перевести в статус "В обработке"
              </Button>
            )}&nbsp;&nbsp;&nbsp;
            {store.status.value.toString() == "3" && (
              <Button size="sm" onClick={() => store.setStatus(4)}>
                Перевести в статус "Аннулировано"
              </Button>
            )}&nbsp;&nbsp;&nbsp;
            {store.status.value.toString() == "3" && (
              <Button size="sm" onClick={() => store.setStatus(5)}>
                Перевести в статус "Отказано"
              </Button>
            )}&nbsp;&nbsp;&nbsp;
            
            {store.status.value.toString() == "3" && (
              <Button size="sm" onClick={() => store.setStatus(6)}>
                Перевести в статус "Обработано"
              </Button>
            )}&nbsp;&nbsp;&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default observer(CommonTab)
