import React, { useEffect, Fragment } from "react"
import compose from "utils/compose"
import { withFormik } from "formik"
import { observer, PropTypes } from "mobx-react"
import { withRouter } from "react-router"
import {
  AsyncSelectInput,
  Card,
  CardTitle,
  DateInput,
  EmailInput,
  RadioButtonGroup,
  RadioInput,
  SelectInput,
  Submit,
  TextInput
} from "components/UI"
import formSubmitHandler from "utils/formSubmitHandler"
import InsurerStore from "stores/InsurerStore/List"
import ChannelStore from "stores/ChannelStore/List"
import BsoKindStore from "stores/BsoKindStore/List"

const insurerStore = InsurerStore.create()
const channelStore = ChannelStore.create()
const bsoKindStore = BsoKindStore.create()

const Form = ({ store, values, handleSubmit, ...props }) => {
  useEffect(() => {
    store.fetchMeta()
    insurerStore.fetch({order_by_ordered: "asc"})
    channelStore.fetch()
  }, [])

  useEffect(() => {
    bsoKindStore.fetch({ filter: { by_insurer_id: values.insurer.id } })
  }, [values.insurer.id])

  const insurerOptions = (() => {
    const { id, name } = values.insurer
    return id ? [{ label: name, value: id }] : insurerStore.insurerOptions
  })()

  const loadInsurerOptions = (input) =>
    insurerStore
      .fetch({ order_by_ordered: "asc", filter: { by_name: input } })
      .then(({ insurerOptions }) => insurerOptions)

  const channelOptions = (() => {
    const { id, name } = values.channel
    return id ? [{ label: name, value: id }] : channelStore.channelOptions
  })()

  const loadChannelOptions = (input) =>
    channelStore.fetch({ filter: { by_name: input }, limit: 0 }).then(({ channelOptions }) => channelOptions)

  const bsoKindOptions = (() => {
    const { id, name, ...other } = values.bso_kind
    return id ? [{ label: name, value: id }] : bsoKindStore.bsoKindOptions
  })()

  const loadBsoKindOptions = (input) =>
    bsoKindStore
      .fetch({ filter: { by_insurer_id: values.insurer.id, by_name: input } })
      .then(({ bsoKindOptions }) => bsoKindOptions)

  const bso_kind = bsoKindStore.data.find((i) => i.id === values.bso_kind.id)
  const bsoBatchOptions = bso_kind
    ? bso_kind.bso_batches.map((i) => {
        return {
          label: i.name,
          value: i.id
        }
      })
    : []

  const { form_statuses, geolocation_statuses } = store.filtersOptions

  const number_statuses = [
    { label: "Нет", value: 0 },
    { label: "Да", value: 1 }
  ]

  return (
    <form onSubmit={handleSubmit}>
      <AsyncSelectInput
        name="insurer.id"
        label="Cтраховая компания"
        options={insurerOptions}
        isSearchable
        loadOptions={loadInsurerOptions}
        required
      />
      <AsyncSelectInput
        name="channel.id"
        label="Канал продаж"
        options={channelOptions}
        isSearchable
        loadOptions={loadChannelOptions}
        required
      />
      <AsyncSelectInput
        name="bso_kind.id"
        label="Вид БСО"
        options={bsoKindOptions}
        isSearchable
        loadOptions={loadBsoKindOptions}
        isDisabled={!values.insurer.id}
        required
      />
      <SelectInput
        name="bso_batch.id"
        label="Серия БСО"
        options={bsoBatchOptions}
        isDisabled={!values.bso_kind.id}
        required
      />

      {!values.id && (
        <Fragment>
          <RadioButtonGroup label="Более чем один" name="number_status.value">
            {number_statuses.map(({ label, value }) => (
              <RadioInput key={`status_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          {parseInt(values.number_status.value) === 0 && <TextInput name="number" label="Номер БСО" required />}
          {parseInt(values.number_status.value) === 1 && (
            <Fragment>
              <TextInput
                name="number_from"
                label="Номера БСО c"
                required
                onChange={(e) => {
                  let { value } = e.target
                  value = value.replace(/\D+/gi, "")
                  props.setFieldValue("number_from", value)
                }}
              />
              <TextInput
                name="number_to"
                label="Номера БСО по"
                required
                onChange={(e) => {
                  let { value } = e.target
                  value = value.replace(/\D+/gi, "")
                  props.setFieldValue("number_to", value)
                }}
              />
            </Fragment>
          )}
        </Fragment>
      )}

      {/* {values.id && <TextInput name="number" label="Номер БСО" required />} */}

      <TextInput name="reception_number" label="Номер акта приема из СК" required />
      <DateInput name="reception_on" label="Дата акта приема из СК" required />
      <TextInput name="delivery_number" label="Номер акта сдачи в СК" />
      <DateInput name="delivery_on" label="Дата акта сдачи в СК" />
      <TextInput name="comment" label="Комментарий" />
      {values.form_status.value.toString() === "1" && (
        <div className="form-group">
          <label>
            Договор страхования:&nbsp;<a href={`/contracts/${values.contract.id}`}>{values.contract.number}</a>
          </label>
        </div>
      )}
      <RadioButtonGroup label="Статус бланка" name="form_status.value" disabled={!values.id}>
        {form_statuses.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Статус геолокации" name="geolocation_status.value" disabled={!values.id}>
        {geolocation_statuses.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {values.geolocation_status.value.toString() === "1" && (
        <div className="form-group">
          <label>Подразделение:&nbsp;{values.contract.user && values.contract.user.subdivision.name}</label>
        </div>
      )}
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/bsos")
  }),
  observer
)(Form)
