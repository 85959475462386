import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Plan from "./containers/Plan"
import Accrued from "./containers/Accrued"
import ToPayoff from "./containers/ToPayoff"
import Paid from "./containers/Paid"

const BudgetBudgetMotivations = () => (
  <Switch>
    <Route path="/budgets/:budget_id/budget_motivations/plan" component={Plan} />
    <Route path="/budgets/:budget_id/budget_motivations/accrued" component={Accrued} />
    <Route path="/budgets/:budget_id/budget_motivations/to_payoff" component={ToPayoff} />
    <Route path="/budgets/:budget_id/budget_motivations/paid" component={Paid} />
    <Route path="/budgets/:budget_id/budget_motivations/:id/edit" component={Edit} />
    <Route path="/budgets/:budget_id/budget_motivations/new" component={Add} />
    <Route path="/budgets/:budget_id/budget_motivations/:id" component={Show} />
    <Route path="/budgets/:budget_id/budget_motivations" component={List} />
  </Switch>
)

export default BudgetBudgetMotivations