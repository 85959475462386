import { types } from "mobx-state-tree"

const Risk = types.model("Risk", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  rate: types.maybeNull(types.integer),
  risk_id: types.maybeNull(types.integer)
})

const InsuranceProduct = types.model("InsuranceProduct", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  insurer_id: types.maybeNull(types.integer),
  bso: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  risks: types.maybeNull(types.array(Risk))
})

export { InsuranceProduct }
