import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import UserSalePlansStore from "stores/UserSalePlanStore/One"
import Form from "pages/Budgets/containers/UserSalePlans/components/Form"
import { BreadcrumbItem } from "components/UI"

const budgetStore = BudgetStore.create()
const UserSalePlanStore = UserSalePlansStore.create()

const Add = (props) => {
  const { budget_id } = props.match.params

  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    UserSalePlanStore.fetchMeta({ budget_id: budget_id })
  }, [])

  UserSalePlanStore.reset()

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>{budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}</BreadcrumbItem>
        <BreadcrumbItem url={`/budgets/${budget_id}/user_sale_plans`}>План продаж</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>

      <Form {...{ store: UserSalePlanStore, budgetStore }} />
    </Fragment>
  )
}

export default observer(Add)
