import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { withRouter } from "react-router-dom"

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Стадия</th>
        <th scope="col">Результат</th>
        <th scope="col">Дата</th>
        <th scope="col">Описание / Обстоятельства</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((i) => (
        <tr>
          <td onClick={() => history.push(`/contracts/${i.contract_id}/losses/${i.id}`)}>{i.id}</td>
          <td onClick={() => history.push(`/contracts/${i.contract_id}/losses/${i.id}`)}>{i.status.label}</td>
          <td onClick={() => history.push(`/contracts/${i.contract_id}/losses/${i.id}`)}>{i.result.label}</td>
          <td onClick={() => history.push(`/contracts/${i.contract_id}/losses/${i.id}`)}>{formatDate(i.date_on)}</td>
          <td onClick={() => history.push(`/contracts/${i.contract_id}/losses/${i.id}`)}>{i.description}</td>
          <td>
            <Edit url={`/contracts/${i.contract_id}/losses/${i.id}/edit`} />
            <Destroy
              action={() =>
                afterDestroyFetch(store, { id: i.id, contract_id: i.contract_id }, { contract_id: i.contract_id })
              }
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
