import React, { Fragment } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { Submit } from "components/UI"
import CommonTab from "./CommonTab"
import SpecificationTab from "./SpecificationTab"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

const Form = ({ handleSubmit, store, ...props }) => {
  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Tabs defaultActiveKey="common">
          <Tab eventKey="common" title="Общие сведения">
            <CommonTab {...{ store, ...props }} />
          </Tab>
          <Tab eventKey="specification" title="Спецификация">
            <SpecificationTab {...{ store, ...props }} />
          </Tab>
          <Tab eventKey="params" title="Технические параметры">
            {/* <CommonTab {...{ store, ...props }} /> */}
          </Tab>
        </Tabs>
        <Submit>Сохранить</Submit>
      </form>
    </Fragment>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data.toJSON() }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  }),
  observer
)(Form)
