import Breadcrumbs from "pages/Providers/components/Breadcrumbs"
import React, { Component, Fragment } from "react"
import ProviderStore from "stores/ProviderStore/One"
import { BreadcrumbItem, Button, Card } from "components/UI"
import { observer } from "mobx-react"
import TabsBlock from "pages/Providers/components/Tabs"
import formatDateTime from "utils/formatDateTime"
import formatDate from "utils/formatDate"
import { Link } from "react-router-dom"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import ActivityKindSelect from "../../components/Form/InfoTab/ActivityKindSelect"

class Show extends Component {
  providerStore = ProviderStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.providerStore.fetch({ id })
  }

  render() {
    const {
      providerStore: {
        data: {
          id,
          emails,
          f_name,
          kind,
          l_name,
          comment,
          name,
          organization_name,
          ownership_kind,
          p_name,
          phones,
          position,
          inn,
          address,
          created_at,
          user,
          subdivision,
          holding,
          industry,
          payment_kind,
          contract_number,
          contract_on,
          bank,
          card_number,
          activity_kind
        }
      }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumbs>
        <TabsBlock store={this.providerStore} />
        {kind === "individual" && (
          <Fragment>
            <br />
            <Tabs defaultActiveKey="common">
              <Tab eventKey="common" title="Общие сведения">
                <br />
                <Card>
                  <Button url={`/providers/${id}/edit`}>Редактировать</Button>
                </Card>
                <div className="row">
                  <div className="col-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2">Поставщик</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>Фамилия</td>
                          <td style={{ width: "50%" }}>{l_name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Имя</td>
                          <td style={{ width: "50%" }}>{f_name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Отчество</td>
                          <td style={{ width: "50%" }}>{p_name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Должность</td>
                          <td style={{ width: "50%" }}>{position}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Вид услуги</td>
                          <td style={{ width: "50%" }}>{activity_kind.name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Адрес регистрации</td>
                          <td style={{ width: "50%" }}>{address}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Комментарий</td>
                          <td style={{ width: "50%" }} dangerouslySetInnerHTML={{ __html: comment }} />
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Телефоны</td>
                          <td style={{ width: "50%" }}>
                            {phones.map(({ value }) => (
                              <Fragment>
                                <a href={`tel:${value}`}>{value}</a>
                                <br />
                              </Fragment>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Emails</td>
                          <td style={{ width: "50%" }}>
                            {emails.map(({ value }) => (
                              <Fragment>
                                <a href={`emailto:${value}`}>{value}</a>
                                <br />
                              </Fragment>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Создал</td>
                          <td style={{ width: "50%" }}>{[user.name, subdivision.short_name].join(", ")}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Создано</td>
                          <td style={{ width: "50%" }}>{formatDateTime(created_at)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="additional" title="Дополнительные сведения">
                <br />
                <Card>
                  <Button url={`/providers/${id}/edit`}>Редактировать</Button>
                </Card>
                <div className="row">
                  <div className="col-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2">Дополнительные сведения</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>Отрасль</td>
                          <td style={{ width: "50%" }}>{industry && industry.name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Номер договора c поcтавщиком</td>
                          <td style={{ width: "50%" }}>{contract_number}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Дата договора c поcтавщиком</td>
                          <td style={{ width: "50%" }}>{formatDate(contract_on)}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Тип оплаты</td>
                          <td style={{ width: "50%" }}>{payment_kind.label}</td>
                        </tr>
                        {payment_kind.value == "0" && (
                          <Fragment>
                            <tr>
                              <td style={{ width: "50%" }}>Название банка</td>
                              <td style={{ width: "50%" }}>{bank}</td>
                            </tr>
                          </Fragment>
                        )}
                        {payment_kind.value == "2" && (
                          <Fragment>
                            <tr>
                              <td style={{ width: "50%" }}>Название банка</td>
                              <td style={{ width: "50%" }}>{bank}</td>
                            </tr>
                          </Fragment>
                        )}
                        {payment_kind.value == "3" && (
                          <Fragment>
                            <tr>
                              <td style={{ width: "50%" }}>Номер карты</td>
                              <td style={{ width: "50%" }}>{card_number}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Название банка</td>
                              <td style={{ width: "50%" }}>{bank}</td>
                            </tr>
                          </Fragment>
                        )}
                        {payment_kind.value == "4" && (
                          <Fragment>
                            <tr>
                              <td style={{ width: "50%" }}>Название банка</td>
                              <td style={{ width: "50%" }}>{bank}</td>
                            </tr>
                          </Fragment>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Fragment>
        )}
        {kind === "entity" && (
          <Fragment>
            <br />
            <Tabs defaultActiveKey="common">
              <Tab eventKey="common" title="Общие сведения">
                <br />
                <Card>
                  <Button url={`/providers/${id}/edit`}>Редактировать</Button>
                </Card>
                <div className="row">
                  <div className="col-12">
                    {kind === "entity" && (
                      <Fragment>
                        <table className="table">
                          <thead>
                            <tr>
                              <th colSpan="2">Поставщик</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ width: "50%" }}>Название</td>
                              <td style={{ width: "50%" }}>
                                {organization_name} {kind != "individual" && ownership_kind.label}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>ИНН</td>
                              <td style={{ width: "50%" }}>{inn}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Вид услуги</td>
                              <td style={{ width: "50%" }}>{activity_kind.name}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Холдинг / Группа компаний</td>
                              <td style={{ width: "50%" }}>
                                {" "}
                                <Link to={`/holdings/${holding.id}`}>{holding.name}</Link>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Фактический адрес</td>
                              <td style={{ width: "50%" }}>{address}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Комментарий</td>
                              <td style={{ width: "50%" }} dangerouslySetInnerHTML={{ __html: comment }} />
                            </tr>
                          </tbody>
                        </table>
                        <table className="table">
                          <thead>
                            <tr>
                              <th colSpan="2">Дополнительные сведения</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ width: "50%" }}>Отрасль</td>
                              <td style={{ width: "50%" }}>{industry && industry.name}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Тип оплаты</td>
                              <td style={{ width: "50%" }}>{payment_kind.label}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Создал</td>
                              <td style={{ width: "50%" }}>{[user.name, subdivision.short_name].join(", ")}</td>
                            </tr>
                            <tr>
                              <td style={{ width: "50%" }}>Дата создания</td>
                              <td style={{ width: "50%" }}>{formatDateTime(created_at)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Fragment>
                    )}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="bank" title="Банковские реквизиты">
                <br />
                <Card>
                  <Button url={`/providers/${id}/edit`}>Редактировать</Button>
                </Card>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2">Поставщик</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>Полное наименование</td>
                      <td style={{ width: "50%" }}>{this.providerStore.data.full_name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>ФИО генерального директора</td>
                      <td style={{ width: "50%" }}>{this.providerStore.data.director}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>ИНН</td>
                      <td style={{ width: "50%" }}>{this.providerStore.data.inn}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Вид услуги</td>
                      <td style={{ width: "50%" }}>{this.providerStore.data.activity_kind.name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>ОГРН</td>
                      <td style={{ width: "50%" }}>{this.providerStore.data.ogrn}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Юридический адрес</td>
                      <td style={{ width: "50%" }}>{this.providerStore.data.legal_address}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Банк</td>
                      <td style={{ width: "50%" }}>{this.providerStore.data.bank}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Расчетный счет</td>
                      <td style={{ width: "50%" }}>{this.providerStore.data.payment_account}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>БИК</td>
                      <td style={{ width: "50%" }}>{this.providerStore.data.bik}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "50%" }}>Корр.счет</td>
                      <td style={{ width: "50%" }}>{this.providerStore.data.correspondent_account}</td>
                    </tr>
                  </tbody>
                </table>
              </Tab>
            </Tabs>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default observer(Show)
