import React from "react"
import { Button, EmailInput } from "components/UI"

const Email = ({ name, index, email, h: { replace, remove } }) => {
  if (email._destroy) return null
  const onDestroyClick = () => (email.id ? replace(index, { ...email, _destroy: true }) : remove(index))
  return (
    <div key={`emails.${index}`}>
      <EmailInput name={`${name}.${index}.value`} label="Email" />
      <Button variant="danger" onClick={onDestroyClick}>
        Удалить email
      </Button>
      <hr />
    </div>
  )
}
export default Email
