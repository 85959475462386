import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import OrderSubjectStore from "stores/OrderSubjectStore/One"
import Breadcrumbs from "pages/OrderSubjects/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"

class Show extends Component {
  order_subjectStore = OrderSubjectStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.order_subjectStore.fetch({ id })
  }

  render() {
    const {
      order_subjectStore: {
        data: { id, name, short_name, comment }
      }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumbs>
        <Card>
          <Button url={`/order_subjects/${id}/edit`}>Редактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Наименование темы приказа</td>
                  <td style={{ width: "50%" }}>{name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Индекс приказа</td>
                  <td style={{ width: "50%" }}>{short_name}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Комментарий</td>
                  <td style={{ width: "50%" }}>{comment}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
