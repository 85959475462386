import React, { Fragment, useEffect, useState } from "react"
import compose from "utils/compose"
import { AsyncSelectInput, DateInput, TextInput, ResetButton, Submit, Button, SelectInput } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import ChannelStore from "stores/ChannelStore/List"
import ClientSelect from "components/Common/ClientSelect"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import InsuranceKindSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsuranceKindSelect"
import classnames from "classnames"

const channelStore = ChannelStore.create()

const Filter = ({ store, selectedRows, totalSelectedRow, values, handleSubmit, history }) => {
  const [isShowAdditional, setIsShowAdditional] = useState(false)
  const toggleAddtional = () => setIsShowAdditional(!isShowAdditional)
  useEffect(() => {
    channelStore.fetch({ order_by_ordered: "asc" })
  }, [])

  const channelOptions = (() => {
    if (values.by_channel) {
      const { id, name } = values.by_channel
      const options = channelStore.channelOptions
      if (id) {
        options.concat([{ label: name, value: id }])
      }
      return options
    }
    return channelStore.channelOptions
  })()

  const loadChannelOptions = (value) =>
    channelStore
      .fetch({ filter: { by_name: value, order_by_ordered: "asc" }, limit: 0 })
      .then(({ channelOptions }) => channelOptions)

  const createAvr = () => {
    // console.log(
    //   qs.stringify({
    //     id: Object.values(selectedRows)
    //       .flat()
    //       .map((i) => i.id)
    //   })
    // )
    history.push(
      `/avrs/new?channel_id=${values.by_channel.id}&insurer_id=${values.by_insurer.id}&${Object.values(selectedRows)
        .flat()
        .map((i) => `kv_cost_ids[]=${i.debitable_id}`)
        .join("&")}`
    )
  }

  const [disabled, setDisabled] = useState(false)

  const onExportButtonHandler = () => {
    setDisabled(true)
    store.export({ filter: values }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "debits.xlsx")
      document.body.appendChild(link)
      link.click()
      setDisabled(false)
    })
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <InsurerSelect name="by_insurer" />
        </div>
        <div className="col-3">
          <AsyncSelectInput
            name="by_channel.id"
            label="Канал продаж"
            options={channelOptions}
            isSearchable
            loadOptions={loadChannelOptions}
          />
        </div>
        <div className="col-3">
          <DateInput name="by_payment_on_start" label="Дата оплаты СП с" />
        </div>
        <div className="col-3">
          <DateInput name="by_payment_on_finish" label="Дата оплаты СП по" />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_avr_status"
            label="Статус включения в АВР"
            isClearable
            options={[
              { label: "Не включен", value: "0" },
              { label: "Включен", value: "1" }
            ]}
          />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_contract_sending_status"
            label="Статус сдачи договора"
            isClearable
            options={[
              { label: "Не сдан", value: "0" },
              { label: "Сдан", value: "1" }
            ]}
          />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_sending_deadline_avr"
            label="Статус срока выставления АВР"
            isClearable
            options={[
              { label: "Просрочен", value: "0" },
              { label: "В плане", value: "1" }
            ]}
          />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_payment_deadline_avr"
            label="Статус срока оплаты АВР"
            isClearable
            options={[
              { label: "Просрочен", value: "0" },
              { label: "В плане", value: "1" }
            ]}
          />
        </div>

        {isShowAdditional && (
          <Fragment>
            <div className="col-3">
              <InsuranceKindSelect name="by_insurance_kind" />
            </div>
            <div className="col-3">
              <ClientSelect name="by_client.id" />
            </div>
            <div className="col-3">
              <TextInput name="by_number" label="№ договора" />
            </div>
            <div className="col-3">
              <SubdivisionSelect name="by_subdivision.id" />
            </div>
          </Fragment>
        )}
      </div>
      <Button onClick={toggleAddtional} title="Дополнительные фильтры">
        <i className={classnames(["fas", { "fa-angle-up": isShowAdditional, "fa-angle-down": !isShowAdditional }])}></i>
      </Button>
      &nbsp;
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      &nbsp;
      <Button onClick={onExportButtonHandler} disabled={disabled}>
        {disabled && (
          <Fragment>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
          </Fragment>
        )}
        Экспорт в .xlsx
      </Button>
      &nbsp;
      {totalSelectedRow > 0 && values.by_channel?.id && values.by_insurer?.id && (
        <Button onClick={createAvr}>Сформировать АВР</Button>
      )}
      &nbsp;
      {store.meta.statistics.map((i) => (
        <Fragment>
          &nbsp;&nbsp;&nbsp;&nbsp;{i[0]}: <b>{i[1]}</b>
        </Fragment>
      ))}
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
