const prepare = (params = {}) => {
  const {
    currency: { value: currency },
    cargo_prefabricated: { id: cargo_prefabricated_id },
    cargo_combined: { id: cargo_combined_id },
    transport_kinds,
    cargos,
    ...other
  } = params

  return {
    ...other,
    cargos: cargos.map((i) => {
      const {
        currency: { value: currency },
        ...other
      } = i
      return {
        ...other,
        currency: parseInt(currency)
      }
    }),
    transport_kind_ids: transport_kinds.filter((i) => !i._destroy).map((i) => i.id),
    currency: parseInt(currency),
    cargo_prefabricated_id,
    cargo_combined_id
  }
}

export { prepare }
