import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { DateInput, TextInput, CurrencyInput } from "components/UI"
import PositionSelect from "components/Common/PositionSelect"
import SubdivisionSelect from "components/Common/SubdivisionSelect"

const ChangePositionTab = ({ store, handleSubmit, values, setFieldValue, ...props }) => {
  return (
    <Fragment>
      <br />
      <DateInput name="order_change_position.date_on" label="Дата перевода на другую должность" required />
      <TextInput name="order_change_position.kind" label="Вид перевода" required />
      <SubdivisionSelect name="order_change_position.subdivision.id" label="Новое подразделение" required />
      <PositionSelect name="order_change_position.position.id" label="Новая должность" required />
      <CurrencyInput name="order_change_position.salary" label="Новый оклад" required />
      <TextInput name="order_change_position.reason_document" label="Основание (документ)" required />
      <TextInput name="order_change_position.comment" label="Комментарий" />
    </Fragment>
  )
}

export default observer(ChangePositionTab)
