import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import AvrStore from "stores/AvrStore/One"
import InsurerStore from "stores/InsurerStore/List"
import ChannelStore from "stores/ChannelStore/List"
import Breadcrumbs from "pages/Avrs/components/Breadcrumbs"
import Form from "pages/Avrs/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"

class Edit extends Component {
  avrStore = AvrStore.create()
  insurerStore = InsurerStore.create()
  channelStore = ChannelStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.avrStore.fetch({ id })
    this.insurerStore.fetch({order_by_ordered: "asc"})
    this.channelStore.fetch()
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .update(values)
      .then(() => history.push("/avrs", { referer: window.location.pathname }))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { avrStore: store, handleSubmit, insurerStore, channelStore } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem url={`/avrs/${store.id}`}>{store.id}</BreadcrumbItem>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <ContractFormContext.Provider
          value={{
            avrStore: store
          }}
        >
          <Form {...{ store, handleSubmit, insurerStore, channelStore }} />
        </ContractFormContext.Provider>
      </Fragment>
    )
  }
}

export default observer(Edit)
