import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"

const Contacts = () => (
  <Switch>
    <Route path="/subagents/:subagent_id/contacts/:id/edit" component={Edit} />
    <Route path="/subagents/:subagent_id/contacts/new" component={Add} />
    <Route path="/subagents/:subagent_id/contacts/:id" component={Show} />
    <Route path="/subagents/:subagent_id/contacts" component={List} />
  </Switch>
)

export default Contacts