import React from "react"
import formatDate from "utils/formatDate"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import { observer } from "mobx-react"

const UpdatingPopover = React.forwardRef(({ popper, children, show: _, ...props }, ref) => (
  <Popover ref={ref} {...props}>
    <Popover.Content>{children}</Popover.Content>
  </Popover>
))

const Checkbox = ({ parcel, page, isCheckedRow, onCheckedRow }) => {
  if (parcel.avr?.id) {
    return (
      <OverlayTrigger
        overlay={
          <UpdatingPopover id="popover-contained">
            Данный платеж уже включен в другой АВР (№ {parcel.avr.id})
          </UpdatingPopover>
        }
      >
        <span className="d-inline-block">
          <input
            type="checkbox"
            checked={isCheckedRow(parcel, page)}
            onChange={(e) => onCheckedRow(e, page)}
            disabled={true}
          />
        </span>
      </OverlayTrigger>
    )
  } else {
    return <input type="checkbox" checked={isCheckedRow(parcel, page)} onChange={(e) => onCheckedRow(parcel, page)} />
  }
}

const Table = ({
  store,
  selectedRows,
  totalSelectedRow,
  isCheckedRow,
  onCheckedRow,
  isCheckedAllRows,
  onCheckedAllRows,
  resetSelected
}) => {
  const { page } = store.meta.pagination
  const balance = Object.values(selectedRows)
    .flat()
    .map((i) => parseMoney(i.cost))
    .reduce((a, b) => a + b, 0)

  const balanceSp = Object.values(selectedRows)
    .flat()
    .map((i) => parseMoney(i.sp))
    .reduce((a, b) => a + b, 0)
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllRows(page)} onChange={(e) => onCheckedAllRows(e, page)} />
          </th>
          <th scope="col">№ п/п</th>
          <th scope="col">Дата отправки</th>
          <th scope="col">№ накладной / заказа</th>
          <th scope="col">Адрес отправителя</th>
          <th scope="col">Адрес получателя</th>
          <th scope="col">Страховая сумма</th>
          <th scope="col">Страховая премия</th>
          <th scope="col">Комментарии</th>
        </tr>
      </thead>
      <tbody>
        {store.data.map((parcel, index) => (
          <tr key={`parcel-${index}`}>
            <td>
              <Checkbox parcel={parcel} page={page} isCheckedRow={isCheckedRow} onCheckedRow={onCheckedRow} />
            </td>
            <td>{parcel.id}</td>
            <td>{formatDate(parcel.departure_on)}</td>
            <td>{parcel.number}</td>
            <td>{parcel.sender_address}</td>
            <td>{parcel.recipient_address}</td>
            <td>{parcel.cost}</td>
            <td>{parcel.sp}</td>
            <td>{parcel.comment}</td>
          </tr>
        ))}
      </tbody>
      <tfoot className="thead-light">
        <tr>
          <th colspan="6"></th>
          <th>{formatMoney(balance)}</th>
          <th colspan="2">{formatMoney(balanceSp)}</th>
        </tr>
      </tfoot>
    </table>
  )
}

export default observer(Table)
