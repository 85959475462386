import { getIn } from "formik"

const prepare = (params = {}) => {
  const {
    kind: { value: kind },
    form: { value: form },
    first_level_bill,
    second_level_bill,
    orders,
    name,
    ...other
  } = params

  const first_level_bill_id = getIn(first_level_bill, "id", null)
  const second_level_bill_id = getIn(second_level_bill, "id", null)
  return { ...other, kind: parseInt(kind), form: parseInt(form), first_level_bill_id, second_level_bill_id }
}

export { prepare }
