import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import ProviderStore from "stores/ProviderStore/One"
import ContactStore from "stores/ContactStore/One"
import InsuranceKindStore from "stores/InsuranceKindStore/List"
import Breadcrumbs from "pages/Providers/containers/Contacts/components/Breadcrumbs"
import Form from "pages/Providers/containers/Contacts/components/Form"
import { BreadcrumbItem } from "components/UI"
import ContactFormContext from "contexts/ContactFormContext"

class Edit extends Component {
  providerStore = ProviderStore.create()
  contactStore = ContactStore.create()
  insuranceKindStore = InsuranceKindStore.create()

  componentDidMount() {
    const { id, provider_id } = this.props.match.params
    this.providerStore.fetch({ id: provider_id })
    this.contactStore.fetch({ id, provider_id })
    this.insuranceKindStore.fetch()
  }

  handleSubmit = (values, { props: { history, store }, setErrors }) => {
    const { id: provider_id } = this.providerStore.data
    return store
      .update({ ...values, provider_id })
      .then(() => history.push(`/providers/${provider_id}/contacts`))
      .catch(({ response }) => setErrors(response.data.errors))
  }

  render() {
    const {
      providerStore: { data: provider },
      contactStore: store,
      handleSubmit,
      insuranceKindStore
    } = this
    return (
      <Fragment>
        <Breadcrumbs {...{ provider }}>
          <BreadcrumbItem active>Редактировать</BreadcrumbItem>
        </Breadcrumbs>
        <ContactFormContext.Provider value={{ insuranceKindStore }}>
          <Form {...{ store, handleSubmit }} />
        </ContactFormContext.Provider>
      </Fragment>
    )
  }
}

export default observer(Edit)
