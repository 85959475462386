import React from "react"
import { observer } from "mobx-react"
import { Breadcrumb, BreadcrumbItem } from "components/UI"

const Breadcrumbs = ({ subagent: { id, name }, children }) => (
  <Breadcrumb>
    <BreadcrumbItem active={!children} url="/subagents">
      Субагенты
    </BreadcrumbItem>
    <BreadcrumbItem url={`/subagents/${id}`}>{name}</BreadcrumbItem>
    <BreadcrumbItem url={`/subagents/${id}/contacts`}>Контактные лица</BreadcrumbItem>
    {children}
  </Breadcrumb>
)

export default observer(Breadcrumbs)
