import parseMoney from "utils/parseMoney"

const prepare = (params = {}) => {
  const {
    kind: { value: kind },
    status: { value: status },
    subdivision: { id: subdivision_id },
    budget_items,
    forecast,
    coefficient_mrot,
    creator,
    parent: { id: parent_id },
    child,
    subdivisions,
    integrated,

    ...other
  } = params

  return {
    ...other,
    kind: parseInt(kind),
    status: parseInt(status),
    subdivision_id: parseInt(subdivision_id),
    subdivision_ids: subdivisions.map(i => i.id),
    forecast: parseMoney(forecast),
    coefficient_mrot: parseMoney(coefficient_mrot),
    parent_id,
    budget_items: budget_items.map((i) => {
      return { ...i, cost: parseMoney(i.cost) }
    })
  }
}

export { prepare }
