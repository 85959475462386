import React, { useContext, useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import UserStore from "stores/UserStore/List"
import AuthStoreContext from "contexts/AuthStoreContext"

const userStore = UserStore.create()

const UserSelect = ({ name = "user", required = false, label = "Пользователь", setCurrentUser = false, ...props }) => {
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    userStore.search({ filter: { order_by_name: "asc" } })
  }, [])

  const { data: currentUser } = useContext(AuthStoreContext)
  if (setCurrentUser) {
    const user = values[name.replace(/\.id$/, "")]
    if (user.id === null) setFieldValue(name.replace(/\.id$/, ""), { id: currentUser.id, name: currentUser.name })
  }

  const getOptions = () => {
    const { userOptions } = userStore
    const { id: value, name: label } = getIn(values, name.replace(/\.id$/, "")) || { id: null, name: null }
    if (value && !userOptions.find((i) => i.value === value)) {
      return userOptions.concat([{ label, value }])
    }
    return userOptions
  }

  const userOptions = getOptions()

  const loadUserOptions = (value) =>
    userStore
      .search({ filter: { by_name: value, order_by_name: "asc" }, limit: 0 })
      .then(({ userOptions }) => userOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        required={required}
        options={userOptions}
        isSearchable
        loadOptions={loadUserOptions}
        {...props}
      />
    </div>
  )
}

export default observer(UserSelect)
