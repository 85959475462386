import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import AvrStore from "stores/AvrStore/List"
import Breadcrumbs from "pages/Avrs/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"
import Filter from "./components/Filter"
import useSelectAll from "hooks/useSelectAll"

const avrStore = AvrStore.create()

const List = (props) => {
  useEffect(() => {
    const { state } = props.history.location
    // if (state && state.referer && /\/avrs\/\d+\/edit/i.test(state.referer)) {
    //   historyPushFromStore(props.history, window.location.pathname)
    // }
    historyPushFromStore(props.history, window.location.pathname)

    avrStore.fetch(getParams())
  }, [])

  const [
    selectedRows,
    totalSelectedRow,
    isCheckedRow,
    onCheckedRow,
    isCheckedAllRows,
    onCheckedAllRows,
    resetSelected
  ] = useSelectAll(avrStore.data.toJSON(), false)

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/avrs/new">Создать</Button>
      </Card>
      <Card>
        <Filter store={avrStore} />
      </Card>
      <Table
        store={avrStore}
        selectedRows={selectedRows}
        totalSelectedRow={totalSelectedRow}
        isCheckedRow={isCheckedRow}
        onCheckedRow={onCheckedRow}
        isCheckedAllRows={isCheckedAllRows}
        onCheckedAllRows={onCheckedAllRows}
        resetSelected={resetSelected}
      />
      <Paginate store={avrStore} />
    </Fragment>
  )
}

export default observer(List)
