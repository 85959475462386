const prepare = (params = {}) => {
  console.log(params)
  const {
    status: { value: status },
    kind: { value: kind },
    form: { value: form },
    operation_kind: { value: operation_kind },
    cash_kind: { value: cash_kind },
    moving_kind: { value: moving_kind },
    prepayment: { value: prepayment },
    subdivision: { id: subdivision_id },
    bill: { id: bill_id },
    user: { id: user_id },
    cashier_from: { id: cashier_from_id },
    cashier_to: { id: cashier_to_id },
    channel: { id: channel_id },
    relation_contract: { id: relation_contract_id },
    counterpart: { id: counterpart_id },
    project: { id: project_id },
    avrs,
    contracts,
    operation_scans,
    balance_bill,
    from_bill,
    to_bill,
    creator,
    updated_at,
    payment_plan_id,
    currency: { value: currency },
    ...other
  } = params

  return {
    ...other,
    status: parseInt(status),
    kind: parseInt(kind),
    form: parseInt(form),
    operation_kind: parseInt(operation_kind),
    cash_kind: parseInt(cash_kind),
    moving_kind: parseInt(moving_kind),
    prepayment: parseInt(prepayment),
    subdivision_id,
    bill_id,
    user_id,
    channel_id,
    relation_contract_id,
    counterpart_id,
    project_id,
    cashier_from_id,
    cashier_to_id,
    avr_ids: avrs.map(i => i.id),
    contract_ids: contracts.map(i => i.id),
    currency: parseInt(currency),
    operation_scans: operation_scans.map((i) => {
      const {
        kind: { value: kind }
      } = i
      return {
        ...i,
        kind: parseInt(kind)
      }
    })
  }
}

export { prepare }
