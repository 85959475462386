import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import BsoKindStore from "stores/BsoKindStore/List"

const bsoKindStore = BsoKindStore.create()

const BsoKindSelect = ({ name = "bso_kind", required = false, label = "Тип БСО", insurer = {}, ...props }) => {
  const { values } = useFormikContext()
  const bso_kind = getIn(values, name, {}) || {}

  useEffect(() => {
    bsoKindStore.fetch({ filter: { by_insurer_id: insurer.id } })
  }, [insurer])

  const bsoKindOptions = bso_kind.id ? [{ label: bso_kind.name, value: bso_kind.id }] : bsoKindStore.bsoKindOptions

  const loadbsoKindOptions = (inputValue) =>
    bsoKindStore.fetch({ filter: { by_name: inputValue }, limit: 0 }).then(({ bsoKindOptions }) => bsoKindOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label={label}
        required={required}
        options={bsoKindOptions}
        required={required}
        isSearchable
        loadOptions={loadbsoKindOptions}
        isDisabled={!insurer.id}
        {...props}
      />
    </div>
  )
}

export default observer(BsoKindSelect)
