import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"
import Contact from "./Contact"

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Client = types.model("Client", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Kv = types.model("Kv", {
  id: types.maybeNull(types.integer),
  rate: types.maybeNull(types.string),
  by_contract: types.maybeNull(types.string),
  by_paid_sp: types.maybeNull(types.string),
  channel: types.maybeNull(Channel)
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string),
  kvs: types.maybeNull(types.array(Kv)),
  conclusion_on: types.maybeNull(customDate),
  client: types.maybeNull(Client),
  insurance_kind: types.maybeNull(InsuranceKind),
  insurer: types.maybeNull(Insurer),
  fix_kv_channel: types.maybeNull(Channel)
})

const ContractInstallment = types.model("ContractInstallment", {
  id: types.maybeNull(types.integer),
  contract: types.maybeNull(Contract),
  sp_payment_cost: types.maybeNull(types.string),
  sp_payment_on: types.maybeNull(customDate),
  sp_payment_kind: types.maybeNull(newCustomEnum),
  sp_sk_payment_on: types.maybeNull(customDate),
  sp_sk_payment_cost: types.maybeNull(types.string),
  sp_sk_payment_form: types.maybeNull(newCustomEnum),
  sp_sinergy_payment_on: types.maybeNull(customDate),
  sp_sinergy_payment_cost: types.maybeNull(types.string),
  sp_sinergy_payment_form: types.maybeNull(newCustomEnum),
  contract_payment_status: types.maybeNull(newCustomEnum),
  payment_cost: types.maybeNull(types.string),
  payment_on: types.maybeNull(customDate),
  kv_by_paid_sp: types.maybeNull(types.string),
  fix_kv_cost: types.maybeNull(types.string)
})

const ContractReturnment = types.model("ContractReturnment", {
  id: types.maybeNull(types.integer),
  contract: types.maybeNull(Contract),
  sp_payment_cost: types.maybeNull(types.string),
  sp_payment_on: types.maybeNull(customDate),
  sp_payment_kind: types.maybeNull(newCustomEnum),
  sp_sk_payment_on: types.maybeNull(customDate),
  sp_sk_payment_cost: types.maybeNull(types.string),
  sp_sk_payment_form: types.maybeNull(newCustomEnum),
  sp_sinergy_payment_on: types.maybeNull(customDate),
  sp_sinergy_payment_cost: types.maybeNull(types.string),
  sp_sinergy_payment_form: types.maybeNull(newCustomEnum),
  contract_payment_status: types.maybeNull(newCustomEnum),
  payment_cost: types.maybeNull(types.string),
  payment_on: types.maybeNull(customDate),
  kv_by_paid_sp: types.maybeNull(types.string),
  returnment: types.maybeNull(types.boolean)
})

const AvrInstallment = types.model("AvrInstallment", {
  id: types.maybeNull(types.integer),
  control_payment_on: types.maybeNull(customDate),
  payment_on: types.maybeNull(customDate),
  payment_kind: types.maybeNull(newCustomEnum),
  payment_cost: types.maybeNull(types.string)
})

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const AvrDocument = types.model("AvrDocument", {
  id: types.maybeNull(types.integer),
  attachments: types.maybeNull(types.array(Attachment)),
  document_kind: types.maybeNull(DocumentKind),
  document_kind_other: types.maybeNull(types.string)
})

const RelationAvr = types.model("RelationAvr", {
  id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string)
})

const AvrRelation = types.model("AvrRelation", {
  id: types.maybeNull(types.integer),
  comment: types.maybeNull(types.string),
  relation_avr: types.maybeNull(RelationAvr)
})

const Operation = types.model("Operation", {
  id: types.maybeNull(types.integer)
})

const Avr = types.model("Avr", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  status: types.maybeNull(newCustomEnum),
  number: types.maybeNull(types.string),
  date_on: types.maybeNull(customDate),
  insurer: types.maybeNull(Insurer),
  insurer_contact: types.maybeNull(Contact),
  start_on: types.maybeNull(customDate),
  finish_on: types.maybeNull(customDate),
  channel: types.maybeNull(Channel),
  kv_by_hold: types.maybeNull(newCustomEnum),
  former: types.maybeNull(newCustomEnum),
  former_on: types.maybeNull(customDate),
  transfer_on: types.maybeNull(customDate),
  payment_on: types.maybeNull(customDate),
  original_receipt_on: types.maybeNull(customDate),
  actual_payment_on: types.maybeNull(customDate),
  total_sp_paid: types.maybeNull(types.string),
  total_commission_by_paid_sp: types.maybeNull(types.string),
  contract_installments: types.array(ContractInstallment),
  contract_returnments: types.array(ContractReturnment),
  created_at: types.maybeNull(customDate),
  payment_status: types.maybeNull(newCustomEnum),
  original_status: types.maybeNull(newCustomEnum),
  kv_by_paid_sp: types.maybeNull(types.string),
  user: types.maybeNull(User),
  comment: types.maybeNull(types.string),
  payment_kind: types.maybeNull(newCustomEnum),
  avr_installments: types.array(AvrInstallment),
  documents: types.maybeNull(types.array(AvrDocument)),
  avr_relations: types.array(AvrRelation),
  relation_avrs: types.array(AvrRelation),
  signed_on: types.maybeNull(customDate),
  kv_sum: types.maybeNull(types.string),
  kv_costs: types.array(types.frozen()),
  kv_cost_returnments: types.array(types.frozen()),
  operations: types.maybeNull(types.array(Operation))
  // fix_kv_costs: types.array(types.frozen()),
})

export { Avr }
