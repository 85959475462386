import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import DebitStore from "stores/DebitStore/List"
import Breadcrumbs from "pages/Debits/components/Breadcrumbs"
import { Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"
import Filter from "./components/Filter"
import useSelectAll from "hooks/useSelectAll"

const debitStore = DebitStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    debitStore.fetch(getParams())
  }, [])

  const [
    selectedRows,
    totalSelectedRow,
    isCheckedRow,
    onCheckedRow,
    isCheckedAllRows,
    onCheckedAllRows,
    resetSelected
  ] = useSelectAll(debitStore.data.toJSON(), false)

  console.log(debitStore.isFetched)

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Filter store={debitStore} selectedRows={selectedRows} totalSelectedRow={totalSelectedRow} />
      </Card>
      <Table
        store={debitStore}
        selectedRows={selectedRows}
        totalSelectedRow={totalSelectedRow}
        isCheckedRow={isCheckedRow}
        onCheckedRow={onCheckedRow}
        isCheckedAllRows={isCheckedAllRows}
        onCheckedAllRows={onCheckedAllRows}
        resetSelected={resetSelected}
      />
      <Paginate store={debitStore} />
      <table className="table" style={{ width: "500px" }}>
        <tr>
          <th className="table-danger">Платеж не включен в АВР</th>
        </tr>
        <tr>
          <th className="table-warning">Платеж включен в АВР, статус Черновик/Согласован</th>
        </tr>
        <tr>
          <th className="table-success">Платеж включен в АВР, статус Выпущен</th>
        </tr>
      </table>
    </Fragment>
  )
}

export default observer(List)
