import React from "react"
import { Tab, Tabs } from "components/UI"

const TabsBlock = () => {
  const mapping = {
    [`/contract_tasks`]: "Задачи",
    [`/contract_tasks/calendar`]: "Календарь"
  }
  return (
    <Tabs>
      {Object.entries(mapping).map((i) => {
        const active = window.location.pathname === i[0]
        return (
          <Tab key={i[0]} active={active} to={i[0]}>
            {i[1]}
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default TabsBlock
