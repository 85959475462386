import { types } from "mobx-state-tree"
import Channel from "./Channel"

const FilterOptions = types.model("FilterOptions", {
  channels: types.array(Channel, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

export default Meta
