import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import DebitKpiStore from "stores/DebitKpiStore/One"
import Breadcrumbs from "pages/DebitKpis/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"

class Show extends Component {
  debitKpiStore = DebitKpiStore.create()

  componentDidMount() {
    const { id } = this.props.match.params
    this.debitKpiStore.fetch({ id })
  }

  render() {
    const {
      debitKpiStore: {
        data: { id, average_level, turnover_rate, delay_period, exposure_period }
      }
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>{id}</BreadcrumbItem>
        </Breadcrumbs>
        <Card>
          <Button url={`/debit_kpis/${id}/edit`}>Редактировать</Button>
        </Card>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Средний уровень дебиторки (рубли)</td>
                  <td style={{ width: "50%" }}>{average_level}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Скорость оборачиваемости дебиторки (дни)</td>
                  <td style={{ width: "50%" }}>{turnover_rate}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Скорость оплаты АВР</td>
                  <td style={{ width: "50%" }}>{delay_period}</td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Срок выставления АВР после оплаты СП (дни)</td>
                  <td style={{ width: "50%" }}>{exposure_period}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default observer(Show)
