import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Plan from "./containers/Plan"

const BudgetBudgetNors = () => (
  <Switch>
    <Route path="/budgets/:budget_id/budget_nors/plan" component={Plan} />
    <Route path="/budgets/:budget_id/budget_nors/:id/edit" component={Edit} />
    <Route path="/budgets/:budget_id/budget_nors/new" component={Add} />
    <Route path="/budgets/:budget_id/budget_nors/:id" component={Show} />
    <Route path="/budgets/:budget_id/budget_nors" component={List} />
  </Switch>
)

export default BudgetBudgetNors