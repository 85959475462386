import React, { Component } from "react"
import { observer } from "mobx-react"
import AuthStore from "stores/AuthStore"
import Form from "pages/Auth/components/Form"

class Login extends Component {
  authStore = AuthStore.create()

  handleSubmit = (values, { props: { store }, setErrors }) =>
    store
      .login(values)
      .then(() => window.location.href = "/")
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { authStore: store, handleSubmit } = this
    return (
      <div className="row justify-content-center">
        <div className="col-lg-3">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-5">
                    <Form {...{ store, handleSubmit }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default observer(Login)
