import React, { Component, Fragment } from "react"
import afterDestroyFetch from "utils/afterDestroyFetch"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import ClientStore from "stores/ClientStore/One"
import ContactStore from "stores/ContactStore/List"
import Breadcrumbs from "pages/Clients/components/Breadcrumbs"
import Tabs from "pages/Clients/components/Tabs"
import { Button, Card, Paginate } from "components/UI"
import { BreadcrumbItem } from "components/UI"
import Table from "./components/Table"

class List extends Component {
  clientStore = ClientStore.create()
  contactStore = ContactStore.create()

  componentDidMount() {
    const { client_id } = this.props.match.params
    this.clientStore.fetch({ id: client_id })
    this.contactStore.fetch({ ...getParams(), client_id })
  }

  onDestroyClick = (id) => {
    const { id: client_id } = this.clientStore
    afterDestroyFetch(this.contactStore, { id, client_id }, { client_id })
  }

  onPageChange = (params) => {
    const { id: client_id } = this.clientStore
    this.contactStore.fetch({ ...params, client_id })
  }

  render() {
    const {
      props: {
        match: {
          params: { client_id }
        }
      },
      onDestroyClick,
      clientStore,
      contactStore
    } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem url={`/clients/${clientStore.id}`}>{clientStore.name}</BreadcrumbItem>
          <BreadcrumbItem active>Контактные лица</BreadcrumbItem>
        </Breadcrumbs>
        <Tabs store={clientStore} />
        <br />
        <Card>
          <Button url={`/clients/${client_id}/contacts/new`}>Создать</Button>
        </Card>
        <Table {...{ data: contactStore.data.toJSON(), client_id, onDestroyClick }} />
        <Paginate store={contactStore} />
      </Fragment>
    )
  }
}

export default observer(List)
