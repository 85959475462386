import React, { Fragment } from "react"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import {
  AddressInput,
  Card,
  CardTitle,
  InnInput,
  RadioButtonGroup,
  RadioInput,
  OgrnInput,
  SelectInput,
  Submit,
  TextArea,
  TextInput,
  KppInput,
  PaymentAccountInput,
  BikInput,
  CorrespondentAccountInput
} from "components/UI"

const InfoTab = ({ store, handleSubmit, values, ...p }) => {
  return (
    <Tabs defaultActiveKey="info">
      <Tab eventKey="info" title="Общие сведения">
        <br />
        <RadioButtonGroup label="Тип" name="kind">
          <RadioInput label="Физическое лицо" id="individual" />
          <RadioInput label="Юридическое лицо" id="entity" />
        </RadioButtonGroup>
        {values.kind === "individual" && (
          <React.Fragment>
            <TextInput name="l_name" label="Фамилия" required />
            <TextInput name="f_name" label="Имя" required />
            <TextInput name="p_name" label="Отчество" />
            <TextInput name="position" label="Должность" />
            <TextArea name="comment" label="Комментарий" />
          </React.Fragment>
        )}
        {values.kind === "entity" && (
          <Fragment>
            <TextInput name="organization_name" label="Наименование" />
            <RadioButtonGroup label="Форма собственности" name="ownership_kind">
              <RadioInput label="ООО" id="ooo" />
              <RadioInput label="ИП" id="ip" />
              <RadioInput label="АО" id="ao" />
              <RadioInput label="ПАО" id="pao" />
              <RadioInput label="НКО" id="nko" />
              <RadioInput label="НПО" id="npo" />
              <RadioInput label="ЗАО" id="zao" />
              <RadioInput label="СПАО" id="spao" />
            </RadioButtonGroup>
            <InnInput name="inn" label="ИНН" />
            <AddressInput name="address" label="Фактический адрес" />
            <TextInput name="kind_of_action" label="Вид деятельности" required />
            <TextArea name="comment" label="Комментарий" />
          </Fragment>
        )}
      </Tab>
      {values.kind === "entity" && (
        <Tab eventKey="requisites" title="Банковские реквизиты">
          <br />
          <TextInput name="name" label="Полное наименование" />
          <TextInput name="director" label="ФИО генерального директора" />
          <KppInput name="kpp" label="КПП" />
          <OgrnInput name="ogrn" label="ОГРН" />
          <TextInput name="legal_address" label="Юридический адрес" />
          <TextInput name="bank" label="Банк" />
          <PaymentAccountInput name="payment_account" label="Расчетный счет" />
          <BikInput name="bik" label="БИК" />
          <CorrespondentAccountInput name="correspondent_account" label="Корр.счет" />
        </Tab>
      )}
    </Tabs>
  )
}

export default InfoTab
