import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { Submit, CurrencyInput, TextInput } from "components/UI"

const Form = ({ handleSubmit, values, setFieldValue }) => (
  <form onSubmit={handleSubmit}>
    <CurrencyInput name="average_level" label="Средний уровень дебиторки (рубли)" disabled />
    <TextInput name="turnover_rate" label="Скорость оборачиваемости дебиторки (дни)" disabled />
    <TextInput
      name="delay_period"
      label="Скорость оплаты АВР"
      onChange={(e) => {
        setFieldValue("delay_period", e.target.value)
        setFieldValue("turnover_rate", parseInt(e.target.value) + parseInt(values.exposure_period))
      }}
    />
    <TextInput
      name="exposure_period"
      label="Срок выставления АВР после оплаты СП (дни)"
      onChange={(e) => {
        setFieldValue("exposure_period", e.target.value)
        setFieldValue("turnover_rate", parseInt(e.target.value) + parseInt(values.delay_period))
      }}
    />
    <Submit>Сохранить</Submit>
  </form>
)

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  })
)(Form)
