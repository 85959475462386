import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import OrderStore from "stores/OrderStore/One"
import Breadcrumbs from "pages/Orders/components/Breadcrumbs"
import Form from "pages/Orders/components/Form"
import { BreadcrumbItem } from "components/UI"

const Add = () => {
  const orderStore = OrderStore.create()

  useEffect(() => {
    orderStore.fetchMeta()
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={orderStore} />
    </Fragment>
  )
}

export default observer(Add)
