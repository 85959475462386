import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { FieldArray } from "formik"
import { Card, TextInput } from "components/UI"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import ContractReturnments from "./ContractReturnments"
import qs from "qs"

const SpTab = ({ store, values, setFieldValue, ...props }) => {
  const { spPaidInflectorOptions } = store.filtersOptions

  const calculateCommissionCost = ({ sp_accrual_cost, kvs }) => {
    sp_accrual_cost = parseMoney(sp_accrual_cost)
    const rate = parseMoney(kvs[0].rate)

    return (sp_accrual_cost * (rate * 0.01)).toFixed(2)
  }

  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const additional = params.additional == "true"

  return (
    <Fragment>
      <br />
      <Card>
        <TextInput
          name="sp_return_cost"
          label="Сумма СП"
          disabled
          value={formatMoney(
            values.contract_returnments
              .filter((i) => !i._destroy)
              .reduce((a, b) => a + parseMoney(b.sp_payment_cost), 0)
          )}
        />
      </Card>
      <FieldArray name="contract_returnments" render={(props) => <ContractReturnments {...props} store={store} />} />
    </Fragment>
  )
}

export default observer(SpTab)
