import React from "react"
import MaskedInput from "react-text-mask"
import classnames from "classnames"
import compose from "utils/compose"
import idGenerator from "utils/idGenerator"
import { connect, getIn, ErrorMessage } from "formik"
import { observer } from "mobx-react"

const PhoneInput = ({ name, label, type = "text", required = false, ...props }) => {
  const id = idGenerator()
  const { errors, values, handleBlur, handleChange } = props.formik
  const error = getIn(errors, name, false)
  const value = getIn(values, name, false) || ""
  const className = classnames("form-control", { "is-invalid": error })
  return (
    <div className={classnames("form-group", { required: required })}>
      <label htmlFor={id}>{label}</label>
      <MaskedInput
        mask={["+", "7", "(", /\d/, /\d/, /\d/, ")", /\d/, /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/]}
        {...{ className, id, name, type, value, ...props }}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="+7(495)111 11 11"
        autoComplete="off"
      />
      {error && (
        <div className="invalid-feedback">
          <ErrorMessage {...{ name }} />
        </div>
      )}
    </div>
  )
}
export default compose(
  observer,
  connect
)(PhoneInput)
