import React from "react"
import { observer } from "mobx-react"

const Table = ({ store, selected, setSelected }) => {
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Год</th>
        </tr>
      </thead>
      <tbody>
        {store.data.map((budget, index) => (
          <tr key={`budget-${index}`}>
            <td>
              <input
                type="radio"
                name="budget.id"
                value={budget.id}
                checked={selected.id === budget.id}
                onChange={() => setSelected({ id: budget.id, year: budget.year })}
              />
            </td>
            <td>{budget.year}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default observer(Table)
