import React, { Component, Fragment } from "react"
import { observer } from "mobx-react"
import LossStore from "stores/LossStore/One"
import Breadcrumbs from "pages/Losses/components/Breadcrumbs"
import Form from "pages/Losses/components/Form"
import { BreadcrumbItem } from "components/UI"

class Add extends Component {
  lossStore = LossStore.create()

  handleSubmit = (values, { props: { history, store }, setErrors }) =>
    store
      .create(values)
      .then(() => history.push("/losses"))
      .catch(({ response }) => setErrors(response.data.errors))

  render() {
    const { lossStore: store, handleSubmit } = this
    return (
      <Fragment>
        <Breadcrumbs>
          <BreadcrumbItem active>Создать</BreadcrumbItem>
        </Breadcrumbs>
        <Form {...{ store, handleSubmit }} />
      </Fragment>
    )
  }
}

export default observer(Add)
