import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">№ бордеро</th>
        <th scope="col">Дата бордеро</th>
        <th scope="col">Валюта договора</th>
        <th scope="col">Общая СС</th>
        <th scope="col">Общая СП</th>
        <th scope="col">Отчетный период</th>
        <th scope="col">Доп. информация</th>
        <th scope="col">Копия бордеро</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`borderos-${index}`}>
          <td>{row.number}</td>
          <td>{formatDate(row.date_on)}</td>
          <td>{row.currency.label}</td>
          <td>{row.ss_cost}</td>
          <td>{row.sp_cost}</td>
          <td>
            {formatDate(row.start_on)} - {formatDate(row.finish_on)}
          </td>
          <td>{row.comment}</td>
          <td>
            {row.documents.map((document) => (
              <a
                key={`document-${document.id}`}
                href={document.file.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {decodeURI(document.file.url.replace(/^.*[\\|/]/, ""))}
                <br />
              </a>
            ))}
          </td>
          <td>
            <Edit url={`/contracts/${row.contract_id}/borderos/${row.id}/edit`} />
            <Destroy
              action={() =>
                afterDestroyFetch(store, { id: row.id, contract_id: row.contract_id }, { contract_id: row.contract_id })
              }
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
