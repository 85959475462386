import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"

const InsuranceProducts = () => (
  <Switch>
    <Route path="/insurers/:insurer_id/insurance_products/:id/edit" component={Edit} />
    <Route path="/insurers/:insurer_id/insurance_products/new" component={Add} />
    <Route path="/insurers/:insurer_id/insurance_products/:id" component={Show} />
    <Route path="/insurers/:insurer_id/insurance_products" component={List} />
  </Switch>
)

export default InsuranceProducts
