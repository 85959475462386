import { useState } from "react"
import instance from "connection/instance"

const useOperations = (collection) => {
  const [operations, setOperations] = useState(collection)

  const fetchOperations = async (params) => {
    const response = await instance.get("/pages/operations", { params })
    setOperations(response.data.operations)
    return response.data
  }

  return { operations, fetchOperations }
}

export default useOperations
