import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const UserSalePlanItem = types.model("UserSalePlanItem", {
  id: types.maybeNull(types.integer),
  month: types.maybeNull(types.number),
  cost: types.maybeNull(types.string)
})

const UserSalePlan = types.model("UserSalePlan", {
  id: types.maybeNull(types.integer),
  budget_id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  status: types.maybeNull(newCustomEnum),
  user: types.maybeNull(User),
  user_sale_plan_items: types.maybeNull(types.array(UserSalePlanItem)),
  comment: types.maybeNull(types.string)
})

export { UserSalePlan }
