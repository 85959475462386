const prepare = (params = {}) => {
  const {
    document_kind: { id: document_kind_id },
    kind: { value: kind },
    ...other
  } = params
  return { ...other, document_kind_id, kind }
}

export { prepare }
