import React from "react"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"

const Table = ({ store, selected, setSelected }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Номер договора</th>
        <th scope="col">Дата заключения</th>
      </tr>
    </thead>
    <tbody>
      {store.data.map((agency_contract, index) => (
        <tr key={`agency_contract-${index}`}>
          <td>
            <input
              type="radio"
              name="agency_contract.id"
              value={agency_contract.id}
              checked={selected.id === agency_contract.id}
              onChange={() => setSelected({ id: agency_contract.id, number: agency_contract.number })}
            />
          </td>
          <td>{agency_contract.number}</td>
          <td>{formatDate(agency_contract.conclusion_on)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
