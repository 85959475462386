import React, { useState } from "react"
import compose from "utils/compose"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { Destroy, Submit, TextInput, RadioButtonGroup, RadioInput, Button, Card, CardTitle } from "components/UI"
import Modal from "./components/Modal"
import formSubmitHandler from "utils/newFormSubmitHandler"
import formatDate from "utils/formatDate"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const Form = ({ handleSubmit, values, setFieldValue }) => {
  const forms = [
    { label: "Банк", value: "bank" },
    { label: "Касса", value: "cashbox" }
  ]

  const [isOpen, setIsOpen] = useState(false)
  const closeModal = () => setIsOpen(false)

  const destroyRow = (id) => {
    setFieldValue(
      "operations",
      values.operations.filter((i) => i.id !== id)
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup label="Форма расчета" name="form">
        {forms.map(({ label, value }) => (
          <RadioInput key={`form_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <TextInput name="comment" label="Комментарий" />
      <Card>
        <CardTitle>Реестр платежей</CardTitle>
        <table className="table table-hover">
          <thead className="thead-light">
            <tr>
              <th>ID</th>
              <th>P&L счет</th>
              <th>Юр. лицо расчетов</th>
              <th>Контрагент</th>
              <th style={{ width: "150px" }}>Сумма</th>
              <th>Дата операции</th>
              <th>Назначение платежа</th>
              <th className="actions one" />
            </tr>
          </thead>
          <tbody>
            {values.operations.map((i, index) => (
              <tr key={`operation-${index}`}>
                <td>{i.id}</td>
                <td>{i.bill.short_name}</td>
                <td>{i.channel.name}</td>
                <td>{i.counterpart.name}</td>
                <td>{i.cost}</td>
                <td>{formatDate(i.date_on)}</td>
                <td>{i.comment + (i.kind.value.toString() === "2" ? ` / ${i.moving_kind.label}` : "")}</td>
                <td>
                  <Destroy action={() => destroyRow(i.id)} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={6}></td>
              <td>{formatMoney(values.operations.reduce((a, b) => a + parseMoney(b.cost), 0))}</td>
              <td colSpan={3}></td>
            </tr>
          </tfoot>
        </table>
        <Button onClick={() => setIsOpen(true)}>Добавить</Button>
      </Card>
      <br />
      <br />
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        initSelected={values.operations}
        setFieldValue={setFieldValue}
        form={values.form}
      />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ entity }) => {
      const { status, form } = entity
      return { ...entity, status: status?.value, form: form?.value }
    },
    handleSubmit: async (values, formikBag) => formSubmitHandler(values, formikBag, "/payment_plans")
  })
)(Form)
