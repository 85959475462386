import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import Documents from "./Documents"
import ContractDocumentStore from "stores/ContractDocumentStore/List"
import ContactFormContext from "contexts/ContactFormContext"

const contractDocumentStore = ContractDocumentStore.create()

const CommonTab = ({ store, ...props }) => {
  useEffect(() => {
    contractDocumentStore.fetchMeta()
  }, [])
  return (
    <Fragment>
      <br />
      <ContactFormContext.Provider value={{ contractDocumentStore }}>
        <Documents />
      </ContactFormContext.Provider>
    </Fragment>
  )
}

export default observer(CommonTab)
