import React from "react"
import { Destroy, Edit } from "components/UI"
import { withRouter } from "react-router-dom"
import formatDate from "utils/formatDate"
import { observer } from "mobx-react"
import afterDestroyFetch from "utils/afterDestroyFetch"

const status = (status) => {
  switch (status) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const integrationStatus = (i) => {
  if (i.kind.value === 1) {
    return "-"
  }

  switch (i.integrated) {
    case false:
      return <i className="fa fa-circle" style={{ color: "red" }} />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} />
  }
}

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Статус бюджета</th>
        <th scope="col">Статус интеграции</th>
        <th scope="col">Подразделение</th>
        <th scope="col">Дата создания</th>
        <th scope="col">Год</th>
        <th scope="col">Комментарии</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((i, index) => (
        <tr key={`budgets-${index}`}>
          <td onClick={() => history.push(`/budgets/${i.id}`)}>{i.id}</td>
          <td onClick={() => history.push(`/budgets/${i.id}`)}>{status(i.status.value)}</td>
          <td onClick={() => history.push(`/budgets/${i.id}`)}>{integrationStatus(i)}</td>
          <td onClick={() => history.push(`/budgets/${i.id}`)}>
            {i.kind.value === 0 ? i.subdivision.name : "Компания"}
          </td>
          <td onClick={() => history.push(`/budgets/${i.id}`)}>{formatDate(i.created_at)}</td>
          <td onClick={() => history.push(`/budgets/${i.id}`)}>{i.year}</td>
          <td onClick={() => history.push(`/budgets/${i.id}`)}>{i.comment}</td>
          <td>
            <Edit url={`/budgets/${i.id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, i.id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
