import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import toOptions from "utils/newToOptions"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"
import { Budget } from "./Budget"
import { prepare } from "./utils"
import formatDate from "utils/formatDate"

const FilterOptions = types.model("FilterOptions", {
  kinds: types.array(newCustomEnum, []),
  statuses: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    kinds: [],
    statuses: []
  }
}

const initialState = {
  status: { value: 0 },
  kind: { value: 0 },
  year: new Date().getFullYear() + 1,
  subdivision: { name: "" },
  subdivisions: [],
  creator: { name: "" },
  parent: { id: null, year: null },
  child: { id: null, year: null },
  budget_items: [
    {
      month: 1,
      cost: "0.0"
    },
    {
      month: 2,
      cost: "0.0"
    },
    {
      month: 3,
      cost: "0.0"
    },
    {
      month: 4,
      cost: "0.0"
    },
    {
      month: 5,
      cost: "0.0"
    },
    {
      month: 6,
      cost: "0.0"
    },
    {
      month: 7,
      cost: "0.0"
    },
    {
      month: 8,
      cost: "0.0"
    },
    {
      month: 9,
      cost: "0.0"
    },
    {
      month: 10,
      cost: "0.0"
    },
    {
      month: 11,
      cost: "0.0"
    },
    {
      month: 12,
      cost: "0.0"
    }
  ]
}

const BudgetStore = types
  .model("BudgetStore", {
    data: types.optional(Budget, initialState),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get filtersOptions() {
      const { kinds, statuses } = self.meta.filters_options
      return {
        kinds: toOptions(kinds),
        statuses: toOptions(statuses)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/budgets/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/budgets/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/budgets`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/budgets/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    },

    reset: () => {
      applySnapshot(self, {
        data: initialState,
        meta: self.meta
      })
    }
  }))

export default BudgetStore
