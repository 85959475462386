import React, { useContext } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import AvrInstallment from "./AvrInstallment"
import NestedFields from "components/UI/NestedFields"
import { useFormikContext, getIn } from "formik"

const defaultValues = {
  control_payment_on: null,
  payment_kind: { value: 0 },
  payment_on: null,
  payment_cost: null
}

const AvrInstallments = ({ name }) => {
  const { values } = useFormikContext()
  const isMultiple = getIn(values, "payment_kind.value", "0") == "1"
  const label = isMultiple ? "Рассрочка по уплате АВР" : "Оплата АВР"

  const { data: currentUser } = useContext(AuthStoreContext)
  const disabled = values.payment_status.value.toString() === "1" && ![5, 23].includes(currentUser.id)

  return (
    <NestedFields
      name={name}
      label={label}
      component={AvrInstallment}
      defaultValues={defaultValues}
      showAdd={isMultiple}
      showDestroy={() => isMultiple}
      reverse={true}
      disabledDestroy={disabled}
      disabledAdd={disabled}
    />
  )
}

export default AvrInstallments
