import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import Breadcrumbs from "pages/Bsos/components/Breadcrumbs"
import Form from "pages/Bsos/components/Form"
import { BreadcrumbItem } from "components/UI"
import BsoStore from "stores/BsoStore/One"

const Add = () => {
  const bsoStore = BsoStore.create()

  useEffect(() => {
    bsoStore.fetchMeta()
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={bsoStore} />
    </Fragment>
  )
}

export default observer(Add)
