import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import CheckPermissions from "components/CheckPermissions"

const Strengths = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("strengths")}>
    <Switch>
      <Route path="/strengths/:id/edit" component={Edit} />
      <Route path="/strengths/new" component={Add} />
      <Route path="/strengths/:id" component={Show} />
      <Route path="/strengths" component={List} />
    </Switch>
  </CheckPermissions>
)

export default Strengths
