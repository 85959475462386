import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const Insurer = types.model("Insurer", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Channel = types.model("Channel", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const BsoBatch = types.model("BsoBatch", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const BsoKind = types.model("BsoKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  bso_batches: types.array(BsoBatch)
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  subdivision: types.maybeNull(Subdivision)
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  number: types.maybeNull(types.string),
  user: types.maybeNull(User)
})

const Bso = types.model("Bso", {
  id: types.maybeNull(types.integer),
  insurer: types.maybeNull(Insurer),
  channel: types.maybeNull(Channel),
  bso_kind: types.maybeNull(BsoKind),
  bso_batch: types.maybeNull(BsoBatch),
  number_status: types.maybeNull(newCustomEnum),
  number: types.maybeNull(types.string),
  number_from: types.maybeNull(types.string),
  number_to: types.maybeNull(types.string),
  reception_number: types.maybeNull(types.string),
  reception_on: types.maybeNull(customDate),
  form_status: types.maybeNull(newCustomEnum),
  geolocation_status: types.maybeNull(newCustomEnum),
  contract: types.maybeNull(Contract),
  is_current: types.maybeNull(types.boolean)
})

export default Bso
