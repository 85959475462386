import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Subdivision } from "./Subdivision"
import toOptions from "utils/newToOptions"
import newCustomEnum from "types/newCustomEnum"
import { prepare } from "./utils"

const FilterOptions = types.model("FilterOptions", {
  kinds: types.array(newCustomEnum, []),
  kind_of_interactions: types.array(newCustomEnum, []),
  finance_models: types.array(newCustomEnum, []),
  statuses: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {}
}

const SubdivisionStore = types
  .model("SubdivisionStore", {
    data: types.optional(Subdivision, {
      kind: { value: 0 },
      kind_of_interaction: { value: 0 },
      finance_model: { value: 0 },
      status: { value: 0 },
      name: "",
      short_name: "",
      head: {},
      creator: {},
      users: [],
    }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get name() {
      return self.data.name
    },

    get filtersOptions() {
      const { kinds, kind_of_interactions, finance_models, statuses } = self.meta.filters_options
      return {
        kinds: toOptions(kinds),
        kind_of_interactions: toOptions(kind_of_interactions),
        finance_models: toOptions(finance_models),
        statuses: toOptions(statuses)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/subdivisions/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/subdivisions/meta`)
        .then(response => self.applyMeta(response))
        .then(response => self.setState("done"))
        .catch(error => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/subdivisions`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/subdivisions/${data.id}`, { data })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default SubdivisionStore
