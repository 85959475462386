import React, { Fragment, useEffect, useState } from "react"
import formatMoney from "utils/formatMoney"
import formatDate from "utils/formatDate"
import parseMoney from "utils/parseMoney"
import instance from "connection/instance"
import { eachDayOfInterval, parseISO } from "date-fns"
import classnames from "classnames"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import TabsBlock from "pages/Placements/components/TabsBlock"
import { Breadcrumb, BreadcrumbItem } from "components/UI"

const Diagrams = () => {
  const [table, setTable] = useState({ operations_sum: [], operations_by_channel: [] })

  const fetchData = async () => {
    try {
      const { data } = await instance.get("/reports/placements")

      let start = null
      let end = null

      data.operations = data.operations.map((i) => {
        const date_on = parseISO(i.date_on)
        const refund_on = parseISO(i.refund_on)

        if (date_on < start || start === null) {
          start = date_on
        }
        if (refund_on > end || end === null) {
          end = refund_on
        }

        return { ...i, sum: parseFloat(i.sum), date_on, refund_on }
      })

      setTable({ ...data, start, end })
    } catch {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (!table.start) {
    return null
  }

  const dates = eachDayOfInterval({
    start: table.start,
    end: table.end
  })

  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem active>Размещения</BreadcrumbItem>
        <BreadcrumbItem active>График</BreadcrumbItem>
      </Breadcrumb>
      <TabsBlock />
      <br />
      <div className="row">
        <div className="col">
          <h6 style={{ fontWeight: "bold" }}>
            Всего размещений на сумму:{" "}
            {formatMoney(table.operations_sum.reduce((a, b) => a + parseMoney(b.total_sum), 0))}
          </h6>
          <div style={{ overflowX: "scroll" }}>
            <table className="table table-bordered table-striped table-hover" style={{ width: "auto" }}>
              <thead>
                <tr>
                  <th style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>Юр. лицо расчетов</th>
                  {dates.map((i) => (
                    <th className="vertical-text">{formatDate(i)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {table?.operations.map((i) => (
                  <tr>
                    <td style={{ width: "300px", whiteSpace: "nowrap" }}>{i.name}</td>
                    {dates.map((date) => (
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            <div style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                              Сумма: {formatMoney(i.sum)}
                              <br />
                              Юр.лицо: {i.name}
                              <br />
                              Контрагент: {i.counterpart_name}
                              <br />
                              Дата возврата: {formatDate(i.refund_on)}
                            </div>
                          </Tooltip>
                        }
                      >
                        <th
                          className={classnames("square", { "table-danger": i.date_on <= date && date <= i.refund_on })}
                        ></th>
                      </OverlayTrigger>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Diagrams
