import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { DateInput, RadioButtonGroup, RadioInput, TextInput } from "components/UI"
import CreatorSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/UserSelect"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import OrderSubjectSelect from "components/Common/OrderSubjectSelect"
import SignerSelect from "components/Common/SignerSelect"
import UserSelect from "components/Common/UserSelect"
import addMonths from "date-fns/addMonths"
import subDays from "date-fns/subDays"
import addYears from "date-fns/addYears"
import isBefore from "date-fns/isBefore"
import set from "date-fns/set"

const InfoTab = ({ store, values, setFieldValue }) => {
  const { statuses } = store.filtersOptions
  return (
    <Fragment>
      <br />
      <RadioButtonGroup name="status.value" label="Статус">
        {statuses.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <CreatorSelect name="creator" label="Составитель" required />
      <ChannelSelect label="Юридическое лицо" required />
      <OrderSubjectSelect
        name="order_subject.id"
        required
        onChange={(e) => {
          setFieldValue("order_subject.id", e.value)

          if (e.value === 4) {
            setFieldValue("order_hire.contract_number", values.user_number || values.user?.number)
          }
          if (e.value === 5) {
            setFieldValue("order_change_position.kind", "постоянно")
            setFieldValue("order_change_position.reason_document", "Изменение к трудовому договору")
          }
          if (e.value === 6) {
            setFieldValue(
              "order_fire_at_will.reason",
              "Расторжение договора по инициативе работника, пункт 3 части 1 статьи 77 Трудового кодекса российской Федерации"
            )
            setFieldValue("order_fire_at_will.reason_document", "Заявление работника")
          }
          if (e.value === 7) {
            setFieldValue(
              "order_fire_by_agreement.reason",
              "Расторжение трудового договора по соглашению сторон, пункт 1 части первой статьи. 77 Трудового кодекса Российской Федерации"
            )
            setFieldValue("order_fire_by_agreement.reason_document", "Соглашение о расторжении трудового договора")
          }
        }}
      />
      <DateInput
        name="date_on"
        label="Дата составления приказа"
        required
        onChange={(e) => {
          setFieldValue("date_on", e)

          if (values.order_subject.id === 4) {
            setFieldValue("order_hire.hire_on", e)
            setFieldValue("order_hire.contract_date_on", e)
          }
          if (values.order_subject.id === 5) {
            setFieldValue("order_change_position.date_on", e)
          }
          if (values.order_subject.id === 6) {
            setFieldValue("order_fire_at_will.date_on", e)
          }
          if (values.order_subject.id === 2) {
            setFieldValue("order_award.date_on", subDays(addMonths(e, 1), 1))
          }
          if (values.order_subject.id === 7) {
            setFieldValue("order_fire_by_agreement.date_on", e)
          }
        }}
      />
      <TextInput name="number" label="Номер приказа" required />
      <SignerSelect name="signer.id" required />
      <UserSelect
        label="ФИО сотрудника"
        name="user.id"
        required
        onChange={(e) => {
          setFieldValue("user", { id: e.value, name: e.label })
          setFieldValue("user_number", e.data.number)
          setFieldValue("user_subdivision", e.data.subdivision.name)
          setFieldValue("user_position", e.data.position.name)

          if (values.order_subject.id === 4) {
            setFieldValue("order_hire.contract_number", e.data.number)
          }
          if (values.order_subject.id === 3) {
            const currentDate = new Date()
            let hiringDate = e.data.hiring_on
            if (hiringDate) {
              if (isBefore(currentDate, hiringDate)) {
                set(hiringDate, { year: currentDate.getYear() + 1900 - 1 })
              } else {
                set(hiringDate, { year: currentDate.getYear() + 1900 })
              }
              setFieldValue("order_vacation.perion_start_on", hiringDate)
              setFieldValue("order_vacation.perion_finish_on", subDays(addYears(hiringDate, 1), 1))
            }
          }
        }}
      />
      <TextInput
        name="user_number"
        label="Табельный номер сотрудника"
        disabled
        value={values.user_number || values.user?.number}
      />
      {/* <TextInput
        name="user_subdivision"
        label="Подразделение сотрудника"
        disabled
        value={values.user_subdivision || values.user?.subdivision?.name}
      />
      <TextInput
        name="user_position"
        label="Должность сотрудника"
        disabled
        value={values.user_position || values.user?.position?.name}
      /> */}
    </Fragment>
  )
}

export default observer(InfoTab)
