import { types } from "mobx-state-tree"
import Source from "./Source"
import Kind from "./Kind"

const FilterOptions = types.model("FilterOptions", {
  sources: types.array(Source, []),
  kinds: types.array(Kind, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

export default Meta
