import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import { withRouter } from "react-router-dom"

const Table = ({ store, history }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th>Название Адресата</th>
        <th>Организация</th>
        <th>Имя адресата (контактное лицо)</th>
        <th>Адрес</th>
        <th>Комментарий</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`destinations-${index}`}>
          <td onClick={() => history.push(`/destinations/${row.id}`)}>{row.name}</td>
          <td onClick={() => history.push(`/destinations/${row.id}`)}>{row.organization.name}</td>
          <td onClick={() => history.push(`/destinations/${row.id}`)}>{row.contact}</td>
          <td onClick={() => history.push(`/destinations/${row.id}`)}>{row.address}</td>
          <td onClick={() => history.push(`/destinations/${row.id}`)}>{row.comment}</td>
          <td>
            <Edit url={`/destinations/${row.id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, row.id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default withRouter(observer(Table))
