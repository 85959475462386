import React, {Fragment} from "react"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">Файл</th>
        <th scope="col">Тип</th>
      </tr>
    </thead>
    <tbody>
      {store.data.documents.map((i, index) => (
        <tr key={`order-document-${index}`}>
          <td>
            {i.attachments
              .filter((i) => i.file.url)
              .map((attachment) => (
                <a
                  key={`attachment-${attachment.id}`}
                  href={attachment.file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {decodeURI(attachment.file.url.replace(/^.*[\\|/]/, ""))}
                  <br />
                </a>
              ))}
          </td>
          <td>{String(i.document_kind.id) === "18" ? i.document_kind_other : i.document_kind.name}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
