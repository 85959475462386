import React, { Fragment, useContext } from "react"
import { TextInput, RadioButtonGroup, RadioInput } from "components/UI"
import BsoKindFormContext from "contexts/BsoKindFormContext"

const BsoBatch = ({ prefix = null, form }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const { store } = useContext(BsoKindFormContext)
  const { bso_batch_statuses } = store.filtersOptions
  const regexp = form.values.insurance_kind?.id !== 14 ? /[^А-Я\d]/g : /[^АВЕКМНОРСТУХ\d]/gi
  return (
    <Fragment>
      <TextInput
        name={path("name")}
        label="Серия БСО"
        onChange={(e) => {
          let { value } = e.target
          value = value.replace(regexp, "")
          form.setFieldValue(path("name"), value)
        }}
      />
      <RadioButtonGroup label="Статус" name={path("status.value")}>
        {bso_batch_statuses.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
    </Fragment>
  )
}

export default BsoBatch
