import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Order } from "./Order"
import toOptions from "utils/newToOptions"
import newCustomEnum from "types/newCustomEnum"
import { prepare } from "./utils"

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    statuses: []
  }
}

const OrderStore = types
  .model("OrderStore", {
    data: types.optional(Order, {
      status: { value: 0 },
      user: { id: null, subdivision: {}, position: {}, signer: { user: {} } },
      creator: { id: null },
      channel: { id: 2 },
      order_subject: { id: null },
      signer: { id: 8 },
      documents: []
    }),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { statuses } = self.meta.filters_options
      return {
        statuses: toOptions(statuses)
      }
    },

    get id() {
      return self.data.id
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, ...options } = params
      return instance
        .get(`/orders/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta(params = {}) {
      self.setState("pending")
      return instance
        .get(`/orders/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .post(`/orders`, { data })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const data = prepare(params)
      return instance
        .put(`/orders/${data.id}`, { data })
        .then((response) => self.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    export(params = {}) {
      const { id, ...options } = params
      self.setState("pending")

      return instance.get(`/orders/${id}/export_xlsx`, {
        params,
        responseType: "arraybuffer",
        headers: {
          Accept: "application/vnd.ms-excel"
        }
      })
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default OrderStore
