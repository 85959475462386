import React from "react"
import { Button, Card, CardTitle } from "components/UI"
import Document from "./Document"

const Documents = ({ push, form, store, ...h }) => {
  const onAddClick = () => push({ file: { url: "" } })
  return (
    <Card>
      <CardTitle>Документы</CardTitle>
      {form.values.documents.map((document, index) => (
        <Document {...{ key: `${index}`, index, document, store, h }} />
      ))}
      <Button variant="success" onClick={onAddClick}>
        Добавить
      </Button>
    </Card>
  )
}

export default Documents
