import React from "react"
import Phone from "./Phone"
import NestedFields from "components/UI/NestedFields"

const defaultValues = {
  value: null
}

const Phones = ({ name = "phones", required = false }) => (
  <NestedFields name={name} label="Телефоны" component={Phone} defaultValues={defaultValues} required={required} />
)

export default Phones
