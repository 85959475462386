import React, { Fragment } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { Submit, SelectInput, TextInput, CurrencyInput } from "components/UI"
import formatMoney from "utils/formatMoney"
import formSubmitHandler from "utils/formSubmitHandler"
import parseMoney from "utils/parseMoney"
import instance from "connection/instance"

const getSum = (values, begin, end) =>
  formatMoney(
    values.items
      .map((i) => i.costs.slice(begin, end).reduce((a, b) => a + parseMoney(b.cost), 0))
      .reduce((a, b) => a + parseMoney(b), 0)
  )

const Form = ({ store, values, handleSubmit, setFieldValue }) => {
  const { kinds } = store.filtersOptions

  const onFileInputChange = (e) => {
    const formData = new FormData()
    formData.append("data[file]", e.target.files[0])
    instance
      .post("/sale_plans/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(({ data }) => {
        setFieldValue("name", data.data.name)
        setFieldValue("year", data.data.year)
        setFieldValue("kind", data.data.kind)
        setFieldValue("items", data.data.items.map(i => {return {
          id: i.id,
          short_name: i.short_name,
          costs: []
        }}))
        data.data.items.forEach((item, itemIndex) => {
          item.costs.forEach((cost, costIndex) => {
            setFieldValue(`items.${itemIndex}.costs.${costIndex}`, cost)
          })
        })
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextInput name="name" label="Название" />
      <TextInput name="year" label="Год" />
      <SelectInput name="kind.value" label="Тип" options={kinds} />
      <div className="form-group">
        <label>Импорт</label>
        <fieldset>
          <input type="file" onChange={onFileInputChange} />
        </fieldset>
      </div>
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: "4300px" }}>
          <thead style={{ background: "#e5efdc" }}>
            <tr>
              <th scope="col" rowSpan="2" style={{ border: "1px solid white" }}>
                Подразделение
              </th>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 1КВ{values.year}
              </th>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 2КВ{values.year}
              </th>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 3КВ{values.year}
              </th>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 4КВ{values.year}
              </th>
              <th scope="col" rowSpan="2" style={{ border: "1px solid white" }}>
                План {values.year}
              </th>
            </tr>
            <tr>
              <th style={{ border: "1px solid white" }}>1КВ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ЯНВ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ФЕВ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>МАРТ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>2КВ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>АПР{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>МАЙ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ИЮНЬ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>3КВ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ИЮЛЬ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>АВГ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>СЕН{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>4КВ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ОКТ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>НОЯ{values.year.slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ДЕК{values.year.slice(2, 4)}</th>
            </tr>
          </thead>
          <tbody>
            {values.items.map((item, itemIndex) => (
              <tr key={`item=${itemIndex}`}>
                <td style={{ fontWeight: "bold", verticalAlign: "middle" }}>{item.short_name}</td>
                {item.costs.map(({ costs }, costIndex) => (
                  <Fragment key={`item=${itemIndex}-${costIndex}`}>
                    {costIndex % 3 === 0 && (
                      <td style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          item.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b.cost), 0)
                        )}
                      </td>
                    )}
                    <td>
                      <CurrencyInput
                        name={`items.${itemIndex}.costs.${costIndex}.cost`}
                        bare
                        style={{ width: "100%" }}
                      />
                    </td>
                  </Fragment>
                ))}
                <td style={{ verticalAlign: "middle", width: "200px" }}>
                  {formatMoney(item.costs.reduce((a, b) => a + parseMoney(b.cost), 0))}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot style={{ background: "yellow", fontWeight: "bold" }}>
            <tr>
              <td>Итого</td>
              <td>{getSum(values, 0, 3)}</td>
              <td>{getSum(values, 0, 1)}</td>
              <td>{getSum(values, 1, 2)}</td>
              <td>{getSum(values, 2, 3)}</td>

              <td>{getSum(values, 3, 6)}</td>
              <td>{getSum(values, 3, 4)}</td>
              <td>{getSum(values, 4, 5)}</td>
              <td>{getSum(values, 5, 6)}</td>

              <td>{getSum(values, 6, 9)}</td>
              <td>{getSum(values, 6, 7)}</td>
              <td>{getSum(values, 7, 8)}</td>
              <td>{getSum(values, 8, 9)}</td>

              <td>{getSum(values, 9, 12)}</td>
              <td>{getSum(values, 9, 10)}</td>
              <td>{getSum(values, 10, 11)}</td>
              <td>{getSum(values, 11, 12)}</td>
              <td>{getSum(values, 0, 12)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <TextInput name="comment" label="Комментарий" />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/sale_plans")
  }),
  observer
)(Form)
