import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import Breadcrumbs from "pages/Bsos/components/Breadcrumbs"
import Form from "pages/Bsos/components/Form"
import { BreadcrumbItem } from "components/UI"
import BsoStore from "stores/BsoStore/One"

const bsoStore = BsoStore.create()

const Edit = (props) => {
  useEffect(() => {
    const { id } = props.match.params
    bsoStore.fetch({ id })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>{bsoStore.number}</BreadcrumbItem>
        <BreadcrumbItem active>Редактировать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={bsoStore} />
    </Fragment>
  )
}

export default observer(Edit)
