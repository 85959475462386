import React, { Fragment, useEffect } from "react"
import Breadcrumbs from "pages/PaymentPlans/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import usePaymentPlans from "pages/PaymentPlans/hooks/usePaymentPlans"
import Table from "./components/Table"
import TabsBlock from 'pages/Operations/containers/List/components/TabsBlock'

const List = (props) => {
  const { paymentPlans, fetchPaymentPlans, destroyPaymentPlanWithFetch } = usePaymentPlans({ data: [] })

  useEffect(() => {
    fetchPaymentPlans()
  }, [])
  return (
    <Fragment>
      <Breadcrumbs />
      <TabsBlock />
      <br/>
      <Card>
        <Button url="/payment_plans/new">Создать</Button>
      </Card>
      <Table collection={paymentPlans} destroyPaymentPlanWithFetch={destroyPaymentPlanWithFetch} />
      {/* <Paginate collection={paymentPlans} fetch={fetchPaymentPlans} /> */}
    </Fragment>
  )
}

export default List
