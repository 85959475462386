import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import Breadcrumbs from "pages/SalePlans/components/Breadcrumbs"
import Form from "pages/SalePlans/components/Form"
import { BreadcrumbItem } from "components/UI"
import SalePlanStore from "stores/SalePlanStore/One"

const Add = () => {
  const salePlanStore = SalePlanStore.create()

  useEffect(() => {
    salePlanStore.fetchMeta().then(({ meta }) => {
      console.log(JSON.parse(JSON.stringify(meta.filters_options.items)))
      salePlanStore.applyData({
        status: 200,
        data: {
          data: {
            ...salePlanStore.data,
            items: meta.filters_options.items.map(({ id, short_name }) => {
              return {
                id,
                short_name,
                costs: Array(12)
                  .fill()
                  .map((e, month) => {
                    return {
                      month: month + 1,
                      cost: "0"
                    }
                  })
              }
            })
          }
        }
      })
      // store.meta.filters_options.items
    })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>
      <Form store={salePlanStore} />
    </Fragment>
  )
}

export default observer(Add)
