import React, { Fragment, useContext, useState } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import {
  RadioButtonGroup,
  RadioInput,
  TextInput,
  Submit,
  PhoneInput,
  AddressInput,
  Button,
  DateInput,
  CurrencyInput,
  SelectInput
} from "components/UI"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import UserSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/UserSelect"
import OrganizationSelect from "components/Common/OrganizationSelect"
import DestinationModal from "./DestinationModal"
import RouteModal from "./RouteModal"
import formSubmitHandler from "utils/formSubmitHandler"
import Recipients from "./Recipients"
import addDays from "date-fns/addDays"
import AuthStoreContext from "contexts/AuthStoreContext"
import ContractSelect from "./ContractSelect"
import ProviderSelect from "components/Common/ProviderSelect"

const Form = ({ store, values, setFieldValue, handleSubmit }) => {
  const { statuses, delivery_services, urgencies, delivery_kinds, delivery_complexities, from_time_kinds } =
    store.filtersOptions

  const { data: currentUser } = useContext(AuthStoreContext)

  const [isOpenFrom, setIsOpenFrom] = useState(false)
  const [isOpenTo, setIsOpenTo] = useState(false)
  const [isOpenRoute, setIsOpenRoute] = useState(false)

  const closeFromModal = () => setIsOpenFrom(false)
  const closeToModal = () => setIsOpenTo(false)
  const closeRouteModal = () => setIsOpenRoute(false)

  const setFrom = (selected) => {
    // setFieldValue("from_destination_id", selected.id)
    setFieldValue("from_organization", selected.organization)
    setFieldValue("from_address", selected.address)
    setFieldValue("from_comment", selected.comment)
    setFieldValue("from_contact", selected.contact)
    setFieldValue("from_phone", selected.phone)
  }

  const setTo = (selected) => {
    // setFieldValue("to_destination_id", selected.id)
    let index = values.recipients.findIndex((i) => !i._destroy)

    if (index === -1) {
      setFieldValue(`recipients.0.to_time_kind`, { value: 1 })
      setFieldValue(`recipients.0.to_date_on`, values.urgency.value.toString() === "1" ? new Date() : null)
      setFieldValue(`recipients.0.recipient_cargos`, [
        {
          kind: { value: 0 },
          kind_other: null
        }
      ])
    }
    index = index === -1 ? 0 : index

    setFieldValue(`recipients.${index}.to_organization`, selected.organization)
    setFieldValue(`recipients.${index}.to_address`, selected.address)
    setFieldValue(`recipients.${index}.to_comment`, selected.comment)
    setFieldValue(`recipients.${index}.to_contact`, selected.contact)
    setFieldValue(`recipients.${index}.to_phone`, selected.phone)
  }

  const setRoute = (selected) => {
    // setFieldValue("from_destination_id", selected.id)
    setFieldValue("from_organization", selected.from_organization)
    setFieldValue("from_address", selected.from_address)
    setFieldValue("from_comment", selected.from_comment)
    setFieldValue("from_contact", selected.from_contact)
    setFieldValue("from_phone", selected.from_phone)

    // setFieldValue("to_destination_id", selected.id)

    let index = values.recipients.findIndex((i) => !i._destroy)

    if (index === -1) {
      setFieldValue(`recipients.0.to_time_kind`, { value: 1 })
      setFieldValue(`recipients.0.to_date_on`, values.urgency.value.toString() === "1" ? new Date() : null)
      setFieldValue(`recipients.0.recipient_cargos`, [
        {
          kind: { value: 0 },
          kind_other: null
        }
      ])
    }
    index = index === -1 ? 0 : index

    setFieldValue(`recipients.${index}.to_organization`, selected.to_organization)
    setFieldValue(`recipients.${index}.to_address`, selected.to_address)
    setFieldValue(`recipients.${index}.to_comment`, selected.to_comment)
    setFieldValue(`recipients.${index}.to_contact`, selected.to_contact)
    setFieldValue(`recipients.${index}.to_phone`, selected.to_phone)
  }

  const setDeliveryService = ({ value }) => {
    setFieldValue("delivery_service.value", value)
    if (value === 0) {
      setFieldValue("cost", "400.00")
    }
  }

  const onSent = () => {
    setFieldValue("status.value", 1)
    handleSubmit()
  }

  const onRejected = () => {
    setFieldValue("status.value", 2)
    handleSubmit()
  }

  const onViceVersa = () => {
    const {
      from_organization,
      from_contact,
      from_phone,
      from_address,
      from_comment,
      from_date_on,
      from_start,
      from_finish,
      from_time_kind
    } = values

    const {
      to_organization,
      to_contact,
      to_phone,
      to_address,
      to_comment,
      to_date_on,
      to_start,
      to_finish,
      to_time_kind
    } = values.recipients[0]

    setFieldValue("from_organization", to_organization)
    setFieldValue("from_contact", to_contact)
    setFieldValue("from_phone", to_phone)
    setFieldValue("from_address", to_address)
    setFieldValue("from_comment", to_comment)
    setFieldValue("from_date_on", null)
    setFieldValue("from_start", null)
    setFieldValue("from_finish", null)
    setFieldValue("from_time_kind", { value: 1 })

    setFieldValue("recipients.0.to_organization", from_organization)
    setFieldValue("recipients.0.to_contact", from_contact)
    setFieldValue("recipients.0.to_phone", from_phone)
    setFieldValue("recipients.0.to_address", from_address)
    setFieldValue("recipients.0.to_comment", from_comment)
    setFieldValue("recipients.0.to_date_on", null)
    setFieldValue("recipients.0.to_start", null)
    setFieldValue("recipients.0.to_finish", null)
    setFieldValue("recipients.0.to_time_kind", { value: 1 })
  }

  const isDisabled =
    (["2", "3", "4", "5"].includes(values.status.value.toString()) &&
      currentUser.subdivision.id.toString() != "7" &&
      currentUser.id.toString() != "48") ||
    (["5"].includes(values.status.value.toString()) &&
      (currentUser.subdivision.id.toString() == "7" || currentUser.id.toString() == "48"))

  const isAdmin = [3, 5, 23, 27, 46, 57].includes(currentUser.id)

  const isDisabledViceVersa = values.id || values.recipients.length !== 1

  return (
    <form onSubmit={handleSubmit}>
      <Tabs defaultActiveKey="common">
        <Tab eventKey="common" title="Общие сведения">
          <br />
          <UserSelect name="user" label="Заказчик" required isDisabled={isDisabled || !isAdmin} />
          <RadioButtonGroup label="Срочность" name="urgency.value" disabled={isDisabled}>
            {urgencies.map(({ label, value }) => (
              <RadioInput
                key={`kind_${value}`}
                label={label}
                id={value}
                onChange={(e) => {
                  setFieldValue("urgency.value", e.target.value)
                  if (e.target.value.toString() === "1") {
                    setFieldValue("from_date_on", new Date())
                    values.recipients.forEach((i, index) => setFieldValue(`recipients.${index}.to_date_on`, new Date()))
                  } else {
                    setFieldValue("from_date_on", null)
                    values.recipients.forEach((i, index) => setFieldValue(`recipients.${index}.to_date_on`, null))
                  }
                }}
              />
            ))}
          </RadioButtonGroup>
          {values.urgency.value.toString() === "1" && (
            <TextInput name="justification_of_urgency" label="Обоснование срочности" required disabled={isDisabled} />
          )}
          <RadioButtonGroup label="Тип доставки" name="delivery_kind.value" disabled={isDisabled}>
            {delivery_kinds.map(({ label, value }) => (
              <RadioInput key={`kind_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <RadioButtonGroup label="Сложность маршрута" name="delivery_complexity.value" disabled={isDisabled}>
            {delivery_complexities.map(({ label, value }) => (
              <RadioInput key={`kind_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <TextInput name="task" label="Задание" required disabled={isDisabled} />
          <ContractSelect disabled={isDisabled} />

          {(currentUser.subdivision.id == 7 || currentUser.id == 5 || currentUser.id == 48) && (
            <Fragment>
              <br />
              <SelectInput name="status.value" label="Статус заявки" options={statuses} />
              <ProviderSelect label="Служба доставки" activity_kind_id="5" />
              <CurrencyInput name="cost" label="Стоимость доставки" />
            </Fragment>
          )}
        </Tab>
        <Tab eventKey="from" title="Отправитель">
          <br />
          <Button onClick={() => setIsOpenFrom(true)} disabled={isDisabled}>
            Типовой адресат
          </Button>
          &nbsp;
          <Button onClick={() => setIsOpenRoute(true)} disabled={isDisabled}>
            Типовой маршрут
          </Button>
          &nbsp;
          <Button onClick={() => onViceVersa()} disabled={isDisabledViceVersa}>
            Поменять местами
          </Button>
          <br />
          <br />
          <OrganizationSelect name="from_organization" isDisabled={isDisabled} />
          <TextInput name="from_contact" label="Имя адресата (контактное лицо)" required disabled={isDisabled} />
          <PhoneInput name="from_phone" label="Телефон" required disabled={isDisabled} />
          <AddressInput name="from_address" label="Адрес" required disabled={isDisabled} />
          <TextInput name="from_comment" label="Комментарий" disabled={isDisabled} />
          <DateInput
            name="from_date_on"
            label="Дата"
            required
            minDate={
              currentUser.id == 5
                ? new Date(1970, 1, 1)
                : addDays(new Date(), values.urgency.value.toString() === "1" ? 0 : 1)
            }
            disabled={isDisabled}
          />
          <TextInput name="from_start" label="Время с" disabled={isDisabled} />
          <TextInput name="from_finish" label="По" disabled={isDisabled} />
          <RadioButtonGroup label="Временные границы" name="from_time_kind.value" disabled={isDisabled}>
            {from_time_kinds.map(({ label, value }) => (
              <RadioInput key={`from_time_kind_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <DestinationModal isOpen={isOpenFrom} onSet={setFrom} closeModal={closeFromModal} />
        </Tab>
        <Tab eventKey="to" title="Получатель">
          <br />
          <Button onClick={() => setIsOpenRoute(true)} disabled={isDisabled}>
            Типовой маршрут
          </Button>
          &nbsp;
          <Button onClick={() => onViceVersa()} disabled={isDisabledViceVersa}>
            Поменять местами
          </Button>
          <br />
          <br />
          <Recipients isDisabled={isDisabled} />
          <DestinationModal isOpen={isOpenTo} onSet={setTo} closeModal={closeToModal} />
        </Tab>
      </Tabs>
      <RouteModal isOpen={isOpenRoute} onSet={setRoute} closeModal={closeRouteModal} />
      <Submit>Сохранить</Submit>&nbsp;
      <Button onClick={onSent} variant="secondary" disabled={isDisabled}>
        Отправить
      </Button>
      &nbsp;
      <Button onClick={onRejected} variant="warning" disabled={isDisabled}>
        Отменить
      </Button>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/couriers")
  }),
  observer
)(Form)
