import { types } from "mobx-state-tree"

const OrderSubject = types.model("OrderSubject", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  short_name: types.maybeNull(types.string),
  comment: types.maybeNull(types.string)
})

export { OrderSubject }
