import React, { Fragment, useState } from "react"
import { observer } from "mobx-react"
import { useFormikContext } from "formik"
import { Link } from "react-router-dom"
import { Button } from "components/UI"
import Modal from "./Modal"

const ContractSelect = ({ disabled = false, ...props }) => {
  const {
    values: { contracts },
    setFieldValue
  } = useFormikContext()

  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const onDestroy = () => {
    setFieldValue("contracts", [])
  }
  return (
    <div className="form-group">
      <label>
        Если отправляем договор страхования, привяжите к нему заявку:&nbsp;
        {contracts.length > 0
          ? contracts.map((i) => (
              <Fragment>
                <Link target="_blank" to={`/contracts/${i.id}`}>
                  {i.number}
                </Link>
                &nbsp;
              </Fragment>
            ))
          : "не выбран"}
      </label>
      <fieldset>
        <Button onClick={openModal} disabled={disabled}>
          Выбрать
        </Button>
        &nbsp;
        <Button onClick={onDestroy} disabled={disabled} variant="danger">
          Удалить
        </Button>
      </fieldset>
      <Modal isOpen={isOpen} closeModal={closeModal} setFieldValue={setFieldValue} />
    </div>
  )
}

export default observer(ContractSelect)
