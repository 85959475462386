import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import AppealStore from "stores/AppealStore/List"
import Breadcrumbs from "pages/Appeals/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import Table from "./components/Table"
import Filter from "./components/Filter"

const appealStore = AppealStore.create()

const List = () => {
  useEffect(() => {
    appealStore.fetch(getParams())
  }, [])

  return (
    <Fragment>
      <Breadcrumbs />
      <Card>
        <Button url="/appeals/new">Создать</Button>
      </Card>
      <Card>
        <Filter store={appealStore} />
      </Card>
      <Table store={appealStore} />
      <Paginate store={appealStore} />
    </Fragment>
  )
}

export default observer(List)
