import React, { Fragment } from "react"
import { observer } from "mobx-react"
import { Card, CardTitle, CurrencyInput, DateInput, RadioButtonGroup, RadioInput, TextInput } from "components/UI"

const BackofficeTab = ({ store, ...props }) => {
  const { spSkRetentionOptions } = store.filtersOptions

  return (
    <Fragment>
      <br />
      <Card>
        <CardTitle>Страховая премия в СК</CardTitle>
        <DateInput name="sp_paid_sk_on" label="Дата оплаты СП СИ-НЕРДЖИ в СК" readOnly />
        <TextInput name="sp_sk_form" label="Форма оплаты СП СИ-НЕРДЖИ в СК (касса/р/сч)" readOnly />
        <RadioButtonGroup label="Тип" name="sp_sk_retention.id" disabled>
          {spSkRetentionOptions.map(({ label, value }) => (
            <RadioInput key={`sp_sk_retention_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
        <CurrencyInput name="sp_sk_cost" label="Сумма (руб.)" readOnly />
      </Card>
      <Card>
        <CardTitle>АСПОД (вид БСО: КАСКО, ОСАГО, ИФЛ, МИГРАНТЫ)</CardTitle>
        <DateInput name="aspod_on" label="Дата подписания" readOnly />
        <TextInput name="aspod_number" label="Номер" readOnly />
      </Card>
      <Card>
        <CardTitle>АКТ ВЫПОЛНЕННЫХ РАБОТ (АВР)</CardTitle>
        <DateInput name="avr_formation_on" label="Дата формирования АВР / дата передачи АВР в СИ-НЕРДЖИ" readOnly />
        <DateInput name="avr_sk_on" label="Дата передачи в СК подисанного АВР" readOnly />
        <DateInput name="avr_sk_paid_on" label="Контрольная дата оплаты АВР СК" readOnly />
        <DateInput name="avr_on" label="ДАТА АВР" readOnly />
        <TextInput name="avr_number" label="Номер АВР" readOnly />
        <CurrencyInput name="avr_cost" label="ОБЩАЯ СУММА АВР" readOnly />
        <DateInput name="avr_original_on" label="ДАТА ПОЛУЧЕНИЯ ОРИГИНАЛА ПОДПИСАННОГО АВР ИЗ СК" readOnly />
      </Card>
    </Fragment>
  )
}

export default observer(BackofficeTab)
