import React from "react"
import { Destroy, Edit, Sort } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import { withRouter } from "react-router-dom"
import classnames from "classnames"
import parseMoney from "utils/parseMoney"
import sortChangeHandler from "utils/sortChangeHandler"

const parcel_bordero = (parcel_bordero) => {
  if (parcel_bordero?.id) {
    return <i className="fa fa-circle" style={{ color: "green" }} />
  }
  return <i className="fa fa-circle" style={{ color: "red" }} />
}

const Table = ({
  history,
  store,
  selectedRows,
  totalSelectedRow,
  isCheckedRow,
  onCheckedRow,
  isCheckedAllRows,
  onCheckedAllRows,
  resetSelected,
  contract_id
}) => {
  const { page } = store.meta.pagination
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllRows(page)} onChange={(e) => onCheckedAllRows(e, page)} />
          </th>
          <th scope="col">ID</th>
          <th scope="col">Бордеро</th>
          <th scope="col">Дата отправки</th>
          <th scope="col">№ накладной / заказа</th>
          <th scope="col">Отправитель / Организация</th>
          <th scope="col">Адрес отправителя</th>
          <th scope="col">Получатель / Организация</th>
          <th scope="col">Адрес получателя</th>
          <th scope="col">Страховая сумма</th>
          <th scope="col">Комментарии</th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {store.data.map((row, index) => (
          <tr key={`parcels-${index}`} className={classnames({ "table-danger": parseMoney(row.cost) > 30000000 })}>
            <td>
              <input type="checkbox" checked={isCheckedRow(row, page)} onChange={() => onCheckedRow(row, page)} />
            </td>
            <td onClick={() => history.push(`/contracts/${row.contract_id}/parcels/${row.id}`)}>{row.id}</td>
            <td onClick={() => history.push(`/contracts/${row.contract_id}/parcels/${row.id}`)}>
              {parcel_bordero(row.parcel_bordero)}
            </td>
            <td onClick={() => history.push(`/contracts/${row.contract_id}/parcels/${row.id}`)}>
              {formatDate(row.departure_on)}
            </td>
            <td onClick={() => history.push(`/contracts/${row.contract_id}/parcels/${row.id}`)}>{row.number}</td>
            <td onClick={() => history.push(`/contracts/${row.contract_id}/parcels/${row.id}`)}>{row.sender}</td>
            <td onClick={() => history.push(`/contracts/${row.contract_id}/parcels/${row.id}`)}>
              {row.sender_address}
            </td>
            <td onClick={() => history.push(`/contracts/${row.contract_id}/parcels/${row.id}`)}>{row.recipient}</td>
            <td onClick={() => history.push(`/contracts/${row.contract_id}/parcels/${row.id}`)}>
              {row.recipient_address}
            </td>
            <td onClick={() => history.push(`/contracts/${row.contract_id}/parcels/${row.id}`)}>{row.cost}</td>
            <td onClick={() => history.push(`/contracts/${row.contract_id}/parcels/${row.id}`)}>{row.comment}</td>
            <td>
              <Edit url={`/contracts/${row.contract_id}/parcels/${row.id}/edit`} />
              <Destroy
                action={() =>
                  afterDestroyFetch(
                    store,
                    { id: row.id, contract_id: row.contract_id },
                    { contract_id: row.contract_id }
                  )
                }
                disabled={row.parcel_bordero?.id && row.parcel_bordero.status.value.toString() === "1"}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot className="thead-light">
        <tr>
          <th colSpan="11">Выбрано: {totalSelectedRow}</th>
          <th>
            <button
              type="button"
              className="btn btn-danger"
              title="Сбросить выделение"
              onClick={resetSelected}
              disabled={totalSelectedRow === 0}
            >
              <i className="fas fa-sync-alt"></i>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  )
}

export default withRouter(observer(Table))
