import React, { Fragment, useEffect } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import InsurerStore from "stores/InsurerStore/List"
import Breadcrumbs from "pages/Insurers/components/Breadcrumbs"
import { Button, Card, Paginate } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Filter from "./components/Filter"
import Table from "./components/Table"
import TabsBlock from "pages/AgencyContracts/containers/List/components/TabsBlock"

const insurerStore = InsurerStore.create()

const List = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    insurerStore.fetch(getParams())
  }, [])


  console.log(insurerStore.isFetched)

  return (
    <Fragment>
      <Breadcrumbs />
      <TabsBlock />
      <br />
      <Card>
        <Button url="/insurers/new">Создать</Button>
      </Card>
      <Card>
        <Filter store={insurerStore} />
      </Card>
      <Table store={insurerStore} />
      <Paginate store={insurerStore} />
    </Fragment>
  )
}

export default observer(List)
