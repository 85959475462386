import React from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import { withRouter } from "react-router-dom"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"

const status = (status) => {
  switch (status) {
    case 0:
      return <i className="fa fa-circle" style={{ color: "red" }} title="Черновик" />
    default:
      return <i className="fa fa-circle" style={{ color: "green" }} title="Выпущен" />
  }
}

const Table = ({
  history,
  store,
  selectedRows,
  totalSelectedRow,
  isCheckedRow,
  onCheckedRow,
  isCheckedAllRows,
  onCheckedAllRows,
  resetSelected,
  contract_id
}) => {
  const { page } = store.meta.pagination

  const cost_sum = Object.values(selectedRows)
    .flat()
    .map((row) => row.parcels.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0))
    .reduce((a, b) => a + b, 0)

  const sp_sum = Object.values(selectedRows)
    .flat()
    .map((row) => row.parcels.map((i) => parseMoney(i.sp)).reduce((a, b) => a + b, 0))
    .reduce((a, b) => a + b, 0)
  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">
            <input type="checkbox" checked={isCheckedAllRows(page)} onChange={(e) => onCheckedAllRows(e, page)} />
          </th>
          <th scope="col">ID</th>
          <th scope="col">Статус</th>
          <th scope="col"># Бордеро</th>
          <th scope="col">Дата бордеро</th>
          <th scope="col">Период</th>
          <th scope="col">Общая страховая сумма</th>
          <th scope="col">Общая страховая премия</th>
          <th scope="col">Комментарий</th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {store.data.map((row, index) => (
          <tr key={`parcel_borderos-${index}`}>
            <td>
              <input type="checkbox" checked={isCheckedRow(row, page)} onChange={() => onCheckedRow(row, page)} />
            </td>
            <td onClick={() => history.push(`/contracts/${contract_id}/parcel_borderos/${row.id}`)}>{row.id}</td>
            <td onClick={() => history.push(`/contracts/${contract_id}/parcel_borderos/${row.id}`)}>
              {status(row.status.value)}
            </td>
            <td onClick={() => history.push(`/contracts/${contract_id}/parcel_borderos/${row.id}`)}>{row.number}</td>
            <td onClick={() => history.push(`/contracts/${contract_id}/parcel_borderos/${row.id}`)}>
              {formatDate(row.date_on)}
            </td>
            <td onClick={() => history.push(`/contracts/${contract_id}/parcel_borderos/${row.id}`)}>
              {formatDate(row.start_on)} - {formatDate(row.finish_on)}
            </td>
            <td onClick={() => history.push(`/contracts/${contract_id}/parcel_borderos/${row.id}`)}>
              {formatMoney(row.parcels.map((i) => parseMoney(i.cost)).reduce((a, b) => a + b, 0))}
            </td>
            <td onClick={() => history.push(`/contracts/${contract_id}/parcel_borderos/${row.id}`)}>
              {formatMoney(row.parcels.map((i) => parseMoney(i.sp)).reduce((a, b) => a + b, 0))}
            </td>
            <td onClick={() => history.push(`/contracts/${contract_id}/parcel_borderos/${row.id}`)}>{row.comment}</td>
            <td>
              <Edit url={`/contracts/${row.contract_id}/parcel_borderos/${row.id}/edit`} />
              <Destroy
                action={() =>
                  afterDestroyFetch(
                    store,
                    { id: row.id, contract_id: row.contract_id },
                    { contract_id: row.contract_id }
                  )
                }
                disabled={row.status.value.toString() === '1'}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot className="thead-light">
        <tr>
          <th colSpan="9">
            Выбрано: {totalSelectedRow} | Страховая сумма: {formatMoney(cost_sum)} | Страховая премия:{" "}
            {formatMoney(sp_sum)}
          </th>
          <th>
            <button
              type="button"
              className="btn btn-danger"
              title="Сбросить выделение"
              onClick={resetSelected}
              disabled={totalSelectedRow === 0}
            >
              <i className="fas fa-sync-alt"></i>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  )
}

export default withRouter(observer(Table))
