import React, { Fragment, useContext } from "react"
import { AddressInput, Button, CurrencyInput, RadioButtonGroup, RadioInput, TextInput } from "components/UI"
import NestedFields from "components/UI/NestedFields"
import ContractFormContext from "contexts/ContractFormContext"
import { getIn } from "formik"
import Property from "./Property"
// import { ReactDadata } from 'react-dadata'

const ContractProperty = ({ prefix = null, index, form }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const { contractStore } = useContext(ContractFormContext)
  const { contractPropertyKindOptions, contractPropertyInflectorOptions } = contractStore.filtersOptions

  return (
    <Fragment>
      <RadioButtonGroup
        label="Тип"
        name={path("kind.id")}
        disabled={
          form.values.status.value.toString() === "1" ||
          (form.values.has_additional && form.values.contract_properties[index].id)
        }
      >
        {contractPropertyKindOptions.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {/* <TextInput
        name={path("beneficiary`}
        label="Выгодоприобретатель"
        disabled={form.values.status.value.toString() === "1"}
      /> */}
      <AddressInput
        name={path("territory")}
        label="Территория страхования"
        disabled={
          form.values.status.value.toString() === "1" ||
          (form.values.has_additional && form.values.contract_properties[index].id)
        }
        required={form.values.status.value.toString() === "1"}
      />
      <RadioButtonGroup
        label="Тип"
        name={path("inflector.id")}
        disabled={
          form.values.status.value.toString() === "1" ||
          (form.values.has_additional && form.values.contract_properties[index].id)
        }
      >
        {contractPropertyInflectorOptions.map(({ label, value }) => (
          <RadioInput
            key={`inflector_${value}`}
            label={label}
            id={value}
            onChange={(e) => {
              form.setFieldValue(path("inflector.id"), e.target.value)
              form.setFieldValue(path("properties"), getIn(form.values, path("properties")).slice(0, 1))
            }}
          />
        ))}
      </RadioButtonGroup>
      {/* <FieldArray name={path("properties`} component={Properties} /> */}
      <NestedFields
        name={path("properties")}
        label="Объекты страхования"
        component={Property}
        defaultValues={{ object: null, ss_cost: 0 }}
        disabledDestroy={({ prefix }) => form.values.has_additional && getIn(form.values, prefix).id}
      />
      <CurrencyInput
        name={path("total_ss_cost")}
        label="Общая страховая сумма (итого)"
        disabled={form.values.status.value.toString() === "1"}
      />
      <TextInput
        name={path("terms")}
        label="Условия страхования"
        disabled={form.values.status.value.toString() === "1"}
        required={form.values.status.value.toString() === "1"}
      />
      <CurrencyInput name={path("franchise")} label="Франшиза" disabled={form.values.status.value.toString() === "1"} />
    </Fragment>
  )
}
export default ContractProperty
