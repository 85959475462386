import React, { useState } from "react"
import { withRouter } from "react-router"
import { historyPushToStore } from "utils/historyPushWithStore"

const getSearchParams = () => new URLSearchParams(window.location.search)

const getParamValue = name => getSearchParams().get(name) || ""

const getSortDirection = (name, thirdDireaction) => {
  switch (getParamValue(name)) {
    case "asc":
      return "desc"
    case "desc":
      return thirdDireaction
    default:
      return "asc"
  }
}

const getSortClass = (direction) => {
  switch (direction) {
    case "asc":
      return "fas fa-sort-amount-up-alt"
    case "desc":
      return "fas fa-sort-amount-down-alt"
    default:
      return ""
  }
}

const Sort = ({ name, onSortChange, children, history, thirdDireaction = "" }) => {
  const [className, setClassName] = useState(getSortClass(getParamValue(name)))

  const onClick = () => {
    const searchParams = getSearchParams()
    const direction = getSortDirection(name, thirdDireaction)
    setClassName(getSortClass(direction))
    searchParams.set(name, direction)
    history.push({
      search: searchParams.toString()
    })
    const { pathname, search } = window.location
    historyPushToStore(history, pathname, search)
    onSortChange()
  }

  return (
    <button type="button" className="btn btn-link btn-action" onClick={onClick}>
      <i className={className}></i>
      &nbsp;
      {children}
    </button>
  )
}

export default withRouter(Sort)
