import React from "react"
import compose from "utils/compose"
import { TextInput, DateInput, ResetButton, Submit } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import ClientSelect from "components/Common/ClientSelect"
import InsuranceKindSelect from "components/Common/InsuranceKindSelect"

const Filter = ({ defaultFilter, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <ClientSelect name="by_client.id" />
        </div>
        <div className="col-3">
          <InsuranceKindSelect name="by_insurance_kind.id" />
        </div>
        <div className="col-3">
          <DateInput name="by_payment_on_start" label="Период с" />
        </div>
        <div className="col-3">
          <DateInput name="by_payment_on_finish" label="Период по" />
        </div>
        <div className="col-3">
          <TextInput name="by_number" label="Номер договора" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton defaultFilter={defaultFilter} />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler({ ...formikBag.props.defaultFilter, ...values }, formikBag)
  }),
  observer
)(Filter)
