import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Button, Card, Modal, ModalHeader, ModalBody, ModalFooter, Paginate } from "components/UI"
import AgencyContractStore from "stores/AgencyContractStore/List"
import Table from "./Table"
// import Filter from "./Filter"

const agencyContractStore = AgencyContractStore.create()

const RelationModal = ({ isOpen, closeModal, name, number, setFieldValue, insurer_id, channel_id }) => {
  const [selected, setSelected] = useState({ id: null, number: null })

  useEffect(() => {
    if (isOpen) {
      agencyContractStore.fetch({
        filter: { by_insurer_id: insurer_id, by_channel_id: channel_id, by_document_kind_id: 4 }
      })
    }
  }, [isOpen])

  const setRelation = () => {
    setFieldValue(name, selected)
    closeModal()
  }

  const handleSubmit = (values) => agencyContractStore.fetch({ filter: { ...values, by_number_not: number } })

  // console.log(agencyContractStore.meta).filter

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader>Выбрать договор</ModalHeader>
      <ModalBody>
        {/* <Card> */}
        {/* <Filter store={agencyContractStore} handleSubmit={handleSubmit} /> */}
        {/* </Card> */}
        <Table store={agencyContractStore} selected={selected} setSelected={setSelected} />
        <Paginate store={agencyContractStore} params={agencyContractStore.meta.filter} saveToStore={false} />
        <Button onClick={setRelation}>Сохранить</Button>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} variant="secondary">
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default observer(RelationModal)
