import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"
import customDate from "types/customDate"

const ActivityKind = types.model("ActivityKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const InsuranceKind = types.model("InsuranceKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Email = types.model("Email", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const Phone = types.model("Phone", {
  id: types.maybeNull(types.integer),
  value: types.maybeNull(types.string)
})

const Contact = types.model("Contact", {
  id: types.maybeNull(types.integer),
  l_name: types.maybeNull(types.string),
  f_name: types.maybeNull(types.string),
  p_name: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  insurance_kinds: types.maybeNull(types.array(InsuranceKind)),
  emails: types.maybeNull(types.array(Email)),
  phones: types.maybeNull(types.array(Phone))
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  short_name: types.maybeNull(types.string)
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  // subdivision: types.maybeNull(Subdivision)
})

const Holding = types.model("Holding", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Industry = types.model("Industry", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Strength = types.model("Strength", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const DocumentKind = types.model("DocumentKind", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Attachment = types.model("Attachment", {
  id: types.maybeNull(types.integer),
  file: types.frozen()
})

const ProviderDocument = types.model("ProviderDocument", {
  id: types.maybeNull(types.integer),
  comment: types.maybeNull(types.string),
  attachments: types.maybeNull(types.array(Attachment)),
  document_kind: types.maybeNull(DocumentKind),
  document_kind_other: types.maybeNull(types.string)
})

const Provider = types.model("Provider", {
  id: types.maybeNull(types.integer),
  kind: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  full_name: types.maybeNull(types.string),
  organization_name: types.maybeNull(types.string),
  ownership_kind: types.maybeNull(newCustomEnum),
  payment_kind: types.maybeNull(newCustomEnum),
  payment_kind_other: types.maybeNull(types.string),
  contract_number: types.maybeNull(types.string),
  contract_on: types.maybeNull(customDate),
  inn: types.maybeNull(types.string),
  director: types.maybeNull(types.string),
  kpp: types.maybeNull(types.string),
  ogrn: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  legal_address: types.maybeNull(types.string),
  bank: types.maybeNull(types.string),
  payment_account: types.maybeNull(types.string),
  bik: types.maybeNull(types.string),
  correspondent_account: types.maybeNull(types.string),

  f_name: types.maybeNull(types.string),
  l_name: types.maybeNull(types.string),
  p_name: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  card_number: types.maybeNull(types.string),
  emails: types.maybeNull(types.array(Email)),
  phones: types.maybeNull(types.array(Phone)),
  contacts: types.maybeNull(types.array(Contact)),
  created_at: types.maybeNull(customDate),
  user: types.maybeNull(User),
  subdivision: types.maybeNull(Subdivision),
  holding: types.maybeNull(Holding),
  industry: types.maybeNull(Industry),
  // strength: types.maybeNull(Strength),
  activity_kind: types.maybeNull(ActivityKind),
  documents: types.maybeNull(types.array(ProviderDocument))
})

export { Provider }
