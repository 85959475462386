import React, { Fragment } from "react"
import { CurrencyInput, TextInput } from "components/UI"
import parseMoney from "utils/parseMoney"
import { getIn } from "formik"
import getRoot from "utils/getRoot"

const Property = ({ prefix = null, index, form }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const root = getRoot(prefix)
  const caclulateTotalSsCost = (properties, currentValue) => {
    const total_ss_cost = properties
      .filter((i, _index) => _index !== index)
      .reduce((a, b) => a + parseMoney(b.ss_cost), parseMoney(currentValue))
      .toFixed(2)
    form.setFieldValue(root.replace(/properties$/, "total_ss_cost"), total_ss_cost)
  }
  return (
    <Fragment>
      <TextInput
        name={path("object")}
        label="Объект страхования"
        disabled={
          form.values.status.value.toString() === "1" || (form.values.has_additional && getIn(form.values, prefix).id)
        }
        required={form.values.status.value.toString() === "1"}
      />
      <CurrencyInput
        name={path("ss_cost")}
        label="Страховая сумма"
        onChange={(e) => {
          form.setFieldValue(path("ss_cost"), e.target.value)
          caclulateTotalSsCost(getIn(form.values, root), e.target.value)
        }}
        disabled={form.values.status.value.toString() === "1"}
        required={form.values.status.value.toString() === "1"}
      />
    </Fragment>
  )
}
export default Property
