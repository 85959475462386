import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import UserSalePlansStore from "stores/UserSalePlanStore/List"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { Button, Card, BreadcrumbItem } from "components/UI"
import BudgetStore from "stores/BudgetStore/One"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/UserSalePlans/components/Tabs"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import instance from "connection/instance"
import zipWith from "lodash.zipwith"

const budgetStore = BudgetStore.create()
const userSalePlanStore = UserSalePlansStore.create()

const List = (props) => {
  const { budget_id } = props.match.params
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    userSalePlanStore.fetchAll({ budget_id })
  }, [])

  const [fact, setFact] = useState([])
  const [factWithoutSubagents, setFactWithoutSubagents] = useState([])
  const [factWithSubagents, setFactWithSubagents] = useState([])
  const [factClear, setFactClear] = useState([])
  const [factOr, setFactOr] = useState([])
  useEffect(() => {
    instance.get(`/budgets/${budget_id}/user_sale_plans/fact`).then(({ data }) => {
      setFact(data)
    })
    instance.get(`/budgets/${budget_id}/user_sale_plans/fact_without_subagents`).then(({ data }) => {
      setFactWithoutSubagents(data)
    })
    instance.get(`/budgets/${budget_id}/user_sale_plans/fact_with_subagents`).then(({ data }) => {
      setFactWithSubagents(data)
    })
    instance.get(`/budgets/${budget_id}/user_sale_plans/fact_clear`).then(({ data }) => {
      setFactClear(data)
    })
    instance.get(`/budgets/${budget_id}/user_sale_plans/fact_or`).then(({ data }) => {
      setFactOr(data)
    })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>План продаж</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: "3500px" }}>
          <thead>
            <tr>
              <th colSpan={19}>План продаж (план)</th>
            </tr>
            <tr>
              <th rowSpan={3}>#</th>
              <th rowSpan={3}>ФИО сотрудника</th>
              <th rowSpan={2}>ИТОГО {budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>
                {formatMoney(
                  fact.map((i) => i.costs.reduce((a, b) => a + parseMoney(b), 0)).reduce((a, b) => a + parseMoney(b), 0)
                )}
              </th>
              {Array(12)
                .fill(null)
                .map((_, index) => (
                  <Fragment key={`item=${index}`}>
                    {index % 3 === 0 && (
                      <th style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          fact
                            .map((i) => i.costs.slice(index, index + 3).reduce((a, b) => a + parseMoney(b), 0))
                            .reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </th>
                    )}
                    <th>{formatMoney(fact.map((i) => i.costs[index]).reduce((a, b) => a + parseMoney(b), 0))}</th>
                  </Fragment>
                ))}
            </tr>
          </thead>
          <tbody>
            {fact.map((values) => (
              <tr>
                <td>{values.id}</td>
                <td>{values.user.name}</td>
                <td>{formatMoney(values.costs.reduce((a, b) => a + parseMoney(b), 0))}</td>
                {values.costs.map((cost, costIndex) => (
                  <Fragment key={`item=${costIndex}`}>
                    {costIndex % 3 === 0 && (
                      <td style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </td>
                    )}
                    <td>{formatMoney(cost)}</td>
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th colSpan={19}>План продаж (ФАКТ) / без учета субагентов</th>
            </tr>
            <tr>
              <th rowSpan={3}>#</th>
              <th rowSpan={3}>ФИО сотрудника</th>
              <th rowSpan={2}>ИТОГО {budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>
                {formatMoney(
                  factWithoutSubagents
                    .map((i) => i.costs.reduce((a, b) => a + parseMoney(b), 0))
                    .reduce((a, b) => a + parseMoney(b), 0)
                )}
              </th>
              {Array(12)
                .fill(null)
                .map((_, index) => (
                  <Fragment key={`item=${index}`}>
                    {index % 3 === 0 && (
                      <th style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          factWithoutSubagents
                            .map((i) => i.costs.slice(index, index + 3).reduce((a, b) => a + parseMoney(b), 0))
                            .reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </th>
                    )}
                    <th>
                      {formatMoney(
                        factWithoutSubagents.map((i) => i.costs[index]).reduce((a, b) => a + parseMoney(b), 0)
                      )}
                    </th>
                  </Fragment>
                ))}
            </tr>
          </thead>
          <tbody>
            {factWithoutSubagents.map((values) => (
              <tr>
                <td>{values.id}</td>
                <td>{values.user.name}</td>
                <td>{formatMoney(values.costs.reduce((a, b) => a + parseMoney(b), 0))}</td>
                {values.costs.map((cost, costIndex) => (
                  <Fragment key={`item=${costIndex}`}>
                    {costIndex % 3 === 0 && (
                      <td style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </td>
                    )}
                    <td>{formatMoney(cost)}</td>
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th colSpan={19}>ОР (факт)</th>
            </tr>
            <tr>
              <th rowSpan={3}>#</th>
              <th rowSpan={3}>ФИО сотрудника</th>
              <th rowSpan={2}>ИТОГО {budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>
                {formatMoney(
                  factOr
                    .map((i) => i.costs.reduce((a, b) => a + parseMoney(b), 0))
                    .reduce((a, b) => a + parseMoney(b), 0)
                )}
              </th>
              {Array(12)
                .fill(null)
                .map((_, index) => (
                  <Fragment key={`item=${index}`}>
                    {index % 3 === 0 && (
                      <th style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          factOr
                            .map((i) => i.costs.slice(index, index + 3).reduce((a, b) => a + parseMoney(b), 0))
                            .reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </th>
                    )}
                    <th>{formatMoney(factOr.map((i) => i.costs[index]).reduce((a, b) => a + parseMoney(b), 0))}</th>
                  </Fragment>
                ))}
            </tr>
          </thead>
          <tbody>
            {factOr.map((values) => (
              <tr>
                <td>{values.id}</td>
                <td>{values.user.name}</td>
                <td>{formatMoney(values.costs.reduce((a, b) => a + parseMoney(b), 0))}</td>
                {values.costs.map((cost, costIndex) => (
                  <Fragment key={`item=${costIndex}`}>
                    {costIndex % 3 === 0 && (
                      <td style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </td>
                    )}
                    <td>{formatMoney(cost)}</td>
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th colSpan={19}>Суб-агенты (ФАКТ)</th>
            </tr>
            <tr>
              <th rowSpan={3}>#</th>
              <th rowSpan={3}>ФИО сотрудника</th>
              <th rowSpan={2}>ИТОГО {budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>
                {formatMoney(
                  factWithSubagents
                    .map((i) => i.costs.reduce((a, b) => a + parseMoney(b), 0))
                    .reduce((a, b) => a + parseMoney(b), 0)
                )}
              </th>
              {Array(12)
                .fill(null)
                .map((_, index) => (
                  <Fragment key={`item=${index}`}>
                    {index % 3 === 0 && (
                      <th style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          factWithSubagents
                            .map((i) => i.costs.slice(index, index + 3).reduce((a, b) => a + parseMoney(b), 0))
                            .reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </th>
                    )}
                    <th>
                      {formatMoney(factWithSubagents.map((i) => i.costs[index]).reduce((a, b) => a + parseMoney(b), 0))}
                    </th>
                  </Fragment>
                ))}
            </tr>
          </thead>
          <tbody>
            {factWithSubagents.map((values) => (
              <tr>
                <td>{values.id}</td>
                <td>{values.user.name}</td>
                <td>{formatMoney(values.costs.reduce((a, b) => a + parseMoney(b), 0))}</td>
                {values.costs.map((cost, costIndex) => (
                  <Fragment key={`item=${costIndex}`}>
                    {costIndex % 3 === 0 && (
                      <td style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </td>
                    )}
                    <td>{formatMoney(cost)}</td>
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th colSpan={19}>План продаж (ФАКТ) / чистый</th>
            </tr>
            <tr>
              <th rowSpan={3}>#</th>
              <th rowSpan={3}>ФИО сотрудника</th>
              <th rowSpan={2}>ИТОГО {budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>Факт 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>
                {formatMoney(
                  factClear
                    .map((i) => i.costs.reduce((a, b) => a + parseMoney(b), 0))
                    .reduce((a, b) => a + parseMoney(b), 0)
                )}
              </th>
              {Array(12)
                .fill(null)
                .map((_, index) => (
                  <Fragment key={`item=${index}`}>
                    {index % 3 === 0 && (
                      <th style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          factClear
                            .map((i) => i.costs.slice(index, index + 3).reduce((a, b) => a + parseMoney(b), 0))
                            .reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </th>
                    )}
                    <th>{formatMoney(factClear.map((i) => i.costs[index]).reduce((a, b) => a + parseMoney(b), 0))}</th>
                  </Fragment>
                ))}
            </tr>
          </thead>
          <tbody>
            {factClear.map((values) => (
              <tr>
                <td>{values.id}</td>
                <td>{values.user.name}</td>
                <td>{formatMoney(values.costs.reduce((a, b) => a + parseMoney(b), 0))}</td>
                {values.costs.map((cost, costIndex) => (
                  <Fragment key={`item=${costIndex}`}>
                    {costIndex % 3 === 0 && (
                      <td style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </td>
                    )}
                    <td>{formatMoney(cost)}</td>
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
          <thead>
            <tr>
              <th colSpan={20}>&nbsp;</th>
            </tr>
            <tr>
              <th colSpan={20}>План продаж (план-факт) накопительным</th>
            </tr>
            <tr>
              <th>#</th>
              <th>ФИО сотрудника</th>
              <th>ИТОГО {budgetStore.data.year.toString().slice(-2)}</th>
              <th>3 мес.</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>6 мес.</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>9 мес.</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>12 мес.</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
              <th></th>
            </tr>
            <tr>
              <th colSpan={2} rowSpan={3} style={{ verticalAlign: "baseline" }}>
                План подразделения (итого)
              </th>
              <th>
                {formatMoney(
                  fact.map((values) => values.costs.reduce((a, b) => a + parseMoney(b), 0)).reduce((a, b) => a + b, 0)
                )}
              </th>
              {zipWith(
                ...fact.map((values) =>
                  values.costs
                    .map((cost, costIndex) => {
                      const returnArray = []
                      if (costIndex % 3 === 0) {
                        returnArray.push(
                          values.costs.slice(0, Math.max(0, costIndex)).reduce((a, b) => a + parseMoney(b), 0) +
                            values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                        )
                      }
                      returnArray.push(
                        values.costs.slice(0, costIndex).reduce((a, b) => a + parseMoney(b), 0) + parseMoney(cost)
                      )
                      return returnArray
                    })
                    .flat()
                ),
                (a, b) => parseMoney(a) + parseMoney(b)
              ).map((i) => (
                <th>{formatMoney(i)}</th>
              ))}
               <th>План продаж (план)</th>
            </tr>
            <tr>
              <th>
                {formatMoney(
                  factClear
                    .map((values) => values.costs.reduce((a, b) => a + parseMoney(b), 0))
                    .reduce((a, b) => a + b, 0)
                )}
              </th>
              {zipWith(
                ...factClear.map((values) =>
                  values.costs
                    .map((cost, costIndex) => {
                      const returnArray = []
                      if (costIndex % 3 === 0) {
                        returnArray.push(
                          values.costs.slice(0, Math.max(0, costIndex)).reduce((a, b) => a + parseMoney(b), 0) +
                            values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                        )
                      }
                      returnArray.push(
                        values.costs.slice(0, costIndex).reduce((a, b) => a + parseMoney(b), 0) + parseMoney(cost)
                      )
                      return returnArray
                    })
                    .flat()
                ),
                (a, b) => parseMoney(a) + parseMoney(b)
              ).map((i) => (
                <th>{formatMoney(i)}</th>
              ))}
              <th>План продаж (факт) / чистый</th>
            </tr>
            <tr>
              <th>
                {(
                  (factClear
                    .map((values) => values.costs.reduce((a, b) => a + parseMoney(b), 0))
                    .reduce((a, b) => a + b, 0) /
                    fact
                      .map((values) => values.costs.reduce((a, b) => a + parseMoney(b), 0))
                      .reduce((a, b) => a + b, 0)) *
                  100
                ).toFixed(3)}
                %
              </th>
              {zipWith(
                ...factClear.map((values) =>
                  values.costs
                    .map((cost, costIndex) => {
                      const returnArray = []
                      if (costIndex % 3 === 0) {
                        returnArray.push(
                          values.costs.slice(0, Math.max(0, costIndex)).reduce((a, b) => a + parseMoney(b), 0) +
                            values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                        )
                      }
                      returnArray.push(
                        values.costs.slice(0, costIndex).reduce((a, b) => a + parseMoney(b), 0) + parseMoney(cost)
                      )
                      return returnArray
                    })
                    .flat()
                ),
                (a, b) => parseMoney(a) + parseMoney(b)
              ).map((i, index) => (
                <th>{((i / zipWith(
                  ...fact.map((values) =>
                    values.costs
                      .map((cost, costIndex) => {
                        const returnArray = []
                        if (costIndex % 3 === 0) {
                          returnArray.push(
                            values.costs.slice(0, Math.max(0, costIndex)).reduce((a, b) => a + parseMoney(b), 0) +
                              values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                          )
                        }
                        returnArray.push(
                          values.costs.slice(0, costIndex).reduce((a, b) => a + parseMoney(b), 0) + parseMoney(cost)
                        )
                        return returnArray
                      })
                      .flat()
                  ),
                  (a, b) => parseMoney(a) + parseMoney(b)
                )[index]) * 100).toFixed(3)}%</th>
              ))}
              <th>% исп.</th>
            </tr>
          </thead>
          <tbody>
            {fact.map((values, index) => (
              <Fragment>
                <tr>
                  <td rowSpan={3}>{values.id}</td>
                  <td rowSpan={3}>{values.user.name}</td>
                  <td>{formatMoney(values.costs.reduce((a, b) => a + parseMoney(b), 0))}</td>
                  {values.costs.map((cost, costIndex) => (
                    <Fragment key={`item=${costIndex}`}>
                      {costIndex % 3 === 0 && (
                        <td style={{ verticalAlign: "middle", width: "200px" }}>
                          {formatMoney(
                            values.costs.slice(0, Math.max(0, costIndex)).reduce((a, b) => a + parseMoney(b), 0) +
                              values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                          )}
                        </td>
                      )}
                      <td>
                        {formatMoney(
                          values.costs.slice(0, costIndex).reduce((a, b) => a + parseMoney(b), 0) + parseMoney(cost)
                        )}
                      </td>
                    </Fragment>
                  ))}
                  <td>План продаж (план)</td>
                </tr>
                {factClear[index] && (
                  <tr>
                    <td>{formatMoney(factClear[index].costs.reduce((a, b) => a + parseMoney(b), 0))}</td>
                    {factClear[index].costs.map((cost, costIndex) => (
                      <Fragment key={`item=${costIndex}`}>
                        {costIndex % 3 === 0 && (
                          <td style={{ verticalAlign: "middle", width: "200px" }}>
                            {formatMoney(
                              factClear[index].costs
                                .slice(0, Math.max(0, costIndex))
                                .reduce((a, b) => a + parseMoney(b), 0) +
                                factClear[index].costs
                                  .slice(costIndex, costIndex + 3)
                                  .reduce((a, b) => a + parseMoney(b), 0)
                            )}
                          </td>
                        )}
                        <td>
                          {formatMoney(
                            factClear[index].costs.slice(0, costIndex).reduce((a, b) => a + parseMoney(b), 0) +
                              parseMoney(cost)
                          )}
                        </td>
                      </Fragment>
                    ))}
                    <td>План продаж (факт) / чистый</td>
                  </tr>
                )}
                {factClear[index] && (
                  <tr>
                    <td>
                      {(
                        (factClear[index].costs.reduce((a, b) => a + parseMoney(b), 0) /
                          values.costs.reduce((a, b) => a + parseMoney(b), 0)) *
                        100
                      ).toFixed(3)}
                      %
                    </td>
                    {factClear[index].costs.map((cost, costIndex) => (
                      <Fragment key={`item=${costIndex}`}>
                        {costIndex % 3 === 0 && (
                          <td style={{ verticalAlign: "middle", width: "200px" }}>
                            {(
                              ((factClear[index].costs
                                .slice(0, Math.max(0, costIndex))
                                .reduce((a, b) => a + parseMoney(b), 0) +
                                factClear[index].costs
                                  .slice(costIndex, costIndex + 3)
                                  .reduce((a, b) => a + parseMoney(b), 0)) /
                                (values.costs.slice(0, Math.max(0, costIndex)).reduce((a, b) => a + parseMoney(b), 0) +
                                  values.costs
                                    .slice(costIndex, costIndex + 3)
                                    .reduce((a, b) => a + parseMoney(b), 0))) *
                              100
                            ).toFixed(3)}
                            %
                          </td>
                        )}
                        <td>
                          {(
                            ((factClear[index].costs.slice(0, costIndex).reduce((a, b) => a + parseMoney(b), 0) +
                              parseMoney(cost)) /
                              (values.costs.slice(0, costIndex).reduce((a, b) => a + parseMoney(b), 0) +
                                parseMoney(values.costs[costIndex]))) *
                            100
                          ).toFixed(3)}
                          %
                        </td>
                      </Fragment>
                    ))}
                    <td>% исп.</td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default observer(List)
