import React, { Fragment, useContext, useState } from "react"
import { TextInput, PhoneInput, AddressInput, RadioButtonGroup, RadioInput, DateInput, Button } from "components/UI"
import OrganizationSelect from "components/Common/OrganizationSelect"
import RecipientCargos from "./RecipientCargos"
import ContractFormContext from "contexts/ContractFormContext"
import DestinationModal from "./DestinationModal"
import addDays from "date-fns/addDays"
import AuthStoreContext from "contexts/AuthStoreContext"

const Recipient = ({ prefix = null, form, index, ...props }) => {
  const [isOpenTo, setIsOpenTo] = useState(false)
  const closeToModal = () => setIsOpenTo(false)

  const setTo = (selected) => {
    form.setFieldValue(`recipients.${index}.to_organization`, selected.organization)
    form.setFieldValue(`recipients.${index}.to_address`, selected.address)
    form.setFieldValue(`recipients.${index}.to_comment`, selected.comment)
    form.setFieldValue(`recipients.${index}.to_contact`, selected.contact)
    form.setFieldValue(`recipients.${index}.to_phone`, selected.phone)
  }

  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const { courierStore } = useContext(ContractFormContext)
  const { to_time_kinds } = courierStore.filtersOptions

  const { data: currentUser } = useContext(AuthStoreContext)
  const isDisabled =
    (["2", "3", "4", "5"].includes(form.values.status.value.toString()) &&
      currentUser.subdivision.id.toString() != "7" && currentUser.id.toString() != "48") ||
    (["5"].includes(form.values.status.value.toString()) && (currentUser.subdivision.id.toString() == "7" || currentUser.id.toString() == "48"))
  return (
    <Fragment>
      <h5>Получатель {index + 1}</h5>
      <Button onClick={() => setIsOpenTo(true)} disabled={isDisabled}>
        Типовой адресат
      </Button>
      &nbsp;
      <br />
      <br />
      <OrganizationSelect name={path("to_organization")} isDisabled={isDisabled} />
      <TextInput name={path("to_contact")} label="Имя адресата (контактное лицо)" required disabled={isDisabled} />
      <PhoneInput name={path("to_phone")} label="Телефон" required disabled={isDisabled} />
      <AddressInput name={path("to_address")} label="Адрес" required disabled={isDisabled} />
      <TextInput name={path("to_comment")} label="Комментарий" disabled={isDisabled} />
      <DateInput
        name={path("to_date_on")}
        label="Дата"
        required
        minDate={currentUser.id == 5 ? new Date(1970, 1, 1) : addDays(new Date(), 1)}
        disabled={isDisabled}
      />
      <TextInput name={path("to_start")} label="Время с" disabled={isDisabled} />
      <TextInput name={path("to_finish")} label="По" disabled={isDisabled} />
      <RadioButtonGroup label="Временные границы" name={path("to_time_kind.value")} disabled={isDisabled}>
        {to_time_kinds.map(({ label, value }) => (
          <RadioInput key={`to_time_kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <RecipientCargos name={path("recipient_cargos")} disabled={isDisabled} />
      <DestinationModal isOpen={isOpenTo} onSet={setTo} closeModal={closeToModal} />
    </Fragment>
  )
}
export default Recipient
