import React from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import { TextArea, AddressInput, InnInput, TextInput, Submit } from "components/UI"
import SpecializationsSelect from "components/Common/SpecializationsSelect"

const Form = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <TextInput name="name" label="Наименование (полное)" required />
    <TextInput name="short_name" label="Наименование (краткое)" required />
    <InnInput name="inn" label="ИНН" required />
    <AddressInput name="address" label="Юридический адрес" />
    <TextInput name="site" label="Сайт" capitalizeValue={false} />
    <SpecializationsSelect required />
    <TextArea name="comment" label="Комментарий" />

    {/* <TextInput name="phone" label="Телефон" />
    <FieldArray name="contacts" component={Contacts} /> */}
    <Submit>Сохранить</Submit>
  </form>
)

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) =>
      formikBag.props.handleSubmit(values, formikBag).catch(() => formikBag.setSubmitting(false))
  })
)(Form)
