import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"

const BsoBatch = types.model("BsoBatch", {
  id: types.maybeNull(types.integer),
  bso_kind_id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
  status: types.maybeNull(newCustomEnum)
})

export { BsoBatch }
