import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { Courier } from "./Courier"
import newCustomEnum from "types/newCustomEnum"
import newToOptions from "utils/newToOptions"
import customEnum from "types/customEnum"
import customDate from "types/customDate"
import format from "date-fns/format"

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, []),
  urgencies: types.array(newCustomEnum, []),
  delivery_services: types.array(newCustomEnum, []),
  by_subdivision: types.maybeNull(customEnum),
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Filter = types.model("Filter", {
  by_from_organization: types.maybeNull(types.string),
  by_to_organization: types.maybeNull(types.string),
  by_status: types.maybeNull(types.integer),
  by_urgency: types.maybeNull(types.integer),
  by_from_date_on_from: types.maybeNull(customDate),
  by_from_date_on_to: types.maybeNull(customDate),
  by_delivery_service: types.maybeNull(types.integer),
  by_subdivision: types.maybeNull(customEnum),
  by_user: types.maybeNull(customEnum),
  by_provider: types.maybeNull(customEnum)
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number),
})

const metaDefaultValue = {
  filters_options: {
    statuses: [],
    urgencies: [],
    delivery_services: [],
    by_subdivision: { id: null, name: null },
  },
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const CourierStore = types
  .model("Courier", {
    data: types.array(Courier, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { statuses, urgencies, delivery_services } = self.meta.filters_options
      return { statuses: newToOptions(statuses), urgencies: newToOptions(urgencies), delivery_services: newToOptions(delivery_services) }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_from_date_on_from, by_from_date_on_to } = params.filter
        if (by_from_date_on_from) {
          params.filter = { ...params.filter, by_from_date_on_from: format(by_from_date_on_from, "yyyy-MM-dd") }
        }
        if (by_from_date_on_to) {
          params.filter = { ...params.filter, by_from_date_on_to: format(by_from_date_on_to, "yyyy-MM-dd") }
        }
      }

      return instance
        .get("/couriers", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/couriers/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default CourierStore
