import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import OrganizationStore from "stores/OrganizationStore/List"

const organizationStore = OrganizationStore.create()

const OrganizationSelect = ({ name = "organization", required = false, label = "Организация", ...props }) => {
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    organizationStore.fetch()
  }, [])

  const getOptions = () => {
    const { organizationOptions } = organizationStore

    const { id: value, name: label } = getIn(values, name.replace(".id", "")) || { id: null, name: null }
    if (value) {
      return organizationOptions.concat([{ label, value }])
    }
    return organizationOptions
  }

  const organizationOptions = getOptions()

  const loadOrganizationOptions = (value) =>
    organizationStore
      .fetch({ filter: { by_name: value }, limit: 0 })
      .then(({ organizationOptions }) => organizationOptions)


  const value = getIn(values, name, false)
  const _value = value && organizationOptions.find((option) => option.value.toString() === String(value.id).toString())

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={organizationOptions}
        required={required}
        isSearchable
        loadOptions={loadOrganizationOptions}
        value={_value}
        onChange={(option) => {
          setFieldValue(name, {
            id: option?.value,
            name: option?.label,
            organizationable_id: option?.data?.organizationable_id,
            organizationable_type: option?.data?.organizationable_type
          })
        }}
        {...props}
      />
    </div>
  )
}

export default observer(OrganizationSelect)
