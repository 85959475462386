import React from "react"
import { observer } from "mobx-react"
import { useFormikContext } from "formik"
import { DateInput } from "components/UI"
import caclulateDurationInDays from "utils/caclulateDurationInDays"

const RefundOnInput = ({ disabled = false, required = false }) => {
  const {
    values: { date_on },
    setFieldValue
  } = useFormikContext()

  const onChange = (refund_on) => {
    setFieldValue("refund_on", refund_on)
    setFieldValue("duration", caclulateDurationInDays(date_on, refund_on))
  }

  return (
    <DateInput
      name="refund_on"
      label="Дата возврата"
      required={required}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

export default observer(RefundOnInput)
