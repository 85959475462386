import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import Breadcrumbs from "pages/Reports/components/Breadcrumbs"
import { BreadcrumbItem, Card } from "components/UI"
import Filter from "./components/Filter"
import Table from "./components/Table"
import ReportStore from "stores/ReportStore/Couriers"
import { historyPushFromStore } from "utils/historyPushWithStore"

const reportStore = ReportStore.create()

const Sales = (props) => {
  useEffect(() => {
    historyPushFromStore(props.history, window.location.pathname)
    reportStore.fetch({ filter: { year: new Date().getFullYear() } })
  }, [])
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem active>План факт курьеры</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Filter store={reportStore} />
      </Card>
      <Table store={reportStore} />
    </Fragment>
  )
}

export default observer(Sales)
