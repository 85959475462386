import React, { Fragment, useContext } from "react"
import { DateInput, TextInput, RadioButtonGroup, RadioInput, SelectInput } from "components/UI"
import Attachments from "./Attachments"
import ContactFormContext from "contexts/ContactFormContext"
import ContractFormContext from "contexts/ContractFormContext"
import { useFormikContext, getIn } from "formik"
import DocumentKindSelect from "components/Common/DocumentKindSelect"

const Document = ({ prefix = null }) => {
  const path = (selgment) => [prefix, selgment].filter(Boolean).join(".")
  const { contractDocumentStore } = useContext(ContactFormContext)
  const { contractStore } = useContext(ContractFormContext)
  const { kinds, submissions } = contractDocumentStore.filtersOptions
  const { values } = useFormikContext()
  return (
    <Fragment>
      <DocumentKindSelect name={path("document_kind.id")} />
      {String(getIn(values, path("document_kind.id"))) === "6" && (
        <SelectInput
          name={path("version_id")}
          label="№ Доп. соглашения / Аддендума"
          options={contractStore.versions.map((i) => {
            return {
              label: i.additional_agreement_number,
              value: i.id
            }
          })}
        />
      )}
      <RadioButtonGroup label="Вид документа" name={path("kind.value")}>
        {kinds.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Геолокация документа" name={path("submission.value")}>
        {submissions.map(({ label, value }) => (
          <RadioInput key={`submission_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {getIn(values, path("submission.value")).toString() === "2" && (
        <TextInput name={path("submission_other")} label="Иное" />
      )}
      <TextInput name={path("submission_holder")} label="Кому сдан документ" />
      <DateInput name={path("submission_on")} label="Дата сдачи документа" />
      <TextInput name={path("comment")} label="Комментарий" />
      {/* <Attachments name={path("attachments")} /> */}
    </Fragment>
  )
}
export default Document
