import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import CheckPermissions from "components/CheckPermissions"

const SalePlans = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("sale_plans")}>
    <Switch>
      <Route path="/sale_plans/:id/edit" component={Edit} />
      <Route path="/sale_plans/new" component={Add} />
      <Route path="/sale_plans" component={List} />
    </Switch>
  </CheckPermissions>
)

export default SalePlans
