import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import state from "types/state"
import AgencyContract from "./AgencyContract"
import Meta from "./ListMeta"

const metaDefaultValue = {
  filter: {},
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const AgencyContractStore = types
  .model("AgencyContract", {
    data: types.array(AgencyContract, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(state)
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { channels } = self.meta.filters_options
      const channelOptions = channels.map(({ id: value, name: label }) => {
        return {
          label,
          value
        }
      })
      return [channelOptions]
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { insurer_id, ...options } = params
      const url = insurer_id ? `/insurers/${insurer_id}/agency_contracts` : `/agency_contracts`
      return instance
        .get(url, { params: options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/agency_contracts/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default AgencyContractStore
