import React from "react"
import { Destroy, Edit } from "components/UI"
import { Link } from "react-router-dom"
import { observer } from "mobx-react"
import afterDestroyFetch from "utils/afterDestroyFetch"
import formatDateTime from "utils/formatDateTime"

const Table = ({ store }) => (
  <table className="table table-hover">
    <thead className="thead-light">
      <tr>
        <th scope="col">№</th>
        <th scope="col">Получатель</th>
        <th scope="col">Тип</th>
        <th scope="col">Статус</th>
        <th scope="col">Дата/время</th>
        <th scope="col" className="actions two" />
      </tr>
    </thead>
    <tbody>
      {store.data.map((row, index) => (
        <tr key={`document_flows-${index}`}>
          <td>
            <Link to={`/sent_document_flows/${row.id}`}>{row.id}</Link>
          </td>
          <td>{row.executor.name}</td>
          <td>{row.kind.label}</td>
          <td>{row.status.label}</td>
          <td>{formatDateTime(row.created_at)}</td>
          <td>
            <Edit url={`/sent_document_flows/${row.id}/edit`} />
            <Destroy action={() => afterDestroyFetch(store, row.id)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default observer(Table)
